import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import { getEnv } from "../env";

var env = getEnv();

const config = {
  apiKey: env.firebase.apiKey,
  authDomain: env.firebase.authDomain,
  databaseURL: env.firebase.databaseURL,
  messagingSenderId: env.firebase.messagingSenderId,
  projectId: env.firebase.projectId,
  storageBucket: env.firebase.storageBucket,
  measurementId: env.firebase.measurementId,
  appId: env.firebase.appId,
};
//!!REPLACE THE ENV JSON measurementId and appId for Training and Production env

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const fb = firebase;
