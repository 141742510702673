import React from "react";
import "./LandingFooter.scss";

interface LandingFooterProps {
  className?: string;
}
export const LandingFooter = (props: LandingFooterProps) => {
  const { className } = props;
  return (
    <div
      id="landing-footer"
      className={`${className} landing-footer landing-dark-blue ion-padding ion-text-center`}
    >
      <a
        className="no-underline mb-paragraph white"
        href="https://www.cortex.ph/"
        target="blank"
      >
        {" "}
        © Copyright Cortex Technologies Corporation. All rights reserved{" "}
      </a>
    </div>
  );
};
