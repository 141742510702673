import _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";

import "./HospitalDepartmentDetail.scss";
import * as services from "../../services";
import { MBContainer } from "../../components/MBContainer/MBContainer";

import {
  Hospital,
  HospitalService,
  HospitalDoctorListView,
  TimeKeeping,
} from "../../models";
import { IonLoading } from "@ionic/react";
import { MBDetailCard } from "../../components/MBDetailCard/MBDetailCard";
import { MSGS_COMMON } from "../../constants/messages";
import {
  MBProps,
  HospitalServiceSchedule,
  ResourceType,
} from "../../interface";
import { MBDoctorDetailCard } from "../../components/MBDoctorDetailCard/MBDoctorDetailCard";
import { RESOURCE_TYPES } from "../../constants/config";
import { MBDialog } from "../../components/MBDialog/MBDialog";
import { ServiceAccountSetup } from "../../components/ServiceAccountSetup/ServiceAccountSetup";
import { MBTimeKeepingDetailCard } from "../../components/MBTimeKeepingDetailCard/MBTimeKeepingDetailCard";

interface DeparmentParams {
  type: "service" | "doctor" | "time-keeping";
  docId: string;
  hospitalId: string;
}
interface ResourceToArchiveInterface {
  id: string;
  forResource: ResourceType;
}
class HospitalDepartmentDetail extends React.Component<MBProps> {
  state = {
    hospital: {} as Hospital,
    loading: true,
    hospitalServiceDoctorTimeKeepingDetail: null as
      | null
      | HospitalServiceSchedule
      | HospitalDoctorListView
      | TimeKeeping,
    resourceToArchive: {} as ResourceToArchiveInterface,
    isOpenResourceArchivedDialog: false,
    isOpenSuccessArchiveDialog: false,
    editData: {},
    isEdit: false,
    isOpenService: false,
  };

  componentDidMount = () => {
    const { type, docId, hospitalId } = this.props.match
      .params as DeparmentParams;
    if (type === "service") {
      this.getService(docId, hospitalId);
    } else if (type === "doctor") {
      this.getHospitalDetails(hospitalId);
      this.getDoctorHospital(docId, hospitalId);
    } else if (type === "time-keeping") {
      this.getTimeKeepingAccount(docId);
    }
  };

  getService = async (serviceId: string, hospitalId: string) => {
    const service = await services.getService(serviceId);
    this.getServiceSchedules(serviceId, service);
    this.getHospitalDetails(hospitalId);
  };

  getDoctorHospital = async (doctorId: string, hospitalId: string) => {
    this.setState({ loading: true });
    const doctorHospital = await services.getDetailedDoctorWithHospital(
      hospitalId,
      doctorId
    );
    console.log(" got it!", doctorHospital);
    this.setState({
      hospitalServiceDoctorTimeKeepingDetail: doctorHospital,
      loading: false,
    });
  };

  getTimeKeepingAccount = async (docId: string) => {
    const timeKeepingAccount = await services.getHospitalTimeKeepingDetail(
      docId
    );

    this.setState({
      hospitalServiceDoctorTimeKeepingDetail: timeKeepingAccount,
      loading: false,
    });
  };

  getHospitalDetails = async (hospitalId: string) => {
    const hospital = await services.getHospital(hospitalId);
    this.setState({ hospital });
  };

  getServiceSchedules = async (serviceId: string, service: HospitalService) => {
    const serviceSchedules = await services.getServiceSchedules(serviceId);
    const hospitalServiceDetail = {
      ...service,
      docId: serviceId,
      forResource: 1,
      schedules: serviceSchedules,
    } as HospitalServiceSchedule;
    this.setState({
      hospitalServiceDoctorTimeKeepingDetail: hospitalServiceDetail,
      loading: false,
    });
  };

  removeDoctorOrService = async (archiveDate?: Date) => {
    const { resourceToArchive, hospital } = this.state;
    try {
      this.setState({ loading: true });
      console.log("resourceToArchive -- ", resourceToArchive);
      if (!_.isEmpty(resourceToArchive)) {
        if (resourceToArchive.forResource === RESOURCE_TYPES.consultation.id) {
          // await services.removeDoctor(resourceToArchive.id, hospital, reason);
        } else {
          await services.archiveService(
            resourceToArchive.id,
            hospital,
            archiveDate!
          );
        }
        this.setState({ isOpenSuccessArchiveDialog: true });
      } else {
        this.setState({
          error: "Error removing resource: No resource to remove",
        });
      }
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  };

  refreshData = () => {
    const { docId, hospitalId } = this.props.match.params as DeparmentParams;
    this.setState({
      hospitalServices: null,
      hospitalDoctors: null,
      loading: true,
    });
    this.getService(docId, hospitalId);
    this.getTimeKeepingAccount(docId);
    this.getDoctorHospital(docId, hospitalId);
  };

  render = () => {
    const {
      loading,
      hospitalServiceDoctorTimeKeepingDetail,
      hospital,
      resourceToArchive,
      isOpenResourceArchivedDialog,
      isOpenSuccessArchiveDialog,
      editData,
      isEdit,
      isOpenService,
    } = this.state;
    const { type } = this.props.match.params as DeparmentParams;

    return (
      <>
        <Helmet>
          <title>MedBook - Hospital - Deparment - Detail</title>
        </Helmet>
        <MBContainer {...this.props} activePage="department">
          {!!hospitalServiceDoctorTimeKeepingDetail &&
            (type === "service"
              ? !loading && (
                  <MBDetailCard
                    onArchived={(
                      docId: string,
                      appointmentType: ResourceType
                    ) => {
                      this.setState({
                        resourceToArchive: {
                          id: docId,
                          forResource: appointmentType,
                        },
                        isOpenResourceArchivedDialog: true,
                      });
                    }}
                    details={
                      hospitalServiceDoctorTimeKeepingDetail as HospitalServiceSchedule
                    }
                    hospital={hospital}
                    onEditService={(data: HospitalServiceSchedule) => {
                      this.setState({
                        editData: data,
                        isEdit: true,
                        isOpenService: true,
                      });
                    }}
                  />
                )
              : type === "doctor"
              ? !loading && (
                  <MBDoctorDetailCard
                    details={
                      hospitalServiceDoctorTimeKeepingDetail as HospitalDoctorListView
                    }
                    hospital={hospital}
                    onEditCallback={() => {
                      const { type, docId, hospitalId } = this.props.match
                        .params as DeparmentParams;
                      this.getDoctorHospital(docId, hospitalId);
                    }}
                    {...this.props}
                  />
                )
              : !loading && (
                  <MBTimeKeepingDetailCard
                    details={
                      hospitalServiceDoctorTimeKeepingDetail as TimeKeeping
                    }
                    {...this.props}
                  />
                ))}
        </MBContainer>
        <MBDialog
          isOpen={isOpenSuccessArchiveDialog}
          icon="success"
          title={` ${
            resourceToArchive.forResource === RESOURCE_TYPES.consultation.id
              ? "Remove"
              : "Archive"
          } success!`}
          message={`You have successfully ${
            resourceToArchive.forResource === RESOURCE_TYPES.consultation.id
              ? "removed the doctor"
              : "archived the service"
          }.`}
          onDidDismiss={() => {
            this.setState({
              isOpenSuccessArchiveDialog: false,
              isOpenResourceArchivedDialog: false,
            });
            this.setState({
              resourceToArchive: {} as ResourceToArchiveInterface,
            });
            this.refreshData();
          }}
        />

        <MBDialog
          isOpen={isOpenResourceArchivedDialog}
          icon="warning"
          title={`You are about to ${
            resourceToArchive.forResource === RESOURCE_TYPES.consultation.id
              ? "remove this doctor"
              : "archive this service"
          }`}
          message={`Patients will no longer be able to book this ${
            resourceToArchive.forResource === RESOURCE_TYPES.consultation.id
              ? "doctor and all appointments with the doctor will be cancelled"
              : "service"
          }. Are you sure you want to continue?`}
          onDidDismiss={() => {
            this.setState({ isOpenResourceArchivedDialog: false });
          }}
          onApprove={() => {
            this.removeDoctorOrService(new Date());
          }}
          onDecline={() => {
            this.setState({
              resourceToArchive: {} as ResourceToArchiveInterface,
            });
            this.setState({ isOpenResourceArchivedDialog: false });
          }}
        />
        <IonLoading
          translucent={false}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />
        {isOpenService && (
          <ServiceAccountSetup
            editData={editData as HospitalServiceSchedule}
            isEdit={isEdit as boolean}
            isOpen={isOpenService}
            hospitalId={hospital.docId || ""}
            hospitalName={hospital.hospitalName}
            onBack={() => {
              window.location.reload();
              this.setState({
                isOpenService: false,
                editData: {},
                isEdit: null,
              });
            }}
            onCancel={() => {}}
            onRefreshData={() => {
              this.refreshData();
              this.setState({ selectedTableList: null });
            }}
            {...this.props}
          />
        )}
      </>
    );
  };
}

export default HospitalDepartmentDetail;
