import * as _ from "lodash";
import QrReader from "react-qr-reader";
import React, { useState } from "react";
import { IonIcon, IonLabel, IonButton, IonModal, IonToast } from "@ionic/react";

import "./MBTimeKeepingScan.scss";
import { MBCOLORS } from "../../constants/config";

interface MBTimeKeepingProps {
  mode: "doctor" | "hospital" | "security";
  onQRScan: (qrResult: string) => void;
  isResultFound: boolean;
}
export const MBTimeKeepingScan = (props: MBTimeKeepingProps) => {
  const { isResultFound, onQRScan } = props;
  const [scanNow, setScanNow] = useState(false);
  const [error, setError] = useState("");

  const handleScan = (data: string | null) => {
    console.log("this is Data -- ", data);
    if (!isResultFound) {
      if (!!data && !_.isNull(data)) {
        onQRScan(data);
      }
    } else {
      setScanNow(false);
    }
  };

  const onError = (error: any) => {
    console.log("this is error -- ", error);
    setError(error);
  };

  return (
    <>
      <div className="mb-time-keeping-scan-container">
        <IonIcon className="mb-time-keeping-scan-logo" />
        <IonLabel className="mb-time-keeping-scan-label mb-h2 ion-no-padding ion-no-margin">
          Scan QR Code
        </IonLabel>
        <IonLabel className="mb-time-keeping-scan-sub-label mb-body ion-no-padding ion-no-margin">
          Please scan the code to confirm patients arrival.
        </IonLabel>
        <IonButton
          mode="ios"
          className="mb-time-keeping-scan-button"
          onClick={() => {
            setScanNow(true);
          }}
        >
          Scan Code
        </IonButton>
      </div>
      {scanNow && (
        <IonModal
          isOpen={scanNow}
          cssClass="mb-qr-scanner-dialog"
          onDidDismiss={() => {
            setScanNow(false);
          }}
        >
          <div className="mb-qr-scanner-container">
            <QrReader
              onScan={(data: string | null) => {
                handleScan(data);
              }}
              delay={300}
              onError={(error) => {
                onError(error);
              }}
              className="mb-qr-scanner"
            />
          </div>
          <IonButton
            mode="ios"
            fill="clear"
            color={MBCOLORS.primary}
            className="mb-qr-scanner-cancel-button mb-h4 bold"
            onClick={() => {
              setScanNow(false);
            }}
          >
            Cancel
          </IonButton>
        </IonModal>
      )}
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
