import React from "react";
import { IonLoading } from "@ionic/react";

import "./DoctorInvitationLogin.scss";
import * as services from "../../services";
import { decryptPassword } from "../../functions/common";
import { Doctor } from "../../models";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import { MSGS_COMMON } from "../../constants/messages";

class DoctorInvitationLogin extends React.Component<MBProps> {
  state = {
    loading: true,
  };
  componentDidMount = async () => {
    const urlParams = this.props.match.params;
    const { doctorId } = urlParams as { doctorId: string };
    try {
      const doctor = (await services.getDoctor(doctorId)) as Doctor;
      this.doctorSignIn(
        doctorId,
        doctor.emailAddress,
        doctor.initialPasswordCipher as string
      );
    } catch (error) {
      console.log("error --- doctorInvidation -- ", error);
      this.setState({ loading: false });
    }
  };
  doctorSignIn = async (
    secretKey: string,
    emailAddress: string,
    initialPasswordCipher: string
  ) => {
    if (!!emailAddress && !!initialPasswordCipher) {
      const decryptedPassword = decryptPassword(
        secretKey,
        initialPasswordCipher
      );
      await services.signIn(emailAddress, decryptedPassword);
      this.setState({ loading: false });
    }
  };
  render = () => {
    const { loading } = this.state;
    return (
      <MBContainer {...this.props}>
        <IonLoading
          translucent={true}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />
      </MBContainer>
    );
  };
}

export default DoctorInvitationLogin;
