import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import { Helmet } from "react-helmet";
import React from "react";
import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLoading,
  IonToast,
  IonIcon,
} from "@ionic/react";

import "./ContactUs.scss";
import * as services from "../../services";
import { analytics } from "../../firebase";
import { contactUsEmailTemplate } from "../../functions/email";
import { formatString } from "../../functions/common";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBInput } from "../../components/MBInput/MBInput";
import { MBProps } from "../../interface";
import { MSGS_COMMON_FORM_ERROR } from "../../constants/messages";
import { MBCOLORS, ANALYTICS_CONTENT_TYPES } from "../../constants/config";

class ContactUs extends React.Component<MBProps> {
  state = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    inquiry: "",

    error: "",
    errorFirstName: "",
    errorLastName: "",
    errorEmailAddress: "",
    errorMobileNumber: "",
    errorInquiry: "",

    success: "",
    loading: false,
  };

  componentDidMount = () => {
    const { authUser } = this.props;
    if (!_.isEmpty(authUser) && !_.isEmpty(authUser.userDetails)) {
      this.setState({
        firstName: authUser.userDetails.firstName,
        lastName: authUser.userDetails.lastName,
        mobileNumber: authUser.userDetails.phoneNumber,
        emailAddress: authUser.userDetails.emailAddress,
      });
    }
  };
  onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const {
      firstName,
      lastName,
      mobileNumber,
      emailAddress,
      inquiry,
    } = this.state;

    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedMobileNumber = formatString(mobileNumber || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedInquiry = inquiry.replace(/(\r\n|\n|\r)/gm, "<br/>");
    let errorMessage;

    if (_.isEmpty(formattedFirstName)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.firstName;
      this.setState({ errorFirstName: errorMessage });
    }
    if (_.isEmpty(formattedLastName)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.lastName;
      this.setState({ errorLastName: errorMessage });
    }
    if (_.isEmpty(formattedMobileNumber)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.mobileNumber;
      this.setState({ errorMobileNumber: errorMessage });
    }
    if (!validatePhoneNumber(formattedMobileNumber)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.invalidMobileNumner;
      this.setState({ errorMobileNumber: errorMessage });
    }
    if (_.isEmpty(formattedEmailAddress)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.emailAddress;
      this.setState({ errorEmailAddress: errorMessage });
    }
    if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.invalidEmail;
      this.setState({ errorEmailAddress: errorMessage });
    }
    if (_.isEmpty(formattedInquiry)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.inquiry;
      this.setState({ errorInquiry: errorMessage });
    }

    if (!errorMessage) {
      this.setState({ loading: true });
      try {
        const sendContactUsEmail = contactUsEmailTemplate(
          formattedFirstName,
          formattedLastName,
          formattedMobileNumber,
          formattedInquiry,
          formattedEmailAddress
        );
        await services.sendEmailToSupport(
          sendContactUsEmail.subject,
          sendContactUsEmail.message
        );
        // analytics.logEvent("submit_contact_us", {
        //   content_type: ANALYTICS_CONTENT_TYPES.contactUs.type,
        // });
        this.setState({
          loading: false,
          success: "Email Sent!",
          firstName: "",
          lastName: "",
          mobileNumber: "",
          emailAddress: "",
          inquiry: "",
        });
      } catch (error) {
        this.setState({ loading: false, error });
      }
    }
  };

  clearState = () => {
    this.setState({
      firstName: "",
      lastName: "",
      mobileNumber: "",
      emailAddress: "",
      inquiry: "",
    });
  };

  render = () => {
    const {
      firstName,
      lastName,
      mobileNumber,
      emailAddress,
      inquiry,

      error,
      errorFirstName,
      errorLastName,
      errorMobileNumber,
      errorEmailAddress,
      errorInquiry,

      success,
      loading,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>MedBook - Contact Us</title>
        </Helmet>
        <MBContainer {...this.props} activePage="contact-us">
          <div className="contact-us-container">
            <IonLabel className="mb-h1 dark-blue big">Contact Us</IonLabel>
            <IonIcon className="contact-us-icon" />
            <IonCard className="contact-us-card" color={MBCOLORS.tertiary}>
              <IonCardContent className="contact-us-card-content">
                <div className="contact-us-content-header-container">
                  <IonLabel className="contact-us-content-header-label mb-h2 bold">
                    Leave us message
                  </IonLabel>
                </div>
                <div className="contact-us-form-container">
                  <form onSubmit={this.onSubmit}>
                    <IonGrid className="ion-no-padding ion-no-margin">
                      <IonRow className="contact-us-row ion-no-padding ion-no-margin">
                        <IonCol
                          size="6"
                          className="contact-us-right-col-input ion-no-padding ion-no-margin"
                        >
                          <MBInput
                            type="text"
                            value={firstName}
                            label="First Name"
                            onChange={(firstName) => {
                              this.setState({ firstName, errorFirstName: "" });
                            }}
                            error={errorFirstName}
                          />
                        </IonCol>
                        <IonCol
                          size="6"
                          className="contact-us-left-col-input ion-no-padding ion-no-margin"
                        >
                          <MBInput
                            type="text"
                            value={lastName}
                            label="Last Name"
                            onChange={(lastName) => {
                              this.setState({ lastName, errorLastName: "" });
                            }}
                            error={errorLastName}
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow className="contact-us-row ion-no-padding ion-no-margin">
                        <IonCol
                          size="6"
                          className="contact-us-right-col-input ion-no-padding ion-no-margin"
                        >
                          <MBInput
                            type="tel"
                            value={mobileNumber}
                            label="Mobile Number"
                            onChange={(mobileNumber) => {
                              this.setState({
                                mobileNumber,
                                errorMobileNumber: "",
                              });
                            }}
                            error={errorMobileNumber}
                          />
                        </IonCol>
                        <IonCol
                          size="6"
                          className="contact-us-left-col-input ion-no-padding ion-no-margin"
                        >
                          <MBInput
                            type="email"
                            value={emailAddress}
                            label="Email Address"
                            onChange={(emailAddress) => {
                              this.setState({
                                emailAddress,
                                errorEmailAddress: "",
                              });
                            }}
                            error={errorEmailAddress}
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow className="contact-us-row ion-no-padding ion-no-margin">
                        <IonCol
                          size="12"
                          className="ion-no-padding ion-no-margin"
                        >
                          <MBInput
                            type="text"
                            value={inquiry}
                            label="Inquiry"
                            onChange={(inquiry) => {
                              this.setState({
                                inquiry,
                                errorInquiry: "",
                              });
                            }}
                            mode="textArea"
                            error={errorInquiry}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>

                    <div className="contact-us-button-container">
                      <IonButton
                        mode="ios"
                        color={MBCOLORS.primary}
                        className="contact-us-submit-button"
                        type="submit"
                      >
                        Submit
                      </IonButton>
                    </div>
                  </form>
                </div>
              </IonCardContent>
            </IonCard>
          </div>

          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={"Sending.."}
          />
          <IonToast
            isOpen={!_.isEmpty(error)}
            message={error}
            duration={2000}
            onDidDismiss={() => {
              this.setState({ error: "" });
            }}
            color={MBCOLORS.danger}
          />
          <IonToast
            isOpen={!_.isEmpty(success)}
            message={success}
            duration={2000}
            onDidDismiss={() => {
              this.setState({
                success: "",
              });
            }}
            color={MBCOLORS.success}
          />
        </MBContainer>
      </>
    );
  };
}

export default ContactUs;
