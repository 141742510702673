import { Helmet } from "react-helmet";
import React from "react";

import "./ResendEmailVerification.scss";
import * as services from "../../services";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import {
  IonCard,
  IonIcon,
  IonLabel,
  IonCardContent,
  IonButton,
} from "@ionic/react";
import { MBCOLORS } from "../../constants/config";

class ResendEmailVerification extends React.Component<MBProps> {
  onCLick = async () => {
    await services.signOut();
  };
  render = () => {
    return (
      <>
        <Helmet>
          <title>MedBook - Email Verification</title>
        </Helmet>
        <MBContainer {...this.props}>
          <IonCard className="resend-email-card" color={MBCOLORS.tertiary}>
            <IonCardContent className="resend-email-card-content">
              <IonIcon className="verify-email-icon warning" />
              <IonLabel className="mb-h2 dark-blue">
                Email Verification Required
              </IonLabel>
              <IonLabel className="resend-email-description mb-body ion-margin-top">
                Hi! to complete your registration please click the link sent to
                your email.
              </IonLabel>
              <IonButton
                mode="ios"
                className="verify-email-ok-button"
                color={MBCOLORS.primary}
                onClick={this.onCLick}
              >
                Okay
              </IonButton>
            </IonCardContent>
          </IonCard>
        </MBContainer>
      </>
    );
  };
}

export default ResendEmailVerification;
