import * as _ from "lodash";
import React, { useState, useRef } from "react";
import {
  IonButton,
  IonModal,
  IonLabel,
  IonToast,
  IonSlides,
  IonSlide,
  IonInput,
  IonIcon,
} from "@ionic/react";

import "./CovidChecklistDialog.scss";
import { isMobile } from "../../functions/common";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBYesOrNoRadioGroup } from "../MBYesOrNoRadioGroup/MBYesOrNoRadioGroup";
import { MBCOLORS } from "../../constants/config";
import { MSGS_COVID_DESCRIPTIONS } from "../../constants/messages";

interface CovidChecklistDialogProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  onNext: (isCovidPositive: boolean) => void; //adding prop will show yes or no option rather than "ok" button
  onBack?: () => void; //adding prop will show yes or no option rather than "ok" button
  userType?: "transport" | "patient";
  hasCompanion: boolean;
}

interface ExposureQuestionInterface {
  question: string;
  subQuestion?: string;
  value: null | boolean;
  travelHistoryInput?: string;
  when?: string;
  times?: string;
}
export const CovidChecklistDialog = (props: CovidChecklistDialogProps) => {
  const { isOpen, onDidDismiss, onNext, onBack, hasCompanion } = props;
  const slidesRef: React.RefObject<HTMLIonSlidesElement> = useRef(null);

  const [listVisible, setListVisible] = useState(false);

  const [exposureQuestionnaire, setExposureQuestionnaire] = useState([
    {
      question: "Are you a HCW (health care worker)?",
      value: null,
    },
    {
      question:
        "In the past 21 days, did you have any travel outside the Philippines? If Yes, please indicate where.",
      value: null,
      travelHistoryInput: "",
    },
    {
      question:
        "In the past 14 days, did you have any of the following exposures?",
      subQuestion:
        "Household contact with another RT-PCR confirmed COVID-19 patient",
      value: null,
    },
    {
      subQuestion:
        "Community contact with another RT-PCR confirmed COVID-19 patient",
      value: null,
    },
    {
      subQuestion: "Any HCW with RT-PCR confirmed COVID-19 patient",
      value: null,
    },
    {
      subQuestion:
        "Exposure to a cluster of patients/people with severe respiratory illness of unknown etiology",
      value: null,
    },
  ] as ExposureQuestionInterface[]);
  const [
    exposureQuestionnaireCompanion,
    setExposureQuestionnaireCompanion,
  ] = useState([
    {
      question: "Are you a HCW (health care worker)?",
      value: null,
    },
    {
      question:
        "In the past 21 days, did you have any travel outside the Philippines? If Yes, please indicate where.",
      value: null,
      travelHistoryInput: "",
    },
    {
      question:
        "In the past 14 days, did you have any of the following exposures?",
      subQuestion:
        "Household contact with another RT-PCR confirmed COVID-19 patient",
      value: null,
    },
    {
      subQuestion:
        "Community contact with another RT-PCR confirmed COVID-19 patient",
      value: null,
    },
    {
      subQuestion: "Any HCW with RT-PCR confirmed COVID-19 patient",
      value: null,
    },
    {
      subQuestion:
        "Exposure to a cluster of patients/people with severe respiratory illness of unknown etiology",
      value: null,
    },
  ] as ExposureQuestionInterface[]);

  const [formPage, setFormPage] = useState(0);

  const [covidChecklist, setCovidChecklist] = useState([
    {
      question: "Ferver (Temp over 38°)",
      value: null,
    },
    {
      question: "Sore Throat",
      value: null,
    },
    {
      question: "New onset cough",
      value: null,
    },

    {
      question: "New onset shortness of breath",
      value: null,
    },
    {
      question: "Runny nose / post nasal drip",
      value: null,
    },
    {
      question: "Loss of smell",
      value: null,
    },

    {
      question: "Loss of taste",
      value: null,
    },

    {
      question: "Diarrhea",
      value: null,
    },
    {
      question: "Muscle pain",
      value: null,
    },
    {
      question: "Night sweats",
      value: null,
    },
  ] as { question: string; value: boolean | null }[]);
  const [covidChecklistCompanion, setCovidChecklistCompanion] = useState([
    {
      question: "Ferver (Temp over 38°)",
      value: null,
    },
    {
      question: "Sore Throat",
      value: null,
    },
    {
      question: "New onset cough",
      value: null,
    },

    {
      question: "New onset shortness of breath",
      value: null,
    },
    {
      question: "Runny nose / post nasal drip",
      value: null,
    },
    {
      question: "Loss of smell",
      value: null,
    },

    {
      question: "Loss of taste",
      value: null,
    },

    {
      question: "Diarrhea",
      value: null,
    },
    {
      question: "Muscle pain",
      value: null,
    },
    {
      question: "Night sweats",
      value: null,
    },
  ] as { question: string; value: boolean | null }[]);
  const [error, setError] = useState("");
  const [whereTravel, setWhereTravel] = useState("");
  const [whereTravelCompanion, setWhereTravelCompanion] = useState("");

  const onValidateSymptoms = () => {
    let error;
    if (
      _.includes(
        covidChecklist.map((item) => item.value),
        null
      )
    ) {
      error = "Please complete the questionaire to proceed.";
    }
    if (hasCompanion) {
      if (
        _.includes(
          covidChecklist.map((item) => item.value),
          null
        )
      ) {
        error = "Please complete the questionaire to proceed.";
      }
    }

    if (error) {
      setError(error);
    } else {
      const hasSymptoms = _.includes(
        covidChecklist.map((item) => item.value),
        true
      );
      const companionHasSymptoms =
        !!hasCompanion &&
        _.includes(
          covidChecklistCompanion.map((item) => item.value),
          true
        );
      if (hasSymptoms || companionHasSymptoms) {
        onNext(hasSymptoms || companionHasSymptoms);
      } else {
        onNext(false); //not covid risk
      }
    }
  };

  const onValidateExposure = () => {
    let error;
    if (
      _.includes(
        exposureQuestionnaire.map((item) => item.value),
        null
      )
    ) {
      error = "Please complete the exposure history questionaire to proceed.";
      setError(error);
    }
    if (_.isEmpty(whereTravel) && exposureQuestionnaire[1].value) {
      //travelhistory question
      error = "Please complete the exposure history questionaire to proceed.";
      setError(error);
    }
    if (hasCompanion) {
      if (
        _.includes(
          exposureQuestionnaireCompanion.map((item) => item.value),
          null
        )
      ) {
        error = "Please complete the exposure history questionaire to proceed.";
        setError(error);
      }
      if (_.isEmpty(whereTravel) && exposureQuestionnaireCompanion[1].value) {
        //travelhistory question
        error = "Please complete the exposure history questionaire to proceed.";
        setError(error);
      }
    }

    if (error) {
      setError(error);
    } else {
      goToPage(2);
    }
  };

  const goToPage = (page: number) => {
    if (!_.isNull(slidesRef.current)) {
      slidesRef.current.lockSwipes(false);
      slidesRef.current.slideTo(page);
      slidesRef.current.lockSwipes(true);
    }
  };

  console.log("hasCompanion ---- ", hasCompanion);
  return (
    <>
      <IonModal
        cssClass="covid-checklist-dialog"
        isOpen={isOpen}
        onDidDismiss={() => {
          onDidDismiss();
        }}
        onWillDismiss={() => {
          setListVisible(false);
        }}
        onWillPresent={() => {
          setListVisible(true);
        }}
      >
        <div className="modal-content">
          <div className="covid-checklist-dialog-children-container">
            {listVisible && (
              <IonSlides
                className="covid-checklist-slides"
                options={{ autoHeight: true }}
                onIonSlideDidChange={async (event) => {
                  const activeIndex = await slidesRef.current!.getActiveIndex();

                  setFormPage(activeIndex);
                }}
                onIonSlidesDidLoad={async () => {
                  if (!_.isNull(slidesRef.current)) {
                    slidesRef.current!.lockSwipes(true);
                    const activeIndex = await slidesRef.current!.getActiveIndex();

                    setFormPage(activeIndex);
                  }
                }}
                ref={slidesRef}
              >
                <IonSlide
                  className="covid-checklist-dialog-landing-slide ion-no-padding ion-no-margin"
                  tabIndex={0}
                  key={`slide_${0}`}
                >
                  <IonLabel className="covid-checklist-sub-label mb-h4 bold dark-blue ion-text-uppercase">
                    COVID-19 Symptoms and Exposure Self-Assessment
                  </IonLabel>

                  <div className="codvid-checklist-landing-container">
                    <IonIcon className="covid-landing-logo" />
                    <IonLabel
                      className={`covid-checklist-dialog-message-label ${
                        isMobile() ? "mb-paragraph" : "mb-body"
                      }`}
                    >
                      {MSGS_COVID_DESCRIPTIONS.patientNotice}
                    </IonLabel>
                  </div>
                </IonSlide>
                <IonSlide
                  className="covid-checklist-dialog-exposure-slide ion-no-padding ion-no-margin"
                  tabIndex={1}
                  key={`slide_${1}`}
                >
                  <div className="exposure-container">
                    <IonLabel className="covid-checklist-sub-label mb-body bold dark-blue ion-text-uppercase">
                      <u>Exposure History</u> (Patient Information)
                    </IonLabel>
                    <div className="codvid-checklist-exposure-questions-container">
                      {exposureQuestionnaire.map((exposureQuestion, index) => {
                        return (
                          <div
                            className="covid-checklist-exposure-questions-choices-container"
                            key={index}
                          >
                            <IonLabel className="covid-checklist-questions-label mb-paragraph bold">
                              {exposureQuestion.question}
                            </IonLabel>
                            {exposureQuestion.subQuestion && (
                              <IonLabel className="covid-checklist-questions-sub-label mb-paragraph">
                                {exposureQuestion.subQuestion}
                              </IonLabel>
                            )}
                            <div className="yes-no-input-container">
                              <div
                                className={`yes-no-radio-group-container ${
                                  isMobile() ? "mobile" : ""
                                }`}
                              >
                                <MBYesOrNoRadioGroup
                                  key={`covid_item_${index}`}
                                  value={exposureQuestion.value}
                                  onChange={(result: boolean) => {
                                    const updatedList = _.clone(
                                      exposureQuestionnaire
                                    );
                                    const updatedListValue = updatedList[index];
                                    const updatedListValueIndex = {
                                      ...updatedListValue,
                                      question: updatedListValue.question,
                                      value: result,
                                    };
                                    updatedList[index] = updatedListValueIndex;
                                    setExposureQuestionnaire(updatedList);

                                    if (
                                      updatedList[1].value === false ||
                                      updatedList[1].value === null
                                    ) {
                                      setWhereTravel("");
                                    }
                                  }}
                                />
                              </div>

                              {_.has(
                                exposureQuestion,
                                "travelHistoryInput"
                              ) && (
                                <IonInput
                                  color={MBCOLORS.dark}
                                  disabled={!exposureQuestion.value as boolean}
                                  type="text"
                                  className={`covid-checklist-travel-history-input ${
                                    isMobile() && "mobile"
                                  } mb-paragraph`}
                                  onIonChange={(travelWhere) => {
                                    setWhereTravel(
                                      travelWhere.detail.value as string
                                    );
                                  }}
                                  value={whereTravel as string}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* FOR COMPANION */}
                    {!!hasCompanion && (
                      <>
                        <IonLabel className="covid-checklist-sub-label mb-body bold blue ion-text-uppercase">
                          <u>Exposure History</u> (Companion Information)
                        </IonLabel>
                        <div className="codvid-checklist-exposure-questions-container">
                          {exposureQuestionnaireCompanion.map(
                            (exposureQuestion, index) => {
                              return (
                                <div
                                  className="covid-checklist-exposure-questions-choices-container"
                                  key={index}
                                >
                                  <IonLabel className="covid-checklist-questions-label mb-paragraph bold">
                                    {exposureQuestion.question}
                                  </IonLabel>
                                  {exposureQuestion.subQuestion && (
                                    <IonLabel className="covid-checklist-questions-sub-label mb-paragraph">
                                      {exposureQuestion.subQuestion}
                                    </IonLabel>
                                  )}
                                  <div className="yes-no-input-container">
                                    <div
                                      className={`yes-no-radio-group-container ${
                                        isMobile() ? "mobile" : ""
                                      }`}
                                    >
                                      <MBYesOrNoRadioGroup
                                        key={`covid_item_${index}`}
                                        value={exposureQuestion.value}
                                        onChange={(result: boolean) => {
                                          const updatedList = _.clone(
                                            exposureQuestionnaireCompanion
                                          );
                                          const updatedListValue =
                                            updatedList[index];
                                          const updatedListValueIndex = {
                                            ...updatedListValue,
                                            question: updatedListValue.question,
                                            value: result,
                                          };
                                          updatedList[
                                            index
                                          ] = updatedListValueIndex;
                                          setExposureQuestionnaireCompanion(
                                            updatedList
                                          );

                                          if (
                                            updatedList[1].value === false ||
                                            updatedList[1].value === null
                                          ) {
                                            setWhereTravel("");
                                          }
                                        }}
                                      />
                                    </div>

                                    {_.has(
                                      exposureQuestion,
                                      "travelHistoryInput"
                                    ) && (
                                      <IonInput
                                        color={MBCOLORS.dark}
                                        disabled={
                                          !exposureQuestion.value as boolean
                                        }
                                        type="text"
                                        className={`covid-checklist-travel-history-input ${
                                          isMobile() && "mobile"
                                        } mb-paragraph`}
                                        onIonChange={(travelWhere) => {
                                          setWhereTravelCompanion(
                                            travelWhere.detail.value as string
                                          );
                                        }}
                                        value={whereTravelCompanion as string}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </IonSlide>
                <IonSlide
                  className="covid-checklist-symptoms-slide ion-no-padding ion-no-margin"
                  tabIndex={2}
                  key={`slide_${2}`}
                >
                  <div className="covid-checklist-container">
                    <IonLabel className="covid-checklist-symptoms-sub-title-label mb-body bold dark-blue">
                      <u>Clinical Symptos (past 14 days)</u> (Patient
                      Information)
                    </IonLabel>
                    <div className="covid-checklist-symptoms-options-container">
                      {covidChecklist.map((item, index) => {
                        return (
                          <div className="yes-no-radio-group-container">
                            <MBYesOrNoRadioGroup
                              key={`covid_item_${index}`}
                              value={item.value}
                              label={item.question}
                              onChange={(result: boolean) => {
                                const updatedList = _.clone(covidChecklist);
                                const updatedListValue = updatedList[index];
                                const updatedListValueIndex = {
                                  question: updatedListValue.question,
                                  value: result,
                                };
                                updatedList[index] = updatedListValueIndex;
                                setCovidChecklist(updatedList);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {/* FOR COMPANION */}
                    {!!hasCompanion && (
                      <>
                        {" "}
                        <IonLabel className="covid-checklist-symptoms-sub-title-label mb-body bold blue">
                          <u>Clinical Symptos (past 14 days)</u> (Companion
                          Information)
                        </IonLabel>
                        <div className="covid-checklist-symptoms-options-container">
                          {covidChecklistCompanion.map((item, index) => {
                            return (
                              <div className="yes-no-radio-group-container">
                                <MBYesOrNoRadioGroup
                                  key={`covid_item_${index}`}
                                  value={item.value}
                                  label={item.question}
                                  onChange={(result: boolean) => {
                                    const updatedList = _.clone(
                                      covidChecklistCompanion
                                    );
                                    const updatedListValue = updatedList[index];
                                    const updatedListValueIndex = {
                                      question: updatedListValue.question,
                                      value: result,
                                    };
                                    updatedList[index] = updatedListValueIndex;
                                    setCovidChecklistCompanion(updatedList);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </IonSlide>
              </IonSlides>
            )}
          </div>
          <MBDivider />
          {onNext !== undefined && onBack !== undefined && (
            <div className="covid-checklist-dialog-buttons-container">
              <IonButton
                onClick={() => {
                  if (formPage === 1) {
                    goToPage(0);
                  } else if (formPage === 2) {
                    goToPage(1);
                  } else {
                    onDidDismiss();
                  }
                }}
                className="covid-checklist-dialog-back-button mb-body ion-text-capitalize"
                fill="clear"
              >
                Back
              </IonButton>
              <IonButton
                onClick={() => {
                  if (formPage === 1) {
                    onValidateExposure();
                  } else if (formPage === 2) {
                    onValidateSymptoms();
                  } else {
                    goToPage(1);
                  }
                }}
                className="covid-checklist-dialog-next-button mb-body bold white ion-text-capitalize"
                color={MBCOLORS.primary}
              >
                Next
              </IonButton>
            </div>
          )}
        </div>
      </IonModal>
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
