import * as _ from "lodash";
import moment from "moment";
import {
  CONSULTATIONS_BOOKED_AND_CAPACITY,
  DOCTOR_HOSPITALS,
  DOCTOR_SCHEDULE,
  HOSPITAL_DOCTOR_APPOINTMENTS,
  HOSPITAL_SERVICES,
  HOSPITAL_SERVICES_SCHEDULE,
  HOSPITAL_SERVICE_APPOINTMENTS,
  PATIENTS,
  SERVICES_BOOKED_AND_CAPACITY,
} from "../constants/dbCollections";
import { firestore } from "../firebase";
import { daysSinceDate, isSameDate } from "../functions/common";
import { DaysOfWeek } from "../interface";
import {
  ConsultationBookedAndCapacity,
  DoctorSchedule,
  HospitalDoctorAppointments,
  HospitalServiceAppointments,
  ServiceSchedule,
} from "../models";

export const getTotalPatientsRegistered = (
  callback: (patientCount: number, error?: string) => void
) => {
  try {
    const unsubscribe = firestore.collection(PATIENTS).onSnapshot(
      (patients) => {
        if (!patients.empty) {
          callback(patients.docs.length);
        } else {
          callback(0);
        }
      },
      (error) => {
        callback(0, error.message);
      }
    );
    return unsubscribe;
  } catch (e) {
    callback(0, e);
    return () => {};
  }
};

export const getTotalHospitalDepartmentDoctors = async (
  hospitalIds: string[],
  callback: (doctorCount: number, error?: string) => void
) => {
  try {
    if (hospitalIds.length <= 10) {
      const unsubscribe = firestore
        .collection(DOCTOR_HOSPITALS)
        .where("hospitalId", "in", hospitalIds)
        .onSnapshot(
          (doctors) => {
            if (!doctors.empty) {
              callback(doctors.docs.length);
            } else {
              callback(0);
            }
          },
          (error) => {
            callback(0, error.message);
          }
        );
      return unsubscribe;
    } else {
      const result = await Promise.all(
        _.chunk(hospitalIds, 10).map((hospitalIdsChucked) => {
          return firestore
            .collection(DOCTOR_HOSPITALS)
            .where("hospitalId", "in", hospitalIdsChucked)
            .get();
        })
      );

      callback(_.sumBy(result, (result) => result.docs.length));
      return () => {};
    }
  } catch (e) {
    callback(0, e);
    return () => {};
  }
};

export const getTotalHospitalDepartmentServicesAppointments = async (
  hospitalIds: string[],
  callback: (bookingCount: number, error?: string) => void
) => {
  try {
    if (hospitalIds.length <= 10) {
      const unsubscribe = firestore
        .collection(HOSPITAL_SERVICE_APPOINTMENTS)
        .where("hospitalId", "in", hospitalIds)
        .onSnapshot(
          (appointments) => {
            if (!appointments.empty) {
              callback(appointments.docs.length);
            } else {
              callback(0);
            }
          },
          (error) => {
            callback(0, error.message);
          }
        );
      return unsubscribe;
    } else {
      const result = await Promise.all(
        _.chunk(hospitalIds, 10).map((hospitalIdsChucked) => {
          return firestore
            .collection(HOSPITAL_SERVICE_APPOINTMENTS)
            .where("hospitalId", "in", hospitalIdsChucked)
            .get();
        })
      );

      callback(_.sumBy(result, (result) => result.docs.length));
      return () => {};
    }
  } catch (e) {
    callback(0, e);
    return () => {};
  }
};

export const getTotalHospitalDepartmentConsultationAppointments = async (
  hospitalIds: string[],
  callback: (bookingCount: number, error?: string) => void
) => {
  try {
    if (hospitalIds.length <= 10) {
      const unsubscribe = firestore
        .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
        .where("hospitalId", "in", hospitalIds)
        .onSnapshot(
          (appointments) => {
            if (!appointments.empty) {
              callback(appointments.docs.length);
            } else {
              callback(0);
            }
          },
          (error) => {
            callback(0, error.message);
          }
        );
      return unsubscribe;
    } else {
      const result = await Promise.all(
        _.chunk(hospitalIds, 10).map((hospitalIdsChucked) => {
          return firestore
            .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
            .where("hospitalId", "in", hospitalIdsChucked)
            .get();
        })
      );

      callback(_.sumBy(result, (result) => result.docs.length));
      return () => {};
    }
  } catch (e) {
    callback(0, e);
    return () => {};
  }
};

export const getDoctorsBookedAndCapacity = async (
  hospitalIds: string[],
  callback: (graphData: number[], error?: string) => void
) => {
  try {
    const bookedIndexedValueMap = {} as { [id: number]: number };
    const capacityIndexedValueMap = {} as { [id: number]: number };
    const unsubscribeList = [] as (() => void)[];
    const finalUnsubscribe = await Promise.all(
      _.chunk(hospitalIds, 10).map((hospitalIdsItem, index) => {
        return new Promise((resolve) => {
          const unsubscribe = firestore
            .collection(DOCTOR_HOSPITALS)
            .where("hospitalId", "in", hospitalIdsItem)
            .onSnapshot(
              (doctorHospital) => {
                if (!doctorHospital.empty) {
                  const doctorHospitalIds = _.uniq(
                    doctorHospital.docs.map(
                      (doctorHospital) => doctorHospital.id
                    )
                  );
                  let schedules = [] as DoctorSchedule[];
                  const dayOfWeek = new Date().getDay();

                  //GET CAPACITY
                  const unsubscribeDoctorHospital = _.chunk(
                    doctorHospitalIds,
                    10
                  ).map((ids) => {
                    return firestore
                      .collection(DOCTOR_SCHEDULE)
                      .where("doctorHospitalId", "in", ids)
                      .where("dayOfWeek", "==", dayOfWeek)
                      .onSnapshot(
                        (doctorScheds) => {
                          if (!doctorScheds.empty) {
                            schedules = schedules.concat(
                              doctorScheds.docs.map((sched) => {
                                return {
                                  ...sched.data(),
                                  docId: sched.id,
                                } as DoctorSchedule;
                              })
                            );
                          }
                          schedules = _.uniqBy(
                            schedules,
                            (schedule) => schedule.docId
                          );
                          capacityIndexedValueMap[index] = _.sum(
                            schedules.map((sched) => sched.slots)
                          );
                          callback([
                            _.sum(Object.values(bookedIndexedValueMap)),
                            _.sum(Object.values(capacityIndexedValueMap)),
                          ]);
                        },
                        (error) => {
                          callback(
                            [
                              _.sum(Object.values(bookedIndexedValueMap)),
                              _.sum(Object.values(capacityIndexedValueMap)),
                            ],
                            error.message
                          );
                        }
                      );
                  });

                  //GET BOOKED
                  const dateMin = _.clone(new Date());
                  dateMin.setHours(0);
                  dateMin.setMinutes(0);
                  dateMin.setSeconds(0);
                  dateMin.setMilliseconds(0);

                  const dateMax = _.clone(new Date());
                  dateMax.setHours(23);
                  dateMax.setMinutes(59);
                  dateMax.setSeconds(59);
                  dateMax.setMilliseconds(59);
                  const unsubscribeAppointments = firestore
                    .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
                    .where("hospitalId", "in", hospitalIdsItem)
                    .where("appointmentDate", ">=", dateMin)
                    .where("appointmentDate", "<=", dateMax)
                    .where("isCancelled", "==", false)
                    .onSnapshot((appointments) => {
                      if (!appointments.empty) {
                        bookedIndexedValueMap[index] = appointments.docs.length;
                      }
                      callback([
                        _.sum(Object.values(bookedIndexedValueMap)),
                        _.sum(Object.values(capacityIndexedValueMap)),
                      ]);
                    });
                  resolve(() => {
                    unsubscribeDoctorHospital.forEach((unsubscribe) => {
                      unsubscribe();
                    });
                    unsubscribeAppointments();
                  });
                } else {
                  callback([
                    _.sum(Object.values(bookedIndexedValueMap)),
                    _.sum(Object.values(capacityIndexedValueMap)),
                  ]);
                  resolve(() => {});
                }
              },
              (error) => {
                callback(
                  [
                    _.sum(Object.values(bookedIndexedValueMap)),
                    _.sum(Object.values(capacityIndexedValueMap)),
                  ],
                  error.message
                );
                resolve(() => {});
              }
            );
          unsubscribeList.push(unsubscribe as () => void);
        }) as Promise<() => void>;
      })
    );
    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
      finalUnsubscribe.forEach((unsubscribe) => unsubscribe());
    };
  } catch (e) {
    callback([], e);
    return () => {};
  }
};

export const getServicesBookedAndCapacity = async (
  hospitalIds: string[],
  callback: (graphData: number[], error?: string) => void
) => {
  try {
    const bookedIndexedValueMap = {} as { [id: number]: number };
    const capacityIndexedValueMap = {} as { [id: number]: number };
    const unsubscribeList = [] as (() => void)[];
    const finalUnsubscribe = await Promise.all(
      _.chunk(hospitalIds, 10).map((hospitalIdsItem, index) => {
        return new Promise((resolve) => {
          const unsubscribe = firestore
            .collection(HOSPITAL_SERVICES)
            .where("hospitalId", "in", hospitalIdsItem)
            .onSnapshot(
              (serviceHospital) => {
                if (!serviceHospital.empty) {
                  const serviceHospitalIds = _.uniq(
                    serviceHospital.docs.map(
                      (serviceHospital) => serviceHospital.id
                    )
                  );
                  let schedules = [] as ServiceSchedule[];
                  const dayOfWeek = new Date().getDay();

                  //GET CAPACITY
                  const unsubscribeServiceHospital = _.chunk(
                    serviceHospitalIds,
                    10
                  ).map((ids) => {
                    return firestore
                      .collection(HOSPITAL_SERVICES_SCHEDULE)
                      .where("hospitalServiceId", "in", ids)
                      .where("dayOfWeek", "==", dayOfWeek)
                      .onSnapshot(
                        (serviceScheds) => {
                          if (!serviceScheds.empty) {
                            schedules = schedules.concat(
                              serviceScheds.docs.map((sched) => {
                                return {
                                  ...sched.data(),
                                  docId: sched.id,
                                } as ServiceSchedule;
                              })
                            );
                          }
                          schedules = _.uniqBy(
                            schedules,
                            (schedule) => schedule.docId
                          );
                          capacityIndexedValueMap[index] = _.sum(
                            schedules.map((sched) => sched.slots)
                          );
                          callback([
                            _.sum(Object.values(bookedIndexedValueMap)),
                            _.sum(Object.values(capacityIndexedValueMap)),
                          ]);
                        },
                        (error) => {
                          callback(
                            [
                              _.sum(Object.values(bookedIndexedValueMap)),
                              _.sum(Object.values(capacityIndexedValueMap)),
                            ],
                            error.message
                          );
                        }
                      );
                  });

                  //GET BOOKED
                  const dateMin = _.clone(new Date());
                  dateMin.setHours(0);
                  dateMin.setMinutes(0);
                  dateMin.setSeconds(0);
                  dateMin.setMilliseconds(0);

                  const dateMax = _.clone(new Date());
                  dateMax.setHours(23);
                  dateMax.setMinutes(59);
                  dateMax.setSeconds(59);
                  dateMax.setMilliseconds(59);
                  const unsubscribeAppointments = firestore
                    .collection(HOSPITAL_SERVICE_APPOINTMENTS)
                    .where("hospitalId", "in", hospitalIdsItem)
                    .where("appointmentDate", ">=", dateMin)
                    .where("appointmentDate", "<=", dateMax)
                    .where("isCancelled", "==", false)
                    .onSnapshot((appointments) => {
                      if (!appointments.empty) {
                        bookedIndexedValueMap[index] = appointments.docs.length;
                      }
                      callback([
                        _.sum(Object.values(bookedIndexedValueMap)),
                        _.sum(Object.values(capacityIndexedValueMap)),
                      ]);
                    });
                  resolve(() => {
                    unsubscribeServiceHospital.forEach((unsubscribe) => {
                      unsubscribe();
                    });
                    unsubscribeAppointments();
                  });
                } else {
                  callback([
                    _.sum(Object.values(bookedIndexedValueMap)),
                    _.sum(Object.values(capacityIndexedValueMap)),
                  ]);
                  resolve(() => {});
                }
              },
              (error) => {
                callback(
                  [
                    _.sum(Object.values(bookedIndexedValueMap)),
                    _.sum(Object.values(capacityIndexedValueMap)),
                  ],
                  error.message
                );
                resolve(() => {});
              }
            );
          unsubscribeList.push(unsubscribe as () => void);
        }) as Promise<() => void>;
      })
    );
    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
      finalUnsubscribe.forEach((unsubscribe) => unsubscribe());
    };
  } catch (e) {
    callback([], e);
    return () => {};
  }
};

export const getConsultationBookedVsCapacity = async (
  hospitalIds: string[],
  startDate: Date,
  endDate: Date,
  mode: "daily" | "weekly" | "monthly" | "range",
  callback: (
    booked: number[],
    capacity: number[],
    label: string[],
    error?: string
  ) => void
) => {
  try {
    const valueLabelMap = {} as {
      [label: string]: {
        [index: number]: { booked: number; capacity: number }[];
      };
    };
    const unsubscribeList = [] as (() => void)[];

    const dateMin = _.clone(startDate);
    dateMin.setHours(0);
    dateMin.setMinutes(0);
    dateMin.setSeconds(0);
    dateMin.setMilliseconds(0);

    const dateMax = _.clone(endDate);
    dateMax.setHours(23);
    dateMax.setMinutes(59);
    dateMax.setSeconds(59);
    dateMax.setMilliseconds(59);

    _.chunk(hospitalIds, 10).map((hospitalIdsItem, index) => {
      const unsubscribe = firestore
        .collection(CONSULTATIONS_BOOKED_AND_CAPACITY)
        .where("hospitalId", "in", hospitalIdsItem)
        .where("createdDt", ">=", dateMin)
        .where("createdDt", "<=", dateMax)
        .onSnapshot(
          (bookedVsCapacityData) => {
            if (!bookedVsCapacityData.empty) {
              const mappedBookedVsCapacity = bookedVsCapacityData.docs.map(
                (bookedVsCapacityItem) =>
                  bookedVsCapacityItem.data() as ConsultationBookedAndCapacity
              );

              if (mode === "monthly") {
                const groupedByMonth = _.groupBy(
                  mappedBookedVsCapacity,
                  (bookedVsCapacity) =>
                    bookedVsCapacity.createdDt.toDate().getMonth()
                );
                Object.values(groupedByMonth).forEach(
                  (groupedData, innerIndex) => {
                    const labelToPush = moment(
                      groupedData[0].createdDt.toDate()
                    ).format("MMMM");

                    if (
                      innerIndex === 0 &&
                      !_.isEmpty(valueLabelMap[labelToPush])
                    ) {
                      delete valueLabelMap[labelToPush][index];
                    }

                    if (
                      _.isEmpty(valueLabelMap[labelToPush]) ||
                      _.isEmpty(valueLabelMap[labelToPush][index])
                    ) {
                      if (!valueLabelMap.hasOwnProperty(labelToPush)) {
                        valueLabelMap[labelToPush] = {};
                      }
                      valueLabelMap[labelToPush][index] = [
                        {
                          booked: _.sumBy(groupedData, "booked"),
                          capacity: _.sumBy(groupedData, "capacity"),
                        },
                      ];
                    } else {
                      valueLabelMap[labelToPush][index].push({
                        booked: _.sumBy(groupedData, "booked"),
                        capacity: _.sumBy(groupedData, "capacity"),
                      });
                    }
                  }
                );
              } else if (mode === "weekly") {
                const groupedByMonth = _.groupBy(
                  mappedBookedVsCapacity,
                  (bookedVsCapacity) =>
                    moment(bookedVsCapacity.createdDt.toDate()).week()
                );
                Object.values(groupedByMonth).forEach(
                  (groupedData, innerIndex) => {
                    const labelToPush = `Week ${moment(
                      groupedData[0].createdDt.toDate()
                    ).week()}`;

                    if (
                      innerIndex === 0 &&
                      !_.isEmpty(valueLabelMap[labelToPush])
                    ) {
                      delete valueLabelMap[labelToPush][index];
                    }

                    if (
                      _.isEmpty(valueLabelMap[labelToPush]) ||
                      _.isEmpty(valueLabelMap[labelToPush][index])
                    ) {
                      if (!valueLabelMap.hasOwnProperty(labelToPush)) {
                        valueLabelMap[labelToPush] = {};
                      }
                      valueLabelMap[labelToPush][index] = [
                        {
                          booked: _.sumBy(groupedData, "booked"),
                          capacity: _.sumBy(groupedData, "capacity"),
                        },
                      ];
                    } else {
                      valueLabelMap[labelToPush][index].push({
                        booked: _.sumBy(groupedData, "booked"),
                        capacity: _.sumBy(groupedData, "capacity"),
                      });
                    }
                  }
                );
              } else {
                //DAILY

                mappedBookedVsCapacity.forEach(
                  (bookedVsCapacity, innerIndex) => {
                    const labelToPush = moment(
                      bookedVsCapacity.createdDt.toDate()
                    ).format("MM/DD/YYYY");

                    if (
                      innerIndex === 0 &&
                      !_.isEmpty(valueLabelMap[labelToPush])
                    ) {
                      delete valueLabelMap[labelToPush][index];
                    }

                    if (
                      _.isEmpty(valueLabelMap[labelToPush]) ||
                      _.isEmpty(valueLabelMap[labelToPush][index])
                    ) {
                      if (!valueLabelMap.hasOwnProperty(labelToPush)) {
                        valueLabelMap[labelToPush] = {};
                      }
                      valueLabelMap[labelToPush][index] = [
                        {
                          booked: bookedVsCapacity.booked,
                          capacity: bookedVsCapacity.capacity,
                        },
                      ];
                    } else {
                      valueLabelMap[labelToPush][index].push({
                        booked: bookedVsCapacity.booked,
                        capacity: bookedVsCapacity.capacity,
                      });
                    }
                  }
                );
              }

              callback(
                _.flatten(
                  Object.keys(valueLabelMap).map((key) => {
                    return _.sumBy(
                      _.flatten(Object.values(valueLabelMap[key])),
                      "booked"
                    );
                  })
                ),
                _.flatten(
                  Object.keys(valueLabelMap).map((key) => {
                    return _.sumBy(
                      _.flatten(Object.values(valueLabelMap[key])),
                      "capacity"
                    );
                  })
                ),
                Object.keys(valueLabelMap)
              );
            } else {
              callback(
                _.flatten(
                  Object.keys(valueLabelMap).map((key) => {
                    return _.sumBy(Object.values(valueLabelMap[key]), "booked");
                  })
                ),
                _.flatten(
                  Object.keys(valueLabelMap).map((key) => {
                    return _.sumBy(
                      Object.values(valueLabelMap[key]),
                      "capacity"
                    );
                  })
                ),
                Object.keys(valueLabelMap)
              );
            }
          },
          (error) => {
            callback(
              _.flatten(
                Object.keys(valueLabelMap).map((key) => {
                  return _.sumBy(Object.values(valueLabelMap[key]), "booked");
                })
              ),
              _.flatten(
                Object.keys(valueLabelMap).map((key) => {
                  return _.sumBy(Object.values(valueLabelMap[key]), "capacity");
                })
              ),
              Object.keys(valueLabelMap),
              error.message
            );
          }
        );
      unsubscribeList.push(unsubscribe);
    });

    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
    };
  } catch (e) {
    callback([], [], [], e);
    return () => {};
  }
};

export const getServicesBookedVsCapacity = async (
  hospitalIds: string[],
  startDate: Date,
  endDate: Date,
  mode: "daily" | "weekly" | "monthly" | "range",
  callback: (
    booked: number[],
    capacity: number[],
    label: string[],
    error?: string
  ) => void
) => {
  try {
    const valueLabelMap = {} as {
      [label: string]: {
        [index: number]: { booked: number; capacity: number }[];
      };
    };
    const unsubscribeList = [] as (() => void)[];

    const dateMin = _.clone(startDate);
    dateMin.setHours(0);
    dateMin.setMinutes(0);
    dateMin.setSeconds(0);
    dateMin.setMilliseconds(0);

    const dateMax = _.clone(endDate);
    dateMax.setHours(23);
    dateMax.setMinutes(59);
    dateMax.setSeconds(59);
    dateMax.setMilliseconds(59);

    _.chunk(hospitalIds, 10).map((hospitalIdsItem, index) => {
      const unsubscribe = firestore
        .collection(SERVICES_BOOKED_AND_CAPACITY)
        .where("hospitalId", "in", hospitalIdsItem)
        .where("createdDt", ">=", dateMin)
        .where("createdDt", "<=", dateMax)
        .onSnapshot(
          (bookedVsCapacityData) => {
            if (!bookedVsCapacityData.empty) {
              const mappedBookedVsCapacity = bookedVsCapacityData.docs.map(
                (bookedVsCapacityItem) =>
                  bookedVsCapacityItem.data() as ConsultationBookedAndCapacity
              );

              if (mode === "monthly") {
                const groupedByMonth = _.groupBy(
                  mappedBookedVsCapacity,
                  (bookedVsCapacity) =>
                    bookedVsCapacity.createdDt.toDate().getMonth()
                );
                Object.values(groupedByMonth).forEach(
                  (groupedData, innerIndex) => {
                    const labelToPush = moment(
                      groupedData[0].createdDt.toDate()
                    ).format("MMMM");

                    if (
                      innerIndex === 0 &&
                      !_.isEmpty(valueLabelMap[labelToPush])
                    ) {
                      delete valueLabelMap[labelToPush][index];
                    }

                    if (
                      _.isEmpty(valueLabelMap[labelToPush]) ||
                      _.isEmpty(valueLabelMap[labelToPush][index])
                    ) {
                      if (!valueLabelMap.hasOwnProperty(labelToPush)) {
                        valueLabelMap[labelToPush] = {};
                      }
                      valueLabelMap[labelToPush][index] = [
                        {
                          booked: _.sumBy(groupedData, "booked"),
                          capacity: _.sumBy(groupedData, "capacity"),
                        },
                      ];
                    } else {
                      valueLabelMap[labelToPush][index].push({
                        booked: _.sumBy(groupedData, "booked"),
                        capacity: _.sumBy(groupedData, "capacity"),
                      });
                    }
                  }
                );
              } else if (mode === "weekly") {
                const groupedByMonth = _.groupBy(
                  mappedBookedVsCapacity,
                  (bookedVsCapacity) =>
                    moment(bookedVsCapacity.createdDt.toDate()).week()
                );
                Object.values(groupedByMonth).forEach(
                  (groupedData, innerIndex) => {
                    const labelToPush = `Week ${moment(
                      groupedData[0].createdDt.toDate()
                    ).week()}`;

                    if (
                      innerIndex === 0 &&
                      !_.isEmpty(valueLabelMap[labelToPush])
                    ) {
                      delete valueLabelMap[labelToPush][index];
                    }

                    if (
                      _.isEmpty(valueLabelMap[labelToPush]) ||
                      _.isEmpty(valueLabelMap[labelToPush][index])
                    ) {
                      if (!valueLabelMap.hasOwnProperty(labelToPush)) {
                        valueLabelMap[labelToPush] = {};
                      }
                      valueLabelMap[labelToPush][index] = [
                        {
                          booked: _.sumBy(groupedData, "booked"),
                          capacity: _.sumBy(groupedData, "capacity"),
                        },
                      ];
                    } else {
                      valueLabelMap[labelToPush][index].push({
                        booked: _.sumBy(groupedData, "booked"),
                        capacity: _.sumBy(groupedData, "capacity"),
                      });
                    }
                  }
                );
              } else {
                //DAILY

                mappedBookedVsCapacity.forEach(
                  (bookedVsCapacity, innerIndex) => {
                    const labelToPush = moment(
                      bookedVsCapacity.createdDt.toDate()
                    ).format("MM/DD/YYYY");

                    if (
                      innerIndex === 0 &&
                      !_.isEmpty(valueLabelMap[labelToPush])
                    ) {
                      delete valueLabelMap[labelToPush][index];
                    }

                    if (
                      _.isEmpty(valueLabelMap[labelToPush]) ||
                      _.isEmpty(valueLabelMap[labelToPush][index])
                    ) {
                      if (!valueLabelMap.hasOwnProperty(labelToPush)) {
                        valueLabelMap[labelToPush] = {};
                      }
                      valueLabelMap[labelToPush][index] = [
                        {
                          booked: bookedVsCapacity.booked,
                          capacity: bookedVsCapacity.capacity,
                        },
                      ];
                    } else {
                      valueLabelMap[labelToPush][index].push({
                        booked: bookedVsCapacity.booked,
                        capacity: bookedVsCapacity.capacity,
                      });
                    }
                  }
                );
              }

              callback(
                _.flatten(
                  Object.keys(valueLabelMap).map((key) => {
                    return _.sumBy(
                      _.flatten(Object.values(valueLabelMap[key])),
                      "booked"
                    );
                  })
                ),
                _.flatten(
                  Object.keys(valueLabelMap).map((key) => {
                    return _.sumBy(
                      _.flatten(Object.values(valueLabelMap[key])),
                      "capacity"
                    );
                  })
                ),
                Object.keys(valueLabelMap)
              );
            } else {
              callback(
                _.flatten(
                  Object.keys(valueLabelMap).map((key) => {
                    return _.sumBy(Object.values(valueLabelMap[key]), "booked");
                  })
                ),
                _.flatten(
                  Object.keys(valueLabelMap).map((key) => {
                    return _.sumBy(
                      Object.values(valueLabelMap[key]),
                      "capacity"
                    );
                  })
                ),
                Object.keys(valueLabelMap)
              );
            }
          },
          (error) => {
            callback(
              _.flatten(
                Object.keys(valueLabelMap).map((key) => {
                  return _.sumBy(Object.values(valueLabelMap[key]), "booked");
                })
              ),
              _.flatten(
                Object.keys(valueLabelMap).map((key) => {
                  return _.sumBy(Object.values(valueLabelMap[key]), "capacity");
                })
              ),
              Object.keys(valueLabelMap),
              error.message
            );
          }
        );
      unsubscribeList.push(unsubscribe);
    });

    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
    };
  } catch (e) {
    callback([], [], [], e);
    return () => {};
  }
};

export const getServicesFutureBookedAndCapacity = async (
  hospitalIds: string[],
  startDate: Date,
  endDate: Date,
  mode: "daily" | "weekly" | "monthly" | "range",
  callback: (
    booked: number[],
    capacity: number[],
    label: string[],
    error?: string
  ) => void
) => {
  try {
    const indexValueMap = {} as {
      [index: number]: {
        booked: number[];
        capacity: number[];
        label: string[];
      };
    };
    const unsubscribeList = [] as (() => void)[];
    _.chunk(hospitalIds, 10).forEach(async (hospitalIdsItem, index) => {
      const unsubscribe = await new Promise(async (resolve) => {
        let unsubscribeAppointments = () => {};
        const unsubscribeCapacity = firestore
          .collection(HOSPITAL_SERVICES)
          .where("hospitalId", "in", hospitalIdsItem)
          .onSnapshot(
            async (hospitalService) => {
              if (!hospitalService.empty) {
                const hospitalServiceIds = _.uniq(
                  hospitalService.docs.map(
                    (doctorHospital) => doctorHospital.id
                  )
                );
                let schedules = [] as ServiceSchedule[];
                let daysOfWeek = {} as { [dayOfWeek: number]: number };
                // if 7 days = all day
                if (daysSinceDate(startDate, endDate) + 1 >= 7) {
                  daysOfWeek = {
                    0: 0,
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                  };
                } else {
                  for (
                    let day = moment(startDate);
                    day.isBefore(endDate) || isSameDate(day.toDate(), endDate);
                    day.add(1, "days")
                  ) {
                    daysOfWeek[day.toDate().getDay()] = 0;
                  }
                }
                // else input daysOfWeek to use "in"

                //GET CAPACITY
                await Promise.all(
                  _.chunk(hospitalServiceIds, 10).map((ids) => {
                    return new Promise((resolveHospitalService) => {
                      firestore
                        .collection(HOSPITAL_SERVICES_SCHEDULE)
                        .where("hospitalServiceId", "in", ids)
                        .onSnapshot(
                          (serviceSched) => {
                            if (!serviceSched.empty) {
                              schedules = schedules.concat(
                                _.compact(
                                  serviceSched.docs.map((sched) => {
                                    const serviceSchedData = {
                                      ...sched.data(),
                                      docId: sched.id,
                                    } as ServiceSchedule;
                                    // Object.keys(daysOfWeek)
                                    if (
                                      Object.keys(daysOfWeek).indexOf(
                                        serviceSchedData.dayOfWeek.toString()
                                      ) !== -1
                                    ) {
                                      return serviceSchedData;
                                    } else {
                                      return null;
                                    }
                                  })
                                )
                              );
                            }
                            schedules = _.uniqBy(
                              schedules,
                              (schedule) => schedule.docId
                            );
                            const groupedByDayOfWeekSched = _.groupBy(
                              schedules,
                              (schedules) => {
                                return schedules.dayOfWeek;
                              }
                            );

                            Object.keys(groupedByDayOfWeekSched).map(
                              (dayOfWeek) => {
                                daysOfWeek[parseInt(dayOfWeek)] = _.sumBy(
                                  groupedByDayOfWeekSched[parseInt(dayOfWeek)],
                                  (sched) => sched.slots
                                );
                              }
                            );
                            resolveHospitalService();
                          },
                          (error) => {
                            const labelValueMap = {} as {
                              [label: string]: {
                                booked: number[];
                                capacity: number[];
                              };
                            };
                            Object.values(indexValueMap).forEach(
                              (indexValueItem) => {
                                indexValueItem.label.forEach(
                                  (labelItem, index) => {
                                    if (
                                      !labelValueMap.hasOwnProperty(labelItem)
                                    ) {
                                      labelValueMap[labelItem] = {
                                        booked: [indexValueItem.booked[index]],
                                        capacity: [
                                          indexValueItem.capacity[index],
                                        ],
                                      };
                                    } else {
                                      labelValueMap[labelItem].booked.push(
                                        indexValueItem.booked[index]
                                      );
                                      labelValueMap[labelItem].capacity.push(
                                        indexValueItem.capacity[index]
                                      );
                                    }
                                  }
                                );
                              }
                            );
                            callback(
                              _.flatten(
                                Object.keys(labelValueMap).map((key) => {
                                  return _.sum(labelValueMap[key].booked);
                                })
                              ),
                              _.flatten(
                                Object.keys(labelValueMap).map((key) => {
                                  return _.sum(labelValueMap[key].capacity);
                                })
                              ),
                              Object.keys(labelValueMap),
                              error.message
                            );
                            resolveHospitalService();
                          }
                        );
                    });
                  })
                );

                //GET BOOKED
                const dateMin = _.clone(startDate);
                dateMin.setHours(0);
                dateMin.setMinutes(0);
                dateMin.setSeconds(0);
                dateMin.setMilliseconds(0);

                const dateMax = _.clone(endDate);
                dateMax.setHours(23);
                dateMax.setMinutes(59);
                dateMax.setSeconds(59);
                dateMax.setMilliseconds(59);
                unsubscribeAppointments = firestore
                  .collection(HOSPITAL_SERVICE_APPOINTMENTS)
                  .where("hospitalId", "in", hospitalIdsItem)
                  .where("appointmentDate", ">=", dateMin)
                  .where("appointmentDate", "<=", dateMax)
                  .where("isCancelled", "==", false)
                  .onSnapshot((appointments) => {
                    if (!appointments.empty) {
                      const serviceAppointments = appointments.docs.map(
                        (appointment) =>
                          appointment.data() as HospitalServiceAppointments
                      );
                      const booked = [] as number[];
                      const capacity = [] as number[];
                      const label = [] as string[];

                      if (mode === "monthly") {
                        const groupedByMonth = _.groupBy(
                          serviceAppointments,
                          (appointment) =>
                            appointment.appointmentDate.toDate().getMonth()
                        );
                        Object.values(groupedByMonth).forEach((groupedData) => {
                          booked.push(groupedData.length);
                          capacity.push(
                            _.sumBy(
                              groupedData,
                              (appointment) =>
                                daysOfWeek[
                                  appointment.appointmentDate.toDate().getDay()
                                ]
                            )
                          );
                          label.push(
                            moment(
                              groupedData[0].appointmentDate.toDate()
                            ).format("MMMM")
                          );
                        });
                      } else if (mode === "weekly") {
                        const groupedByWeek = _.groupBy(
                          serviceAppointments,
                          (appointment) =>
                            moment(appointment.appointmentDate.toDate()).week()
                        );
                        Object.values(groupedByWeek).forEach((groupedData) => {
                          booked.push(groupedData.length);
                          capacity.push(
                            _.sumBy(
                              groupedData,
                              (appointment) =>
                                daysOfWeek[
                                  appointment.appointmentDate.toDate().getDay()
                                ]
                            )
                          );
                          label.push(
                            `Week ${moment(
                              groupedData[0].appointmentDate.toDate()
                            ).week()}`
                          );
                        });
                      } else {
                        const groupedByDay = _.groupBy(
                          serviceAppointments,
                          (appointment) =>
                            appointment.appointmentDate.toDate().getDate() +
                            "_" +
                            appointment.appointmentDate.toDate().getMonth()
                        );
                        Object.values(groupedByDay).forEach((groupedData) => {
                          booked.push(groupedData.length);
                          capacity.push(
                            _.sumBy(
                              groupedData,
                              (appointment) =>
                                daysOfWeek[
                                  appointment.appointmentDate.toDate().getDay()
                                ]
                            )
                          );
                          label.push(
                            moment(
                              groupedData[0].appointmentDate.toDate()
                            ).format("MM/DD/YYYY")
                          );
                        });
                      }
                      indexValueMap[index] = {
                        booked,
                        capacity,
                        label,
                      };

                      // callback(booked, capacity, label);
                    } else {
                      indexValueMap[index] = {
                        booked: [],
                        capacity: [],
                        label: [],
                      };
                    }
                    const labelValueMap = {} as {
                      [label: string]: {
                        booked: number[];
                        capacity: number[];
                      };
                    };
                    Object.values(indexValueMap).forEach((indexValueItem) => {
                      indexValueItem.label.forEach((labelItem, index) => {
                        if (!labelValueMap.hasOwnProperty(labelItem)) {
                          labelValueMap[labelItem] = {
                            booked: [indexValueItem.booked[index]],
                            capacity: [indexValueItem.capacity[index]],
                          };
                        } else {
                          labelValueMap[labelItem].booked.push(
                            indexValueItem.booked[index]
                          );
                          labelValueMap[labelItem].capacity.push(
                            indexValueItem.capacity[index]
                          );
                        }
                      });
                    });
                    callback(
                      _.flatten(
                        Object.keys(labelValueMap).map((key) => {
                          return _.sum(labelValueMap[key].booked);
                        })
                      ),
                      _.flatten(
                        Object.keys(labelValueMap).map((key) => {
                          return _.sum(labelValueMap[key].capacity);
                        })
                      ),
                      Object.keys(labelValueMap)
                    );
                  });
              } else {
                const labelValueMap = {} as {
                  [label: string]: {
                    booked: number[];
                    capacity: number[];
                  };
                };
                Object.values(indexValueMap).forEach((indexValueItem) => {
                  indexValueItem.label.forEach((labelItem, index) => {
                    if (!labelValueMap.hasOwnProperty(labelItem)) {
                      labelValueMap[labelItem] = {
                        booked: [indexValueItem.booked[index]],
                        capacity: [indexValueItem.capacity[index]],
                      };
                    } else {
                      labelValueMap[labelItem].booked.push(
                        indexValueItem.booked[index]
                      );
                      labelValueMap[labelItem].capacity.push(
                        indexValueItem.capacity[index]
                      );
                    }
                  });
                });
                callback(
                  _.flatten(
                    Object.keys(labelValueMap).map((key) => {
                      return _.sum(labelValueMap[key].booked);
                    })
                  ),
                  _.flatten(
                    Object.keys(labelValueMap).map((key) => {
                      return _.sum(labelValueMap[key].capacity);
                    })
                  ),
                  Object.keys(labelValueMap)
                );
              }

              resolve(() => {
                unsubscribeCapacity();
                unsubscribeAppointments();
              });
            },
            (error) => {
              const labelValueMap = {} as {
                [label: string]: {
                  booked: number[];
                  capacity: number[];
                };
              };
              Object.values(indexValueMap).forEach((indexValueItem) => {
                indexValueItem.label.forEach((labelItem, index) => {
                  if (!labelValueMap.hasOwnProperty(labelItem)) {
                    labelValueMap[labelItem] = {
                      booked: [indexValueItem.booked[index]],
                      capacity: [indexValueItem.capacity[index]],
                    };
                  } else {
                    labelValueMap[labelItem].booked.push(
                      indexValueItem.booked[index]
                    );
                    labelValueMap[labelItem].capacity.push(
                      indexValueItem.capacity[index]
                    );
                  }
                });
              });
              callback(
                _.flatten(
                  Object.keys(labelValueMap).map((key) => {
                    return _.sum(labelValueMap[key].booked);
                  })
                ),
                _.flatten(
                  Object.keys(labelValueMap).map((key) => {
                    return _.sum(labelValueMap[key].capacity);
                  })
                ),
                Object.keys(labelValueMap),
                error.message
              );
              resolve(() => {});
            }
          );
      });

      unsubscribeList.push(unsubscribe as () => void);
    });
    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
    };
  } catch (e) {
    callback([], [], [], e);
    return () => {};
  }
};

export const getDoctorsFutureBookedAndCapacity = async (
  hospitalIds: string[],
  startDate: Date,
  endDate: Date,
  mode: "daily" | "weekly" | "monthly" | "range",
  callback: (
    booked: number[],
    capacity: number[],
    label: string[],
    error?: string
  ) => void
) => {
  try {
    const indexValueMap = {} as {
      [index: number]: {
        booked: number[];
        capacity: number[];
        label: string[];
      };
    };
    const unsubscribeList = [] as (() => void)[];
    _.chunk(hospitalIds, 10).forEach(async (hospitalIdsItem, index) => {
      const unsubscribe = await new Promise(async (resolve) => {
        let unsubscribeAppointments = () => {};
        const unsubscribeCapacity = firestore
          .collection(DOCTOR_HOSPITALS)
          .where("hospitalId", "in", hospitalIdsItem)
          .onSnapshot(
            async (doctorHospitals) => {
              if (!doctorHospitals.empty) {
                const doctorHospitalIds = _.uniq(
                  doctorHospitals.docs.map(
                    (doctorHospital) => doctorHospital.id
                  )
                );
                let schedules = [] as DoctorSchedule[];
                let daysOfWeek = {} as { [dayOfWeek: number]: number };
                // if 7 days = all day
                if (daysSinceDate(startDate, endDate) + 1 >= 7) {
                  daysOfWeek = {
                    0: 0,
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                  };
                } else {
                  for (
                    let day = moment(startDate);
                    day.isBefore(endDate) || isSameDate(day.toDate(), endDate);
                    day.add(1, "days")
                  ) {
                    daysOfWeek[day.toDate().getDay()] = 0;
                  }
                }
                // else input daysOfWeek to use "in"

                //GET CAPACITY
                await Promise.all(
                  _.chunk(doctorHospitalIds, 10).map((ids) => {
                    return new Promise((resolveDoctorHospital) => {
                      firestore
                        .collection(DOCTOR_SCHEDULE)
                        .where("doctorHospitalId", "in", ids)
                        .onSnapshot(
                          (doctorScheds) => {
                            if (!doctorScheds.empty) {
                              schedules = schedules.concat(
                                _.compact(
                                  doctorScheds.docs.map((sched) => {
                                    const doctorSchedData = {
                                      ...sched.data(),
                                      docId: sched.id,
                                    } as DoctorSchedule;
                                    // Object.keys(daysOfWeek)
                                    if (
                                      Object.keys(daysOfWeek).indexOf(
                                        doctorSchedData.dayOfWeek.toString()
                                      ) !== -1
                                    ) {
                                      return doctorSchedData;
                                    } else {
                                      return null;
                                    }
                                  })
                                )
                              );
                            }
                            schedules = _.uniqBy(
                              schedules,
                              (schedule) => schedule.docId
                            );
                            const groupedByDayOfWeekSched = _.groupBy(
                              schedules,
                              (schedules) => {
                                return schedules.dayOfWeek;
                              }
                            );

                            Object.keys(groupedByDayOfWeekSched).map(
                              (dayOfWeek) => {
                                daysOfWeek[parseInt(dayOfWeek)] = _.sumBy(
                                  groupedByDayOfWeekSched[parseInt(dayOfWeek)],
                                  (sched) => sched.slots
                                );
                              }
                            );
                            resolveDoctorHospital();
                          },
                          (error) => {
                            const labelValueMap = {} as {
                              [label: string]: {
                                booked: number[];
                                capacity: number[];
                              };
                            };
                            Object.values(indexValueMap).forEach(
                              (indexValueItem) => {
                                indexValueItem.label.forEach(
                                  (labelItem, index) => {
                                    if (
                                      !labelValueMap.hasOwnProperty(labelItem)
                                    ) {
                                      labelValueMap[labelItem] = {
                                        booked: [indexValueItem.booked[index]],
                                        capacity: [
                                          indexValueItem.capacity[index],
                                        ],
                                      };
                                    } else {
                                      labelValueMap[labelItem].booked.push(
                                        indexValueItem.booked[index]
                                      );
                                      labelValueMap[labelItem].capacity.push(
                                        indexValueItem.capacity[index]
                                      );
                                    }
                                  }
                                );
                              }
                            );
                            callback(
                              _.flatten(
                                Object.keys(labelValueMap).map((key) => {
                                  return _.sum(labelValueMap[key].booked);
                                })
                              ),
                              _.flatten(
                                Object.keys(labelValueMap).map((key) => {
                                  return _.sum(labelValueMap[key].capacity);
                                })
                              ),
                              Object.keys(labelValueMap),
                              error.message
                            );
                            resolveDoctorHospital();
                          }
                        );
                    });
                  })
                );

                //GET BOOKED
                const dateMin = _.clone(startDate);
                dateMin.setHours(0);
                dateMin.setMinutes(0);
                dateMin.setSeconds(0);
                dateMin.setMilliseconds(0);

                const dateMax = _.clone(endDate);
                dateMax.setHours(23);
                dateMax.setMinutes(59);
                dateMax.setSeconds(59);
                dateMax.setMilliseconds(59);
                unsubscribeAppointments = firestore
                  .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
                  .where("hospitalId", "in", hospitalIdsItem)
                  .where("appointmentDate", ">=", dateMin)
                  .where("appointmentDate", "<=", dateMax)
                  .where("isCancelled", "==", false)
                  .onSnapshot((appointments) => {
                    if (!appointments.empty) {
                      const doctorAppointments = appointments.docs.map(
                        (appointment) =>
                          appointment.data() as HospitalDoctorAppointments
                      );
                      const booked = [] as number[];
                      const capacity = [] as number[];
                      const label = [] as string[];

                      if (mode === "monthly") {
                        const groupedByMonth = _.groupBy(
                          doctorAppointments,
                          (appointment) =>
                            appointment.appointmentDate.toDate().getMonth()
                        );
                        Object.values(groupedByMonth).forEach((groupedData) => {
                          booked.push(groupedData.length);
                          capacity.push(
                            _.sumBy(
                              groupedData,
                              (appointment) =>
                                daysOfWeek[
                                  appointment.appointmentDate.toDate().getDay()
                                ]
                            )
                          );
                          label.push(
                            moment(
                              groupedData[0].appointmentDate.toDate()
                            ).format("MMMM")
                          );
                        });
                      } else if (mode === "weekly") {
                        const groupedByWeek = _.groupBy(
                          doctorAppointments,
                          (appointment) =>
                            moment(appointment.appointmentDate.toDate()).week()
                        );
                        Object.values(groupedByWeek).forEach((groupedData) => {
                          booked.push(groupedData.length);
                          capacity.push(
                            _.sumBy(
                              groupedData,
                              (appointment) =>
                                daysOfWeek[
                                  appointment.appointmentDate.toDate().getDay()
                                ]
                            )
                          );
                          label.push(
                            `Week ${moment(
                              groupedData[0].appointmentDate.toDate()
                            ).week()}`
                          );
                        });
                      } else {
                        const groupedByDay = _.groupBy(
                          doctorAppointments,
                          (appointment) =>
                            appointment.appointmentDate.toDate().getDate() +
                            "_" +
                            appointment.appointmentDate.toDate().getMonth()
                        );
                        Object.values(groupedByDay).forEach((groupedData) => {
                          booked.push(groupedData.length);
                          capacity.push(
                            _.sumBy(
                              groupedData,
                              (appointment) =>
                                daysOfWeek[
                                  appointment.appointmentDate.toDate().getDay()
                                ]
                            )
                          );
                          label.push(
                            moment(
                              groupedData[0].appointmentDate.toDate()
                            ).format("MM/DD/YYYY")
                          );
                        });
                      }
                      indexValueMap[index] = {
                        booked,
                        capacity,
                        label,
                      };

                      // callback(booked, capacity, label);
                    } else {
                      indexValueMap[index] = {
                        booked: [],
                        capacity: [],
                        label: [],
                      };
                    }
                    const labelValueMap = {} as {
                      [label: string]: {
                        booked: number[];
                        capacity: number[];
                      };
                    };
                    Object.values(indexValueMap).forEach((indexValueItem) => {
                      indexValueItem.label.forEach((labelItem, index) => {
                        if (!labelValueMap.hasOwnProperty(labelItem)) {
                          labelValueMap[labelItem] = {
                            booked: [indexValueItem.booked[index]],
                            capacity: [indexValueItem.capacity[index]],
                          };
                        } else {
                          labelValueMap[labelItem].booked.push(
                            indexValueItem.booked[index]
                          );
                          labelValueMap[labelItem].capacity.push(
                            indexValueItem.capacity[index]
                          );
                        }
                      });
                    });
                    callback(
                      _.flatten(
                        Object.keys(labelValueMap).map((key) => {
                          return _.sum(labelValueMap[key].booked);
                        })
                      ),
                      _.flatten(
                        Object.keys(labelValueMap).map((key) => {
                          return _.sum(labelValueMap[key].capacity);
                        })
                      ),
                      Object.keys(labelValueMap)
                    );
                  });
              } else {
                const labelValueMap = {} as {
                  [label: string]: {
                    booked: number[];
                    capacity: number[];
                  };
                };
                Object.values(indexValueMap).forEach((indexValueItem) => {
                  indexValueItem.label.forEach((labelItem, index) => {
                    if (!labelValueMap.hasOwnProperty(labelItem)) {
                      labelValueMap[labelItem] = {
                        booked: [indexValueItem.booked[index]],
                        capacity: [indexValueItem.capacity[index]],
                      };
                    } else {
                      labelValueMap[labelItem].booked.push(
                        indexValueItem.booked[index]
                      );
                      labelValueMap[labelItem].capacity.push(
                        indexValueItem.capacity[index]
                      );
                    }
                  });
                });
                callback(
                  _.flatten(
                    Object.keys(labelValueMap).map((key) => {
                      return _.sum(labelValueMap[key].booked);
                    })
                  ),
                  _.flatten(
                    Object.keys(labelValueMap).map((key) => {
                      return _.sum(labelValueMap[key].capacity);
                    })
                  ),
                  Object.keys(labelValueMap)
                );
              }

              resolve(() => {
                unsubscribeCapacity();
                unsubscribeAppointments();
              });
            },
            (error) => {
              const labelValueMap = {} as {
                [label: string]: {
                  booked: number[];
                  capacity: number[];
                };
              };
              Object.values(indexValueMap).forEach((indexValueItem) => {
                indexValueItem.label.forEach((labelItem, index) => {
                  if (!labelValueMap.hasOwnProperty(labelItem)) {
                    labelValueMap[labelItem] = {
                      booked: [indexValueItem.booked[index]],
                      capacity: [indexValueItem.capacity[index]],
                    };
                  } else {
                    labelValueMap[labelItem].booked.push(
                      indexValueItem.booked[index]
                    );
                    labelValueMap[labelItem].capacity.push(
                      indexValueItem.capacity[index]
                    );
                  }
                });
              });
              callback(
                _.flatten(
                  Object.keys(labelValueMap).map((key) => {
                    return _.sum(labelValueMap[key].booked);
                  })
                ),
                _.flatten(
                  Object.keys(labelValueMap).map((key) => {
                    return _.sum(labelValueMap[key].capacity);
                  })
                ),
                Object.keys(labelValueMap),
                error.message
              );
              resolve(() => {});
            }
          );
      });

      unsubscribeList.push(unsubscribe as () => void);
    });
    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
    };
  } catch (e) {
    callback([], [], [], e);
    return () => {};
  }
};
