import React from "react";

import "./ExecutiveAssistantCreatePassword.scss";
import CommonAuthPageContainer from "../../components/CommonAuthPageContainer/CommonAuthPageContainer";
import { ExecutiveAssistantCreatePasswordForm } from "../../components/ExecutiveAssistantCreatePasswordForm/ExecutiveAssistantCreatePasswordForm";
import { MBProps } from "../../interface";

class ExecutiveAssistantCreatePassword extends React.Component<MBProps> {
  render = () => {
    return (
      <>
        <CommonAuthPageContainer
          {...this.props}
          helmetTitle="MedBook - Executive Assistant Create Password"
          component={<ExecutiveAssistantCreatePasswordForm {...this.props} />}
        />
      </>
    );
  };
}

export default ExecutiveAssistantCreatePassword;
