import moment from "moment";
import { Helmet } from "react-helmet";
import React from "react";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonButton,
  IonCardHeader,
  IonItem,
  IonList,
  IonPopover,
  IonInput,
  IonModal,
  IonLoading,
} from "@ionic/react";

import "./DoctorAccount.scss";
import * as api from "../../api";
import * as services from "../../services";
import {
  DoctorAccountForm,
  DoctorScheduleOptions,
} from "../../components/DoctorAccountForm/DoctorAccountForm";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBDivider } from "../../components/MBDivider/MBDivider";
import { MBDialog } from "../../components/MBDialog/MBDialog";
import { MBProps } from "../../interface";
import {
  daysSinceDate,
  getServerTimestamp,
  isMobile,
  toDateTimeFromSecs,
} from "../../functions/common";
import {
  addOutline,
  ellipsisHorizontal,
  pencilOutline,
  trashBin,
} from "ionicons/icons";
import _ from "lodash";
import {
  MBDropdownSelect,
  MBDropdownSelectOption,
} from "../../components/MBDropdownSelect/MBDropdownSelect";
import {
  Doctor,
  DoctorHospitals,
  DoctorSchedule,
  ExecutiveAssistantDetails,
  Speciality,
} from "../../models";
import { ExecutiveAssistantFormDialog } from "../../components/ExecutiveAssistantFormDialog/ExecutiveAssistantFormDialog";
import { DAYS_OF_WEEK, MBCOLORS } from "../../constants/config";

class DoctorAccount extends React.Component<MBProps> {
  state = {
    error: "",
    loading: false,
    isEdit: false,
    showActionButtons: false,
    actionButtonsEvent: undefined as any,
    doctorHospitalOptions: null as MBDropdownSelectOption[] | null,
    executiveAssistants: null as ExecutiveAssistantDetails[] | null,
    isAddEditExecutiveDialogOpen: false,
    selectedDoctorHospital: {} as MBDropdownSelectOption,
    withLunchBreak: false,
    startBreakTime: new Date(2020, 1, 1, 12, 0, 0, 0),
    endBreakTime: new Date(2020, 1, 1, 13, 0, 0, 0),
    selectedEstimatedAppointmentMinuteLength: {
      id: "15",
      name: "15 Minutes",
    } as MBDropdownSelectOption,
    estimatedAppointmentMinuteLengthOptions: [
      { id: "15", name: "15 Minutes" },
      { id: "30", name: "30 Minutes" },
      { id: "45", name: "45 Minutes" },
      { id: "60", name: "60 Minutes" },
    ] as MBDropdownSelectOption[],
    outOfOffice: false,
    selectedSpecialities: [] as Speciality[],
    doctorHospitalSchedule: [] as DoctorScheduleOptions[],
    isDeleteProfileConfirmationDialogOpen: false,
    isOpenEditAccount: false,

    isRemoveAssistantConfirmationDialogOpen: false,
    willViewAssistant: false,
    toViewEditAssistantHospitalId: "",
    toViewEditAssistantId: "",
    toViewEditAssistantDoctorHospitalId: "",
  };

  componentDidMount = () => {
    const { authUser } = this.props;
    const doctor = authUser.userDetails as Doctor;
    this.getDoctorHospitals();
    const isOutOfOffice =
      doctor.outOfOfficeStartDate !== undefined &&
      !_.isNull(doctor.outOfOfficeStartDate) &&
      ((doctor.outOfOfficeEndDate !== undefined &&
        !_.isNull(doctor.outOfOfficeEndDate) &&
        daysSinceDate(doctor.outOfOfficeEndDate!.toDate()) <= 0) ||
        _.isEmpty(doctor.outOfOfficeEndDate));
    this.setState({ outOfOffice: isOutOfOffice });
  };
  getDoctorHospitals = async () => {
    this.setState({ loading: true });
    const { authUser } = this.props;
    try {
      const doctorHospitals = await services.getDoctorHospitals(authUser.uid);
      console.log("got doctor hospitals", doctorHospitals);
      if (!_.isEmpty(doctorHospitals)) {
        const options = doctorHospitals.map((doctorHospital) => {
          return {
            id: doctorHospital.docId || "",
            name: `${doctorHospital.hospitalDetails.hospitalName} - ${doctorHospital.hospitalDetails.department}`,
            metadata: {
              data: doctorHospital,
              hospital: doctorHospital.hospitalDetails,
              specialities: doctorHospital.specialities,
              schedules: doctorHospital.schedules,
              hospitalRepId: doctorHospital.hospitalDetails.docId,
              initialSetupDone: doctorHospital.initialSetupDone,
            },
          };
        });

        const doctorHospitalNotInitiallySetup = _.find(
          options,
          (doctorHospital) => !doctorHospital.metadata.initialSetupDone
        );
        const selectedDoctorHospital = !_.isEmpty(
          doctorHospitalNotInitiallySetup
        )
          ? doctorHospitalNotInitiallySetup
          : options[0];

        this.setState({
          doctorHospitalOptions: options,
          selectedDoctorHospital,
          loading: false,
        });
        this.selectDoctorHospital(selectedDoctorHospital!);
        this.getDoctorHospitalExecutiveAssistant(
          doctorHospitals.map((doctorHospital) => doctorHospital.docId || "")
        );

        if (!_.isEmpty(doctorHospitalNotInitiallySetup)) {
          this.setState({ isEdit: true });
        }
      } else {
        console.log(
          "DOCTOR HOSPITAL IS EMPTY THIS IS IMPOSIBBLE TO HAPPEN. REASON: BAD DATA"
        );
        this.setState({ doctorHospitalOptions: [] });
      }
    } catch (error) {
      this.setState({ doctorHospitalOptions: [], error, loading: false });
    }
  };

  getDoctorHospitalExecutiveAssistant = (doctorHospitalIds: string[]) => {
    services.getDoctorHospitalExecutiveAssistant(
      doctorHospitalIds,
      (executiveAssistants, error) => {
        if (_.isEmpty(error)) {
          this.setState({ executiveAssistants });
        } else {
          this.setState({ executiveAssistants: [], error });
        }
      }
    );
  };

  selectDoctorHospital = (option: MBDropdownSelectOption) => {
    const { estimatedAppointmentMinuteLengthOptions } = this.state;

    const doctorHospital = option.metadata.data as DoctorHospitals;
    if (!_.isEmpty(doctorHospital.startBreakTime)) {
      this.setState({
        startBreakTime: doctorHospital.startBreakTime!.toDate(),
        endBreakTime: doctorHospital.endBreakTime!.toDate(),
      });
    }
    if (!_.isEmpty(doctorHospital.startBreakTime)) {
      this.setState({ withLunchBreak: true });
    }
    this.setState({
      selectedEstimatedAppointmentMinuteLength: estimatedAppointmentMinuteLengthOptions.find(
        (estimateOption) => {
          return (
            estimateOption.id ===
            (option.metadata
              .schedules[0] as DoctorSchedule).estimatedAppointmentMinuteLength.toString()
          );
        }
      )!,
      selectedSpecialities: doctorHospital.specialities,
    });
    const doctorSchedule = Object.values(DAYS_OF_WEEK).map((day) => {
      const existingSchedule = _.find(
        option.metadata.schedules as DoctorSchedule[],
        (doctorSchedule) => doctorSchedule.dayOfWeek === day.day
      );

      if (!_.isEmpty(existingSchedule)) {
        return {
          ...existingSchedule,
          name: day.name,
          isDayChecked: true,
        } as DoctorScheduleOptions;
      } else {
        const dateToday = new Date();
        const startTime = _.cloneDeep(dateToday);
        startTime.setHours(6);
        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);

        const endTime = _.clone(dateToday);
        endTime.setHours(18);
        endTime.setMinutes(0);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);
        return {
          dayOfWeek: day.day,
          name: day.name,
          isDayChecked: false,
          slots: 0,
          startTime: getServerTimestamp(startTime),
          endTime: getServerTimestamp(endTime),
        } as DoctorScheduleOptions;
      }
    });
    console.log("WILL NOW EDIT THIS", doctorSchedule);
    this.setState({ doctorHospitalSchedule: doctorSchedule });
  };

  deleteProfile = async () => {
    const { authUser } = this.props;
    try {
      this.setState({ loading: true });
      await api.deleteUser(authUser.authEmail);
      await services.signOut();
      // analytics.logEvent("delete_doctor_account", {
      //   content_type: ANALYTICS_CONTENT_TYPES.doctorAccount.type,
      //   hospital: selectedDoctorHospital.name,
      // });
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  };

  removeAssistant = async (
    toViewEditAssistantId: string,
    toViewEditAssistantDoctorHospitalId: string
  ) => {
    try {
      this.setState({ loading: true });
      console.log(
        "SHOULD REMOVE",
        toViewEditAssistantId,
        toViewEditAssistantDoctorHospitalId
      );

      await services.removeExecutiveAssistant(
        toViewEditAssistantId,
        toViewEditAssistantDoctorHospitalId
      );

      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  };

  render = () => {
    const {
      isOpenEditAccount,
      doctorHospitalOptions,
      executiveAssistants,
      isAddEditExecutiveDialogOpen,
      showActionButtons,
      actionButtonsEvent,
      selectedDoctorHospital,
      selectedSpecialities,
      doctorHospitalSchedule,
      isDeleteProfileConfirmationDialogOpen,

      isRemoveAssistantConfirmationDialogOpen,
      willViewAssistant,
      toViewEditAssistantHospitalId,
      loading,
      toViewEditAssistantId,
      toViewEditAssistantDoctorHospitalId,
    } = this.state;
    console.log("selectedDoctorHospital -- ", selectedDoctorHospital);
    const { authUser } = this.props;
    const doctor = authUser.userDetails as Doctor;
    const isDoctorAccountFormOpen =
      !_.isEmpty(selectedDoctorHospital) &&
      selectedDoctorHospital.metadata.initialSetupDone === false
        ? true
        : isOpenEditAccount;
    return (
      <>
        <Helmet>
          <title>MedBook - Doctor Account</title>
        </Helmet>
        <MBContainer {...this.props} activePage="account">
          {!loading && (
            <IonCard
              className={`doctor-account-detail-card ${
                isMobile() && "mobile"
              } ion-no-margin`}
              color={MBCOLORS.tertiary}
            >
              <IonCardContent
                className={`doctor-account-detail-card-content ${
                  isMobile() && "mobile"
                } ion-no-padding`}
              >
                <div className="doctor-account-detail-card-content-details-container">
                  <IonGrid className="doctor-account-detail-card-grid-detail ion-no-padding ion-no-margin">
                    <IonRow className="doctor-account-detail-card-row ion-no-padding ion-no-margin">
                      <IonCol
                        size={isMobile() ? "12" : "4"}
                        className={`doctor-account-detail-card-col-icons ${
                          isMobile() && "mobile"
                        } ion-no-padding ion-no-margin`}
                      >
                        <IonIcon className="doctor-account-detail-card-details-icon ion-no-margin" />
                        {isMobile() && (
                          <IonButton
                            className="doctor-account-detail-card-action-button"
                            disabled={
                              _.isEmpty(selectedDoctorHospital) ||
                              _.isEmpty(doctorHospitalOptions)
                            }
                            color={MBCOLORS.medium}
                            onClick={(e) => {
                              this.setState({
                                willViewAssistant: false,
                                toViewEditAssistantHospitalId: "",
                                toViewEditAssistantId: "",
                                actionButtonsEvent: e.nativeEvent,
                                showActionButtons: true,
                              });
                            }}
                          >
                            <IonIcon icon={ellipsisHorizontal} />
                          </IonButton>
                        )}
                      </IonCol>
                      <IonCol
                        size={isMobile() ? "12" : "7"}
                        className="doctor-account-detail-card-col-details ion-no-padding ion-no-margin"
                      >
                        <div className="doctor-account-detail-card-details-container">
                          <IonLabel className="mb-h1 dark-blue ion-no-padding ion-no-margin">
                            {`${doctor.firstName} ${doctor.lastName}`}
                          </IonLabel>
                          <div className="doctor-account-detail-card-sub-detail-container">
                            <div className="detail-container">
                              <IonIcon className="md-detail-card-sub-detail-icon mail" />
                              <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                                {doctor.emailAddress}
                              </IonLabel>
                            </div>
                            <div className="detail-container">
                              <IonIcon className="md-detail-card-sub-detail-icon mobile" />
                              <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                                {doctor.phoneNumber}
                              </IonLabel>
                            </div>
                            <div className="doctor-hospital-dropdown-container">
                              <MBDropdownSelect
                                label="Hospital"
                                value={
                                  !_.isEmpty(selectedDoctorHospital)
                                    ? selectedDoctorHospital.name
                                    : ""
                                }
                                onSelectItem={(selectedDoctorHospitalNew) => {
                                  // setSelectedDoctorHospital(
                                  //   selectedDoctorHospitalNew
                                  // );
                                  this.setState({
                                    selectedDoctorHospital: selectedDoctorHospitalNew,
                                  });
                                  if (
                                    !_.isEmpty(selectedDoctorHospital) &&
                                    selectedDoctorHospital.id !==
                                      selectedDoctorHospitalNew.id
                                  ) {
                                    this.selectDoctorHospital(
                                      selectedDoctorHospitalNew
                                    );
                                  }
                                }}
                                selectFromOptionsRequired={true}
                                options={doctorHospitalOptions}
                                emptyOptionLabel={"No assigned hospital..."}
                                disabled={_.isNull(doctorHospitalOptions)}
                                disableTab={true}
                              />
                            </div>
                          </div>
                        </div>
                      </IonCol>
                      {!isMobile() && (
                        <IonCol
                          size="1"
                          className="doctor-account-detail-card-col-button ion-no-padding ion-no-margin"
                        >
                          <IonButton
                            className="doctor-account-detail-card-action-button"
                            disabled={
                              _.isEmpty(selectedDoctorHospital) ||
                              _.isEmpty(doctorHospitalOptions)
                            }
                            color={MBCOLORS.medium}
                            onClick={(e) => {
                              this.setState({
                                willViewAssistant: false,
                                toViewEditAssistantHospitalId: "",
                                toViewEditAssistantId: "",
                                actionButtonsEvent: e.nativeEvent,
                                showActionButtons: true,
                              });
                            }}
                          >
                            <IonIcon icon={ellipsisHorizontal} />
                          </IonButton>
                        </IonCol>
                      )}
                    </IonRow>
                    <IonRow className="doctor-account-detail-speciality-row">
                      <IonCol
                        className="doctor-account-detail-container-col ion-no-padding"
                        size="6"
                      >
                        <IonLabel className="mb-h4  dark-blue bold">
                          Speciality
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        className="doctor-account-detail-container-col ion-no-padding"
                        size="6"
                      >
                        <IonLabel className="mb-h4 dark-blue">
                          Price (PHP)
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                    {selectedSpecialities.map((speciality, index) => {
                      return (
                        <IonRow
                          key={`row_speciality_${index}`}
                          className="doctor-account-detail-speciality-row"
                        >
                          <IonCol
                            className="doctor-account-detail-container-col ion-align-self-center ion-no-padding"
                            size="6"
                          >
                            <IonLabel className="mb-body">
                              {speciality.name}
                            </IonLabel>
                          </IonCol>
                          <IonCol
                            className="doctor-account-detail-container-col ion-no-padding"
                            size="6"
                          >
                            <IonInput
                              className="doctor-account-detail-container-fee-input mb-body"
                              type="number"
                              min={"0"}
                              readonly={true}
                              value={speciality.fee}
                              onIonChange={(fee) => {
                                if (!!fee.detail.value) {
                                  const newSelectedSpecialities = _.cloneDeep(
                                    selectedSpecialities
                                  );
                                  newSelectedSpecialities[index].fee = parseInt(
                                    fee.detail.value!
                                  );

                                  // this.setState(newSelectedSpecialities);
                                }
                              }}
                            ></IonInput>
                          </IonCol>
                        </IonRow>
                      );
                    })}
                  </IonGrid>
                  <MBDivider />
                  <IonGrid
                    className={`doctor-account-detail-card-grid-schedule ${
                      isMobile() && "mobile"
                    } ion-no-padding ion-no-margin`}
                  >
                    <IonRow className="doctor-account-detail-card-row-schedule-header ion-no-padding ion-no-margin">
                      <IonCol size="4">
                        <IonLabel
                          className={`${
                            isMobile() ? "mb-h5" : "mb-h4"
                          } bold ion-no-padding ion-no-margin`}
                        >
                          Schedule
                        </IonLabel>
                      </IonCol>
                      <IonCol size="3">
                        <IonLabel
                          className={`${
                            isMobile() ? "mb-h5" : "mb-h4"
                          } normal ion-no-padding ion-no-margin`}
                        >
                          Start Time
                        </IonLabel>
                      </IonCol>
                      <IonCol size="3">
                        <IonLabel
                          className={`${
                            isMobile() ? "mb-h5" : "mb-h4"
                          } normal ion-no-padding ion-no-margin`}
                        >
                          End Time
                        </IonLabel>
                      </IonCol>
                      <IonCol size="1.5">
                        <IonLabel
                          className={`${
                            isMobile() ? "mb-h5" : "mb-h4"
                          } normal ion-no-padding ion-no-margin`}
                        >
                          Slots
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className="doctor-account-detail-card-row-schedule-data ion-no-padding ion-no-margin">
                      <IonCol
                        className="doctor-account-detail-card-col-schedule-day"
                        size="4"
                      >
                        {!!doctorHospitalSchedule &&
                          doctorHospitalSchedule.map((scheduleDay) => {
                            return (
                              <div
                                className={`doctor-account-detail-card-schedule-day-container ${
                                  isMobile() && "mobile"
                                } ${
                                  scheduleDay.slots !== 0 ? "" : "not-available"
                                } ion-no-padding ion-no-margin`}
                              >
                                <IonLabel
                                  className={`doctor-account-detail-card-schedule-label ${
                                    isMobile() ? "mb-h5" : "mb-h2"
                                  } ${
                                    scheduleDay.slots !== 0
                                      ? "primary"
                                      : "light"
                                  } normal ion-no-padding ion-no-margin`}
                                >
                                  {scheduleDay.name}
                                </IonLabel>
                              </div>
                            );
                          })}
                      </IonCol>
                      <IonCol
                        size="3"
                        className="doctor-account-detail-card-col-schedule-time-slot ion-no-padding ion-no-margin"
                      >
                        {doctorHospitalSchedule.map((scheduleDay) => {
                          return (
                            <IonLabel
                              className={`doctor-account-detail-card-schedule-time-slot-label ${
                                isMobile() ? "mobile mb-h5" : "mb-h2"
                              } ${!!scheduleDay.slots ? "" : "light"} normal`}
                            >
                              {!!scheduleDay.slots
                                ? moment(
                                    toDateTimeFromSecs(
                                      (scheduleDay.startTime as any).seconds
                                    )
                                  ).format("hh:mm A")
                                : "0:00 AM"}
                            </IonLabel>
                          );
                        })}
                      </IonCol>
                      <IonCol
                        size="3"
                        className="doctor-account-detail-card-col-schedule-time-slot ion-no-padding ion-no-margin"
                      >
                        {doctorHospitalSchedule.map((scheduleDay) => {
                          return (
                            <IonLabel
                              className={`doctor-account-detail-card-schedule-time-slot-label ${
                                isMobile() ? "mobile mb-h5" : "mb-h2"
                              } ${!!scheduleDay.slots ? "" : "light"} normal`}
                            >
                              {!!scheduleDay.slots
                                ? moment(
                                    toDateTimeFromSecs(
                                      (scheduleDay.endTime as any).seconds
                                    )
                                  ).format("hh:mm A")
                                : "0:00 AM"}
                            </IonLabel>
                          );
                        })}
                      </IonCol>
                      <IonCol
                        size="1.5"
                        className="doctor-account-detail-card-col-schedule-time-slot ion-no-padding ion-no-margin"
                      >
                        {doctorHospitalSchedule.map((scheduleDay) => {
                          return (
                            <IonLabel
                              className={`doctor-account-detail-card-schedule-time-slot-label ${
                                isMobile() ? "mobile mb-h5" : "mb-h2"
                              }${!!scheduleDay.slots ? "" : "light"} normal`}
                            >
                              {scheduleDay.slots}
                            </IonLabel>
                          );
                        })}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </IonCardContent>
            </IonCard>
          )}

          {!loading && (
            <IonCard
              className={`doctor-account-assistants-card ${
                isMobile() && "mobile"
              } ion-no-margin`}
            >
              <IonCardHeader className="doctor-account-assistants-card-header ion-no-padding">
                <IonLabel className="mb-body bold">Doctor's Assistant</IonLabel>

                <IonButton
                  onClick={() => {
                    this.setState({
                      isAddEditExecutiveDialogOpen: true,
                      toViewEditAssistantHospitalId: "",
                      toViewEditAssistantId: "",
                    });
                  }}
                  disabled={
                    _.isEmpty(doctorHospitalOptions) ||
                    _.isNull(executiveAssistants)
                  }
                  color={MBCOLORS.primary}
                  className="doctor-account-assistants-card-add-button mb-body white bold ion-text-capitalize"
                >
                  <IonIcon icon={addOutline} />
                  Add
                </IonButton>
              </IonCardHeader>
              <IonCardContent className="doctor-account-assistants-card-content">
                {!_.isEmpty(executiveAssistants) ? (
                  <IonGrid className="ion-no-margin ion-no-padding">
                    {executiveAssistants!.map((executiveAssistant, index) => {
                      return (
                        <IonRow key={`assistant_${index}`}>
                          <IonCol
                            size="5"
                            className="doctor-account-assistants-card-col ion-no-margin ion-no-padding"
                          >
                            <IonLabel className="mb-body">
                              {`${executiveAssistant.executiveAssistantDetails.firstName} ${executiveAssistant.executiveAssistantDetails.lastName}`}
                            </IonLabel>
                          </IonCol>
                          <IonCol
                            size="5"
                            className="doctor-account-assistants-card-col ion-no-margin ion-no-padding"
                          >
                            <IonLabel className="mb-body">
                              {
                                _.find(
                                  doctorHospitalOptions,
                                  (doctorHospital) =>
                                    doctorHospital.id ===
                                    executiveAssistant
                                      .executiveAssistantDoctorHospitalDetails
                                      .doctorHospitalId
                                )!.metadata.hospital.hospitalName
                              }
                            </IonLabel>
                          </IonCol>
                          <IonCol
                            size="2"
                            className="doctor-account-assistants-card-col button ion-no-margin ion-no-padding"
                          >
                            <IonButton
                              className="doctor-account-detail-card-action-button"
                              fill="clear"
                              onClick={(e) => {
                                this.setState({
                                  willViewAssistant: true,
                                  toViewEditAssistantHospitalId:
                                    executiveAssistant.executiveAssistantDetails
                                      .hospitalUID,
                                  toViewEditAssistantId:
                                    executiveAssistant.executiveAssistantDetails
                                      .docId,
                                  toViewEditAssistantDoctorHospitalId:
                                    executiveAssistant
                                      .executiveAssistantDoctorHospitalDetails
                                      .doctorHospitalId,
                                  actionButtonsEvent: e.nativeEvent,
                                  showActionButtons: true,
                                });
                              }}
                            >
                              <IonIcon
                                color={MBCOLORS.dark}
                                icon={ellipsisHorizontal}
                              />
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      );
                    })}
                  </IonGrid>
                ) : (
                  <IonLabel className="mb-body bold ion-text-center">
                    {" "}
                    No Results...
                  </IonLabel>
                )}
              </IonCardContent>
            </IonCard>
          )}
        </MBContainer>

        {!_.isEmpty(doctorHospitalOptions) &&
          isAddEditExecutiveDialogOpen &&
          !_.isNull(executiveAssistants) && (
            <ExecutiveAssistantFormDialog
              {...this.props}
              isOpen={isAddEditExecutiveDialogOpen}
              onCancel={() => {
                this.setState({
                  isAddEditExecutiveDialogOpen: false,
                });
              }}
              doctorHospitalOptions={doctorHospitalOptions!}
              existingExecutiveAssistantIds={executiveAssistants.map(
                (assistant) => assistant.executiveAssistantDetails.docId || ""
              )}
              {...(!_.isEmpty(toViewEditAssistantHospitalId) && {
                existingExecutiveAssistantHospitalID: toViewEditAssistantHospitalId,
              })}
            />
          )}

        {!!isDoctorAccountFormOpen &&
          !_.isEmpty(selectedDoctorHospital) &&
          !_.isEmpty(doctorHospitalOptions) && (
            <IonModal
              isOpen={isDoctorAccountFormOpen}
              onDidDismiss={() => {
                this.getDoctorHospitals();
                this.setState({
                  isOpenEditAccount: false,
                });
              }}
              cssClass="hospital-account-form-modal"
            >
              <DoctorAccountForm
                {...this.props}
                onBack={() => {
                  this.getDoctorHospitals();
                  this.setState({
                    isOpenEditAccount: false,
                  });
                }}
                inititalSelectedDoctorHospital={selectedDoctorHospital}
                initialDoctorHospitalOptions={doctorHospitalOptions!}
                isOpenEditAccount={isDoctorAccountFormOpen}
              />
            </IonModal>
          )}

        <MBDialog
          isOpen={isDeleteProfileConfirmationDialogOpen}
          icon="warning"
          title="You are about to delete your profile"
          message="Are you sure you want to delete your profile?"
          onDidDismiss={() => {
            this.setState({ isDeleteProfileConfirmationDialogOpen: false });
          }}
          onApprove={() => {
            this.deleteProfile();
            this.setState({ isDeleteProfileConfirmationDialogOpen: false });
          }}
          onDecline={() => {
            this.setState({ isDeleteProfileConfirmationDialogOpen: false });
          }}
        />

        <MBDialog
          isOpen={isRemoveAssistantConfirmationDialogOpen}
          icon="warning"
          title="You are about to remove this executive assistant"
          message="Are you sure you want to remove this executive assistant?"
          onDidDismiss={() => {
            this.setState({ isRemoveAssistantConfirmationDialogOpen: false });
          }}
          onApprove={() => {
            // this.deleteProfile();
            this.removeAssistant(
              this.state.toViewEditAssistantId,
              this.state.toViewEditAssistantDoctorHospitalId
            );
            this.setState({ isRemoveAssistantConfirmationDialogOpen: false });
          }}
          onDecline={() => {
            this.setState({ isRemoveAssistantConfirmationDialogOpen: false });
          }}
        />

        <IonPopover
          mode="md"
          isOpen={showActionButtons}
          event={actionButtonsEvent}
          cssClass={`doctor-account-detail-card-popover ${
            isMobile() && "mobile"
          } noselect ion-no-padding`}
          showBackdrop={false}
          onDidDismiss={() => {
            this.setState({
              showActionButtons: false,
            });
          }}
        >
          <div className="doctor-account-detail-card-list-container">
            <IonList className="doctor-account-detail-card-list">
              <div className="doctor-account-detail-card-item-container">
                <IonItem
                  className="doctor-account-detail-card-dropdown-modal-item ion-justify-content-center"
                  button={true}
                  lines="none"
                  onClick={() => {
                    if (!willViewAssistant) {
                      this.setState({
                        isOpenEditAccount: true,
                        showActionButtons: false,
                      });
                    } else {
                      this.setState({
                        isAddEditExecutiveDialogOpen: true,
                        showActionButtons: false,
                      });
                    }
                  }}
                >
                  <IonLabel className="doctor-account-detail-card-dropdown-label mb-h5">
                    <IonIcon
                      className="doctor-account-detail-card-dropdown-modal-item-icon"
                      icon={pencilOutline}
                      slot="start"
                    />
                    {!willViewAssistant ? "Edit" : "View"}
                  </IonLabel>
                </IonItem>
                <IonItem
                  className="doctor-account-detail-card-dropdown-modal-item ion-justify-content-center"
                  button={true}
                  onClick={() => {
                    if (!willViewAssistant) {
                      this.setState({
                        isDeleteProfileConfirmationDialogOpen: true,
                        showActionButtons: false,
                      });
                    } else {
                      this.setState({
                        isRemoveAssistantConfirmationDialogOpen: true,
                        showActionButtons: false,
                      });
                    }
                  }}
                  lines="none"
                >
                  <IonLabel className="doctor-account-detail-card-dropdown-label mb-h5">
                    <IonIcon
                      className="doctor-account-detail-card-dropdown-modal-item-icon"
                      icon={trashBin}
                      slot="start"
                    />
                    Remove
                  </IonLabel>
                </IonItem>
              </div>
            </IonList>
          </div>
        </IonPopover>
        <IonLoading
          translucent={true}
          mode="ios"
          isOpen={loading}
          message={"Loading.."}
        />
      </>
    );
  };
}

export default DoctorAccount;
