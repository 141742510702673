import moment from "moment";
import * as _ from "lodash";

import * as api from "../api";
import * as email from "../functions/email";
import * as routes from "../constants/routes";
import * as sms from "../functions/sms";
import { firestore } from "../firebase";
import {
  doSendVerificationEmail,
  sendEmail,
  sendEmailToSupport,
} from "./users";
import {
  getServerTimestamp,
  isHistoricalDate,
  isToday,
} from "../functions/common";
import { DaysOfWeek, Department, HospitalServiceSchedule } from "../interface";
import {
  Coordinates,
  HospitalService,
  DoctorHospitals,
  ServiceSchedule,
  HospitalServiceAppointmentsOLD,
  Patient,
  Doctor,
  Hospital,
  HospitalDoctorAppointmentsOLD,
  HospitalRepInvitation,
  TimeKeeping,
  HospitalNetwork,
} from "../models";
import { USER_TYPES, RESOURCE_TYPES } from "../constants/config";
import {
  ACCOUNT_TYPES,
  HOSPITALS,
  HOSPITAL_SERVICES,
  HOSPITAL_SERVICES_SCHEDULE,
  DOCTOR_HOSPITALS,
  DOCTOR_SCHEDULE,
  SERVICES,
  HOSPITAL_SERVICE_APPOINTMENTS,
  PATIENTS,
  DOCTORS,
  HOSPITAL_DOCTOR_APPOINTMENTS,
  HOSPITAL_REPRESENTATIVES_INVITATION,
  HOSPITAL_TIME_KEEPING,
  HOSPITAL_NETWORK,
} from "../constants/dbCollections";

export const hospitalDepartmentExisting = async (
  name: string,
  department: string
) => {
  const result = await firestore
    .collection(HOSPITALS)
    .where("hospitalName", "==", name)
    .get();

  const hospitals = result.docs.filter((hospital) => {
    const hospitalData = hospital.data() as Hospital;
    return (
      hospitalData.isVerified &&
      hospitalData.department.trim().toLowerCase() ===
        department.trim().toLowerCase()
    );
  });

  return hospitals.length > 0;
};

export const getHospitalDepartmentWithName = async (name: string) => {
  const result = await firestore
    .collection(HOSPITALS)
    .where("hospitalName", "==", name)
    .get();

  const hospitals = result.docs.map((hospital) => {
    const hospitalData = { docId: hospital.id, ...hospital.data() } as Hospital;
    if (hospitalData.isVerified) {
      return hospitalData;
    } else {
      return null;
    }
  });

  return _.compact(hospitals);
};

export const getHospitalNetworkWithId = async (docId: string) => {
  const result = await firestore.collection(HOSPITAL_NETWORK).doc(docId).get();
  if (result.exists) {
    return { docId: result.id, ...result.data() } as HospitalNetwork;
  } else {
    return null;
  }
};

export const createHospitalNetwork = async (
  hospitalName: string,
  location: Coordinates,
  province: string,
  cityMunicipality: string
) => {
  await firestore.collection(HOSPITAL_NETWORK).add({
    hospitalName,
    location,
    province,
    cityMunicipality,
    createdAt: getServerTimestamp(),
  });
};

export const getHospitalNetwork = (
  callback: (hospitalList: HospitalNetwork[]) => void
) => {
  try {
    firestore.collection(HOSPITAL_NETWORK).onSnapshot(
      (hospitalList) => {
        if (!hospitalList.empty) {
          callback(
            hospitalList.docs.map((hospital) => {
              return {
                ...hospital.data(),
                docId: hospital.id,
              } as HospitalNetwork;
            })
          );
        } else {
          callback([]);
        }
      },
      (error) => {
        callback([]);
      }
    );
  } catch (e) {
    console.log("ERROR IN getHospitalNetwork: ", e);
    callback([]);
  }
};

export const getHospital = async (hospitalId: string) => {
  const result = await firestore.collection(HOSPITALS).doc(hospitalId).get();
  if (result.exists) {
    return {
      docId: result.id,
      ...result.data(),
    } as Hospital;
  } else {
    return {} as Hospital;
  }
};

export const getAllServices = async (
  callback: (services: HospitalService[]) => void
) => {
  firestore.collection(HOSPITAL_SERVICES).onSnapshot(
    (snapshot) => {
      if (snapshot.docs.length > 0) {
        callback(
          snapshot.docs.map((doc) => {
            const result = doc.data();
            return { ...result, docId: doc.id } as HospitalService;
          })
        );
      } else {
        callback([]);
      }
    },
    (error) => {
      callback([]);
    }
  );
};

export const createNewHospital = async (
  docId: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: string,
  department: string,
  governmentId: string,
  identificationCard: string,
  hospitalName: string,
  province: string,
  cityMunicipality: string,
  location: Coordinates,
  isVerified = false
) => {
  const machineDetails = await api.getUserMachineDetails();

  await firestore.collection(HOSPITALS).doc(docId).set({
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    hospitalName,
    province,
    cityMunicipality,
    location,
    department,
    governmentId,
    identificationCard,
    isVerified,
    createdAt: getServerTimestamp(),
    updatedAt: getServerTimestamp(),
    machineDetails,
  });

  await firestore.collection(ACCOUNT_TYPES).doc(docId).update({
    userType: USER_TYPES.hospitals.id,
  });

  if (!isVerified) {
    await doSendVerificationEmail();
  }

  const { subject, message } = email.hospitalRegisterVerificationRequest(
    `${firstName} ${lastName}`,
    `${hospitalName} - ${department}`,
    emailAddress,
    phoneNumber
  );

  await sendEmailToSupport(subject, message);
};

export const getHospitalWithRep = async (
  hospitalRepId: string
): Promise<Hospital | null> => {
  const result = await firestore.collection(HOSPITALS).doc(hospitalRepId).get();
  if (result.exists) {
    return { ...result.data(), docId: result.id } as Hospital;
  } else {
    return null;
  }
};

export const getHospitalRep = async (hospitalId: string): Promise<Hospital> => {
  const result = await firestore.collection(HOSPITALS).doc(hospitalId).get();
  if (result.exists) {
    return { ...result.data(), docId: result.id } as Hospital;
  } else {
    return {} as Hospital;
  }
};

// export const createTimeKeepingAccount = async (
//   hospitalId: string,
//   firstName: string,
//   lastName: string,
//   phoneNumber: string,
//   designation: string,
//   userName: string,
//   password: string
// ) => {
//   const machineDetails = await api.getUserMachineDetails();

//   const result = await firestore.collection(HOSPITAL_TIME_KEEPING).add({
//     hospitalId,
//     firstName,
//     lastName,
//     phoneNumber,
//     designation,
//     userName,
//     password,
//     createdAt: new Date(),
//     machineDetails,
//   });

//   return result.id;
// };

export const createService = async (
  hospitalId: string,
  treatmentName: string,
  contactNumber: string,
  secondaryContactNumber: string,
  location: string,
  fee: number,
  maxNoOfPatient: number,
  isAmbulatory: boolean
) => {
  const result = await firestore.collection(HOSPITAL_SERVICES).add({
    hospitalId,
    treatmentName,
    contactNumber,
    secondaryContactNumber,
    location,
    fee,
    maxNoOfPatient,
    isAmbulatory,
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  return result.id;
};

export const updateService = async (
  serviceId: string,
  updatedService: Partial<HospitalService>
) => {
  await firestore
    .collection(HOSPITAL_SERVICES)
    .doc(serviceId)
    .update({
      ...updatedService,
      updatedAt: new Date(),
    });
};

export const updateServiceSchedule = async (
  serviceSchduleId: string,
  updatedServiceSchedule: Partial<ServiceSchedule>
) => {
  await firestore
    .collection(HOSPITAL_SERVICES_SCHEDULE)
    .doc(serviceSchduleId)
    .update({
      ...updatedServiceSchedule,
      updatedAt: new Date(),
    });
};

export const removeServiceSchedule = async (serviceScheduleId: string) => {
  await firestore
    .collection(HOSPITAL_SERVICES_SCHEDULE)
    .doc(serviceScheduleId)
    .delete();
};

export const createServiceSchedule = async (
  hospitalServiceId: string,
  dayOfWeek: DaysOfWeek,
  estimatedAppointmentMinuteLength: number,
  slots: number,
  startTime: Date
) => {
  await firestore.collection(HOSPITAL_SERVICES_SCHEDULE).add({
    hospitalServiceId,
    dayOfWeek,
    estimatedAppointmentMinuteLength,
    slots,
    startTime,
    createdAt: new Date(),
    updatedAt: new Date(),
  });
};

export const getHospitalIdsWithServices = async (serviceId: string) => {
  const result = await firestore
    .collection(HOSPITAL_SERVICES)
    .where("service", "==", serviceId)
    .get();

  if (result.docs.length > 0) {
    return _.compact(
      result.docs.map((doc) => {
        const serviceData = doc.data() as HospitalService;
        console.log("GOT SERVICE! ", serviceData);
        if (
          serviceData.archiveDate === undefined ||
          (!isHistoricalDate(serviceData.archiveDate.toDate()) &&
            !isToday(serviceData.archiveDate.toDate()))
        ) {
          return serviceData.hospitalId;
        } else {
          return null;
        }
      })
    );
  } else {
    return [];
  }
};

export const addDoctorHospital = async (
  doctorId: string,
  hospitalId: string,
  specialities: string[]
) => {
  const doctorHospital = await firestore.collection(DOCTOR_HOSPITALS).add({
    doctorId,
    hospitalId,
    specialities: specialities.map((speciality) => {
      return { name: speciality, fee: 0 };
    }),
    isVerified: false,
    createdAt: new Date(),
    updatedAt: new Date(),
  });
  return { doctorHospital: doctorHospital.id };
};

export const getHospitalIdsWithDoctor = async (doctorId: string) => {
  const result = await firestore
    .collection(DOCTOR_HOSPITALS)
    .where("doctorId", "==", doctorId)
    .get();

  if (result.docs.length > 0) {
    return result.docs.map((doc) => (doc.data() as DoctorHospitals).hospitalId);
  } else {
    return [];
  }
};

export const getHospitals = async (hospitalIds: string[]) => {
  const result = [] as Hospital[];
  const hospitals = await Promise.all(
    hospitalIds.map((hospitalId) => {
      return firestore.collection(HOSPITALS).doc(hospitalId).get();
    })
  );
  // No need to check if Hospital representatives is verified or nah cause they will not be able to create doctos and services in the first place if not verified
  hospitals.forEach((hospital) => {
    const hospitalData = hospital.data() as Hospital;
    if (
      hospital.exists &&
      hospitalData.isVerified &&
      !hospitalData.isRejected
    ) {
      result.push({
        ...hospitalData,
        docId: hospital.id,
      } as Hospital);
    }
  });
  return result;
};

export const getHospitalsRealTime = async (
  callback: (hospitals: Hospital[], error?: string) => void
) => {
  try {
    firestore.collection(HOSPITALS).onSnapshot(
      (hospitals) => {
        if (!hospitals.empty) {
          callback(
            _.filter(
              hospitals.docs.map((hospitalQuery) => {
                return {
                  docId: hospitalQuery.id,
                  ...hospitalQuery.data(),
                } as Hospital;
              }),
              (hospital) => {
                return hospital.isVerified && !hospital.isRejected;
              }
            )
          );
        } else {
          callback([]);
        }
      },
      (error) => {
        callback([], error.message);
      }
    );
  } catch (e) {
    callback([], e);
  }
};

export const getHospitalApplications = async (
  callback: (applications: Hospital[]) => void,
  isVerified = false
) => {
  try {
    firestore
      .collection(HOSPITALS)
      .where("isVerified", "==", isVerified)
      .onSnapshot(
        async (hospitals) => {
          if (!hospitals.empty) {
            callback(
              hospitals.docs.map((hospital) => {
                return { docId: hospital.id, ...hospital.data() } as Hospital;
              })
            );
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([]);
        }
      );
  } catch (e) {
    callback([]);
  }
};

export const approveApplication = async (hospital: Hospital) => {
  // verify hospital
  await firestore.collection(HOSPITALS).doc(hospital.docId).update({
    isVerified: true,
    isRejected: false,
  });

  try {
    const { subject, message } = email.successHospitalRegistration(
      `${hospital.firstName} ${hospital.lastName}`,
      `${hospital.hospitalName} - ${hospital.department}`
    );

    await sendEmail(hospital.emailAddress, subject, message);

    const smsMessage = sms.successHospitalRegistration(
      `${hospital.firstName} ${hospital.lastName}`,
      `${hospital.hospitalName} - ${hospital.department}`
    );

    await api.sendSMS(hospital.phoneNumber, smsMessage);
  } catch (e) {
    console.log("ERROR SENDING SMS", e);
  }
};

export const removeDoctorSchedule = async (doctorScheduleId: string) => {
  await firestore.collection(DOCTOR_SCHEDULE).doc(doctorScheduleId).delete();
};

export const updateDoctorSchedule = async (
  doctorSchduleId: string,
  slots: number,
  estimatedAppointmentMinuteLength: number,
  startTime: Date,
  endTime: Date
) => {
  await firestore.collection(DOCTOR_SCHEDULE).doc(doctorSchduleId).update({
    slots,
    estimatedAppointmentMinuteLength,
    startTime,
    endTime,
    updatedAt: new Date(),
  });
};

export const removeTimeKeeping = async (timeKeepingId: string) => {
  const timeKeeping = await firestore
    .collection(HOSPITAL_TIME_KEEPING)
    .doc(timeKeepingId)
    .get();
  if (timeKeeping.exists) {
    await api.deleteUser((timeKeeping.data() as TimeKeeping).userEmail);
  }
};

export const archiveService = async (
  hospitalServiceId: string,
  hospital: Hospital,
  archiveDate: Date
) => {
  const serviceSchedules = await firestore
    .collection(HOSPITAL_SERVICES_SCHEDULE)
    .where("hospitalServiceId", "==", hospitalServiceId)
    .get();
  const serviceScheduleIds = [] as string[];
  if (!serviceSchedules.empty) {
    await Promise.all(
      serviceSchedules.docs.map((scheduleToDelete) => {
        serviceScheduleIds.push(scheduleToDelete.id);
        return scheduleToDelete.ref.delete();
      })
    );
  }

  const hospitalService = await firestore
    .collection(HOSPITAL_SERVICES)
    .doc(hospitalServiceId)
    .get();

  hospitalService.ref.update({
    archiveDate,
    updatedAt: getServerTimestamp(),
  });
  const hospitalServiceData = hospitalService.data() as HospitalService;
  const serviceData = await getService(hospitalServiceData.treatmentName);
  //NOTIFY PATIENTS
  try {
    // get all appointments
    const hospitalAppointments = await firestore
      .collection(HOSPITAL_SERVICE_APPOINTMENTS)
      .where("appointmentDate", ">=", new Date())
      .where("serviceScheduleId", "in", serviceScheduleIds)
      .get();

    if (!hospitalAppointments.empty) {
      const patientsNotified = [] as string[];
      //send notifs
      await Promise.all(
        hospitalAppointments.docs.map((appointment) => {
          const appointmentData = appointment.data() as HospitalServiceAppointmentsOLD;
          return new Promise(async (resolve) => {
            try {
              const patient = await firestore
                .collection(PATIENTS)
                .doc(appointmentData.patientId)
                .get();
              if (
                patient.exists &&
                patientsNotified.indexOf(patient.id) === -1
              ) {
                const patientData = patient.data() as Patient;
                const { firstName, lastName } = patientData;
                const { subject, message } = email.serviceArchived(
                  `${firstName} ${lastName}`,
                  hospital.hospitalName,
                  hospital.phoneNumber,
                  moment(archiveDate).format("MM/DD/YYYY"),
                  serviceData.treatmentName
                );

                await sendEmail(patientData.emailAddress, subject, message);
                patientsNotified.push(patient.id);
                resolve();
              } else {
                resolve();
              }
            } catch (e) {
              resolve();
            }
          });
        })
      );
    }
  } catch (e) {
    console.log("ERROR SENDING NOTIFS!", e);
  }
};

export const removeDoctor = async (
  doctorHospitalId: string,
  hospital: Hospital,
  reason: string
) => {
  const doctorSchedules = await firestore
    .collection(DOCTOR_SCHEDULE)
    .where("doctorHospitalId", "==", doctorHospitalId)
    .get();

  if (!doctorSchedules.empty) {
    await Promise.all(
      doctorSchedules.docs.map((scheduleToDelete) =>
        scheduleToDelete.ref.delete()
      )
    );
  }

  const doctorHospital = await firestore
    .collection(DOCTOR_HOSPITALS)
    .doc(doctorHospitalId)
    .get();

  const doctorHospitalData = doctorHospital.data() as DoctorHospitals;
  const doctor = await firestore
    .collection(DOCTORS)
    .doc(doctorHospitalData.doctorId)
    .get();
  const doctorData = doctor.data() as Doctor;

  // DELETE DOCTORS
  doctorHospital.ref.delete();

  //NOTIFY PATIENTS
  try {
    const doctorHospitalAppointments = await firestore
      .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
      .where("appointmentDate", ">=", new Date())
      .where("doctorId", "==", doctor.id)
      .where("hospitalId", "==", hospital.docId || "")
      .get();
    if (!doctorHospitalAppointments.empty) {
      const patientsNotified = [] as string[];
      // send notifs to patients
      await Promise.all(
        doctorHospitalAppointments.docs.map((appointment) => {
          const appointmentData = appointment.data() as HospitalDoctorAppointmentsOLD;
          return new Promise(async (resolve) => {
            try {
              const patient = await firestore
                .collection(PATIENTS)
                .doc(appointmentData.patientId)
                .get();
              if (
                patient.exists &&
                patientsNotified.indexOf(patient.id) === -1
              ) {
                const patientData = patient.data() as Patient;
                const { firstName, lastName } = patientData;

                const {
                  subject,
                  message,
                } = email.notifyPatientHospitalDeletedDoctor(
                  `${firstName} ${lastName}`,
                  hospital.hospitalName,
                  hospital.phoneNumber,
                  `${doctorData.firstName} ${doctorData.lastName}`,
                  reason
                );

                await sendEmail(patientData.emailAddress, subject, message);

                const smsMessage = sms.notifyPatientHospitalDeletedDoctor(
                  `${firstName} ${lastName}`,
                  hospital.hospitalName,
                  hospital.phoneNumber,
                  `${doctorData.firstName} ${doctorData.lastName}`,
                  reason
                );

                await api.sendSMS(patientData.phoneNumber, smsMessage);
                patientsNotified.push(patient.id);
              }
              appointment.ref.update({
                isCancelled: true,
              });
              resolve();
            } catch (e) {
              console.log("ERROR SENDING NOTIF!", e);
              resolve();
            }
          });
        })
      );
    }

    //send notif to doctor
    const { subject, message } = email.notifyDoctorHospitalDeletedDoctor(
      hospital.hospitalName,
      hospital.phoneNumber,
      `${doctorData.firstName} ${doctorData.lastName}`,
      reason
    );

    await sendEmail(doctorData.emailAddress, subject, message);

    const smsMessage = sms.notifyDoctorHospitalDeletedDoctor(
      hospital.hospitalName,
      hospital.phoneNumber,
      `${doctorData.firstName} ${doctorData.lastName}`,
      reason
    );

    await api.sendSMS(doctorData.phoneNumber, smsMessage);
  } catch (e) {
    console.log("ERROR NOTIFY !", e);
  }
};

export const getExistingHospitalServices = async (hospitalId: string) => {
  const result = [] as string[];
  const hospitalServices = await firestore
    .collection(HOSPITAL_SERVICES)
    .where("hospitalId", "==", hospitalId)
    .get();

  hospitalServices.forEach((service) => {
    const serviceData = service.data() as HospitalService;
    if (
      service.exists &&
      (serviceData.archiveDate === undefined ||
        (!isHistoricalDate(serviceData.archiveDate.toDate()) &&
          !isToday(serviceData.archiveDate.toDate())))
    ) {
      result.push(serviceData.treatmentName);
    }
  });
  return result;
};

export const checkIfDoctorHospitalExist = async (
  doctorId: string,
  hospitalId: string
) => {
  const doctorHospital = await firestore
    .collection(DOCTOR_HOSPITALS)
    .where("doctorId", "==", doctorId)
    .where("hospitalId", "==", hospitalId)
    .get();
  if (doctorHospital.docs.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const updateHospitalRepresentative = async (
  docId: string,
  updatedHospital: Partial<Hospital>
) => {
  await firestore
    .collection(HOSPITALS)
    .doc(docId)
    .update({
      ...updatedHospital,
      updatedAt: getServerTimestamp(),
    } as Hospital);
};

export const addService = async (name: string) => {
  const result = await firestore.collection(SERVICES).add({
    name,
    createdAt: getServerTimestamp(),
  });

  return result.id;
};

export const getServices = async () => {
  const services = await firestore.collection(HOSPITAL_SERVICES).get();
  if (!services.empty) {
    return services.docs.map((service) => {
      const serviceData = service.data() as HospitalService;
      return {
        id: service.id,
        name: serviceData.treatmentName,
      };
    });
  } else {
    return [];
  }
};

export const getService = async (id: string): Promise<HospitalService> => {
  const service = await firestore.collection(HOSPITAL_SERVICES).doc(id).get();
  if (service.exists) {
    return service.data() as HospitalService;
  } else {
    return {} as HospitalService;
  }
};

export const getServiceSchedules = async (serviceId: string) => {
  const serviceSchedules = await firestore
    .collection(HOSPITAL_SERVICES_SCHEDULE)
    .where("hospitalServiceId", "==", serviceId)
    .get();

  if (serviceSchedules.docs.length > 0) {
    const schedules = [] as ServiceSchedule[];
    serviceSchedules.docs.forEach((item) => {
      schedules.push({ ...item.data(), docId: item.id } as ServiceSchedule);
    });
    return schedules;
  }
  return [];
};

export const getHospitalServices = async (hospitalId: string) => {
  try {
    if (!!hospitalId) {
      const hospitalServices = await firestore
        .collection(HOSPITAL_SERVICES)
        .where("hospitalId", "==", hospitalId)
        .get();

      if (hospitalServices.docs.length > 0) {
        const hospitalServicesList = hospitalServices.docs.map((service) => {
          return { ...service.data(), docId: service.id };
        }) as HospitalService[];

        const serviceScheduleMap: {
          [hospitalServiceId: string]: ServiceSchedule[];
        } = {};

        // Map schedule to hospital service id
        const serviceSchedules = await Promise.all(
          hospitalServicesList.map((hospitalService) => {
            return firestore
              .collection(HOSPITAL_SERVICES_SCHEDULE)
              .where("hospitalServiceId", "==", hospitalService.docId)
              .get();
          })
        );

        serviceSchedules.forEach((serviceSchedule) => {
          if (serviceSchedule.docs.length > 0) {
            const data0 = serviceSchedule.docs[0];

            serviceScheduleMap[
              data0.data().hospitalServiceId
            ] = serviceSchedule.docs.map((serviceScheduleItem) => {
              return {
                ...serviceScheduleItem.data(),
                docId: serviceScheduleItem.id,
              } as ServiceSchedule;
            });
          }
        });

        const result: HospitalServiceSchedule[] = hospitalServicesList.map(
          (hospitalService) => {
            return {
              ...hospitalService,
              schedules: serviceScheduleMap[hospitalService.docId || ""],
              forResource: RESOURCE_TYPES.services.id,
            };
          }
        );
        return result as HospitalServiceSchedule[];
      }
    } else {
      return [] as HospitalServiceSchedule[];
    }
  } catch (error) {
    console.log("error -- getHospitalServices -- ", error);
    return [] as HospitalServiceSchedule[];
  }
};

export const getHospitalTimeKeeping = async (hospitalId: string) => {
  try {
    if (!!hospitalId) {
      const hospitalTimeKeeping = await firestore
        .collection(HOSPITAL_TIME_KEEPING)
        .where("hospitalId", "==", hospitalId)
        .get();
      if (hospitalTimeKeeping.docs.length > 0) {
        const hospitalTimeKeepingList = hospitalTimeKeeping.docs.map(
          (timeKeeping) => {
            return {
              ...timeKeeping.data(),
              docId: timeKeeping.id,
              forResource: RESOURCE_TYPES.timeKeeping.id,
            };
          }
        ) as TimeKeeping[];

        return hospitalTimeKeepingList;
      } else {
        return [];
      }
    }
  } catch (error) {
    console.log("error -- getHospitalTimeKeeping - ", error);
  }
};

export const updateHospitalTimeKeeping = async (
  docId: string,
  updatedTimeKeeping: Partial<TimeKeeping>
) => {
  await firestore
    .collection(HOSPITAL_TIME_KEEPING)
    .doc(docId)
    .update({
      ...updatedTimeKeeping,
      updatedAt: getServerTimestamp(),
    } as TimeKeeping);
};

export const getHospitalTimeKeepingDetail = async (docId: string) => {
  try {
    const hospitalTimeKeeping = await firestore
      .collection(HOSPITAL_TIME_KEEPING)
      .doc(docId)
      .get();
    if (hospitalTimeKeeping.exists) {
      return { ...hospitalTimeKeeping.data(), docId: hospitalTimeKeeping.id };
    } else {
      return {} as TimeKeeping;
    }
  } catch (error) {
    console.log("error -- getHospitalTimeKeepingDetail - ", error);
    return {} as TimeKeeping;
  }
};

export const getServiceMap = async (): Promise<{ [id: string]: string }> => {
  const services = await firestore.collection(HOSPITAL_SERVICES).get();
  if (!services.empty) {
    const servicesMap = {} as { [id: string]: string };

    services.docs.forEach((service) => {
      const serviceData = service.data() as HospitalService;
      servicesMap[service.id] = serviceData.treatmentName;
    });
    return servicesMap;
  } else {
    return {} as { [id: string]: string };
  }
};

export const transferRepresentativeAccount = async (
  emailAddress: string,
  hospital: Hospital
) => {
  const invitation = await firestore
    .collection(HOSPITAL_REPRESENTATIVES_INVITATION)
    .add({
      emailAddress,
      invitedBy: hospital.docId || "",
      hospitalId: hospital.docId || "",
      isCancelled: false,
      isUsed: false,
      createdAt: getServerTimestamp(),
    });

  const { subject, message } = email.inviteHospitalRepresentative(
    `${hospital.firstName} ${hospital.lastName}`,
    hospital.hospitalName,
    hospital.phoneNumber,
    routes.CREATE_WITH_EMAIL_ACCOUNT_SETUP_WITH_INVITE.replace(
      ":invitationId",
      invitation.id
    )
  );

  await sendEmail(emailAddress, subject, message);
};

export const getInvitation = async (invitationId: string) => {
  const invitation = await firestore
    .collection(HOSPITAL_REPRESENTATIVES_INVITATION)
    .doc(invitationId)
    .get();
  if (invitation.exists) {
    return {
      docId: invitation.id,
      ...invitation.data(),
    } as HospitalRepInvitation;
  } else {
    return {} as HospitalRepInvitation;
  }
};

export const getCurrentInvitation = async (hospitalRepId: string) => {
  const invitation = await firestore
    .collection(HOSPITAL_REPRESENTATIVES_INVITATION)
    .where("invitedBy", "==", hospitalRepId)
    .where("isCancelled", "==", false)
    .where("isUsed", "==", false)
    .get();
  if (!invitation.empty) {
    const invitationData = invitation.docs[0];
    return {
      docId: invitationData.id,
      ...invitationData.data(),
    } as HospitalRepInvitation;
  } else {
    return {} as HospitalRepInvitation;
  }
};

export const cancelInvitation = async (invitationId: string) => {
  await firestore
    .collection(HOSPITAL_REPRESENTATIVES_INVITATION)
    .doc(invitationId)
    .update({
      isCancelled: true,
    });
};
