import * as _ from "lodash";

import * as api from "../api";
import { doSendVerificationEmail } from "./users";
import { firestore } from "../firebase";
import {
  getServerTimestamp,
  getCurrentLocation,
  getDistanceFrom,
} from "../functions/common";
import { CancerType } from "../interface";
import {
  Patient,
  DoctorResource,
  DoctorHospitals,
  Doctor,
  DoctorSchedule,
  Hospital,
  ServiceResource,
  HospitalService,
  ServiceSchedule,
  ChargeableGcash,
  PaymentAppointmentReference,
  FamilyMember,
} from "../models";
import { USER_TYPES } from "../constants/config";
import {
  PATIENTS,
  ACCOUNT_TYPES,
  HOSPITAL_SERVICE_APPOINTMENTS,
  HOSPITAL_DOCTOR_APPOINTMENTS,
  DOCTOR_HOSPITALS,
  DOCTORS,
  DOCTOR_SCHEDULE,
  HOSPITALS,
  HOSPITAL_SERVICES,
  HOSPITAL_SERVICES_SCHEDULE,
  GCASH_PAYMENTS,
  CREDIT_DEBIT_PAYMENTS,
  FAMILY_MEMBERS,
} from "../constants/dbCollections";

export const createPatient = async (
  docId: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: string,
  gender: string,
  birthday: Date,
  province: string,
  cityMunicipality: string,
  governmentId: string,
  isVerified = false
) => {
  const machineDetails = await api.getUserMachineDetails();
  await firestore.collection(PATIENTS).doc(docId).set({
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    gender,
    birthday,
    cancelCounter: 0,
    bookingCredit: 0,
    province,
    cityMunicipality,
    governmentId,
    createdAt: getServerTimestamp(),
    updatedAt: getServerTimestamp(),
    machineDetails,
  });

  await firestore.collection(ACCOUNT_TYPES).doc(docId).update({
    userType: USER_TYPES.patients.id,
  });

  if (!isVerified) {
    await doSendVerificationEmail();
  }
};

export const getPatient = async (
  patientId: string,
  callback: (patient: Patient) => void
) => {
  firestore
    .collection(PATIENTS)
    .doc(patientId)
    .onSnapshot(
      (snapshot) => {
        if (snapshot.exists) {
          const doc = snapshot.data();
          callback({
            ...doc,
            docId: snapshot.id,
          } as Patient);
        } else {
          callback({} as Patient);
        }
      },
      (error) => {
        callback({} as Patient);
      }
    );
};

export const updatePatient = async (
  docId: string,
  updatedPatientDetail: Partial<Patient>
) => {
  await firestore
    .collection(PATIENTS)
    .doc(docId)
    .update({
      ...updatedPatientDetail,
      updatedAt: getServerTimestamp(),
    } as Patient);
};

export const createFamilyMember = async (
  patientId: string,
  relationship: string,
  firstName: string,
  lastName: string,
  gender: string,
  birthday: Date
) => {
  await firestore.collection(FAMILY_MEMBERS).add({
    patientId,
    relationship,
    firstName,
    lastName,
    gender,
    birthday,
    createdAt: getServerTimestamp(),
    updatedAt: getServerTimestamp(),
  });
};

export const getFamilyMember = (
  patientId: string,
  callback: (familyMembers: FamilyMember[], error?: string) => void
) => {
  try {
    firestore
      .collection(FAMILY_MEMBERS)
      .where("patientId", "==", patientId)
      .onSnapshot(
        (familyMembers) => {
          if (!familyMembers.empty) {
            callback(
              familyMembers.docs.map((familyMember) => {
                return {
                  ...familyMember.data(),
                  docId: familyMember.id,
                } as FamilyMember;
              })
            );
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([], error.message);
        }
      );
  } catch (e) {
    callback([], e);
  }
};

export const getFamilyMemberOnce = async (patientId: string) => {
  const familyMembers = await firestore
    .collection(FAMILY_MEMBERS)
    .where("patientId", "==", patientId)
    .get();

  if (!familyMembers.empty) {
    return familyMembers.docs.map((familyMember) => {
      return {
        ...familyMember.data(),
        docId: familyMember.id,
      } as FamilyMember;
    });
  } else {
    return [] as FamilyMember[];
  }
};

export const updateFamilyMember = async (
  docId: string,
  updatedDetails: Partial<FamilyMember>
) => {
  await firestore
    .collection(FAMILY_MEMBERS)
    .doc(docId)
    .update({ ...updatedDetails, updatedAt: getServerTimestamp() });
};

export const deleteFamilyMember = async (docId: string) => {
  await firestore.collection(FAMILY_MEMBERS).doc(docId).delete();
};

export const addCreditDebitPayment = async (
  status: string,
  postPaymentDetails: any
) => {
  return await firestore.collection(CREDIT_DEBIT_PAYMENTS).add({
    status,
    postPaymentDetails,
    createdDt: getServerTimestamp(),
  });
};

export const addAppointmentDetailsToCreditDebitPayment = async (
  docId: string,
  appointments: PaymentAppointmentReference[]
) => {
  await firestore.collection(CREDIT_DEBIT_PAYMENTS).doc(docId).update({
    appointments,
  });
};

export const createGCashPayment = async () => {
  return await firestore.collection(GCASH_PAYMENTS).add({
    initialized: getServerTimestamp(),
  });
};

export const markGcashAsNonChargeable = async (gcashPayment: string) => {
  await firestore.collection(GCASH_PAYMENTS).doc(gcashPayment).update({
    chargeable: false,
  });
};

export const watchForGCashPayment = (
  sourceId: string,
  callback: (isChargeable: null | boolean, id: string, error?: string) => void
) => {
  try {
    const unsubscribe = firestore
      .collection(GCASH_PAYMENTS)
      .where("sourceId", "==", sourceId)
      .onSnapshot(
        (chargeableGcash) => {
          if (!chargeableGcash.empty) {
            const chargeableGcashData = chargeableGcash.docs[0].data() as ChargeableGcash;
            if (_.isEmpty(chargeableGcashData.postPaymentDetails)) {
              callback(
                chargeableGcashData.chargeable,
                chargeableGcash.docs[0].id
              );
            } else {
              callback(null, "");
            }
          } else {
            callback(null, "");
          }
        },
        (error) => {
          callback(null, error.message);
        }
      );
    return unsubscribe;
  } catch (e) {
    callback(false, e);
    return () => {};
  }
};

export const updateGCashPayment = async (
  docId: string,
  postPaymentDetails: any
) => {
  await firestore.collection(GCASH_PAYMENTS).doc(docId).update({
    postPaymentDetails,
  });
};

export const addAppointmentDetailsToGcashPayment = async (
  docId: string,
  appointments: PaymentAppointmentReference[]
) => {
  await firestore.collection(GCASH_PAYMENTS).doc(docId).update({
    appointments,
  });
};

export const updateGCashPaymentSourceId = async (
  docId: string,
  sourceId: string
) => {
  await firestore.collection(GCASH_PAYMENTS).doc(docId).update({
    sourceId,
  });
};

export const addPreferredDoctor = async (
  patientId: string,
  preferredDoctor = ""
) => {
  await firestore.collection(PATIENTS).doc(patientId).update({
    preferredDoctor,
  });
};

export const addDefaultCancerType = async (
  patientId: string,
  defaultCancerType: CancerType
) => {
  await firestore.collection(PATIENTS).doc(patientId).update({
    defaultCancerType,
  });
};

export const isFirstBooking = async (patientId: string) => {
  const treatmentBooking = await firestore
    .collection(HOSPITAL_SERVICE_APPOINTMENTS)
    .where("patientId", "==", patientId)
    .limit(1)
    .get();

  const consultation = await firestore
    .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
    .where("patientId", "==", patientId)
    .limit(1)
    .get();

  return treatmentBooking.empty && consultation.empty;
};

export const getDoctorResources = async (
  hospitalId = null as null | string,
  specialityFilter = null as null | string,
  getDistance = false,
  callback: (doctorResource: DoctorResource[], error?: string) => void
) => {
  try {
    const userLocation = !getDistance ? null : await getCurrentLocation();
    const doctorHospitalQuery = firestore.collection(DOCTOR_HOSPITALS);
    let filteredDoctorHospitalQuery = null as null | firebase.firestore.Query;
    let hospitalFilterDetails = null as null | Hospital;
    if (!_.isNull(hospitalId)) {
      filteredDoctorHospitalQuery = doctorHospitalQuery.where(
        "hospitalId",
        "==",
        hospitalId
      );
      const hospitalFilterDetailsQuery = await firestore
        .collection(HOSPITALS)
        .doc(hospitalId)
        .get();

      if (hospitalFilterDetailsQuery.exists) {
        hospitalFilterDetails = {
          docId: hospitalFilterDetailsQuery.id,
          ...hospitalFilterDetailsQuery.data(),
        } as Hospital;
      }
    }

    if (
      _.isNull(hospitalId) ||
      (!_.isNull(hospitalId) && !_.isNull(hospitalFilterDetails))
    ) {
      (doctorHospitalQuery || filteredDoctorHospitalQuery).onSnapshot(
        async (doctorHospitals) => {
          if (!doctorHospitals.empty) {
            const unformattedData = await Promise.all(
              doctorHospitals.docs.map((doctorHospital) => {
                return new Promise(async (resolve) => {
                  const doctorHospitalData = doctorHospital.data() as DoctorHospitals;
                  let resolved = false;
                  if (!_.isNull(specialityFilter)) {
                    const validSpeciality = _.find(
                      doctorHospitalData.specialities,
                      (specialityItem) =>
                        specialityItem.name.trim().toLowerCase() ===
                        specialityFilter.trim().toLowerCase()
                    );

                    if (_.isEmpty(validSpeciality)) {
                      resolved = true;
                      resolve(null);
                    }
                  }

                  if (!resolved) {
                    let hospitalDetails = null as Hospital | null;
                    if (_.isNull(hospitalFilterDetails)) {
                      const hospitalQuery = await firestore
                        .collection(HOSPITALS)
                        .doc(doctorHospitalData.hospitalId)
                        .get();
                      hospitalDetails = {
                        docId: hospitalQuery.id,
                        ...hospitalQuery.data(),
                      } as Hospital;
                    } else {
                      hospitalDetails = hospitalFilterDetails;
                    }

                    const doctor = await firestore
                      .collection(DOCTORS)
                      .doc(doctorHospitalData.doctorId)
                      .get();

                    if (doctor.exists) {
                      const doctorData = {
                        docId: doctor.id,
                        ...doctor.data(),
                      } as Doctor;

                      if (_.isEmpty(doctorData.initialPasswordCipher)) {
                        const doctorSchedule = await firestore
                          .collection(DOCTOR_SCHEDULE)
                          .where("doctorHospitalId", "==", doctorHospital.id)
                          .get();
                        if (!doctorSchedule.empty) {
                          const doctorScheduleList = doctorSchedule.docs.map(
                            (doctorScheduleItem) => {
                              return {
                                ...doctorScheduleItem.data(),
                                docId: doctorScheduleItem.id,
                              } as DoctorSchedule;
                            }
                          );

                          resolve(
                            _.compact(
                              doctorHospitalData.specialities.map(
                                (speciality) => {
                                  if (
                                    _.isNull(specialityFilter) ||
                                    (!_.isNull(specialityFilter) &&
                                      speciality.name.trim().toLowerCase() ===
                                        specialityFilter.trim().toLowerCase())
                                  ) {
                                    return {
                                      ...doctorData,
                                      doctorHospitalId: doctorHospital.id,
                                      fullName: `${doctorData.firstName} ${doctorData.lastName}`,
                                      specialitiesFormatted: doctorHospitalData.specialities
                                        .map((speciality) => speciality.name)
                                        .join(", "),
                                      specialities: [speciality],
                                      schedule: doctorScheduleList,
                                      hospital: hospitalDetails,
                                      distance: !_.isNull(userLocation)
                                        ? getDistanceFrom(
                                            userLocation,
                                            hospitalDetails!.location
                                          )
                                        : null,
                                    } as DoctorResource;
                                  } else {
                                    return null;
                                  }
                                }
                              )
                            )
                          );
                        } else {
                          resolve(null);
                        }
                      } else {
                        resolve(null);
                      }
                    } else {
                      resolve(null);
                    }
                  }
                }) as Promise<null | DoctorResource | DoctorResource[]>;
              })
            );

            callback(_.compact(_.flatten(unformattedData)));
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([]);
        }
      );
    } else {
      callback([]);
    }
  } catch (e) {
    console.log("ERROR GETTING: getDoctorResources", e);
    callback([], e);
  }
};

//!WILL RETURN AMBULATORY ONLY SINCE WE'LL OPT TO NOT INCLUDE SPECIALTY TREATMENT
export const getServiceResources = async (
  hospitalId = null as null | string,
  treatmentName = null as null | string,
  getDistance = false,
  callback: (serviceResource: ServiceResource[], error?: string) => void
) => {
  try {
    const userLocation = !getDistance ? null : await getCurrentLocation();
    const serviceHospitalQuery = firestore
      .collection(HOSPITAL_SERVICES)
      .where("isAmbulatory", "==", true);

    let filteredServiceHospitalQuery = null as null | firebase.firestore.Query;
    let hospitalFilterDetails = null as null | Hospital;
    if (!_.isNull(hospitalId)) {
      filteredServiceHospitalQuery = serviceHospitalQuery.where(
        "hospitalId",
        "==",
        hospitalId
      );
      const hospitalFilterDetailsQuery = await firestore
        .collection(HOSPITALS)
        .doc(hospitalId)
        .get();

      if (hospitalFilterDetailsQuery.exists) {
        hospitalFilterDetails = {
          docId: hospitalFilterDetailsQuery.id,
          ...hospitalFilterDetailsQuery.data(),
        } as Hospital;
      }
    }

    if (
      _.isNull(hospitalId) ||
      (!_.isNull(hospitalId) && !_.isNull(hospitalFilterDetails))
    ) {
      (serviceHospitalQuery || filteredServiceHospitalQuery).onSnapshot(
        async (serviceHospitals) => {
          if (!serviceHospitals.empty) {
            const unformattedData = await Promise.all(
              serviceHospitals.docs.map((serviceHospital) => {
                return new Promise(async (resolve) => {
                  const serviceHospitalData = serviceHospital.data() as HospitalService;
                  let resolved = false;
                  if (!_.isNull(treatmentName)) {
                    const validTreatment =
                      serviceHospitalData.treatmentName.trim().toLowerCase() ===
                      treatmentName.trim().toLowerCase();

                    if (!validTreatment) {
                      resolved = true;
                      resolve(null);
                    }
                  }

                  if (!resolved) {
                    let hospitalDetails = null;
                    if (_.isNull(hospitalFilterDetails)) {
                      const hospitalQuery = await firestore
                        .collection(HOSPITALS)
                        .doc(serviceHospitalData.hospitalId)
                        .get();
                      hospitalDetails = {
                        docId: hospitalQuery.id,
                        ...hospitalQuery.data(),
                      } as Hospital;
                    } else {
                      hospitalDetails = hospitalFilterDetails;
                    }

                    const serviceData = {
                      docId: serviceHospital.id,
                      ...serviceHospitalData,
                    } as HospitalService;
                    const serviceSchedule = await firestore
                      .collection(HOSPITAL_SERVICES_SCHEDULE)
                      .where("hospitalServiceId", "==", serviceHospital.id)
                      .get();
                    if (!serviceSchedule.empty) {
                      const serviceScheduleList = serviceSchedule.docs.map(
                        (serviceScheduleItem) => {
                          return {
                            ...serviceScheduleItem.data(),
                            docId: serviceScheduleItem.id,
                          } as ServiceSchedule;
                        }
                      );

                      resolve({
                        ...serviceData,
                        schedule: serviceScheduleList,
                        hospital: hospitalDetails,
                        distance: !_.isNull(userLocation)
                          ? getDistanceFrom(
                              userLocation,
                              hospitalDetails!.location
                            )
                          : null,
                      } as ServiceResource);
                    } else {
                      resolve(null);
                    }
                  }
                }) as Promise<null | ServiceResource>;
              })
            );

            callback(_.compact(unformattedData));
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([], error.message);
        }
      );
    } else {
      callback([]);
    }
  } catch (e) {
    console.log("ERROR GETTING: getServiceResources", e);
    callback([], e);
  }
};
