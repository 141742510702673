export const ADDRESS: {
  [key: string]: string[];
} = {
  Abra: [
    "Bangued",
    "Boliney",
    "Bucay",
    "Bucloc",
    "Daguioman",
    "Danglas",
    "Dolores",
    "La Paz",
    "Lacub",
    "Lagangilang",
    "Lagayan",
    "Langiden",
    "Licuan-Baay (Licuan)",
    "Luba",
    "Malibcong",
    "Manabo",
    "Peñarrubia",
    "Pidigan",
    "Pilar",
    "Sallapadan",
    "San Isidro",
    "San Juan",
    "San Quintin",
    "Tayum",
    "Tineg",
    "Tubo",
    "Villaviciosa",
  ],
  "Agusan del Norte": [
    "Buenavista",
    "Butuan",
    "Cabadbaran",
    "Carmen",
    "Jabonga",
    "Kitcharao",
    "Las Nieves",
    "Magallanes",
    "Nasipit",
    "Remedios T. Romualdez",
    "Santiago",
    "Tubay",
  ],
  "Agusan del Sur": [
    "Bayugan",
    "Bunawan",
    "Esperanza",
    "La Paz",
    "Loreto",
    "Prosperidad",
    "Rosario",
    "San Francisco",
    "San Luis",
    "Santa Josefa",
    "Sibagat",
    "Talacogon",
    "Trento",
    "Veruela",
  ],
  Aklan: [
    "Altavas",
    "Balete",
    "Banga",
    "Batan",
    "Buruanga",
    "Ibajay",
    "Kalibo",
    "Lezo",
    "Libacao",
    "Madalag",
    "Makato",
    "Malay",
    "Malinao",
    "Nabas",
    "New Washington",
    "Numancia",
    "Tangalan",
  ],
  Albay: [
    "Bacacay",
    "Camalig",
    "Daraga (Locsin)",
    "Guinobatan",
    "Jovellar",
    "Legazpi",
    "Libon",
    "Ligao",
    "Malilipot",
    "Malinao",
    "Manito",
    "Oas",
    "Pio Duran",
    "Polangui",
    "Rapu-Rapu",
    "Santo Domingo",
    "Tabaco",
    "Tiwi",
  ],
  Antique: [
    "Anini-y",
    "Barbaza",
    "Belison",
    "Bugasong",
    "Caluya",
    "Culasi",
    "Hamtic",
    "Laua-an",
    "Libertad",
    "Pandan",
    "Patnongon",
    "San Jose de Buenavista",
    "San Remigio",
    "Sebaste",
    "Sibalom",
    "Tibiao",
    "Tobias Fornier (Dao)",
    "Valderrama",
  ],
  Apayao: [
    "Calanasan",
    "Conner",
    "Flora",
    "Kabugao",
    "Luna",
    "Pudtol",
    "Santa Marcela",
  ],
  Aurora: [
    "Baler",
    "Casiguran",
    "Dilasag",
    "Dinalungan",
    "Dingalan",
    "Dipaculao",
    "Maria Aurora",
    "San Luis",
  ],
  Basilan: [
    "Akbar",
    "Al-Barka",
    "Hadji Mohammad Ajul",
    "Hadji Muhtamad",
    "Isabela City",
    "Lamitan",
    "Lantawan",
    "Maluso",
    "Sumisip",
    "Tabuan-Lasa",
    "Tipo-Tipo",
    "Tuburan",
    "Ungkaya Pukan",
  ],
  Bataan: [
    "Abucay",
    "Bagac",
    "Balanga",
    "Dinalupihan",
    "Hermosa",
    "Limay",
    "Mariveles",
    "Morong",
    "Orani",
    "Orion",
    "Pilar",
    "Samal",
  ],
  Batanes: ["Basco", "Itbayat", "Ivana", "Mahatao", "Sabtang", "Uyugan"],
  Batangas: [
    "Agoncillo",
    "Alitagtag",
    "Balayan",
    "Balete",
    "Batangas City",
    "Bauan",
    "Calaca",
    "Calatagan",
    "Cuenca",
    "Ibaan",
    "Laurel",
    "Lemery",
    "Lian",
    "Lipa",
    "Lobo",
    "Mabini",
    "Malvar",
    "Mataasnakahoy",
    "Nasugbu",
    "Padre Garcia",
    "Rosario",
    "San Jose",
    "San Juan",
    "San Luis",
    "San Nicolas",
    "San Pascual",
    "Santa Teresita",
    "Santo Tomas",
    "Taal",
    "Talisay",
    "Tanauan",
    "Taysan",
    "Tingloy",
    "Tuy",
  ],
  Benguet: [
    "Atok",
    "Baguio",
    "Bakun",
    "Bokod",
    "Buguias",
    "Itogon",
    "Kabayan",
    "Kapangan",
    "Kibungan",
    "La Trinidad",
    "Mankayan",
    "Sablan",
    "Tuba",
    "Tublay",
  ],
  Biliran: [
    "Almeria",
    "Biliran",
    "Cabucgayan",
    "Caibiran",
    "Culaba",
    "Kawayan",
    "Maripipi",
    "Naval",
  ],
  Bohol: [
    "Alburquerque",
    "Alicia",
    "Anda",
    "Antequera",
    "Baclayon",
    "Balilihan",
    "Batuan",
    "Bien Unido",
    "Bilar",
    "Buenavista",
    "Calape",
    "Candijay",
    "Carmen",
    "Catigbian",
    "Clarin",
    "Corella",
    "Cortes",
    "Dagohoy",
    "Danao",
    "Dauis",
    "Dimiao",
    "Duero",
    "Garcia Hernandez",
    "Getafe",
    "Guindulman",
    "Inabanga",
    "Jagna",
    "Lila",
    "Loay",
    "Loboc",
    "Loon",
    "Mabini",
    "Maribojoc",
    "Panglao",
    "Pilar",
    "President Carlos P. Garcia (Pitogo)",
    "Sagbayan (Borja)",
    "San Isidro",
    "San Miguel",
    "Sevilla",
    "Sierra Bullones",
    "Sikatuna",
    "Tagbilaran",
    "Talibon",
    "Trinidad",
    "Tubigon",
    "Ubay",
    "Valencia",
  ],
  Bukidnon: [
    "Baungon",
    "Cabanglasan",
    "Damulog",
    "Dangcagan",
    "Don Carlos",
    "Impasugong",
    "Kadingilan",
    "Kalilangan",
    "Kibawe",
    "Kitaotao",
    "Lantapan",
    "Libona",
    "Malaybalay",
    "Malitbog",
    "Manolo Fortich",
    "Maramag",
    "Pangantucan",
    "Quezon",
    "San Fernando",
    "Sumilao",
    "Talakag",
    "Valencia",
  ],
  Bulacan: [
    "Angat",
    "Balagtas (Bigaa)",
    "Baliuag",
    "Bocaue",
    "Bulakan",
    "Bustos",
    "Calumpit",
    "Doña Remedios Trinidad",
    "Guiguinto",
    "Hagonoy",
    "Malolos",
    "Marilao",
    "Meycauayan",
    "Norzagaray",
    "Obando",
    "Pandi",
    "Paombong",
    "Plaridel",
    "Pulilan",
    "San Ildefonso",
    "San Jose del Monte",
    "San Miguel",
    "San Rafael",
    "Santa Maria",
  ],
  Cagayan: [
    "Abulug",
    "Alcala",
    "Allacapan",
    "Amulung",
    "Aparri",
    "Baggao",
    "Ballesteros",
    "Buguey",
    "Calayan",
    "Camalaniugan",
    "Claveria",
    "Enrile",
    "Gattaran",
    "Gonzaga",
    "Iguig",
    "Lal-lo",
    "Lasam",
    "Pamplona",
    "Peñablanca",
    "Piat",
    "Rizal",
    "Sanchez-Mira",
    "Santa Ana",
    "Santa Praxedes",
    "Santa Teresita",
    "Santo Niño (Faire)",
    "Solana",
    "Tuao",
    "Tuguegarao",
  ],
  "Camarines Norte": [
    "Basud",
    "Capalonga",
    "Daet",
    "Jose Panganiban",
    "Labo",
    "Mercedes",
    "Paracale",
    "San Lorenzo Ruiz (Imelda)",
    "San Vicente",
    "Santa Elena",
    "Talisay",
    "Vinzons",
  ],
  "Camarines Sur": [
    "Baao",
    "Balatan",
    "Bato",
    "Bombon",
    "Buhi",
    "Bula",
    "Cabusao",
    "Calabanga",
    "Camaligan",
    "Canaman",
    "Caramoan",
    "Del Gallego",
    "Gainza",
    "Garchitorena",
    "Goa",
    "Iriga",
    "Lagonoy",
    "Libmanan",
    "Lupi",
    "Magarao",
    "Milaor",
    "Minalabac",
    "Nabua",
    "Naga",
    "Ocampo",
    "Pamplona",
    "Pasacao",
    "Pili",
    "Presentacion (Parubcan)",
    "Ragay",
    "Sagñay",
    "San Fernando",
    "San Jose",
    "Sipocot",
    "Siruma",
    "Tigaon",
    "Tinambac",
  ],
  Camiguin: ["Catarman", "Guinsiliban", "Mahinog", "Mambajao", "Sagay"],
  Capiz: [
    "Cuartero",
    "Dao",
    "Dumalag",
    "Dumarao",
    "Ivisan",
    "Jamindan",
    "Maayon",
    "Mambusao",
    "Panay",
    "Panitan",
    "Pilar",
    "Pontevedra",
    "President Roxas",
    "Roxas City",
    "Sapian",
    "Sigma",
    "Tapaz",
  ],
  Catanduanes: [
    "Bagamanoc",
    "Baras",
    "Bato",
    "Caramoran",
    "Gigmoto",
    "Pandan",
    "Panganiban (Payo)",
    "San Andres (Calolbon)",
    "San Miguel",
    "Viga",
    "Virac",
  ],
  Cavite: [
    "Alfonso",
    "Amadeo",
    "Bacoor",
    "Carmona",
    "Cavite City",
    "Dasmariñas",
    "General Emilio Aguinaldo",
    "General Mariano Alvarez",
    "General Trias",
    "Imus",
    "Indang",
    "Kawit",
    "Magallanes",
    "Maragondon",
    "Mendez (Mendez-Nuñez)",
    "Naic",
    "Noveleta",
    "Rosario",
    "Silang",
    "Tagaytay",
    "Tanza",
    "Ternate",
    "Trece Martires",
  ],
  Cebu: [
    "Alcantara",
    "Alcoy",
    "Alegria",
    "Aloguinsan",
    "Argao",
    "Asturias",
    "Badian",
    "Balamban",
    "Bantayan",
    "Barili",
    "Bogo",
    "Boljoon",
    "Borbon",
    "Carcar",
    "Carmen",
    "Catmon",
    "Cebu City",
    "Compostela",
    "Consolacion",
    "Cordova",
    "Daanbantayan",
    "Dalaguete",
    "Danao",
    "Dumanjug",
    "Ginatilan",
    "Lapu-Lapu (Opon)",
    "Liloan",
    "Madridejos",
    "Malabuyoc",
    "Mandaue",
    "Medellin",
    "Minglanilla",
    "Moalboal",
    "Naga",
    "Oslob",
    "Pilar",
    "Pinamungajan",
    "Poro",
    "Ronda",
    "Samboan",
    "San Fernando",
    "San Francisco",
    "San Remigio",
    "Santa Fe",
    "Santander",
    "Sibonga",
    "Sogod",
    "Tabogon",
    "Tabuelan",
    "Talisay",
    "Toledo",
    "Tuburan",
    "Tudela",
  ],
  Cotabato: [
    "Alamada",
    "Aleosan",
    "Antipas",
    "Arakan",
    "Banisilan",
    "Carmen",
    "Kabacan",
    "Kidapawan",
    "Libungan",
    "M'lang",
    "Magpet",
    "Makilala",
    "Matalam",
    "Midsayap",
    "Pigcawayan",
    "Pikit",
    "President Roxas",
    "Tulunan",
  ],
  "Davao de Oro": [
    "Compostela",
    "Laak (San Vicente)",
    "Mabini (Doña Alicia)",
    "Maco",
    "Maragusan (San Mariano)",
    "Mawab",
    "Monkayo",
    "Montevista",
    "Nabunturan",
    "New Bataan",
    "Pantukan",
  ],
  "Davao del Norte": [
    "Asuncion (Saug)",
    "Braulio E. Dujali",
    "Carmen",
    "Kapalong",
    "New Corella",
    "Panabo",
    "Samal",
    "San Isidro",
    "Santo Tomas",
    "Tagum",
    "Talaingod",
  ],
  "Davao del Sur": [
    "Bansalan",
    "Davao City",
    "Digos",
    "Hagonoy",
    "Kiblawan",
    "Magsaysay",
    "Malalag",
    "Matanao",
    "Padada",
    "Santa Cruz",
    "Sulop",
  ],
  "Davao Occidental": [
    "Don Marcelino",
    "Jose Abad Santos (Trinidad)",
    "Malita",
    "Santa Maria",
    "Sarangani",
  ],
  "Davao Oriental": [
    "Baganga",
    "Banaybanay",
    "Boston",
    "Caraga",
    "Cateel",
    "Governor Generoso",
    "Lupon",
    "Manay",
    "Mati",
    "San Isidro",
    "Tarragona",
  ],
  "Dinagat Islands": [
    "Basilisa (Rizal)",
    "Cagdianao",
    "Dinagat",
    "Libjo (Albor)",
    "Loreto",
    "San Jose",
    "Tubajon",
  ],
  "Eastern Samar": [
    "Arteche",
    "Balangiga",
    "Balangkayan",
    "Borongan",
    "Can-avid",
    "Dolores",
    "General MacArthur",
    "Giporlos",
    "Guiuan",
    "Hernani",
    "Jipapad",
    "Lawaan",
    "Llorente",
    "Maslog",
    "Maydolong",
    "Mercedes",
    "Oras",
    "Quinapondan",
    "Salcedo",
    "San Julian",
    "San Policarpo",
    "Sulat",
    "Taft",
  ],
  Guimaras: [
    "Buenavista",
    "Jordan",
    "Nueva Valencia",
    "San Lorenzo",
    "Sibunag",
  ],
  Ifugao: [
    "Aguinaldo",
    "Alfonso Lista (Potia)",
    "Asipulo",
    "Banaue",
    "Hingyon",
    "Hungduan",
    "Kiangan",
    "Lagawe",
    "Lamut",
    "Mayoyao",
    "Tinoc",
  ],
  "Ilocos Norte": [
    "Adams",
    "Bacarra",
    "Badoc",
    "Bangui",
    "Banna (Espiritu)",
    "Batac",
    "Burgos",
    "Carasi",
    "Currimao",
    "Dingras",
    "Dumalneg",
    "Laoag",
    "Marcos",
    "Nueva Era",
    "Pagudpud",
    "Paoay",
    "Pasuquin",
    "Piddig",
    "Pinili",
    "San Nicolas",
    "Sarrat",
    "Solsona",
    "Vintar",
  ],
  "Ilocos Sur": [
    "Alilem",
    "Banayoyo",
    "Bantay",
    "Burgos",
    "Cabugao",
    "Candon",
    "Caoayan",
    "Cervantes",
    "Galimuyod",
    "Gregorio del Pilar (Concepcion)",
    "Lidlidda",
    "Magsingal",
    "Nagbukel",
    "Narvacan",
    "Quirino (Angkaki)",
    "Salcedo (Baugen)",
    "San Emilio",
    "San Esteban",
    "San Ildefonso",
    "San Juan (Lapog)",
    "San Vicente",
    "Santa",
    "Santa Catalina",
    "Santa Cruz",
    "Santa Lucia",
    "Santa Maria",
    "Santiago",
    "Santo Domingo",
    "Sigay",
    "Sinait",
    "Sugpon",
    "Suyo",
    "Tagudin",
    "Vigan",
  ],
  Iloilo: [
    "Ajuy",
    "Alimodian",
    "Anilao",
    "Badiangan",
    "Balasan",
    "Banate",
    "Barotac Nuevo",
    "Barotac Viejo",
    "Batad",
    "Bingawan",
    "Cabatuan",
    "Calinog",
    "Carles",
    "Concepcion",
    "Dingle",
    "Dueñas",
    "Dumangas",
    "Estancia",
    "Guimbal",
    "Igbaras",
    "Iloilo City",
    "Janiuay",
    "Lambunao",
    "Leganes",
    "Lemery",
    "Leon",
    "Maasin",
    "Miagao",
    "Mina",
    "New Lucena",
    "Oton",
    "Passi",
    "Pavia",
    "Pototan",
    "San Dionisio",
    "San Enrique",
    "San Joaquin",
    "San Miguel",
    "San Rafael",
    "Santa Barbara",
    "Sara",
    "Tigbauan",
    "Tubungan",
    "Zarraga",
  ],
  Isabela: [
    "Alicia",
    "Angadanan",
    "Aurora",
    "Benito Soliven",
    "Burgos",
    "Cabagan",
    "Cabatuan",
    "Cauayan",
    "Cordon",
    "Delfin Albano (Magsaysay)",
    "Dinapigue",
    "Divilacan",
    "Echague",
    "Gamu",
    "Ilagan",
    "Jones",
    "Luna",
    "Maconacon",
    "Mallig",
    "Naguilian",
    "Palanan",
    "Quezon",
    "Quirino",
    "Ramon",
    "Reina Mercedes",
    "Roxas",
    "San Agustin",
    "San Guillermo",
    "San Isidro",
    "San Manuel (Callang)",
    "San Mariano",
    "San Mateo",
    "San Pablo",
    "Santa Maria",
    "Santiago",
    "Santo Tomas",
    "Tumauini",
  ],
  Kalinga: [
    "Balbalan",
    "Lubuagan",
    "Pasil",
    "Pinukpuk",
    "Rizal (Liwan)",
    "Tabuk",
    "Tanudan",
    "Tinglayan",
  ],
  "La Union": [
    "Agoo",
    "Aringay",
    "Bacnotan",
    "Bagulin",
    "Balaoan",
    "Bangar",
    "Bauang",
    "Burgos",
    "Caba",
    "Luna",
    "Naguilian",
    "Pugo",
    "Rosario",
    "San Fernando",
    "San Gabriel",
    "San Juan",
    "Santo Tomas",
    "Santol",
    "Sudipen",
    "Tubao",
  ],
  Laguna: [
    "Alaminos",
    "Bay",
    "Biñan",
    "Cabuyao",
    "Calamba",
    "Calauan",
    "Cavinti",
    "Famy",
    "Kalayaan",
    "Liliw",
    "Los Baños",
    "Luisiana",
    "Lumban",
    "Mabitac",
    "Magdalena",
    "Majayjay",
    "Nagcarlan",
    "Paete",
    "Pagsanjan",
    "Pakil",
    "Pangil",
    "Pila",
    "Rizal",
    "San Pablo",
    "San Pedro",
    "Santa Cruz",
    "Santa Maria",
    "Santa Rosa",
    "Siniloan",
    "Victoria",
  ],
  "Lanao del Norte": [
    "Bacolod",
    "Baloi",
    "Baroy",
    "Iligan",
    "Kapatagan",
    "Kauswagan",
    "Kolambugan",
    "Lala",
    "Linamon",
    "Magsaysay",
    "Maigo",
    "Matungao",
    "Munai",
    "Nunungan",
    "Pantao Ragat",
    "Pantar",
    "Poona Piagapo",
    "Salvador",
    "Sapad",
    "Sultan Naga Dimaporo (Karomatan)",
    "Tagoloan",
    "Tangcal",
    "Tubod",
  ],
  "Lanao del Sur": [
    "Amai Manabilang (Bumbaran)",
    "Bacolod-Kalawi (Bacolod-Grande)",
    "Balabagan",
    "Balindong (Watu)",
    "Bayang",
    "Binidayan",
    "Buadiposo-Buntong",
    "Bubong",
    "Butig",
    "Calanogas",
    "Ditsaan-Ramain",
    "Ganassi",
    "Kapai",
    "Kapatagan",
    "Lumba-Bayabao (Maguing)",
    "Lumbaca-Unayan",
    "Lumbatan",
    "Lumbayanague",
    "Madalum",
    "Madamba",
    "Maguing",
    "Malabang",
    "Marantao",
    "Marawi",
    "Marogong",
    "Masiu",
    "Mulondo",
    "Pagayawan (Tatarikan)",
    "Piagapo",
    "Picong (Sultan Gumander)",
    "Poona Bayabao (Gata)",
    "Pualas",
    "Saguiaran",
    "Sultan Dumalondong",
    "Tagoloan II",
    "Tamparan",
    "Taraka",
    "Tubaran",
    "Tugaya",
    "Wao",
  ],
  Leyte: [
    "Abuyog",
    "Alangalang",
    "Albuera",
    "Babatngon",
    "Barugo",
    "Bato",
    "Baybay",
    "Burauen",
    "Calubian",
    "Capoocan",
    "Carigara",
    "Dagami",
    "Dulag",
    "Hilongos",
    "Hindang",
    "Inopacan",
    "Isabel",
    "Jaro",
    "Javier (Bugho)",
    "Julita",
    "Kananga",
    "La Paz",
    "Leyte",
    "MacArthur",
    "Mahaplag",
    "Matag-ob",
    "Matalom",
    "Mayorga",
    "Merida",
    "Ormoc",
    "Palo",
    "Palompon",
    "Pastrana",
    "San Isidro",
    "San Miguel",
    "Santa Fe",
    "Tabango",
    "Tabontabon",
    "Tacloban",
    "Tanauan",
    "Tolosa",
    "Tunga",
    "Villaba",
  ],
  Maguindanao: [
    "Ampatuan",
    "Barira",
    "Buldon",
    "Buluan",
    "Cotabato City",
    "Datu Abdullah Sangki",
    "Datu Anggal Midtimbang",
    "Datu Blah T. Sinsuat",
    "Datu Hoffer Ampatuan",
    "Datu Montawal (Pagagawan)",
    "Datu Odin Sinsuat (Dinaig)",
    "Datu Paglas",
    "Datu Piang (Dulawan)",
    "Datu Salibo",
    "Datu Saudi-Ampatuan",
    "Datu Unsay",
    "General Salipada K. Pendatun",
    "Guindulungan",
    "Kabuntalan (Tumbao)",
    "Mamasapano",
    "Mangudadatu",
    "Matanog",
    "Northern Kabuntalan",
    "Pagalungan",
    "Paglat",
    "Pandag",
    "Parang",
    "Rajah Buayan",
    "Shariff Aguak (Maganoy)",
    "Shariff Saydona Mustapha",
    "South Upi",
    "Sultan Kudarat (Nuling)",
    "Sultan Mastura",
    "Sultan sa Barongis (Lambayong)",
    "Sultan Sumagka (Talitay)",
    "Talayan",
    "Upi",
  ],
  Marinduque: [
    "Boac",
    "Buenavista",
    "Gasan",
    "Mogpog",
    "Santa Cruz",
    "Torrijos",
  ],
  Masbate: [
    "Aroroy",
    "Baleno",
    "Balud",
    "Batuan",
    "Cataingan",
    "Cawayan",
    "Claveria",
    "Dimasalang",
    "Esperanza",
    "Mandaon",
    "Masbate City",
    "Milagros",
    "Mobo",
    "Monreal",
    "Palanas",
    "Pio V. Corpuz (Limbuhan)",
    "Placer",
    "San Fernando",
    "San Jacinto",
    "San Pascual",
    "Uson",
  ],
  "Misamis Occidental": [
    "Aloran",
    "Baliangao",
    "Bonifacio",
    "Calamba",
    "Clarin",
    "Concepcion",
    "Don Victoriano Chiongbian (Don Mariano Marcos)",
    "Jimenez",
    "Lopez Jaena",
    "Oroquieta",
    "Ozamiz",
    "Panaon",
    "Plaridel",
    "Sapang Dalaga",
    "Sinacaban",
    "Tangub",
    "Tudela",
  ],
  "Misamis Oriental": [
    "Alubijid",
    "Balingasag",
    "Balingoan",
    "Binuangan",
    "Cagayan de Oro",
    "Claveria",
    "El Salvador",
    "Gingoog",
    "Gitagum",
    "Initao",
    "Jasaan",
    "Kinoguitan",
    "Lagonglong",
    "Laguindingan",
    "Libertad",
    "Lugait",
    "Magsaysay (Linugos)",
    "Manticao",
    "Medina",
    "Naawan",
    "Opol",
    "Salay",
    "Sugbongcogon",
    "Tagoloan",
    "Talisayan",
    "Villanueva",
  ],
  "Mountain Province": [
    "Barlig",
    "Bauko",
    "Besao",
    "Bontoc",
    "Natonin",
    "Paracelis",
    "Sabangan",
    "Sadanga",
    "Sagada",
    "Tadian",
  ],
  "Metro Manila": [
    "Caloocan",
    "Las Piñas",
    "Makati",
    "Malabon",
    "Mandaluyong",
    "Manila",
    "Marikina",
    "Muntinlupa",
    "Navotas",
    "Parañaque",
    "Pasay",
    "Pasig",
    "Pateros",
    "Quezon City",
    "San Juan",
    "Taguig",
    "Valenzuela",
  ],
  "Negros Occidental": [
    "Bacolod",
    "Bago",
    "Binalbagan",
    "Cadiz",
    "Calatrava",
    "Candoni",
    "Cauayan",
    "Enrique B. Magalona (Saravia)",
    "Escalante",
    "Himamaylan",
    "Hinigaran",
    "Hinoba-an (Asia)",
    "Ilog",
    "Isabela",
    "Kabankalan",
    "La Carlota",
    "La Castellana",
    "Manapla",
    "Moises Padilla (Magallon)",
    "Murcia",
    "Pontevedra",
    "Pulupandan",
    "Sagay",
    "Salvador Benedicto",
    "San Carlos",
    "San Enrique",
    "Silay",
    "Sipalay",
    "Talisay",
    "Toboso",
    "Valladolid",
    "Victorias",
  ],
  "Negros Oriental": [
    "Amlan (Ayuquitan)",
    "Ayungon",
    "Bacong",
    "Bais",
    "Basay",
    "Bayawan (Tulong)",
    "Bindoy (Payabon)",
    "Canlaon",
    "Dauin",
    "Dumaguete",
    "Guihulngan",
    "Jimalalud",
    "La Libertad",
    "Mabinay",
    "Manjuyod",
    "Pamplona",
    "San Jose",
    "Santa Catalina",
    "Siaton",
    "Sibulan",
    "Tanjay",
    "Tayasan",
    "Valencia (Luzurriaga)",
    "Vallehermoso",
    "Zamboanguita",
  ],
  "Northern Samar": [
    "Allen",
    "Biri",
    "Bobon",
    "Capul",
    "Catarman",
    "Catubig",
    "Gamay",
    "Laoang",
    "Lapinig",
    "Las Navas",
    "Lavezares",
    "Lope de Vega",
    "Mapanas",
    "Mondragon",
    "Palapag",
    "Pambujan",
    "Rosario",
    "San Antonio",
    "San Isidro",
    "San Jose",
    "San Roque",
    "San Vicente",
    "Silvino Lobos",
    "Victoria",
  ],
  "Nueva Ecija": [
    "Aliaga",
    "Bongabon",
    "Cabanatuan",
    "Cabiao",
    "Carranglan",
    "Cuyapo",
    "Gabaldon (Bitulok & Sabani)",
    "Gapan",
    "General Mamerto Natividad",
    "General Tinio (Papaya)",
    "Guimba",
    "Jaen",
    "Laur",
    "Licab",
    "Llanera",
    "Lupao",
    "Muñoz",
    "Nampicuan",
    "Palayan",
    "Pantabangan",
    "Peñaranda",
    "Quezon",
    "Rizal",
    "San Antonio",
    "San Isidro",
    "San Jose",
    "San Leonardo",
    "Santa Rosa",
    "Santo Domingo",
    "Talavera",
    "Talugtug",
    "Zaragoza",
  ],
  "Nueva Vizcaya": [
    "Alfonso Castañeda",
    "Ambaguio",
    "Aritao",
    "Bagabag",
    "Bambang",
    "Bayombong",
    "Diadi",
    "Dupax del Norte",
    "Dupax del Sur",
    "Kasibu",
    "Kayapa",
    "Quezon",
    "Santa Fe (Imugan)",
    "Solano",
    "Villaverde (Ibung)",
  ],
  "Occidental Mindoro": [
    "Abra de Ilog",
    "Calintaan",
    "Looc",
    "Lubang",
    "Magsaysay",
    "Mamburao",
    "Paluan",
    "Rizal",
    "Sablayan",
    "San Jose",
    "Santa Cruz",
  ],
  "Oriental Mindoro": [
    "Baco",
    "Bansud",
    "Bongabong",
    "Bulalacao (San Pedro)",
    "Calapan",
    "Gloria",
    "Mansalay",
    "Naujan",
    "Pinamalayan",
    "Pola",
    "Puerto Galera",
    "Roxas",
    "San Teodoro",
    "Socorro",
    "Victoria",
  ],
  Palawan: [
    "Aborlan",
    "Agutaya",
    "Araceli",
    "Balabac",
    "Bataraza",
    "Brooke's Point",
    "Busuanga",
    "Cagayancillo",
    "Coron",
    "Culion",
    "Cuyo",
    "Dumaran",
    "El Nido (Bacuit)",
    "Kalayaan",
    "Linapacan",
    "Magsaysay",
    "Narra",
    "Puerto Princesa",
    "Quezon",
    "Rizal (Marcos)",
    "Roxas",
    "San Vicente",
    "Sofronio Española",
    "Taytay",
  ],
  Pampanga: [
    "Angeles",
    "Apalit",
    "Arayat",
    "Bacolor",
    "Candaba",
    "Floridablanca",
    "Guagua",
    "Lubao",
    "Mabalacat",
    "Macabebe",
    "Magalang",
    "Masantol",
    "Mexico",
    "Minalin",
    "Porac",
    "San Fernando",
    "San Luis",
    "San Simon",
    "Santa Ana",
    "Santa Rita",
    "Santo Tomas",
    "Sasmuan",
  ],
  Pangasinan: [
    "Agno",
    "Aguilar",
    "Alaminos",
    "Alcala",
    "Anda",
    "Asingan",
    "Balungao",
    "Bani",
    "Basista",
    "Bautista",
    "Bayambang",
    "Binalonan",
    "Binmaley",
    "Bolinao",
    "Bugallon",
    "Burgos",
    "Calasiao",
    "Dagupan",
    "Dasol",
    "Infanta",
    "Labrador",
    "Laoac",
    "Lingayen",
    "Mabini",
    "Malasiqui",
    "Manaoag",
    "Mangaldan",
    "Mangatarem",
    "Mapandan",
    "Natividad",
    "Pozorrubio",
    "Rosales",
    "San Carlos",
    "San Fabian",
    "San Jacinto",
    "San Manuel",
    "San Nicolas",
    "San Quintin",
    "Santa Barbara",
    "Santa Maria",
    "Santo Tomas",
    "Sison",
    "Sual",
    "Tayug",
    "Umingan",
    "Urbiztondo",
    "Urdaneta",
    "Villasis",
  ],
  Quezon: [
    "Agdangan",
    "Alabat",
    "Atimonan",
    "Buenavista",
    "Burdeos",
    "Calauag",
    "Candelaria",
    "Catanauan",
    "Dolores",
    "General Luna",
    "General Nakar",
    "Guinayangan",
    "Gumaca",
    "Infanta",
    "Jomalig",
    "Lopez",
    "Lucban",
    "Lucena",
    "Macalelon",
    "Mauban",
    "Mulanay",
    "Padre Burgos",
    "Pagbilao",
    "Panukulan",
    "Patnanungan",
    "Perez",
    "Pitogo",
    "Plaridel",
    "Polillo",
    "Quezon",
    "Real",
    "Sampaloc",
    "San Andres",
    "San Antonio",
    "San Francisco (Aurora)",
    "San Narciso",
    "Sariaya",
    "Tagkawayan",
    "Tayabas",
    "Tiaong",
    "Unisan",
  ],
  Quirino: [
    "Aglipay",
    "Cabarroguis",
    "Diffun",
    "Maddela",
    "Nagtipunan",
    "Saguday",
  ],
  Rizal: [
    "Angono",
    "Antipolo",
    "Baras",
    "Binangonan",
    "Cainta",
    "Cardona",
    "Jalajala",
    "Morong",
    "Pililla",
    "Rodriguez (Montalban)",
    "San Mateo",
    "Tanay",
    "Taytay",
    "Teresa",
  ],
  Romblon: [
    "Alcantara",
    "Banton (Jones)",
    "Cajidiocan",
    "Calatrava",
    "Concepcion",
    "Corcuera",
    "Ferrol",
    "Looc",
    "Magdiwang",
    "Odiongan",
    "Romblon",
    "San Agustin",
    "San Andres",
    "San Fernando",
    "San Jose",
    "Santa Fe",
    "Santa Maria (Imelda)",
  ],
  Samar: [
    "Almagro",
    "Basey",
    "Calbayog",
    "Calbiga",
    "Catbalogan",
    "Daram",
    "Gandara",
    "Hinabangan",
    "Jiabong",
    "Marabut",
    "Matuguinao",
    "Motiong",
    "Pagsanghan",
    "Paranas (Wright)",
    "Pinabacdao",
    "San Jorge",
    "San Jose de Buan",
    "San Sebastian",
    "Santa Margarita",
    "Santa Rita",
    "Santo Niño",
    "Tagapul-an",
    "Talalora",
    "Tarangnan",
    "Villareal",
    "Zumarraga",
  ],
  Sarangani: [
    "Alabel",
    "Glan",
    "Kiamba",
    "Maasim",
    "Maitum",
    "Malapatan",
    "Malungon",
  ],
  Siquijor: [
    "Enrique Villanueva",
    "Larena",
    "Lazi",
    "Maria",
    "San Juan",
    "Siquijor",
  ],
  Sorsogon: [
    "Barcelona",
    "Bulan",
    "Bulusan",
    "Casiguran",
    "Castilla",
    "Donsol",
    "Gubat",
    "Irosin",
    "Juban",
    "Magallanes",
    "Matnog",
    "Pilar",
    "Prieto Diaz",
    "Santa Magdalena",
    "Sorsogon City",
  ],
  "South Cotabato": [
    "Banga",
    "General Santos (Dadiangas)",
    "Koronadal",
    "Lake Sebu",
    "Norala",
    "Polomolok",
    "Santo Niño",
    "Surallah",
    "T'Boli",
    "Tampakan",
    "Tantangan",
    "Tupi",
  ],
  "Southern Leyte": [
    "Anahawan",
    "Bontoc",
    "Hinunangan",
    "Hinundayan",
    "Libagon",
    "Liloan",
    "Limasawa",
    "Maasin",
    "Macrohon",
    "Malitbog",
    "Padre Burgos",
    "Pintuyan",
    "Saint Bernard",
    "San Francisco",
    "San Juan (Cabalian)",
    "San Ricardo",
    "Silago",
    "Sogod",
    "Tomas Oppus",
  ],
  "Sultan Kudarat": [
    "Bagumbayan",
    "Columbio",
    "Esperanza",
    "Isulan",
    "Kalamansig",
    "Lambayong (Mariano Marcos)",
    "Lebak",
    "Lutayan",
    "Palimbang",
    "President Quirino",
    "Senator Ninoy Aquino",
    "Tacurong",
  ],
  Sulu: [
    "Banguingui (Tongkil)",
    "Hadji Panglima Tahil (Marunggas)",
    "Indanan",
    "Jolo",
    "Kalingalan Caluang",
    "Lugus",
    "Luuk",
    "Maimbung",
    "Old Panamao",
    "Omar",
    "Pandami",
    "Panglima Estino (New Panamao)",
    "Pangutaran",
    "Parang",
    "Pata",
    "Patikul",
    "Siasi",
    "Talipao",
    "Tapul",
  ],
  "Surigao del Norte": [
    "Alegria",
    "Bacuag",
    "Burgos",
    "Claver",
    "Dapa",
    "Del Carmen",
    "General Luna",
    "Gigaquit",
    "Mainit",
    "Malimono",
    "Pilar",
    "Placer",
    "San Benito",
    "San Francisco (Anao-Aon)",
    "San Isidro",
    "Santa Monica (Sapao)",
    "Sison",
    "Socorro",
    "Surigao City",
    "Tagana-an",
    "Tubod",
  ],
  "Surigao del Sur": [
    "Barobo",
    "Bayabas",
    "Bislig",
    "Cagwait",
    "Cantilan",
    "Carmen",
    "Carrascal",
    "Cortes",
    "Hinatuan",
    "Lanuza",
    "Lianga",
    "Lingig",
    "Madrid",
    "Marihatag",
    "San Agustin",
    "San Miguel",
    "Tagbina",
    "Tago",
    "Tandag",
  ],
  Tarlac: [
    "Anao",
    "Bamban",
    "Camiling",
    "Capas",
    "Concepcion",
    "Gerona",
    "La Paz",
    "Mayantoc",
    "Moncada",
    "Paniqui",
    "Pura",
    "Ramos",
    "San Clemente",
    "San Jose",
    "San Manuel",
    "Santa Ignacia",
    "Tarlac City",
    "Victoria",
  ],
  "Tawi-Tawi": [
    "Bongao",
    "Languyan",
    "Mapun (Cagayan de Tawi-Tawi)",
    "Panglima Sugala (Balimbing)",
    "Sapa-Sapa",
    "Sibutu",
    "Simunul",
    "Sitangkai",
    "South Ubian",
    "Tandubas",
    "Turtle Islands (Taganak)",
  ],
  Zambales: [
    "Botolan",
    "Cabangan",
    "Candelaria",
    "Castillejos",
    "Iba",
    "Masinloc",
    "Olongapo",
    "Palauig",
    "San Antonio",
    "San Felipe",
    "San Marcelino",
    "San Narciso",
    "Santa Cruz",
    "Subic",
  ],
  "Zamboanga del Norte": [
    "Baliguian",
    "Dapitan",
    "Dipolog",
    "Godod",
    "Gutalac",
    "Jose Dalman (Ponot)",
    "Kalawit",
    "Katipunan",
    "La Libertad",
    "Labason",
    "Leon B. Postigo (Bacungan)",
    "Liloy",
    "Manukan",
    "Mutia",
    "Piñan (New Piñan)",
    "Polanco",
    "President Manuel A. Roxas",
    "Rizal",
    "Salug",
    "Sergio Osmeña Sr.",
    "Siayan",
    "Sibuco",
    "Sibutad",
    "Sindangan",
    "Siocon",
    "Sirawai",
    "Tampilisan",
  ],
  "Zamboanga del Sur": [
    "Aurora",
    "Bayog",
    "Dimataling",
    "Dinas",
    "Dumalinao",
    "Dumingag",
    "Guipos",
    "Josefina",
    "Kumalarang",
    "Labangan",
    "Lakewood",
    "Lapuyan",
    "Mahayag",
    "Margosatubig",
    "Midsalip",
    "Molave",
    "Pagadian",
    "Pitogo",
    "Ramon Magsaysay (Liargo)",
    "San Miguel",
    "San Pablo",
    "Sominot (Don Mariano Marcos)",
    "Tabina",
    "Tambulig",
    "Tigbao",
    "Tukuran",
    "Vincenzo A. Sagun",
    "Zamboanga City",
  ],
  "Zamboanga Sibugay": [
    "Alicia",
    "Buug",
    "Diplahan",
    "Imelda",
    "Ipil",
    "Kabasalan",
    "Mabuhay",
    "Malangas",
    "Naga",
    "Olutanga",
    "Payao",
    "Roseller Lim",
    "Siay",
    "Talusan",
    "Titay",
    "Tungawan",
  ],
};

export const PROVINCES = [
  "Abra",
  "Agusan del Norte",
  "Agusan del Sur",
  "Aklan",
  "Albay",
  "Antique",
  "Apayao",
  "Aurora",
  "Basilan",
  "Bataan",
  "Batanes",
  "Batangas",
  "Benguet",
  "Biliran",
  "Bohol",
  "Bukidnon",
  "Bulacan",
  "Cagayan",
  "Camarines Norte",
  "Camarines Sur",
  "Camiguin",
  "Capiz",
  "Catanduanes",
  "Cavite",
  "Cebu",
  "Cotabato",
  "Davao de Oro",
  "Davao del Norte",
  "Davao del Sur",
  "Davao Occidental",
  "Davao Oriental",
  "Dinagat Islands",
  "Eastern Samar",
  "Guimaras",
  "Ifugao",
  "Ilocos Norte",
  "Ilocos Sur",
  "Iloilo",
  "Isabela",
  "Kalinga",
  "La Union",
  "Laguna",
  "Lanao del Norte",
  "Lanao del Sur",
  "Leyte",
  "Maguindanao",
  "Marinduque",
  "Masbate",
  "Misamis Occidental",
  "Misamis Oriental",
  "Mountain Province",
  "Metro Manila",
  "Negros Occidental",
  "Negros Oriental",
  "Northern Samar",
  "Nueva Ecija",
  "Nueva Vizcaya",
  "Occidental Mindoro",
  "Oriental Mindoro",
  "Palawan",
  "Pampanga",
  "Pangasinan",
  "Quezon",
  "Quirino",
  "Rizal",
  "Romblon",
  "Samar",
  "Sarangani",
  "Siquijor",
  "Sorsogon",
  "South Cotabato",
  "Southern Leyte",
  "Sultan Kudarat",
  "Sulu",
  "Surigao del Norte",
  "Surigao del Sur",
  "Tarlac",
  "Tawi-Tawi",
  "Zambales",
  "Zamboanga del Norte",
  "Zamboanga del Sur",
  "Zamboanga Sibugay",
];
