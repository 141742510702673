import { Helmet } from "react-helmet";
import React from "react";

import "./HospitalApplications.scss";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";

import { IonLoading } from "@ionic/react";
import { MSGS_COMMON } from "../../constants/messages";
import { HospitalApplicationsList } from "../../components/HospitalApplicationsList/HospitalApplicationsList";

class HospitalApplications extends React.Component<MBProps> {
  state = {
    loading: false,
  };

  render = () => {
    const { loading } = this.state;
    return (
      <>
        <Helmet>
          <title>MedBook - Hospital - Applications</title>
        </Helmet>
        <MBContainer {...this.props} activePage="for-approvals">
          <HospitalApplicationsList {...this.props} />

          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />
        </MBContainer>
      </>
    );
  };
}

export default HospitalApplications;
