import _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { IonButton, IonLoading, IonSearchbar, IonToast } from "@ionic/react";

import "./HospitalPatientsList.scss";
import * as services from "../../services";
import { MBDialog } from "../../components/MBDialog/MBDialog";
import { MBAppointmentList } from "../../components/MBAppointmentList/MBAppointmentList";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps, UserType } from "../../interface";
import { Hospital, HospitalServiceAppointmentsView } from "../../models";
import { APPOINTMENT_STATUS_TYPES, MBCOLORS } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";

class HospitalPatientsList extends React.Component<MBProps> {
  state = {
    loading: false,
    error: "",
    successConfirmArrival: false,

    searchQuery: "",
    serviceAppointments: [] as HospitalServiceAppointmentsView[],
    filteredAppointments: [] as HospitalServiceAppointmentsView[],
  };

  componentDidMount = () => {
    this.getHospitalServiceAppointments();
  };

  updateAppointmentStatus = async (
    appointmentId: string,
    isDoctorAppointment: boolean
  ) => {
    try {
      const { authUser } = this.props;
      await services.pushAppointmentProgress(
        appointmentId,
        isDoctorAppointment,
        APPOINTMENT_STATUS_TYPES.onGoing.id,
        authUser.uid,
        authUser.userType as UserType
      );
      this.setState({ successConfirmArrival: true });
    } catch (error) {
      this.setState({ error });
    }
  };

  getHospitalServiceAppointments = async () => {
    const { authUser } = this.props;

    try {
      await services.getServiceAppointmentsPatientOrHospital(
        "hospital",
        (authUser.userDetails as Hospital).docId as string,
        (hospitalServiceAppointments, error) => {
          if (!error) {
            this.setState({
              serviceAppointments: _.filter(
                hospitalServiceAppointments,
                (appointment) => !appointment.isCancelled
              ),
            });
            setTimeout(() => {
              this.applyFilters();
            }, 200);
          }
        },
        new Date()
      );
    } catch (error) {
      console.log("Error -- ", error);
    }
  };
  applyFilters = () => {
    const { searchQuery, serviceAppointments } = this.state;

    const filteredServices = _.filter(serviceAppointments, (appointment) => {
      let isValid = true;
      const formattedSearchQuery = searchQuery.trim().toLowerCase();

      if (
        !_.isEmpty(searchQuery) &&
        !appointment.patientDetails.firstName
          .trim()
          .toLocaleLowerCase()
          .includes(formattedSearchQuery)
      ) {
        isValid = false;
      }
      return isValid;
    });

    this.setState({
      filteredAppointments: _.sortBy(filteredServices, "distance"),
    });
  };

  render = () => {
    const {
      searchQuery,
      serviceAppointments,
      filteredAppointments,
      successConfirmArrival,
      loading,
      error,
    } = this.state;
    return (
      <>
        <Helmet>
          <title>MedBook - Hospital - Patient List</title>
        </Helmet>
        <MBContainer {...this.props} activePage="search-patients">
          <div className="hospital-patient-list-container">
            <div className="hospital-patient-list-search-card-container">
              <IonSearchbar
                mode="ios"
                placeholder="Search keyword here..."
                value={searchQuery}
                onIonChange={(e) =>
                  this.setState({ searchQuery: e.detail.value })
                }
                className="hospital-patient-list-search-input mb-body ion-no-margin ion-no-padding ion-text-start"
              />

              <IonButton
                className="hospital-patient-list-search-submit mb-body"
                color={MBCOLORS.primary}
                mode="ios"
                disabled={loading}
                onClick={this.applyFilters}
              >
                Search
              </IonButton>
            </div>
            <MBAppointmentList
              mode="patient-list"
              appointmentData={
                !_.isEmpty(filteredAppointments)
                  ? filteredAppointments
                  : serviceAppointments
              }
              onClickView={(appointmentId, isDoctorAppointment) => {
                this.updateAppointmentStatus(
                  appointmentId,
                  isDoctorAppointment
                );
              }}
              onClickCancel={() => {}}
            />
          </div>
        </MBContainer>

        <IonLoading
          translucent={true}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />

        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => {
            this.setState({
              error: "",
            });
          }}
          color={MBCOLORS.danger}
        />

        <MBDialog
          isOpen={successConfirmArrival}
          title="Patient Arrival has been confirmed"
          message="Please assist the patient to their appointment."
          icon="success"
          onDidDismiss={() => {
            this.setState({
              successConfirmArrival: false,
            });
          }}
        />
      </>
    );
  };
}

export default HospitalPatientsList;
