import _ from "lodash";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonLoading,
  IonModal,
  IonToast,
} from "@ionic/react";

import "./HospitalAppointments.scss";
import * as api from "../../api";
import * as email from "../../functions/email";
import * as sms from "../../functions/sms";
import * as services from "../../services";
import {
  download,
  getAppointmentStatus,
  isHistoricalDate,
  isSameDate,
  toDateTimeFromSecs,
} from "../../functions/common";
import { CancelReasonDialog } from "../../components/CancelReasonDialog/CancelReasonDialog";
import { MBAppointmentList } from "../../components/MBAppointmentList/MBAppointmentList";
import { MBCalendar } from "../../components/MBCalendar/MBCalendar";
import { MBCalendarRangeDialog } from "../../components/MBCalendarRangeDialog/MBCalendarRangeDialog";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBDialog } from "../../components/MBDialog/MBDialog";
import { MBViewAnAppointment } from "../../components/MBViewAnAppointment/MBViewAnAppointment";
import {
  AppointmentFilterParams,
  AppointmentsFilterDialog,
} from "../../components/AppointmentsFilterDialog/AppointmentsFilterDialog";
import {
  MBAppointments,
  StatusTab,
} from "../../components/MBAppointments/MBAppointments";
import { MBProps, AppointmentView, UserType } from "../../interface";
import {
  HospitalDoctorAppointmentViewOLD,
  HospitalServiceAppointmentView,
  HospitalAppointmentDetails,
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
} from "../../models";
import { MSGS_COMMON } from "../../constants/messages";
import { MBCOLORS, APPOINTMENT_STATUS_TYPES } from "../../constants/config";

class HospitalAppointments extends React.Component<MBProps> {
  state = {
    viewMode: 2 as AppointmentView,
    selectedDate: new Date(),
    showCalendar: false,
    loading: false,
    error: "",
    servicesMap: {} as { [id: string]: string },
    futureAppointments: null as Date[] | null,
    startDateDownload: null as Date | null,
    endDateDownload: null as Date | null,
    isDownloadDialogOpen: false,
    isSuccessDialogOpen: false,
    isOpenClearAllReasonDialog: false,
    isOpenClearSchedule: false,
    isOpenSuccessClearDialog: false,
    isOpenCancelDialog: false,
    isOpenCancelSuccessDialog: false,
    filterDialogOpen: false,
    isMarkDoneOpen: false,
    showJobWellDone: false,
    status: "today" as StatusTab,
    selectedAppointment: {} as
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView,
    viewAppointment: false,
    doctorAppointments: [] as HospitalDoctorAppointmentsView[],
    serviceAppointments: [] as HospitalServiceAppointmentsView[],
    filteredAppointments: [] as (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[],
    filterParams: null as null | AppointmentFilterParams,
    appointmentViewMode: "list" as "list" | "calendar",
    appointmentToCancelData: {} as
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView,
    appointmentToMarkDone: {} as
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView,
  };

  checkHospitalHasAppointments = (
    appointments: (
      | HospitalDoctorAppointmentViewOLD
      | HospitalServiceAppointmentView
    )[]
  ) => {
    this.setState({ hasAppointments: !_.isEmpty(appointments) });
  };

  getAllFutureAppointments = async () => {
    const { authUser } = this.props;
    try {
      const hospital = await services.getHospitalWithRep(authUser.uid);
      services.getAllHospitalFutureAppointments(
        hospital!.docId || "",
        (appointments) => {
          this.setState({
            futureAppointments: null,
          });
          this.setState({
            futureAppointments: appointments,
          });
        }
      );
    } catch (e) {
      console.log("ERROR GETTING APPOINTMENTS");
    }
  };
  getServicesMap = async () => {
    try {
      const servicesMap = await services.getServiceMap();

      this.setState({
        servicesMap,
      });
    } catch (e) {
      this.setState({ error: e });
    }
  };
  componentDidMount = () => {
    this.getHospitalDoctorAppointments();
    this.getHospitalServiceAppointments();
    const { resourceType, appointmentId } = this.props.match.params as {
      resourceType: "doctor" | "service";
      appointmentId: string;
    };

    if (!_.isEmpty(resourceType) && !_.isEmpty(appointmentId)) {
      this.getSelectedAppointment(appointmentId, resourceType === "doctor");
    }
  };

  getHospitalDoctorAppointments = async () => {
    const { authUser } = this.props;
    try {
      await services.getDoctorAppointmentsPatientOrHospital(
        "hospital",
        authUser.uid,
        (hospitalDoctorAppointments, error) => {
          if (!error) {
            this.setState({
              doctorAppointments: hospitalDoctorAppointments,
              loading: false,
            });
          }
        }
      );
    } catch (error) {
      console.log("Error -- ", error);
    }
  };

  getHospitalServiceAppointments = async () => {
    const { authUser } = this.props;

    try {
      await services.getServiceAppointmentsPatientOrHospital(
        "hospital",
        authUser.uid,
        (hospitalServiceAppointments, error) => {
          if (!error) {
            this.setState({
              serviceAppointments: hospitalServiceAppointments,
            });
          }
        }
      );
    } catch (error) {
      console.log("Error -- ", error);
    }
  };

  formatAppointments = (
    appointments: (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[]
  ): HospitalAppointmentDetails[] => {
    if (!_.isEmpty(appointments)) {
      return appointments!.map((appointment) => {
        const row = {} as Partial<HospitalAppointmentDetails>;
        row.Patient = `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`;
        row.Contact = `${appointment.patientDetails.phoneNumber} / ${appointment.patientDetails.emailAddress}`;
        row.Appointment =
          (appointment as HospitalDoctorAppointmentsView).doctorId !== undefined
            ? "Consultation"
            : "Out-Patient Service";
        row.Date = moment(appointment.appointmentDate)
          .format("MMM D, YYYY")
          .toString();

        if (
          (appointment as HospitalDoctorAppointmentsView).doctorId !== undefined
        ) {
          row.Service = "N/A";
          const doctorAppointment = appointment as HospitalDoctorAppointmentsView;
          row.Doctor = `${doctorAppointment.doctorDetails.firstName} ${doctorAppointment.doctorDetails.lastName}`;
        } else {
          row.Service = (appointment as HospitalServiceAppointmentsView).hospitalServiceDetails.treatmentName;
          row.Doctor = "N/A";
        }

        row.Status = getAppointmentStatus(
          appointment.appointmentDate,
          appointment.isCancelled,
          appointment.appointmentStatus
        );
        return row as HospitalAppointmentDetails;
      });
    } else {
      return [];
    }
  };

  downloadSchedules = async (
    startDateDownload: Date,
    endDateDownload: Date
  ) => {
    console.log(startDateDownload, endDateDownload);
    try {
      this.setState({
        loading: true,
      });

      if (!_.isEmpty(this.getConsolidatedAppointments())) {
        download(
          this.formatAppointments(
            _.filter(this.getConsolidatedAppointments(), (appointment) =>
              moment(appointment.appointmentDate).isBetween(
                moment(startDateDownload),
                moment(endDateDownload)
              )
            )
          ),
          `Appointments-${moment(new Date()).format("MMM D, YYYY").toString()}`
        );

        this.setState({
          loading: false,
          isSuccessDialogOpen: true,
        });
      } else {
        this.setState({
          loading: false,
          error: "Empty appointments, nothing to download",
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  cancelAppointment = async (reason: string) => {
    const { appointmentToCancelData } = this.state;
    const { authUser } = this.props;
    const isDoctorAppointment =
      (appointmentToCancelData as HospitalDoctorAppointmentsView).doctorId !==
      undefined;
    try {
      this.setState({ loading: true });
      if (!_.isEmpty(appointmentToCancelData)) {
        //hospital cancel appointment - patient got free booking
        try {
          const bookingCredit =
            appointmentToCancelData.patientDetails.bookingCredit + 1;

          await services.updatePatient(appointmentToCancelData.patientId, {
            bookingCredit,
          });
        } catch (error) {
          console.log("error -- free booking for patient -- ", error);
          this.setState({ error });
        }

        if (isDoctorAppointment) {
          const appointment = appointmentToCancelData as HospitalDoctorAppointmentsView;
          await services.cancelAppointment(
            appointment.docId || "",
            "doctor",
            authUser.uid,
            authUser.userType as UserType,
            reason
          );
          try {
            const { subject, message } = email.hospitalCancelDoctorAppointment(
              appointment.hospitalDetails.hospitalName,
              authUser.userDetails.phoneNumber,
              `${appointment.doctorDetails.firstName} ${appointment.doctorDetails.lastName}`,
              !!appointment.doctorHospitalAssistantDetails
                ? `${appointment.doctorHospitalAssistantDetails.firstName} ${appointment.doctorHospitalAssistantDetails.lastName}`
                : "",
              moment(appointment.appointmentDate).format("MMM D, YYYY"),
              `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
              reason
            );

            await services.sendEmail(
              appointment.doctorDetails.emailAddress,
              subject,
              message
            );
            await services.sendEmail(
              appointment.patientDetails.emailAddress,
              subject,
              message
            );
            if (!!appointment.doctorHospitalAssistantDetails) {
              await services.sendEmail(
                appointment.doctorHospitalAssistantDetails.emailAddress,
                subject,
                message
              );

              const smsMessage = sms.hospitalCancelDoctorAppointment(
                appointment.hospitalDetails.hospitalName,
                authUser.userDetails.phoneNumber,
                !!appointment.doctorHospitalAssistantDetails
                  ? `${appointment.doctorHospitalAssistantDetails.firstName} ${appointment.doctorHospitalAssistantDetails.lastName}`
                  : "",
                moment(appointment.appointmentDate).format("MMM D, YYYY"),
                `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
                reason
              );
              await api.sendSMS(
                appointment.doctorHospitalAssistantDetails.phoneNumber,
                smsMessage
              );
              await api.sendSMS(
                appointment.patientDetails.phoneNumber,
                smsMessage
              );
            }

            // analytics.logEvent("cancel_patient_appointment", {
            //   content_type: ANALYTICS_CONTENT_TYPES.hospitalSchedule.type,
            //   reason: reason,
            //   appointment_type:
            //     APPOINTMENT_TYPES[
            //       APPOINTMENT_TYPE_KEYS[appointment.appointmentType]
            //     ].key,
            //   cancer_type:
            //     CANCER_TYPES[CANCER_TYPE_KEYS[appointment.cancerType]].key,
            //   doctor: `${appointment.doctorDetails.firstName} ${appointment.doctorDetails.lastName}`,
            //   hospital: hospitalName,
            //   doctor_specialization: doctorSpecialities[
            //     appointment.doctorId
            //   ].join(),
            // });
          } catch (e) {
            console.log("error -- cancelHospitalDoctorAppointment -- ", e);
          }
        } else {
          const appointment = appointmentToCancelData as HospitalServiceAppointmentsView;

          await services.cancelAppointment(
            appointment.docId || "",
            "service",
            authUser.uid,
            authUser.userType as UserType,
            reason
          );

          try {
            const { subject, message } = email.hospitalCancelServiceAppointment(
              appointment.hospitalDetails.hospitalName,
              authUser.userDetails.phoneNumber,
              moment(appointment.appointmentDate).format("MMM D, YYYY"),
              `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
              appointment.hospitalServiceDetails.treatmentName,
              reason
            );

            await services.sendEmail(
              appointment.patientDetails.emailAddress,
              subject,
              message
            );

            const smsMessage = sms.hospitalCancelServiceAppointment(
              appointment.hospitalDetails.hospitalName,
              authUser.userDetails.phoneNumber,
              moment(appointment.appointmentDate).format("MMM D, YYYY"),
              `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
              appointment.hospitalServiceDetails.treatmentName,
              reason
            );

            await api.sendSMS(
              appointment.patientDetails.phoneNumber,
              smsMessage
            );
            // analytics.logEvent("cancel_patient_appointment", {
            //   content_type: ANALYTICS_CONTENT_TYPES.hospitalSchedule.type,
            //   reason: reason,
            //   appointment_type:
            //     "service",
            //   cancer_type:
            //     CANCER_TYPES[CANCER_TYPE_KEYS[appointment.cancerType]].key,
            //   hospital: hospitalName,
            // });
          } catch (e) {
            console.log("error -- cancelHospitalServiceAppointment -- ", e);
          }
        }
        this.setState({ loading: false, isOpenCancelSuccessDialog: true });
      } else {
        this.setState({
          error: "Error cancelling appointment: No appointment to cancel",
          loading: false,
        });
      }
    } catch (e) {
      console.log("error -- ", e);
      this.setState({ loading: false, error: e });
    }
  };

  getSelectedAppointment = async (
    appointmentId: string,
    isDoctorAppointment: boolean
  ) => {
    this.setState({ loading: true });
    try {
      const selectedAppointment = await services.getAppointment(
        appointmentId,
        isDoctorAppointment
      );
      this.setState({ loading: false, selectedAppointment });
    } catch (error) {
      console.log("error - getSelectedAppointment - ", error);
    }
  };

  markAppointmentDone = async () => {
    const { appointmentToMarkDone } = this.state;
    const { authUser } = this.props;
    const isDoctorAppointment =
      (appointmentToMarkDone as HospitalDoctorAppointmentsView).doctorId !==
      undefined;
    this.setState({ loading: true });
    try {
      await services.pushAppointmentProgress(
        appointmentToMarkDone.docId || "",
        isDoctorAppointment,
        APPOINTMENT_STATUS_TYPES.done.id,
        authUser.uid,
        authUser.userType as UserType
      );
      this.setState({
        loading: false,
        showJobWellDone: true,
        isMarkDoneOpen: false,
      });
    } catch (error) {
      console.log("error -- mark done -- ", error);
      this.setState({ loading: false, error });
    }
  };

  getAppointmentBySelectedStatus = (
    status: StatusTab,
    appointments: (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[]
  ) => {
    return _.filter(appointments, (appointment) => {
      if (status === "today") {
        return (
          !appointment.isCancelled &&
          _.isEmpty(appointment.appointmentStatus) &&
          isSameDate(new Date(), appointment.appointmentDate)
        );
      } else if (status === "cancelled") {
        return appointment.isCancelled === true;
      } else if (status === "done") {
        return _.find(
          appointment.appointmentStatus,
          (status) =>
            status.id === APPOINTMENT_STATUS_TYPES.done.id &&
            !appointment.isCancelled
        );
      } else if (status === "scheduled") {
        return (
          !!appointment.appointmentStatus &&
          !_.includes(
            appointment.appointmentStatus.map((status) => status.id),
            APPOINTMENT_STATUS_TYPES.done.id
          ) &&
          !appointment.isCancelled
        );
      } else {
        // returns all booked data
        return (
          !appointment.isCancelled &&
          _.isEmpty(appointment.appointmentStatus) &&
          !isSameDate(new Date(), appointment.appointmentDate)
        );
      }
    }) as HospitalDoctorAppointmentsView[] | HospitalServiceAppointmentsView[];
  };
  getConsolidatedAppointments = () => {
    const { doctorAppointments, serviceAppointments } = this.state;
    const consolidatedAppointments: (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[] = _.concat(
      doctorAppointments || [],
      (serviceAppointments as (
        | HospitalDoctorAppointmentsView
        | HospitalServiceAppointmentsView
      )[]) || []
    );
    return _.orderBy(consolidatedAppointments, "appointmentDate", "asc");
  };

  filterAppointmentList = (
    filterParams: AppointmentFilterParams,
    appointmentList: (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[]
  ) => {
    const {
      isConsultationSelected,
      isTreatmentSelected,
      selectedDoctor,
      selectedService,
      selectedPatient,
      isPastSelected,
      isCancelledSelected,
      isActiveSelected,
    } = filterParams;

    if (
      !!isConsultationSelected ||
      !!isTreatmentSelected ||
      !_.isEmpty(selectedDoctor) ||
      !_.isEmpty(selectedService) ||
      !_.isEmpty(selectedPatient) ||
      !!isPastSelected ||
      !!isCancelledSelected ||
      !!isActiveSelected
    ) {
      let clonedAppointmentList = _.cloneDeep(appointmentList);
      this.setState({ filteredAppointments: filterParams });

      //FILTER BY APPOINTMENT TYPES
      // this means if a single param is selected
      if (
        _.compact([isConsultationSelected, isTreatmentSelected]).length === 1
      ) {
        if (isConsultationSelected) {
          clonedAppointmentList = _.filter(
            clonedAppointmentList,
            (appointment: HospitalDoctorAppointmentsView) =>
              appointment.doctorId !== undefined
          ) as HospitalDoctorAppointmentsView[];
        } else {
          clonedAppointmentList = _.filter(
            clonedAppointmentList,
            (appointment: HospitalServiceAppointmentsView) =>
              appointment.service !== undefined
          ) as HospitalServiceAppointmentsView[];
        }
      }

      //FILTER BY RESOURCE TYPE
      if (!!isConsultationSelected && !_.isEmpty(selectedDoctor)) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) =>
            (appointment as HospitalDoctorAppointmentsView).doctorId !==
              undefined &&
            (appointment as HospitalDoctorAppointmentsView).doctorId ===
              selectedDoctor
        );
      }

      if (!!isTreatmentSelected && !_.isEmpty(selectedService)) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) =>
            (appointment as HospitalServiceAppointmentsView)
              .hospitalServiceDetails !== undefined &&
            (appointment as HospitalServiceAppointmentsView)
              .hospitalServiceDetails.docId === selectedService
        );
      }

      //FILTER BY PATIENT
      if (!_.isEmpty(selectedPatient)) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) => appointment.patientId === selectedPatient
        );
      }

      //FILTER BY DATE
      if (!!isPastSelected) {
        clonedAppointmentList = _.filter(clonedAppointmentList, (appointment) =>
          isHistoricalDate(appointment.appointmentDate)
        );
      }

      //FILTER BY STATUS
      // this means if a single params are selected
      if (_.compact([isCancelledSelected, isActiveSelected]).length === 1) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) => appointment.isCancelled === isCancelledSelected
        );
      }

      this.setState({
        filteredAppointments:
          this.state.appointmentViewMode === "calendar"
            ? _.filter(clonedAppointmentList, (appointment) =>
                isSameDate(appointment.appointmentDate, this.state.selectedDate)
              )
            : clonedAppointmentList,
      });
    } else {
      this.setState({ filterParams: null, filteredAppointments: null });
    }
  };
  cancelAllAppointments = async (reason: string) => {
    const { authUser } = this.props;
    const { selectedDate } = this.state;

    try {
      this.setState({ loading: true });
      const hospital = await services.getHospitalWithRep(authUser.uid);

      if (!_.isNull(hospital)) {
        const appointments = _.filter(
          this.getConsolidatedAppointments(),
          (appointment) =>
            moment(appointment.appointmentDate).isSame(
              moment(selectedDate),
              "days"
            ) && appointment.isCancelled === false
        );

        await Promise.all(
          appointments.map((appointment) => {
            return services.cancelAppointment(
              appointment.docId as string,
              (appointment as HospitalServiceAppointmentsView).service !==
                undefined
                ? "service"
                : "doctor",
              authUser.uid,
              authUser.userType as UserType,
              reason
            );
          })
        );

        await Promise.all(
          appointments.map((appointment, index) => {
            const bookingCredit =
              appointment.patientDetails.bookingCredit + index + 1;
            return services.updatePatient(appointment.patientId, {
              bookingCredit,
            });
          })
        );

        try {
          await Promise.all(
            appointments.map((appointment) => {
              return new Promise(async (resolve) => {
                try {
                  if (
                    (appointment as HospitalDoctorAppointmentsView).doctorId !==
                    undefined
                  ) {
                    const doctorAppointment = appointment as HospitalDoctorAppointmentsView;

                    const {
                      subject,
                      message,
                    } = email.hospitalCancelDoctorAppointment(
                      doctorAppointment.hospitalDetails.hospitalName,
                      authUser.userDetails.phoneNumber,
                      `${doctorAppointment.doctorDetails.firstName} ${doctorAppointment.doctorDetails.lastName}`,
                      !!doctorAppointment.doctorHospitalAssistantDetails
                        ? `${doctorAppointment.doctorHospitalAssistantDetails.firstName} ${doctorAppointment.doctorHospitalAssistantDetails.lastName}`
                        : "",
                      moment(doctorAppointment.appointmentDate).format(
                        "MMM D, YYYY"
                      ),
                      `${doctorAppointment.patientDetails.firstName} ${doctorAppointment.patientDetails.lastName}`,
                      reason
                    );

                    await services.sendEmail(
                      doctorAppointment.doctorDetails.emailAddress,
                      subject,
                      message
                    );
                    await services.sendEmail(
                      doctorAppointment.doctorDetails.emailAddress,
                      subject,
                      message
                    );
                    await services.sendEmail(
                      doctorAppointment.patientDetails.emailAddress,
                      subject,
                      message
                    );
                    const smsMessage = sms.hospitalCancelDoctorAppointment(
                      doctorAppointment.hospitalDetails.hospitalName,
                      authUser.userDetails.phoneNumber,
                      !!doctorAppointment.doctorHospitalAssistantDetails
                        ? `${doctorAppointment.doctorHospitalAssistantDetails.firstName} ${doctorAppointment.doctorHospitalAssistantDetails.lastName}`
                        : "",
                      moment(doctorAppointment.appointmentDate).format(
                        "MMM D, YYYY"
                      ),
                      `${doctorAppointment.patientDetails.firstName} ${doctorAppointment.patientDetails.lastName}`,
                      reason
                    );
                    await api.sendSMS(
                      doctorAppointment.patientDetails.phoneNumber,
                      smsMessage
                    );

                    if (!!doctorAppointment.doctorHospitalAssistantDetails) {
                      await services.sendEmail(
                        doctorAppointment.doctorHospitalAssistantDetails
                          .emailAddress,
                        subject,
                        message
                      );

                      await api.sendSMS(
                        doctorAppointment.doctorHospitalAssistantDetails
                          .phoneNumber,
                        smsMessage
                      );
                    }
                  } else {
                    const servicesAppointment = appointment as HospitalServiceAppointmentsView;
                    const {
                      subject,
                      message,
                    } = email.hospitalCancelServiceAppointment(
                      servicesAppointment.hospitalDetails.hospitalName,
                      authUser.userDetails.phoneNumber,
                      moment(servicesAppointment.appointmentDate).format(
                        "MMM D, YYYY"
                      ),
                      `${servicesAppointment.patientDetails.firstName} ${servicesAppointment.patientDetails.lastName}`,
                      servicesAppointment.hospitalServiceDetails.treatmentName,
                      reason
                    );

                    await services.sendEmail(
                      servicesAppointment.patientDetails.emailAddress,
                      subject,
                      message
                    );

                    const smsMessage = sms.hospitalCancelServiceAppointment(
                      servicesAppointment.hospitalDetails.hospitalName,
                      authUser.userDetails.phoneNumber,
                      moment(servicesAppointment.appointmentDate).format(
                        "MMM D, YYYY"
                      ),
                      `${servicesAppointment.patientDetails.firstName} ${servicesAppointment.patientDetails.lastName}`,
                      servicesAppointment.hospitalServiceDetails.treatmentName,
                      reason
                    );

                    await api.sendSMS(
                      servicesAppointment.patientDetails.phoneNumber,
                      smsMessage
                    );
                  }
                  resolve();
                } catch (error) {
                  this.setState({ error });
                  resolve();
                }
              });
            })
          );
          // analytics.logEvent("cancel_all_hospital_appointemnts", {
          //   content_type: ANALYTICS_CONTENT_TYPES.hospitalSchedule.type,
          //   reason: reason,
          //   hospital: hospitalName,
          // });
        } catch (error) {
          this.setState({ error });
        }

        this.setState({ loading: false, isOpenSuccessClearDialog: true });
      } else {
        this.setState({
          error:
            "Error getting hospital appointments: Cant find hospital data...",
          loading: false,
        });
      }
    } catch (e) {
      this.setState({ error: e, loading: false });
    }
  };

  render = () => {
    const {
      selectedDate,
      loading,
      error,
      startDateDownload,
      endDateDownload,
      isSuccessDialogOpen,
      isDownloadDialogOpen,
      filteredAppointments,
      selectedAppointment,
      filterDialogOpen,
      appointmentViewMode,
      isOpenCancelDialog,
      isOpenCancelSuccessDialog,
      status,
      appointmentToMarkDone,
      isMarkDoneOpen,
      showJobWellDone,
      isOpenClearAllReasonDialog,
      isOpenClearSchedule,
      isOpenSuccessClearDialog,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>MedBook - Hospital - Schedule</title>
        </Helmet>
        <MBContainer {...this.props} activePage="hospital-appointments">
          <div className="hospital-appointments-container">
            {_.isEmpty(selectedAppointment) ? (
              <>
                <MBAppointments
                  onFilterView={() => {
                    this.setState({ filterDialogOpen: true });
                  }}
                  onDownloadSchedule={() => {
                    this.setState({ isDownloadDialogOpen: true });
                  }}
                  onClearSchedule={() => {
                    this.setState({ isOpenClearSchedule: true });
                  }}
                  status={status}
                  onClickStatus={(status) => {
                    this.setState({ status: status });
                  }}
                  setSelectedView={(selectedView) => {
                    this.setState({ appointmentViewMode: selectedView });
                    if (selectedView === "list") {
                      this.setState({ filteredAppointments: [] });
                    }
                  }}
                  view={appointmentViewMode}
                >
                  {appointmentViewMode !== "list" && (
                    <MBCalendar
                      appointmentDates={this.getConsolidatedAppointments().map(
                        (appointment) => appointment.appointmentDate
                      )}
                      onDateChange={(date) => {
                        this.setState({
                          selectedDate: date,
                          filteredAppointments: _.filter(
                            this.getConsolidatedAppointments(),
                            (appointment) =>
                              isSameDate(date, appointment.appointmentDate)
                          ),
                        });
                      }}
                    />
                  )}
                  <MBAppointmentList
                    mode="hospital"
                    appointmentData={
                      appointmentViewMode === "list"
                        ? this.getAppointmentBySelectedStatus(
                            status,
                            !_.isEmpty(filteredAppointments)
                              ? (filteredAppointments as (
                                  | HospitalDoctorAppointmentsView
                                  | HospitalServiceAppointmentsView
                                )[])
                              : this.getConsolidatedAppointments()
                          )
                        : !_.isEmpty(filteredAppointments)
                        ? (filteredAppointments as (
                            | HospitalDoctorAppointmentsView
                            | HospitalServiceAppointmentsView
                          )[])
                        : _.filter(
                            this.getConsolidatedAppointments(),
                            (appointment) =>
                              isSameDate(
                                appointment.appointmentDate,
                                selectedDate
                              )
                          )
                    }
                    onClickCancel={(appointmentId) => {
                      this.setState({
                        isOpenCancelDialog: true,
                        appointmentToCancelData: _.find(
                          this.getConsolidatedAppointments(),
                          (appointment) => appointment.docId === appointmentId
                        ),
                      });
                    }}
                    onClickView={(appointmentId, isDoctorAppointment) => {
                      this.getSelectedAppointment(
                        appointmentId,
                        isDoctorAppointment
                      );
                    }}
                  />
                </MBAppointments>
              </>
            ) : (
              <div className="hospital-appointments-view-container">
                <MBViewAnAppointment
                  {...this.props}
                  mode="hospital"
                  appointmentData={selectedAppointment}
                  onClickCancel={(appointmentId) => {
                    this.setState({
                      isOpenCancelDialog: true,
                      appointmentToCancelData: _.find(
                        this.getConsolidatedAppointments(),
                        (appointment) => appointment.docId === appointmentId
                      ),
                    });
                  }}
                  onMarkAsDone={(appointmentId) => {
                    this.setState({
                      isMarkDoneOpen: true,
                      appointmentToMarkDone: _.find(
                        this.getConsolidatedAppointments(),
                        (appointment) => appointment.docId === appointmentId
                      ),
                    });
                  }}
                  onClickBack={() => {
                    this.setState({
                      selectedAppointment: null,
                      filteredAppointments: [],
                      status: "today",
                    });
                  }}
                />
              </div>
            )}
          </div>
        </MBContainer>

        <MBDialog
          isOpen={isMarkDoneOpen}
          icon="success"
          title="Are you sure you want to mark this appointment as done?"
          message="This appointment will be tagged as complete once you click Proceed."
          onApprove={() => {
            //call to update mark as done
            // show job well done dialog
            this.markAppointmentDone();
          }}
          onDecline={() => {
            this.setState({
              isMarkDoneOpen: false,
            });
          }}
          onDidDismiss={() => {
            this.setState({
              isMarkDoneOpen: false,
            });
          }}
        />

        {!!appointmentToMarkDone && !_.isEmpty(appointmentToMarkDone) && (
          <IonModal
            isOpen={showJobWellDone}
            cssClass="hospital-job-well-done-dialog"
            onDidDismiss={() => {
              this.setState({
                showJobWellDone: false,
                appointmentToMarkDone: {},
                initialDiagnosis: "",
                prescription: "",
                selectedAppointment: null,
                filteredAppointments: null,
                status: "today",
              });
            }}
          >
            <div className="hospital-job-well-done-container">
              <IonLabel className="hospital-job-well-done-label mb-h1 dark-blue ion-no-padding ion-no-margin">
                Job Well Done
              </IonLabel>
              <IonLabel className="hospital-job-well-done-label mb-body ion-no-padding ion-no-margin">
                You have successfully completed an appointment.
              </IonLabel>
              <IonIcon className="hospital-job-well-done-icon ion-no-padding ion-no-margin" />
              <IonLabel className="hospital-job-well-done-label mb-h4 dark-blue bold ion-no-padding ion-no-margin">{`${appointmentToMarkDone.patientDetails.firstName} ${appointmentToMarkDone.patientDetails.lastName}`}</IonLabel>
              <IonLabel className="hospital-job-well-done-label mb-body ion-no-padding ion-no-margin">
                {`Booking Id: ${appointmentToMarkDone.docId} scheduled on `}{" "}
                <b>
                  {moment(appointmentToMarkDone.appointmentDate).format(
                    "MMMM DD, YYYY HH:mm A"
                  )}
                </b>
              </IonLabel>

              <IonButton
                mode="ios"
                className="hospital-job-well-done-button"
                onClick={() => {
                  this.setState({
                    showJobWellDone: false,
                    appointmentToMarkDone: {},
                    selectedAppointment: null,
                    filteredAppointments: null,
                    status: "today",
                  });
                }}
              >
                Done
              </IonButton>
            </div>
          </IonModal>
        )}

        {!_.isEmpty(this.getConsolidatedAppointments()) && (
          <AppointmentsFilterDialog
            mode="hospital"
            isOpen={filterDialogOpen}
            doctorOptions={_.filter(
              this.getConsolidatedAppointments(),
              (appointment: HospitalDoctorAppointmentsView) =>
                appointment.doctorId !== undefined
            ).map((appointment) => {
              const doctorAppointment = appointment as HospitalDoctorAppointmentsView;
              return {
                id: doctorAppointment.doctorId,
                name: `${doctorAppointment.doctorDetails.firstName} ${doctorAppointment.doctorDetails.lastName}`,
              };
            })}
            serviceOptions={_.uniqBy(
              _.filter(
                this.getConsolidatedAppointments(),
                (appointment: HospitalServiceAppointmentsView) =>
                  appointment.service !== undefined
              ).map((appointment) => {
                const hospitalAppointment = appointment as HospitalServiceAppointmentsView;
                return {
                  id: hospitalAppointment.hospitalServiceDetails.docId || "",
                  name:
                    hospitalAppointment.hospitalServiceDetails.treatmentName,
                };
              }),
              (service) => service.id
            )}
            patientOptions={this.getConsolidatedAppointments()!.map(
              (appointment) => {
                return {
                  id: appointment.patientId,
                  name: `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
                };
              }
            )}
            onSave={(filterParams) => {
              this.filterAppointmentList(
                filterParams,
                this.getConsolidatedAppointments()
              );
              this.setState({ filterDialogOpen: false });
              // analytics.logEvent("filter_hospital_appointments", {
              //   content_type: ANALYTICS_CONTENT_TYPES.hospitalSchedule.type,
              // });
            }}
            onBack={() => {
              this.setState({ filterDialogOpen: false });
            }}
          />
        )}

        {isDownloadDialogOpen && (
          <MBCalendarRangeDialog
            icon="download"
            isOpen={isDownloadDialogOpen}
            title="Please set the duration of the schedule you want to download"
            errorMessage="You need to define date range to download schedules"
            hasEasyOptions={true}
            onDidDismiss={() => {
              this.setState({
                isDownloadDialogOpen: false,
              });
            }}
            onSubmit={(startDate, endDate) => {
              this.setState({
                startDateDownload: startDate,
                endDateDownload: endDate,
              });
              this.downloadSchedules(startDate!, endDate!);
              this.setState({
                isDownloadDialogOpen: false,
              });
            }}
            onCancel={() => {
              this.setState({
                isDownloadDialogOpen: false,
              });
            }}
          />
        )}
        <MBDialog
          isOpen={isOpenCancelSuccessDialog}
          icon="success"
          title="Schedule cancelled"
          message="You have successfully cancelled a scheduled appointment. The patient will be notified of this cancellation."
          onDidDismiss={() => {
            this.setState({
              isOpenCancelSuccessDialog: false,
              selectedAppointment: null,
              filteredAppointments: [],
              appointmentToCancelData: {},
              status: "today",
            });
          }}
        />
        {!!isOpenClearAllReasonDialog && (
          <CancelReasonDialog
            mode="appointment"
            isOpen={isOpenClearAllReasonDialog}
            icon="warning"
            title="Please select your reason for cancelling."
            message="Cancelling impacts patient experience"
            onDidDismiss={() => {
              this.setState({ isOpenClearAllReasonDialog: false });
            }}
            onApprove={(reason) => {
              this.cancelAllAppointments(reason);
              this.setState({ isOpenClearAllReasonDialog: false });
            }}
            onDecline={() => {
              this.setState({ isOpenClearAllReasonDialog: false });
            }}
          />
        )}
        <MBDialog
          isOpen={isOpenClearSchedule}
          icon="warning"
          title={`You are about to clear all your schedules for ${moment(
            selectedDate
          ).format("MMM DD, YYYY")}`}
          message="Are you sure you want to clear all your schedules?"
          onDidDismiss={() => {
            this.setState({ isOpenClearSchedule: false });
          }}
          onApprove={() => {
            this.setState({ isOpenClearSchedule: false });
            this.setState({ isOpenClearAllReasonDialog: true });
          }}
          onDecline={() => {
            this.setState({ isOpenClearSchedule: false });
          }}
        />

        <MBDialog
          isOpen={isOpenSuccessClearDialog}
          icon="success"
          title="Schedule cleared!"
          message={`You have successfully cancelled all scheduled appointments for ${moment(
            selectedDate
          ).format(
            "MMM DD, YYYY"
          )}. All affected patients will be notified of this cancellation.`}
          onDidDismiss={() => {
            this.setState({
              isOpenSuccessClearDialog: false,
              status: "today",
              selectedAppointment: null,
              filteredAppointments: [],
            });
          }}
        />

        {isOpenCancelDialog && (
          <CancelReasonDialog
            mode="appointment"
            isOpen={isOpenCancelDialog}
            icon="warning"
            title="Please select your reason for cancelling."
            message="Cancelling impacts patient experience"
            onDidDismiss={() => {
              // setIsViewAppointmentDialogIsOpen(false);
            }}
            onApprove={(reason) => {
              // send reason to email here
              this.cancelAppointment(reason);

              this.setState({ isOpenCancelDialog: false });
            }}
            onDecline={() => {
              this.setState({
                isOpenCancelDialog: false,
                appointmentToCancelData: {},
              });
            }}
          />
        )}
        <MBDialog
          isOpen={isSuccessDialogOpen}
          icon="success"
          title="Download complete"
          message={`Please check your downloads folder for the offline file of the schedules from ${moment(
            startDateDownload
          ).format("MMM DD, YYYY")} to ${moment(endDateDownload).format(
            "MMM DD, YYYY"
          )}`}
          onDidDismiss={() => {
            this.setState({
              isSuccessDialogOpen: false,
            });
          }}
          onClick={() => {
            this.setState({
              isSuccessDialogOpen: false,
            });
          }}
        />

        <IonLoading
          translucent={true}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />

        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => {
            this.setState({
              error: "",
            });
          }}
          color={MBCOLORS.danger}
        />
      </>
    );
  };
}

export default HospitalAppointments;
