import _ from "lodash";
import { ellipsisHorizontal, pencilOutline, trashBin } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonCard,
  IonLabel,
  IonCardContent,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonList,
  IonPopover,
  IonLoading,
  IonToast,
} from "@ionic/react";

import "./MBTimeKeepingDetailCard.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { MBProps } from "../../interface";
import { TimeKeeping } from "../../models";
import { TimeKeepingForm } from "../TimeKeepingForm/TimeKeepingForm";
import { MBCOLORS } from "../../constants/config";
import { MBDialog } from "../MBDialog/MBDialog";
import { MSGS_COMMON } from "../../constants/messages";

interface MBTimeKeepingDetailCardPropsInterface extends MBProps {
  details: TimeKeeping;
  hideRemove?: boolean;
}

export const MBTimeKeepingDetailCard = (
  props: MBTimeKeepingDetailCardPropsInterface
) => {
  const { details, history, hideRemove } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [
    isOpenEditTimeKeepingAccount,
    setIsOpenEditTimeKeepingAccount,
  ] = useState(false);

  const [showActionButtons, setShowActionButtons] = useState(false);
  const [actionButtonsEvent, setActionButtonsEvent] = useState(
    undefined
  ) as any;

  const [isOpenConfirmRemoveDialog, setIsOpenConfirmRemoveDialog] = useState(
    false
  );
  const [
    isOpenTimeKeepingRemovedDialog,
    setIsOpenTimeKeepingRemovedDialog,
  ] = useState(false);

  const removeTimeKeeping = async () => {
    try {
      setLoading(true);
      await services.removeTimeKeeping(details.docId || "");
      setIsOpenTimeKeepingRemovedDialog(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  return (
    <>
      <IonCard
        className="mb-time-keeping-account-detail-card ion-no-margin"
        color={MBCOLORS.tertiary}
      >
        <IonCardContent className="mb-time-keeping-account-detail-card-content ion-no-padding">
          <div className="mb-time-keeping-account-detail-card-content-details-container">
            <IonGrid className="mb-time-keeping-account-detail-card-grid-detail ion-no-padding ion-no-margin">
              <IonRow className="mb-time-keeping-account-detail-card-row ion-no-padding ion-no-margin">
                <IonCol
                  size="4"
                  className="mb-time-keeping-account-detail-card-col-icons ion-no-padding ion-no-margin"
                >
                  <IonIcon className="mb-time-keeping-account-detail-card-details-icon ion-no-margin" />
                </IonCol>
                <IonCol
                  size="7"
                  className="mb-time-keeping-account-detail-card-col-details ion-no-padding ion-no-margin"
                >
                  <div className="mb-time-keeping-account-detail-card-details-container">
                    <IonLabel className="mb-h1 dark-blue ion-no-padding ion-no-margin">
                      {`${details.firstName} ${details.lastName}`}
                    </IonLabel>
                    <div className="mb-time-keeping-account-detail-card-sub-detail-container">
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon security" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          {details.designation}
                        </IonLabel>
                      </div>
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon mobile" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          {details.phoneNumber}
                        </IonLabel>
                      </div>
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon mail" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          {details.userEmail}
                        </IonLabel>
                      </div>
                    </div>
                  </div>
                </IonCol>
                <IonCol
                  size="1"
                  className="mb-time-keeping-account-detail-card-col-button ion-no-padding ion-no-margin"
                >
                  <IonButton
                    className="mb-time-keeping-account-detail-card-action-button"
                    color={MBCOLORS.medium}
                    onClick={(e) => {
                      setActionButtonsEvent(e.nativeEvent);
                      setShowActionButtons(true);
                    }}
                  >
                    <IonIcon icon={ellipsisHorizontal} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonCardContent>
      </IonCard>

      <IonPopover
        mode="md"
        isOpen={showActionButtons}
        event={actionButtonsEvent}
        cssClass="mb-time-keeping-account-detail-card-popover noselect ion-no-padding"
        showBackdrop={false}
        onDidDismiss={() => {
          setShowActionButtons(false);
        }}
      >
        <div className="mb-time-keeping-account-detail-card-list-container">
          <IonList className="mb-time-keeping-account-detail-card-list">
            <div className="mb-time-keeping-account-detail-card-item-container">
              <IonItem
                className="mb-time-keeping-account-detail-card-dropdown-modal-item ion-justify-content-center"
                button={true}
                lines="none"
                onClick={() => {
                  setIsOpenEditTimeKeepingAccount(true);
                  setShowActionButtons(false);
                }}
              >
                <IonLabel className="mb-time-keeping-account-detail-card-dropdown-label mb-h5">
                  <IonIcon
                    className="mb-time-keeping-account-detail-card-dropdown-modal-item-icon"
                    icon={pencilOutline}
                    slot="start"
                  />
                  Edit
                </IonLabel>
              </IonItem>
              {!hideRemove && (
                <IonItem
                  className="mb-time-keeping-account-detail-card-dropdown-modal-item ion-justify-content-center"
                  button={true}
                  lines="none"
                  onClick={() => {
                    setIsOpenConfirmRemoveDialog(true);
                    setShowActionButtons(false);
                  }}
                >
                  <IonLabel className="mb-time-keeping-account-detail-card-dropdown-label mb-h5">
                    <IonIcon
                      className="mb-time-keeping-account-detail-card-dropdown-modal-item-icon"
                      icon={trashBin}
                      slot="start"
                    />
                    Remove
                  </IonLabel>
                </IonItem>
              )}
            </div>
          </IonList>
        </div>
      </IonPopover>

      <MBDialog
        isOpen={isOpenTimeKeepingRemovedDialog}
        icon="success"
        title="Remove Personnel Success"
        message="You have successfully removed this personnel"
        onDidDismiss={() => {
          setIsOpenTimeKeepingRemovedDialog(false);
          history.push(routes.HOSPITAL_DEPARTMENT);
        }}
      />

      <MBDialog
        isOpen={isOpenConfirmRemoveDialog}
        icon="warning"
        title="You are about to remove this personnel"
        message="This Personnel/Staff will no longer be able to login."
        onDidDismiss={() => {
          setIsOpenConfirmRemoveDialog(false);
        }}
        onApprove={() => {
          removeTimeKeeping();
        }}
        onDecline={() => {
          setIsOpenConfirmRemoveDialog(false);
        }}
      />

      <TimeKeepingForm
        {...props}
        editData={details}
        isEdit={true}
        isOpen={isOpenEditTimeKeepingAccount}
        onBack={() => {
          window.location.reload();
          setIsOpenEditTimeKeepingAccount(false);
        }}
        hospitalId={details.hospitalId}
        onCancel={() => {
          setIsOpenEditTimeKeepingAccount(false);
        }}
      />

      <IonLoading
        translucent={false}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
