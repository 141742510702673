import _ from "lodash";
import { Helmet } from "react-helmet";
import React from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPopover,
  IonRow,
  IonToast,
} from "@ionic/react";

import "./HospitalAccount.scss";
import * as api from "../../api";
import * as email from "../../functions/email";
import * as services from "../../services";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import { HospitalAccountForm } from "../../components/HospitalAccountForm/HospitalAccountForm";
import { MBDialog } from "../../components/MBDialog/MBDialog";
import { MBEmailDialog } from "../../components/MBEmailDialog/MBEmailDialog";
import {
  ellipsisHorizontal,
  pencilOutline,
  swapHorizontal,
} from "ionicons/icons";
import { MBPasswordReauthenticationDialog } from "../../components/MBPasswordReauthenticationDialog/MBPasswordReauthenticationDialog";
import { Hospital, HospitalRepInvitation } from "../../models";
import { MSGS_COMMON } from "../../constants/messages";
import { MBCOLORS } from "../../constants/config";

class HospitalAccount extends React.Component<MBProps> {
  state = {
    loading: false,
    showActionButtons: false,
    actionButtonsEvent: undefined as any,
    forTransferEmailAddress: "",
    isConfirmTransferAccountDialogOpen: false,
    isConfirmCancelTransferAccountDialogOpen: false,
    isTransferEmailDialogOpen: false,
    success: "",
    isReauthenticateDialogOpen: false,
    ongoingInvitation: {} as HospitalRepInvitation,
    isTransferAccountSuccess: false,
    isOpenEditAccount: false,
  };

  componentDidMount = () => {
    this.getCurrentInvitation();
  };

  getCurrentInvitation = async () => {
    const { authUser } = this.props;
    this.setState({ loading: true });
    try {
      const currentInvitation = await services.getCurrentInvitation(
        authUser.uid
      );

      this.setState({ loading: false, ongoingInvitation: currentInvitation });
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  };

  checkUniqueEmail = async (emailAddress: string) => {
    this.setState({ loading: true });
    try {
      await api.checkIfEmailIsUnique(emailAddress);

      this.setState({
        loading: false,
        isReauthenticateDialogOpen: true,
        forTransferEmailAddress: emailAddress,
      });
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  };

  onTransferAccount = async () => {
    const { forTransferEmailAddress } = this.state;
    this.setState({ loading: true });
    try {
      const hospital = this.props.authUser.userDetails as Hospital;
      if (!_.isNull(hospital)) {
        await services.transferRepresentativeAccount(
          forTransferEmailAddress,
          hospital
        );
        this.setState({ loading: false, isTransferAccountSuccess: true });
      } else {
        this.setState({ loading: false, error: "Hospital not found." });
      }
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  };

  cancelInvitation = async () => {
    const { ongoingInvitation } = this.state;
    const { authUser } = this.props;
    const hospital = authUser.userDetails as Hospital;
    if (!_.isEmpty(ongoingInvitation)) {
      try {
        this.setState({ loading: true });
        await services.cancelInvitation(ongoingInvitation.docId || "");

        try {
          const {
            subject,
            message,
          } = email.inviteHospitalRepresentativeCancelled(
            `${hospital.firstName} ${hospital.lastName}`,
            hospital.hospitalName,
            hospital.phoneNumber
          );

          await services.sendEmail(
            ongoingInvitation.emailAddress,
            subject,
            message
          );
        } catch (e) {
          console.log("ERROR SENDING NOTIF", e);
        }
        this.getCurrentInvitation();
        this.setState({
          loading: false,
          success: "Request to transfer now cancelled.",
        });
      } catch (e) {
        this.setState({ loading: false, error: e });
      }
    } else {
      this.setState({ error: "No Ongoing invitation" });
    }
  };

  render = () => {
    const {
      loading,
      showActionButtons,
      actionButtonsEvent,
      isReauthenticateDialogOpen,
      isConfirmTransferAccountDialogOpen,
      isConfirmCancelTransferAccountDialogOpen,
      isTransferEmailDialogOpen,
      isOpenEditAccount,
      ongoingInvitation,
      isTransferAccountSuccess,
      success,
    } = this.state;
    const { authUser } = this.props;
    const hospitalDetails = authUser.userDetails as Hospital;
    return (
      <>
        <Helmet>
          <title>MedBook - Hospital - My Account</title>
        </Helmet>
        <MBContainer {...this.props} activePage="account">
          {/* <HospitalAccountForm {...this.props} /> */}
          <IonCard
            className="hospital-account-detail-card ion-no-margin"
            color={MBCOLORS.tertiary}
          >
            <IonCardContent className="hospital-account-detail-card-content ion-no-padding">
              <div className="hospital-account-detail-card-content-details-container">
                <IonGrid className="hospital-account-detail-card-grid-detail ion-no-padding ion-no-margin">
                  <IonRow className="hospital-account-detail-card-row ion-no-padding ion-no-margin">
                    <IonCol
                      size="4"
                      className="hospital-account-detail-card-col-icons ion-no-padding ion-no-margin"
                    >
                      <IonIcon className="hospital-account-detail-card-details-icon ion-no-margin" />
                    </IonCol>
                    <IonCol
                      size="7"
                      className="hospital-account-detail-card-col-details ion-no-padding ion-no-margin"
                    >
                      <div className="hospital-account-detail-card-details-container">
                        <IonLabel className="mb-h2 dark-blue ion-no-padding ion-no-margin">
                          {hospitalDetails.hospitalName}
                        </IonLabel>
                        <div className="hospital-account-detail-card-sub-detail-container">
                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon address" />
                            <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                              {`${hospitalDetails.cityMunicipality}, ${hospitalDetails.province}`}
                            </IonLabel>
                          </div>

                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon mail" />
                            <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                              {hospitalDetails.emailAddress}
                            </IonLabel>
                          </div>
                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon mobile" />
                            <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                              {hospitalDetails.phoneNumber}
                            </IonLabel>
                          </div>
                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon password" />
                            <IonLabel className="mb-h3 success ion-no-padding ion-no-margin">
                              * * * * * * * * *
                            </IonLabel>
                          </div>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol
                      size="1"
                      className="hospital-account-detail-card-col-button ion-no-padding ion-no-margin"
                    >
                      <IonButton
                        className="hospital-account-detail-card-action-button"
                        color={MBCOLORS.medium}
                        onClick={(e) => {
                          this.setState({
                            actionButtonsEvent: e.nativeEvent,
                            showActionButtons: true,
                          });
                        }}
                      >
                        <IonIcon icon={ellipsisHorizontal} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonCardContent>
          </IonCard>

          <IonPopover
            mode="md"
            isOpen={showActionButtons}
            event={actionButtonsEvent}
            cssClass="hospital-account-detail-card-popover noselect ion-no-padding"
            showBackdrop={false}
            onDidDismiss={() => {
              this.setState({
                showActionButtons: false,
              });
            }}
          >
            <div className="hospital-account-detail-card-list-container">
              <IonList className="hospital-account-detail-card-list">
                <div className="hospital-account-detail-card-item-container">
                  <IonItem
                    className="hospital-account-detail-card-dropdown-modal-item ion-justify-content-center"
                    button={true}
                    lines="none"
                    onClick={() => {
                      this.setState({
                        isOpenEditAccount: true,
                        showActionButtons: false,
                      });
                    }}
                  >
                    <IonLabel className="hospital-account-detail-card-dropdown-label mb-h5">
                      <IonIcon
                        className="hospital-account-detail-card-dropdown-modal-item-icon"
                        icon={pencilOutline}
                        slot="start"
                      />
                      Edit
                    </IonLabel>
                  </IonItem>
                  <IonItem
                    className="hospital-account-detail-card-dropdown-modal-item ion-justify-content-center"
                    button={true}
                    onClick={() => {
                      if (_.isEmpty(ongoingInvitation)) {
                        this.setState({
                          isConfirmTransferAccountDialogOpen: true,
                        });
                      } else {
                        this.setState({
                          isConfirmCancelTransferAccountDialogOpen: true,
                        });
                      }
                    }}
                    lines="none"
                  >
                    <IonLabel className="hospital-account-detail-card-dropdown-label mb-h5">
                      <IonIcon
                        className="hospital-account-detail-card-dropdown-modal-item-icon"
                        icon={swapHorizontal}
                        slot="start"
                      />
                      {!_.isEmpty(ongoingInvitation)
                        ? "Cancel Transfer Account"
                        : "Transfer Account"}
                    </IonLabel>
                  </IonItem>
                </div>
              </IonList>
            </div>
          </IonPopover>

          <MBDialog
            isOpen={isConfirmTransferAccountDialogOpen}
            icon="warning"
            title="You are about to transfer your account to someone else"
            message="Appointments will not be affected. Are you sure you want to proceed?"
            onDidDismiss={() => {
              this.setState({ isConfirmTransferAccountDialogOpen: false });
            }}
            onApprove={() => {
              this.setState({
                isConfirmTransferAccountDialogOpen: false,
                isTransferEmailDialogOpen: true,
              });
            }}
            onDecline={() => {
              this.setState({ isConfirmTransferAccountDialogOpen: false });
            }}
          />

          <MBDialog
            isOpen={isConfirmCancelTransferAccountDialogOpen}
            icon="warning"
            title="You are about to cancel your request to transfer account."
            message="The invitation link you sent will now be invalid. Are you sure you want to proceed?"
            onDidDismiss={() => {
              this.setState({
                isConfirmCancelTransferAccountDialogOpen: false,
              });
              this.setState({
                isConfirmCancelTransferAccountDialogOpen: false,
              });
            }}
            onApprove={() => {
              this.cancelInvitation();
              this.setState({
                isConfirmCancelTransferAccountDialogOpen: false,
              });
            }}
            onDecline={() => {
              this.setState({
                isConfirmCancelTransferAccountDialogOpen: false,
              });
            }}
          />

          <MBEmailDialog
            title="Transfer Hospital Representative Account"
            message="Please enter the email address of the new representative."
            isOpen={isTransferEmailDialogOpen}
            onDidDismiss={() => {
              this.setState({ isTransferEmailDialogOpen: false });
            }}
            onCancel={() => {
              this.setState({ isTransferEmailDialogOpen: false });
            }}
            onSubmit={(emailAddress) => {
              this.setState({ isTransferEmailDialogOpen: false });
              this.checkUniqueEmail(emailAddress);
            }}
          />
          <MBDialog
            isOpen={isTransferAccountSuccess}
            title="Transfer Account Success"
            message="The invitation link was sent to the new representative. Your account will be deleted once the invitation got accepted."
            icon="success"
            onDidDismiss={() => {
              this.setState({ isTransferAccountSuccess: false });
              services.signOut();
            }}
          />

          {isReauthenticateDialogOpen && (
            <MBPasswordReauthenticationDialog
              {...this.props}
              isOpen={isReauthenticateDialogOpen}
              onDidDismiss={() => {
                this.setState({ isReauthenticateDialogOpen: false });
              }}
              onSubmit={(isReauthenticated, password) => {
                if (isReauthenticated) {
                  this.onTransferAccount();
                }
                this.setState({ isReauthenticateDialogOpen: false });
              }}
            />
          )}

          {!!isOpenEditAccount && (
            <IonModal
              isOpen={isOpenEditAccount}
              onDidDismiss={() => {
                this.setState({
                  isOpenEditAccount: false,
                });
              }}
              cssClass="hospital-account-form-modal"
            >
              <HospitalAccountForm
                {...this.props}
                onBack={() => {
                  this.setState({
                    isOpenEditAccount: false,
                  });
                }}
              />
            </IonModal>
          )}
          <IonToast
            isOpen={!_.isEmpty(success)}
            message={success}
            duration={2000}
            onDidDismiss={() => this.setState({ success: "" })}
            color={MBCOLORS.success}
          />
          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />
        </MBContainer>
      </>
    );
  };
}

export default HospitalAccount;
