/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonCard,
  IonCardHeader,
  IonItem,
  IonInput,
  IonButton,
  IonImg,
} from "@ionic/react";

import "./PatientAccountForm.scss";
import * as services from "../../services";
import { analytics, storage } from "../../firebase";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import {
  formatString,
  useEffectOnlyOnce,
  isMobile,
} from "../../functions/common";
import { Patient } from "../../models";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBInput } from "../MBInput/MBInput";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBProps } from "../../interface";
import {
  MBDropdownSelect,
  MBDropdownSelectOption,
} from "../MBDropdownSelect/MBDropdownSelect";
import { ADDRESS, PROVINCES } from "../../constants/address";
import {
  LIMITS,
  MBCOLORS,
  ANALYTICS_CONTENT_TYPES,
} from "../../constants/config";
import {
  MSGS_COMMON,
  MSGS_SIGNUP,
  MSGS_COMMON_FORM_ERROR,
} from "../../constants/messages";
import { MBFileInput } from "../MBFileInput/MBFileInput";
// import { MBFileInput } from "../MBFileInput/MBFileInput";

interface PatientAccountFormProps extends MBProps {
  patient: Patient;
  onBack: () => void;
  hasActiveAppointment?: boolean;
}

export const PatientAccountForm = (props: PatientAccountFormProps) => {
  const { onBack, authUser, patient } = props;

  const [firstName, setFirstName] = useState(
    !!patient && patient.firstName ? patient.firstName : ""
  );
  const [lastName, setLastName] = useState(
    !!patient && patient.lastName ? patient.lastName : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    !!patient && patient.phoneNumber ? patient.phoneNumber : ""
  );
  const [emailAddress, setEmailAddress] = useState(
    !!patient && patient.emailAddress ? patient.emailAddress : ""
  );

  const [preferredDoctor, setPreferredDoctor] = useState(
    null as null | MBDropdownSelectOption
  );
  const [preferredDoctorSearch, setPreferredDoctorSearch] = useState("");

  const [doctorOptions, setDoctorOptions] = useState(
    null as null | MBDropdownSelectOption[]
  );

  const [governmentId, setGovernmentId] = useState(
    !!patient && patient.governmentId ? patient.governmentId : ""
  );

  const [selectedProvince, setSelectedProvince] = useState(
    !!patient && patient.province ? patient.province : ""
  );

  const [provinceSearch, setProvinceSearch] = useState("");

  const [selectedCityMunicipality, setSelectedCityMunicipality] = useState(
    !!patient && patient.cityMunicipality ? patient.cityMunicipality : ""
  );
  const [cityMunicipalitySearch, setCityMunicipalitySearch] = useState("");
  const [governmentIdFile, setGovernmentIdFile] = useState(null as any);
  const [errorMsgGovtIdFile, setErrorMsgGovtIdFile] = useState("");

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("");
  const [errorMsgEmaillAddress, setErrorMsgEmaillAddress] = useState("");
  const [errorMsgProvince, setErrorMsgProvince] = useState("");
  const [errorMsgCityMunicipality, setErrorMsgCityMunicipality] = useState("");

  const [isOpenSuccessProfileUpdate, setIsOpenSuccessProfileUpdate] = useState(
    false
  );
  const [isOpenSendChangePassword, setIsOpenSendChangePassword] = useState(
    false
  );
  const [listOfCityMunicipality, setListOfCityMunicipality] = useState(
    !!patient && patient.province ? ADDRESS[patient.province] : ([] as string[])
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffectOnlyOnce(() => {
    if (!_.isEmpty(authUser)) {
      setEmailAddress(authUser.authEmail);
      setupPreferredDoctorOptions();
    }
  });

  const setupPreferredDoctorOptions = () => {
    services.getAllDoctors((doctors) => {
      const doctorOptions = doctors.map((doctor) => {
        return {
          id: doctor.docId || "",
          name: `${doctor.firstName} ${doctor.lastName}`,
        } as MBDropdownSelectOption;
      });
      setDoctorOptions(doctorOptions);
      if (!_.isEmpty(patient.preferredDoctor)) {
        setPreferredDoctor(
          _.find(
            doctorOptions,
            (doctor) => doctor.id === patient.preferredDoctor
          ) || null
        );
      }
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedPhoneNumber = formatString((phoneNumber || "") as string);
    const formattedProvince = formatString(selectedProvince || "");
    const formattedCityMunicipality = formatString(
      selectedCityMunicipality || ""
    );
    let errorMessage = null;
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_SIGNUP.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.firstName.max;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_SIGNUP.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedPhoneNumber.length === 0) {
      errorMessage = MSGS_SIGNUP.mobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (!validatePhoneNumber(formattedPhoneNumber)) {
      errorMessage = MSGS_SIGNUP.invalidMobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (formattedProvince.length === 0) {
      errorMessage = MSGS_SIGNUP.province;
      setErrorMsgProvince(errorMessage);
    }
    if (formattedCityMunicipality.length === 0) {
      errorMessage = MSGS_SIGNUP.cityMunicipality;
      setErrorMsgCityMunicipality(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      let newGovermentIdURL = "";
      if (!_.isNull(governmentIdFile)) {
        const ref = storage.ref();
        const governmentIdPath = `governmentId/${formattedLastName
          .replace(" ", "")
          .toLowerCase()}_${formattedFirstName}_${
          authUser.uid
        }/government_id_${new Date().valueOf()}`;
        const uploadGovernmentIdSnapshot = await ref
          .child(governmentIdPath)
          .put(governmentIdFile);
        newGovermentIdURL = await uploadGovernmentIdSnapshot.ref.getDownloadURL();
      }
      const patientDetail = _.cloneDeep(patient);
      const updatedPatientDetail = {
        ...patientDetail,
        firstName: formattedFirstName,
        lastName: formattedLastName,
        province: formattedProvince,
        preferredDoctor: !_.isNull(preferredDoctor) ? preferredDoctor.id : "",
        cityMunicipality: formattedCityMunicipality,
        ...(!_.isNull(governmentIdFile) && {
          governmentId: newGovermentIdURL,
        }),
        phoneNumber: formattedPhoneNumber,
      } as Partial<Patient>;
      try {
        await services.updatePatient(patient.docId || "", updatedPatientDetail);
        setLoading(false);

        setIsOpenSuccessProfileUpdate(true);
      } catch (error) {
        console.log("error - updatePatient -- ", error);
        setError(error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const onChangePassword = async () => {
    let error = "";
    if (_.isEmpty(emailAddress)) {
      error = MSGS_COMMON_FORM_ERROR.emailAddress;
      setErrorMsgEmaillAddress(error);
    }

    if (!error) {
      setIsOpenSendChangePassword(true);
      try {
        await services.doSendResetPasswordEmail(emailAddress);
        analytics.logEvent("edit_patient_password", {
          content_type: ANALYTICS_CONTENT_TYPES.patientAccount.type,
        });
      } catch (error) {
        console.log("Error -- doSendResetPasswordEmail -- ", error);
      }
    }
  };

  return (
    <>
      <IonCard
        className={`patient-account-card ${
          isMobile() && "mobile"
        } ion-no-margin`}
      >
        <IonCardHeader className="patient-account-card-header">
          <IonLabel className="mb-h2 dark-blue ion-text-start">
            Edit Details
          </IonLabel>
        </IonCardHeader>
        <form autoComplete="nope...">
          <div className="patient-account-form-container">
            <div className="patient-account-form-description-container"></div>
            <IonGrid className="patient-account-form-container-grid ion-no-padding">
              {!isMobile() ? (
                <IonRow>
                  <IonCol
                    className="patient-account-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="First name"
                      value={firstName}
                      type="text"
                      error={errorMsgFirstName}
                      onChange={(firstName) => {
                        setFirstName(firstName);
                        setErrorMsgFirstName("");
                      }}
                    />
                  </IonCol>
                  <IonCol
                    className="patient-account-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="Last name"
                      value={lastName}
                      type="text"
                      error={errorMsgLastName}
                      onChange={(lastName) => {
                        setLastName(lastName);
                        setErrorMsgLastName("");
                      }}
                    />
                  </IonCol>
                </IonRow>
              ) : (
                <>
                  <IonRow>
                    <IonCol
                      className="patient-account-form-container-col ion-no-padding"
                      size="12"
                    >
                      <MBInput
                        label="First name"
                        value={firstName}
                        type="text"
                        error={errorMsgFirstName}
                        onChange={(firstName) => {
                          setFirstName(firstName);
                          setErrorMsgFirstName("");
                        }}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      className="patient-account-form-container-col ion-no-padding"
                      size="12"
                    >
                      <MBInput
                        label="Last name"
                        value={lastName}
                        type="text"
                        error={errorMsgLastName}
                        onChange={(lastName) => {
                          setLastName(lastName);
                          setErrorMsgLastName("");
                        }}
                      />
                    </IonCol>
                  </IonRow>
                </>
              )}
              {!isMobile() ? (
                <IonRow>
                  <IonCol
                    className="patient-account-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="Mobile Number"
                      value={phoneNumber}
                      type="tel"
                      error={errorMsgPhoneNumber}
                      onChange={(phoneNumber) => {
                        setPhoneNumber(phoneNumber);
                        setErrorMsgPhoneNumber("");
                      }}
                    />
                  </IonCol>
                  <IonCol
                    className="patient-account-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="Email Address"
                      value={emailAddress}
                      type="email"
                      error={errorMsgEmaillAddress}
                      onChange={(emailAddress) => {
                        setEmailAddress(emailAddress);
                        setErrorMsgEmaillAddress("");
                      }}
                      readonly={true} //last priority - implement the email change and verification
                    />
                  </IonCol>
                </IonRow>
              ) : (
                <>
                  <IonRow>
                    <IonCol
                      className="patient-account-form-container-col ion-no-padding"
                      size="12"
                    >
                      <MBInput
                        label="Mobile Number"
                        value={phoneNumber}
                        type="tel"
                        error={errorMsgPhoneNumber}
                        onChange={(phoneNumber) => {
                          setPhoneNumber(phoneNumber);
                          setErrorMsgPhoneNumber("");
                        }}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      className="patient-account-form-container-col ion-no-padding"
                      size="12"
                    >
                      <MBInput
                        label="Email Address"
                        value={emailAddress}
                        type="email"
                        error={errorMsgEmaillAddress}
                        onChange={(emailAddress) => {
                          setEmailAddress(emailAddress);
                          setErrorMsgEmaillAddress("");
                        }}
                        readonly={true} //last priority - implement the email change and verification
                      />
                    </IonCol>
                  </IonRow>
                </>
              )}
              {!isMobile() ? (
                <IonRow>
                  <IonCol
                    className="patient-account-form-container-col ion-no-padding"
                    size="6"
                  >
                    <div className="patient-account-form-province-container">
                      <MBDropdownSelect
                        label="Province"
                        value={selectedProvince || provinceSearch}
                        onChange={(provinceSearch) => {
                          setProvinceSearch(provinceSearch);
                          setErrorMsgProvince("");
                          if (provinceSearch.trim() !== selectedProvince) {
                            setSelectedProvince("");
                          }
                        }}
                        onSelectItem={(selectedProvince) => {
                          setSelectedProvince(selectedProvince.name);
                          setListOfCityMunicipality(
                            ADDRESS[selectedProvince.name]
                          );
                          setSelectedCityMunicipality("");
                          setCityMunicipalitySearch("");
                          setErrorMsgProvince("");
                        }}
                        selectFromOptionsRequired={true}
                        options={PROVINCES.map((province, index) => {
                          return {
                            id: index.toString(),
                            name: province,
                          } as MBDropdownSelectOption;
                        })}
                        error={errorMsgProvince}
                      />
                    </div>
                  </IonCol>
                  <IonCol
                    className="patient-account-form-container-col ion-no-padding"
                    size="6"
                  >
                    <div className="patient-account-form-province-container">
                      <MBDropdownSelect
                        label="City/Municipality"
                        value={
                          selectedCityMunicipality || cityMunicipalitySearch
                        }
                        onChange={(cityMunicipalitySearch) => {
                          setCityMunicipalitySearch(cityMunicipalitySearch);
                          setErrorMsgCityMunicipality("");
                          if (
                            cityMunicipalitySearch.trim() !==
                            selectedCityMunicipality
                          ) {
                            setSelectedCityMunicipality("");
                          }
                        }}
                        onSelectItem={(selectedCityMunicipality) => {
                          setSelectedCityMunicipality(
                            selectedCityMunicipality.name
                          );
                          setErrorMsgCityMunicipality("");
                        }}
                        selectFromOptionsRequired={true}
                        options={listOfCityMunicipality.map(
                          (cityMunicipality, index) => {
                            return {
                              id: index.toString(),
                              name: cityMunicipality,
                            } as MBDropdownSelectOption;
                          }
                        )}
                        error={errorMsgCityMunicipality}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              ) : (
                <>
                  <IonRow>
                    <IonCol
                      className="patient-account-form-container-col ion-no-padding"
                      size="12"
                    >
                      <div className="patient-account-form-province-container">
                        <MBDropdownSelect
                          className="patient-account-form-dropdown"
                          label="Province"
                          value={selectedProvince || provinceSearch}
                          onChange={(provinceSearch) => {
                            setProvinceSearch(provinceSearch);
                            setErrorMsgProvince("");
                            if (provinceSearch.trim() !== selectedProvince) {
                              setSelectedProvince("");
                            }
                          }}
                          onSelectItem={(selectedProvince) => {
                            setSelectedProvince(selectedProvince.name);
                            setListOfCityMunicipality(
                              ADDRESS[selectedProvince.name]
                            );
                            setSelectedCityMunicipality("");
                            setCityMunicipalitySearch("");
                            setErrorMsgProvince("");
                          }}
                          selectFromOptionsRequired={true}
                          options={PROVINCES.map((province, index) => {
                            return {
                              id: index.toString(),
                              name: province,
                            } as MBDropdownSelectOption;
                          })}
                          error={errorMsgProvince}
                        />
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      className="patient-account-form-container-col ion-no-padding"
                      size="12"
                    >
                      <div className="patient-account-form-province-container">
                        <MBDropdownSelect
                          className="patient-account-form-dropdown"
                          label="City/Municipality"
                          value={
                            selectedCityMunicipality || cityMunicipalitySearch
                          }
                          onChange={(cityMunicipalitySearch) => {
                            setCityMunicipalitySearch(cityMunicipalitySearch);
                            setErrorMsgCityMunicipality("");
                            if (
                              cityMunicipalitySearch.trim() !==
                              selectedCityMunicipality
                            ) {
                              setSelectedCityMunicipality("");
                            }
                          }}
                          onSelectItem={(selectedCityMunicipality) => {
                            setSelectedCityMunicipality(
                              selectedCityMunicipality.name
                            );
                            setErrorMsgCityMunicipality("");
                          }}
                          selectFromOptionsRequired={true}
                          options={listOfCityMunicipality.map(
                            (cityMunicipality, index) => {
                              return {
                                id: index.toString(),
                                name: cityMunicipality,
                              } as MBDropdownSelectOption;
                            }
                          )}
                          error={errorMsgCityMunicipality}
                        />
                      </div>
                    </IonCol>
                  </IonRow>
                </>
              )}
              <IonRow>
                <IonCol
                  className="patient-account-form-container-col ion-no-padding"
                  size="12"
                >
                  <div className="patient-account-form-doctor-container">
                    <MBDropdownSelect
                      className="patient-account-form-dropdown"
                      label="Preferred Doctor"
                      value={
                        !_.isEmpty(preferredDoctor)
                          ? preferredDoctor!.name
                          : "N/A"
                      }
                      onChange={(doctorSearch) => {
                        setPreferredDoctorSearch(doctorSearch);
                        if (
                          !_.isEmpty(preferredDoctor) &&
                          doctorSearch.trim() !== preferredDoctor!.name
                        ) {
                          setPreferredDoctor(null);
                        }
                      }}
                      onSelectItem={(selectedDoctor) => {
                        setPreferredDoctor(selectedDoctor);
                      }}
                      selectFromOptionsRequired={true}
                      options={doctorOptions}
                      disabled={_.isNull(doctorOptions)}
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className="patient-account-form-container-col ion-no-padding"
                  size="12"
                >
                  <IonItem
                    lines="none"
                    className="patient-account-form-password-item ion-no-padding ion-no-margin"
                  >
                    <IonLabel
                      position="stacked"
                      className="mb-paragraph bold ion-no-padding ion-no-margin"
                    >
                      Password
                    </IonLabel>
                    <IonInput
                      type="password"
                      className="patient-account-form-password-input ion-no-padding ion-no-margin"
                      autocomplete="off"
                      value={patient.emailAddress} //fake password changing password will send an Email
                      readonly={true}
                    >
                      <IonButton
                        color={MBCOLORS.primary}
                        className="patient-account-form-change-password-button sv-paragraph ion-no-margin ion-text-capitalize"
                        onClick={onChangePassword}
                      >
                        Edit Password
                      </IonButton>
                    </IonInput>
                  </IonItem>
                </IonCol>

                <IonCol
                  className="patient-account-form-container-col last ion-no-padding"
                  size="12"
                >
                  {_.isEmpty(governmentId) ? (
                    <MBFileInput
                      label="Government ID"
                      onChange={(file) => {
                        setGovernmentIdFile(file);
                        setErrorMsgGovtIdFile("");
                      }}
                      error={errorMsgGovtIdFile}
                      disableTab={true}
                    />
                  ) : (
                    <>
                      <IonLabel className="mb-paragraph bold">
                        Government ID
                      </IonLabel>
                      <IonImg
                        className="patient-account-form-id ion-margin-top"
                        src={governmentId}
                        onClick={() => {
                          window.open(governmentId, "_blank");
                        }}
                      />
                    </>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </form>
        <MBDivider />
        <div className="patient-account-card-footer">
          <IonButton
            onClick={() => {
              onBack();
            }}
            className="patient-account-cancel-button mb-body ion-text-capitalize"
            fill="clear"
          >
            Back
          </IonButton>
          <IonButton
            onClick={() => {
              onSubmit();
            }}
            className={`patient-account-submit-button ${
              isMobile() && "mobile"
            } mb-body bold white ion-text-capitalize`}
            color={MBCOLORS.primary}
          >
            Save
          </IonButton>
        </div>
      </IonCard>
      <MBDialog
        isOpen={isOpenSendChangePassword}
        title="Email Sent"
        message={`We have sent you an email to ${emailAddress} Click the link to change you account password.`}
        icon="success"
        onDidDismiss={() => {
          setIsOpenSendChangePassword(false);
        }}
      />

      <MBDialog
        isOpen={isOpenSuccessProfileUpdate}
        title="Personal Profile Updated"
        message="You have successfully updated your personal profile. This will now reflect on your appointment details."
        icon="success"
        onDidDismiss={() => {
          setIsOpenSuccessProfileUpdate(false);
          onBack();
        }}
      />

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
