import { Link } from "react-router-dom";
import React from "react";
import { IonFooter, IonLabel } from "@ionic/react";

import "./MBFooter.scss";
import * as routes from "../../constants/routes";
import { getEnv } from "../../env";
import { APP_ID } from "../../config";
import { isMobile } from "../../functions/common";

const env = getEnv();
export const MBFooter: React.FunctionComponent<{}> = () => {
  const isDemoMode = env.firebase.projectId === APP_ID.demo;
  const isDevelopmentMode = env.firebase.projectId === APP_ID.development;

  return (
    <IonFooter>
      <div className="mb-footer-container">
        <a
          href="https://www.cortex.ph"
          target="blank"
          className="mb-footer-copy-right-label mb-paragraph white"
        >
          © Copyright Cortex Technologies Corporation
          {isMobile() ? <br /> : ". "}
          All rights reserved
        </a>
        {isDemoMode && (
          <IonLabel
            className={`mb-footer-training-notice-label ${
              isMobile() && "mobile"
            } mb-h3 white bold`}
          >
            Notice: You are using the MedBook demo environment.
          </IonLabel>
        )}
        {isDevelopmentMode && (
          <IonLabel
            className={`mb-footer-training-notice-label ${
              isMobile() && "mobile"
            } mb-h3 white bold`}
          >
            Notice: You are using the MedBook Development environment.
          </IonLabel>
        )}

        <div className="mb-footer-links-container">
          <Link
            to={routes.FAQ}
            target="_blank"
            className="mb-footer-privacy-label mb-paragraph white"
          >
            FAQ
          </Link>
          <Link
            to={routes.CONTACT_US}
            target="_blank"
            className="mb-footer-privacy-label mb-paragraph white"
          >
            Contact Us
          </Link>
          <Link
            to={routes.DATA_PRIVACY}
            target="_blank"
            className="mb-footer-privacy-label mb-paragraph white"
          >
            Privacy notice
          </Link>
          <Link
            to={routes.TERMS_CONDITION}
            target="_blank"
            className="mb-footer-terms-condition-label mb-paragraph white ion-no-padding ion-no-margin"
          >
            Terms and Condition
          </Link>
        </div>
      </div>
    </IonFooter>
  );
};
