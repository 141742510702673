import React from "react";
import { Helmet } from "react-helmet";
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { home, medkit, people, person } from "ionicons/icons";

import "./Landing.scss";
import * as routes from "../../constants/routes";
import Preregistration from "./Preregistration";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import { MBProps } from "../../interface";
import { analytics } from "../../firebase";
import { ANALYTICS_CONTENT_TYPES } from "../../constants/config";

class Landing extends React.Component<MBProps> {
  state = {
    otherView: "",
    selectedView: "home" as
      | "home"
      | "patient"
      | "doctor"
      | "hospital"
      | "preregistration",
  };
  componentDidMount = () => {
    if (this.props.location.pathname === routes.LANDING_PRE_REGISTER) {
      this.setState({
        selectedView: "preregistration",
      });
    }
  };

  render = () => {
    const { selectedView } = this.state;
    return (
      <>
        <meta
          title="Online Hospital Appointment Booking Philippines | Medbook"
          content="Online booking for hospital appointments, doctor consultations, check ups and more. Make your trips to hospitals near you fast, safe, and easy with Medbook"
        />
        <Helmet>
          <title>
            Online Hospital Appointment Booking Philippines | Medbook
          </title>
        </Helmet>
        <IonPage className="landing-page-container">
          <IonContent>
            <div className="landing-content">
              {selectedView === "preregistration" ? (
                <Preregistration
                  {...this.props}
                  onGoHome={() => {
                    this.setState({
                      selectedView: "home",
                    });
                    analytics.logEvent("go_to_home", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                    });
                  }}
                />
              ) : selectedView === "home" ? (
                <Tab3
                  onGoHome={() => {
                    this.setState({
                      selectedView: "home",
                    });
                    analytics.logEvent("go_to_home", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                    });
                  }}
                  onPreregister={() => {
                    this.setState({
                      selectedView: "preregistration",
                    });
                    analytics.logEvent("go_to_pre_registration", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                    });
                  }}
                  onGoToDoctor={() => {
                    this.setState({
                      selectedView: "doctor",
                    });
                    analytics.logEvent("go_to_doctor", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                    });
                  }}
                  onGoToHospital={() => {
                    this.setState({
                      selectedView: "hospital",
                    });
                    analytics.logEvent("go_to_hospital", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                    });
                  }}
                  onGoToPatient={() => {
                    this.setState({
                      selectedView: "patient",
                    });
                    analytics.logEvent("go_to_patient", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                    });
                  }}
                />
              ) : selectedView === "patient" ? (
                <Tab1
                  onGoHome={() => {
                    this.setState({
                      selectedView: "home",
                    });
                    analytics.logEvent("go_to_home", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                    });
                  }}
                  onPreregister={() => {
                    this.setState({
                      selectedView: "preregistration",
                    });
                    analytics.logEvent("go_to_pre_registration", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                    });
                  }}
                />
              ) : selectedView === "doctor" ? (
                <Tab2
                  onGoHome={() => {
                    this.setState({
                      selectedView: "home",
                    });
                    analytics.logEvent("go_to_home", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                    });
                  }}
                />
              ) : (
                <Tab4
                  onGoHome={() => {
                    this.setState({
                      selectedView: "home",
                    });
                    analytics.logEvent("go_to_home", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                    });
                  }}
                />
              )}
            </div>
            <IonSegment
              className="landing-segment"
              value={selectedView}
              onIonChange={(e) => {
                console.log("Segment selected", e.detail.value);
                this.setState({
                  selectedView: e.detail.value,
                });
                analytics.logEvent("on_click_segment", {
                  content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                  selected_segment: e.detail.value,
                });
              }}
            >
              <IonSegmentButton value="home">
                <IonIcon icon={home} />
                <IonLabel className="ion-text-capitalize">Home</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="patient">
                <IonIcon icon={person} />
                <IonLabel className="ion-text-capitalize">For Patient</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="doctor">
                <IonIcon icon={people} />
                <IonLabel className="ion-text-capitalize">For Doctors</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="hospital">
                <IonIcon icon={medkit} />
                <IonLabel className="ion-text-capitalize">
                  For Hospitals
                </IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonContent>
        </IonPage>
      </>
    );
  };
}

export default Landing;
