import * as _ from "lodash";
import React from "react";

import "./HospitalAccountSetup.scss";
import * as services from "../../services";
// import { DepartmentAccountTypeOptions } from "../../components/DepartmentAccountTypeOptions/DepartmentAccountTypeOptions";
// import { DoctorHospitalAccountSetup } from "../../components/DoctorHospitalAccountSetup/DoctorHospitalAccountSetup";
// import { ServiceAccountSetup } from "../../components/ServiceAccountSetup/ServiceAccountSetup";
import { Hospital, Doctor } from "../../models";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps, UserType, Department } from "../../interface";
import { USER_TYPES, DEPARTMENTS } from "../../constants/config";
import { Helmet } from "react-helmet";

class HospitalAccountSetup extends React.Component<MBProps> {
  state = {
    selectedUserType: null as UserType | null,
    hospitalId: "",
    hospitalName: "",
    hospitalDepartment: DEPARTMENTS.cancerCenter.id as Department,
    hospitalRepName: "",
    doctors: [] as Doctor[],
    specialityMap: {} as {
      [doctorId: string]: string[];
    },
  };

  componentDidMount = async () => {
    const { authUser } = this.props;
    if (!_.isEmpty(authUser)) {
      try {
        const hospital = authUser.userDetails as Hospital;
        this.getAllDoctors();
        this.setState({
          hospitalId: hospital.docId,
          hospitalName: hospital.hospitalName,
          hospitalDepartment: hospital.department,
          hospitalRepName: `${hospital.firstName} ${hospital.lastName}` as string,
        });
      } catch (error) {
        console.log("error -- getHospitalWithRep -- ", error);
      }
    }
  };

  getAllDoctors = async () => {
    try {
      await services.getAllDoctors((doctors: Doctor[]) => {
        this.setState({ doctors: doctors });
        this.getDoctorSpecialities(doctors);
      });
    } catch (error) {
      console.log("error - getAllDoctors -- ", error);
    }
  };

  getDoctorSpecialities = async (doctors: Doctor[]) => {
    try {
      const specialityMap = await services.getDoctorSpecialitiesMap(
        doctors.map((doc) => doc.docId || "")
      );
      this.setState({ specialityMap });
    } catch (error) {
      console.log("error - specialityMap -- ", error);
    }
  };

  render = () => {
    const {
      selectedUserType,
      // hospitalId,
      // hospitalName,
      // hospitalDepartment,
      // hospitalRepName,
      // doctors,
      // specialityMap,
    } = this.state;
    return (
      <>
        <Helmet>
          <title>{`MedBook - Hospital - ${
            selectedUserType === USER_TYPES.services.id ? "Services" : "Doctor"
          } Account Setup`}</title>
        </Helmet>
        <MBContainer {...this.props} activePage="department">
          {/* {_.isNull(selectedUserType) ? (
            <DepartmentAccountTypeOptions
              {...this.props}
              onSelect={(userType) => {
                this.setState({
                  selectedUserType: userType,
                });
              }}
            />
          ) : selectedUserType === USER_TYPES.services.id ? (
            <ServiceAccountSetup
              hospitalId={hospitalId}
              hospitalName={hospitalName}
              onBack={() => {
                this.setState({
                  selectedUserType: null,
                });
              }}
              onCancel={() => {
                this.setState({
                  selectedUserType: null,
                });
              }}
              {...this.props}
            />
          ) : (
            // <DoctorHospitalAccountSetup
            //   doctors={doctors as Doctor[]}
            //   hospitalId={hospitalId}
            //   hospitalName={hospitalName}
            //   hospitalDepartment={hospitalDepartment}
            //   hospitalRepName={hospitalRepName as string}
            //   specialityMap={specialityMap}
            //   onBack={() => {
            //     this.setState({
            //       selectedUserType: null,
            //     });
            //   }}
            //   onCancel={() => {
            //     this.setState({
            //       selectedUserType: null,
            //     });
            //   }}
            //   {...this.props}
            // />
          )} */}
        </MBContainer>
      </>
    );
  };
}

export default HospitalAccountSetup;
