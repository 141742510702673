import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { calendarOutline, timeOutline } from "ionicons/icons";
import {
  IonIcon,
  IonLabel,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCheckbox,
} from "@ionic/react";

import "./MBAppointmentList.scss";
import {
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
} from "../../models";
import {
  daysSinceDate,
  getAppointmentStatus,
  getDayTimeDifference,
  isMobile,
  toDateTimeFromSecs,
} from "../../functions/common";
import { MBCOLORS } from "../../constants/config";

interface MBAppointmentListProps {
  appointmentData: (
    | HospitalDoctorAppointmentsView
    | HospitalServiceAppointmentsView
  )[];
  mode: "doctor" | "patient" | "hospital" | "patient-list";
  showCheckbox?: boolean; //MEANS USED IN NOTIF DIALOG !THIS WILL HIDE CANCEL BUTTONS
  selectedAppointments?: string[];
  onClickCancel: (appointmentId: string) => void;
  onClickView: (appointmentId: string, isDoctorAppointment: boolean) => void;
  onCheckAppointment?: (appointmentIds: string[]) => void;
  isDoctorView?: boolean;
}
export interface AppointmentWithCheckStatus {
  [bookingId: string]: boolean;
}

export const MBAppointmentList = (props: MBAppointmentListProps) => {
  const {
    appointmentData,
    mode,
    showCheckbox,
    selectedAppointments,
    onClickView,
    onClickCancel,
    onCheckAppointment,
    isDoctorView,
  } = props;

  return (
    <div className="mb-appointment-list-container">
      {!_.isEmpty(appointmentData) ? (
        appointmentData.map((appointment) => {
          const doctorAppointment = appointment as HospitalDoctorAppointmentsView;
          const serviceAppointment = appointment as HospitalServiceAppointmentsView;
          const isDoctorAppointment = doctorAppointment.doctorId !== undefined;

          const doneWithCovidCheck =
            !!appointment.patientDetails.lastCovidFree &&
            daysSinceDate(appointment.patientDetails.lastCovidFree.toDate()) <=
              1;

          const isCovidRisk =
            !!doctorAppointment.patientDetails.isCovidPositive &&
            doneWithCovidCheck;

          return isDoctorAppointment ? (
            mode === "doctor" ? (
              <IonCard
                color={MBCOLORS.tertiary}
                className="mb-appointment-list-card ion-no-margin ion-no-padding"
              >
                <IonGrid className="mb-appointment-list-grid ion-no-margin ion-no-padding">
                  <IonRow className="mb-appointment-list-row ion-no-argin ion-no-padding">
                    <IonCol
                      size={isMobile() ? "12" : "2"}
                      className="mb-appointment-list-icon-col ion-no-margin ion-no-padding"
                    >
                      {showCheckbox && (
                        <IonCheckbox
                          checked={
                            selectedAppointments !== undefined
                              ? selectedAppointments.includes(
                                  appointment.docId || ""
                                )
                              : false
                          }
                          onClick={(event) => {
                            if (selectedAppointments !== undefined) {
                              const copySelectedAppointments = _.cloneDeep(
                                selectedAppointments
                              );

                              if (event.currentTarget.checked) {
                                copySelectedAppointments.push(
                                  appointment.docId || ""
                                );
                              } else {
                                _.pull(
                                  copySelectedAppointments,
                                  appointment.docId || ""
                                );
                              }

                              if (onCheckAppointment !== undefined) {
                                onCheckAppointment(copySelectedAppointments);
                              }
                            }
                          }}
                          mode="md"
                          className="mb-appointment-list-content-checkbox"
                        />
                      )}
                      <div className="mb-appointment-list-icon-container">
                        <IonIcon className="mb-appointment-list-card-icon patient" />
                        <IonLabel
                          className={`mb-appointment-list-status ${getAppointmentStatus(
                            doctorAppointment.appointmentDate,
                            doctorAppointment.isCancelled,
                            doctorAppointment.appointmentStatus
                          )}`}
                        >
                          {console.log(
                            getAppointmentStatus(
                              doctorAppointment.appointmentDate,
                              doctorAppointment.isCancelled,
                              doctorAppointment.appointmentStatus
                            ).toUpperCase()
                          )}
                          {getAppointmentStatus(
                            doctorAppointment.appointmentDate,
                            doctorAppointment.isCancelled,
                            doctorAppointment.appointmentStatus
                          ).toUpperCase()}
                        </IonLabel>
                      </div>
                    </IonCol>
                    <IonCol
                      size={isMobile() ? "12" : "6.5"}
                      className="mb-appointment-list-details-col ion-no-margin ion-no-padding"
                    >
                      <div className="mb-appointment-name-container">
                        <IonLabel className="mb-appointment-list-patient-name mb-h2 dark-blue normal ion-no-padding ion-no-margin">
                          <b>{`${doctorAppointment.patientDetails.firstName} ${doctorAppointment.patientDetails.lastName}`}</b>{" "}
                        </IonLabel>
                        {isCovidRisk && (
                          <div className="covid-risk-container">
                            <IonIcon className="covid-risk-icon" />{" "}
                            <IonLabel className="mb-body white bold ion-no-padding ion-no-margin">
                              Covid Risk
                            </IonLabel>
                          </div>
                        )}
                      </div>

                      <div className="mb-appointment-list-hospital-container">
                        <IonLabel className="mb-appointment-list-hospital mb-body ion-no-padding ion-no-margin">
                          {doctorAppointment.hospitalDetails.hospitalName}
                        </IonLabel>
                        <IonLabel className="mb-appointment-list-appointment-type mb-body light ion-no-padding ion-no-margin">
                          {" "}
                          • Consultation
                        </IonLabel>
                      </div>
                      <div className="mb-appointment-list-schedule-container">
                        <div className="mb-appointment-list-date-container">
                          <IonIcon
                            className="mb-appointment-list-calendar-icon"
                            color={MBCOLORS.primary}
                            icon={calendarOutline}
                          />
                          <IonLabel className="mb-appointment-list-date-label mb-body">
                            {moment(doctorAppointment.appointmentDate).format(
                              "MMMM DD, YYYY"
                            )}
                          </IonLabel>
                        </div>
                        <div className="mb-appointment-list-time-container">
                          <IonIcon
                            color={MBCOLORS.primary}
                            icon={timeOutline}
                          />
                          <IonLabel className="mb-appointment-list-time-label mb-body">
                            {/* //todo: once the function for checking time is okay replace the Est. Time */}
                            Est. Time{" "}
                            {moment(doctorAppointment.appointmentDate).format(
                              "HH:mm A"
                            )}{" "}
                            - Slot {doctorAppointment.slotNumber}
                          </IonLabel>
                        </div>
                      </div>
                      <IonLabel className="mb-appointment-list-fee-label mb-h4 normal">
                        <b>Php {doctorAppointment.resourceFee}</b> Consultation
                        Fee
                      </IonLabel>
                    </IonCol>
                    <IonCol
                      size={isMobile() ? "12" : "3.5"}
                      className="mb-appointment-list-button-col ion-no-margin ion-no-padding"
                    >
                      <IonLabel className="mb-appointment-list-days-counter mb-paragraph light ion-no-padding ion-no-margin">
                        {`${getDayTimeDifference(
                          doctorAppointment.createdAt.toDate()
                        )} ago`}
                      </IonLabel>
                      <div className="mb-appointment-list-button-container">
                        {_.isEmpty(doctorAppointment.appointmentStatus) &&
                          !doctorAppointment.isCancelled &&
                          !showCheckbox && (
                            <IonButton
                              mode="ios"
                              fill="clear"
                              color={MBCOLORS.danger}
                              className="mb-appointment-list-button cancel mb-h4 bold ion-no-padding ion-no-margin"
                              onClick={() => {
                                onClickCancel(doctorAppointment.docId || "");
                              }}
                            >
                              Cancel
                            </IonButton>
                          )}
                        <IonButton
                          mode="ios"
                          fill="clear"
                          color={MBCOLORS.primary}
                          className="mb-appointment-list-button view mb-h4 bold ion-no-padding ion-no-margin"
                          onClick={() => {
                            onClickView(doctorAppointment.docId || "", true);
                          }}
                        >
                          View
                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            ) : (
              <IonCard
                color={MBCOLORS.tertiary}
                className="mb-appointment-list-card ion-no-margin ion-no-padding"
              >
                <IonGrid className="mb-appointment-list-grid ion-no-margin ion-no-padding">
                  {(mode !== "patient-list" ||
                    (mode === "patient-list" && !isMobile())) && (
                    <IonRow className="mb-appointment-list-row ion-no-argin ion-no-padding">
                      <IonCol
                        size={isMobile() ? "12" : "2"}
                        className="mb-appointment-list-icon-col ion-no-margin ion-no-padding"
                      >
                        {showCheckbox && (
                          <IonCheckbox
                            checked={
                              selectedAppointments !== undefined
                                ? selectedAppointments.includes(
                                    appointment.docId || ""
                                  )
                                : false
                            }
                            onClick={(event) => {
                              if (selectedAppointments !== undefined) {
                                const copySelectedAppointments = _.cloneDeep(
                                  selectedAppointments
                                );

                                if (event.currentTarget.checked) {
                                  copySelectedAppointments.push(
                                    appointment.docId || ""
                                  );
                                } else {
                                  _.pull(
                                    copySelectedAppointments,
                                    appointment.docId || ""
                                  );
                                }

                                if (onCheckAppointment !== undefined) {
                                  onCheckAppointment(copySelectedAppointments);
                                }
                              }
                            }}
                            mode="md"
                            className="mb-appointment-list-content-checkbox"
                          />
                        )}
                        <div className="mb-appointment-list-icon-container">
                          <IonIcon
                            className={`mb-appointment-list-card-icon ${
                              isDoctorView ? "patient" : "doctor"
                            }`}
                          />
                          <IonLabel
                            className={`mb-appointment-list-status ${getAppointmentStatus(
                              doctorAppointment.appointmentDate,
                              doctorAppointment.isCancelled,
                              doctorAppointment.appointmentStatus
                            )}`}
                          >
                            {getAppointmentStatus(
                              doctorAppointment.appointmentDate,
                              doctorAppointment.isCancelled,
                              doctorAppointment.appointmentStatus
                            ).toUpperCase()}
                          </IonLabel>
                        </div>
                      </IonCol>
                      <IonCol
                        size={isMobile() ? "12" : "6.5"}
                        className="mb-appointment-list-details-col ion-no-margin ion-no-padding"
                      >
                        <div className="mb-appointment-name-container">
                          <IonLabel className="mb-appointment-list-patient-name mb-h2 dark-blue normal ion-no-padding ion-no-margin">
                            <b>
                              {mode !== "patient-list"
                                ? `${doctorAppointment.doctorDetails.firstName} ${doctorAppointment.doctorDetails.lastName}`
                                : `${doctorAppointment.patientDetails.firstName} ${doctorAppointment.patientDetails.lastName}`}
                            </b>
                            {mode !== "patient-list"
                              ? ` (${doctorAppointment.doctorHospitalDetails.specialities
                                  .map((speciality) => speciality.name)
                                  .toString()})`
                              : " (General Consultation)"}
                          </IonLabel>
                          {isCovidRisk && mode !== "patient" && (
                            <div className="covid-risk-container">
                              <IonIcon className="covid-risk-icon" />{" "}
                              <IonLabel className="mb-body white bold ion-no-padding ion-no-margin">
                                Covid Risk
                              </IonLabel>
                            </div>
                          )}
                        </div>
                        <div className="mb-appointment-list-hospital-container">
                          <IonLabel className="mb-appointment-list-hospital mb-body ion-no-padding ion-no-margin">
                            {mode !== "patient-list"
                              ? doctorAppointment.hospitalDetails.hospitalName
                              : `${doctorAppointment.doctorDetails.firstName} ${doctorAppointment.doctorDetails.lastName}`}
                          </IonLabel>
                          <IonLabel className="mb-appointment-list-appointment-type mb-body light ion-no-padding ion-no-margin">
                            {" "}
                            • Consultation
                          </IonLabel>
                        </div>
                        <div className="mb-appointment-list-schedule-container">
                          <div className="mb-appointment-list-date-container">
                            <IonIcon
                              className="mb-appointment-list-calendar-icon"
                              color={MBCOLORS.primary}
                              icon={calendarOutline}
                            />
                            <IonLabel className="mb-appointment-list-date-label mb-body">
                              {moment(doctorAppointment.appointmentDate).format(
                                "MMMM DD, YYYY"
                              )}
                            </IonLabel>
                          </div>
                          <div className="mb-appointment-list-time-container">
                            <IonIcon
                              color={MBCOLORS.primary}
                              icon={timeOutline}
                            />
                            <IonLabel className="mb-appointment-list-time-label mb-body">
                              Est. Time{" "}
                              {moment(doctorAppointment.appointmentDate).format(
                                "HH:mm A"
                              )}{" "}
                              - Slot {doctorAppointment.slotNumber}
                            </IonLabel>
                          </div>
                        </div>
                        <IonLabel className="mb-appointment-list-fee-label mb-h4 normal">
                          {mode !== "patient-list" ? (
                            <>
                              <b>Php {doctorAppointment.resourceFee}</b>{" "}
                              Consultation Fee
                            </>
                          ) : (
                            <div className="mb-appointment-list-with-result-covid-container">
                              <IonIcon className="mb-appointment-list-with-result-covid-icon" />
                              <IonLabel
                                className={`mb-appointment-list-with-result-covid-label mb-body bold ${
                                  doneWithCovidCheck ? "success" : "danger"
                                } ion-no-padding ion-no-margin`}
                              >
                                {doneWithCovidCheck
                                  ? "Done with Covid Check"
                                  : "Not yet done with Covid Check"}
                              </IonLabel>
                            </div>
                          )}
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        size={isMobile() ? "12" : "3.5"}
                        className="mb-appointment-list-button-col ion-no-margin ion-no-padding"
                      >
                        <IonLabel className="mb-appointment-list-days-counter mb-paragraph light ion-no-padding ion-no-margin">
                          {`${getDayTimeDifference(
                            toDateTimeFromSecs(
                              doctorAppointment.createdAt.seconds
                            )
                          )} ago`}
                        </IonLabel>
                        <div className="mb-appointment-list-button-container">
                          {_.isEmpty(doctorAppointment.appointmentStatus) &&
                            !doctorAppointment.isCancelled &&
                            mode !== "patient-list" &&
                            !showCheckbox && (
                              <IonButton
                                mode="ios"
                                fill="clear"
                                color={MBCOLORS.danger}
                                className="mb-appointment-list-button cancel mb-h4 bold ion-no-padding ion-no-margin"
                                onClick={() => {
                                  onClickCancel(doctorAppointment.docId || "");
                                }}
                              >
                                Cancel
                              </IonButton>
                            )}
                          {(mode !== "patient-list" ||
                            (mode === "patient-list" &&
                              !doctorAppointment.isCancelled)) && (
                            <IonButton
                              mode="ios"
                              fill="clear"
                              color={MBCOLORS.primary}
                              className={`mb-appointment-list-button ${
                                mode === "patient-list"
                                  ? "confirm-arrival"
                                  : "view"
                              } mb-h4 bold ion-no-padding ion-no-margin`}
                              onClick={() => {
                                onClickView(
                                  doctorAppointment.docId || "",
                                  true
                                );
                              }}
                            >
                              {mode !== "patient-list"
                                ? "View"
                                : "Confirm Arrival"}
                            </IonButton>
                          )}
                        </div>
                      </IonCol>
                    </IonRow>
                  )}

                  {isMobile() &&
                    mode === "patient-list" &&
                    [
                      <IonCol
                        size="12"
                        className="mb-appointment-list-icon-col ion-no-margin ion-no-padding"
                      >
                        <div className="mb-appointment-list-icon-container">
                          <IonIcon className="mb-appointment-list-card-icon doctor" />
                          <IonLabel
                            className={`mb-appointment-list-status ${getAppointmentStatus(
                              doctorAppointment.appointmentDate,
                              doctorAppointment.isCancelled,
                              doctorAppointment.appointmentStatus
                            )}`}
                          >
                            {getAppointmentStatus(
                              doctorAppointment.appointmentDate,
                              doctorAppointment.isCancelled,
                              doctorAppointment.appointmentStatus
                            ).toUpperCase()}
                          </IonLabel>
                        </div>
                      </IonCol>,
                      <IonCol
                        size="12"
                        className="mb-appointment-list-details-col ion-no-margin ion-no-padding"
                      >
                        <div className="mb-appointment-name-container">
                          <IonLabel className="mb-appointment-list-patient-name mb-h2 dark-blue normal ion-no-padding ion-no-margin">
                            <b>
                              {mode !== "patient-list"
                                ? `${doctorAppointment.doctorDetails.firstName} ${doctorAppointment.doctorDetails.lastName}`
                                : `${doctorAppointment.patientDetails.firstName} ${doctorAppointment.patientDetails.lastName}`}
                            </b>
                            {mode !== "patient-list"
                              ? ` (${doctorAppointment.doctorHospitalDetails.specialities
                                  .map((speciality) => speciality.name)
                                  .toString()})`
                              : " (General Consultation)"}
                          </IonLabel>
                          {!!doctorAppointment.patientDetails
                            .isCovidPositive && (
                            <div className="covid-risk-container">
                              <IonIcon className="covid-risk-icon" />{" "}
                              <IonLabel className="mb-body white bold ion-no-padding ion-no-margin">
                                Covid Risk
                              </IonLabel>
                            </div>
                          )}
                        </div>
                        <div className="mb-appointment-list-hospital-container">
                          <IonLabel className="mb-appointment-list-hospital mb-body ion-no-padding ion-no-margin">
                            {mode !== "patient-list"
                              ? doctorAppointment.hospitalDetails.hospitalName
                              : `${doctorAppointment.doctorDetails.firstName} ${doctorAppointment.doctorDetails.lastName}`}
                          </IonLabel>
                          <IonLabel className="mb-appointment-list-appointment-type mb-body light ion-no-padding ion-no-margin">
                            {" "}
                            • Consultation
                          </IonLabel>
                        </div>
                        <div className="mb-appointment-list-schedule-container">
                          <div className="mb-appointment-list-date-container">
                            <IonIcon
                              className="mb-appointment-list-calendar-icon"
                              color={MBCOLORS.primary}
                              icon={calendarOutline}
                            />
                            <IonLabel className="mb-appointment-list-date-label mb-body">
                              {moment(doctorAppointment.appointmentDate).format(
                                "MMMM DD, YYYY"
                              )}
                            </IonLabel>
                          </div>
                          <div className="mb-appointment-list-time-container">
                            <IonIcon
                              color={MBCOLORS.primary}
                              icon={timeOutline}
                            />
                            <IonLabel className="mb-appointment-list-time-label mb-body">
                              Est. Time{" "}
                              {moment(doctorAppointment.appointmentDate).format(
                                "HH:mm A"
                              )}{" "}
                              - Slot {doctorAppointment.slotNumber}
                            </IonLabel>
                          </div>
                        </div>
                        <IonLabel className="mb-appointment-list-fee-label mb-h4 normal">
                          {mode !== "patient-list" ? (
                            <>
                              <b>Php {doctorAppointment.resourceFee}</b>{" "}
                              Consultation Fee
                            </>
                          ) : (
                            <div className="mb-appointment-list-with-result-covid-container">
                              <IonIcon className="mb-appointment-list-with-result-covid-icon" />
                              <IonLabel
                                className={`mb-appointment-list-with-result-covid-label mb-body bold ${
                                  doneWithCovidCheck ? "success" : "danger"
                                } ion-no-padding ion-no-margin`}
                              >
                                {doneWithCovidCheck
                                  ? "Done with Covid Check"
                                  : "Not yet done with Covid Check"}
                              </IonLabel>
                            </div>
                          )}
                        </IonLabel>
                      </IonCol>,
                      <IonCol
                        size="12"
                        className="mb-appointment-list-button-col ion-no-margin ion-no-padding"
                      >
                        <IonLabel className="mb-appointment-list-days-counter mb-paragraph light ion-no-padding ion-no-margin">
                          {`${getDayTimeDifference(
                            toDateTimeFromSecs(
                              doctorAppointment.createdAt.seconds
                            )
                          )} ago`}
                        </IonLabel>
                        <div className="mb-appointment-list-button-container">
                          {_.isEmpty(doctorAppointment.appointmentStatus) &&
                            !doctorAppointment.isCancelled &&
                            mode !== "patient-list" &&
                            !showCheckbox && (
                              <IonButton
                                mode="ios"
                                fill="clear"
                                color={MBCOLORS.danger}
                                className="mb-appointment-list-button cancel mb-h4 bold ion-no-padding ion-no-margin"
                                onClick={() => {
                                  onClickCancel(doctorAppointment.docId || "");
                                }}
                              >
                                Cancel
                              </IonButton>
                            )}
                          {(mode !== "patient-list" ||
                            (mode === "patient-list" &&
                              !doctorAppointment.isCancelled)) && (
                            <IonButton
                              mode="ios"
                              fill="clear"
                              color={MBCOLORS.primary}
                              className={`mb-appointment-list-button ${
                                mode === "patient-list"
                                  ? "confirm-arrival"
                                  : "view"
                              } mb-h4 bold ion-no-padding ion-no-margin`}
                              onClick={() => {
                                onClickView(
                                  doctorAppointment.docId || "",
                                  true
                                );
                              }}
                            >
                              {mode !== "patient-list"
                                ? "View"
                                : "Confirm Arrival"}
                            </IonButton>
                          )}
                        </div>
                      </IonCol>,
                    ].map((col) => {
                      return (
                        <IonRow className="mb-appointment-list-row ion-no-argin ion-no-padding">
                          {col}
                        </IonRow>
                      );
                    })}
                </IonGrid>
              </IonCard>
            )
          ) : mode === "patient" ? (
            <IonCard
              color={MBCOLORS.tertiary}
              className="mb-appointment-list-card ion-no-margin ion-no-padding"
            >
              <IonGrid className="mb-appointment-list-grid ion-no-margin ion-no-padding">
                <IonRow className="mb-appointment-list-row ion-no-argin ion-no-padding">
                  <IonCol
                    size={isMobile() ? "12" : "2"}
                    className="mb-appointment-list-icon-col ion-no-margin ion-no-padding"
                  >
                    <div className="mb-appointment-list-icon-container">
                      <IonIcon className="mb-appointment-list-card-icon out-patient" />
                      <IonLabel
                        className={`mb-appointment-list-status ${getAppointmentStatus(
                          serviceAppointment.appointmentDate,
                          serviceAppointment.isCancelled,
                          serviceAppointment.appointmentStatus
                        )}`}
                      >
                        {getAppointmentStatus(
                          serviceAppointment.appointmentDate,
                          serviceAppointment.isCancelled,
                          serviceAppointment.appointmentStatus
                        ).toUpperCase()}
                      </IonLabel>
                    </div>
                  </IonCol>
                  <IonCol
                    size={isMobile() ? "12" : "6.5"}
                    className="mb-appointment-list-details-col ion-no-margin ion-no-padding"
                  >
                    <IonLabel className="mb-appointment-list-patient-name mb-h2 dark-blue normal ion-no-padding ion-no-margin">
                      <b>{`${serviceAppointment.hospitalServiceDetails.treatmentName}`}</b>
                    </IonLabel>
                    <div className="mb-appointment-list-hospital-container">
                      <IonLabel className="mb-appointment-list-hospital mb-body ion-no-padding ion-no-margin">
                        {serviceAppointment.hospitalDetails.hospitalName}
                      </IonLabel>
                      <IonLabel className="mb-appointment-list-appointment-type mb-body light ion-no-padding ion-no-margin">
                        {" "}
                        • Out-Patient Service
                      </IonLabel>
                    </div>
                    <div className="mb-appointment-list-schedule-container">
                      <div className="mb-appointment-list-date-container">
                        <IonIcon
                          className="mb-appointment-list-calendar-icon"
                          color={MBCOLORS.primary}
                          icon={calendarOutline}
                        />
                        <IonLabel className="mb-appointment-list-date-label mb-body">
                          {moment(serviceAppointment.appointmentDate).format(
                            "MMMM DD, YYYY"
                          )}
                        </IonLabel>
                      </div>
                      <div className="mb-appointment-list-time-container">
                        <IonIcon color={MBCOLORS.primary} icon={timeOutline} />
                        <IonLabel className="mb-appointment-list-time-label mb-body">
                          Est. Time{" "}
                          {moment(serviceAppointment.appointmentDate).format(
                            "HH:mm A"
                          )}{" "}
                          - Slot {serviceAppointment.slotNumber}
                        </IonLabel>
                      </div>
                    </div>
                    <IonLabel className="mb-appointment-list-fee-label mb-h4 normal">
                      <b>Php {serviceAppointment.resourceFee}</b> Service Fee
                    </IonLabel>
                  </IonCol>
                  <IonCol
                    size={isMobile() ? "12" : "3.5"}
                    className="mb-appointment-list-button-col ion-no-margin ion-no-padding"
                  >
                    <IonLabel className="mb-appointment-list-days-counter mb-paragraph light ion-no-padding ion-no-margin">
                      {`${getDayTimeDifference(
                        toDateTimeFromSecs(serviceAppointment.createdAt.seconds)
                      )} ago`}
                    </IonLabel>
                    <div className="mb-appointment-list-button-container">
                      {_.isEmpty(serviceAppointment.appointmentStatus) &&
                        !serviceAppointment.isCancelled &&
                        !showCheckbox && (
                          <IonButton
                            mode="ios"
                            fill="clear"
                            color={MBCOLORS.danger}
                            className="mb-appointment-list-button cancel mb-h4 bold ion-no-padding ion-no-margin"
                            onClick={() => {
                              onClickCancel(serviceAppointment.docId || "");
                            }}
                          >
                            Cancel
                          </IonButton>
                        )}
                      <IonButton
                        mode="ios"
                        fill="clear"
                        color={MBCOLORS.primary}
                        className="mb-appointment-list-button view mb-h4 bold ion-no-padding ion-no-margin"
                        onClick={() => {
                          onClickView(serviceAppointment.docId || "", false);
                        }}
                      >
                        View
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          ) : (
            <IonCard
              color={MBCOLORS.tertiary}
              className="mb-appointment-list-card ion-no-margin ion-no-padding"
            >
              <IonGrid className="mb-appointment-list-grid ion-no-margin ion-no-padding">
                {(mode !== "patient-list" ||
                  (mode === "patient-list" && !isMobile())) && (
                  <IonRow className="mb-appointment-list-row ion-no-argin ion-no-padding">
                    <IonCol
                      size={isMobile() ? "12" : "2"}
                      className="mb-appointment-list-icon-col ion-no-margin ion-no-padding"
                    >
                      {showCheckbox && (
                        <IonCheckbox
                          checked={
                            selectedAppointments !== undefined
                              ? selectedAppointments.includes(
                                  appointment.docId || ""
                                )
                              : false
                          }
                          onClick={(event) => {
                            if (selectedAppointments !== undefined) {
                              const copySelectedAppointments = _.cloneDeep(
                                selectedAppointments
                              );

                              if (event.currentTarget.checked) {
                                copySelectedAppointments.push(
                                  appointment.docId || ""
                                );
                              } else {
                                _.pull(
                                  copySelectedAppointments,
                                  appointment.docId || ""
                                );
                              }

                              if (onCheckAppointment !== undefined) {
                                onCheckAppointment(copySelectedAppointments);
                              }
                            }
                          }}
                          mode="md"
                          className="mb-appointment-list-content-checkbox"
                        />
                      )}
                      <div className="mb-appointment-list-icon-container">
                        <IonIcon className="mb-appointment-list-card-icon out-patient" />
                        <IonLabel
                          className={`mb-appointment-list-status ${getAppointmentStatus(
                            serviceAppointment.appointmentDate,
                            serviceAppointment.isCancelled,
                            serviceAppointment.appointmentStatus
                          )}`}
                        >
                          {getAppointmentStatus(
                            serviceAppointment.appointmentDate,
                            serviceAppointment.isCancelled,
                            serviceAppointment.appointmentStatus
                          ).toUpperCase()}
                        </IonLabel>
                      </div>
                    </IonCol>
                    <IonCol
                      size={isMobile() ? "12" : "6.5"}
                      className="mb-appointment-list-details-col ion-no-margin ion-no-padding"
                    >
                      <div className="mb-appointment-name-container">
                        <IonLabel className="mb-appointment-list-patient-name mb-h2 dark-blue normal ion-no-padding ion-no-margin">
                          <b>{`${serviceAppointment.patientDetails.firstName} ${serviceAppointment.patientDetails.lastName}`}</b>
                          {` (${serviceAppointment.hospitalServiceDetails.treatmentName})`}
                        </IonLabel>
                        {isCovidRisk && (
                          <div className="covid-risk-container">
                            <IonIcon className="covid-risk-icon" />{" "}
                            <IonLabel className="mb-body white bold ion-no-padding ion-no-margin">
                              Covid Risk
                            </IonLabel>
                          </div>
                        )}
                      </div>
                      <div className="mb-appointment-list-hospital-container">
                        <IonLabel className="mb-appointment-list-hospital mb-body ion-no-padding ion-no-margin">
                          {serviceAppointment.hospitalDetails.hospitalName}
                        </IonLabel>
                        <IonLabel className="mb-appointment-list-appointment-type mb-body light ion-no-padding ion-no-margin">
                          {" "}
                          • Out-Patient Service
                        </IonLabel>
                      </div>
                      <div className="mb-appointment-list-schedule-container">
                        <div className="mb-appointment-list-date-container">
                          <IonIcon
                            className="mb-appointment-list-calendar-icon"
                            color={MBCOLORS.primary}
                            icon={calendarOutline}
                          />
                          <IonLabel className="mb-appointment-list-date-label mb-body">
                            {moment(serviceAppointment.appointmentDate).format(
                              "MMMM DD, YYYY"
                            )}
                          </IonLabel>
                        </div>
                        <div className="mb-appointment-list-time-container">
                          <IonIcon
                            color={MBCOLORS.primary}
                            icon={timeOutline}
                          />
                          <IonLabel className="mb-appointment-list-time-label mb-body">
                            {/* //todo: once the function for checking time is okay replace the Est. Time */}
                            Est. Time{" "}
                            {moment(serviceAppointment.appointmentDate).format(
                              "HH:mm A"
                            )}{" "}
                            - Slot {serviceAppointment.slotNumber}
                          </IonLabel>
                        </div>
                      </div>
                      <IonLabel className="mb-appointment-list-fee-label mb-h4 normal">
                        {mode !== "patient-list" ? (
                          <>
                            <b>Php {serviceAppointment.resourceFee}</b> Service
                            Fee
                          </>
                        ) : (
                          <div className="mb-appointment-list-with-result-covid-container">
                            <IonIcon className="mb-appointment-list-with-result-covid-icon" />
                            <IonLabel
                              className={`mb-appointment-list-with-result-covid-label mb-body bold ${
                                doneWithCovidCheck ? "success" : "danger"
                              } ion-no-padding ion-no-margin`}
                            >
                              {doneWithCovidCheck
                                ? "Done with Covid Check"
                                : "Not yet done with Covid Check"}
                            </IonLabel>
                          </div>
                        )}
                      </IonLabel>
                    </IonCol>
                    <IonCol
                      size={isMobile() ? "12" : "3.5"}
                      className="mb-appointment-list-button-col ion-no-margin ion-no-padding"
                    >
                      <IonLabel className="mb-appointment-list-days-counter mb-paragraph light ion-no-padding ion-no-margin">
                        {`${getDayTimeDifference(
                          toDateTimeFromSecs(
                            doctorAppointment.createdAt.seconds
                          )
                        )} ago`}
                      </IonLabel>
                      <div className="mb-appointment-list-button-container">
                        {_.isEmpty(serviceAppointment.appointmentStatus) &&
                          !serviceAppointment.isCancelled &&
                          mode !== "patient-list" &&
                          !showCheckbox && (
                            <IonButton
                              mode="ios"
                              fill="clear"
                              color={MBCOLORS.danger}
                              className="mb-appointment-list-button cancel mb-h4 bold ion-no-padding ion-no-margin"
                              onClick={() => {
                                onClickCancel(serviceAppointment.docId || "");
                              }}
                            >
                              Cancel
                            </IonButton>
                          )}
                        {(mode !== "patient-list" ||
                          (mode === "patient-list" &&
                            !doctorAppointment.isCancelled)) && (
                          <IonButton
                            mode="ios"
                            fill="clear"
                            color={MBCOLORS.primary}
                            className={`mb-appointment-list-button ${
                              mode === "patient-list"
                                ? "confirm-arrival"
                                : "view"
                            } mb-h4 bold ion-no-padding ion-no-margin`}
                            onClick={() => {
                              onClickView(
                                serviceAppointment.docId || "",
                                false
                              );
                            }}
                          >
                            {mode !== "patient-list"
                              ? "View"
                              : "Confirm Arrival"}
                          </IonButton>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>
                )}

                {isMobile() &&
                  mode === "patient-list" &&
                  [
                    <IonCol
                      size="12"
                      className="mb-appointment-list-icon-col ion-no-margin ion-no-padding"
                    >
                      <div className="mb-appointment-list-icon-container">
                        <IonIcon className="mb-appointment-list-card-icon out-patient" />
                        <IonLabel
                          className={`mb-appointment-list-status ${getAppointmentStatus(
                            serviceAppointment.appointmentDate,
                            serviceAppointment.isCancelled,
                            serviceAppointment.appointmentStatus
                          )}`}
                        >
                          {getAppointmentStatus(
                            serviceAppointment.appointmentDate,
                            serviceAppointment.isCancelled,
                            serviceAppointment.appointmentStatus
                          ).toUpperCase()}
                        </IonLabel>
                      </div>
                    </IonCol>,
                    <IonCol
                      size="12"
                      className="mb-appointment-list-details-col ion-no-margin ion-no-padding"
                    >
                      <div className="mb-appointment-name-container">
                        <IonLabel className="mb-appointment-list-patient-name mb-h2 dark-blue normal ion-no-padding ion-no-margin">
                          <b>{`${serviceAppointment.patientDetails.firstName} ${serviceAppointment.patientDetails.lastName}`}</b>
                          {` (${serviceAppointment.hospitalServiceDetails.treatmentName})`}
                        </IonLabel>
                        {isCovidRisk && (
                          <div className="covid-risk-container">
                            <IonIcon className="covid-risk-icon" />{" "}
                            <IonLabel className="mb-body white bold ion-no-padding ion-no-margin">
                              Covid Risk
                            </IonLabel>
                          </div>
                        )}
                      </div>
                      <div className="mb-appointment-list-hospital-container">
                        <IonLabel className="mb-appointment-list-hospital mb-body ion-no-padding ion-no-margin">
                          {serviceAppointment.hospitalDetails.hospitalName}
                        </IonLabel>
                        <IonLabel className="mb-appointment-list-appointment-type mb-body light ion-no-padding ion-no-margin">
                          {" "}
                          • Out-Patient Service
                        </IonLabel>
                      </div>
                      <div className="mb-appointment-list-schedule-container">
                        <div className="mb-appointment-list-date-container">
                          <IonIcon
                            className="mb-appointment-list-calendar-icon"
                            color={MBCOLORS.primary}
                            icon={calendarOutline}
                          />
                          <IonLabel className="mb-appointment-list-date-label mb-body">
                            {moment(serviceAppointment.appointmentDate).format(
                              "MMMM DD, YYYY"
                            )}
                          </IonLabel>
                        </div>
                        <div className="mb-appointment-list-time-container">
                          <IonIcon
                            color={MBCOLORS.primary}
                            icon={timeOutline}
                          />
                          <IonLabel className="mb-appointment-list-time-label mb-body">
                            {/* //todo: once the function for checking time is okay replace the Est. Time */}
                            Est. Time{" "}
                            {moment(serviceAppointment.appointmentDate).format(
                              "HH:mm A"
                            )}{" "}
                            - Slot {serviceAppointment.slotNumber}
                          </IonLabel>
                        </div>
                      </div>
                      <IonLabel className="mb-appointment-list-fee-label mb-h4 normal">
                        {mode !== "patient-list" ? (
                          <>
                            <b>Php {serviceAppointment.resourceFee}</b> Service
                            Fee
                          </>
                        ) : (
                          <div className="mb-appointment-list-with-result-covid-container">
                            <IonIcon className="mb-appointment-list-with-result-covid-icon" />
                            <IonLabel
                              className={`mb-appointment-list-with-result-covid-label mb-body bold ${
                                doneWithCovidCheck ? "success" : "danger"
                              } ion-no-padding ion-no-margin`}
                            >
                              {doneWithCovidCheck
                                ? "Done with Covid Check"
                                : "Not yet done with Covid Check"}
                            </IonLabel>
                          </div>
                        )}
                      </IonLabel>
                    </IonCol>,
                    <IonCol
                      size="12"
                      className="mb-appointment-list-button-col ion-no-margin ion-no-padding"
                    >
                      <IonLabel className="mb-appointment-list-days-counter mb-paragraph light ion-no-padding ion-no-margin">
                        {`${getDayTimeDifference(
                          toDateTimeFromSecs(
                            doctorAppointment.createdAt.seconds
                          )
                        )} ago`}
                      </IonLabel>
                      <div className="mb-appointment-list-button-container">
                        {_.isEmpty(serviceAppointment.appointmentStatus) &&
                          !serviceAppointment.isCancelled &&
                          mode !== "patient-list" && (
                            <IonButton
                              mode="ios"
                              fill="clear"
                              color={MBCOLORS.danger}
                              className="mb-appointment-list-button cancel mb-h4 bold ion-no-padding ion-no-margin"
                              onClick={() => {
                                onClickCancel(serviceAppointment.docId || "");
                              }}
                            >
                              Cancel
                            </IonButton>
                          )}
                        {(mode !== "patient-list" ||
                          (mode === "patient-list" &&
                            !doctorAppointment.isCancelled)) && (
                          <IonButton
                            mode="ios"
                            fill="clear"
                            color={MBCOLORS.primary}
                            className={`mb-appointment-list-button ${
                              mode === "patient-list"
                                ? "confirm-arrival"
                                : "view"
                            } mb-h4 bold ion-no-padding ion-no-margin`}
                            onClick={() => {
                              onClickView(
                                serviceAppointment.docId || "",
                                false
                              );
                            }}
                          >
                            {mode !== "patient-list"
                              ? "View"
                              : "Confirm Arrival"}
                          </IonButton>
                        )}
                      </div>
                    </IonCol>,
                  ].map((col) => {
                    return (
                      <IonRow className="mb-appointment-list-row ion-no-argin ion-no-padding">
                        {col}
                      </IonRow>
                    );
                  })}
              </IonGrid>
            </IonCard>
          );
        })
      ) : (
        <div className="mb-appointment-list-no-appointments-container">
          <IonIcon className={`no-appointments ${mode}`} />
          <IonLabel className="no-appointments-label mb-h2">
            {mode !== "patient-list"
              ? "You dont'have appointments here"
              : "No Results"}
          </IonLabel>
          {mode !== "patient-list" && (
            <IonLabel className="no-appointments-sub-label mb-body">
              Try selecting different view or tab
            </IonLabel>
          )}
        </div>
      )}
    </div>
  );
};
