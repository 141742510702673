import * as _ from "lodash";
import * as React from "react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import { IonRouterOutlet, IonLoading } from "@ionic/react";

import { auth } from "../firebase";
import { AuthUser } from "../interface";

import * as capacitorStorage from "../functions/localStorageCapacitor";
import * as routes from "../constants/routes";
import * as services from "../services";
import AccountSetup from "../pages/AccountSetup/AccountSetup";
import ContactUs from "../pages/ContactUs/ContactUs";
import DataPrivacy from "../pages/DataPrivacy/DataPrivacy";
import DoctorAppointments from "../pages/DoctorAppointments/DoctorAppointments";
import DoctorInvitationLogin from "../pages/DoctorInvitationLogin/DoctorInvitationLogin";
import DoctorCreatePassword from "../pages/DoctorCreatePassword/DoctorCreatePassword";
import DoctorAccount from "../pages/DoctorAccount/DoctorAccount";
import ExternalAction from "../pages/ExternalAction/ExternalAction";
import FAQ from "../pages/FAQ/FAQ";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import HospitalAppointments from "../pages/HospitalAppointments/HospitalAppointments";
import HospitalDepartment from "../pages/HospitalDepartment/HospitalDepartment";
import HospitalAccountSetup from "../pages/HospitalAccountSetup/HospitalAccountSetup";
import HospitalRepAccount from "../pages/HospitalAccount/HospitalAccount";
import HospitalApplications from "../pages/HospitalApplications/HospitalApplications";
import HospitalApprovedApplications from "../pages/HospitalApprovedApplications/HospitalApprovedApplications";
import Login from "../pages/Login/Login";
import LoginOptions from "../pages/LoginOptions/LoginOptions";
import OngoingHospitalVerification from "../pages/OngoingHospitalVerification/OngoingHospitalVerification";
import PatientAppointments from "../pages/PatientAppointments/PatientAppointments";
import PatientAccount from "../pages/PatientAccount/PatientAccount";
// import PatientTransportation from "../pages/PatientTransportation/PatientTransportation";
import ResendEmailVerification from "../pages/ResendEmailVerification/ResendEmailVerification";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
// import TransportAccount from "../pages/TransportAccount/TransportAccount";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndCondition";
import VerifyEmail from "../pages/VerifyEmail/VerifyEmail";
// import SupportGroupRegistration from "../pages/SupportGroupRegistration/SupportGroupRegistration";
// import SupportGroupRegistrations from "../pages/SupportGroupRegistrations/SupportGroupRegistrations";
// import SupportGroupApprovedRegistrations from "../pages/SupportGroupApprovedRegistrations/SupportGroupApprovedRegistrations";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import { UnauthenticatedRoute } from "./UnauthenticatedRoute";
import { LOCAL_STORAGE } from "../config";
// import SupportGroups from "../pages/SupportGroups/SupportGroups";
// import Landing from "../pages/Landing/Landing";
import TechAdminDashboard from "../pages/TechAdminDashboard/TechAdminDashboard";
import HospitalHome from "../pages/HospitalHome/HospitalHome";
import HospitalDepartmentDetail from "../pages/HospitalDepartmentDetail/HospitalDepartmentDetail";
import PatientHome from "../pages/PatientHome/PatientHome";
import Checkout from "../pages/Checkout/Checkout";
import DoctorHome from "../pages/DoctorHome/DoctorHome";
import DoctorScan from "../pages/DoctorScan/DoctorScan";
import HospitalScan from "../pages/HospitalScan/HospitalScan";
import PaymentProcessingLanding from "../pages/PaymentProcessingLanding/PaymentProcessingLanding";
import TimeKeepingScan from "../pages/TimeKeepingScan/TimeKeepingScan";
import TimeKeepingPatientsList from "../pages/TimeKeepingPatientsList/TimeKeepingPatientsList";
import ExecutiveAssistantCreatePassword from "../pages/ExecutiveAssistantCreatePassword/ExecutiveAssistantCreatePassword";
import ExecutiveAssistantInvitationLogin from "../pages/ExecutiveAssistantInvitationLogin/ExecutiveAssistantInvitationLogin";
import ExecutiveAssistantDoctorsList from "../pages/ExecutiveAssistantDoctorsList/ExecutiveAssistantDoctorsList";
import ExecutiveAccount from "../pages/ExecutiveAccount/ExecutiveAccount";
import TimeKeepingAccount from "../pages/TimeKeepingAccount/TimeKeepingAccount";
import Landing from "../pages/Landing/Landing";
import HospitalPatientsList from "../pages/HospitalPatientsList/HospitalPatientsList";
import DoctorPatientsList from "../pages/DoctorPatientsList/DoctorPatientsList";
import MarketingFeedbacks from "../pages/MarketingFeedbacks/MarketingFeedbacks";
import Analytics from "../pages/Analytics/Analytics";

class RouterComponent extends React.Component<any, any> {
  state = {
    loading: true,
    authUser: {} as AuthUser,
    unsubscribeUser: () => {},
  };

  componentDidMount = () => {
    this.deployAuthStateListener();
  };

  deployAuthStateListener = () => {
    auth.onAuthStateChanged(async (authUser) => {
      if (!!authUser) {
        this.refreshToken();
        try {
          let authenticatedUser = { authEmail: authUser.email } as AuthUser;
          console.log("GOT AUTHENTICATED USRE!!!", authenticatedUser);

          services.getUserAccountType(authUser.uid, (accountType) => {
            const userType = accountType.userType;
            authenticatedUser.uid = authUser.uid;
            if (userType !== undefined) {
              authenticatedUser.userType = userType;

              const unsubscribeUser = services.getUserDetailsRealTime(
                authUser.uid,
                userType,
                (userDetails) => {
                  if (userDetails.docId === authUser.uid) {
                    // this will prevent data leak from accessing wrong data
                    if (!_.isEmpty(userDetails)) {
                      authenticatedUser = {
                        ...authenticatedUser,
                        userDetails,
                        emailVerified: authUser.emailVerified,
                      };
                    }

                    this.setState({
                      loading: false,
                      authUser: authenticatedUser,
                    });
                  } else {
                    this.setState({
                      loading: false,
                      authUser: authenticatedUser,
                    });
                  }
                }
              );
              this.setState({
                unsubscribeUser,
              });
            } else {
              this.setState({ loading: false, authUser: authenticatedUser });
              this.state.unsubscribeUser();
            }
          });
        } catch (e) {
          console.log("error getting auth user", e);
          this.setState({ loading: false, authUser: null });
          this.state.unsubscribeUser();
        }
      } else {
        console.log("no authUser");
        this.setState({ loading: false, authUser: null });
        this.state.unsubscribeUser();
      }
    });
  };

  refreshToken = async () => {
    if (!!auth.currentUser) {
      const idToken = await auth.currentUser.getIdToken(true);

      // if expired do something
      if (!idToken) {
        this.setState({
          authUser: null,
          loading: false,
        });
      } else {
        const currentToken = await capacitorStorage.getItem(
          LOCAL_STORAGE.fbIdToken
        );

        // refresh token if not updated
        if (currentToken !== idToken) {
          await capacitorStorage.setItem(LOCAL_STORAGE.fbIdToken, idToken);
          await capacitorStorage.setItem(
            LOCAL_STORAGE.userId,
            auth.currentUser.uid
          );
        }
      }
    }
  };

  //TODO: check auth, integrate the authed and unauthed routes

  render = () => {
    const { authUser, loading } = this.state;

    return (
      <IonReactRouter>
        {!loading && (
          <IonRouterOutlet>
            {/* ! uncomment if done with demo */}
            <UnauthenticatedRoute
              path={routes.LOGIN_OPTIONS}
              component={LoginOptions}
              exact={true}
              authUser={authUser}
            />
            <UnauthenticatedRoute
              path={routes.LOGIN_WITH_EMAIL}
              component={Login}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.ACCOUNT_SETUP}
              component={AccountSetup}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.HOSPITAL_ACCOUNT_SETUP}
              component={HospitalAccountSetup}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.HOSPITAL_DEPARTMENT_DETAIL}
              component={HospitalDepartmentDetail}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.DOCTOR_CREATE_PASSWORD}
              component={DoctorCreatePassword}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.EXECUTIVE_ASSISTANT_CREATE_PASSWORD}
              component={ExecutiveAssistantCreatePassword}
              exact={true}
              authUser={authUser}
            />
            <UnauthenticatedRoute
              path={routes.CREATE_WITH_EMAIL_ACCOUNT_SETUP}
              component={AccountSetup}
              exact={true}
              authUser={authUser}
            />
            <UnauthenticatedRoute
              path={routes.CREATE_WITH_EMAIL_ACCOUNT_SETUP_WITH_INVITE}
              component={AccountSetup}
              exact={true}
              authUser={authUser}
            />
            <UnauthenticatedRoute
              path={routes.DOCTOR_INVITATION}
              component={DoctorInvitationLogin}
              exact={true}
              authUser={authUser}
            />
            <UnauthenticatedRoute
              path={routes.EXECUTIVE_ASSISTANT_INVITATION}
              component={ExecutiveAssistantInvitationLogin}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.PATIENT_HOME}
              component={PatientHome}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.PATIENT_EDIT_BOOKLISTITEM}
              component={PatientHome}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.PATIENT_CHECKOUT}
              component={Checkout}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.PATIENT_APPOINTMENTS}
              component={PatientAppointments}
              exact={true}
              authUser={authUser}
            />
            {/* <AuthenticatedRoute
              path={routes.PATIENT_TRANSPORT}
              component={PatientTransportation}
              exact={true}
              authUser={authUser}
            /> */}

            {/* <AuthenticatedRoute
              path={routes.SUPPORT_GROUPS}
              component={SupportGroups}
              exact={true}
              authUser={authUser}
            /> */}

            <AuthenticatedRoute
              path={routes.PATIENT_ACCOUNT}
              component={PatientAccount}
              exact={true}
              authUser={authUser}
            />
            {/* <AuthenticatedRoute
              path={routes.TRANSPORT_ACCOUNT}
              component={TransportAccount}
              exact={true}
              authUser={authUser}
            /> */}
            <AuthenticatedRoute
              path={routes.HOSPITAL_REP_ACCOUNT}
              component={HospitalRepAccount}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.DOCTOR_ACCOUNT}
              component={DoctorAccount}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.DOCTOR_HOME}
              component={DoctorHome}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.DOCTOR_APPOINTMENTS}
              component={DoctorAppointments}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.DOCTOR_APPOINTMENT_DETAIL}
              component={DoctorAppointments}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.DOCTOR_SCAN}
              component={DoctorScan}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.DOCTOR_SEARCH_PATIENTS}
              component={DoctorPatientsList}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.EXECUTIVE_ASSISTANT_APPOINTMENTS}
              component={DoctorAppointments}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.EXECUTIVE_ASSISTANT_DOCTOR_LIST}
              component={ExecutiveAssistantDoctorsList}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.EXECUTIVE_ASSISTANT_HOME}
              component={DoctorHome}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.EXECUTIVE_ASSISTANT_SCAN}
              component={DoctorScan}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.EXECUTIVE_ASSISTANT_ACCOUNT}
              component={ExecutiveAccount}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.HOSPITAL_APPOINTMENTS}
              component={HospitalAppointments}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.HOSPITAL_APPOINTMENT_DETAIL}
              component={HospitalAppointments}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.HOSPITAL_SCAN}
              component={HospitalScan}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.HOSPITAL_ANALYTICS}
              component={Analytics}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.HOSPITAL_SEARCH_PATIENTS}
              component={HospitalPatientsList}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.HOSPITAL_APPLICATIONS}
              component={HospitalApplications}
              exact={true}
              authUser={authUser}
            />
            {/* <AuthenticatedRoute
              path={routes.SUPPORT_GROUP_REGISTRATIONS}
              component={SupportGroupRegistrations}
              exact={true}
              authUser={authUser}
            /> */}
            <AuthenticatedRoute
              path={routes.HOSPITAL_APPROVED_APPLICATIONS}
              component={HospitalApprovedApplications}
              exact={true}
              authUser={authUser}
            />
            {/* <AuthenticatedRoute
              path={routes.SUPPORT_GROUP_APPROVED_REGISTRATONS}
              component={SupportGroupApprovedRegistrations}
              exact={true}
              authUser={authUser}
            /> */}
            <AuthenticatedRoute
              path={routes.HOSPITAL_HOME}
              component={HospitalHome}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.HOSPITAL_DEPARTMENT}
              component={HospitalDepartment}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.TIME_KEEPING_SCAN}
              component={TimeKeepingScan}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.TIME_KEEPING_SEARCH_PATIENTS}
              component={TimeKeepingPatientsList}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.TIME_KEEPING_ACCOUNT}
              component={TimeKeepingAccount}
              exact={true}
              authUser={authUser}
            />

            <AuthenticatedRoute
              path={routes.RESEND_EMAIL_VERIFICATION}
              component={ResendEmailVerification}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.ONGOING_HOSPITAL_VERIFICATION}
              component={OngoingHospitalVerification}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.TECH_ADMIN}
              component={TechAdminDashboard}
              exact={true}
              authUser={authUser}
            />
            <AuthenticatedRoute
              path={routes.PAYMENT_PROCESSING_FAILED_LANDING}
              component={PaymentProcessingLanding}
              exact={true}
              authUser={authUser}
            />
            {/* <UnauthenticatedRoute
              path={routes.SUPPORT_GROUP_REGISTRATION}
              component={SupportGroupRegistration}
              exact={true}
              authUser={authUser}
            /> */}
            <Route
              exact
              path={routes.EXTERNAL_ACCOUNT_ACTION}
              component={ExternalAction}
            />
            <UnauthenticatedRoute
              path={routes.LANDING}
              component={Landing}
              exact={true}
              authUser={authUser}
            />
            <UnauthenticatedRoute
              path={routes.LANDING_PRE_REGISTER}
              component={Landing}
              exact={true}
              authUser={authUser}
            />

            <UnauthenticatedRoute
              path={routes.MARKETING_FEEDBACK}
              component={MarketingFeedbacks}
              exact={true}
              authUser={authUser}
            />
            {/* <Route
              exact
              path="/"
              render={() => <Redirect to={routes.LANDING} />}
            /> */}

            <Route
              exact
              path={routes.PAYMENT_PROCESSING_LANDING}
              component={PaymentProcessingLanding}
            />
            <Route
              exact
              path={routes.TERMS_CONDITION}
              component={TermsAndConditions}
            />
            <Route exact path={routes.DATA_PRIVACY} component={DataPrivacy} />

            <UnauthenticatedRoute
              path={routes.FAQ}
              component={FAQ}
              exact={true}
              authUser={authUser}
            />
            <UnauthenticatedRoute
              path={routes.CONTACT_US}
              component={ContactUs}
              exact={true}
              authUser={authUser}
            />
            <UnauthenticatedRoute
              path={routes.VERIFY_EMAIL}
              component={VerifyEmail}
              exact={true}
              authUser={authUser}
            />
            <Route
              path={routes.FORGOT_PASSWORD}
              component={ForgotPassword}
              exact={true}
            />
            <Route
              path={routes.RESET_PASSWORD}
              component={ResetPassword}
              exact={true}
            />
          </IonRouterOutlet>
        )}

        <IonLoading
          translucent={true}
          isOpen={loading}
          mode="ios"
          message={"Loading ..."}
        />
      </IonReactRouter>
    );
  };
}

export default RouterComponent;
