import * as _ from "lodash";
import * as React from "react";
import * as routes from "../constants/routes";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";

import { AuthUser } from "../interface";
import { Doctor, ExecutiveAssistant } from "../models";
import { USER_TYPES } from "../constants/config";

interface UnauthenticatedRouteProps {
  component: any;
  path: string;
  exact: boolean;
  authUser: AuthUser;
}
export const UnauthenticatedRoute = (props: UnauthenticatedRouteProps) => {
  const { exact, path, authUser, component } = props;
  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        return getRender(authUser, path, props, component);
      }}
    />
  );
};

const getRender = (authUser: AuthUser, path: string, props: any, C: any) => {
  // const authUser = RouteRef.state.authUser as AuthUser;
  console.log("GOT AUTH USER", authUser);

  if (!_.isEmpty(authUser)) {
    // if (authUser.role) //todo: check role here
    if (
      authUser.userType === undefined &&
      [routes.FAQ, routes.CONTACT_US].indexOf(path) === -1
    ) {
      if (
        [
          routes.CREATE_WITH_EMAIL_ACCOUNT_SETUP,
          routes.CREATE_WITH_EMAIL_ACCOUNT_SETUP_WITH_INVITE,
        ].indexOf(path) !== -1
      ) {
        return <C {...props} {...{ authUser: authUser }} />;
      } else {
        return <Redirect to={routes.ACCOUNT_SETUP} />;
      }
    } else if ([routes.FAQ, routes.CONTACT_US].indexOf(path) !== -1) {
      return <C {...props} {...{ authUser: authUser }} />;
    } else if (authUser.userType === USER_TYPES.patients.id) {
      if (path === routes.CREATE_WITH_EMAIL_ACCOUNT_SETUP) {
        return <C {...props} {...{ authUser: authUser }} />;
      } else {
        return <Redirect to={routes.PATIENT_HOME} />;
      }
    } else if (authUser.userType === USER_TYPES.hospitals.id) {
      if (
        [
          routes.CREATE_WITH_EMAIL_ACCOUNT_SETUP,
          routes.CREATE_WITH_EMAIL_ACCOUNT_SETUP_WITH_INVITE,
        ].indexOf(path) !== -1
      ) {
        return <C {...props} {...{ authUser: authUser }} />;
      } else {
        return <Redirect to={routes.HOSPITAL_HOME} />;
      }
    } else if (authUser.userType === USER_TYPES.doctors.id) {
      const doctorDetail = authUser.userDetails as Doctor;
      if (!!doctorDetail && _.isEmpty(doctorDetail.initialPasswordCipher)) {
        return <Redirect to={routes.DOCTOR_HOME} />;
      } else {
        return <Redirect to={routes.DOCTOR_CREATE_PASSWORD} />;
      }
    } else if (authUser.userType === USER_TYPES.executiveAssistant.id) {
      const executiveAssistantDetail = authUser.userDetails as ExecutiveAssistant;
      if (
        !!executiveAssistantDetail &&
        _.isEmpty(executiveAssistantDetail.initialPasswordCipher)
      ) {
        if (executiveAssistantDetail.initialSetupDone) {
          return <Redirect to={routes.EXECUTIVE_ASSISTANT_HOME} />;
        } else {
          return <Redirect to={routes.EXECUTIVE_ASSISTANT_ACCOUNT} />;
        }
      } else {
        return <Redirect to={routes.EXECUTIVE_ASSISTANT_CREATE_PASSWORD} />;
      }
    } else if (authUser.userType === USER_TYPES.support.id) {
      return <Redirect to={routes.HOSPITAL_APPLICATIONS} />;
    } else if (authUser.userType === USER_TYPES.techAdmin.id) {
      return <Redirect to={routes.TECH_ADMIN} />;
    } else if (authUser.userType === USER_TYPES.timeKeeping.id) {
      return <Redirect to={routes.TIME_KEEPING_SCAN} />;
    } else if (authUser.userType === USER_TYPES.hospitalAdmin.id) {
      return <Redirect to={routes.HOSPITAL_ANALYTICS} />;
    }
  }

  return <C {...props} {...{ authUser: authUser }} />;
};
