import * as _ from "lodash";
import moment from "moment";
import React from "react";
import Calendar from "react-calendar";
import {
  caretBack,
  caretForward,
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import { IonIcon, IonPopover } from "@ionic/react";

import "./MBMiniCalendar.scss";

interface MBMiniCalendarProps {
  calendarAnchor: null | undefined | Event;
  selectedDate: Date;
  removeCalendarAnchor: () => void;
  onDateChange: (date: Date) => void;
  maxDate?: Date;
  minDate?: Date;
}
export const MBMiniCalendar = (props: MBMiniCalendarProps) => {
  const {
    calendarAnchor,
    removeCalendarAnchor,
    selectedDate,
    onDateChange,
    maxDate,
    minDate,
  } = props;

  return (
    <IonPopover
      isOpen={!_.isEmpty(calendarAnchor)}
      event={!_.isNull(calendarAnchor) ? calendarAnchor : undefined}
      onDidDismiss={() => removeCalendarAnchor()}
      showBackdrop={false}
    >
      <div className="mb-mini-calendar-container">
        <Calendar
          className="mb-mini-custom-calendar"
          value={selectedDate}
          onChange={(date: Date | Date[]) => {
            onDateChange(date as Date);
            removeCalendarAnchor();
          }}
          calendarType="US"
          minDetail="year"
          maxDate={maxDate}
          minDate={minDate}
          prevLabel={<IonIcon mode="md" icon={caretBack} />}
          nextLabel={<IonIcon mode="md" icon={caretForward} />}
          prev2Label={<IonIcon mode="md" icon={chevronBackOutline} />}
          next2Label={<IonIcon mode="md" icon={chevronForwardOutline} />}
          formatMonthYear={(local: string, date: Date) => {
            const monthYear = moment(date).format("MMM YYYY");
            return monthYear;
          }}
          formatShortWeekday={(local: string, date: Date) => {
            const weekday = moment(date).format("ddd")[0];
            return weekday;
          }}
          tileClassName="mb-mini-profile-calendar-tile"
        />
      </div>
    </IonPopover>
  );
};
