import { ENVIRONMENTS } from "../config/index";

export const getEnv = () => {
  var env;
  if (process.env.REACT_APP_APPENV === ENVIRONMENTS.production) {
    env = require("./env.production.json");
  } else if (process.env.REACT_APP_APPENV === ENVIRONMENTS.local) {
    env = require("./env.local.json");
  } else if (process.env.REACT_APP_APPENV === ENVIRONMENTS.training) {
    env = require("./env.training.json");
  } else if (process.env.REACT_APP_APPENV === ENVIRONMENTS.demo) {
    env = require("./env.demo.json");
  } else {
    env = require("./env.development.json");
  }
  return env;
};
