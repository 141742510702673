import * as _ from "lodash";
import { Helmet } from "react-helmet";
import React from "react";
import {
  IonLoading,
  IonButton,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
} from "@ionic/react";

import "./TechAdminDashboard.scss";
import * as services from "../../services";
import { MBCard } from "../../components/MBCard/MBCard";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import { MBCOLORS } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";

class TechAdminDashboard extends React.Component<MBProps> {
  state = {
    patientCount: 0,
    hospitalCount: 0,
    doctorCount: 0,
    servicesCount: 0,
    appointmentCount: 0,
    loading: false,
    error: "",
  };

  componentDidMount = () => {
    this.getPatientCount();
    this.getHospitalCount();
    this.getDoctorCount();
    this.getServicesCount();
    this.getAppointmentCount();
  };

  downloadAllPatientsDetails = async () => {
    this.setState({
      loading: true,
    });
    try {
      const result = await services.downloadAllPatientsDetails();
      if (result) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: "Nothing to download..." });
      }
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  downloadAllDoctorDetails = async () => {
    this.setState({
      loading: true,
    });
    try {
      const result = await services.downloadAllDoctorDetails();
      if (result) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: "Nothing to download..." });
      }
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  downloadAllServices = async () => {
    this.setState({
      loading: true,
    });
    try {
      const result = await services.downloadAllServices();
      if (result) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: "Nothing to download..." });
      }
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  // downloadAllHospitalRepDetails = async () => {
  //   this.setState({
  //     loading: true,
  //   });
  //   try {
  //     const result = await services.downloadAllHospitalRepDetails();
  //     if (result) {
  //       this.setState({
  //         loading: false,
  //       });
  //     } else {
  //       this.setState({ loading: false, error: "Nothing to download..." });
  //     }
  //   } catch (e) {
  //     this.setState({
  //       error: e,
  //       loading: false,
  //     });
  //   }
  // };

  downloadAllHospitalDetails = async () => {
    this.setState({
      loading: true,
    });
    try {
      const result = await services.downloadAllHospitalDetails();
      if (result) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: "Nothing to download..." });
      }
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  downloadAllHospitalDoctorDetails = async () => {
    this.setState({
      loading: true,
    });
    try {
      const result = await services.downloadAllHospitalDoctorDetails();
      if (result) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: "Nothing to download..." });
      }
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  downloadAllHospitalServiceDetails = async () => {
    this.setState({
      loading: true,
    });
    try {
      const result = await services.downloadAllHospitalServiceDetails();
      if (result) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: "Nothing to download..." });
      }
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  downloadAllDoctorSchedules = async () => {
    this.setState({
      loading: true,
    });
    try {
      const result = await services.downloadAllDoctorSchedules();
      if (result) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: "Nothing to download..." });
      }
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  downloadAllServiceSchedules = async () => {
    this.setState({
      loading: true,
    });
    try {
      const result = await services.downloadAllServiceSchedules();
      if (result) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: "Nothing to download..." });
      }
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  downloadAllAppointments = async () => {
    this.setState({
      loading: true,
    });
    try {
      const result = await services.downloadAllAppointments();
      if (result) {
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: false, error: "Nothing to download..." });
      }
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  downloadEverything = async () => {
    this.setState({
      loading: true,
    });
    try {
      await services.downloadAllPatientsDetails();
      await services.downloadAllDoctorDetails();
      await services.downloadAllServices();
      // await services.downloadAllHospitalRepDetails();
      await services.downloadAllHospitalDetails();
      await services.downloadAllHospitalDoctorDetails();
      await services.downloadAllHospitalServiceDetails();
      await services.downloadAllDoctorSchedules();
      await services.downloadAllServiceSchedules();
      await services.downloadAllAppointments();
      this.setState({
        loading: false,
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  getPatientCount = () => {
    services.getPatientCount((count) => {
      this.setState({
        patientCount: count,
      });
    });
  };
  getHospitalCount = () => {
    services.getHospitalCount((count) => {
      this.setState({
        hospitalCount: count,
      });
    });
  };
  getDoctorCount = () => {
    services.getDoctorCount((count) => {
      this.setState({
        doctorCount: count,
      });
    });
  };
  getServicesCount = () => {
    services.getServicesCount((count) => {
      this.setState({
        servicesCount: count,
      });
    });
  };
  getAppointmentCount = () => {
    services.getAppointmentCount((count) => {
      this.setState({
        appointmentCount: count,
      });
    });
  };

  render = () => {
    const {
      patientCount,
      hospitalCount,
      doctorCount,
      servicesCount,
      appointmentCount,
      loading,
      error,
    } = this.state;
    return (
      <>
        <Helmet>
          <title>MedBook - Tech Admin</title>
        </Helmet>
        <MBContainer {...this.props} activePage="tech-admin-dashboard">
          <MBCard
            onBack={() => {}}
            onSubmit={() => {}}
            hideActionButton={true}
            title="Download Data"
            mode="tech-admin"
          >
            <div className="tech-admin-download-options ">
              <IonGrid className="tech-admin-grid-container">
                <IonRow>
                  <IonCol size="2.4">
                    <IonLabel className="mb-h2">Patients</IonLabel>
                  </IonCol>
                  <IonCol size="2.4">
                    <IonLabel className="mb-h2">Hospitals</IonLabel>
                  </IonCol>
                  <IonCol size="2.4">
                    <IonLabel className="mb-h2">Doctors</IonLabel>
                  </IonCol>
                  <IonCol size="2.4">
                    <IonLabel className="mb-h2">Services</IonLabel>
                  </IonCol>
                  <IonCol size="2.4">
                    <IonLabel className="mb-h2">Appointments</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="2.4">
                    <IonLabel className="mb-h1">{patientCount}</IonLabel>
                  </IonCol>
                  <IonCol size="2.4">
                    <IonLabel className="mb-h1">{hospitalCount}</IonLabel>
                  </IonCol>
                  <IonCol size="2.4">
                    <IonLabel className="mb-h1">{doctorCount}</IonLabel>
                  </IonCol>
                  <IonCol size="2.4">
                    <IonLabel className="mb-h1">{servicesCount}</IonLabel>
                  </IonCol>
                  <IonCol size="2.4">
                    <IonLabel className="mb-h1">{appointmentCount}</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-margin-top">
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllPatientsDetails}
                    >
                      Patients
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllDoctorDetails}
                    >
                      Doctors
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllServices}
                    >
                      Services
                    </IonButton>
                  </IonCol>
                </IonRow>
                {/* <IonRow>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllHospitalRepDetails}
                    >
                      Hospital Representatives
                    </IonButton>
                  </IonCol>
                   <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllTransportDetails}
                    >
                      Transport
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllSupportGroupDetails}
                    >
                      Support Groups
                    </IonButton>
                  </IonCol>
                </IonRow> */}
                <IonRow>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllHospitalDetails}
                    >
                      Hospitals
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllHospitalDoctorDetails}
                    >
                      Hospitals Doctors
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllHospitalServiceDetails}
                    >
                      Hospitals Services
                    </IonButton>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllDoctorSchedules}
                    >
                      Doctor Schedules
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllServiceSchedules}
                    >
                      Service Schedules
                    </IonButton>
                  </IonCol>
                  <IonCol size="4">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadAllAppointments}
                    >
                      All Appointments
                    </IonButton>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol size="12">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={this.downloadEverything}
                      className="mb-h2 bold white"
                    >
                      Download All
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </MBCard>

          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />

          <IonToast
            isOpen={!_.isEmpty(error)}
            message={error}
            duration={2000}
            onDidDismiss={() => {
              this.setState({
                error: "",
              });
            }}
            color={MBCOLORS.danger}
          />
        </MBContainer>
      </>
    );
  };
}

export default TechAdminDashboard;
