/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonModal,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
} from "@ionic/react";

import "./TimeKeepingForm.scss";
import * as api from "../../api";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import {
  formatString,
  isMobile,
  encryptPassword,
} from "../../functions/common";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBCOLORS, LIMITS, USER_TYPES } from "../../constants/config";
import { MSGS_COMMON, MSGS_SIGNUP } from "../../constants/messages";
import { Hospital, TimeKeeping } from "../../models";

interface TimeKeepingFormProps extends MBProps {
  onCancel: () => void;
  isOpen: boolean;
  isEdit?: boolean;
  editData?: TimeKeeping;
  hospitalId: string;
  onBack: () => void;
  onRefreshData?: () => void;
}

export const TimeKeepingForm = (props: TimeKeepingFormProps) => {
  const {
    hospitalId,
    onBack,
    isOpen,
    onRefreshData,
    isEdit,
    authUser,
    editData,
  } = props;

  const isTimeKeeping = authUser.userType === USER_TYPES.timeKeeping.id;
  const timeKeepingDetails = (isTimeKeeping
    ? authUser.userDetails
    : editData || {}) as TimeKeeping;

  const editMode = isEdit || isTimeKeeping;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [firstName, setFirstName] = useState(
    editMode ? timeKeepingDetails!.firstName : ""
  );
  const [lastName, setLastName] = useState(
    editMode ? timeKeepingDetails!.lastName : ""
  );
  const [designation, setDesignation] = useState(
    editMode ? timeKeepingDetails!.designation : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    editMode ? timeKeepingDetails!.phoneNumber : ""
  );
  const [userEmail, setUserEmail] = useState(
    editMode ? timeKeepingDetails!.userEmail : ""
  );
  const [password, setPassword] = useState("");

  const [isOpenSendChangePassword, setIsOpenSendChangePassword] = useState(
    false
  );

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgDesignation, setErrorMsgDesignation] = useState("");
  const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("");
  const [errorMsgUserName, setErrorMsgUserEmail] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");

  const onSubmit = async () => {
    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedPhoneNumber = formatString((phoneNumber || "") as string);
    const formattedDesignation = formatString(designation || "");
    const formattedUserEmail = formatString(userEmail || "");
    const formattedPassword = formatString(password || "");

    let errorMessage = null;
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_SIGNUP.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.firstName.max;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_SIGNUP.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedDesignation.length === 0) {
      errorMessage = MSGS_SIGNUP.designation;
      setErrorMsgDesignation(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedPhoneNumber.length === 0) {
      errorMessage = MSGS_SIGNUP.mobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (!validatePhoneNumber(formattedPhoneNumber)) {
      errorMessage = MSGS_SIGNUP.invalidMobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (formattedUserEmail.length === 0) {
      errorMessage = MSGS_SIGNUP.userName;
      setErrorMsgUserEmail(errorMessage);
    }
    if (!EmailValidator.validate(formattedUserEmail)) {
      errorMessage = MSGS_SIGNUP.email.invalid;
      setErrorMsgUserEmail(errorMessage);
    }
    if (formattedPassword.length === 0 && !editMode) {
      errorMessage = MSGS_SIGNUP.timeKeepingPassword;
      setErrorMsgPassword(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      try {
        setLoading(true);

        if (!editMode) {
          const encryptedPassword = encryptPassword(
            hospitalId,
            formattedPassword
          );

          const hospitalUserDetails = authUser.userDetails as Hospital;
          await api.createSecurityTimeKeeping(
            hospitalId,
            `${hospitalUserDetails.firstName} ${hospitalUserDetails.lastName}`,
            hospitalUserDetails.hospitalName,
            formattedFirstName,
            formattedLastName,
            formattedPhoneNumber,
            formattedDesignation,
            formattedUserEmail,
            encryptedPassword
          );
          setSuccess("New Time Keeping has been added");
        } else {
          const updatedTimeKeepingDetail = {
            firstName: formattedFirstName,
            lastName: formattedLastName,
            phoneNumber: formattedPhoneNumber,
            designation: formattedDesignation,
          } as Partial<TimeKeeping>;
          await services.updateHospitalTimeKeeping(
            timeKeepingDetails.docId || "",
            updatedTimeKeepingDetail
          );

          setSuccess("Update success");
        }

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
  };

  const onChangePassword = async () => {
    setIsOpenSendChangePassword(true);
    try {
      await services.doSendResetPasswordEmail(userEmail);
    } catch (error) {
      console.log("Error -- doSendResetPasswordEmail -- ", error);
    }
  };

  return (
    <>
      <IonModal
        cssClass="time-keeping-modal"
        isOpen={isOpen}
        onDidDismiss={onBack}
      >
        <div className="time-keeping-setup-form-container">
          <IonLabel className="time-keeping-header mb-h2 dark-blue ion-text-start">
            {`${editMode ? "Edit" : "Add"} ${
              isTimeKeeping ? "Account Details" : "Time Keeping"
            }`}
          </IonLabel>
          <MBDivider />
          <div className="time-keeping-setup-form-content-container">
            <IonGrid className="time-keeping-setup-form-container-grid ion-no-padding">
              <IonRow>
                <IonCol
                  className={`time-keeping-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="First Name"
                    value={firstName}
                    type="text"
                    error={errorMsgFirstName}
                    onChange={(FirstName) => {
                      setFirstName(FirstName);
                      setErrorMsgFirstName("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`time-keeping-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="Last Name"
                    value={lastName}
                    type="text"
                    error={errorMsgLastName}
                    onChange={(lastName) => {
                      setLastName(lastName);
                      setErrorMsgLastName("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`time-keeping-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="Designation"
                    value={designation}
                    type="text"
                    error={errorMsgDesignation}
                    onChange={(designation) => {
                      setDesignation(designation);
                      setErrorMsgDesignation("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`time-keeping-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="Mobile Number"
                    value={phoneNumber}
                    type="tel"
                    error={errorMsgPhoneNumber}
                    onChange={(phoneNumber) => {
                      setPhoneNumber(phoneNumber);
                      setErrorMsgPhoneNumber("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`time-keeping-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="User Email"
                    value={userEmail}
                    type="email"
                    readonly={editMode}
                    error={errorMsgUserName}
                    onChange={(userName) => {
                      setUserEmail(userName);
                      setErrorMsgUserEmail("");
                    }}
                  />
                </IonCol>
              </IonRow>
              {(!editMode || (editMode && isTimeKeeping)) && (
                <IonRow>
                  <IonCol
                    className={`time-keeping-setup-form-container-col ${
                      isMobile() && "mobile"
                    } ion-no-padding`}
                    size="12"
                  >
                    {!isTimeKeeping ? (
                      <MBInput
                        type="password"
                        label="Password"
                        value={password}
                        onChange={(password: string) => {
                          setPassword(password);
                          setErrorMsgPassword("");
                        }}
                        error={errorMsgPassword}
                      />
                    ) : (
                      <IonItem
                        lines="none"
                        className="time-keeping-form-password-item ion-no-padding ion-no-margin"
                        color={MBCOLORS.tertiary}
                      >
                        <IonLabel
                          position="stacked"
                          className="mb-paragraph bold ion-no-padding ion-no-margin"
                        >
                          Password
                        </IonLabel>
                        <IonInput
                          type="password"
                          className="time-keeping-form-password-input ion-no-padding ion-no-margin"
                          autocomplete="off"
                          value="password" //fake password changing password will send an Email
                          readonly={true}
                        >
                          <IonButton
                            color={MBCOLORS.primary}
                            className="time-keeping-form-change-password-button sv-paragraph ion-no-margin ion-text-capitalize"
                            onClick={onChangePassword}
                          >
                            Edit Password
                          </IonButton>
                        </IonInput>
                      </IonItem>
                    )}
                  </IonCol>
                </IonRow>
              )}
            </IonGrid>
          </div>
        </div>
        {!editMode && (
          <div className="time-keeping-footer-note-container">
            <IonIcon className="info-icon" />
            <IonLabel className="time-keeping-info-note mb-body blue">
              We will send the username and password to this employee upon
              creation of the account in order for them to login.
            </IonLabel>
          </div>
        )}
        <MBDivider />
        <div className="time-keeping-setup-button-container">
          <IonButton
            onClick={() => {
              onBack();
            }}
            className="time-keeping-setup-back-button mb-body ion-text-capitalize"
            fill="clear"
            color={MBCOLORS.primary}
          >
            Cancel
          </IonButton>
          <IonButton
            onClick={() => {
              onSubmit();
            }}
            className="time-keeping-setup-save-button mb-body bold white ion-text-capitalize"
            color={MBCOLORS.primary}
          >
            Save
          </IonButton>
        </div>
      </IonModal>
      <MBDialog
        isOpen={!_.isEmpty(success)}
        icon="time-keeping"
        title={success}
        buttonLabel="Okay"
        message={
          !editMode
            ? `${firstName} ${lastName} is now added to the system. They can now have an access to tag a patient’s arrival. We also sent the username and password to the mobile number provided in order for the user to login.`
            : "New details will now be reflected in the system"
        }
        onDidDismiss={() => {
          if (!!onRefreshData) {
            onRefreshData();
          }
          if (!!onBack) {
            onBack();
          }
          setSuccess("");
        }}
      />
      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />

      <MBDialog
        isOpen={isOpenSendChangePassword}
        title="Email Sent"
        message={`We have sent you an email to ${userEmail} Click the link to change your account password.`}
        icon="success"
        onDidDismiss={() => {
          setIsOpenSendChangePassword(false);
        }}
      />
    </>
  );
};
