import * as _ from "lodash";
import moment from "moment";
import React from "react";
import QRCode from "qrcode.react";
import { calendarOutline, time } from "ionicons/icons";
import { IonIcon, IonButton, IonModal, IonLabel } from "@ionic/react";

import "./MBQRresultDialog.scss";
import { MBDialog } from "../MBDialog/MBDialog";
import { daysSinceDate, isMobile } from "../../functions/common";
import { MBCOLORS } from "../../constants/config";
import {
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
} from "../../models";

interface MBQRresultDialogProps {
  mode: "doctor" | "hospital" | "security";
  isOpen: boolean;
  onDidDismiss: () => void;
  onDidDismissSuccessConfirm: () => void;
  onConfirmArrival: (appointmentId: string) => void;
  successConfirmArrival: boolean;
  qrData: HospitalDoctorAppointmentsView | HospitalServiceAppointmentsView;
}

export const MBQRresultDialog = (props: MBQRresultDialogProps) => {
  const {
    mode,
    isOpen,
    onDidDismiss,
    qrData,
    onConfirmArrival,
    successConfirmArrival,
    onDidDismissSuccessConfirm,
  } = props;

  let serviceAppointment = {} as HospitalServiceAppointmentsView;
  let doctorAppointment = {} as HospitalDoctorAppointmentsView;
  let isDoctorAppointment = false;
  let doneWithCovidCheck = false;
  let isCovidRisk = false;

  if (!_.isEmpty(qrData)) {
    serviceAppointment = qrData as HospitalServiceAppointmentsView;
    doctorAppointment = qrData as HospitalDoctorAppointmentsView;
    isDoctorAppointment = !_.isEmpty(doctorAppointment.doctorDetails);

    doneWithCovidCheck =
      !!qrData.patientDetails.lastCovidFree &&
      daysSinceDate(qrData.patientDetails.lastCovidFree.toDate()) <= 1;
    isCovidRisk =
      !!doctorAppointment.patientDetails.isCovidPositive && doneWithCovidCheck;
  }
  return (
    <>
      <IonModal
        cssClass="mb-qr-result-dialog"
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
      >
        <div className="modal-content">
          <div className="mb-qr-result-header-container">
            <IonLabel className="mb-qr-result-header-label mb-h2 dark-blue ion-no-padding ion-no-margin">
              QR Scan Result
            </IonLabel>
          </div>
          <div className="mb-qr-result-dialog-children-container">
            {!_.isEmpty(qrData) ? (
              <div className="mb-qr-result-with-result-container">
                <div
                  className={`mb-result-item-components-container ${
                    isMobile() && "mobile"
                  }`}
                >
                  <div className="qr-result-icon-status-container">
                    <IonIcon className="patient-icon" />
                    <IonLabel className="result-status today ion-no-padding ion-no-margin">
                      TODAY
                    </IonLabel>
                  </div>
                  <IonLabel className="with-result-patient-name mb-h4 normal dark-blue ion-no-padding ion-no-margin">
                    <b>{`${qrData.patientDetails.firstName} ${qrData.patientDetails.lastName}`}</b>{" "}
                    (
                    {isDoctorAppointment
                      ? "Consultation"
                      : serviceAppointment.hospitalServiceDetails.treatmentName}
                    )
                  </IonLabel>
                  <div className="with-result-hospital-container">
                    <IonIcon className="with-result-hospital-icon" />
                    <IonLabel className="with-result-hospital mb-body ion-no-padding ion-no-margin">
                      {qrData.hospitalDetails.hospitalName}
                      <small>
                        {isDoctorAppointment
                          ? " • Consultation"
                          : " • Out-Patient-Service"}
                      </small>
                    </IonLabel>
                  </div>
                  <div className="with-result-doctor-container">
                    <IonIcon
                      className={`${
                        isDoctorAppointment
                          ? "with-result-doctor-icon"
                          : "with-result-hospital-icon"
                      }`}
                    />
                    <IonLabel className="with-result-doctor mb-body ion-no-padding ion-no-margin">
                      {/* //todo: change the doctor details here once appointment model is okay */}
                      {isDoctorAppointment
                        ? `${doctorAppointment.doctorDetails.firstName} ${doctorAppointment.doctorDetails.lastName} `
                        : serviceAppointment.hospitalServiceDetails.location}
                    </IonLabel>
                    {!!doctorAppointment.doctorHospitalDetails && (
                      <IonLabel className="mb-h6 ion-no-padding ion-no-margin">
                        {doctorAppointment.doctorHospitalDetails.specialities
                          .map((speciality) => speciality.name)
                          .join(", ")}
                      </IonLabel>
                    )}
                  </div>
                  <div
                    className={`with-result-schedule-container ${
                      isMobile() && "mobile"
                    }`}
                  >
                    <div
                      className={`with-result-date-container ${
                        isMobile() && "mobile"
                      }`}
                    >
                      <IonIcon
                        className="schedule-icon"
                        icon={calendarOutline}
                      />
                      <IonLabel className="with-result-date-label mb-body">
                        {moment(qrData.appointmentDate).format("MMMM DD, YYYY")}
                      </IonLabel>
                    </div>
                    <div className="with-result-time-container">
                      <IonIcon className="schedule-icon" icon={time} />
                      <IonLabel className="with-result-time-label mb-body">
                        {/* //todo: once the function for checking time is okay replace the Est. Time */}
                        Est. Time{" "}
                        {moment(qrData.appointmentDate).format("HH:mm A")} -
                        Slot {qrData.slotNumber}
                      </IonLabel>
                    </div>
                  </div>
                  <div className="with-result-covid-container">
                    <IonIcon className="with-result-covid-icon" />
                    <IonLabel
                      className={`with-result-covid-label mb-body bold ${
                        doneWithCovidCheck ? "success" : "danger"
                      } ion-no-padding ion-no-margin`}
                    >
                      {doneWithCovidCheck
                        ? "Done with Covid Check"
                        : "Not yet done with Covid Check"}
                    </IonLabel>
                  </div>
                  {isCovidRisk && (
                    <div className="with-result-covid-container">
                      <div className="covid-risk-container">
                        <IonIcon className="covid-risk-icon" />{" "}
                        <IonLabel className="mb-body white bold ion-no-padding ion-no-margin">
                          Covid Risk
                        </IonLabel>
                      </div>
                    </div>
                  )}
                  <IonLabel className="with-result-booked-days-ago mb-paragraph light ion-no-padding ion-no-margin">
                    Booked: {daysSinceDate(qrData.createdAt.toDate())} days ago
                  </IonLabel>
                </div>

                <div className="with-result-qr-buttons-container">
                  <div className="with-result-qr-code-container">
                    <QRCode value="testappointmentId123" size={100} />
                  </div>
                  <div className="with-result-action-button-container">
                    <IonButton
                      mode="ios"
                      color={MBCOLORS.primary}
                      fill="clear"
                      className="with-result-button go-back mb-body blue bold"
                      onClick={onDidDismiss}
                    >
                      Go Back
                    </IonButton>
                    {moment(qrData.appointmentDate).isSameOrBefore(
                      new Date(),
                      "days"
                    ) && (
                      <IonButton
                        mode="ios"
                        color={MBCOLORS.success}
                        className="with-result-button confirm mb-body white bold"
                        onClick={() => {
                          onConfirmArrival(qrData.docId || "");
                        }}
                      >
                        Confirm Arrival
                      </IonButton>
                    )}
                  </div>
                </div>
              </div> //end of result container
            ) : (
              <div className="mb-qr-result-no-result-container">
                <IonIcon className="qr-no-result" />
                <IonLabel className="mb-qr-result-no-result-label mb-h2 dark-blue ion-no-padding ion-no-margin">
                  No Results Found
                </IonLabel>
                <IonLabel className="mb-qr-result-no-result-sub-label mb-body ion-no-padding ion-no-margin">
                  Please try again and if it fails again try searching the
                  patient's name to the search page
                </IonLabel>
                <div className="mb-qr-result-no-result-action-buttons-container">
                  <div className="try-search-buttons-container">
                    <IonButton
                      color={MBCOLORS.success}
                      mode="ios"
                      className="try-again-button mb-body bold white ion-no-padding ion-no-margin"
                      onClick={onDidDismiss}
                    >
                      Please Try Again
                    </IonButton>
                    <IonButton
                      color={MBCOLORS.primary}
                      mode="ios"
                      className="search-button mb-body bold white ion-no-padding ion-no-margin"
                    >
                      Go to Search
                    </IonButton>
                  </div>
                  <IonButton
                    mode="ios"
                    fill="clear"
                    color={MBCOLORS.primary}
                    className="go-back-button mb-body bold blue ion-no-padding ion-no-margin"
                    onClick={onDidDismiss}
                  >
                    Go Back
                  </IonButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </IonModal>
      <MBDialog
        isOpen={successConfirmArrival}
        title={
          mode === "doctor"
            ? "You hav successfully admited the patient."
            : "Patient Arrival has been confirmed"
        }
        message={
          mode === "doctor"
            ? "It’s up to you now. We wish you a safe and a blessed medical service"
            : "Please assist the patient to their appointment."
        }
        icon="success"
        onDidDismiss={onDidDismissSuccessConfirm}
      />
    </>
  );
};
