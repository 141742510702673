import * as EmailValidator from "email-validator";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { logoFacebook, call, mail } from "ionicons/icons";
import { TimelineLite, Power3, Power4, Power2 } from "gsap";
import {
  IonButtons,
  IonLoading,
  IonInput,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonRow,
  IonToast,
  IonModal,
  IonCheckbox,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonImg,
  IonRippleEffect,
} from "@ionic/react";

import * as email from "../../functions/email";
import * as services from "../../services";
import * as routes from "../../constants/routes";
import "./Preregistration.scss";
import { analytics } from "../../firebase";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import { Helmet } from "react-helmet";
import { MBProps } from "../../interface";
import { LandingFooter } from "./LandingFooter";
import { formatString, isMobile } from "../../functions/common";
import { Geolocation } from "@capacitor/core";
import { Coordinates } from "../../models";
import { ANALYTICS_CONTENT_TYPES } from "../../constants/config";
import { MBCOLORS } from "../../constants/config";
import { MSGS_COMMON_FORM_ERROR } from "../../constants/messages";

interface PreregistrationProps extends MBProps {
  onGoHome: () => void;
}
const Preregistration: React.FC<PreregistrationProps> = (props) => {
  setTimeout(() => {
    setShowLoading(false);
  }, 1000);

  let t1 = new TimelineLite({ delay: 0.1 });

  useEffect(() => {
    t1.from(
      ".banneranim",
      { y: 15, opacity: 0, ease: Power3.easeInOut, delay: 0.8, yoyo: true },
      "Start"
    );

    t1.from(
      ".textanim",
      { y: 20, opacity: 0, ease: Power4.easeOut, delay: 0.4 },
      "Start"
    );
    t1.from(
      ".btnanim",
      { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.7 },
      "Start"
    );
    t1.from(
      ".box",
      { x: 20, opacity: 0, ease: Power3.easeInOut, delay: 0.7 },
      "Start"
    );
    t1.from(
      ".headanim",
      { y: -100, opacity: 0, ease: Power2.easeInOut, delay: 0 },
      "Start"
    );
  }, []);

  const { onGoHome } = props;
  const [showLoading, setShowLoading] = useState(true);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [isSubcribedToNewsletter, setIsSubcribedToNewsletter] = useState(false);
  // const [selectedProvince, setSelectedProvince] = useState("");
  // const [provinceSearch, setProvinceSearch] = useState("");

  // const [selectedCityMunicipality, setSelectedCityMunicipality] = useState("");
  // const [cityMunicipalitySearch, setCityMunicipalitySearch] = useState("");
  // const [listOfCityMunicipality, setListOfCityMunicipality] = useState(
  //   [] as string[]
  // );

  // const [selectedHospital1, setSelectedHospital1] = useState(
  //   {} as MBDropdownSelectOption
  // );
  // const [hospitalSearch1, setHospitalSearch1] = useState("");

  // const [selectedHospital2, setSelectedHospital2] = useState(
  //   {} as MBDropdownSelectOption
  // );
  // const [hospitalSearch2, setHospitalSearch2] = useState("");

  // const [selectedHospital3, setSelectedHospital3] = useState(
  //   {} as MBDropdownSelectOption
  // );
  // const [hospitalSearch3, setHospitalSearch3] = useState("");

  // const [hospitalNetworkOptions, setHospitalNetworkOptions] = useState(
  //   [] as MBDropdownSelectOption[]
  // );

  // const [service1, setService1] = useState("");
  // const [service2, setService2] = useState("");
  // const [service3, setService3] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // useEffectOnlyOnce(() => {
  //   getHospitalOptions();
  // });
  // const getHospitalOptions = () => {
  //   const hospitalNetwork = HOSPITAL_LIST.map((hospital, index) => {
  //     return {
  //       id: index.toString(),
  //       name: _.upperCase(hospital),
  //     };
  //   });
  //   setHospitalNetworkOptions(hospitalNetwork);
  // };

  const getCurrentLocation = async (): Promise<Coordinates> => {
    return new Promise((resolve) => {
      Geolocation.getCurrentPosition()
        .then((result) => {
          resolve({
            lat: result.coords.latitude,
            lng: result.coords.longitude,
          });
        })
        .catch(() => {
          resolve({ lat: -28.1104171, lng: 153.4342987 });
        });
    });
  };

  const onSubmit = async () => {
    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedPhoneNumber = formatString((phoneNumber || "") as string);
    // const formattedSelectedHospital1 = formatString(
    //   (selectedHospital1.name || hospitalSearch1 || "") as string
    // );
    // const formattedSelectedHospital2 = formatString(
    //   (selectedHospital2.name || hospitalSearch2 || "") as string
    // );
    // const formattedSelectedHospital3 = formatString(
    //   (selectedHospital3.name || hospitalSearch3 || "") as string
    // );
    let errorMessage = "";
    if (_.isEmpty(formattedFirstName)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.firstName;
      setError(errorMessage);
    } else if (_.isEmpty(formattedLastName)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.lastName;
      setError(errorMessage);
    } else if (_.isEmpty(formattedPhoneNumber)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.mobileNumber;
      setError(errorMessage);
    } else if (!validatePhoneNumber(formattedPhoneNumber)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.invalidMobileNumner;
      setError(errorMessage);
    } else if (_.isEmpty(formattedEmailAddress)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.emailAddress;
      setError(errorMessage);
    } else if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.invalidEmail;
      setError(errorMessage);
    }
    // } else if (_.isEmpty(selectedProvince)) {
    //   errorMessage = "Province is required";
    //   setError(errorMessage);
    // } else if (_.isEmpty(selectedCityMunicipality)) {
    //   errorMessage = "City/Municipality is required";
    //   setError(errorMessage);
    // }

    if (_.isEmpty(errorMessage)) {
      setLoading(true);
      try {
        const location = (await getCurrentLocation()) as Coordinates;
        await services.addPreRegistrationSignUps(
          formattedFirstName,
          formattedLastName,
          formattedEmailAddress,
          formattedPhoneNumber,
          location as Coordinates,
          isSubcribedToNewsletter
        );

        try {
          console.log("will send email notif");
          const { subject, message } = email.successPreRegistration();
          console.log(subject, message);
          await services.sendEmail(formattedEmailAddress, subject, message);
          analytics.logEvent("submit_pre_registration", {
            content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
          });
        } catch (error) {
          console.log("ERROR SENDING NOTIFS", error);
        }
        clearState();
        setIsSuccessOpen(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError("");
      }
    }
  };

  const clearState = () => {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setEmailAddress("");
    // setService1("");
    // setService2("");
    // setService3("");
  };

  return (
    <IonPage className="bkg-gray">
      <meta
        title="Pre Register for Medbook Philippines | Online Appointments"
        content="Pre register now for Medbook Philippines to get exclusive rewards. Book hospital appointments, doctor consultations, check ups and more."
      />
      <Helmet>
        <title>
          Pre Register for Medbook Philippines | Online Appointments
        </title>
      </Helmet>

      <IonHeader {...(isMobile() && { mode: "ios" })}>
        <IonToolbar
          className={`${
            isMobile()
              ? "ion-padding-vertical landing-header"
              : "ion-padding-top"
          }`}
        >
          <IonTitle
            onClick={() => {
              if (props.location.pathname === routes.LANDING_PRE_REGISTER) {
                props.history.push(routes.LANDING);
                analytics.logEvent("on_click_header_to_landing", {
                  content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                });
              } else {
                onGoHome();
              }
            }}
            className={`ion-no-padding ${
              isMobile() ? "ion-padding-vertical ion-align-items-start" : ""
            }`}
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              className={`${isMobile() ? "ion-padding-start" : "logo-padding"}`}
              src={`${process.env.PUBLIC_URL}/assets/images/Medbooklogo.svg`}
            />
          </IonTitle>

          <IonButtons
            slot="primary"
            className={` ${
              isMobile() ? "ion-align-items-end" : "ion-align-self-start"
            } ml-50`}
          >
            {/* <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-margin-right mr-10"
              // fill="outline"
              color="primary"
              routerLink={routes.LOGIN_OPTIONS}
            >
              Login
            </IonButton> */}
            <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className={`landing-dark-blue-text ${
                isMobile() ? "ion-padding-end" : "ion-padding-end mr-50"
              }`}
              fill="outline"
              color="primary"
              onClick={() => {
                analytics.logEvent("follow_us_facebook", {
                  content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                });
              }}
            >
              Follow us
              <IonIcon slot="start" icon={logoFacebook} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* CONTENT */}
      <IonContent>
        <IonRow className="pre-reg-row ion-justify-content-center landing-light textanim">
          <IonCol
            size={isMobile() ? "12" : "5"}
            className="pre-reg-form-col ion-justify-content-center ion-text-align-center"
          >
            <IonCard
              className={`pre-reg-card ${isMobile() ? "mobile" : ""} shadow ${
                !isMobile() && "ion-margin bord20"
              } preregistration`}
            >
              <div
                className={`pre-reg-input-containers ${isMobile() && "mobile"}`}
              >
                <h3 className="landing-dark-blue-text mb-h2 dark-blue bold">
                  Sign-up
                </h3>
                <p>Open your doors to the new normal. Pre-Register Now.</p>
                <form>
                  <IonItem className="pre-reg-input-item">
                    <IonLabel
                      className="pre-reg-input-label mb-body bold"
                      position="stacked"
                    >
                      First Name
                    </IonLabel>
                    <IonInput
                      type="text"
                      clearInput
                      onIonChange={(e) => {
                        setFirstName(e.detail.value || "");
                      }}
                      required
                    ></IonInput>
                  </IonItem>
                  <br></br>
                  <IonItem className="pre-reg-input-item">
                    <IonLabel
                      className="pre-reg-input-label mb-body bold"
                      position="stacked"
                    >
                      Last Name
                    </IonLabel>
                    <IonInput
                      type="text"
                      clearInput
                      onIonChange={(e) => {
                        setLastName(e.detail.value || "");
                      }}
                      required
                    ></IonInput>
                  </IonItem>
                  <br></br>
                  <IonItem className="pre-reg-input-item">
                    <IonLabel
                      className="pre-reg-input-label mb-body bold"
                      position="stacked"
                    >
                      Mobile Number
                    </IonLabel>
                    <IonInput
                      type="number"
                      onIonChange={(e) => {
                        setPhoneNumber(e.detail.value || "");
                      }}
                      required
                    >
                      {" "}
                    </IonInput>
                    <IonIcon
                      className="preregister-icon"
                      color="medium"
                      src={call}
                      slot="end"
                    ></IonIcon>
                  </IonItem>
                  <br></br>

                  <IonItem className="pre-reg-input-item">
                    <IonLabel
                      className="pre-reg-input-label mb-body bold"
                      position="stacked"
                    >
                      Email
                    </IonLabel>
                    <IonInput
                      type="email"
                      onIonChange={(e) => {
                        setEmailAddress(e.detail.value || "");
                      }}
                      required
                    >
                      {" "}
                    </IonInput>
                    <IonIcon
                      className="preregister-icon"
                      color="medium"
                      src={mail}
                      slot="end"
                    ></IonIcon>
                  </IonItem>
                  <br></br>
                  <IonItem className="newsletter-item" lines="none">
                    <IonCheckbox
                      mode="md"
                      checked={isSubcribedToNewsletter}
                      onIonChange={(event) => {
                        setIsSubcribedToNewsletter(event.detail.checked);
                      }}
                      className="ion-margin-end"
                    />
                    <IonLabel
                      className={`newsletter-label ${
                        isMobile() ? "mb-h6" : "mb-body"
                      } ion-no-margin ion-no-padding`}
                    >
                      Subscribe to Medbook’s Newsletter for Rewards and Promo
                      Updates
                    </IonLabel>
                  </IonItem>
                  {/* <div
                    className={`pregistration-dropdown-container ${
                      isMobile() && "mobile"
                    }`}
                  >
                    <MBDropdownSelect
                      label="Province"
                      className="preregistration-dropdown-input"
                      value={selectedProvince || provinceSearch}
                      onChange={(provinceSearch) => {
                        setProvinceSearch(provinceSearch);
                        // setErrorMsgProvince("");
                        if (provinceSearch.trim() !== selectedProvince) {
                          setSelectedProvince("");
                        }
                      }}
                      onSelectItem={(selectedProvince) => {
                        setSelectedProvince(selectedProvince.name);
                        setListOfCityMunicipality(
                          ADDRESS[selectedProvince.name]
                        );
                        setSelectedCityMunicipality("");
                        setCityMunicipalitySearch("");
                        // setErrorMsgProvince("");
                      }}
                      selectFromOptionsRequired={true}
                      options={PROVINCES.map((province, index) => {
                        return {
                          id: index.toString(),
                          name: province,
                        } as MBDropdownSelectOption;
                      })}
                      error={""}
                    />
                  </div> */}

                  {/* <div
                    className={`pregistration-dropdown-container ${
                      isMobile() && "mobile"
                    }`}
                  >
                    <MBDropdownSelect
                      label="City/Municipality"
                      value={selectedCityMunicipality || cityMunicipalitySearch}
                      onChange={(cityMunicipalitySearch) => {
                        setCityMunicipalitySearch(cityMunicipalitySearch);
                        if (
                          cityMunicipalitySearch.trim() !==
                          selectedCityMunicipality
                        ) {
                          setSelectedCityMunicipality("");
                        }
                      }}
                      onSelectItem={(selectedCityMunicipality) => {
                        setSelectedCityMunicipality(
                          selectedCityMunicipality.name
                        );
                      }}
                      selectFromOptionsRequired={true}
                      options={listOfCityMunicipality.map(
                        (cityMunicipality, index) => {
                          return {
                            id: index.toString(),
                            name: cityMunicipality,
                          } as MBDropdownSelectOption;
                        }
                      )}
                      error={""}
                    />
                  </div> */}

                  {/* SURVEY */}

                  {/* <IonCard className="card-no-shadow" color="right"></IonCard>

                  <p className="">
                    {" "}
                    <strong> Quick Survey (Optional)</strong>{" "}
                  </p>
                  <p className="survey-note mb-paragraph light">
                    By answering this short survey, we will be able to improve
                    our services that benefits you and your loved ones, because
                    a total patient experience is what you deserve.
                  </p>
                  <IonItem className="landing-light" lines="none">
                    <p>Top 3 hospitals that you visit regularly</p>
                  </IonItem>
                  <br></br>

                  <div
                    className={`pregistration-dropdown-container ${
                      isMobile() && "mobile"
                    }`}
                  >
                    <MBDropdownSelect
                      className="hospital-account-setup-hospital-drop-down"
                      label="Hospital 1"
                      value={
                        !_.isEmpty(selectedHospital1)
                          ? selectedHospital1.name
                          : hospitalSearch1
                      }
                      onChange={(hospitalSearch1) => {
                        setHospitalSearch1(hospitalSearch1);
                        if (
                          !_.isEmpty(selectedHospital1) &&
                          hospitalSearch1.trim().toLowerCase() !==
                            selectedHospital1.name.trim().toLowerCase()
                        ) {
                          setSelectedHospital1({} as MBDropdownSelectOption);
                        }
                      }}
                      onSelectItem={(selectedHospital1) => {
                        setSelectedHospital1(selectedHospital1);
                      }}
                      selectFromOptionsRequired={true}
                      options={hospitalNetworkOptions}
                      error={""}
                    />
                  </div>

                  <div
                    className={`pregistration-dropdown-container ${
                      isMobile() && "mobile"
                    }`}
                  >
                    <MBDropdownSelect
                      className="hospital-account-setup-hospital-drop-down"
                      label="Hospital 2"
                      value={
                        !_.isEmpty(selectedHospital2)
                          ? selectedHospital2.name
                          : hospitalSearch2
                      }
                      onChange={(hospitalSearch2) => {
                        setHospitalSearch2(hospitalSearch2);
                        if (
                          !_.isEmpty(selectedHospital2) &&
                          hospitalSearch2.trim().toLowerCase() !==
                            selectedHospital2.name.trim().toLowerCase()
                        ) {
                          setSelectedHospital2({} as MBDropdownSelectOption);
                        }
                      }}
                      onSelectItem={(selectedHospital2) => {
                        setSelectedHospital2(selectedHospital2);
                      }}
                      selectFromOptionsRequired={true}
                      options={hospitalNetworkOptions}
                      error={""}
                    />
                  </div>

                  <div
                    className={`pregistration-dropdown-container ${
                      isMobile() && "mobile"
                    }`}
                  >
                    <MBDropdownSelect
                      className="hospital-account-setup-hospital-drop-down"
                      label="Hospital 3"
                      value={
                        !_.isEmpty(selectedHospital3)
                          ? selectedHospital3.name
                          : hospitalSearch3
                      }
                      onChange={(hospitalSearch3) => {
                        setHospitalSearch3(hospitalSearch3);
                        if (
                          !_.isEmpty(selectedHospital3) &&
                          hospitalSearch3.trim().toLowerCase() !==
                            selectedHospital3.name.trim().toLowerCase()
                        ) {
                          setSelectedHospital3({} as MBDropdownSelectOption);
                        }
                      }}
                      onSelectItem={(selectedHospital3) => {
                        setSelectedHospital3(selectedHospital3);
                      }}
                      selectFromOptionsRequired={true}
                      options={hospitalNetworkOptions}
                      error={""}
                    />
                  </div>

                  <br></br>

                  <IonItem className="landing-light" lines="none">
                    <p>Top 3 Services that you usually avail</p>
                  </IonItem>
                  <br></br>

                  <IonItem className="pre-reg-input-item">
                    <IonLabel
                      className={`${
                        isMobile() ? "mb-paragraph" : "mb-h4"
                      } bold`}
                      position="stacked"
                    >
                      Service 1
                    </IonLabel>
                    <IonInput
                      type="text"
                      onIonChange={(e) => {
                        setService1(e.detail.value || "");
                      }}
                      clearInput
                    >
                      {" "}
                    </IonInput>
                  </IonItem>

                  <IonItem className="pre-reg-input-item">
                    <IonLabel
                      className={`${
                        isMobile() ? "mb-paragraph" : "mb-h4"
                      } bold`}
                      position="stacked"
                    >
                      Service 2
                    </IonLabel>
                    <IonInput
                      type="text"
                      onIonChange={(e) => {
                        setService2(e.detail.value || "");
                      }}
                      clearInput
                    >
                      {" "}
                    </IonInput>
                  </IonItem>
                  <IonItem className="pre-reg-input-item">
                    <IonLabel
                      className={`${
                        isMobile() ? "mb-paragraph" : "mb-h4"
                      } bold`}
                      position="stacked"
                    >
                      Service 3
                    </IonLabel>
                    <IonInput
                      type="text"
                      onIonChange={(e) => {
                        setService3(e.detail.value || "");
                      }}
                      clearInput
                    >
                      {" "}
                    </IonInput>
                  </IonItem> */}

                  <br></br>
                  <br></br>
                  <div className="doctor-create-password-privacy ion-margin-bottom">
                    <IonLabel
                      className={`doctor-create-password-data-privacy-consent-label ${
                        isMobile() ? "mb-paragraph" : "md-body"
                      } ion-no-margin`}
                    >
                      By clicking SUBMIT, I acknowledge that I have read and
                      accepted the{" "}
                      <a
                        // className="no-underline mb-paragraph white"
                        href="https://www.cortex.ph/privacypolicy/"
                        target="blank"
                        onClick={() => {
                          analytics.logEvent(
                            "on_click_data_privacy_pre_registration",
                            {
                              content_type:
                                ANALYTICS_CONTENT_TYPES.landingPage.type,
                            }
                          );
                        }}
                      >
                        {" "}
                        data privacy policy{" "}
                      </a>
                    </IonLabel>
                  </div>

                  <IonButton
                    onClick={() => {
                      if (
                        props.location.pathname === routes.LANDING_PRE_REGISTER
                      ) {
                        props.history.push(routes.LANDING);
                      } else {
                        onGoHome();
                      }
                      analytics.logEvent(
                        "on_click_go_to_home_pre_registration",
                        {
                          content_type:
                            ANALYTICS_CONTENT_TYPES.landingPage.type,
                        }
                      );
                    }}
                    className="goto-home-pre-reg-button mb-h4 normal mb-web-gray-button ion-margin-right"
                  >
                    GO TO HOME
                  </IonButton>

                  <IonButton
                    className="submit-pre-reg-button mb-h4 ion-margin-left"
                    color="primary"
                    onClick={() => {
                      onSubmit();
                    }}
                  >
                    SUBMIT
                  </IonButton>
                </form>

                <br></br>
                <br></br>
                <br></br>
              </div>
            </IonCard>
          </IonCol>

          {!isMobile() && (
            <IonCol
              size="5"
              className="pre-reg-form-col ion-self-aling-center btnanim"
            >
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/preregister-illustration.svg`}
              ></img>
            </IonCol>
          )}
          {/* <LandingFooter /> */}

          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"We are getting there..."}
            duration={5000}
          />

          {/* <LandingFooter className={isMobile() ? "" : "pre-reg-footer"} /> */}
        </IonRow>
        {/* HOW EASY MEDBOOK */}
        <IonRow className="pre-reg-how-easy-row bkg-blue marg">
          <IonCard
            // color="light"
            className="landing-center card-no-shadow ion-text-center bkg-trans"
          >
            <IonCardHeader>
              <IonCardTitle>
                <h1 className="landing-dark-blue-text"> Booking made easy</h1>
              </IonCardTitle>
              <IonCardContent>
                Medbook provides a hassle-free and easy use app to provide your
                medical needs.
              </IonCardContent>

              <IonRow className="ion-justify-content-center  ">
                <IonCol size="2.5">
                  <IonCard className="card-no-shadow  bord-20 ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bkg-trans ">
                    <IonImg
                      src={`${process.env.PUBLIC_URL}/assets/images/LoginPhone.png`}
                    />
                    <h4 className="landing-dark-blue-text">Login</h4>
                    <p>
                      We provide innovative realtime booking of medical services
                      and consultations.
                    </p>
                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
                <IonCol size="2.5">
                  <IonCard className="card-no-shadow   bord-20 ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bkg-trans ">
                    <IonImg
                      src={`${process.env.PUBLIC_URL}/assets/images/SeartchPhone.png`}
                    />
                    <h4 className="landing-dark-blue-text">Search </h4>
                    <p>
                      We provide innovative realtime booking of medical services
                      and consultations.
                    </p>
                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
                <IonCol size="2.5">
                  <IonCard className="card-no-shadow  bord-20 ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bkg-trans ">
                    <IonImg
                      src={`${process.env.PUBLIC_URL}/assets/images/BookandPayPhone.png`}
                    />
                    <h4 className="landing-dark-blue-text">Book and Pay</h4>
                    <p>
                      We provide innovative realtime booking of medical services
                      and consultations.
                    </p>
                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonCardHeader>
          </IonCard>
        </IonRow>
        {/* HOME TO HOME IN 6 STEPS */}
        <div className="home-to-home-container">
          <IonRow className="ion-justify-content-center teal box">
            <IonCard color="primary" className="card-no-shadow bkg-trans">
              <IonCardHeader>
                <IonCardTitle className="ion-text-center">
                  <h1>Home to Home in 6 Steps</h1>
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow className="ion-justify-content-center teal boxcont">
            <IonCol size="2.5">
              <IonCard className="card-no-shadow ion-padding bkg-trans">
                <IonItem color="transparent" lines="none">
                  <IonIcon
                    src={`${process.env.PUBLIC_URL}/assets/images/step.svg`}
                    slot="start"
                  />
                  <h6 className="white-text">Book and Pay</h6>
                </IonItem>

                <IonCardContent>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/BookandPay.svg`}
                  ></img>
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol size="2.5">
              <IonCard className="card-no-shadow ion-padding bkg-trans">
                <IonItem color="transparent" lines="none">
                  <IonIcon
                    src={`${process.env.PUBLIC_URL}/assets/images/step2.svg`}
                    slot="start"
                  />
                  <h6 className="white-text">Waiting for Appointment Day</h6>
                </IonItem>

                <IonCardContent>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Wait.svg`}
                  ></img>
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol size="2.5">
              <IonCard className="card-no-shadow ion-padding bkg-trans">
                <IonItem color="transparent" lines="none">
                  <IonIcon
                    src={`${process.env.PUBLIC_URL}/assets/images/step3.svg`}
                    slot="start"
                  />
                  <h6 className="white-text">
                    Take Covid Checks & Save QR Code
                  </h6>
                </IonItem>

                <IonCardContent>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/TakeCovidCheck.svg`}
                  ></img>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-center teal">
            <IonCol size="2.5">
              <IonCard className="card-no-shadow ion-padding bkg-trans">
                <IonItem color="transparent" lines="none">
                  <IonIcon
                    src={`${process.env.PUBLIC_URL}/assets/images/step4.svg`}
                    slot="start"
                  />
                  <h6 className="white-text">
                    Present QR Code to Hospital Security
                  </h6>
                </IonItem>

                <IonCardContent>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/PresentQR.svg`}
                  ></img>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="2.5">
              <IonCard className="card-no-shadow ion-padding bkg-trans">
                <IonItem color="transparent" lines="none">
                  <IonIcon
                    src={`${process.env.PUBLIC_URL}/assets/images/step5.svg`}
                    slot="start"
                  />
                  <h6 className="white-text">Go to your Appointment</h6>
                </IonItem>
                <IonCardContent>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Appointment.svg`}
                  ></img>
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol size="2.5">
              <IonCard className="card-no-shadow ion-padding bkg-trans">
                <IonItem color="transparent" lines="none">
                  <IonIcon
                    src={`${process.env.PUBLIC_URL}/assets/images/step6.svg`}
                    slot="start"
                  />
                  <h6 className="white-text">Go Home & Rate the Service</h6>
                </IonItem>

                <IonCardContent>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/rate.svg`}
                  ></img>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </div>
        {/* LEARN MORE */}
        <IonRow
          className={`learn-more-row ${
            isMobile() ? "mobile" : ""
          } bkg-blue ion-justify-content-center teal ion-padding-top`}
        >
          <IonCol
            className={`learn-more-col ${
              isMobile() ? "mobile" : ""
            } white-text ion-padding-top pb-0  textanim`}
            size={isMobile() ? "12" : "4"}
          >
            <div className="learn-more-content-container">
              <IonLabel className="learn-more-title dark-blue">
                Learn more about MedBook
              </IonLabel>
              <IonLabel className="learn-more-description">
                Book your next consultation, checkup, lab test, and more through
                Medbook!
              </IonLabel>

              <IonButton
                className="learn-more-button btnanim ion-text-capitalize"
                color="warning"
                routerLink={routes.LANDING}
                onClick={() => {
                  onGoHome();
                }}
              >
                Learn More
              </IonButton>
            </div>
          </IonCol>

          <IonCol
            size={isMobile() ? "12" : "4"}
            className="learn-more-phone-col ion-no-padding ion-no-margin pb-0 banneranim"
          >
            <div className="phone-img-container">
              <IonImg
                className={`phone-img ${
                  isMobile() ? "mobile" : ""
                } ion-no-padding ion-no-margin`}
                src={`${process.env.PUBLIC_URL}/assets/images/pre-reg-phone.png`}
              ></IonImg>
            </div>
          </IonCol>
        </IonRow>

        <LandingFooter />
      </IonContent>
      <IonModal
        isOpen={isSuccessOpen}
        cssClass="pre-reg-success-dialog"
        onDidDismiss={() => {
          setIsSuccessOpen(false);
          window.location.reload();
        }}
      >
        <div className="modal-content">
          <div className="pre-reg-dialog-children-container">
            <IonLabel className="pre-reg-dialog-title-label mb-h2 dark-blue">
              You're good to go!
            </IonLabel>
            <IonLabel className="pre-reg-dialog-message-label mb-body">
              You have successfully pre-registered for Medbook. Watch out for
              our email announcements regarding our official release.
            </IonLabel>
            <IonIcon className="pre-reg-icon" />
            <IonButton
              className="pre-reg-dialog-action-button mb-body white bold ion-text-capitalize"
              color={MBCOLORS.primary}
              onClick={() => {
                setIsSuccessOpen(false);
                analytics.logEvent("on_close_success_registration", {
                  content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                });
              }}
            >
              Close
            </IonButton>
          </div>
        </div>
      </IonModal>
      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={"Loading.."}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={MBCOLORS.danger}
      />
      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={2000}
        onDidDismiss={() => {
          setSuccess("");
        }}
        color={MBCOLORS.success}
      />
    </IonPage>
  );
};

export default Preregistration;
