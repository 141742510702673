import * as _ from "lodash";
import { chevronDownOutline, closeCircle } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import {
  IonLabel,
  IonInput,
  IonList,
  IonIcon,
  IonSpinner,
  IonChip,
} from "@ionic/react";

import "./MBMultipleDropdownSelect.scss";
import { isMobile } from "../../functions/common";

export interface MBMultipleDropdownSelectOption {
  id: string;
  name: string;
  metadata?: any;
}
interface MBMultipleDropdownSelectProps {
  label?: string;
  searchItem: string;
  onChange?: (value: string) => void;
  onSelectItems?: (item: string[]) => void;
  options: MBMultipleDropdownSelectOption[] | null;
  selectFromOptionsRequired?: boolean;
  readonly?: boolean;
  error?: string;
  disabled?: boolean;
  disableTab?: boolean;
  emptyOptionLabel?: string;
  placeholder?: string;
  values: string[];
  onRemoveItem: (items: string[]) => void;
}
export const MBMultipleDropdownSelect = (
  props: MBMultipleDropdownSelectProps
) => {
  const {
    label,
    searchItem,
    onChange,
    onSelectItems,
    error,
    options,
    selectFromOptionsRequired,
    disableTab,
    disabled,
    readonly,
    emptyOptionLabel,
    placeholder,
    values,
    onRemoveItem,
  } = props;
  const [hasFocus, setHasFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [listOptions, setListOptions] = useState(
    null as MBMultipleDropdownSelectOption[] | null
  );
  const [rawOptions, setRawOptions] = useState(
    [] as MBMultipleDropdownSelectOption[]
  );
  const [itemGettingClicked, setItemGettingClicked] = useState(false);

  const [onKeyEnterValue, setOnKeyEnterValue] = useState("");

  useEffect(() => {
    if (!_.isNull(options)) {
      const newOptionsIdentifier = options.map((option) => option.id).join("");
      const oldOptionsIdentifier = rawOptions
        .map((option) => option.id)
        .join("");

      if (newOptionsIdentifier !== oldOptionsIdentifier) {
        setRawOptions(_.sortBy(options, "name", "desc"));
        if (_.isEmpty(searchItem)) {
          setListOptions(null);
        }
      }
    } else {
      setRawOptions([]);
      setListOptions(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <>
      <div className="mb-dropdown-multiple-select-item ion-no-padding ion-no-margin">
        {label !== undefined && (
          <IonLabel
            className={`mb-dropdown-multiple-select-label mb-paragraph bold ${
              hasFocus && !readonly && "has-focus"
            } ${!!error && "has-error"}`}
            position="stacked"
          >
            {label}
          </IonLabel>
        )}
        <div className="mb-dropdow-select-input-list-container">
          <div
            className={`mb-dropdown-multiple-select-chip-input-container ${
              hasFocus && "has-focus"
            } ${!!error && "has-error"} mb-body`}
          >
            {!_.isEmpty(values) &&
              values.map((item, index) => {
                return (
                  <IonChip
                    key={index}
                    className="mb-dropdown-multiple-select-chip ion-no-padding ion-no-margin"
                  >
                    <IonLabel className="mb-h4 blue">{item}</IonLabel>
                    <IonIcon
                      className="mb-dropdown-multiple-select-chip-icon"
                      icon={closeCircle}
                      onClick={() => {
                        const prevSelectedItems = _.clone(values);
                        const removedItem = _.remove(
                          prevSelectedItems,
                          (selectedItem) => {
                            return selectedItem !== item;
                          }
                        );
                        onRemoveItem(removedItem);
                      }}
                    />
                  </IonChip>
                );
              })}
            <IonInput
              {...(readonly !== undefined && { readonly: true })}
              className="mb-dropdown-multiple-select-input mb-body"
              {...(placeholder !== undefined && { placeholder })}
              type="text"
              onKeyDown={(event) => {
                if (event.keyCode === 13 && onSelectItems !== undefined) {
                  event.preventDefault();
                  const prevMultipleItems = _.clone(values);

                  onSelectItems([...prevMultipleItems, onKeyEnterValue]);
                  setOnKeyEnterValue("");
                }
              }}
              onIonChange={(event) => {
                if (onChange !== undefined) {
                  const strValue = event.detail.value || "";
                  onChange(strValue);
                  setOnKeyEnterValue(strValue);
                  const filteredOptions = _.filter(
                    rawOptions,
                    (option) =>
                      option.name
                        .trim()
                        .toLowerCase()
                        .indexOf(strValue.trim().toLowerCase()) !== -1
                  );

                  setListOptions(
                    !_.isNull(rawOptions)
                      ? !_.isEmpty(strValue)
                        ? filteredOptions
                        : rawOptions
                      : []
                  );
                } else {
                  event.preventDefault();
                }
              }}
              disabled={disabled}
              autocomplete="off"
              onIonFocus={() => {
                setHasFocus(true);
                setIsOpen(true);
              }}
              onIonBlur={() => {
                if (!itemGettingClicked) {
                  setIsOpen(false);
                }
                setHasFocus(false);
              }}
              {...(disableTab && {
                onKeyDown: (event: React.KeyboardEvent) => {
                  if (event.keyCode === 9) {
                    event.preventDefault();
                  }
                },
              })}
            >
              {_.isNull(options) ? (
                <IonSpinner />
              ) : (
                selectFromOptionsRequired && (
                  <IonIcon
                    className="mb-dropdown-multiple-select-icon"
                    icon={chevronDownOutline}
                  />
                )
              )}
            </IonInput>
          </div>

          {!!isOpen &&
            (!_.isEmpty(
              !_.isNull(listOptions)
                ? listOptions
                : !_.isNull(rawOptions)
                ? rawOptions
                : []
            ) ||
              !_.isEmpty(emptyOptionLabel)) && (
              <IonList
                className={`mb-dropdown-multiple-select-list ${
                  isMobile() && "mobile"
                }`}
                onBlur={() => {
                  if (!selectFromOptionsRequired) {
                    setIsOpen(false);
                  }
                }}
              >
                {!_.isEmpty(listOptions) || !_.isEmpty(rawOptions) ? (
                  (!_.isNull(listOptions)
                    ? listOptions
                    : !_.isNull(rawOptions)
                    ? rawOptions
                    : []
                  ).map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="mb-dropdown-multiple-select-list-container ion-no-padding ion-no-margin"
                        onClick={() => {
                          if (onSelectItems !== undefined) {
                            const prevItems = _.clone(values || []);
                            onSelectItems([...prevItems, item.name]);
                          }
                          setIsOpen(false);
                        }}
                        onMouseOver={() => {
                          setItemGettingClicked(true);
                        }}
                        onMouseLeave={() => {
                          setItemGettingClicked(false);
                        }}
                      >
                        <IonLabel className="mb-dropdown-multiple-select-list-label mb-body ion-no-padding ion-no-margin">
                          {item.name}
                        </IonLabel>
                      </div>
                    );
                  })
                ) : (
                  <div className="mb-dropdown-multiple-select-list-container ion-no-padding ion-no-margin">
                    <IonLabel className="mb-dropdown-multiple-select-list-label mb-body ion-no-padding ion-no-margin">
                      {emptyOptionLabel || ""}
                    </IonLabel>
                  </div>
                )}
              </IonList>
            )}
        </div>
      </div>
      {!!error && (
        <div className="ion-text-start">
          <IonLabel className="mb-dropdown-multiple-select-error-label mb-h6 small has-error ion ion-no-margin ion-no-padding">
            {error}
          </IonLabel>
        </div>
      )}
    </>
  );
};
