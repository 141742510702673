import * as EmailValidator from "email-validator";
import React, { useState, useEffect } from "react";
import {
  IonButtons,
  IonToast,
  IonInput,
  IonSlide,
  IonSlides,
  IonItem,
  IonLabel,
  IonRippleEffect,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonCard,
} from "@ionic/react";
import { TimelineLite, Power3, Power4, Bounce, Power2 } from "gsap";

import { logoFacebook } from "ionicons/icons";

import "./Tab3.scss";
import * as services from "../../services";
import * as routes from "../../constants/routes";
import { LandingFooter } from "./LandingFooter";
import { formatString, isMobile } from "../../functions/common";
import _ from "lodash";
import { ANALYTICS_CONTENT_TYPES, MBCOLORS } from "../../constants/config";
import { MSGS_COMMON_FORM_ERROR } from "../../constants/messages";
import { analytics } from "../../firebase";

const slideOpts = {
  ease: Bounce,

  initialSlide: 0,
  speed: 500,
  loop: false,
  duration: 2500,
  pager: false,
  autoplay: true,
  zoom: {
    maxRatio: 5,
  },
};
interface PreregistrationProps {
  onGoHome: () => void;
  onPreregister: () => void;
  onGoToPatient: () => void;
  onGoToDoctor: () => void;
  onGoToHospital: () => void;
}
const Tab3: React.FC<PreregistrationProps> = (props) => {
  let t1 = new TimelineLite({ delay: 0.1 });
  const {
    onGoHome,
    onPreregister,
    onGoToPatient,
    onGoToDoctor,
    onGoToHospital,
  } = props;

  useEffect(() => {
    t1.from(
      ".banneranim",
      { y: 15, opacity: 0, ease: Power3.easeInOut, delay: 0.8 },
      "Start"
    );

    t1.from(
      ".textanim",
      { y: 20, opacity: 0, ease: Power4.easeOut, delay: 0.4 },
      "Start"
    );
    t1.from(
      ".btnanim",
      { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.7 },
      "Start"
    );
    t1.from(
      ".box",
      { x: 20, opacity: 0, ease: Power3.easeInOut, delay: 0.7 },
      "Start"
    );
    t1.from(
      ".headanim",
      { y: -100, opacity: 0, ease: Power2.easeInOut, delay: 0 },
      "Start"
    );
  }, []);

  const [showToast1, setShowToast1] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const onSubmit = async () => {
    const formattedEmailAddress = formatString(emailAddress || "");
    let errorMessage = "";
    if (_.isEmpty(formattedEmailAddress)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.emailAddress;
      setError(errorMessage);
    } else if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.invalidEmail;
      setError(errorMessage);
    }
    if (_.isEmpty(errorMessage)) {
      try {
        await services.addSubscribedEmails(formattedEmailAddress);
        setEmailAddress("");
        setSuccess(
          "Thanks for signing up! We Will notify via email for any updates."
        );
      } catch (error) {
        setError("");
      }
    }
  };

  return (
    <IonPage className="tab-container">
      <meta content="Online booking for hospital appointments, doctor consultations, check ups and more. Make your trips to hospitals near you fast, safe, and easy with Medbook" />
      <IonHeader {...(isMobile() && { mode: "ios" })}>
        <IonToolbar
          className={`${
            isMobile()
              ? "ion-padding-vertical landing-header"
              : "ion-padding-top"
          }`}
        >
          <IonTitle
            onClick={onGoHome}
            className={`ion-no-padding ${
              isMobile()
                ? "ion-padding-vertical ion-align-items-start"
                : "ion-padding-start"
            }`}
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              className={`${isMobile() ? "ion-padding-start" : "logo-padding"}`}
              src={`${process.env.PUBLIC_URL}/assets/images/Medbooklogo.svg`}
            />
          </IonTitle>

          <IonButtons
            slot="primary"
            className={` ${
              isMobile() ? "ion-align-items-end" : "ion-align-self-start"
            } ml-50`}
          >
            {/* <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-margin-right mr-10"
              // fill="outline"
              color="primary"
              routerLink={routes.LOGIN_OPTIONS}
            >
              Login
            </IonButton> */}
            <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className={`landing-dark-blue-text ${
                isMobile() ? "ion-no-padding" : "ion-padding-end"
              }`}
              fill="outline"
              color="primary"
              onClick={() => {
                analytics.logEvent("follow_us_facebook", {
                  content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                });
              }}
            >
              Follow us
              <IonIcon slot="start" icon={logoFacebook} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* <IonHeader>
      <IonToolbar className="ion-padding-top ion-padding-left">
     <a href="/tab3"></a><img  className="logo-padding"src={`${process.env.PUBLIC_URL}/assets/images/Medbooklogo.svg`}/>
      </IonToolbar>
    </IonHeader> */}

      <IonContent>
        <IonSlides
          className={`landing-slides ${isMobile() ? "mobile" : ""}`}
          pager={true}
          options={slideOpts}
        >
          {/* LANDING SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center ">
            <IonRow className="bkg-blue ion-justify-content-center  textanim   ion-padding-top ">
              <IonCol className="ion-padding-top pt-20  " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text">
                  Open Your Doors to the New Normal
                </h1>
                <p>
                  Book your next consultation, checkup, lab test, and more
                  through Medbook!
                </p>
                <IonButton
                  color="warning"
                  className="btnanim ion-text-capitalize"
                  onClick={onPreregister}
                >
                  Get Started
                </IonButton>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0 banneranim">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/landing-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          {/* PATIENT SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center">
            <IonRow className="bkg-blue ion-justify-content-center   ion-padding-top ">
              <IonCol className="ion-padding-top pt-20    textanim " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text">
                  Are you a Patient?
                </h1>
                <p>
                  Avoid the lines, reduce exposure, and track appointments
                  easily for you and your family.
                </p>
                <IonButton
                  className="landing-text-color ion-text-capitalize btnanim"
                  color="warning"
                  onClick={() => {
                    onGoToPatient();
                    analytics.logEvent("on_click_learn_more", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                      gotoPage: "patient",
                    });
                  }}
                >
                  Learn More
                </IonButton>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0 banneranim">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/patient-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          {/* HOSPITAL SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center">
            <IonRow className="bkg-blue ion-justify-content-center  ion-padding-top ">
              <IonCol className="ion-padding-top pt-20  " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text  ">
                  Hospital or Clinic
                </h1>
                <p>
                  We redefine the way you track appointments and schedules
                  through our innovative medical booking app.
                </p>
                <IonButton
                  className="landing-text-color ion-text-capitalize"
                  color="warning"
                  onClick={() => {
                    onGoToHospital();
                    analytics.logEvent("on_click_learn_more", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                      gotoPage: "hospital",
                    });
                  }}
                >
                  Learn More
                </IonButton>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/hospital-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          {/* DOCTOR SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center">
            <IonRow className="bkg-blue ion-justify-content-center  ion-padding-top ">
              <IonCol className="ion-padding-top pt-20  " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text  ">
                  Are you a Doctor?
                </h1>
                <p>
                  Take control of your daily schedules with Medbook. Organize
                  appointments and walk-in consultations efficiently.
                </p>
                <IonButton
                  className="landing-text-color ion-text-capitalize"
                  color="warning"
                  onClick={() => {
                    onGoToDoctor();
                    analytics.logEvent("on_click_learn_more", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                      gotoPage: "doctor",
                    });
                  }}
                >
                  Learn More
                </IonButton>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/doctor-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          {/* COVID SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center">
            <IonRow className="bkg-blue ion-justify-content-center  ion-padding-top ">
              <IonCol className="ion-padding-top pt-20  " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text  ">
                  #StaySafe & get the full healthcare experience
                </h1>
                <p className="word">
                  Medbook helps you #StaySafe while you get quality,
                  face-to-face consultations with your doctor.
                </p>
                <IonButton
                  className="ion-text-capitalize"
                  color="warning"
                  onClick={() => {
                    onPreregister();
                    analytics.logEvent("on_click_get_started", {
                      content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                      gotoPage: "pre-registration",
                    });
                  }}
                >
                  Get Started
                </IonButton>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/vaccine-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          {/* REGISTER SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center">
            <IonRow className="bkg-blue ion-justify-content-center  ion-padding-top ">
              <IonCol className="ion-padding-top pt-20  " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text  ">
                  Pre Register Now!
                </h1>
                <p>
                  Get early access to Medbook and keep up-to-date with the
                  latest promos, offers, and news.
                </p>
                <form>
                  <IonButton
                    color="warning"
                    onClick={() => {
                      window.location.href = routes.LANDING_PRE_REGISTER;
                      analytics.logEvent("on_click_get_started", {
                        content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                      });
                    }}
                    className="ion-margin-top ion-text-capitalize"
                  >
                    Get Started
                  </IonButton>
                </form>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/pre-register-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message="You have been successfully subscribe to Medbook."
            duration={2000}
          />
        </IonSlides>

        {/* 3 CARDS  */}
        <div className={isMobile() ? "" : "cards-footer-container"}>
          <IonRow
            className={`${isMobile() ? "abs" : "three-cards-container"} box`}
          >
            <IonCard
              data-aos="fade-left"
              className="card-no-shadow thumbs teal ion-padding ion-text-center ion-justify-content-centerripple-parent bord-rad-0  ion-activatable ion-no-margin "
            >
              <a className="pointer" onClick={onGoToPatient}>
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/heartIcon.svg`}
                />
                <h6 className="white-text">For Patient </h6>
                <IonRippleEffect></IonRippleEffect>
              </a>
            </IonCard>

            <IonCard
              data-aos="fade-up"
              className="card-no-shadow thumbs landing-dark-blue ion-padding ion-text-center ion-justify-content-center ripple-parent  bord-rad-0  ion-activatable ion-no-margin"
            >
              <a
                data-aos="fade-up"
                className="pointer"
                onClick={onGoToHospital}
              >
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/hospitalIcon.svg`}
                />
                <h6 className="white-text">For Hospitals</h6>
              </a>
              <IonRippleEffect></IonRippleEffect>
            </IonCard>

            <IonCard className="card-no-shadow thumbs lime ion-padding ion-text-center ion-justify-content-center ripple-parent  bord-rad-0  ion-activatable ion-no-margin">
              <a className="pointer" onClick={onGoToDoctor}>
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/stetoscopeIcon.svg`}
                />
                <h4 className="white-text">For Doctors</h4>
              </a>
              <IonRippleEffect></IonRippleEffect>
            </IonCard>
          </IonRow>

          <LandingFooter className={`${isMobile() ? "" : "tab3-footer"}`} />
        </div>
      </IonContent>
      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={2000}
        onDidDismiss={() => {
          setSuccess("");
          setEmailAddress("");
        }}
        color={MBCOLORS.success}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={MBCOLORS.danger}
      />
    </IonPage>
  );
};

export default Tab3;
