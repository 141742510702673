import Add from "@material-ui/icons/Add";
import React from "react";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Search from "@material-ui/icons/Search";
import MaterialTable, { Column, Icons, MTableToolbar } from "material-table";
import {
  pencilOutline,
  archiveOutline,
  eyeOutline,
  add,
  trashBin,
} from "ionicons/icons";
import { IonButton, IonIcon, IonLabel } from "@ionic/react";

import "./MBDataTable.scss";
import { MBProps, ResourceType } from "../../interface";
import { MBCOLORS } from "../../constants/config";

export interface TableState {
  columns: Array<Column<any>>;
  data: any[];
}

interface MBDataTableProps extends MBProps {
  tableType: "doctor" | "service" | "time-keeping";
  tableDataProps: TableState;
  toolbarButtonLabel: string;
  tableHeaderTitle: string;
  onClickButtonToolbar: () => void;
  onViewAction: (docId: string) => void;
  onEditService: (data: any) => void;
  onEditTimeKeeping: (data: any) => void;
  onArchivedAction: (docId: string, appointmentType: ResourceType) => void;
  onEditDoctor: (physicianId: string) => void;
}

export const MBDataTable = (props: MBDataTableProps) => {
  const {
    tableDataProps,
    toolbarButtonLabel,
    tableHeaderTitle,
    onClickButtonToolbar,
    onViewAction,
    onArchivedAction,
    onEditService,
    onEditTimeKeeping,
    onEditDoctor,
  } = props;

  const tableIcons = {
    LastPage,
    Add,
    PreviousPage: ChevronLeft,
    NextPage: ChevronRight,
    ResetSearch: Clear,
    FirstPage,
    Search,
  };
  return (
    <MaterialTable
      title={tableHeaderTitle}
      icons={tableIcons as Icons}
      columns={tableDataProps.columns}
      data={tableDataProps.data}
      options={{ actionsColumnIndex: -1, search: true, sorting: false }}
      style={{ width: "inherit" }}
      components={{
        Toolbar: (props) => (
          <div className="mb-data-table-toolbar-container">
            <div className="mb-data-table-title-search">
              <MTableToolbar {...props} />
            </div>

            <IonButton
              className="mb-data-table-add-button"
              mode="ios"
              onClick={() => {
                onClickButtonToolbar();
              }}
            >
              <IonIcon className="mb-data-table-add-icon" icon={add} />
              <IonLabel className="mb-data-table-button-label mb-body white bold">
                {toolbarButtonLabel}
              </IonLabel>
            </IonButton>
          </div>
        ),
      }}
      actions={
        !!tableDataProps && !!tableDataProps.data
          ? [
              {
                icon: (props: any) => (
                  <IonButton
                    className="mb-data-table-view-button ion-no-padding ion-no-margin"
                    fill="clear"
                  >
                    <IonIcon icon={eyeOutline} color={MBCOLORS.primary} />
                  </IonButton>
                ),
                onClick: (event, rowData) => {
                  onViewAction(rowData.docId);
                },
              },
              (rowData) => {
                return {
                  icon: (props: any) => (
                    <IonButton
                      className="ion-no-padding ion-no-margin"
                      fill="clear"
                    >
                      <IonIcon icon={pencilOutline} color={MBCOLORS.primary} />
                    </IonButton>
                  ),
                  hidden: rowData.archiveDate === undefined ? false : true,
                  onClick: (event, rowData) => {
                    //!NEVER USE THIS KIND OF TYPING EVER AGAIN. TOO CONFUSING AND UNMAINTAINABLE
                    console.log("You are editing " + rowData);
                    if (!!rowData.physicianIdNumber) {
                      onEditDoctor(rowData.physicianIdNumber);
                    } else if (!!rowData.userEmail) {
                      onEditTimeKeeping(rowData);
                    } else {
                      onEditService(rowData);
                    }
                  },
                };
              },
              (rowData) => {
                return {
                  icon: (props: any) => {
                    return (
                      <IonButton
                        className="ion-no-padding ion-no-margin"
                        fill="clear"
                      >
                        <IonIcon
                          icon={
                            rowData.forResource !== 1
                              ? trashBin
                              : archiveOutline
                          }
                          color={MBCOLORS.primary}
                        />
                      </IonButton>
                    );
                  },
                  hidden: rowData.archiveDate === undefined ? false : true,
                  onClick: (event, rowData) => {
                    onArchivedAction(rowData, rowData.forResource);
                  },
                };
              },
            ]
          : []
      }
    />
  );
};
