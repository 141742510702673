import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonIcon,
  IonButton,
  IonModal,
  IonLabel,
  IonLoading,
  IonToast,
} from "@ionic/react";

import "./MBPasswordReauthenticationDialog.scss";
import { fb, auth } from "../../firebase";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import { MBCOLORS } from "../../constants/config";
import { MSGS_COMMON, MSGS_COMMON_FORM_ERROR } from "../../constants/messages";

interface MBPasswordReauthenticationDialogProps extends MBProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  onSubmit: (success: boolean, password: string) => void;
}

export const MBPasswordReauthenticationDialog = (
  props: MBPasswordReauthenticationDialogProps
) => {
  const { isOpen, onDidDismiss, onSubmit } = props;

  const [password, setPassword] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const reauthenticate = async () => {
    try {
      setLoading(true);

      let errorMsg = "";
      if (password.trim().length === 0) {
        errorMsg = MSGS_COMMON_FORM_ERROR.password;
        setErrorMsgPassword(errorMsg);
      }

      if (_.isEmpty(errorMsg)) {
        const { authUser } = props;
        const credential = fb.auth.EmailAuthProvider.credential(
          authUser.userDetails.emailAddress,
          password
        );
        let user = auth.currentUser;
        await user!.reauthenticateWithCredential(credential);
        setSuccess("Reauthentication Success");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };
  return (
    <IonModal cssClass="mb-dialog" isOpen={isOpen} onDidDismiss={onDidDismiss}>
      <div className="modal-content">
        <div className="mb-dialog-children-container">
          <IonIcon className="mb-dialog-icon warning" />
          <IonLabel className="mb-dialog-title-label mb-h2">
            Enter password to confirm this action
          </IonLabel>
          <div className="mb-dialog-password-container">
            <MBInput
              label="Password"
              value={password}
              onChange={(password) => {
                setPassword(password);
                setErrorMsgPassword("");
              }}
              type="password"
              error={errorMsgPassword}
            />
          </div>
          <div className="mb-dialog-options-container">
            <IonButton
              onClick={onDidDismiss}
              className="mb-dialog-no-button mb-body ion-text-capitalize"
              fill="clear"
            >
              Cancel
            </IonButton>
            <IonButton
              onClick={reauthenticate}
              className="mb-dialog-submit-button mb-body bold white ion-text-capitalize"
              color={MBCOLORS.primary}
            >
              Yes
            </IonButton>
          </div>
        </div>
      </div>

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
          if (!_.isEmpty(password)) {
            onSubmit(false, "");
          }
        }}
        color={MBCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={2000}
        onDidDismiss={() => {
          setSuccess("");
          onSubmit(true, password);
        }}
        color={MBCOLORS.success}
      />
    </IonModal>
  );
};
