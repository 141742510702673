import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import {
  IonButton,
  IonModal,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";

import "./MBEmailDialog.scss";
import { MBInput } from "../MBInput/MBInput";
import { MBCOLORS } from "../../constants/config";
import { MSGS_SIGNUP } from "../../constants/messages";
import { formatString } from "../../functions/common";

interface MBEmailDialogProps {
  title: string;
  message?: string;
  isOpen: boolean;
  onDidDismiss: () => void;
  onSubmit: (emailAddress: string) => void;
  onCancel: () => void;
}

export const MBEmailDialog = (props: MBEmailDialogProps) => {
  const { isOpen, title, message, onDidDismiss, onSubmit, onCancel } = props;

  const [emailAddress, setEmailAddress] = useState("");
  const [errorMsgEmailAddress, setErrorMsgEmailAddress] = useState("");

  const submit = () => {
    const formattedEmailAddress = formatString(emailAddress || "");
    let errorMessage = "";
    if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_SIGNUP.email.required;
      setErrorMsgEmailAddress(errorMessage);
    } else if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_SIGNUP.email.invalid;
      setErrorMsgEmailAddress(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      onSubmit(formattedEmailAddress);
    }
  };

  return (
    <IonModal
      cssClass="mb-email-dialog"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <div className="modal-content">
        <div className="mb-email-dialog-children-container">
          <IonLabel className="mb-email-dialog-title-label mb-h2">
            {title}
          </IonLabel>
          {!_.isEmpty(message) && (
            <IonLabel className="mb-email-dialog-message-label mb-body">
              {message}
            </IonLabel>
          )}
          <IonGrid className="mb-email-dialog-dates-container ion-no-padding">
            <IonRow>
              <IonCol className="ion-no-margin ion-no-padding" size="12">
                <MBInput
                  label="Email Address"
                  value={emailAddress}
                  onChange={(emailAddress) => {
                    setEmailAddress(emailAddress);
                    setErrorMsgEmailAddress("");
                  }}
                  error={errorMsgEmailAddress}
                  type="text"
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="mb-email-dialog-options-container">
            <IonButton
              onClick={onCancel}
              className="mb-email-dialog-no-button mb-body ion-text-capitalize"
              fill="clear"
            >
              Cancel
            </IonButton>
            <IonButton
              onClick={submit}
              className="mb-email-dialog-submit-button mb-body bold white ion-text-capitalize"
              color={MBCOLORS.primary}
            >
              Submit
            </IonButton>
          </div>
        </div>
      </div>
    </IonModal>
  );
};
