import * as _ from "lodash";
import * as moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import {
  IonLoading,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import "./HospitalDepartment.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { DoctorHospitalAccountSetup } from "../../components/DoctorHospitalAccountSetup/DoctorHospitalAccountSetup";
import { ServiceAccountSetup } from "../../components/ServiceAccountSetup/ServiceAccountSetup";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBDialog } from "../../components/MBDialog/MBDialog";
import {
  MBDataTable,
  TableState,
} from "../../components/MBDataTable/MBDataTable";
import {
  Hospital,
  HospitalDoctorListView,
  DoctorSchedule,
  Doctor,
  TimeKeeping,
} from "../../models";
import {
  MBProps,
  AppointmentView,
  HospitalServiceSchedule,
  ResourceType,
} from "../../interface";
import { RESOURCE_TYPES } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";
import { TimeKeepingForm } from "../../components/TimeKeepingForm/TimeKeepingForm";

interface ResourceToArchiveInterface {
  id: string;
  forResource: ResourceType;
}

class HospitalDepartment extends React.Component<MBProps> {
  state = {
    viewMode: 2 as AppointmentView,
    hospital: {} as Hospital,
    hospitalServices: null as HospitalServiceSchedule[] | null,
    hospitalDoctors: null as HospitalDoctorListView[] | null,
    hospitalDoctorsNotInHospital: null as HospitalDoctorListView[] | null,
    hospitalTimeKeeping: null as TimeKeeping[] | null,
    selectedTableList: null as "doctor" | "service" | "time-keeping" | null,
    loading: false,
    tableDataProps: null as TableState | null,
    isOpenService: false,
    isOpenDoctor: false,
    resourceToArchive: {} as ResourceToArchiveInterface,
    editData: {},
    isEdit: false as boolean | null,
    isOpenResourceArchivedDialog: false,
    isOpenSuccessArchiveDialog: false,
    isOpenTimeKeeping: false,
    tableOptions: {} as {
      url: string;
      toolBarLabel: string;
      tableHeaderTitle: string;
    },
    isOpenEditDoctor: false,
    physicianId: "",
  };

  componentDidMount = () => {
    this.getHospital();
  };

  getHospital = async () => {
    try {
      const { authUser } = this.props;
      if (!_.isEmpty(authUser)) {
        try {
          const hospital = await services.getHospital(authUser.uid);

          this.setState({ hospital });
          if (!_.isNull(hospital)) {
            this.getServicesSchedule(hospital.docId as string);
            this.getDoctorHospitalList(hospital.docId || "");
            this.getHospitalTimeKeeping(hospital.docId as string);
            this.getAllDoctorsNotInHospital(hospital.docId as string);
          }
        } catch (error) {
          console.log("error -- getHospitalWithRep -- ", error);
        }
      }
    } catch (e) {
      console.log("SILENT ERROR -- ", e);
    }
  };

  getServicesSchedule = async (hospitalId: string) => {
    try {
      this.setState({ loading: true });
      const hospitalServices = await services.getHospitalServices(
        hospitalId as string
      );
      this.setState({ hospitalServices, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.log("SILENT ERROR -- ", e);
    }
  };

  getDoctorHospitalList = async (hospitalId: string) => {
    try {
      this.setState({ loading: true });
      services.getDetailedDoctorlistWithHospital(
        hospitalId,
        (hospitalDoctors) => {
          console.log("hospitalDoctors", hospitalDoctors);

          this.setColumn("doctor", hospitalDoctors);

          this.setState({ hospitalDoctors, loading: false });
        }
      );
    } catch (e) {
      this.setState({ loading: false, hospitalDoctors: [] });
      console.log("SILENT ERROR -- ", e);
    }
  };

  getAllDoctorsNotInHospital = async (hospitalId: string) => {
    try {
      this.setState({ loading: true });
      services.getAllDoctorsNotInHospital(
        hospitalId,
        (hospitalDoctorsNotInHospital) => {
          console.log(
            "hospitalDoctors not in hospital",
            hospitalDoctorsNotInHospital
          );

          this.setState({ hospitalDoctorsNotInHospital, loading: false });
        }
      );
    } catch (e) {
      this.setState({ loading: false, hospitalDoctors: [] });
      console.log("SILENT ERROR -- ", e);
    }
  };

  getHospitalTimeKeeping = async (hospitalId: string) => {
    console.log("hospitalTimeKeeping hospital -id -- ", hospitalId);
    try {
      this.setState({ loading: true });
      const hospitalTimeKeeping = await services.getHospitalTimeKeeping(
        hospitalId as string
      );

      this.setState({ hospitalTimeKeeping, loading: false });
    } catch (e) {
      this.setState({ loading: false, hospitalTimeKeeping: [] });
      console.log("SILENT ERROR -- ", e);
    }
  };

  setColumn = (
    listType: "doctor" | "service" | "time-keeping",
    dataOverride?:
      | HospitalServiceSchedule[]
      | HospitalDoctorListView[]
      | TimeKeeping[]
  ) => {
    console.log("WILL TRY TO SETUP", listType);
    this.getTableOptions(listType);
    if (listType === "service") {
      const servicesColumns = [
        {
          title: "Service Name",
          field: "treatmentName",
          render: (rowData: any) => {
            const isAvailableToday = !_.isEmpty(
              _.find(
                rowData.schedules,
                (schedule) => schedule.dayOfWeek === new Date().getDay()
              )
            );
            const isArchived = !_.isEmpty(rowData.archiveDate);
            return (
              <div className="mb-status-with-label-container">
                <div
                  className={`mb-status-indicator ${
                    isArchived
                      ? "archived"
                      : isAvailableToday
                      ? ""
                      : "not-available"
                  }`}
                >
                  <span className="tooltiptext">
                    {isArchived
                      ? "Archived"
                      : isAvailableToday
                      ? "Available"
                      : "Not Available"}
                  </span>
                </div>

                <IonLabel className="mb-data-table-label mb-h4 normal">
                  {rowData.treatmentName}
                </IonLabel>
              </div>
            );
          },
        },
        {
          title: "Contact Number",
          field: "contactNumber",
        },
        {
          title: "Location",
          field: "location",
        },
        {
          title: "Schedule",
          field: "schedule",
          render: (rowData: any) => {
            return rowData.schedule.map((schedule: any) => {
              return (
                <IonLabel className="mb-data-table-schedule-label mb-h6 blue bold">
                  {schedule}
                </IonLabel>
              );
            });
          },
        },
        {
          title: "Fee",
          field: "fee",
        },
      ];
      const servicesRowData = this.state
        .hospitalServices as HospitalServiceSchedule[];

      const newRowData = (servicesRowData || []).map((service) => {
        // this alter the schedule to show as String
        return {
          ...service,
          schedule: _.sortBy(
            (service as HospitalServiceSchedule).schedules,
            "dayOfWeek"
          ).map((schedule) => {
            return moment.weekdaysMin(schedule.dayOfWeek);
          }),
        };
      });

      const tableDataProps = {
        columns: servicesColumns,
        data: newRowData,
      };
      this.setState({ tableDataProps });
    } else if (listType === "time-keeping") {
      console.log(
        "THIS STATE TIME KEEPING -- ",
        this.state.hospitalTimeKeeping
      );
      const timeKeepingColumns = [
        {
          title: "Name",
          field: "fullName",
        },
        {
          title: "Mobile Number",
          field: "phoneNumber",
        },
        {
          title: "Designation",
          field: "designation",
        },
      ];

      const hospitalTimeKeeping = this.state
        .hospitalTimeKeeping as TimeKeeping[];

      const newRowData = hospitalTimeKeeping.map((timeKeeping) => {
        return {
          ...timeKeeping,
          fullName: `${timeKeeping.firstName} ${timeKeeping.lastName}`,
        };
      });

      const tableDataProps = {
        columns: timeKeepingColumns,
        data: newRowData,
      };
      this.setState({ tableDataProps });
    } else {
      const doctorColumns = [
        {
          title: "Name",
          field: "fullName",
          render: (rowData: any) => {
            const isAlreadyActive = _.isEmpty(rowData.initialPasswordCipher);
            return (
              <div className="mb-status-with-label-container">
                <div
                  className={`mb-status-indicator ${
                    isAlreadyActive ? "" : "not-available"
                  }`}
                >
                  <span className="tooltiptext">
                    {isAlreadyActive ? "Available" : "Account Not Activated"}
                  </span>
                </div>

                <IonLabel className="mb-data-table-label mb-h4 normal">
                  {rowData.fullName}
                </IonLabel>
              </div>
            );
          },
        },
        {
          title: "Physician ID",
          field: "physicianIdNumber",
        },
        {
          title: "Mobile Number",
          field: "phoneNumber",
        },
        {
          title: "Speciality",
          field: "specialitiesFormatted",
        },
        {
          title: "Schedule",
          field: "schedule",
          render: (rowData: any) => {
            return _.sortBy(rowData.schedule, "dayOfWeek").map(
              (schedule: DoctorSchedule) => {
                return (
                  <IonLabel className="mb-data-table-schedule-label mb-h6 blue bold">
                    {moment.weekdaysMin(schedule.dayOfWeek)}
                  </IonLabel>
                );
              }
            );
          },
        },
      ];
      const tableDataProps = {
        columns: doctorColumns,
        data: this.state.hospitalDoctors || dataOverride,
      };
      this.setState({ tableDataProps });
    }
    this.setState({ selectedTableList: listType });
  };

  refreshData = () => {
    this.setState({
      hospitalServices: null,
      hospitalDoctors: null,
      hospitalTimeKeeping: null,
      loading: true,
    });
    this.getServicesSchedule(this.state.hospital.docId || "");
    this.getDoctorHospitalList(this.state.hospital.docId || "");
    this.getHospitalTimeKeeping(this.state.hospital.docId || "");
  };

  removeDoctorOrService = async (archiveDate?: Date) => {
    const { resourceToArchive, hospital } = this.state;
    try {
      this.setState({ loading: true });
      if (!_.isEmpty(resourceToArchive)) {
        if (resourceToArchive.forResource === RESOURCE_TYPES.services.id) {
          await services.archiveService(
            resourceToArchive.id,
            hospital,
            archiveDate!
          );
        } else if (
          resourceToArchive.forResource === RESOURCE_TYPES.timeKeeping.id
        ) {
          await services.removeTimeKeeping(resourceToArchive.id);
        } else {
          await services.removeDoctor(resourceToArchive.id, hospital, "");
        }
        this.setState({ isOpenSuccessArchiveDialog: true });
      } else {
        this.setState({
          error: "Error removing resource: No resource to remove",
        });
      }
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  };

  getTableOptions = (
    selectedTableList: "doctor" | "service" | "time-keeping"
  ) => {
    switch (selectedTableList) {
      case "doctor":
        return this.setState({
          tableOptions: {
            url: "doctor",
            toolBarLabel: "Add Doctor",
            tableHeaderTitle: "Doctor List",
          },
        });
      case "service":
        return this.setState({
          tableOptions: {
            url: "service",
            toolBarLabel: "Add Out-Patient Service",
            tableHeaderTitle: "Out-Patient Service List",
          },
        });
      case "time-keeping":
        return this.setState({
          tableOptions: {
            url: "time-keeping",
            toolBarLabel: "Add Time Keeping",
            tableHeaderTitle: "Time Keeping List",
          },
        });
      default:
        return this.setState({ tableOptions: {} });
    }
  };

  render = () => {
    const {
      loading,
      selectedTableList,
      hospital,
      hospitalDoctors,
      hospitalDoctorsNotInHospital,
      tableDataProps,
      isOpenService,
      isOpenDoctor,
      resourceToArchive,
      isOpenResourceArchivedDialog,
      isOpenSuccessArchiveDialog,
      isOpenTimeKeeping,
      editData,
      isEdit,
      isOpenEditDoctor,
      physicianId,
      tableOptions,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>MedBook - Hospital - Deparment</title>
        </Helmet>
        <MBContainer {...this.props} activePage="department">
          <div className="hospital-department-header-container">
            <IonLabel className="hospital-department-header mb-h1 dark-blue">
              My Department
            </IonLabel>
            <IonItem className="hospital-department-select-list" mode="ios">
              <IonSelect
                className="hospital-department-select ion-no-padding ion-no-margin"
                interface="popover"
                mode="ios"
                value={!!selectedTableList ? selectedTableList : "doctor"}
                onIonChange={(e) => {
                  //todo: create function to set column and row data here to pass in table.
                  this.setColumn(e.detail.value);
                  // this.setState({ selectedTableList: e.detail.value })
                }}
              >
                <IonSelectOption className="mb-body" value="doctor">
                  Doctors List
                </IonSelectOption>
                <IonSelectOption className="mb-body" value="service">
                  Out-Patient Services List
                </IonSelectOption>
                <IonSelectOption className="mb-body" value="time-keeping">
                  Time Keeping List
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
          <div className="hospital-department-table-container">
            <MBDataTable
              onViewAction={(docId: string) => {
                this.props.history.push(
                  routes.HOSPITAL_DEPARTMENT_DETAIL.replace(
                    ":hospitalId",
                    hospital.docId || ""
                  )
                    .replace(
                      ":type",

                      selectedTableList === "doctor"
                        ? "doctor"
                        : tableOptions.url
                    )
                    .replace(":docId", docId || "")
                );
              }}
              onEditTimeKeeping={(data) => {
                this.setState({
                  isOpenTimeKeeping: true,
                  editData: data,
                  isEdit: true,
                });
              }}
              onEditService={(data) => {
                this.setState({
                  editData: data,
                  isEdit: true,
                  isOpenService: true,
                });
              }}
              onArchivedAction={(rowData: any, resourceType: ResourceType) => {
                this.setState({
                  resourceToArchive: {
                    id:
                      !!rowData && !!rowData.doctorHospitalId
                        ? rowData.doctorHospitalId
                        : rowData.docId,
                    forResource: resourceType,
                  },
                  isOpenResourceArchivedDialog: true,
                });
              }}
              onEditDoctor={(physicianId) => {
                this.setState({ isOpenEditDoctor: true, physicianId });
              }}
              tableType={!!selectedTableList ? selectedTableList : "doctor"}
              {...this.props}
              tableDataProps={
                !_.isEmpty(tableDataProps)
                  ? tableDataProps!
                  : ({} as TableState)
              }
              toolbarButtonLabel={
                !!selectedTableList ? tableOptions.toolBarLabel : "Add Doctor"
              }
              tableHeaderTitle={`${
                !!selectedTableList
                  ? tableOptions.tableHeaderTitle
                  : "Doctor List"
              }`}
              onClickButtonToolbar={() => {
                if (selectedTableList === "doctor") {
                  this.setState({ isOpenDoctor: true, editData: {} });
                } else if (selectedTableList === "service") {
                  this.setState({ isOpenService: true, editData: {} });
                } else if (selectedTableList === "time-keeping") {
                  this.setState({ isOpenTimeKeeping: true, editData: {} });
                }
              }}
            />
          </div>
        </MBContainer>
        {isOpenTimeKeeping && (
          <TimeKeepingForm
            editData={editData as TimeKeeping}
            isEdit={isEdit as boolean}
            isOpen={isOpenTimeKeeping}
            hospitalId={hospital.docId || ""}
            onBack={() => {
              this.setState({
                isOpenTimeKeeping: false,
                editData: {},
                isEdit: null,
              });
            }}
            onCancel={() => {}}
            onRefreshData={() => {
              this.refreshData();
              this.setState({ selectedTableList: null });
            }}
            {...this.props}
          />
        )}
        {isOpenService && (
          <ServiceAccountSetup
            editData={editData as HospitalServiceSchedule}
            isEdit={isEdit as boolean}
            isOpen={isOpenService}
            hospitalId={hospital.docId || ""}
            hospitalName={hospital.hospitalName}
            onBack={() => {
              this.setState({
                isOpenService: false,
                editData: {},
                isEdit: null,
              });
            }}
            onCancel={() => {}}
            onRefreshData={() => {
              this.refreshData();
              this.setState({ selectedTableList: null });
            }}
            {...this.props}
          />
        )}
        {isOpenDoctor && (
          <DoctorHospitalAccountSetup
            isOpen={isOpenDoctor}
            doctors={hospitalDoctorsNotInHospital as Doctor[]}
            hospitalId={hospital.docId || ""}
            hospitalName={hospital.hospitalName}
            hospitalDepartment={hospital.department}
            hospitalRepName={`${hospital.firstName} ${hospital.lastName}`}
            specialityMap={{} as any}
            onBack={() => {
              this.getHospital();
              this.setState({
                isOpenDoctor: false,
              });
            }}
            onCancel={() => {}}
            {...this.props}
          />
        )}

        <MBDialog
          isOpen={isOpenSuccessArchiveDialog}
          icon="success"
          title={` ${
            resourceToArchive.forResource !== RESOURCE_TYPES.services.id
              ? "Remove"
              : "Archive"
          } success!`}
          message={`You have successfully ${
            resourceToArchive.forResource === RESOURCE_TYPES.services.id
              ? "archived the service"
              : resourceToArchive.forResource === RESOURCE_TYPES.timeKeeping.id
              ? "removed this personnel"
              : "removed the doctor"
          }.`}
          onDidDismiss={() => {
            this.setState({
              isOpenSuccessArchiveDialog: false,
              isOpenResourceArchivedDialog: false,
            });
            this.setState({
              resourceToArchive: {} as ResourceToArchiveInterface,
            });
            this.refreshData();
          }}
        />

        <MBDialog
          isOpen={isOpenResourceArchivedDialog}
          icon="warning"
          title={`You are about to ${
            resourceToArchive.forResource === RESOURCE_TYPES.services.id
              ? "archive this service"
              : resourceToArchive.forResource === RESOURCE_TYPES.timeKeeping.id
              ? "remove this personnel"
              : "remove this doctor"
          }`}
          message={
            resourceToArchive.forResource === RESOURCE_TYPES.timeKeeping.id
              ? "This Personnel/Staff will no longer be able to login."
              : `Patients will no longer be able to book this ${
                  resourceToArchive.forResource === RESOURCE_TYPES.services.id
                    ? "service"
                    : "doctor and all appointments with the doctor will be cancelled"
                }. Are you sure you want to continue?`
          }
          onDidDismiss={() => {
            this.setState({ isOpenResourceArchivedDialog: false });
          }}
          onApprove={() => {
            this.removeDoctorOrService(new Date());
          }}
          onDecline={() => {
            this.setState({
              resourceToArchive: {} as ResourceToArchiveInterface,
            });
            this.setState({ isOpenResourceArchivedDialog: false });
          }}
        />

        {isOpenEditDoctor && (
          <DoctorHospitalAccountSetup
            {...this.props}
            isEdit={true}
            existingPhysicianIdNumber={physicianId}
            isOpen={isOpenEditDoctor}
            doctors={hospitalDoctors as Doctor[]}
            hospitalId={hospital.docId || ""}
            hospitalName={hospital.hospitalName}
            hospitalDepartment={hospital.department}
            hospitalRepName={`${hospital.firstName} ${hospital.lastName}`}
            specialityMap={{} as any}
            onBack={() => {
              this.setState({
                isOpenEditDoctor: false,
              });
              this.getDoctorHospitalList(hospital.docId || "");
            }}
            onCancel={() => {}}
          />
        )}

        <IonLoading
          translucent={false}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />
      </>
    );
  };
}

export default HospitalDepartment;
