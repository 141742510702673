export const weCanDataPrivacy = "";
// export const weCanDataPrivacy = `<html>
// <head>
//   <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
//   <style type="text/css">

//   </style>
// </head>
// <body class="c6">
//   <p class="c7"><span class="c8 c18">June 1, 2020</span></p>
//   <p class="c7 c14"><span class="c8 c18"></span></p>
//   <p class="c2"><span class="c8">DATA PRIVACY POLICY</span></p>
//   <p class="c2">
//     <span class="c1"
//       >&ldquo;PCS FOUNDATION INC&rdquo; or &ldquo;the Company&rdquo;,
//       recognizes that privacy is our right, and thus we want you to be
//       familiar with how we collect, use and disclose data.</span
//     >
//   </p>
//   <p class="c2"><span class="c8">COLLECTION OF PERSONAL DATA</span></p>
//   <ol class="c12 lst-kix_list_1-0 start" start="1">
//     <li class="c7 c16">
//       <span class="c1"
//         >The personal data we collect are information that identify you as
//         Data Subject as a user of our WeCAN application, we collect Personal
//         Data in accordance with law, such as:</span
//       >
//     </li>
//     <li class="c7 c16">
//       <span class="c1"
//         >Personal Information - refers to any information or set of
//         information in any form that would directly identify you. These may
//         include your name, contact number, address and email address.</span
//       >
//     </li>
//     <li class="c7 c16">
//       <span class="c15">Special information Required for</span
//       ><span class="c10">&#8203;&#8203;</span><span class="c15">&nbsp;</span
//       ><span class="c10">&#8203;</span
//       ><span class="c1"
//         >WeCAN&nbsp;application, such travel information, recent area visited,
//         health status, particularly on potential symptoms for COVID-19, and
//         the duration these symptoms have been manifesting and pre-existing
//         health conditions that can be classified as comorbidities for COVID-19
//         infections.</span
//       >
//     </li>
//   </ol>
//   <p class="c2 c14"><span class="c1"></span></p>
//   <p class="c2">
//     <span class="c1"
//       >These data are intended purely to ensure WECAN runs as intended and the
//       result can become helpful to you and to the relevant government
//       agencies&rsquo; containment efforts of the pandemic.</span
//     >
//   </p>
//   <p class="c2"><span class="c8">HOW WE COLLECT OTHER DATA</span></p>
//   <p class="c2">
//     <span class="c1"
//       >BROWSING - we collect general information about you or your activities
//       through which you cannot be identified. When you use the Internet, your
//       web browser or client software may transmit certain kinds of information
//       to the servers that host the websites you visit. The information may
//       include the IP address or unique numbers assigned to your server or
//       Internet connection, the capabilities and features of your computer,
//       your geographic location, and your movement within and interaction with
//       the website, including what pages you look at and what information you
//       download. When you visit PCS FOUNDATION websites, its servers collect
//       such general information. PCS FOUNDATION uses this general information
//       to generate aggregate statistics about visitors to its website. In
//       situations where it is possible to do so, general information may be
//       linked to personal information.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >COOKIES - PCS FOUNDATION&rsquo;s website uses cookie technology.
//       Cookies are identifying pieces of information that are transferred to
//       your computer hard drive by our website. Cookies helps identify you when
//       you access its website. Cookies do not allow to access any personal
//       information about you, but they do allow it to better understand your
//       use of our website so that it may improve its website. PCS
//       FOUNDATION&rsquo;s website uses cookies to provide a unique identifier
//       to your computer so that it can generate statistics about usage of its
//       website, such as the percentage of repeat visitors and other general
//       statistics. The unique identifiers are not matched with any personal
//       information. Cookies do not store any personal information about you.
//       You can configure your browser to allow you to browse the Internet and
//       PCS FOUNDATION&rsquo;s website without cookies or to notify you when
//       each cookie is offered so that you can accept or reject each cookie. You
//       may not be able to use some services on our website if you disable your
//       browser from accepting cookies.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >PCS FOUNDATION strives to maintain the highest standards of decency,
//       fairness and integrity in all its operations. Likewise, it is dedicated
//       to protecting its customers&rsquo; and online visitors&rsquo; privacy on
//       its website. Browsing PCS FOUNDATION&rsquo;s website will not result in
//       your computer getting infected by spyware, adware, viruses or other
//       forms of hostile or intrusive software. PCS FOUNDATION does not phish,
//       harm or participate in any illicit or identity theft activities.</span
//     >
//   </p>
//   <p class="c2"><span class="c8">THIRD PARTY LINKS/SITES</span></p>
//   <p class="c2">
//     <span class="c1"
//       >PCS FOUNDATION is not responsible for the privacy policies and
//       procedures of third-party sites that may link to PCS FOUNDATION
//       websites, or PCS FOUNDATION may link to as part of past or present
//       business relationships or initiatives. Please review the privacy
//       policies of any linked sites you visit before using or providing
//       information to any of those sites.</span
//     >
//   </p>
//   <p class="c2"><span class="c8">HOW WE USE YOUR INFORMATION</span></p>
//   <p class="c2">
//     <span class="c1"
//       >PCS FOUNDATION may use the collected information for use in the
//       application, but not limited to notify you of important medical
//       notification or advice that we deem important for you, or as advised by
//       authorities.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c8">DISCLOSURE OF COLLECTED INFORMATION</span>
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >Except as outlined in this Privacy Policy, PCS FOUNDATION will not
//       sell, trade, or disclose to third parties any personal information
//       obtained through its website without your consent.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1">PCS FOUNDATION may disclose Personal Information:</span>
//   </p>
//   <ul class="c12 lst-kix_list_2-0 start">
//     <li class="c7 c9">
//       <span class="c1"
//         >to assist relevant government agencies in containing the pandemic,
//         such as efforts in contact tracing, enforcement of quarantine,
//         tracking of distribution of aids due to pandemic and the timely update
//         of the CoVID-19 statistics.</span
//       >
//     </li>
//     <li class="c7 c9">
//       <span class="c1"
//         >to comply with a court order, subpoena, search warrant or other legal
//         process</span
//       >
//     </li>
//     <li class="c7 c9">
//       <span class="c1"
//         >to protect and defend PCS FOUNDATION, its parent, subsidiaries and
//         affiliates, and all of their officers, directors, employees,
//         attorneys, agents, contractors, and partners, in connection with any
//         legal action, claim or dispute</span
//       >
//     </li>
//     <li class="c7 c9">
//       <span class="c1">to enforce the terms of our website</span>
//     </li>
//     <li class="c7 c9">
//       <span class="c1">to prevent imminent physical harm</span>
//     </li>
//     <li class="c7 c9">
//       <span class="c1"
//         >in connection with the sale, assignment, or transfer of any
//         subsidiary or affiliate business</span
//       >
//     </li>
//   </ul>
//   <p class="c2 c14"><span class="c1"></span></p>
//   <p class="c2"><span class="c1">CHOOSING TO PROVIDE INFORMATION</span></p>
//   <p class="c2">
//     <span class="c1"
//       >Providing personal information is required through PCS FOUNDATION
//       website to be able to provide you with our products and services we
//       offer. You may opt not provide any personal information; however, we
//       cannot guarantee access or quality of service given to you.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >If you submit any personal information in behalf of another person, you
//       represent that you have the authority to provide such information and to
//       permit us to use said personal information in accordance with this
//       Privacy Notice.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1">HOW WE STORE AND SECURE YOUR INFORMATION</span>
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >Personal information collected by the website is stored on secure
//       servers. The secure servers are protected by firewalls and other
//       standard security procedures. The secure servers are not generally
//       accessible by unauthorized third parties, but could become accessible in
//       the event of a security breach.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >YOUR RIGHTS AND PREFERENCES UNDER THE DATA PRIVACY ACT OF 2012</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >You acknowledge that you have a full understanding of and completely
//       agree to giving your consent to PCS FOUNDATION and/or its operating and
//       related companies to collect, store, access, share, process, and/or
//       destroy copies of your personal data. You further agree and give consent
//       to the sharing of all your personal information with third parties, when
//       required by the law or public authority in connection with the
//       abovementioned purposes; and warrant that you are fully aware and
//       completely understand your rights under the Data Privacy Act, including
//       the right to request access to your personal, sensitive and/or
//       privileged data, as well as to move for the correction of the same, if
//       said data is already inaccurate and/or outdated</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >PCS FOUNDATION shall safeguard the confidentiality of all types of
//       personal data it has collected, stored, shared or used and treat them
//       with reasonable and appropriate degree of protection.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >PCS FOUNDATION is keen to protect your privacy rights.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >You have the right to be informed for which your personal data is
//       collected You have the right to access your personal data with us and
//       you right to rectify should you find that your personal data needs
//       updating. We respect the exercise of said rights provided that the
//       accompanying request is not vexatious and unreasonable.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >You have the right to object to processing of your personal data or
//       withhold consent previously given. Likewise, you have the right to
//       erasure or blocking of your personal data. Should you exercise these
//       rights, we will be constrained to limit your access to its facilities
//       and/or quality service.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1">You have the right to data portability; and</span>
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >You have the right to damages CHOICE, ACCESS AND RETENTION</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >You have choices when it comes to how we use your data and we want to
//       ensure</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >you have the information to make the choices that are right for
//       you.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >If you no longer want to receive marketing-related emails, you may opt
//       out by visiting our unsubscribe page.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >We will try to comply with your request as soon as reasonably
//       practicable. If you opt out of receiving marketing emails from us, we
//       may still send you important administrative messages, from which you
//       cannot opt out.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >HOW CAN YOU REQUEST TO ACCESS, CHANGE, DELETE, RESTRICT THE USE OR
//       OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c15"
//       >If you would like to request to access, change, delete, restrict the
//       use or object to the processing of your Personal Data that you have
//       previously provided to us, or if you would like to receive an electronic
//       copy of your Personal Data for purposes of transmitting it to another
//       company, please complete access form. If you have any questions about
//       the form or our process, feel free to email us at </span
//     ><span class="c8">hello.pcscancom@gmail.com</span>
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >For your protection, we may need to verify your identity before
//       fulfilling your request. We will try to comply with your request as soon
//       as reasonably practicable and consistent with applicable law.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >Please note that we often need to retain certain data for recordkeeping
//       purposes and/or to complete any transactions that you began prior to
//       requesting a change or deletion (e.g., when you make a purchase or
//       reservation, or enter a promotion, you may not be able to change or
//       delete the Personal Data provided until after the completion of such
//       purchase, reservation, or promotion). There may also be residual data
//       that will remain within our databases and other records, which will not
//       be removed. In addition, there may be certain data that we may not allow
//       you to review for legal, security, or other reasons.</span
//     >
//   </p>
//   <p class="c2"><span class="c1">RETENTION</span></p>
//   <p class="c2">
//     <span class="c1"
//       >We will retain your Personal Data for the period necessary to fulfill
//       the purposes outlined in this Privacy Statement unless a longer
//       retention period is required or permitted by law.</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >The criteria used to determine our retention periods include:</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >The length of time we have an ongoing relationship with you and provide
//       the Services to you (for example, for as long as you have an account
//       with us or keep using our Services)</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >Whether there is a legal obligation to which we are subject (for
//       example, certain laws require us to keep records of your transactions
//       for a certain period of time before we can delete them)</span
//     >
//   </p>
//   <p class="c2">
//     <span class="c1"
//       >Whether retention is advisable considering our legal position (such as,
//       for statutes of limitations, litigation or regulatory
//       investigations)</span
//     >
//   </p>
//   <p class="c2"><span class="c1">SENSITIVE DATA</span></p>
//   <p class="c2">
//     <span class="c1"
//       >Unless specifically requested, we ask that you not send us, and you not
//       disclose, on or through the Services or otherwise to us, any Sensitive
//       Personal Data (e.g., social security number, taxpayer identification
//       number, passport number, driver&rsquo;s license number or other
//       government- issued identification number; credit or debit card details
//       or financial account number, with or without any code or password that
//       would permit access to the account, credit history; or information on
//       race, religion, ethnicity, sex life or practices or sexual orientation,
//       medical or health information, genetic or biometric information,
//       biometric templates, political or philosophical beliefs, political party
//       or trade union membership, background check information, judicial data
//       such as criminal records or information on other judicial or
//       administrative proceedings)</span
//     >
//   </p>
//   <p class="c2"><span class="c1">UPDATES TO THIS PRIVACY STATEMENT</span></p>
//   <p class="c2">
//     <span class="c1"
//       >Any changes will become effective when we post the revised Privacy
//       Statement on the Online Services. Your use of the Services following
//       these changes means that you accept the revised Privacy Statement.</span
//     >
//   </p>
//   <p class="c2"><span class="c8">CONTACTING US</span></p>
//   <p class="c2">
//     <span class="c1"
//       >If you have any questions about this Privacy Statement, please contact
//       us by email.</span
//     >
//   </p>
//   <p class="c7 c14"><span class="c1"></span></p>
//   <p class="c7"><span class="c4">Miko L. Balisi</span></p>
//   <p class="c7"><span class="c17">&#8203;Data Privacy Officer</span></p>
//   <p class="c7 c14"><span class="c11"></span></p>
//   <p class="c7">
//     <span class="c0">+63 956 3852108</span><span><br /></span
//     ><span class="c0">alt. email: </span
//     ><span class="c0 c3"
//       ><a class="c5" href="mailto:mikolumacad.bal@student.mahidol.edu"
//         >mikolumacad.bal@student.mahidol.edu</a
//       ></span
//     ><span
//       ><a class="c5" href="mailto:mikolumacad.bal@student.mahidol.edu"
//         ><br /></a></span
//     ><span class="c0">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span
//     ><span class="c3 c0"
//       ><a class="c5" href="mailto:miko@devhealth.org"
//         >miko@devhealth.org</a
//       ></span
//     >
//   </p>
// </body>
// </html>
// `;
