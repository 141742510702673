import { Helmet } from "react-helmet";
import React from "react";
import { Link } from "react-router-dom";
import {
  IonCard,
  IonLabel,
  IonCardContent,
  IonButton,
  IonIcon,
  IonPage,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";

import "./LoginOptions.scss";
import * as pjson from "../../../package.json";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { analytics } from "../../firebase";
import { isMobile } from "../../functions/common";
import { MBProps } from "../../interface";
import { MBCOLORS, ANALYTICS_CONTENT_TYPES } from "../../constants/config";
import { MBHeader } from "../../components/MBHeader/MBHeader";
import { MBFooter } from "../../components/MBFooter/MBFooter";

class LoginOptions extends React.Component<MBProps> {
  state = {
    hidden: "",
  };

  render = () => {
    const mbVersion = pjson.version.split(".").splice(0, 2).join(".");
    return (
      <>
        <Helmet>
          <title>MedBook</title>
        </Helmet>
        <IonPage>
          <MBHeader {...this.props} />
          <IonContent className="login-options-bg-card ion-no-padding ion-no-margin">
            <div
              className={`login-options-bg-card-container ${
                isMobile() ? "mobile" : ""
              }`}
            >
              <IonGrid className="login-options-grid ion-no-padding ion-no-margin">
                <IonRow className="login-options-row ion-no-padding ion-no-margin">
                  {!isMobile() && (
                    <IonCol
                      size="8"
                      className="login-options-bg-image"
                    ></IonCol>
                  )}
                  <IonCol
                    size={isMobile() ? "12" : "4"}
                    className={`login-options-card-col`}
                  >
                    {isMobile() && (
                      <div className="login-options-bg-image mobile-bg" />
                    )}
                    <IonCard
                      className={`login-options-card ${
                        isMobile() ? "mobile" : ""
                      } ion-no-margin`}
                      color={MBCOLORS.tertiary}
                    >
                      <IonCardContent
                        className={`login-options-card-content ${
                          isMobile() && "mobile"
                        } ion-no-padding`}
                      >
                        <IonIcon
                          className={`login-options-card-logo ${
                            isMobile() ? "mobile mb-h3" : "mb-h2"
                          }`}
                        />
                        <IonLabel
                          className={`login-options-text-header ${
                            isMobile() && "mobile"
                          } mb-h2`}
                        >
                          Open Your Doors to the New Normal
                        </IonLabel>
                        <IonLabel className="login-options-text-description mb-body medium-dark">
                          Medbook is a real-time, integrated, centralized
                          medical booking platform for patients, hospitals and
                          physicians.
                        </IonLabel>
                        <div className="login-options-details">
                          <IonButton
                            color={MBCOLORS.primary}
                            className="login-options-create-button mb-body white bold ion-text-capitalize"
                            routerLink={routes.LOGIN_WITH_EMAIL}
                            onClick={() => {
                              analytics.logEvent("select_login_option", {
                                content_type:
                                  ANALYTICS_CONTENT_TYPES.login.type,
                                event_value: "email",
                              });
                            }}
                          >
                            Login / Create account
                          </IonButton>
                          <IonIcon className="login-options-divider" />
                          <IonButton
                            onClick={() => {
                              services.signInWithGoogle();
                              analytics.logEvent("select_login_option", {
                                content_type:
                                  ANALYTICS_CONTENT_TYPES.login.type,
                                event_value: "gmail",
                              });
                            }}
                            className="login-options-gmail-button mb-body white bold ion-text-capitalize"
                          >
                            <IonIcon className="gmail-icon" />
                            Login using Gmail
                          </IonButton>

                          <IonLabel
                            className={`login-options-agreement-label ${
                              isMobile() && "mobile"
                            } mb-paragraph light`}
                          >
                            By clicking any of the options above, I hereby agree
                            and consent to the
                            <Link
                              className="user-agreement-link mb-paragraph light ion-text-capitalize ion-no-margin ion-no-padding"
                              to={routes.TERMS_CONDITION}
                              target="_blank"
                            >
                              User Agreement
                            </Link>
                            .
                          </IonLabel>
                          <IonLabel className="mb-version mb-h6 xsmall light">
                            {"MedBook v" + mbVersion}
                          </IonLabel>
                        </div>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonContent>
          <MBFooter />
        </IonPage>
      </>
    );
  };
}

export default LoginOptions;
