import * as _ from "lodash";
import React, { useState, useRef } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSlides,
  IonSlide,
  IonButton,
  IonIcon,
  IonLoading,
  IonToast,
  IonModal,
  IonSearchbar,
} from "@ionic/react";

import "./HospitalApprovedApplicationsList.scss";
import * as services from "../../services";
import { analytics } from "../../firebase";
import { useEffectOnlyOnce, isMobile } from "../../functions/common";
import { Hospital } from "../../models";
import { HospitalApplicationDetails } from "../HospitalApplicationDetails/HospitalApplicationDetails";
import { MBAppointmentCard } from "../MBAppointmentCard/MBAppointmentCard";
import { MBProps } from "../../interface";
import { MSGS_COMMON } from "../../constants/messages";
import { MBCOLORS, ANALYTICS_CONTENT_TYPES } from "../../constants/config";

export const HospitalApprovedApplicationsList = (props: MBProps) => {
  const slidesRef: React.RefObject<HTMLIonSlidesElement> = useRef(null);

  const [formPage, setFormPage] = useState(0);
  const [lastSlideUserIsOn, setLastSlideUserIsOn] = useState(
    null as number | null
  );
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [applicationList, setApplicationList] = useState(
    null as Hospital[] | null
  );

  const [
    isViewApplicationDialogOpen,
    setIsViewApplicationDialogOpen,
  ] = useState(false);

  const [applicationData, setApplicationData] = useState(
    null as Hospital | null
  );
  const [filteredApplicationList, setFilteredApplicationList] = useState(
    [] as Hospital[]
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffectOnlyOnce(() => {
    getHospitalApplications();
  });

  const getHospitalApplications = async () => {
    try {
      setLoading(true);
      services.getHospitalApplications((applications) => {
        setApplicationList([]);
        setApplicationList(applications);
        setLoading(false);
      }, true);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  const goToPage = (page: number) => {
    if (!_.isNull(slidesRef.current)) {
      slidesRef.current.lockSwipes(false);
      slidesRef.current.slideTo(page);
      slidesRef.current.lockSwipes(true);

      setLastSlideUserIsOn(page);
    }
  };

  const filterApplications = (query: string) => {
    if (!_.isEmpty(query)) {
      let searchQuery = query.trim().toLowerCase();
      const filteredApplications = _.filter(applicationList, (application) => {
        return (
          application.hospitalName.trim().toLowerCase().indexOf(searchQuery) !==
            -1 ||
          application.province.trim().toLowerCase().indexOf(searchQuery) !==
            -1 ||
          application.cityMunicipality
            .trim()
            .toLowerCase()
            .indexOf(searchQuery) !== -1
        );
      });
      setFilteredApplicationList(filteredApplications);
    } else {
      setFilteredApplicationList([]);
    }
  };

  return (
    <MBAppointmentCard
      mode="hospital-applications"
      title="Approved Hospitals"
      titleDescription="Hospital Representatives List"
      onSubmit={() => {}}
      cssCardWidth="support-hospital-card"
      showActionButton={true}
    >
      <div className="hospital-applications-search-input-container">
        <IonSearchbar
          mode="ios"
          placeholder=""
          onIonChange={(e) => filterApplications(e.detail.value!)}
          className="hospital-applications-search-input ion-no-margin ion-no-padding ion-text-start"
        />
      </div>

      <IonGrid className="hospital-applications-table-header-grid ion-no-padding ion-no-margin">
        <IonRow className="hospital-applications-table-header-row">
          <IonCol
            className="hospital-applications-table-header-col ion-no-padding ion-no-margin ion-text-start"
            size={`${isMobile() ? "12" : "3.5"}`}
          >
            <IonLabel className="mb-paragraph bold">Hospital</IonLabel>
          </IonCol>
          {!isMobile() && (
            <>
              <IonCol
                className="hospital-applications-table-header-col ion-no-padding ion-no-margin ion-text-start"
                size="2"
              >
                <IonLabel className="mb-paragraph bold">Department</IonLabel>
              </IonCol>
              <IonCol
                className="hospital-applications-table-header-col ion-no-padding ion-no-margin ion-text-start"
                size="6.5"
              >
                <IonLabel className="mb-paragraph bold">Location</IonLabel>
              </IonCol>
            </>
          )}
        </IonRow>
      </IonGrid>
      {!_.isNull(applicationList) && (
        <IonSlides
          onIonSlideDidChange={async (event) => {
            if (!_.isNull(slidesRef)) {
              const activeIndex = await slidesRef.current!.getActiveIndex();
              setFormPage(activeIndex);
            }
          }}
          onIonSlidesDidLoad={async () => {
            if (!_.isNull(slidesRef)) {
              slidesRef.current!.lockSwipes(true);
              const activeIndex = await slidesRef.current!.getActiveIndex();
              const lengthOfPage = _.chunk(
                !_.isEmpty(filteredApplicationList)
                  ? filteredApplicationList
                  : applicationList,
                5
              ).length;
              setNumberOfPages(lengthOfPage);
              setFormPage(activeIndex);

              if (!_.isNull(lastSlideUserIsOn)) {
                goToPage(lastSlideUserIsOn);
              }
            }
          }}
          ref={slidesRef}
          key={(!_.isEmpty(filteredApplicationList)
            ? filteredApplicationList
            : applicationList
          )
            .map((application) => application.docId || "")
            .join("_")}
          className="hospital-applications-slides-container"
        >
          {!_.isEmpty(applicationList) ? (
            _.chunk(
              !_.isEmpty(filteredApplicationList)
                ? filteredApplicationList
                : applicationList,
              5
            ).map((row, index) => {
              return (
                <IonSlide
                  id="hospital-application-list"
                  className="ion-no-padding ion-no-margin"
                  tabIndex={index}
                  key={`row_${index}`}
                >
                  <IonGrid className="hospital-application-list-grid ion-no-padding ion-no-margin">
                    {row.map((hospitalApplication, index) => {
                      return (
                        <IonRow
                          key={hospitalApplication.docId || ""}
                          className={`hospital-application-list-row ${
                            index === 4 ? "last" : ""
                          }`}
                        >
                          <IonCol
                            className="hospital-application-list-col ion-no-padding ion-no-margin ion-text-start"
                            size={`${isMobile() ? "9" : "3.5"}`}
                          >
                            <IonLabel className="mb-paragraph">
                              {`${hospitalApplication.hospitalName}`}
                            </IonLabel>
                          </IonCol>
                          {!isMobile() && (
                            <>
                              <IonCol
                                className="hospital-application-list-col ion-no-padding ion-no-margin ion-text-start"
                                size="2"
                              >
                                <IonLabel className="mb-paragraph">
                                  {hospitalApplication.department}
                                </IonLabel>
                              </IonCol>
                              <IonCol
                                className="hospital-application-list-col ion-no-padding ion-no-margin ion-text-start"
                                size="5"
                              >
                                <IonLabel className="mb-paragraph">
                                  {hospitalApplication.province +
                                    " " +
                                    hospitalApplication.cityMunicipality}
                                </IonLabel>
                              </IonCol>
                            </>
                          )}
                          <IonCol
                            className="hospital-application-list-col ion-no-padding ion-no-margin"
                            size={`${isMobile() ? "3" : "1.5"}`}
                          >
                            <IonButton
                              color={MBCOLORS.tertiary}
                              expand="full"
                              shape="round"
                              className="hospital-application-view-button ion-text-capitalize"
                              onClick={() => {
                                setApplicationData(hospitalApplication);
                                setIsViewApplicationDialogOpen(true);
                                analytics.logEvent("view_approved_hospitals", {
                                  content_type:
                                    ANALYTICS_CONTENT_TYPES.supportAccount.type,
                                });
                              }}
                            >
                              View
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      );
                    })}
                  </IonGrid>
                </IonSlide>
              );
            })
          ) : (
            <div className="hospital-application-list-no-schedule-container">
              <IonLabel className="hospital-application-list-label mb-h3 bold">
                No existing approved applications
              </IonLabel>
            </div>
          )}
        </IonSlides>
      )}

      {!_.isEmpty(applicationList) && (
        <div className="hospital-application-list-pagination-container">
          <IonIcon
            className="hospital-application-list-previous-icon"
            onClick={() => {
              goToPage(formPage - 1);
            }}
          />
          <IonLabel className="mb-body bold">{`Page ${
            formPage + 1
          } of ${numberOfPages}`}</IonLabel>
          <IonIcon
            className="hospital-application-list-next-icon"
            onClick={() => {
              goToPage(formPage + 1);
            }}
          />
        </div>
      )}
      {!!applicationData && (
        <IonModal
          cssClass="mb-application-details-dialog"
          isOpen={isViewApplicationDialogOpen}
          onDidDismiss={() => {
            setIsViewApplicationDialogOpen(false);
          }}
        >
          <HospitalApplicationDetails
            applicationData={applicationData}
            onCancel={() => {
              setIsViewApplicationDialogOpen(false);
            }}
            readonly={true}
          />
        </IonModal>
      )}

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </MBAppointmentCard>
  );
};
