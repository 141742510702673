import { Helmet } from "react-helmet";
import React from "react";
import { IonCard } from "@ionic/react";

import "./DataPrivacy.scss";
import { isMobile } from "../../functions/common";
import { weCanDataPrivacy } from "./WeCANDataPrivacy";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";

class DataPrivacy extends React.Component<MBProps> {
  render = () => {
    return (
      <>
        <Helmet>
          <title>MedBook - Privacy Notice</title>
        </Helmet>
        <MBContainer {...this.props}>
          <IonCard className={`data-privacy-card ${isMobile() && "mobile"}`}>
            <div
              className="data-privacy-html mb-body"
              dangerouslySetInnerHTML={{
                __html: weCanDataPrivacy,
              }}
            ></div>
          </IonCard>
        </MBContainer>
      </>
    );
  };
}

export default DataPrivacy;
