/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonModal,
  IonButton,
} from "@ionic/react";

import "./ExecutiveAssistantFormDialog.scss";
import * as api from "../../api";
import * as services from "../../services";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import {
  formatString,
  isMobile,
  useEffectOnlyOnce,
} from "../../functions/common";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import {
  MBDropdownSelectOption,
  MBDropdownSelect,
} from "../MBDropdownSelect/MBDropdownSelect";
import { MBCOLORS } from "../../constants/config";
import { MSGS_COMMON, MSGS_DOCTOR } from "../../constants/messages";

interface ExecutiveAssistantFormDialogProps extends MBProps {
  onCancel: () => void;
  doctorHospitalOptions: MBDropdownSelectOption[];
  isOpen: boolean;
  existingExecutiveAssistantIds: string[];
  existingExecutiveAssistantHospitalID?: string;
}

export const ExecutiveAssistantFormDialog = (
  props: ExecutiveAssistantFormDialogProps
) => {
  const {
    isOpen,
    doctorHospitalOptions,
    existingExecutiveAssistantIds,
    existingExecutiveAssistantHospitalID,
    onCancel,
  } = props;
  const isView = !_.isEmpty(existingExecutiveAssistantHospitalID);

  const [
    existingExecutiveAssistantId,
    setExistingExecutiveAssistantId,
  ] = useState("");

  const [hospitalUID, setHospitalUID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  const [selectedDoctorHospital, setSelectedDoctorHospital] = useState(
    doctorHospitalOptions[0]
  );

  const [errorMsgHospitalUID, setErrorMsgHospitalUID] = useState("");
  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgMobileNumber, setErrorMsgMobileNumber] = useState("");
  const [errorMsgEmailAddress, setErrorMsgEmailAddress] = useState("");
  const [isAssistantExistInHospital, setIsAssistantExistInHospital] = useState(
    false
  );

  const [isSearching, setIsSearching] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffectOnlyOnce(() => {
    if (!_.isEmpty(existingExecutiveAssistantHospitalID)) {
      checkIfExecutiveAssistant(existingExecutiveAssistantHospitalID!);
    }
  });

  const checkIfExecutiveAssistant = async (hospitalUID: string) => {
    try {
      setHospitalUID(hospitalUID);
      setIsSearching(true);

      const existingExecutiveAssistant = await services.findExecutiveAssistantByHospitalUID(
        hospitalUID
      );

      console.log("GOT EXISTING ASSISTANT", existingExecutiveAssistant);

      if (!_.isEmpty(existingExecutiveAssistant)) {
        if (
          !existingExecutiveAssistantIds.includes(
            existingExecutiveAssistant.docId || ""
          ) ||
          isView
        ) {
          setExistingExecutiveAssistantId(
            existingExecutiveAssistant.docId || ""
          );
          setFirstName(existingExecutiveAssistant.firstName);
          setLastName(existingExecutiveAssistant.lastName);
          setEmailAddress(existingExecutiveAssistant.emailAddress);
          setMobileNumber(existingExecutiveAssistant.phoneNumber);
          setReadOnly(true);

          setErrorMsgHospitalUID("");
          setIsAssistantExistInHospital(false);
        } else {
          setErrorMsgHospitalUID(MSGS_DOCTOR.executiveAssistantExist);
          setIsAssistantExistInHospital(true);
        }
      }

      setIsSearching(false);
    } catch (e) {
      setError(e);
      setIsSearching(false);
      setIsAssistantExistInHospital(false);
    }
  };

  const onSubmit = async () => {
    const formattedHospitalUID = formatString(hospitalUID || "");
    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedMobileNumber = formatString(mobileNumber || "") as string;
    const formattedEmailAddress = formatString(emailAddress || "");

    let errorMessage = null;

    if (formattedHospitalUID.length === 0) {
      errorMessage = MSGS_DOCTOR.physicianId;
      setErrorMsgHospitalUID(errorMessage);
    }
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_DOCTOR.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_DOCTOR.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (!validatePhoneNumber(formattedMobileNumber)) {
      errorMessage = MSGS_DOCTOR.invalidMobileNumber;
      setErrorMsgMobileNumber(errorMessage);
    }
    if (formattedMobileNumber.length === 0) {
      errorMessage = MSGS_DOCTOR.mobileNumber;
      setErrorMsgMobileNumber(errorMessage);
    }
    if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_DOCTOR.email.required;
      setErrorMsgEmailAddress(errorMessage);
    }
    if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_DOCTOR.email.invalid;
      setErrorMsgEmailAddress(errorMessage);
    }

    if (_.isEmpty(errorMessage) && !isAssistantExistInHospital) {
      try {
        setSaving(true);
        const { authUser } = props;

        if (_.isEmpty(existingExecutiveAssistantId)) {
          await api.addExecutiveDoctor(
            formattedHospitalUID,
            formattedFirstName,
            formattedLastName,
            formattedEmailAddress,
            formattedMobileNumber,
            `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
            selectedDoctorHospital.id,
            selectedDoctorHospital.metadata.hospital.hospitalName
          );
        } else {
          await services.addExistingExecutiveAssistant(
            existingExecutiveAssistantId,
            selectedDoctorHospital.id
          );
        }

        setSuccess(MSGS_DOCTOR.addExecAssistantSuccesTitle);
        setSaving(false);
      } catch (e) {
        setError(e);
        setSaving(false);
      }
    } else {
      setSaving(false);
    }
  };

  return (
    <>
      <IonModal
        cssClass="executive-account-form-modal"
        isOpen={isOpen}
        onDidDismiss={onCancel}
      >
        <IonLabel className="executive-account-form-header mb-h2 dark-blue ion-text-start">
          {!isView ? "Add Executive Assistant" : "Executive Assistant Details"}
        </IonLabel>
        <MBDivider />
        <div className="executive-account-form-setup-form-container">
          <div
            className={`executive-account-form-setup-form-description-container ${
              isMobile() && "mobile"
            }`}
          ></div>
          <IonGrid className="executive-account-form-setup-form-container-grid ion-no-padding">
            <IonRow>
              <IonCol
                className={`executive-account-form-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <div className="executive-account-form-setup-form-container-col-dropdown-container">
                  <MBInput
                    label="Hospital ID number"
                    value={hospitalUID.replace(/^0+/, "")}
                    type="text"
                    error={errorMsgHospitalUID}
                    debounce={100}
                    onChange={(hospitalUID) => {
                      // checkPhysicianIdNumberExist(
                      //   physicianIdNumber.replace(/^0+/, "")
                      // );
                      checkIfExecutiveAssistant(hospitalUID);
                    }}
                  />
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className={`executive-account-form-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <MBInput
                  readonly={readOnly || isSearching}
                  label="First name"
                  value={firstName}
                  type="text"
                  error={errorMsgFirstName}
                  onChange={(firstName) => {
                    setFirstName(firstName);
                    setErrorMsgFirstName("");
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className={`executive-account-form-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <MBInput
                  readonly={readOnly || isSearching}
                  label="Last name"
                  value={lastName}
                  type="text"
                  error={errorMsgLastName}
                  onChange={(lastName) => {
                    setLastName(lastName);
                    setErrorMsgLastName("");
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className={`executive-account-form-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <MBInput
                  readonly={readOnly || isSearching}
                  label="Mobile number"
                  value={mobileNumber}
                  type="tel"
                  error={errorMsgMobileNumber}
                  onChange={(mobileNumber) => {
                    setMobileNumber(mobileNumber);
                    setErrorMsgMobileNumber("");
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className={`executive-account-form-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <MBInput
                  readonly={readOnly || isSearching}
                  label="Email address"
                  value={emailAddress}
                  type="email"
                  error={errorMsgEmailAddress}
                  onChange={(emailAddress) => {
                    setEmailAddress(emailAddress);
                    setErrorMsgEmailAddress("");
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className="executive-account-form-setup-form-container-col  last ion-no-padding"
                size="12"
              >
                <div className="executive-account-form-setup-form-select-container">
                  <MBDropdownSelect
                    label="Hospital"
                    value={
                      !_.isEmpty(selectedDoctorHospital)
                        ? selectedDoctorHospital.name
                        : ""
                    }
                    onSelectItem={(selectedDoctorHospitalNew) => {
                      setSelectedDoctorHospital(selectedDoctorHospitalNew);
                    }}
                    selectFromOptionsRequired={true}
                    options={doctorHospitalOptions}
                    emptyOptionLabel={"No assigned hospital..."}
                    disabled={_.isNull(doctorHospitalOptions)}
                    disableTab={true}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <MBDivider />
        <div className="executive-account-form-setup-button-container">
          <IonButton
            onClick={onCancel}
            className="executive-account-form-setup-back-button mb-body ion-text-capitalize"
            fill="clear"
            color={MBCOLORS.primary}
          >
            {!isView ? "Cancel" : "Back"}
          </IonButton>
          {!isView && (
            <IonButton
              onClick={() => {
                onSubmit();
              }}
              className="executive-account-form-setup-save-button mb-body bold white ion-text-capitalize"
              color={MBCOLORS.primary}
            >
              Save
            </IonButton>
          )}
        </div>
      </IonModal>

      <MBDialog
        isOpen={!_.isEmpty(success)}
        icon="assistant"
        buttonLabel="Okay"
        title={success}
        message={MSGS_DOCTOR.addExecAssistantSuccesMessage}
        onDidDismiss={() => {
          setSuccess("");
          onCancel();
        }}
      />
      {/* DOCTOR SPECIALITY MODAL */}

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={saving ? MSGS_COMMON.saving : MSGS_COMMON.loading}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
