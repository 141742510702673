import rp from "request-promise";
import Paymongo from "paymongo";

import { getEnv } from "../env";
import { getRequestHeaders } from "../functions/common";

var env = getEnv();

let url = env.firebase.cloudFunctionsUrl;
let websiteUrl = env.firebase.websiteUrl;
var paymongoSecretKey = env.paymongo.secretKey;
const paymongo = new Paymongo(paymongoSecretKey);

interface GCashSource {
  data: {
    id: string;
    attributes: {
      redirect: {
        checkout_url: string;
        failed: string;
        success: string;
      };
      status: string;
      type: string;
    };
  };
}

export const createGCashSource = async (
  amount: number,
  phone: string,
  name: string,
  email: string,
  gcashPaymentId: string
): Promise<GCashSource> => {
  const gcashSource = await paymongo.sources.create({
    data: {
      attributes: {
        type: "gcash",
        amount: amount * 100, // converts to cents,
        currency: "PHP",
        redirect: {
          success: `${websiteUrl}payment-processing`,
          failed: `${websiteUrl}payment-failed/${gcashPaymentId}`,
        },
        billing: {
          name,
          phone,
          email,
        },
      },
    },
  });
  return gcashSource;
};

export interface PaymongoPayment {
  data: {
    attributes: {
      status: string;
    };
  };
}
export const paymongoCreatePayment = async (
  amount: number,
  sourceId: string,
  description: string,
  statementDescriptor: string
): Promise<PaymongoPayment> => {
  const result = await paymongo.payments.create({
    data: {
      attributes: {
        amount: amount * 100, // converts to cents,
        source: {
          id: sourceId,
          type: "source",
        },
        description: description,
        currency: "PHP",
        statement_descriptor: statementDescriptor,
      },
    },
  });
  return result;
};

export interface PaymongoIntent {
  data: {
    id: string;
    type: string;
    attributes: {
      amount: number;
      currency: string;
      description: any;
      last_payment_error: any;
      statement_descriptor: string;
      status: string;
      livemode: false;
      client_key: string;
      created_at: number;
      updated_at: number;
      payment_method_allowed: string[];
      payments: [];
      next_action: any;
      payment_method_options: {
        card: {
          request_three_d_secure: any;
        };
      };
      metadata: any;
    };
  };
}

export const paymongoCreateIntent = async (
  amount: number
): Promise<PaymongoIntent> => {
  return await paymongo.paymentIntents.create({
    data: {
      attributes: {
        amount: amount * 100, // converts to cents,
        currency: "PHP",
        payment_method_allowed: ["card"],
      },
    },
  });
};

export const paymongoRetrieveIntent = async (
  intentId: string
): Promise<PaymongoIntent> => {
  return await paymongo.paymentIntents.retrieve(intentId);
};

export interface PaymongoPaymentMethod {
  data: {
    id: string;
    type: string;
    attributes: {
      livemode: false;
      type: string;
      billing: {
        email: string;
        name: string;
      };
      created_at: number;
      updated_at: number;
      details: {
        exp_month: any;
        exp_year: any;
        last4: number;
      };
      metadata: any;
    };
  };
}

export const paymongoCreatePaymentMethod = async (
  cardNumber: string,
  expMonth: number,
  expYear: number,
  cvv: string,
  name: string,
  email: string
): Promise<PaymongoPaymentMethod> => {
  return await paymongo.paymentMethods.create({
    data: {
      attributes: {
        type: "card", // The only available type for now is 'card'.
        details: {
          card_number: cardNumber,
          exp_month: expMonth,
          exp_year: expYear,
          cvc: cvv,
        },
        billing: {
          name,
          email,
        },
      },
    },
  });
};

export interface PaymongoPaymentIntentAttached {
  data: {
    id: string;
    type: string;
    attributes: {
      amount: number;
      currency: string;
      description: any;
      statement_descriptor: string;
      status: string;
      livemode: false;
      client_key: string;
      created_at: number;
      updated_at: number;
      last_payment_error: any;
      payment_method_allowed: string[];
      payments: {
        id: string;
        type: string;
        attributes: {
          access_url: any;
          amount: number;
          balance_transaction_id: string;
          billing: {
            address: {
              city: any;
              country: any;
              line1: any;
              line2: any;
              postal_code: any;
              state: any;
            };
            email: string;
            name: string;
            phone: any;
          };
          currency: string;
          description: any;
          disputed: false;
          external_reference_number: string;
          fee: number;
          foreign_fee: number;
          livemode: false;
          net_amount: 8050;
          payout: any;
          source: {
            id: string;
            type: string;
            brand: string;
            country: string;
            last4: string;
          };
          statement_descriptor: string;
          status: string;
          refunds: [];
          available_at: number;
          created_at: number;
          paid_at: number;
          updated_at: number;
        };
      }[];
      next_action: any;
      payment_method_options: {
        card: {
          request_three_d_secure: "any";
        };
      };
      metadata: any;
    };
  };
}

export const paymongoAttachPaymentMethodToIntent = async (
  intentId: string,
  paymentMethodId: string
): Promise<PaymongoPaymentIntentAttached> => {
  return await paymongo.paymentIntents.attach(intentId, {
    data: {
      attributes: {
        payment_method: paymentMethodId,
      },
    },
  });
};

/**
 * sample for attached method to intent
 * data: {
    id: "pi_xHW3Gmo8Wj1jB8VVY5DB3kXs";
    type: "payment_intent";
    attributes: {
      amount: 10000;
      currency: "PHP";
      description: any;
      statement_descriptor: "Cortex Technologies Corporation";
      status: "succeeded";
      livemode: false;
      client_key: "pi_xHW3Gmo8Wj1jB8VVY5DB3kXs_client_N9Ljv9oYd5JFcmaqik4nnqz4";
      created_at: 1600359984;
      updated_at: 1600360242;
      last_payment_error: any;
      payment_method_allowed: ["card"];
      payments: [
        {
          id: "pay_SYFqtbWHi3gL2p6iyBupPB8t";
          type: "payment";
          attributes: {
            access_url: any;
            amount: 10000;
            balance_transaction_id: "bal_txn_xgduGVn3tt7scjgkzEWMuQzT";
            billing: {
              address: {
                city: any;
                country: any;
                line1: any;
                line2: any;
                postal_code: any;
                state: any;
              };
              email: "jandreicortiz@outlook.com";
              name: "Joshua Maxwell";
              phone: any;
            };
            currency: "PHP";
            description: any;
            disputed: false;
            external_reference_number: "";
            fee: 1850;
            foreign_fee: 100;
            livemode: false;
            net_amount: 8050;
            payout: any;
            source: {
              id: "card_LHQ6FGxPZXeZBYbztPfdZtMw";
              type: "card";
              brand: "visa";
              country: "US";
              last4: "4345";
            };
            statement_descriptor: "Cortex Technologies Corporation";
            status: "paid";
            refunds: [];
            available_at: 1600851600;
            created_at: 1600360242;
            paid_at: 1600360242;
            updated_at: 1600360242;
          };
        }
      ];
      next_action: any;
      payment_method_options: {
        card: {
          request_three_d_secure: "any";
        };
      };
      metadata: any;
    };
 */
