import * as _ from "lodash";
import { Helmet } from "react-helmet";
import React from "react";
import {
  IonPage,
  IonContent,
  IonCard,
  IonCardContent,
  IonLabel,
  IonIcon,
  IonLoading,
} from "@ionic/react";

import "./PaymentProcessingLanding.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { MBProps } from "../../interface";
import { MBHeader } from "../../components/MBHeader/MBHeader";
import { MBFooter } from "../../components/MBFooter/MBFooter";
import { MSGS_COMMON } from "../../constants/messages";

class PaymentProcessingLanding extends React.Component<MBProps> {
  state = {
    loading: false,
    gcashPayment: null as null | string,
  };
  componentDidMount = () => {
    if (this.props.match.path === routes.PAYMENT_PROCESSING_FAILED_LANDING) {
      const urlParams = this.props.match.params;
      const { gcashPayment } = urlParams as { gcashPayment: string };
      console.log("wat do i got", gcashPayment);
      if (!!gcashPayment) {
        this.setState({
          gcashPayment,
        });
        this.markGcashPaymentAsUnChargeable(gcashPayment);
      } else {
        this.setState({ gcashPayment: "" });
      }
    } else {
      this.setState({ gcashPayment: "" });
    }
  };

  markGcashPaymentAsUnChargeable = async (gcashPayment: string) => {
    this.setState({
      loading: true,
    });
    try {
      await services.markGcashAsNonChargeable(gcashPayment);
      this.setState({
        loading: false,
      });
    } catch (e) {
      console.log("ERROR ON FAILED PAYMENT: ", e);
      this.setState({
        loading: false,
      });
    }
  };

  render = () => {
    const { loading, gcashPayment } = this.state;
    return (
      <>
        <Helmet>
          <title>
            MedBook - Payment{" "}
            {_.isEmpty(gcashPayment) ? "Processing" : "Failed"}
          </title>
        </Helmet>
        <IonPage>
          <MBHeader {...this.props} />
          <IonContent className="mb-page-content-bg">
            <div className="mb-page-content-bg-card-container">
              {!_.isNull(gcashPayment) && (
                <IonCard className="payment-processing-landing-card">
                  <IonCardContent className="payment-processing-landing-card-content">
                    <IonLabel className="mb-h2 dark-blue">
                      {_.isEmpty(gcashPayment)
                        ? "Payment is being Processed"
                        : "Can’t Process your Payment"}
                    </IonLabel>
                    <IonLabel className="mb-body ion-margin-top">
                      {_.isEmpty(gcashPayment)
                        ? "You can now close this page and wait until the checkout screen loads"
                        : "There must be an internal error or timeout while processing your payment. Please close this page and try again"}
                    </IonLabel>
                    <IonIcon
                      className={`payment-processing-landing-card-icon ${
                        !_.isEmpty(gcashPayment) ? "failed" : ""
                      }`}
                    />

                    <IonLabel className="mb-paragraph light">
                      Powered By
                    </IonLabel>
                    <IonIcon className="payment-processing-landing-card-paymongo-icon" />
                  </IonCardContent>
                </IonCard>
              )}
            </div>
          </IonContent>
          <MBFooter />
          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />
        </IonPage>
      </>
    );
  };
}

export default PaymentProcessingLanding;
