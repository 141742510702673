import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonLabel,
  IonButton,
  IonToast,
  IonLoading,
  IonCheckbox,
  IonItem,
} from "@ionic/react";

import "./DoctorCreatePasswordForm.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { Link } from "react-router-dom";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import { MBCOLORS } from "../../constants/config";
import { MSGS_COMMON_FORM_ERROR, MSGS_COMMON } from "../../constants/messages";
import { decryptPassword, isMobile } from "../../functions/common";
import { Doctor } from "../../models";
import { MBDialog } from "../MBDialog/MBDialog";

export const DoctorCreatePasswordForm = (props: MBProps) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [haveReadPolicy, setHaveReadPolicy] = useState(false);
  const [consentNeededDialogOpen, setConsentNeededDialogOpen] = useState(false);

  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    let error;
    setLoading(true);
    if (_.isEmpty(password)) {
      error = MSGS_COMMON_FORM_ERROR.password;
      setErrorPassword(error);
    } else if (_.isEmpty(confirmPassword)) {
      error = MSGS_COMMON_FORM_ERROR.passwordConfirm;
      setErrorConfirmPassword(error);
    } else if (!_.isEqualWith(password, confirmPassword)) {
      error = "Password do not match.";
      setErrorConfirmPassword(error);
    }

    if (!error) {
      if (!haveReadPolicy) {
        setLoading(false);
        setConsentNeededDialogOpen(true);
      } else {
        try {
          const doctorDetail = props.authUser.userDetails as Doctor;
          const oldPassword = decryptPassword(
            doctorDetail.docId as string,
            doctorDetail.initialPasswordCipher
          );
          await services.updatePassword(oldPassword, password);
          await services.doctorRemoveInitialPassword(
            doctorDetail.docId as string,
            doctorDetail
          );
          setLoading(false);
          props.history.push(routes.DOCTOR_APPOINTMENTS);
        } catch (e) {
          setError(e.message);
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <IonCard
      className="doctor-create-password-form-card ion-no-margin"
      color={MBCOLORS.tertiary}
    >
      <IonCardContent className="doctor-create-password-form-card-content ion-no-padding">
        <div className="doctor-mb-logo-container">
          <IonIcon
            className={`doctor-create-password-form-card-logo ${
              isMobile() ? "mobile" : ""
            }`}
          />
        </div>
        <MBDivider />
        <div className="doctor-create-password-form-details">
          <IonLabel className="mb-h2 bold">First Time Login</IonLabel>
          <form onSubmit={onSubmit}>
            <div className="doctor-create-password-form-input-container">
              <div className="doctor-create-password-form-password-input">
                <MBInput
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(password: string) => {
                    setPassword(password);
                    setErrorPassword("");
                  }}
                  error={errorPassword}
                />
              </div>
              <div className="doctor-create-password-form-confirm-password-input">
                <MBInput
                  type="password"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(confirmPassword: string) => {
                    setConfirmPassword(confirmPassword);
                    setErrorConfirmPassword("");
                  }}
                  error={errorConfirmPassword}
                />
              </div>
            </div>
            <div className="doctor-create-password-privacy-container">
              <IonLabel className="doctor-create-password-privacy-notice mb-body ion-no-padding ion-no-margin">
                This must be your first time logging in. Please read the Data
                Privacy Policy and click the check box to proceed.
              </IonLabel>
            </div>
            <IonItem
              lines="none"
              className="doctor-create-password-data-privacy-consent-item ion-no-padding"
              color={MBCOLORS.tertiary}
            >
              <IonCheckbox
                className="doctor-create-password-data-privacy-consent-checkbox ion-no-margin"
                checked={haveReadPolicy}
                onIonChange={(e) => setHaveReadPolicy(e.detail.checked)}
                color={MBCOLORS.primary}
                mode="md"
              />
              <IonLabel className="doctor-create-password-data-privacy-consent-label mb-body ion-no-margin">
                I have read and understood the{" "}
                <Link to={routes.DATA_PRIVACY} target="_blank">
                  <u>Data Privacy Policy</u>
                </Link>
              </IonLabel>
            </IonItem>
            <IonButton
              color={MBCOLORS.primary}
              className="doctor-create-password-form-login-button ion-text-capitalize"
              type="submit"
            >
              Login
            </IonButton>
          </form>
          <IonLabel className="doctor-create-password-terms mb-h6 small">
            By clicking Login I hereby agree and consent to the{" "}
            <Link to={routes.TERMS_CONDITION} target="_blank">
              User Agreement
            </Link>
            .
          </IonLabel>
        </div>
      </IonCardContent>
      <MBDialog
        isOpen={consentNeededDialogOpen}
        title="Consent needed"
        message="We cannot proceed unless you have read and agreed to the Data Privacy Policy. Please take your time in reading the policy."
        icon="warning"
        onDidDismiss={() => setConsentNeededDialogOpen(false)}
      />
      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </IonCard>
  );
};
