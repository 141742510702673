import * as _ from "lodash";
import { Helmet } from "react-helmet";
import React from "react";

import "./VerifyEmail.scss";
import * as services from "../../services";
import * as routes from "../../constants/routes";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import qs from "qs";
import { doApplyActionCode } from "../../services";
import { MSGS_FIREBASE, MSGS_COMMON } from "../../constants/messages";
import {
  IonCard,
  IonIcon,
  IonLabel,
  IonCardContent,
  IonButton,
  IonLoading,
  IonToast,
} from "@ionic/react";
import { MBCOLORS } from "../../constants/config";

class VerifyEmail extends React.Component<MBProps> {
  state = {
    error: "",
    loading: true,
  };
  componentDidMount = () => {
    const obj = qs.parse(this.props.location.search);
    if (!_.isEmpty(obj)) {
      services
        .doApplyActionCode(obj.oobCode as string)
        .then(() => {
          this.setState({
            error: "",
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            error,
            loading: false,
          });
          console.log("error -- doApplyActionCode -- ", doApplyActionCode);
        });
    } else {
      this.setState({
        error: MSGS_FIREBASE.emailVerificationFailedNoOOB,
        loading: false,
      });
    }
  };
  render = () => {
    const { loading, error } = this.state;
    return (
      <>
        <Helmet>
          <title>MedBook - Verify Email</title>
        </Helmet>
        <MBContainer {...this.props}>
          {!loading && (
            <IonCard className="verify-email-card">
              <IonCardContent className="verify-email-card-content">
                <IonIcon
                  className={`verify-email-icon ${
                    !!error ? "warning" : "success"
                  }`}
                />
                <IonLabel className="mb-h3 bold">
                  {`${!!error ? "Error" : "Success"} Email Verification!`}
                </IonLabel>
                <IonButton
                  className="verify-email-ok-button"
                  color={MBCOLORS.tertiary}
                  routerLink={routes.LOGIN_OPTIONS}
                >
                  Ok
                </IonButton>
              </IonCardContent>
            </IonCard>
          )}
          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />
          <IonToast
            isOpen={!_.isEmpty(error)}
            message={error}
            onDidDismiss={() => {
              this.setState({ error: "" });
            }}
            color={MBCOLORS.danger}
          />
        </MBContainer>
      </>
    );
  };
}

export default VerifyEmail;
