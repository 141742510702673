import React, { useState } from "react";
import {
  IonCard,
  IonLabel,
  IonCardContent,
  IonButton,
  IonIcon,
} from "@ionic/react";

import "./AccountTypeOptions.scss";
import { isMobile } from "../../functions/common";
import { UserType } from "../../interface";
import { MBCOLORS, USER_TYPES } from "../../constants/config";

interface AccountTypeOptionsProps {
  onSelect: (userType: UserType) => void;
}
export const AccountTypeOptions = (props: AccountTypeOptionsProps) => {
  const [selected, setSelected] = useState(0 as UserType);
  return (
    <>
      <IonCard
        className={`account-type-options-card ${
          isMobile() && "mobile"
        } ion-no-margin`}
        color={MBCOLORS.tertiary}
      >
        <IonCardContent className="account-type-options-card-content ion-no-padding">
          <IonLabel className="account-type-header mb-h1 dark-blue ion-text-start">
            Select User Type
          </IonLabel>
          <IonLabel className="account-type-description mb-body ion-text-start">
            Please select if you are registering as a patient or hospital.
          </IonLabel>
          <div
            className={`account-type-options-card-types-container ${
              isMobile() && "mobile"
            }`}
          >
            <div className="account-type-button-container">
              <div
                className={`account-type-options-card-types-button ${
                  selected === USER_TYPES.patients.id && "isSelected"
                } ${isMobile() && "mobile"}`}
                onClick={() => {
                  setSelected(USER_TYPES.patients.id);
                }}
              >
                <div className="account-type-user-button-container">
                  <IonIcon className="account-type-icon patient" />
                  <IonLabel
                    className={`account-type-user-label ${
                      selected === USER_TYPES.patients.id && "isSelected"
                    }`}
                  >
                    {USER_TYPES.patients.name}
                  </IonLabel>
                </div>
              </div>

              <div
                className={`account-type-options-card-types-button ${
                  selected === USER_TYPES.hospitals.id && "isSelected"
                } ${isMobile() && "mobile"}`}
                onClick={() => {
                  setSelected(USER_TYPES.hospitals.id);
                }}
              >
                <div className="account-type-user-button-container">
                  <IonIcon className="account-type-icon hospital" />
                  <IonLabel
                    className={`account-type-user-label ${
                      selected === USER_TYPES.hospitals.id && "isSelected"
                    }`}
                  >
                    {USER_TYPES.hospitals.name}
                  </IonLabel>
                </div>
              </div>
            </div>
          </div>
          <IonButton
            className="account-type-next-button"
            onClick={() => {
              props.onSelect(selected);
            }}
            mode="ios"
          >
            Next
          </IonButton>
        </IonCardContent>
      </IonCard>
    </>
  );
};
