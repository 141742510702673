/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonCard,
  IonCardHeader,
  IonItem,
  IonInput,
  IonButton,
  IonImg,
} from "@ionic/react";

import "./ExecutiveAccountForm.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { analytics } from "../../firebase";
import { storage } from "../../firebase";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import {
  formatString,
  useEffectOnlyOnce,
  isMobile,
} from "../../functions/common";
import { ExecutiveAssistant } from "../../models";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBFileInput } from "../MBFileInput/MBFileInput";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import {
  LIMITS,
  MBCOLORS,
  ANALYTICS_CONTENT_TYPES,
} from "../../constants/config";
import {
  MSGS_COMMON,
  MSGS_SIGNUP,
  MSGS_COMMON_FORM_ERROR,
} from "../../constants/messages";

interface ExecutiveAccountFormProps extends MBProps {
  onBack: () => void;
}
export const ExecutiveAccountForm = (props: ExecutiveAccountFormProps) => {
  const { authUser, onBack } = props;

  const executiveAssistant = authUser.userDetails as ExecutiveAssistant;

  const { initialSetupDone } = executiveAssistant;

  const [firstName, setFirstName] = useState(
    !!executiveAssistant && executiveAssistant.firstName
      ? executiveAssistant.firstName
      : ""
  );
  const [lastName, setLastName] = useState(
    !!executiveAssistant && executiveAssistant.lastName
      ? executiveAssistant.lastName
      : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    !!executiveAssistant && executiveAssistant.phoneNumber
      ? executiveAssistant.phoneNumber
      : ""
  );
  const [emailAddress, setEmailAddress] = useState(
    !!executiveAssistant && executiveAssistant.emailAddress
      ? executiveAssistant.emailAddress
      : ""
  );

  const [governmentIdFile, setGovernmentIdFile] = useState(null as any);

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("");
  const [errorMsgEmaillAddress, setErrorMsgEmaillAddress] = useState("");
  const [errorMsgGovtIdFile, setErrorMsgGovtIdFile] = useState("");

  const [isOpenSuccessProfileUpdate, setIsOpenSuccessProfileUpdate] = useState(
    false
  );
  const [isOpenSendChangePassword, setIsOpenSendChangePassword] = useState(
    false
  );

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  useEffectOnlyOnce(() => {
    if (!_.isEmpty(authUser)) {
      setEmailAddress(authUser.authEmail);
    }
  });

  const onSubmit = async () => {
    setLoading(true);
    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedPhoneNumber = formatString((phoneNumber || "") as string);

    let errorMessage = null;
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_SIGNUP.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.firstName.max;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_SIGNUP.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedPhoneNumber.length === 0) {
      errorMessage = MSGS_SIGNUP.mobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (!validatePhoneNumber(formattedPhoneNumber)) {
      errorMessage = MSGS_SIGNUP.invalidMobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_SIGNUP.email.required;
      setErrorMsgEmaillAddress(errorMessage);
    }
    if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_SIGNUP.email.invalid;
      setErrorMsgEmaillAddress(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      let newGovermentIdURL = "";
      if (!_.isNull(governmentIdFile)) {
        const ref = storage.ref();
        const governmentIdPath = `governmentId/${formattedLastName
          .replace(" ", "")
          .toLowerCase()}_${formattedFirstName}_${
          authUser.uid
        }/government_id_${new Date().valueOf()}`;
        const uploadGovernmentIdSnapshot = await ref
          .child(governmentIdPath)
          .put(governmentIdFile);
        newGovermentIdURL = await uploadGovernmentIdSnapshot.ref.getDownloadURL();
      }

      const executiveAssistantDetail = _.cloneDeep(executiveAssistant);
      const updatedExecutiveAssistantDetail = {
        ...executiveAssistantDetail,
        firstName: formattedFirstName,
        lastName: formattedLastName,
        phoneNumber: formattedPhoneNumber,
        initialSetupDone: true,
        ...(!_.isNull(governmentIdFile) && {
          governmentId: newGovermentIdURL,
        }),
      } as Partial<ExecutiveAssistant>;

      try {
        await services.updateExecutiveAssistantDetail(
          executiveAssistant.docId || "",
          updatedExecutiveAssistantDetail
        );

        setLoading(false);
        setIsOpenSuccessProfileUpdate(true);
      } catch (error) {
        console.log("error - updateHospitalRepresentative -- ", error);
        setError(error);
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  };

  const onChangePassword = async () => {
    let error = "";
    if (_.isEmpty(emailAddress)) {
      error = MSGS_COMMON_FORM_ERROR.emailAddress;
      setErrorMsgEmaillAddress(error);
    }

    if (!error) {
      setIsOpenSendChangePassword(true);
      try {
        await services.doSendResetPasswordEmail(emailAddress);
        analytics.logEvent("edit_hospital_rep_password", {
          content_type: ANALYTICS_CONTENT_TYPES.hospitalRepAccount.type,
        });
      } catch (error) {
        console.log("Error -- doSendResetPasswordEmail -- ", error);
      }
    }
  };

  return (
    <>
      <IonCard
        className="executive-assistant-edit-card ion-no-margin"
        color={MBCOLORS.tertiary}
      >
        <IonCardHeader className="executive-assistant-edit-card-header">
          <IonLabel className="mb-h2 dark-blue ion-text-start">
            {initialSetupDone ? "Edit" : "Confirm"} Details
          </IonLabel>
        </IonCardHeader>
        <form autoComplete="nope...">
          <div className="executive-assistant-edit-form-container">
            <IonGrid className="executive-assistant-edit-form-container-grid ion-no-padding">
              <IonRow>
                <IonCol
                  className="executive-assistant-edit-form-container-col ion-no-padding"
                  size="12"
                >
                  <MBInput
                    label="ID Number"
                    value={executiveAssistant.hospitalUID}
                    type="text"
                    error={""}
                    readonly={true}
                  />
                </IonCol>
              </IonRow>
              {_.chunk(
                [
                  <IonCol
                    className="executive-assistant-edit-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="First name"
                      value={firstName}
                      type="text"
                      error={errorMsgFirstName}
                      onChange={(firstName) => {
                        setFirstName(firstName);
                        setErrorMsgFirstName("");
                      }}
                    />
                  </IonCol>,
                  <IonCol
                    className="executive-assistant-edit-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="Last name"
                      value={lastName}
                      type="text"
                      error={errorMsgLastName}
                      onChange={(lastName) => {
                        setLastName(lastName);
                        setErrorMsgLastName("");
                      }}
                    />
                  </IonCol>,
                  <IonCol
                    className="executive-assistant-edit-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="Mobile Number"
                      value={phoneNumber}
                      type="tel"
                      error={errorMsgPhoneNumber}
                      onChange={(phoneNumber) => {
                        setPhoneNumber(phoneNumber);
                        setErrorMsgPhoneNumber("");
                      }}
                    />
                  </IonCol>,
                  <IonCol
                    className="executive-assistant-edit-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="Email Address"
                      value={emailAddress}
                      type="email"
                      error={errorMsgEmaillAddress}
                      onChange={(emailAddress) => {
                        setEmailAddress(emailAddress);
                        setErrorMsgEmaillAddress("");
                      }}
                      readonly={true} //last priority - implement the email change and verification
                    />
                  </IonCol>,
                ],
                !isMobile() ? 2 : 1
              ).map((row, index) => {
                return (
                  <IonRow>
                    {row.map((col) => {
                      return (
                        <IonCol
                          className="executive-assistant-edit-form-container-col ion-no-padding"
                          size={(12 / row.length).toString()}
                        >
                          {col}
                        </IonCol>
                      );
                    })}
                  </IonRow>
                );
              })}

              {initialSetupDone && (
                <IonRow>
                  <IonCol
                    className="executive-assistant-edit-form-container-col last ion-no-padding"
                    size="12"
                  >
                    <IonItem
                      lines="none"
                      className="executive-assistant-edit-form-password-item ion-no-padding ion-no-margin"
                      color={MBCOLORS.tertiary}
                    >
                      <IonLabel
                        position="stacked"
                        className="mb-paragraph bold ion-no-padding ion-no-margin"
                      >
                        Password
                      </IonLabel>
                      <IonInput
                        type="password"
                        className="executive-assistant-edit-form-password-input ion-no-padding ion-no-margin"
                        autocomplete="off"
                        value={executiveAssistant.emailAddress} //fake password changing password will send an Email
                        readonly={true}
                      >
                        <IonButton
                          color={MBCOLORS.primary}
                          className="executive-assistant-edit-form-change-password-button sv-paragraph ion-no-margin ion-text-capitalize"
                          onClick={onChangePassword}
                        >
                          Edit Password
                        </IonButton>
                      </IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
              )}

              <IonRow className="ion-no-padding">
                <IonCol
                  className="executive-assistant-edit-form-container-col ion-no-padding"
                  size="12"
                >
                  {_.isEmpty(executiveAssistant.governmentId) ? (
                    <MBFileInput
                      label="Government ID"
                      onChange={(file) => {
                        setGovernmentIdFile(file);
                        setErrorMsgGovtIdFile("");
                      }}
                      error={errorMsgGovtIdFile}
                      disableTab={true}
                    />
                  ) : (
                    <>
                      <IonLabel className="mb-paragraph bold">
                        Government ID
                      </IonLabel>
                      <IonImg
                        className="support-group-registration-form-id ion-margin-top"
                        src={executiveAssistant.governmentId}
                        onClick={() => {
                          window.open(
                            executiveAssistant.governmentId,
                            "_blank"
                          );
                        }}
                      />
                    </>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </form>
        <MBDivider />
        <div className="executive-assistant-edit-card-footer">
          {initialSetupDone && (
            <IonButton
              onClick={onBack}
              className="executive-assistant-edit-cancel-button mb-body bold ion-text-capitalize"
              fill="outline"
              color={MBCOLORS.primary}
            >
              Back
            </IonButton>
          )}

          <IonButton
            onClick={() => {
              onSubmit();
            }}
            className="executive-assistant-edit-submit-button mb-body bold white ion-text-capitalize"
            color={MBCOLORS.primary}
          >
            {initialSetupDone ? "Save" : "Confirm"}
          </IonButton>
        </div>
      </IonCard>

      <MBDialog
        isOpen={isOpenSendChangePassword}
        title="Email Sent"
        message={`We have sent you an email to ${emailAddress} Click the link to change your account password.`}
        icon="success"
        onDidDismiss={() => {
          setIsOpenSendChangePassword(false);
        }}
      />

      <MBDialog
        isOpen={isOpenSuccessProfileUpdate}
        title={
          initialSetupDone
            ? "Personal Profile Updated"
            : "All right we're all set"
        }
        message={
          initialSetupDone
            ? "You have successfully updated your personal profile. This will now reflect on your profile details."
            : "You can now assist your doctor in services related patient consultations."
        }
        icon="success"
        {...(!initialSetupDone && {
          buttonLabel: "Let's Get Started",
        })}
        onDidDismiss={() => {
          setIsOpenSuccessProfileUpdate(false);
          if (initialSetupDone) {
            onBack();
          } else {
            props.history.push(routes.EXECUTIVE_ASSISTANT_HOME);
          }
        }}
      />

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={2000}
        onDidDismiss={() => setSuccess("")}
        color={MBCOLORS.success}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
