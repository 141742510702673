import _ from "lodash";
import React from "react";
import { ellipsisHorizontal, pencilOutline, trashBin } from "ionicons/icons";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonButton,
  IonItem,
  IonList,
  IonPopover,
} from "@ionic/react";

import { ExecutiveAssistant } from "../../models";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import { MBCOLORS } from "../../constants/config";

import "./ExecutiveAccount.scss";
import { ExecutiveAccountForm } from "../../components/ExecutiveAccountForm/ExecutiveAccountForm";

class ExecutiveAccount extends React.Component<MBProps> {
  state = {
    executiveAccount: this.props.authUser.userDetails as ExecutiveAssistant,
    showActionButtons: false,
    actionButtonsEvent: undefined as any,
    isOpenEditAccount: false,
  };

  componentDidMount = () => {
    console.log("DID IT CHANGE?", this.props.authUser.userDetails);
    if (!this.state.executiveAccount.initialSetupDone) {
      this.setState({
        isOpenEditAccount: true,
      });
    }
  };

  componentDidUpdate = () => {
    this.updateExecAccount();
  };

  updateExecAccount = async () => {
    const { executiveAccount } = this.state;
    const updatedAccount = this.props.authUser.userDetails;
    if (
      executiveAccount.firstName !== updatedAccount.firstName ||
      executiveAccount.lastName !== updatedAccount.lastName ||
      executiveAccount.phoneNumber !== updatedAccount.phoneNumber
    ) {
      this.setState({
        executiveAccount: this.props.authUser.userDetails as ExecutiveAssistant,
      });
    }
  };

  render = () => {
    const {
      executiveAccount,
      showActionButtons,
      actionButtonsEvent,
      isOpenEditAccount,
    } = this.state;
    return (
      <MBContainer {...this.props} activePage="account">
        {!isOpenEditAccount ? (
          <IonCard
            className="executive-assistant-account-detail-card ion-no-margin"
            color={MBCOLORS.tertiary}
          >
            <IonCardContent className="executive-assistant-account-detail-card-content ion-no-padding">
              <div className="executive-assistant-account-detail-card-content-details-container">
                <IonGrid className="executive-assistant-account-detail-card-grid-detail ion-no-padding ion-no-margin">
                  <IonRow className="executive-assistant-account-detail-card-row ion-no-padding ion-no-margin">
                    <IonCol
                      size="4"
                      className="executive-assistant-account-detail-card-col-icons ion-no-padding ion-no-margin"
                    >
                      <IonIcon className="executive-assistant-account-detail-card-details-icon ion-no-margin" />
                    </IonCol>
                    <IonCol
                      size="7"
                      className="executive-assistant-account-detail-card-col-details ion-no-padding ion-no-margin"
                    >
                      <div className="executive-assistant-account-detail-card-details-container">
                        <IonLabel className="mb-h1 dark-blue ion-no-padding ion-no-margin">
                          {`${executiveAccount.firstName} ${executiveAccount.lastName}`}
                        </IonLabel>
                        <div className="executive-assistant-account-detail-card-sub-detail-container">
                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon mobile" />
                            <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                              {executiveAccount.phoneNumber}
                            </IonLabel>
                          </div>
                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon mail" />
                            <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                              {executiveAccount.emailAddress}
                            </IonLabel>
                          </div>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol
                      size="1"
                      className="executive-assistant-account-detail-card-col-button ion-no-padding ion-no-margin"
                    >
                      <IonButton
                        className="executive-assistant-account-detail-card-action-button"
                        color={MBCOLORS.medium}
                        onClick={(e) => {
                          this.setState({
                            actionButtonsEvent: e.nativeEvent,
                            showActionButtons: true,
                          });
                        }}
                      >
                        <IonIcon icon={ellipsisHorizontal} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonCardContent>
          </IonCard>
        ) : (
          <ExecutiveAccountForm
            {...this.props}
            onBack={() => {
              this.setState({
                isOpenEditAccount: false,
              });
            }}
          />
        )}

        <IonPopover
          mode="md"
          isOpen={showActionButtons}
          event={actionButtonsEvent}
          cssClass="executive-assistant-account-detail-card-popover noselect ion-no-padding"
          showBackdrop={false}
          onDidDismiss={() => {
            this.setState({
              showActionButtons: false,
            });
          }}
        >
          <div className="executive-assistant-account-detail-card-list-container">
            <IonList className="executive-assistant-account-detail-card-list">
              <div className="executive-assistant-account-detail-card-item-container">
                <IonItem
                  className="executive-assistant-account-detail-card-dropdown-modal-item ion-justify-content-center"
                  button={true}
                  lines="none"
                  onClick={() => {
                    this.setState({
                      isOpenEditAccount: true,
                      showActionButtons: false,
                    });
                  }}
                >
                  <IonLabel className="executive-assistant-account-detail-card-dropdown-label mb-h5">
                    <IonIcon
                      className="executive-assistant-account-detail-card-dropdown-modal-item-icon"
                      icon={pencilOutline}
                      slot="start"
                    />
                    Edit
                  </IonLabel>
                </IonItem>
              </div>
            </IonList>
          </div>
        </IonPopover>
      </MBContainer>
    );
  };
}

export default ExecutiveAccount;
