import * as _ from "lodash";
import React, { useState, useRef } from "react";
import { IonLabel, IonItem, IonInput, IonButton } from "@ionic/react";

import "./MBFileInput.scss";
import { MBCOLORS } from "../../constants/config";

interface MBInputProps {
  label: string;
  onChange: (file: any) => void;
  error?: string;
  disableTab?: boolean;
}
export const MBFileInput = (props: MBInputProps) => {
  const { label, onChange, error, disableTab } = props;
  const [fileName, setFileName] = useState("");

  const fileInputRef: React.RefObject<HTMLInputElement> = useRef(null);

  return (
    <div>
      <IonItem
        lines="none"
        className="mb-file-input-item ion-no-padding ion-no-margin"
      >
        <IonLabel
          className={`mb-file-input-label mb-paragraph bold ${
            !!error && "has-error"
          }`}
          position="stacked"
        >
          {label}
        </IonLabel>
        <div className="mb-file-input-button-container">
          <IonInput
            className={`mb-file-input ${
              !!error && "has-error"
            } mb-body ion-align-items-end`}
            type="text"
            value={fileName}
            readonly
          >
            <input
              hidden
              type="file"
              ref={fileInputRef}
              accept="image/*"
              onChange={(event) => {
                console.log("GOT FILE!");

                const fileValue = event.target.files;
                if (!_.isNull(fileValue) && fileValue.length > 0) {
                  setFileName((fileValue[0] as File).name);
                  onChange(fileValue[0]);
                }
              }}
              {...(disableTab && {
                onKeyDown: (event: React.KeyboardEvent) => {
                  if (event.keyCode === 9) {
                    event.preventDefault();
                  }
                },
              })}
            />
          </IonInput>
          <IonButton
            slot="end"
            color={MBCOLORS.primary}
            fill="clear"
            onClick={() => {
              if (!_.isNull(fileInputRef)) {
                fileInputRef.current!.click();
              }
            }}
            className="mb-file-input-upload-button mb-h6 small bold blue ion-text-capitalize ion-no-margin"
          >
            Upload
          </IonButton>
        </div>
      </IonItem>
      {!!error && (
        <div className="ion-text-start">
          <IonLabel className="mb-file-input-error-label mb-h6 small has-error ion ion-no-margin ion-no-padding">
            {error}
          </IonLabel>
        </div>
      )}
    </div>
  );
};
