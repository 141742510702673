import * as _ from "lodash";
import { Helmet } from "react-helmet";
import React from "react";

import "./FAQ.scss";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import {
  IonCard,
  IonCardHeader,
  IonLabel,
  IonCardContent,
  IonIcon,
  IonItem,
} from "@ionic/react";
import { caretForward, caretDown } from "ionicons/icons";

const questions: { [index: number]: FaqQuestionInterface } = {
  0: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
  1: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: `
        `,
      },
      {
        header: "",
        answer: `
        `,
      },
      {
        header: "",
        answer: `
        `,
      },
      {
        header: "",
        answer: `
        `,
      },
    ],
  },
  2: {
    question: " ",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ` `,
      },
    ],
  },
  3: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
  4: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
  5: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
  6: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
  7: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
  8: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },

  9: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
  10: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
  11: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
  12: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
      {
        header: "",
        answer: ``,
      },
      {
        header: "",
        answer: ``,
      },
    ],
  },
  13: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
  14: {
    question: "",
    isQuestionOpen: false,
    answers: [
      {
        header: "",
        answer: ``,
      },
    ],
  },
};

interface FaqQuestionInterface {
  question: string;
  isQuestionOpen: boolean;
  answers: { header: string; answer: string }[];
}

class FAQ extends React.Component<MBProps> {
  state = {
    faqQuestions: [] as FaqQuestionInterface[],
  };

  componentDidMount = () => {
    this.setState({ faqQuestions: Object.values(questions) });
  };
  render = () => {
    const { faqQuestions } = this.state;
    return (
      <>
        <Helmet>
          <title>MedBook - FAQ</title>
        </Helmet>
        <MBContainer {...this.props} activePage="faq">
          <IonCard className="faq-card">
            <IonCardHeader className="faq-card-header">
              <IonLabel className="faq-header-label mb-h2 ion-text-start">
                Frequently Asked Questions
              </IonLabel>
              <IonLabel className="faq-header-description mb-body ion-text-start">
                Listed below are some of the frequently asked questions we get
                from our customer support line. Feel free to browse them. If you
                have questions not covered here please click the contact us tab.
                Our customer care representatives will be happy to answer them
                for you.
              </IonLabel>
            </IonCardHeader>
            <IonCardContent>
              {faqQuestions.map((question, index) => {
                return (
                  <>
                    <IonItem
                      className="faq-item ion-no-margin ion-no-padding"
                      lines="none"
                      onClick={() => {
                        const currentIndexState = faqQuestions[index];
                        const updatedFAQ = _.cloneDeep(faqQuestions);
                        updatedFAQ[index] = {
                          ...currentIndexState,
                          isQuestionOpen: !currentIndexState.isQuestionOpen,
                        };

                        this.setState({ faqQuestions: updatedFAQ });
                      }}
                    >
                      <IonIcon
                        icon={
                          question.isQuestionOpen ? caretDown : caretForward
                        }
                      />
                      <IonLabel className="faq-question-label ion-text-wrap mb-h4 bold">
                        {question.question}
                      </IonLabel>
                    </IonItem>
                    {question.isQuestionOpen &&
                      question.answers.map(
                        (
                          answer: { header: string; answer: string },
                          index: any
                        ) => {
                          return (
                            <div className="faq-answers-container">
                              <IonLabel className="mb-body bold">
                                {answer.header}
                              </IonLabel>
                              <div
                                className="faq-answer-div-html mb-body"
                                dangerouslySetInnerHTML={{
                                  __html: answer.answer,
                                }}
                              ></div>
                            </div>
                          );
                        }
                      )}
                  </>
                );
              })}
            </IonCardContent>
          </IonCard>
        </MBContainer>
      </>
    );
  };
}

export default FAQ;
