import * as _ from "lodash";

import * as api from "../api";
import { firestore } from "../firebase";
import { getServerTimestamp } from "../functions/common";
import { DaysOfWeek } from "../interface";
import {
  Doctor,
  DoctorHospitals,
  DoctorHospitalsView,
  DoctorSchedule,
  ExecutiveAssistant,
  ExecutiveAssistantDetails,
  ExecutiveAssistantDoctorHospital,
  HospitalDoctorListView,
} from "../models";
import {
  DOCTOR_SCHEDULE,
  DOCTORS,
  DOCTOR_HOSPITALS,
  SPECIALITIES,
  HOSPITALS,
  EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL,
  EXECUTIVE_ASSISTANT,
} from "../constants/dbCollections";

export const createDoctorSchedule = async (
  doctorHospitalId: string,
  dayOfWeek: DaysOfWeek,
  slots: number,
  estimatedAppointmentMinuteLength: number,
  startTime: Date,
  endTime: Date
) => {
  await firestore.collection(DOCTOR_SCHEDULE).add({
    doctorHospitalId,
    dayOfWeek,
    slots,
    estimatedAppointmentMinuteLength,
    startTime,
    endTime,
    createdAt: new Date(),
    updatedAt: new Date(),
  });
};

export const getAllVerifiedDoctors = async (
  callback: (doctors: Doctor[]) => void
) => {
  firestore.collection(DOCTORS).onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      callback(
        _.filter(
          snapshot.docs.map((doc) => {
            const result = doc.data();
            return { ...result, docId: doc.id } as Doctor;
          }),
          (doctor) => {
            return _.isEmpty(doctor.initialPasswordCipher);
          }
        )
      );
    } else {
      callback([]);
    }
  });
};

export const getAllDoctors = async (callback: (doctors: Doctor[]) => void) => {
  firestore.collection(DOCTORS).onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      callback(
        snapshot.docs.map((doc) => {
          const result = doc.data();
          return { ...result, docId: doc.id } as Doctor;
        })
      );
    } else {
      callback([]);
    }
  });
};

export const getDoctor = async (doctorId: string) => {
  const result = await firestore.collection(DOCTORS).doc(doctorId).get();
  if (result.exists) {
    return { ...result.data(), docId: result.id } as Doctor;
  } else {
    return {} as Doctor;
  }
};

export const getDoctorHospitals = async (doctorId: string) => {
  const result = await firestore
    .collection(DOCTOR_HOSPITALS)
    .where("doctorId", "==", doctorId)
    .get();
  if (!result.empty) {
    const doctorHospitalView = await Promise.all(
      result.docs.map((doctorHospital) => {
        return new Promise(async (resolve) => {
          try {
            const doctorHospitalData = {
              ...doctorHospital.data(),
              docId: doctorHospital.id,
            } as DoctorHospitals;

            const hospital = await firestore
              .collection(HOSPITALS)
              .doc(doctorHospitalData.hospitalId)
              .get();

            const doctorSchedules = await firestore
              .collection(DOCTOR_SCHEDULE)
              .where("doctorHospitalId", "==", doctorHospital.id)
              .get();

            resolve({
              ...doctorHospitalData,
              hospitalDetails: {
                ...hospital.data(),
                docId: hospital.id,
              },
              schedules: doctorSchedules.docs.map((doctorSchedule) => {
                return {
                  ...doctorSchedule.data(),
                  docId: doctorSchedule.id,
                } as DoctorSchedule;
              }),
            } as DoctorHospitalsView);
          } catch (e) {
            resolve(null);
          }
        });
      })
    );

    return _.compact(doctorHospitalView) as DoctorHospitalsView[];
  } else {
    return [];
  }
};

export const getAllDoctorsNotInHospital = async (
  hospitalId: string,
  callback: (hospitalDoctorList: HospitalDoctorListView[]) => void
) => {
  try {
    firestore
      .collection(DOCTOR_HOSPITALS)
      .onSnapshot(async (doctorHospitals) => {
        if (!doctorHospitals.empty) {
          const unformattedData = await Promise.all(
            doctorHospitals.docs.map((doctorHospital) => {
              return new Promise(async (resolve) => {
                const doctorHospitalData = doctorHospital.data() as DoctorHospitals;

                if (doctorHospitalData.hospitalId !== hospitalId) {
                  const doctor = await firestore
                    .collection(DOCTORS)
                    .doc(doctorHospitalData.doctorId)
                    .get();

                  if (doctor.exists) {
                    const doctorData = {
                      docId: doctor.id,
                      ...doctor.data(),
                    } as Doctor;

                    const doctorSchedule = await firestore
                      .collection(DOCTOR_SCHEDULE)
                      .where("doctorHospitalId", "==", doctorHospital.id)
                      .get();
                    if (!doctorSchedule.empty) {
                      const doctorScheduleList = doctorSchedule.docs.map(
                        (doctorScheduleItem) => {
                          return {
                            ...doctorScheduleItem.data(),
                            docId: doctorScheduleItem.id,
                          } as DoctorSchedule;
                        }
                      );
                      resolve({
                        ...doctorData,
                        doctorHospitalId: doctorHospital.id,
                        fullName: `${doctorData.firstName} ${doctorData.lastName}`,
                        specialitiesFormatted: doctorHospitalData.specialities
                          .map((speciality) => speciality.name)
                          .join(", "),
                        specialities: doctorHospitalData.specialities,
                        schedule: doctorScheduleList,
                      } as HospitalDoctorListView);
                    } else {
                      // resolve(null);
                      resolve({
                        ...doctorData,
                        doctorHospitalId: doctorHospital.id,
                        fullName: `${doctorData.firstName} ${doctorData.lastName}`,
                        specialitiesFormatted: doctorHospitalData.specialities
                          .map((speciality) => speciality.name)
                          .join(", "),
                        specialities: doctorHospitalData.specialities,
                        schedule: [] as DoctorSchedule[],
                      } as HospitalDoctorListView);
                    }
                  } else {
                    resolve(null);
                  }
                } else {
                  resolve(null);
                }
              }) as Promise<null | HospitalDoctorListView>;
            })
          );

          callback(_.compact(_.filter(unformattedData)));
        } else {
          callback([]);
        }
      });
  } catch (e) {
    console.log("ERROR GETTING: getDetailedDoctorlistWithHospital", e);
    callback([]);
  }
};

export const getDetailedDoctorlistWithHospital = async (
  hospitalId: string,
  callback: (hospitalDoctorList: HospitalDoctorListView[]) => void
) => {
  try {
    firestore
      .collection(DOCTOR_HOSPITALS)
      .where("hospitalId", "==", hospitalId)
      .onSnapshot(
        async (doctorHospitals) => {
          if (!doctorHospitals.empty) {
            const unformattedData = await Promise.all(
              doctorHospitals.docs.map((doctorHospital) => {
                return new Promise(async (resolve) => {
                  const doctorHospitalData = doctorHospital.data() as DoctorHospitals;

                  const doctor = await firestore
                    .collection(DOCTORS)
                    .doc(doctorHospitalData.doctorId)
                    .get();

                  if (doctor.exists) {
                    const doctorData = {
                      docId: doctor.id,
                      ...doctor.data(),
                    } as Doctor;

                    const doctorSchedule = await firestore
                      .collection(DOCTOR_SCHEDULE)
                      .where("doctorHospitalId", "==", doctorHospital.id)
                      .get();
                    if (!doctorSchedule.empty) {
                      const doctorScheduleList = doctorSchedule.docs.map(
                        (doctorScheduleItem) => {
                          return {
                            ...doctorScheduleItem.data(),
                            docId: doctorScheduleItem.id,
                          } as DoctorSchedule;
                        }
                      );
                      resolve({
                        ...doctorData,
                        doctorHospitalId: doctorHospital.id,
                        fullName: `${doctorData.firstName} ${doctorData.lastName}`,
                        specialitiesFormatted: doctorHospitalData.specialities
                          .map((speciality) => speciality.name)
                          .join(", "),
                        specialities: doctorHospitalData.specialities,
                        schedule: doctorScheduleList,
                      } as HospitalDoctorListView);
                    } else {
                      // resolve(null);
                      resolve({
                        ...doctorData,
                        doctorHospitalId: doctorHospital.id,
                        fullName: `${doctorData.firstName} ${doctorData.lastName}`,
                        specialitiesFormatted: doctorHospitalData.specialities
                          .map((speciality) => speciality.name)
                          .join(", "),
                        specialities: doctorHospitalData.specialities,
                        schedule: [] as DoctorSchedule[],
                      } as HospitalDoctorListView);
                    }
                  } else {
                    resolve(null);
                  }
                }) as Promise<null | HospitalDoctorListView>;
              })
            );

            callback(_.compact(unformattedData));
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([]);
        }
      );
  } catch (e) {
    console.log("ERROR GETTING: getDetailedDoctorlistWithHospital", e);
    callback([]);
  }
};

export const getDetailedDoctorWithHospital = async (
  hospitalId: string,
  doctorId: string
): Promise<HospitalDoctorListView> => {
  try {
    const doctorHospitals = await firestore
      .collection(DOCTOR_HOSPITALS)
      .where("hospitalId", "==", hospitalId)
      .where("doctorId", "==", doctorId)
      .get();

    if (!doctorHospitals.empty) {
      const doctorHospitalData = {
        docId: doctorHospitals.docs[0].id,
        ...doctorHospitals.docs[0].data(),
      } as DoctorHospitals;

      const doctor = await firestore
        .collection(DOCTORS)
        .doc(doctorHospitalData.doctorId)
        .get();

      const executiveAssistantDoctorHospital = await firestore
        .collection(EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL)
        .where("doctorHospitalId", "==", doctorHospitalData.docId)
        .get();

      let executiveAssistant = {} as ExecutiveAssistant;
      if (!executiveAssistantDoctorHospital.empty) {
        const executiveAssistantId = (executiveAssistantDoctorHospital.docs[0].data() as ExecutiveAssistantDoctorHospital)
          .executiveAssistantId;
        const executiveQuery = await firestore
          .collection(EXECUTIVE_ASSISTANT)
          .doc(executiveAssistantId)
          .get();
        if (executiveQuery.exists) {
          executiveAssistant = {
            ...executiveQuery.data(),
            docId: executiveQuery.id,
          } as ExecutiveAssistant;
        }
      }

      if (doctor.exists) {
        const doctorData = {
          docId: doctor.id,
          ...doctor.data(),
        } as Doctor;
        const doctorSchedule = await firestore
          .collection(DOCTOR_SCHEDULE)
          .where("doctorHospitalId", "==", doctorHospitalData.docId!)
          .get();

        if (!doctorSchedule.empty) {
          const doctorScheduleList = doctorSchedule.docs.map(
            (doctorScheduleItem) => {
              return {
                ...doctorScheduleItem.data(),
                docId: doctorScheduleItem.id,
              } as DoctorSchedule;
            }
          );

          return {
            ...doctorData,
            doctorHospitalId: doctorHospitalData.docId!,
            fullName: `${doctorData.firstName} ${doctorData.lastName}`,
            specialitiesFormatted: doctorHospitalData.specialities
              .map((speciality) => speciality.name)
              .join(", "),
            executiveAssistant,
            specialities: doctorHospitalData.specialities,
            schedule: doctorScheduleList,
            startBreakTime: doctorHospitalData.startBreakTime,
            endBreakTime: doctorHospitalData.endBreakTime,
          } as HospitalDoctorListView;
        } else {
          return {
            ...doctorData,
            doctorHospitalId: doctorHospitalData.docId!,
            fullName: `${doctorData.firstName} ${doctorData.lastName}`,
            specialitiesFormatted: doctorHospitalData.specialities
              .map((speciality) => speciality.name)
              .join(", "),
            executiveAssistant,
            specialities: doctorHospitalData.specialities,
            schedule: [] as DoctorSchedule[],
            startBreakTime: doctorHospitalData.startBreakTime,
            endBreakTime: doctorHospitalData.endBreakTime,
          } as HospitalDoctorListView;
        }
      } else {
        return {} as HospitalDoctorListView;
      }
    } else {
      return {} as HospitalDoctorListView;
    }
  } catch (e) {
    console.log("ERROR GETTING: getDetailedDoctorlistWithHospital", e);
    return {} as HospitalDoctorListView;
  }
};

export const getDoctorSpecialitiesMap = async (doctorIds: string[]) => {
  const doctorSpecialitiesMap: { [doctorId: string]: string[] } = {};

  const doctorHospitals = await Promise.all(
    doctorIds.map((doctorId) => {
      return firestore
        .collection(DOCTOR_HOSPITALS)
        .where("doctorId", "==", doctorId)
        .get();
    })
  );

  doctorHospitals.forEach((doctorHospital) => {
    if (doctorHospital.docs.length > 0) {
      doctorHospital.docs.forEach((doc) => {
        const result = doc.data() as DoctorHospitals;
        const specialities = result.specialities;

        const currentSpecialty =
          _.cloneDeep(doctorSpecialitiesMap[result.doctorId || ""]) || [];
        specialities.forEach((speciality) => {
          currentSpecialty.push(speciality.name);
        });

        doctorSpecialitiesMap[result.doctorId || ""] = _.compact(
          _.uniq(currentSpecialty)
        );
      });
    }
  });

  return doctorSpecialitiesMap;
};

export const getSpecialtiesOptions = async (callback: (string: []) => void) => {
  firestore.collection(SPECIALITIES).onSnapshot(
    (specialities) => {
      if (specialities.docs.length > 0) {
        callback(specialities.docs[0].data().specialities);
      } else {
        callback([]);
      }
    },
    (error) => {
      callback([]);
    }
  );
};

export const addNewSpecialties = async (specialities: string[]) => {
  const oldSpecialties = await firestore.collection(SPECIALITIES).get();
  if (!oldSpecialties.empty) {
    const oldList =
      oldSpecialties.docs.length > 0
        ? oldSpecialties.docs[0].data().specialities
        : [];
    await oldSpecialties.docs[0].ref.update({
      specialities: _.uniq(oldList.concat(specialities)),
    });
  } else {
    await firestore.collection(SPECIALITIES).add({
      specialities: specialities,
    });
  }
};

export const doctorRemoveInitialPassword = async (
  doctorId: string,
  doctorDetail: Partial<Doctor>
) => {
  const machineDetails = await api.getUserMachineDetails();

  const result = await firestore
    .collection(DOCTORS)
    .doc(doctorId)
    .update({ ...doctorDetail, initialPasswordCipher: "", machineDetails });
  return result;
};

export const executiveAssistantRemoveInitialPassword = async (
  executiveAssistantId: string
) => {
  const machineDetails = await api.getUserMachineDetails();

  const result = await firestore
    .collection(EXECUTIVE_ASSISTANT)
    .doc(executiveAssistantId)
    .update({
      initialPasswordCipher: "",
      machineDetails,
    });
  return result;
};

export const updateDoctorDetail = async (
  doctorId: string,
  doctorDetail: Partial<Doctor>
) => {
  await firestore
    .collection(DOCTORS)
    .doc(doctorId)
    .update({ ...doctorDetail, updatedAt: new Date() });
};

export const updateDoctorHospitalDetail = async (
  doctorHospitalId: string,
  doctorHospitalDetail: Partial<DoctorHospitals>
) => {
  await firestore
    .collection(DOCTOR_HOSPITALS)
    .doc(doctorHospitalId)
    .update({ ...doctorHospitalDetail, updatedAt: new Date() });
};

export const updateDoctorHospitalSpecialties = async (
  doctorHospitalId: string,
  updatedDoctorHospital: Partial<DoctorHospitals>
) => {
  await firestore
    .collection(DOCTOR_HOSPITALS)
    .doc(doctorHospitalId)
    .update({ ...updatedDoctorHospital, updatedAt: new Date() });
};

export const updateDoctorVacationMode = async (
  doctorId: string,
  outOfOfficeStartDate: Date | null,
  outOfOfficeEndDate: Date | null
) => {
  await firestore.collection(DOCTORS).doc(doctorId).update({
    outOfOfficeStartDate,
    outOfOfficeEndDate,
  });
};

export const addExistingExecutiveAssistant = async (
  executiveAssistantId: string,
  doctorHospitalId: string
) => {
  await firestore.collection(EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL).add({
    executiveAssistantId,
    doctorHospitalId,
    createdAt: getServerTimestamp(),
    updatedAt: getServerTimestamp(),
  });
};

export const updateExecutiveAssistantDetail = async (
  executiveAssistantId: string,
  executiveAssistantDetail: Partial<ExecutiveAssistant>
) => {
  await firestore
    .collection(EXECUTIVE_ASSISTANT)
    .doc(executiveAssistantId)
    .update({ ...executiveAssistantDetail, updatedAt: new Date() });
};

export const getExecutiveAssistant = async (docId: string) => {
  const result = await firestore
    .collection(EXECUTIVE_ASSISTANT)
    .doc(docId)
    .get();
  if (result.exists) {
    return { ...result.data(), docId: result.id } as ExecutiveAssistant;
  } else {
    return {} as ExecutiveAssistant;
  }
};

export const findExecutiveAssistantByHospitalUID = async (
  hospitalUID: string
): Promise<ExecutiveAssistant> => {
  try {
    const executiveAssistant = await firestore
      .collection(EXECUTIVE_ASSISTANT)
      .where("hospitalUID", "==", hospitalUID)
      .get();
    if (!executiveAssistant.empty) {
      return {
        ...executiveAssistant.docs[0].data(),
        docId: executiveAssistant.docs[0].id,
      } as ExecutiveAssistant;
    } else {
      return {} as ExecutiveAssistant;
    }
  } catch (e) {
    console.log("ERROR IN findExecutiveAssistantByHospitalUID: ", e);
    return {} as ExecutiveAssistant;
  }
};

export const getExecutiveAssistantDoctorHospitals = (
  executiveAssistantId: string,
  callback: (doctorHospitals: DoctorHospitals[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore
      .collection(EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL)
      .where("executiveAssistantId", "==", executiveAssistantId)
      .onSnapshot(
        async (executiveAssistantDoctorHospitals) => {
          if (!executiveAssistantDoctorHospitals.empty) {
            const doctorHospitals = await Promise.all(
              executiveAssistantDoctorHospitals.docs.map(
                (execAssistantDocHospital) => {
                  return new Promise(async (resolve) => {
                    const execAssistantDocHospitalData = execAssistantDocHospital.data() as ExecutiveAssistantDoctorHospital;
                    const doctorHospital = await firestore
                      .collection(DOCTOR_HOSPITALS)
                      .doc(execAssistantDocHospitalData.doctorHospitalId)
                      .get();
                    if (doctorHospital.exists) {
                      resolve({
                        docId: doctorHospital.id,
                        ...doctorHospital.data(),
                      } as DoctorHospitals);
                    } else {
                      resolve(null);
                    }
                  }) as Promise<DoctorHospitals | null>;
                }
              )
            );

            callback(_.compact(doctorHospitals));
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([]);
        }
      );
    return unsubscribe;
  } catch (e) {
    callback([], e);
    return () => {};
  }
};

export const getDoctorHospitalExecutiveAssistant = async (
  doctorHospitalIds: string[],
  callback: (
    executiveAssistants: ExecutiveAssistantDetails[],
    error?: string
  ) => void
) => {
  try {
    firestore
      .collection(EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL)
      .where("doctorHospitalId", "in", doctorHospitalIds)
      .onSnapshot(
        async (executiveAssistantDoctorHospital) => {
          if (!executiveAssistantDoctorHospital.empty) {
            const result = await Promise.all(
              executiveAssistantDoctorHospital.docs.map(
                (executiveAssistantDoctorHospitalDoc) => {
                  const executiveAssistantDoctorHospitalData = executiveAssistantDoctorHospitalDoc.data() as ExecutiveAssistantDoctorHospital;

                  return new Promise(async (resolve) => {
                    const executiveAssistantData = await firestore
                      .collection(EXECUTIVE_ASSISTANT)
                      .doc(
                        executiveAssistantDoctorHospitalData.executiveAssistantId
                      )
                      .get();

                    if (executiveAssistantData.exists) {
                      resolve({
                        executiveAssistantDetails: {
                          docId: executiveAssistantData.id,
                          ...executiveAssistantData.data(),
                        } as ExecutiveAssistant,
                        executiveAssistantDoctorHospitalDetails: {
                          docId: executiveAssistantDoctorHospitalDoc.id,
                          ...executiveAssistantDoctorHospitalData,
                        } as ExecutiveAssistantDoctorHospital,
                      });
                    } else {
                      resolve(null);
                    }
                  }) as Promise<ExecutiveAssistantDetails | null>;
                }
              )
            );

            callback(_.compact(result));
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([], error.message);
        }
      );
  } catch (e) {
    console.log("ERROR IN getDoctorHospitalExecutiveAssistant: ", e);
    callback([], e);
  }
};

export const getExecutiveAssistantDoctorIds = async (
  executiveAssistantId: string
) => {
  const executiveAssistantDoctorHospitalList = await firestore
    .collection(EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL)
    .where("executiveAssistantId", "==", executiveAssistantId)
    .get();

  if (!executiveAssistantDoctorHospitalList.empty) {
    const result = await Promise.all(
      executiveAssistantDoctorHospitalList.docs.map(
        (executiveAssistantDoctorHospital) => {
          const executiveAssistantDoctorHospitalData = executiveAssistantDoctorHospital.data() as ExecutiveAssistantDoctorHospital;
          return new Promise(async (resolve) => {
            const doctorHospital = await firestore
              .collection(DOCTOR_HOSPITALS)
              .doc(executiveAssistantDoctorHospitalData.doctorHospitalId)
              .get();
            if (doctorHospital.exists) {
              const doctorHospitalData = doctorHospital.data() as DoctorHospitals;
              resolve(doctorHospitalData.doctorId);
            } else {
              resolve(null);
            }
          }) as Promise<string | null>;
        }
      )
    );
    return _.compact(result);
  } else {
    return [];
  }
};

export const removeExecutiveAssistant = async (
  executiveAssistantId: string,
  doctorHospitalId: string
) => {
  const assistantDoctorHospital = await firestore
    .collection(EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL)
    .where("executiveAssistantId", "==", executiveAssistantId)
    .where("doctorHospitalId", "==", doctorHospitalId)
    .get();

  if (!assistantDoctorHospital.empty) {
    await assistantDoctorHospital.docs[0].ref.delete();
  }
};
