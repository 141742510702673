import _ from "lodash";
import React from "react";
import { ellipsisHorizontal, pencilOutline, trashBin } from "ionicons/icons";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
  IonButton,
  IonItem,
  IonList,
  IonPopover,
} from "@ionic/react";

import { ExecutiveAssistant, TimeKeeping } from "../../models";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import { MBCOLORS } from "../../constants/config";

import "./TimeKeepingAccount.scss";
import { MBTimeKeepingDetailCard } from "../../components/MBTimeKeepingDetailCard/MBTimeKeepingDetailCard";

class TimeKeepingAccount extends React.Component<MBProps> {
  state = {
    timeKeepingAccount: this.props.authUser.userDetails as TimeKeeping,
    showActionButtons: false,
    actionButtonsEvent: undefined as any,
    isOpenEditAccount: false,
  };

  componentDidUpdate = () => {
    this.updateExecAccount();
  };

  updateExecAccount = async () => {
    const { timeKeepingAccount } = this.state;
    const updatedAccount = this.props.authUser.userDetails as TimeKeeping;
    if (
      timeKeepingAccount.firstName !== updatedAccount.firstName ||
      timeKeepingAccount.lastName !== updatedAccount.lastName ||
      timeKeepingAccount.phoneNumber !== updatedAccount.phoneNumber ||
      timeKeepingAccount.designation !== updatedAccount.designation
    ) {
      this.setState({
        timeKeepingAccount: this.props.authUser.userDetails as TimeKeeping,
      });
    }
  };
  render = () => {
    const { timeKeepingAccount } = this.state;
    return (
      <MBContainer {...this.props} activePage="account">
        <MBTimeKeepingDetailCard
          {...this.props}
          details={timeKeepingAccount}
          hideRemove={true}
        />
      </MBContainer>
    );
  };
}

export default TimeKeepingAccount;
