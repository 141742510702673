import moment from "moment";
import * as _ from "lodash";
import { Helmet } from "react-helmet";
import React from "react";
import { IonLoading, IonToast } from "@ionic/react";

import "./DoctorScan.scss";
import * as services from "../../services";
import { HospitalDoctorAppointmentsView } from "../../models";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps, UserType } from "../../interface";
import { MBTimeKeepingScan } from "../../components/MBTimeKeepingScan/MBTimeKeepingScan";
import { MBQRresultDialog } from "../../components/MBQRresultDialog/MBQRresultDialog";
import { MSGS_COMMON } from "../../constants/messages";
import {
  APPOINTMENT_STATUS_TYPES,
  MBCOLORS,
  USER_TYPES,
} from "../../constants/config";

class DoctorScan extends React.Component<MBProps> {
  state = {
    loading: true,
    isResultFound: false,
    qrResultOpenDialog: false,
    selected: 0,
    successConfirmArrival: false,
    qrAppointment: {} as HospitalDoctorAppointmentsView,
    isDoctorAppointment: false,
    error: "",
  };
  //todo: get doctors appointments to check
  componentDidMount = () => {
    this.getAllDoctors();
  };

  getAllDoctors = () => {
    services.getAllDoctors((doctors) => {
      this.setState({
        loading: false,
        doctors,
      });
    });
  };

  getAppointment = async (qrAppointmentId: string) => {
    try {
      const qrResult = await services.getAppointment(qrAppointmentId, true);
      const isResultFound = !_.isEmpty(qrResult);
      this.setState({
        isResultFound,
        qrResultOpenDialog: true,
        qrAppointment: qrResult,
      });
    } catch (e) {
      this.setState({
        isResultFound: false,
        qrResultOpenDialog: true,
        qrAppointment: {},
      });
    }
  };

  updateAppointmentStatus = async (appointmentId: string) => {
    try {
      const { authUser } = this.props;
      await services.pushAppointmentProgress(
        appointmentId,
        true,
        APPOINTMENT_STATUS_TYPES.onGoing.id,
        authUser.uid,
        authUser.userType as UserType
      );
      this.setState({ successConfirmArrival: true });
    } catch (error) {
      this.setState({ error });
    }
  };

  render = () => {
    const {
      loading,
      isResultFound,
      qrResultOpenDialog,
      qrAppointment,
      successConfirmArrival,
      error,
    } = this.state;

    const { userType } = this.props.authUser;

    return (
      <>
        <Helmet>
          <title>
            MedBook -{" "}
            {userType === USER_TYPES.doctors.id
              ? "Doctor"
              : "Executive Assistant"}{" "}
            - Scan Code
          </title>
        </Helmet>
        <MBContainer
          {...this.props}
          activePage={
            userType === USER_TYPES.doctors.id
              ? "doctor-scan"
              : "executive-assistant-scan"
          }
        >
          <div className="doctor-scan-container">
            <MBTimeKeepingScan
              mode="doctor"
              isResultFound={isResultFound}
              onQRScan={(qrResult) => {
                this.getAppointment(qrResult);
              }}
            />
          </div>
          <MBQRresultDialog
            mode="hospital"
            isOpen={qrResultOpenDialog}
            onDidDismiss={() => {
              this.setState({
                qrResultOpenDialog: false,
                isResultFound: false,
              });
            }}
            qrData={qrAppointment}
            successConfirmArrival={successConfirmArrival}
            onDidDismissSuccessConfirm={() => {
              this.setState({
                successConfirmArrival: false,
                qrResultOpenDialog: false,
                isResultFound: false,
              });
            }}
            onConfirmArrival={(appointmentId: string) => {
              this.updateAppointmentStatus(appointmentId);
            }}
          />
          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />
          <IonToast
            isOpen={!_.isEmpty(error)}
            message={error}
            duration={2000}
            onDidDismiss={() => this.setState({ error: "" })}
            color={MBCOLORS.danger}
          />
        </MBContainer>
      </>
    );
  };
}

export default DoctorScan;
