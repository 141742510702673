import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import {
  IonIcon,
  IonButton,
  IonModal,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";

import "./MBCalendarRangeDialog.scss";
import {
  dateTomorrow,
  dateYesterday,
  daysSinceDate,
} from "../../functions/common";
import { MBCalendar } from "../MBCalendar/MBCalendar";
import { MBCard } from "../MBCard/MBCard";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBInput } from "../MBInput/MBInput";
import { MBCOLORS } from "../../constants/config";

interface MBCalendarRangeDialogProps {
  title: string;
  message?: string;
  indefiniteEndDateLabel?: string;
  errorMessage: string;
  isOpen: boolean;
  hasEasyOptions?: boolean;
  icon?:
    | "success"
    | "warning"
    | "transportation"
    | "no-transportation"
    | "download";
  singleMode?: boolean;
  isHistorical?: boolean;
  maxRange?: number;
  onDidDismiss: () => void;
  onSubmit: (startDate: Date, endDate: Date | null) => void;
  onCancel: () => void;
}

export const MBCalendarRangeDialog = (props: MBCalendarRangeDialogProps) => {
  const {
    isOpen,
    title,
    message,
    indefiniteEndDateLabel,
    errorMessage,
    icon,
    hasEasyOptions,
    singleMode,
    isHistorical,
    maxRange,
    onDidDismiss,
    onSubmit,
    onCancel,
  } = props;

  const [selectedDate, setSelectedDate] = useState(null as Date | null);
  const [startDate, setStartDate] = useState(null as Date | null);
  const [endDate, setEndDate] = useState(null as Date | null);
  const [selectedEasyValue, setSelectedEasyValue] = useState("");

  const [isIndefiniteEndDate, setIsIndefiniteEndDate] = useState(false);
  const [isStartDateDialogOpen, setIsStartDateDialogOpen] = useState(false);
  const [isEndDateDialogOpen, setIsEndDateDialogOpen] = useState(false);
  const [invalidFormDialogOpen, setInvalidFormDialogOpen] = useState(false);

  const onDateSelected = () => {
    if (_.isNull(startDate)) {
      setInvalidFormDialogOpen(true);
    } else if (
      indefiniteEndDateLabel === undefined &&
      !singleMode &&
      _.isNull(endDate)
    ) {
      setInvalidFormDialogOpen(true);
    } else if (
      indefiniteEndDateLabel !== undefined &&
      _.isNull(endDate) &&
      !isIndefiniteEndDate &&
      !singleMode
    ) {
      setInvalidFormDialogOpen(true);
    } else {
      const clonedStartDate = _.clone(startDate);
      clonedStartDate.setHours(0);
      clonedStartDate.setMinutes(0);
      clonedStartDate.setSeconds(0);
      clonedStartDate.setMilliseconds(0);
      const clonedEndDate = _.clone(!_.isNull(endDate) ? endDate : new Date());
      clonedEndDate.setHours(23);
      clonedEndDate.setMinutes(59);
      clonedEndDate.setSeconds(59);
      clonedEndDate.setMilliseconds(59);
      onSubmit(
        clonedStartDate,
        isIndefiniteEndDate || singleMode ? null : clonedEndDate
      );
    }
  };

  const currentDay = new Date();
  const nextWeek = moment(new Date()).add(1, "week").toDate();

  const firstMonthDay = new Date(new Date().setDate(1));
  const nextMonth = moment(firstMonthDay).add(1, "month").toDate();

  return (
    <IonModal
      cssClass="mb-calendar-range-dialog"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <div className="modal-content">
        <div className="mb-calendar-range-dialog-children-container">
          {icon !== undefined && (
            <IonIcon className={`mb-calendar-range-dialog-icon ${icon}`} />
          )}
          <IonLabel className="mb-calendar-range-dialog-title-label mb-h2">
            {title}
          </IonLabel>
          {!_.isEmpty(message) && (
            <IonLabel className="mb-calendar-range-dialog-message-label mb-body">
              {message}
            </IonLabel>
          )}
          {!!hasEasyOptions && (
            <IonRadioGroup
              color={MBCOLORS.tertiary}
              className="mb-calendar-range-dialog-radio-container ion-no-padding"
              value={selectedEasyValue}
              onIonChange={(e) => {
                const value = e.detail.value;
                setSelectedEasyValue(value);
                if (value === "weekly") {
                  setStartDate(currentDay);
                  setEndDate(nextWeek);
                } else if (value === "monthly") {
                  setStartDate(firstMonthDay);
                  setEndDate(nextMonth);
                } else {
                  setStartDate(null);
                  setEndDate(null);
                }
              }}
            >
              <IonItem
                color={MBCOLORS.tertiary}
                className="ion-no-padding"
                lines="none"
              >
                <IonLabel className="mb-body">
                  1 week: {moment(currentDay).format("MM/DD")} -{" "}
                  {moment(nextWeek).format("MM/DD")}
                </IonLabel>
                <IonRadio
                  className="mb-calendar-radio-range ion-no-margin"
                  mode="md"
                  slot="start"
                  value="weekly"
                />
              </IonItem>

              <IonItem
                color={MBCOLORS.tertiary}
                className="ion-no-padding"
                lines="none"
              >
                <IonLabel className="mb-body">
                  1 month: {moment(firstMonthDay).format("MM/YYYY")} -{" "}
                  {moment(nextMonth).format("MM/YYYY")}
                </IonLabel>
                <IonRadio
                  className="mb-calendar-radio-range ion-no-margin"
                  mode="md"
                  slot="start"
                  value="monthly"
                />
              </IonItem>

              <IonItem
                color={MBCOLORS.tertiary}
                className="ion-no-padding"
                lines="none"
              >
                <IonLabel className="mb-body">
                  Select start and end date
                </IonLabel>
                <IonRadio
                  className="mb-calendar-radio-range ion-no-margin"
                  mode="md"
                  slot="start"
                  value="custom"
                />
              </IonItem>
            </IonRadioGroup>
          )}
          {(!hasEasyOptions ||
            (hasEasyOptions && selectedEasyValue === "custom")) && (
            <IonGrid className="mb-calendar-range-dialog-dates-container ion-no-padding">
              <IonRow>
                <IonCol className="ion-no-margin ion-no-padding" size="12">
                  <MBInput
                    label="Start Date"
                    placeholder="MM/DD/YYYY"
                    value={
                      !_.isNull(startDate)
                        ? moment(startDate).format("MM/DD/YYYY").toString()
                        : ""
                    }
                    onClick={() => {
                      setIsStartDateDialogOpen(true);
                    }}
                    type="text"
                    readonly={true}
                  />
                </IonCol>
              </IonRow>
              {!singleMode && (
                <IonRow>
                  <IonCol
                    className="ion-no-margin ion-no-padding ion-padding-top"
                    size="12"
                  >
                    <MBInput
                      label="End Date"
                      placeholder="MM/DD/YYYY"
                      value={
                        !_.isNull(endDate)
                          ? moment(endDate).format("MM/DD/YYYY").toString()
                          : ""
                      }
                      onClick={() => {
                        setIsEndDateDialogOpen(true);
                      }}
                      type="text"
                      disabled={isIndefiniteEndDate || _.isNull(startDate)}
                      readonly={true}
                    />
                  </IonCol>
                </IonRow>
              )}
              {indefiniteEndDateLabel !== undefined && (
                <IonRow>
                  <IonCol
                    className="ion-no-margin ion-no-padding ion-padding-top"
                    size="12"
                  >
                    <IonItem
                      className="doctor-account-setup-schedule-item ion-no-padding"
                      lines="none"
                    >
                      <IonCheckbox
                        mode="md"
                        checked={isIndefiniteEndDate}
                        onIonChange={(event) => {
                          setIsIndefiniteEndDate(event.detail.checked);
                          setEndDate(null);
                        }}
                        className="ion-margin-end ion-no-padding"
                      />
                      <IonLabel className="mb-body">
                        {indefiniteEndDateLabel}
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              )}
            </IonGrid>
          )}
          <div className="mb-calendar-range-dialog-options-container">
            <IonButton
              onClick={onCancel}
              className="mb-calendar-range-dialog-no-button mb-body ion-text-capitalize"
              fill="clear"
            >
              Cancel
            </IonButton>
            <IonButton
              onClick={onDateSelected}
              className="mb-calendar-range-dialog-submit-button mb-body bold white ion-text-capitalize"
              color={MBCOLORS.primary}
            >
              Submit
            </IonButton>
          </div>
        </div>
      </div>

      <IonModal
        cssClass="mb-web-dialog"
        isOpen={isStartDateDialogOpen || isEndDateDialogOpen}
        onDidDismiss={() => {
          if (isStartDateDialogOpen) {
            setIsStartDateDialogOpen(false);
          } else {
            setIsEndDateDialogOpen(false);
          }
        }}
      >
        <MBCard
          onBack={() => {
            if (isStartDateDialogOpen) {
              setIsStartDateDialogOpen(false);
            } else {
              setIsEndDateDialogOpen(false);
            }
          }}
          onSubmit={() => {
            if (isStartDateDialogOpen) {
              setStartDate(selectedDate);
              setIsStartDateDialogOpen(false);
            } else {
              setEndDate(selectedDate);
              setIsEndDateDialogOpen(false);
            }
          }}
          noHeader={true}
        >
          <MBCalendar
            {...(!isHistorical
              ? {
                  minDate: _.isNull(startDate) ? dateTomorrow() : startDate,
                }
              : { maxDate: dateYesterday() })}
            {...(!_.isNull(startDate) &&
              maxRange !== undefined && {
                maxDate:
                  isHistorical &&
                  daysSinceDate(startDate, dateYesterday()) < maxRange
                    ? dateYesterday()
                    : moment(startDate).add(maxRange, "days").toDate(),
              })}
            onDateChange={(date) => {
              setSelectedDate(date);
            }}
          />
        </MBCard>
      </IonModal>

      <MBDialog
        isOpen={invalidFormDialogOpen}
        icon="warning"
        title="Please complete the information needed"
        message={errorMessage}
        onDidDismiss={() => {
          setInvalidFormDialogOpen(false);
        }}
        onClick={() => {
          setInvalidFormDialogOpen(false);
        }}
      />
    </IonModal>
  );
};
