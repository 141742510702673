/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import React, { useState, useRef } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSlides,
  IonSlide,
  IonLoading,
  IonToast,
  IonImg,
} from "@ionic/react";

import "./HospitalApplicationDetails.scss";
import * as api from "../../api";
import * as email from "../../functions/email";
import * as sms from "../../functions/sms";
import * as services from "../../services";
import { analytics } from "../../firebase";
import { useEffectOnlyOnce, isMobile } from "../../functions/common";
import { CancelReasonDialog } from "../CancelReasonDialog/CancelReasonDialog";
import { Hospital } from "../../models";
import { MBCard } from "../MBCard/MBCard";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBInput } from "../MBInput/MBInput";
import { MSGS_COMMON } from "../../constants/messages";
import { MBCOLORS, ANALYTICS_CONTENT_TYPES } from "../../constants/config";

interface HospitalApplicationDetailsProps {
  onCancel: () => void;
  applicationData: Hospital;
  readonly?: boolean;
}
export const HospitalApplicationDetails = (
  props: HospitalApplicationDetailsProps
) => {
  const { applicationData, onCancel, readonly } = props;

  const slidesRef: React.RefObject<HTMLIonSlidesElement> = useRef(null);

  const [
    isApproveConfirmationDialogOpen,
    setIsApproveConfirmationDialogOpen,
  ] = useState(false);

  const [
    isRejectApplicationDialogOpen,
    setIsRejectApplicationDialogOpen,
  ] = useState(false);

  const [
    isSuccessVerificationDialogOpen,
    setIsSuccessVerificationDialogOpen,
  ] = useState(false);

  const [
    isSuccessRejectionDialogOpen,
    setIsSuccessRejectionDialogOpen,
  ] = useState(false);

  const [rejectReason, setRejectReason] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffectOnlyOnce(() => {});

  const onSubmit = async () => {
    try {
      setLoading(true);
      await services.approveApplication(applicationData);
      analytics.logEvent("approve_hospital_application", {
        content_type: ANALYTICS_CONTENT_TYPES.supportAccount.type,
      });
      setIsSuccessVerificationDialogOpen(true);
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  const onReject = async (reason: string) => {
    try {
      setLoading(true);

      try {
        const { subject, message } = email.rejectedHospitalRegistration(
          `${applicationData.firstName} ${applicationData.lastName}`,
          `${applicationData.hospitalName} - ${applicationData.department}`,
          reason
        );

        await services.sendEmail(
          applicationData.emailAddress,
          subject,
          message
        );

        const smsMessage = sms.rejectedHospitalRegistration(
          `${applicationData.firstName} ${applicationData.lastName}`,
          `${applicationData.hospitalName} - ${applicationData.department}`,
          reason
        );
        await api.sendSMS(applicationData.phoneNumber, smsMessage);
        analytics.logEvent("reject_hospital_application", {
          content_type: ANALYTICS_CONTENT_TYPES.supportAccount.type,
          reason: reason,
        });
      } catch (error) {
        console.log("ERROR  SENDING NOTIFS", error);
      }
      await api.rejectHospitalApplication(applicationData);

      setIsSuccessRejectionDialogOpen(true);
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  return (
    <MBCard
      title="Account Information"
      titleDescription="Hospital Representative"
      subtitle="Information provided by hospital representative"
      onBack={() => {
        onCancel();
      }}
      onSubmit={() => {
        setIsApproveConfirmationDialogOpen(true);
      }}
      onReject={() => {
        setIsRejectApplicationDialogOpen(true);
      }}
      readonly={readonly !== undefined ? readonly : false}
    >
      <IonSlides
        ref={slidesRef}
        onIonSlidesDidLoad={() => {
          if (!_.isNull(slidesRef)) {
            slidesRef.current!.lockSwipes(true);
          }
        }}
      >
        <IonSlide id="hospital-account-setup-form-step-1" tabIndex={0}>
          <div className="hospital-account-setup-form-container">
            <div className="hospital-account-setup-form-description-container">
              <IonLabel className="mb-h2 ion-text-start">
                User Information
              </IonLabel>
            </div>
            <IonGrid className="hospital-account-setup-form-container-grid ion-no-padding">
              <IonRow>
                <IonCol
                  className="hospital-account-setup-form-container-col ion-no-padding"
                  size={`${isMobile() ? "12" : "6"}`}
                >
                  <MBInput
                    label="First name"
                    value={applicationData.firstName}
                    type="text"
                    readonly={true}
                  />
                </IonCol>
                <IonCol
                  className="hospital-account-setup-form-container-col ion-no-padding"
                  size={`${isMobile() ? "12" : "6"}`}
                >
                  <MBInput
                    label="Last name"
                    value={applicationData.lastName}
                    type="text"
                    readonly={true}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className="hospital-account-setup-form-container-col ion-no-padding"
                  size={`${isMobile() ? "12" : "6"}`}
                >
                  <MBInput
                    label="Mobile Number"
                    value={applicationData.phoneNumber}
                    type="tel"
                    readonly={true}
                  />
                </IonCol>
                <IonCol
                  className="hospital-account-setup-form-container-col ion-no-padding"
                  size={`${isMobile() ? "12" : "6"}`}
                >
                  <MBInput
                    label="Email Address"
                    value={applicationData.emailAddress}
                    type="email"
                    readonly={true}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <MBDivider />
            <div className="hospital-account-setup-form-footer-description-container">
              <IonLabel className="mb-h2 ion-text-start">
                Verification requirements
              </IonLabel>
              <IonLabel className="mb-body ion-text-start">
                For the protection of all parties we would require the
                following: Any Government ID and a picture of your Hospital ID.
              </IonLabel>
            </div>

            <IonGrid className="hospital-account-setup-form-container-grid footer ion-no-padding">
              <IonRow>
                <IonCol
                  className="hospital-account-setup-form-container-col ion-no-padding"
                  size="12"
                >
                  <IonLabel className="mb-paragraph bold" position="stacked">
                    Goverment ID
                  </IonLabel>

                  <IonImg
                    className="hospital-account-setup-form-id"
                    src={applicationData.governmentId}
                    onClick={() => {
                      window.open(applicationData.governmentId, "_blank");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className="hospital-account-setup-form-container-col ion-no-padding"
                  size="12"
                >
                  <IonLabel className="mb-paragraph bold" position="stacked">
                    Hospital ID
                  </IonLabel>

                  <IonImg
                    className="hospital-account-setup-form-id"
                    src={applicationData.identificationCard}
                    onClick={() => {
                      window.open(applicationData.identificationCard, "_blank");
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <div className="hospital-account-setup-form-description-container">
              <IonLabel className="mb-h2 ion-text-start">Location</IonLabel>
            </div>
            <IonGrid className="hospital-account-setup-form-container-grid ion-no-padding">
              <IonRow>
                <IonCol
                  className="hospital-account-setup-form-container-col ion-no-padding"
                  size="12"
                >
                  <div
                    className={`hospital-account-setup-form-container-col-select-hospital ${
                      isMobile() && "mobile"
                    }`}
                  >
                    <MBInput
                      label="Department"
                      value={applicationData.department}
                      type="text"
                      readonly={true}
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className="hospital-account-setup-form-container-col ion-no-padding"
                  size="12"
                >
                  <div
                    className={`hospital-account-setup-form-container-col-select-hospital ${
                      isMobile() && "mobile"
                    }`}
                  >
                    <MBInput
                      label="Institution/Hospital name"
                      value={applicationData.hospitalName}
                      type="text"
                      readonly={true}
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className="hospital-account-setup-form-container-col ion-no-padding"
                  size="12"
                >
                  <MBInput
                    label="Institution/Hospital address"
                    value={`${applicationData.province} ${applicationData.cityMunicipality}`}
                    type="text"
                    readonly={true}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonSlide>
      </IonSlides>

      <MBDialog
        isOpen={isApproveConfirmationDialogOpen}
        title="You are about to approve a new hospital account"
        message="Are you sure you want to approve this application?"
        onDidDismiss={() => {
          setIsApproveConfirmationDialogOpen(false);
        }}
        onApprove={() => {
          onSubmit();
          setIsApproveConfirmationDialogOpen(false);
        }}
        onDecline={() => {
          setIsApproveConfirmationDialogOpen(false);
        }}
      />

      <CancelReasonDialog
        mode="application"
        isOpen={isRejectApplicationDialogOpen}
        icon="warning"
        title="Please select your reason for cancelling."
        onDidDismiss={() => {
          setIsRejectApplicationDialogOpen(false);
        }}
        onApprove={(reason) => {
          // send reason to email here
          console.log("GOT REASON", reason);
          setRejectReason(reason);
          onReject(reason);
          setIsRejectApplicationDialogOpen(false);
        }}
        onDecline={() => {
          setIsRejectApplicationDialogOpen(false);
        }}
      />

      <MBDialog
        isOpen={isSuccessVerificationDialogOpen}
        icon="success"
        title="Account Verified"
        message="You have verified this account. They will now be able to add their services and will show up in the list of approved hospitals."
        onDidDismiss={() => {
          setIsSuccessVerificationDialogOpen(false);
          onCancel();
        }}
      />

      <MBDialog
        isOpen={isSuccessRejectionDialogOpen}
        icon="warning"
        title="Verification Request Rejected"
        message={`You have rejected this request because of the following reason: ${rejectReason}. An email will be sent to them regarding their application.`}
        onDidDismiss={() => {
          setIsSuccessRejectionDialogOpen(false);
          onCancel();
        }}
      />

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </MBCard>
  );
};
