/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonCheckbox,
  IonItem,
  IonInput,
  IonModal,
  IonButton,
  IonDatetime,
} from "@ionic/react";

import "./ServiceAccountSetup.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { analytics } from "../../firebase";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBInput } from "../MBInput/MBInput";
import {
  MBDropdownSelect,
  MBDropdownSelectOption,
} from "../MBDropdownSelect/MBDropdownSelect";
import { MBProps, DaysOfWeek, HospitalServiceSchedule } from "../../interface";
import {
  formatString,
  useEffectOnlyOnce,
  isMobile,
  getServerTimestamp,
} from "../../functions/common";
import { MBDivider } from "../MBDivider/MBDivider";
import { Hospital, HospitalService, ServiceSchedule } from "../../models";
import { MSGS_COMMON, MSGS_HOSPITAL_SERVICES } from "../../constants/messages";
import {
  MBCOLORS,
  DAYS_OF_WEEK,
  ANALYTICS_CONTENT_TYPES,
} from "../../constants/config";

interface ServiceAccountSetupProps extends MBProps {
  onCancel: () => void;
  isOpen: boolean;
  isEdit?: boolean;
  editData?: HospitalServiceSchedule;
  hospitalId: string;
  onBack: () => void;
  hospitalName: string;
  onRefreshData?: () => void;
}

interface SelectScheduleDays {
  dayOfWeek: number;
  name: string;
  slots: number | null;
  isDayChecked: boolean;
  time: string; //firebase.firestore.Timestamp;
  docId?: string;
  estimatedAppointmentMinuteLength: number;
}
export const ServiceAccountSetup = (props: ServiceAccountSetupProps) => {
  const { onBack, isOpen, authUser, onRefreshData, editData, isEdit } = props;
  const hospitalId = (authUser.userDetails as Hospital).docId || "";
  const hospitalName = (authUser.userDetails as Hospital).hospitalName;

  const scheduleDays = Object.values(DAYS_OF_WEEK).map((day) => {
    return {
      estimatedAppointmentMinuteLength:
        !!editData &&
        !_.isEmpty(
          _.find(
            editData.schedules,
            (schedule) => schedule.dayOfWeek === day.day
          )
        )
          ? _.find(
              editData.schedules,
              (schedule) => schedule.dayOfWeek === day.day
            )!.estimatedAppointmentMinuteLength
          : 15,
      dayOfWeek: day.day,
      name: day.name,
      slots:
        !!editData &&
        !_.isEmpty(
          _.find(
            editData.schedules,
            (schedule) => schedule.dayOfWeek === day.day
          )
        )
          ? _.find(
              editData.schedules,
              (schedule) => schedule.dayOfWeek === day.day
            )!.slots
          : 0,
      isDayChecked:
        !!editData &&
        !_.isEmpty(
          _.find(
            editData.schedules,
            (schedule) => schedule.dayOfWeek === day.day
          )
        )
          ? true
          : false,
      docId:
        !!editData &&
        !_.isEmpty(
          _.find(
            editData.schedules,
            (schedule) => schedule.dayOfWeek === day.day
          )
        )
          ? _.find(
              editData.schedules,
              (schedule) => schedule.dayOfWeek === day.day
            )!.docId
          : "",
      time:
        !!editData &&
        !_.isEmpty(
          _.find(
            editData.schedules,
            (schedule) => schedule.dayOfWeek === day.day
          )
        )
          ? _.find(
              editData.schedules,
              (schedule) => schedule.dayOfWeek === day.day
            )!
              .startTime.toDate()
              .toISOString()
          : new Date(0, 0, 0).toISOString(),
    };
  });

  const [treatmentName, setTreatmentName] = useState(
    !!editData && editData.treatmentName ? editData.treatmentName : ""
  );
  const [contactNumber, setContactNumber] = useState(
    !!editData && editData.contactNumber ? editData.contactNumber : ""
  );
  const [secondaryContactNumber, setSecondaryContactNumber] = useState(
    !!editData && editData.secondaryContactNumber
      ? editData.secondaryContactNumber
      : ""
  );
  const [location, setLocation] = useState(
    !!editData && editData.location ? editData.location : ""
  );
  const [selectedScheduleDays, setSelectedScheduleDays] = useState(
    scheduleDays as SelectScheduleDays[]
  );
  const [serviceFee, setServiceFee] = useState(
    !!editData && editData.fee ? editData.fee.toString() : ""
  );
  const [existingServices, setExistingServices] = useState([] as string[]);

  const existingTimeOfService = !!editData
    ? _.find(editData.schedules, (schedule) => {
        return !!schedule || [];
      })
    : "";

  const [
    selectedEstimatedTimeService,
    setSelectedEstimatedTimeService,
  ] = useState(
    !!editData && !_.isEmpty(editData.schedules)
      ? ({
          id:
            !!existingTimeOfService &&
            existingTimeOfService.estimatedAppointmentMinuteLength.toString(),
          name: `${
            !!existingTimeOfService &&
            existingTimeOfService.estimatedAppointmentMinuteLength.toString()
          } Minutes`,
        } as MBDropdownSelectOption)
      : ({ id: "15", name: "15 Minutes" } as MBDropdownSelectOption)
  );
  const [maxNoOfPatient, setMaxNoOfPatient] = useState(
    !!editData && editData.maxNoOfPatient
      ? editData.maxNoOfPatient.toString()
      : ""
  );

  const [errorMsgTreatmentName, setErrorMsgTreatmentName] = useState("");
  const [errorMsgContactNumber, setErrorMsgContactNumber] = useState("");
  const [
    errorMsgSecondContactNumber,
    setErrorMsgSecondaryContactNumber,
  ] = useState("");
  const [errorMsgLocation, setErrorMsgLocation] = useState("");
  const [errorMsgServiceFee, setErrorMsgServiceFee] = useState("");
  const [errorMsgEstimatedService, setErrorMsgEstimatedService] = useState("");
  const [errorMsgMaxNoOfPatient, setErrorMsgMaxNoOfPatient] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [estimatedAppointmentMinuteLengthOptions] = useState([
    { id: "15", name: "15 Minutes" },
    { id: "30", name: "30 Minutes" },
    { id: "45", name: "45 Minutes" },
    { id: "60", name: "60 Minutes" },
  ] as MBDropdownSelectOption[]);

  useEffectOnlyOnce(() => {
    getExistingService(hospitalId);
  });

  const getExistingService = async (hospitalId: string) => {
    try {
      const existingServices = await services.getExistingHospitalServices(
        hospitalId
      );
      setExistingServices(existingServices);
    } catch (error) {
      console.log("error - getExistingService -- ", error);
    }
  };

  const onSubmit = async () => {
    const formattedContactNumber = formatString(contactNumber || "");
    const formattedSecondaryContactNumber = formatString(
      secondaryContactNumber || ""
    );
    const formattedLocation = formatString(location || "");
    const filteredSelectedDays = _.filter(
      selectedScheduleDays,
      (item) => item.slots !== 0 && item.isDayChecked
    );

    const noSlotChecked = !_.isEmpty(
      _.filter(
        selectedScheduleDays,
        (item) => item.isDayChecked && item.slots === 0
      )
    );

    const formattedMaxNoOfPatient = formatString(maxNoOfPatient || "");

    let errorMessage = null;
    if (_.isEmpty(treatmentName)) {
      errorMessage = MSGS_HOSPITAL_SERVICES.treatmentType.required;
      setErrorMsgTreatmentName(errorMessage);
    }
    if (formattedContactNumber.length === 0) {
      errorMessage = MSGS_HOSPITAL_SERVICES.contactNumber;
      setErrorMsgContactNumber(errorMessage);
    }
    if (!validatePhoneNumber(formattedContactNumber)) {
      errorMessage = MSGS_HOSPITAL_SERVICES.invalidContactNumber;
      setErrorMsgContactNumber(errorMessage);
    }
    if (!validatePhoneNumber(formattedSecondaryContactNumber)) {
      errorMessage = MSGS_HOSPITAL_SERVICES.invalidSecondaryContactNumber;
      setErrorMsgSecondaryContactNumber(errorMessage);
    }
    if (serviceFee.trim().length === 0 && parseInt(serviceFee) <= 0) {
      errorMessage = MSGS_HOSPITAL_SERVICES.invalidServiceFee;
      setErrorMsgServiceFee(errorMessage);
    }
    if (formattedLocation.length === 0) {
      errorMessage = MSGS_HOSPITAL_SERVICES.location;
      setErrorMsgLocation(errorMessage);
    }
    if (formattedMaxNoOfPatient.length === 0) {
      errorMessage = MSGS_HOSPITAL_SERVICES.maxNoOfPatient;
      setErrorMsgMaxNoOfPatient(errorMessage);
    }
    if (_.isEmpty(filteredSelectedDays) || noSlotChecked) {
      errorMessage = MSGS_HOSPITAL_SERVICES.allotedSlots;
      setError(errorMessage);
    }
    if (_.isEmpty(errorMessage)) {
      setLoading(true);

      try {
        if (!!isEdit && !!editData) {
          const removedSchedule = _.filter(
            selectedScheduleDays,
            (schedule) =>
              schedule.isDayChecked === false && !_.isEmpty(schedule.docId)
          );

          console.log("removedSchedule ------ ", removedSchedule);
          const serviceId = editData.docId || "";
          const updatedServiceDetail = {
            treatmentName,
            secondaryContactNumber,
            location,
            contactNumber,
            fee: parseInt(serviceFee),
            maxNoOfPatient: parseInt(formattedMaxNoOfPatient),
          } as Partial<HospitalService>;
          await services.updateService(serviceId, updatedServiceDetail);
          filteredSelectedDays.forEach(async (updatedSchedule) => {
            if (!!updatedSchedule.docId && !_.isEmpty(updatedSchedule.docId)) {
              await services.updateServiceSchedule(updatedSchedule.docId, {
                dayOfWeek: updatedSchedule.dayOfWeek,
                estimatedAppointmentMinuteLength: parseInt(
                  selectedEstimatedTimeService.id
                ),
                startTime: getServerTimestamp(new Date(updatedSchedule.time)),
                slots: updatedSchedule.slots,
              } as Partial<ServiceSchedule>);
            } else {
              await services.createServiceSchedule(
                serviceId,
                updatedSchedule.dayOfWeek as DaysOfWeek,
                parseInt(selectedEstimatedTimeService.id),
                updatedSchedule.slots as number,
                new Date(updatedSchedule.time)
              );
            }
          });
          if (!_.isEmpty(removedSchedule)) {
            removedSchedule.forEach(async (schedule) => {
              await services.removeServiceSchedule(schedule.docId || "");
            });
          }

          setLoading(false);
          setSuccess("Out-Patient Service information updated");
        } else {
          const existingSearch = _.find(existingServices, (existingService) => {
            return (
              existingService.trim().toLocaleLowerCase() ===
              treatmentName.trim().toLowerCase()
            );
          });
          if (!_.isEmpty(existingSearch)) {
            setError("Service is already existing");
            setLoading(false);
            return;
          }

          const resultId = await services.createService(
            hospitalId,
            treatmentName,
            contactNumber,
            secondaryContactNumber,
            location,
            parseInt(serviceFee),
            parseInt(maxNoOfPatient),
            true
          );
          filteredSelectedDays.forEach(async (item) => {
            const startTime = new Date(item.time);
            startTime.setSeconds(0);
            startTime.setMilliseconds(0);
            await services.createServiceSchedule(
              resultId,
              item.dayOfWeek as DaysOfWeek,
              parseInt(selectedEstimatedTimeService.id),
              item.slots as number,
              startTime
            );
          });
          setLoading(false);
          setSuccess(MSGS_HOSPITAL_SERVICES.success);
          analytics.logEvent("add_new_service", {
            content_type: ANALYTICS_CONTENT_TYPES.hospitalDepartment.type,
            hospital: hospitalName,
          });
        }
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    } else {
      setLoading(false);
    }
  };
  return (
    <>
      {/* <MBCard
        title="Service Offered"
        titleDescription="Treatment Type"
        subtitle=""
        onBack={onBack}
        onSubmit={onSubmit}
      > */}
      <IonModal
        cssClass="hospital-services-modal"
        isOpen={isOpen}
        onDidDismiss={onBack}
      >
        <div className="hospital-services-setup-form-container">
          <IonLabel className="hospital-services-header mb-h2 dark-blue ion-text-start">
            {`${isEdit ? "Edit" : "Add"} Out-Patient Service`}
          </IonLabel>
          <MBDivider />
          <div className="hospital-services-setup-form-content-container">
            <IonGrid className="hospital-services-setup-form-container-grid ion-no-padding">
              <IonRow>
                <IonCol
                  className={`hospital-services-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="Treatment Name"
                    value={treatmentName}
                    type="tel"
                    error={errorMsgTreatmentName}
                    onChange={(treatmentName) => {
                      setTreatmentName(treatmentName);
                      setErrorMsgTreatmentName("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`hospital-services-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="Contact Number"
                    value={contactNumber}
                    type="tel"
                    error={errorMsgContactNumber}
                    onChange={(contactNumber) => {
                      setContactNumber(contactNumber);
                      setErrorMsgContactNumber("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`hospital-services-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="Secondary Contact Number"
                    value={secondaryContactNumber}
                    type="tel"
                    error={errorMsgSecondContactNumber}
                    onChange={(secondaryContactNumber) => {
                      setSecondaryContactNumber(secondaryContactNumber);
                      setErrorMsgSecondaryContactNumber("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`hospital-services-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="Location"
                    value={location}
                    type="text"
                    error={errorMsgLocation}
                    onChange={(location) => {
                      setLocation(location);
                      setErrorMsgLocation("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`hospital-services-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="6"
                >
                  <MBInput
                    label="Service Fee"
                    value={serviceFee}
                    type="number"
                    error={errorMsgServiceFee}
                    onChange={(serviceFee) => {
                      setServiceFee(serviceFee);
                      setErrorMsgServiceFee("");
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonGrid className="estimated-dropdown-grid ion-no-padding ion-no-margin">
              <IonRow className="estimated-row">
                <IonCol className="ion-align-self-center" size="3">
                  <IonLabel className="mb-paragraph bold">
                    Estimated Time per Appointment
                  </IonLabel>
                </IonCol>
                <IonCol
                  className="hospital-services-setup-estimated-col ion-align-items-center"
                  size="4.5"
                >
                  <div className="hospital-services-setup-form-container-col-dropdown-container">
                    <MBDropdownSelect
                      label=""
                      className="appointment-length-options"
                      options={estimatedAppointmentMinuteLengthOptions}
                      value={selectedEstimatedTimeService.name}
                      onSelectItem={(
                        selectedEstimatedAppointmentMinuteLength
                      ) => {
                        setSelectedEstimatedTimeService(
                          selectedEstimatedAppointmentMinuteLength
                        );
                        setErrorMsgEstimatedService("");
                      }}
                      selectFromOptionsRequired={true}
                      error={errorMsgEstimatedService}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonGrid className="hospital-services-setup-form-container-grid ion-no-padding ion-no-margin">
              <IonRow>
                <IonCol className="ion-align-self-center" size="3">
                  <IonLabel className="mb-paragraph bold">
                    Max No. of Patient per Session
                  </IonLabel>
                </IonCol>
                <IonCol
                  className="hospital-services-setup-estimated-col ion-align-items-center"
                  size="4.5"
                >
                  <MBInput
                    label=""
                    value={maxNoOfPatient}
                    type="number"
                    error={errorMsgMaxNoOfPatient}
                    onChange={(maxNoOfPatient) => {
                      setMaxNoOfPatient(maxNoOfPatient);
                      setErrorMsgMaxNoOfPatient("");
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <div
              className={`hospital-services-setup-schedule-container ${
                isMobile() && "mobile"
              }`}
            >
              <IonLabel className="mb-paragraph bold">
                Schedule (can select multiple days)
              </IonLabel>
              <IonGrid className="ion-no-padding">
                {selectedScheduleDays.map((item, index) => {
                  return (
                    <IonRow>
                      <IonCol size={`${isMobile() ? "6.5" : "4.5"}`}>
                        <IonItem
                          className="hospital-services-setup-schedule-item"
                          lines="none"
                          color={MBCOLORS.tertiary}
                        >
                          <IonCheckbox
                            mode="md"
                            checked={item.isDayChecked}
                            onIonChange={() => {
                              const updatedScheduleDays = _.clone(
                                selectedScheduleDays
                              );
                              const updateValue = updatedScheduleDays[index];
                              const updateValueIndex = {
                                ...updatedScheduleDays[index],
                                isDayChecked: !updateValue.isDayChecked,
                              } as SelectScheduleDays;
                              updatedScheduleDays[index] = updateValueIndex;
                              setSelectedScheduleDays(updatedScheduleDays);
                            }}
                          />
                          <IonLabel className="hospital-services-setup-schedule-label mb-body">
                            {isMobile()
                              ? item.name.slice(0, index === 4 ? 3 : 3)
                              : item.name}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size={`${isMobile() ? "5.5" : "7.5"}`}>
                        {item.isDayChecked && (
                          <div className="hospital-services-time-slot-container">
                            <IonItem
                              className={`hospital-services-setup-time-slots-item ${
                                isMobile() && "mobile"
                              } ion-no-padding ion-no-margin`}
                              lines="none"
                              color={MBCOLORS.tertiary}
                            >
                              <IonLabel
                                slot="start"
                                className="mb-paragraph ion-no-margin"
                              >
                                Start Time
                              </IonLabel>
                              <IonDatetime
                                slot="end"
                                className="hospital-services-setup-time-slots-input mb-paragraph ion-no-margin ion-no-padding"
                                display-format="h:mm A"
                                picker-format="h:mm A"
                                minuteValues="0,15,30,45"
                                value={item.time}
                                onIonChange={(selectedTime) => {
                                  const updatedScheduleDays = _.clone(
                                    selectedScheduleDays
                                  );
                                  const updateValueIndex = {
                                    ...updatedScheduleDays[index],
                                    time:
                                      new Date(
                                        selectedTime.detail.value as string
                                      ).toISOString() || ("" as string),
                                  } as SelectScheduleDays;
                                  updatedScheduleDays[index] = updateValueIndex;
                                  setSelectedScheduleDays(updatedScheduleDays);
                                }}
                              ></IonDatetime>
                            </IonItem>
                            <IonItem
                              className={`hospital-services-setup-schedule-slots-item ${
                                isMobile() && "mobile"
                              } ion-no-padding ion-no-margin`}
                              lines="none"
                              color={MBCOLORS.tertiary}
                            >
                              <IonLabel className="mb-paragraph">
                                Slots
                              </IonLabel>
                              <IonInput
                                className="hospital-services-setup-schedule-slots-input mb-paragraph"
                                type="number"
                                min={"0"}
                                max={"99"}
                                value={item.slots}
                                onIonChange={(slots) => {
                                  const updatedScheduleDays = _.clone(
                                    selectedScheduleDays
                                  );
                                  const updateValueIndex = {
                                    ...updatedScheduleDays[index],
                                    slots:
                                      parseInt(slots.detail.value as string) ||
                                      (0 as number),
                                  } as SelectScheduleDays;
                                  updatedScheduleDays[index] = updateValueIndex;
                                  setSelectedScheduleDays(updatedScheduleDays);
                                }}
                              ></IonInput>
                            </IonItem>
                          </div>
                        )}
                      </IonCol>
                    </IonRow>
                  );
                })}
              </IonGrid>
            </div>
          </div>
        </div>
        <MBDivider />
        <div className="hospital-services-setup-button-container">
          <IonButton
            onClick={() => {
              onBack();
            }}
            className="hospital-services-setup-back-button mb-body ion-text-capitalize"
            fill="clear"
            color={MBCOLORS.primary}
          >
            Cancel
          </IonButton>
          <IonButton
            onClick={() => {
              onSubmit();
            }}
            className="hospital-services-setup-save-button mb-body bold white ion-text-capitalize"
            color={MBCOLORS.primary}
          >
            Save
          </IonButton>
        </div>
      </IonModal>
      <MBDialog
        isOpen={!_.isEmpty(success)}
        icon={"services"}
        title={success}
        buttonLabel="Okay"
        message={`Out-Patient Service details ${
          isEdit ? "updated" : "added"
        } to the system. The service will now show up on list of services associated with the hospital.`}
        onDidDismiss={() => {
          if (!!onRefreshData) {
            onRefreshData();
          }
          if (!!onBack) {
            onBack();
          }
          setSuccess("");
        }}
      />
      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
