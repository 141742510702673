import * as _ from "lodash";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const formatPhoneNumber = (phoneNumber: string) => {
  const formattedNumber = parsePhoneNumberFromString(phoneNumber);
  return formattedNumber ? formattedNumber.number : null;
};

export const getParsedMobileNumber = (phoneNumber: string) => {
  const formattedNumber = parsePhoneNumberFromString(phoneNumber);
  return formattedNumber;
};

export const validatePhoneNumber = (phoneNumber: string) => {
  let phoneNumberToValidate = _.clone(phoneNumber);
  if (phoneNumberToValidate.indexOf("+63") === 0) {
    return (
      !_.isEmpty(parsePhoneNumberFromString(phoneNumber)) &&
      phoneNumber.length === 13
    );
  } else if (phoneNumberToValidate.indexOf("0") === 0) {
    return (
      !_.isEmpty(parsePhoneNumberFromString(phoneNumber.replace("0", "+63"))) &&
      phoneNumber.length === 11
    );
  } else {
    return false;
  }
};
