/* eslint-disable jsx-a11y/alt-text */
import * as _ from "lodash";
import { Helmet } from "react-helmet";
import { TimelineLite, Power3, Power4 } from "gsap";
import React, { useEffect, useState } from "react";
import {
  IonButtons,
  IonModal,
  IonInput,
  IonImg,
  IonRippleEffect,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonRow,
} from "@ionic/react";

import "./Tab1.scss";
import * as routes from "../../constants/routes";
import { isMobile } from "../../functions/common";
import { LandingContact } from "./LandingContact";
import { LandingFooter } from "./LandingFooter";
import { logoFacebook } from "ionicons/icons";
import { analytics } from "../../firebase";
import { ANALYTICS_CONTENT_TYPES } from "../../constants/config";

interface PreregistrationProps {
  onGoHome: () => void;
  onPreregister: () => void;
}
const Tab1: React.FC<PreregistrationProps> = (props) => {
  //SHOW LOADING
  const { onGoHome, onPreregister } = props;

  // REACT FORM

  const [showModal, setShowModal] = useState(false);
  let t1 = new TimelineLite({ delay: 0.1 });

  useEffect(() => {
    t1.from(
      ".banneranim",
      { y: 15, opacity: 0, ease: Power3.easeInOut, delay: 0.8 },
      "Start"
    );

    t1.from(
      ".textanim",
      { y: 20, opacity: 0, ease: Power4.easeOut, delay: 0.4 },
      "Start"
    );
    t1.from(
      ".btnanim",
      { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.7 },
      "Start"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPage className="tab-container">
      <meta
        title="Doctor Consultation & Hospital Appointment Booking | Medbook"
        content="Book now with hospitals near you. Fast & easy online booking of hospital appointments, doctor consultations and check-ups. Avoid the lines and lessen exposure. "
      />
      <Helmet>
        <title>
          Doctor Consultation & Hospital Appointment Booking | Medbook
        </title>
      </Helmet>
      <IonHeader {...(isMobile() && { mode: "ios" })}>
        <IonToolbar
          className={`${
            isMobile()
              ? "ion-padding-vertical landing-header"
              : "ion-padding-top"
          }`}
        >
          <IonTitle
            onClick={onGoHome}
            className={`ion-no-padding ${
              isMobile()
                ? "ion-padding-vertical ion-align-items-start"
                : "ion-padding-start"
            }`}
          >
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              className={`${isMobile() ? "ion-padding-start" : "logo-padding"}`}
              src={`${process.env.PUBLIC_URL}/assets/images/Medbooklogo.svg`}
            />
          </IonTitle>

          <IonButtons
            slot="primary"
            className={` ${
              isMobile() ? "ion-align-items-end" : "ion-align-self-start"
            } ml-50`}
          >
            {/* <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-margin-right mr-10"
              // fill="outline"
              color="primary"
              routerLink={routes.LOGIN_OPTIONS}
            >
              Login
            </IonButton> */}
            <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className={`landing-dark-blue-text ${
                isMobile() ? "ion-no-padding" : "ion-padding-end"
              }`}
              fill="outline"
              color="primary"
              onClick={() => {
                analytics.logEvent("follow_us_facebook", {
                  content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
                });
              }}
            >
              Follow us
              <IonIcon slot="start" icon={logoFacebook} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {/* CONTENT */}

      <IonContent className="pb-0">
        <IonRow className="h100 teal"></IonRow>
        <IonRow className="bkg-blue ion-justify-content-center teal ion-padding-top  ">
          <IonCol
            className="white-text ion-padding-top pb-0  textanim  "
            size="4"
          >
            <h1 className="lgH1 animtext">
              Avoid the lines and long wait times!
            </h1>
            <p>
              Book an appointment online and arrive at your most convenient time
              and day. Wait in the comfort of your own home.
            </p>

            <IonButton
              className="btnanim ion-text-capitalize"
              color="warning"
              onClick={onPreregister}
            >
              Sign Up
            </IonButton>
            {/* <IonButton color="warning" onClick={() => setShowModal(true)} >Sign Up</IonButton>   */}
          </IonCol>

          <IonCol size="6" className="ion-padding-top pb-0 banneranim">
            {" "}
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/patient-banner.png`}
            ></img>
          </IonCol>
        </IonRow>

        {/* WHY MEDBOOK? */}
        <IonRow className="bkg-blue ion-justify-content-center dark-teal ion-padding-bottom content-main">
          <IonCard
            color="primary"
            className="card-no-shadow bkg-trans ion-justify-content-center"
          >
            <IonCardHeader>
              <IonCardTitle className="ion-text-center ion-padding-bottom content">
                <h1>Why is Medbook a great fit for you?</h1>
              </IonCardTitle>
            </IonCardHeader>
            <IonRow className="ion-padding-bottom ">
              <IonCol size="">
                <IonCard className="landing-info-card card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bord-20 content ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/Realtime.svg`}
                  />
                  <h4 className="landing-dark-blue-text">Real-time booking </h4>
                  <p>
                    Medbook makes your medical appointments easy and
                    hassle-free.
                  </p>
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>

              <IonCol size="">
                <IonCard className="landing-info-card card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bord-20  ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/Accessibility.svg`}
                  />
                  <h4 className="landing-dark-blue-text">Accessibility </h4>
                  <p>
                    Be able find the nearest Hospital wherever you book an
                    appointment.
                  </p>
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>
              <IonCol size="">
                <IonCard className="landing-info-card card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bord-20 ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/Powerofchoice.svg`}
                  />
                  <h4 className="landing-dark-blue-text">Your Choice</h4>
                  <p>
                    Compare prices, ratings and feedback from multiple
                    hospitals, services & physicians.
                  </p>
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>
              <IonCol size="">
                <IonCard className="landing-info-card card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent  bord-20 ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/Trusted.svg`}
                  />
                  <h4 className="landing-dark-blue-text">Trusted</h4>
                  <p>Consult only with qualified and certified physicians.</p>
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonCard>
        </IonRow>

        {/* HOMO TO HOME IN 6 STEPS */}

        <IonRow className="ion-justify-content-center teal box">
          <IonCard color="primary" className="card-no-shadow bkg-trans">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                <h1>Home to Home in 6 Steps</h1>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonRow>

        <IonRow className="ion-justify-content-center teal boxcont">
          <IonCol size="2.5">
            <IonCard className="card-no-shadow ion-padding bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step.svg`}
                  slot="start"
                />
                <h6 className="white-text">Book and Pay</h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/BookandPay.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol size="2.5">
            <IonCard className="card-no-shadow ion-padding bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step2.svg`}
                  slot="start"
                />
                <h6 className="white-text">Waiting for Appointment Day</h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/Wait.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol size="2.5">
            <IonCard className="card-no-shadow ion-padding bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step3.svg`}
                  slot="start"
                />
                <h6 className="white-text">Take Covid Checks & Save QR Code</h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/TakeCovidCheck.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>

        <IonRow className="ion-justify-content-center teal">
          <IonCol size="2.5">
            <IonCard className="card-no-shadow ion-padding bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step4.svg`}
                  slot="start"
                />
                <h6 className="white-text">
                  Present QR Code to Hospital Security
                </h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/PresentQR.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size="2.5">
            <IonCard className="card-no-shadow ion-padding bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step5.svg`}
                  slot="start"
                />
                <h6 className="white-text">Go to your Appointment</h6>
              </IonItem>
              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/Appointment.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol size="2.5">
            <IonCard className="card-no-shadow ion-padding bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step6.svg`}
                  slot="start"
                />
                <h6 className="white-text">Go Home & Rate the Service</h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/rate.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>

        {/* HOW EASY MEDBOOK */}
        <IonRow className="bkg-blue marg">
          <IonCard
            color="light"
            className="landing-center card-no-shadow ion-text-center bkg-trans"
          >
            <IonCardHeader>
              <IonCardTitle>
                <h1 className="landing-dark-blue-text"> Booking made easy</h1>
              </IonCardTitle>
              <IonCardContent>
                Medbook provides a hassle-free and easy use app to provide your
                medical needs.
              </IonCardContent>

              <IonRow className="ion-justify-content-center  ">
                <IonCol size="2.5">
                  <IonCard className="card-no-shadow  bord-20 ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bkg-trans ">
                    <IonImg
                      src={`${process.env.PUBLIC_URL}/assets/images/LoginPhone.png`}
                    />
                    <h4 className="landing-dark-blue-text">Login</h4>
                    <p>
                      We provide innovative realtime booking of medical services
                      and consultations.
                    </p>
                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
                <IonCol size="2.5">
                  <IonCard className="card-no-shadow   bord-20 ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bkg-trans ">
                    <IonImg
                      src={`${process.env.PUBLIC_URL}/assets/images/SeartchPhone.png`}
                    />
                    <h4 className="landing-dark-blue-text">Search </h4>
                    <p>
                      We provide innovative realtime booking of medical services
                      and consultations.
                    </p>
                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
                <IonCol size="2.5">
                  <IonCard className="card-no-shadow  bord-20 ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bkg-trans ">
                    <IonImg
                      src={`${process.env.PUBLIC_URL}/assets/images/BookandPayPhone.png`}
                    />
                    <h4 className="landing-dark-blue-text">Book and Pay</h4>
                    <p>
                      We provide innovative realtime booking of medical services
                      and consultations.
                    </p>
                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonCardHeader>
          </IonCard>
        </IonRow>

        {/* GET NOTIFIED */}
        <form>
          <IonCard className="notified-card card-no-shadow landing-light ion-padding">
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="4" className="padding">
                {" "}
                <div className="get-notified-container">
                  <h3 className="ion-text-center teal-text">
                    Get notified for the latest updates
                  </h3>{" "}
                  <IonButton
                    className="subscribe-button ion-text-capitalize"
                    color="primary"
                    size="large"
                    onClick={() => {
                      window.location.href = routes.LANDING_PRE_REGISTER;
                    }}
                  >
                    Subscribe
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </IonCard>
        </form>

        {/* YOUMAY ASLO LIKE */}
        {/* 
<IonRow className="ion-justify-content-center six-steps ">
      <IonCard color="light"className="bkg-trans">
        <IonCardHeader>
          <IonCardTitle className="ion-text-center" ><h1 className="landing-dark-blue-text">You may also like</h1></IonCardTitle>
          <IonCardContent className="ion-text-center" >
          Medbook also have some partner brands, hospitals,doctors and services for you to offer
        </IonCardContent>
        </IonCardHeader>
        <IonRow className="ion-justify-content-center">
      <IonCol size="2.5">
              <IonCard className="card-no-shadow ion-padding shadow bord-10">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/Ad1.png`}></img>
                     <h5>Add Placement 1</h5>
                           
                     <p>Lorem Ipsum dolor este por pabor delamente colorado pordito mecando</p>
                      <IonButton  fill="outline">Learn More</IonButton>
                 
               </IonCard>
        </IonCol>
        <IonCol size="2.5">
                <IonCard className="card-no-shadow ion-padding shadow bord-10">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/Ad2.png`}></img>
                     <h5>Add Placement 1</h5>
                           
                     <p>Lorem Ipsum dolor este por pabor delamente colorado pordito mecando</p>
                      <IonButton  fill="outline">Learn More</IonButton>
                 
               </IonCard>
        </IonCol>


        <IonCol size="2.5">
                <IonCard className="card-no-shadow ion-padding shadow bord-10">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/Ad3.png`}></img>
                     <h5>Add Placement 1</h5>
                           
                     <p>Lorem Ipsum dolor este por pabor delamente colorado pordito mecando</p>
                      <IonButton  fill="outline">Learn More</IonButton>
                 
               </IonCard>
        </IonCol>
      </IonRow>

      </IonCard>
  




      </IonRow> */}

        {/* CONTACT US NEW */}

        <LandingContact />

        {/* MODAL */}

        <IonModal isOpen={showModal} cssClass="mymod sc-ion-modal-lg">
          <IonCard className="card-no-shadow ion-padding">
            <IonRow>
              <IonCol>
                <h3 className="landing-dark-blue-text">Pre-Register Now!</h3>
                <p>
                  Get a <strong>3 FREE bookings</strong> upon pre-registration.
                </p>
              </IonCol>

              <IonCol>
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/BookandPay.svg`}
                ></img>
              </IonCol>
              <IonButton onClick={() => setShowModal(false)}>
                Close Modal
              </IonButton>
              <IonItem color="transparent">
                <IonLabel position="floating" className="">
                  Full Name
                </IonLabel>
                <IonInput type="text" clearInput>
                  {" "}
                </IonInput>
              </IonItem>
              <br></br>
              <IonItem color="transparent">
                <IonLabel position="floating">Mobile Number</IonLabel>
                <IonInput type="number"> </IonInput>
              </IonItem>
              <br></br>

              <IonItem color="transparent">
                <IonLabel position="floating">Email</IonLabel>
                <IonInput type="email"> </IonInput>
              </IonItem>
              <br></br>
            </IonRow>
          </IonCard>
        </IonModal>
        <LandingFooter />
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
