import * as qs from "qs";
import { RouteComponentProps } from "react-router-dom";
import React, { Component } from "react";
import { IonPage, IonContent, withIonLifeCycle, IonLabel } from "@ionic/react";

import "./ExternalAction.scss";
import * as routes from "../../constants/routes";
import { FIREBASE_EMAIL_MODES } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";

interface InterfaceProps extends RouteComponentProps<{}> {
  history: any;
  location: any;
}

class ExternalAction extends Component<InterfaceProps> {
  componentDidMount() {
    const obj = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { history } = this.props;

    if (obj.mode === FIREBASE_EMAIL_MODES.verifyEmail) {
      history.push(`${routes.VERIFY_EMAIL}${this.props.location.search}`);
    } else if (obj.mode === FIREBASE_EMAIL_MODES.resetPassword) {
      history.push(`${routes.RESET_PASSWORD}${this.props.location.search}`);
    } else if (obj.mode === FIREBASE_EMAIL_MODES.recoverEmail) {
      history.push(`${routes.RECOVER_EMAIL}${this.props.location.search}`);
    }
  }

  render() {
    return (
      <>
        <IonPage>
          <IonContent>
            <IonLabel>{MSGS_COMMON.loading}</IonLabel>
          </IonContent>
        </IonPage>
      </>
    );
  }
}

export default withIonLifeCycle(ExternalAction);
