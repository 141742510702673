import * as _ from "lodash";
import * as Highcharts from "highcharts";
import React from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
} from "@ionic/react";

import "./MarketingFeedbacks.scss";
import * as services from "../../services";
import { PreRegistrationUser, SubscribedEmails } from "../../models";
import moment from "moment";

class MarketingFeedbacks extends React.Component<{}> {
  preRegChartDivRef: React.RefObject<HTMLDivElement>;
  subRegChartDivRef: React.RefObject<HTMLDivElement>;

  state = {
    error: "",
    loading: false,
    preRegistrations: null as null | PreRegistrationUser[],
    subscriptions: null as null | SubscribedEmails[],
    preRegistrationsChartData: null as null | [number, number][],
    subscrbptionsChartData: null as null | [number, number][],

    preRegChart: null as Highcharts.Chart | null,
    subsChart: null as Highcharts.Chart | null,
  };

  constructor(props: any) {
    super(props);
    this.preRegChartDivRef = React.createRef();
    this.subRegChartDivRef = React.createRef();
  }

  componentDidMount = () => {
    this.getPreRegistrations();
    this.getSubscriptions();
  };

  getPreRegistrations = () => {
    services.getPreregistrations((preRegistrations, error) => {
      const sortedData = _.sortBy(preRegistrations, (preReg) =>
        preReg.createdAt.toDate()
      );
      this.setState({
        preRegistrations: sortedData,
        error,
      });

      if (!!this.state.preRegChart) {
        this.state.preRegChart.destroy();
      }
      this.createPreRegChart(sortedData);
    });
  };

  getSubscriptions = () => {
    services.getEmailSubscriptions((subscriptions, error) => {
      const sortedData = _.sortBy(subscriptions, (sub) =>
        sub.createdAt.toDate()
      );
      this.setState({
        subscriptions: sortedData,
        error,
      });

      if (!!this.state.subsChart) {
        this.state.subsChart.destroy();
      }
      this.createSubsChart(sortedData);
    });
  };

  getTop5Locations = (): { location: string; count: number }[] => {
    const { preRegistrations } = this.state;
    if (!_.isNull(preRegistrations)) {
      return _.chunk(
        _.orderBy(
          Object.values(
            _.groupBy(
              _.filter(
                preRegistrations,
                (preReg) =>
                  !_.isEmpty(preReg.cityMunicipality) &&
                  !_.isEmpty(preReg.province)
              ),
              (preReg) => `${preReg.province}_${preReg.cityMunicipality}`
            )
          ).map((preRegLocation) => {
            return {
              location: `${preRegLocation[0].province}, ${preRegLocation[0].cityMunicipality}`,
              count: preRegLocation.length,
            };
          }),
          "count",
          "desc"
        ),
        5
      )[0];
    } else {
      return [];
    }
  };

  createPreRegChart = (preRegs: PreRegistrationUser[]) => {
    const chartData = Object.values(
      _.groupBy(preRegs, (preReg) =>
        moment(preReg.createdAt.toDate()).format("MM-DD-YYYY")
      )
    ).map((preRegList) => {
      return [
        moment(preRegList[0].createdAt.toDate()).format("MM/DD/YYYY"),
        preRegList.length,
      ];
    });

    if (!_.isNull(this.preRegChartDivRef.current)) {
      const createdChart = Highcharts.chart("pre-reg-container", {
        chart: { type: "spline", zoomType: "x" },
        credits: { enabled: false },
        lang: { noData: "No data found." },
        noData: {
          attr: { zIndex: 1 },
          position: { align: "center", verticalAlign: "middle", x: 0, y: 0 },
          useHTML: true,
        },
        title: { text: "" },
        yAxis: { title: { text: "" }, allowDecimals: false },
        xAxis: {
          type: "category",
        },
        legend: { enabled: false },
        plotOptions: {
          series: {
            label: { connectorAllowed: false },
            borderColor: "#ff0000",
          },
        },
        responsive: {
          rules: [
            {
              condition: { maxWidth: 870, minWidth: 500 },
              chartOptions: { legend: { enabled: false } },
            },
          ],
        },

        series: !_.isEmpty(chartData)
          ? [
              {
                name: "Pre-registrations",
                data: chartData,
                color: "#ffc809",
                marker: {
                  radius: 2.5,
                },
              } as Highcharts.SeriesOptionsType,
            ]
          : [],
      });
      // createdChart.set;
      // createdChart.addSeries({
      //   name: "On Site",
      //   type: "spline",
      //   data: chartData,
      //   color: "#ffc809",
      // } as Highcharts.SeriesOptionsType);

      this.setState({
        preRegChart: createdChart,
      });
    } else {
      setTimeout(() => {
        this.createPreRegChart(preRegs);
      }, 500);
    }
  };

  createSubsChart = (subscriptions: SubscribedEmails[]) => {
    const chartData = Object.values(
      _.groupBy(subscriptions, (subscription) =>
        moment(subscription.createdAt.toDate()).format("MM-DD-YYYY")
      )
    ).map((subscriptionsList) => {
      return [
        moment(subscriptionsList[0].createdAt.toDate()).format("MM/DD/YYYY"),
        subscriptionsList.length,
      ];
    });

    if (!_.isNull(this.preRegChartDivRef.current)) {
      const createdChart = Highcharts.chart("subs-container", {
        chart: { type: "spline", zoomType: "x" },
        credits: { enabled: false },
        lang: { noData: "No data found." },
        noData: {
          attr: { zIndex: 1 },
          position: { align: "center", verticalAlign: "middle", x: 0, y: 0 },
          useHTML: true,
        },
        title: { text: "" },
        yAxis: { title: { text: "" }, allowDecimals: false },
        xAxis: {
          type: "category",
        },
        legend: { enabled: false },
        plotOptions: {
          series: {
            label: { connectorAllowed: false },
            borderColor: "#ff0000",
          },
        },
        responsive: {
          rules: [
            {
              condition: { maxWidth: 870, minWidth: 500 },
              chartOptions: { legend: { enabled: false } },
            },
          ],
        },

        series: !_.isEmpty(chartData)
          ? [
              {
                name: "Email Subscriptions",
                data: chartData,
                color: "#ffc809",
                marker: {
                  radius: 2.5,
                },
              } as Highcharts.SeriesOptionsType,
            ]
          : [],
      });
      // createdChart.set;
      // createdChart.addSeries({
      //   name: "On Site",
      //   type: "spline",
      //   data: chartData,
      //   color: "#ffc809",
      // } as Highcharts.SeriesOptionsType);

      this.setState({
        subsChart: createdChart,
      });
    } else {
      setTimeout(() => {
        this.createSubsChart(subscriptions);
      }, 500);
    }
  };

  render = () => {
    const { error, loading, preRegistrations, subscriptions } = this.state;
    return (
      <IonPage>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol size="6">
                <IonCard color="tertiary">
                  <IonCardHeader>
                    <IonTitle color="dark">Pre-Registrations</IonTitle>
                  </IonCardHeader>
                  <IonCardContent color="tertiary">
                    <IonCardSubtitle className="mb-huge ion-padding-start">
                      {!_.isNull(preRegistrations)
                        ? preRegistrations.length
                        : "..."}
                    </IonCardSubtitle>
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol size="6">
                <IonCard color="tertiary">
                  <IonCardHeader>
                    <IonTitle color="dark">Email Subscriptions</IonTitle>
                  </IonCardHeader>
                  <IonCardContent color="tertiary">
                    <IonCardSubtitle className="mb-huge ion-padding-start">
                      {!_.isNull(subscriptions) ? subscriptions.length : "..."}
                    </IonCardSubtitle>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonCard color="tertiary">
                  <IonCardHeader>
                    <IonTitle color="dark">Top Locations</IonTitle>
                  </IonCardHeader>
                  <IonCardContent color="tertiary">
                    {!_.isNull(preRegistrations) && !_.isNull(subscriptions) ? (
                      <IonList
                        color="tertiary"
                        className="ion-no-padding ion-margin-left"
                      >
                        {this.getTop5Locations().map((location) => {
                          return (
                            <IonItem
                              color="tertiary ion-no-padding"
                              lines="none"
                            >
                              <IonLabel className="mb-h3">
                                {location.location} - {location.count}
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                      </IonList>
                    ) : (
                      <IonLabel>....</IonLabel>
                    )}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonCard color="tertiary">
                  <IonCardHeader>
                    <IonTitle color="dark">Pre-registration Graph</IonTitle>
                  </IonCardHeader>
                  <IonCardContent className="pre-reg-card-container">
                    <div
                      id="pre-reg-container"
                      className="pre-reg-container"
                      ref={this.preRegChartDivRef}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonCard color="tertiary">
                  <IonCardHeader>
                    <IonTitle color="dark">Email Subscription Graph</IonTitle>
                  </IonCardHeader>
                  <IonCardContent className="pre-reg-card-container">
                    <div
                      id="subs-container"
                      className="subs-container"
                      ref={this.subRegChartDivRef}
                    />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="8">
                <IonCard color="tertiary">
                  <IonCardHeader>
                    <IonTitle color="dark">Pre-Registrations</IonTitle>
                  </IonCardHeader>
                  <IonCardContent color="tertiary">
                    {!_.isNull(preRegistrations) ? (
                      <IonList
                        color="tertiary"
                        className="ion-no-padding ion-margin-left"
                      >
                        {preRegistrations.map((preReg) => {
                          return (
                            <IonItem
                              color="tertiary ion-no-padding"
                              lines="none"
                            >
                              <IonLabel className="mb-h3 bold">
                                {preReg.emailAddress}
                              </IonLabel>
                              <IonLabel className="mb-h3">
                                {`${preReg.province}, ${preReg.cityMunicipality}`}
                              </IonLabel>
                              <IonLabel className="mb-h3">
                                {moment(preReg.createdAt.toDate()).format(
                                  "ddd, MM/DD/YYYY"
                                )}
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                      </IonList>
                    ) : (
                      <IonLabel>....</IonLabel>
                    )}
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol size="4">
                <IonCard color="tertiary">
                  <IonCardHeader>
                    <IonTitle color="dark">Email Subscriptions</IonTitle>
                  </IonCardHeader>
                  <IonCardContent color="tertiary">
                    {!_.isNull(subscriptions) ? (
                      <IonList
                        color="tertiary"
                        className="ion-no-padding ion-margin-left"
                      >
                        {subscriptions.map((subscriptions) => {
                          return (
                            <IonItem
                              color="tertiary ion-no-padding"
                              lines="none"
                            >
                              <IonLabel className="mb-h3 bold">
                                {subscriptions.emailAddress}
                              </IonLabel>

                              <IonLabel className="mb-h3">
                                {moment(
                                  subscriptions.createdAt.toDate()
                                ).format("ddd, MM/DD/YYYY")}
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                      </IonList>
                    ) : (
                      <IonLabel>....</IonLabel>
                    )}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
}

export default MarketingFeedbacks;
