import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonRow,
  IonGrid,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonModal,
} from "@ionic/react";

import "./MBContainer.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { MBFooter } from "../MBFooter/MBFooter";
import { MBHeader } from "../MBHeader/MBHeader";
import { MBProps, Pages } from "../../interface";
import { USER_TYPES, MBCOLORS } from "../../constants/config";
import {
  daysSinceDate,
  getServerTimestamp,
  isMobile,
  toDateTimeFromSecs,
} from "../../functions/common";
import {
  HospitalServiceAppointmentsView,
  HospitalDoctorAppointmentsView,
  Patient,
  Doctor,
} from "../../models";
import moment from "moment";
import { CovidChecklistDialog } from "../CovidChecklistDialog/CovidChecklistDialog";
import { MBDialog } from "../MBDialog/MBDialog";
import { closeOutline, warning } from "ionicons/icons";

interface MBContainerProps extends MBProps {
  children: any;
  activePage?: Pages;
}

interface NavLink {
  name: string;
  routeURL: string;
  page: Pages;
  icon?: string;
  iconActive?: string;
  target?: string;
  onClick?: () => void;
}

export const MBContainer = (props: MBContainerProps) => {
  const { authUser } = props;
  const [showSideMenu, setShowSideMenu] = useState(!isMobile() ? true : false);

  const [todayAppointment, setTodayAppointment] = useState(
    null as
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
      | null
  );

  const [isDoctorOutOfOffice, setIsDoctorOutOfOffice] = useState(false);
  const [isDoctorBannerViewed, setIsDoctorBannerViewed] = useState(false);

  const [covidChecklistDialogOpen, setCovidChecklistDialogOpen] = useState(
    false
  );
  const [appointmentReminderDialog, setAppointmentReminderDialog] = useState(
    false
  );

  const [showThankyouAnsweringCovid, setShowThankyouAnsweringCovid] = useState(
    false
  );
  const [hasCompanion, setHasCompanion] = useState(false);

  const navLinks: NavLink[] = [
    ...(!_.isEmpty(authUser) &&
    (authUser.emailVerified ||
      authUser.userType === USER_TYPES.doctors.id ||
      authUser.userType === USER_TYPES.support.id ||
      authUser.userType === USER_TYPES.techAdmin.id ||
      authUser.userType === USER_TYPES.timeKeeping.id ||
      authUser.userType === USER_TYPES.executiveAssistant.id)
      ? authUser.userType === USER_TYPES.doctors.id
        ? [
            {
              name: "Home",
              routeURL: routes.DOCTOR_HOME,
              page: "doctor-home" as Pages,
              icon: "home",
              iconActive: "home-active",
            },
            {
              name: "My Appointments",
              routeURL: routes.DOCTOR_APPOINTMENTS,
              page: "doctor-appointments" as Pages,
              icon: "my-appointments",
              iconActive: "my-appointments-active",
            },
            {
              name: "Scan Code",
              routeURL: routes.DOCTOR_SCAN,
              page: "doctor-scan" as Pages,
              icon: "scan-qr",
              iconActive: "scan-qr-active",
            },
            {
              name: "Search",
              routeURL: routes.DOCTOR_SEARCH_PATIENTS,
              page: "search-patients" as Pages,
              icon: "search",
              iconActive: "search-active",
            },
          ]
        : authUser.userType === USER_TYPES.hospitals.id
        ? [
            {
              name: "Home",
              routeURL: routes.HOSPITAL_HOME,
              page: "hospital-home" as Pages,
              icon: "home",
              iconActive: "home-active",
            },
            {
              name: "My Department",
              routeURL: routes.HOSPITAL_DEPARTMENT,
              page: "department" as Pages,
              icon: "department",
              iconActive: "department-active",
            },
            {
              name: "Daily Schedule",
              routeURL: routes.HOSPITAL_APPOINTMENTS,
              page: "hospital-appointments" as Pages,
              icon: "my-appointments",
              iconActive: "my-appointments-active",
            },
            {
              name: "Scan Code",
              routeURL: routes.HOSPITAL_SCAN,
              page: "hospital-scan" as Pages,
              icon: "scan-qr",
              iconActive: "scan-qr-active",
            },
            {
              name: "Search",
              routeURL: routes.HOSPITAL_SEARCH_PATIENTS,
              page: "search-patients" as Pages,
              icon: "search",
              iconActive: "search-active",
            },
            {
              name: "Analytics & Reports",
              routeURL: routes.HOSPITAL_ANALYTICS,
              page: "hospital-analytics" as Pages,
              icon: "analytics",
              iconActive: "analytics-active",
            },
          ]
        : authUser.userType === USER_TYPES.patients.id
        ? [
            {
              name: "Home",
              routeURL: routes.PATIENT_HOME, //shoudl replace route for booking
              page: "patient-home" as Pages,
              icon: "home",
              iconActive: "home-active",
            },
            {
              name: "My Appointments",
              routeURL: routes.PATIENT_APPOINTMENTS,
              page: "patient-appointments" as Pages,
              icon: "my-appointments",
              iconActive: "my-appointments-active",
            },
          ]
        : authUser.userType === USER_TYPES.support.id
        ? [
            {
              name: "For Approvals",
              routeURL: routes.HOSPITAL_APPLICATIONS,
              page: "for-approvals" as Pages,
            },
            {
              name: "Approved Hospitals",
              routeURL: routes.HOSPITAL_APPROVED_APPLICATIONS,
              page: "approved-applications" as Pages,
            },
          ]
        : authUser.userType === USER_TYPES.timeKeeping.id
        ? [
            {
              name: "Scan Code",
              routeURL: routes.TIME_KEEPING_SCAN,
              page: "time-keeping-scan" as Pages,
              icon: "scan-qr",
              iconActive: "scan-qr-active",
            },
            {
              name: "Search",
              routeURL: routes.TIME_KEEPING_SEARCH_PATIENTS,
              page: "search-patients" as Pages,
              icon: "search",
              iconActive: "search-active",
            },
          ]
        : authUser.userType === USER_TYPES.executiveAssistant.id
        ? [
            {
              name: "Home",
              routeURL: routes.EXECUTIVE_ASSISTANT_HOME,
              page: "executive-assistant-home" as Pages,
              icon: "home",
              iconActive: "home-active",
            },
            {
              name: "Appointments",
              routeURL: routes.EXECUTIVE_ASSISTANT_APPOINTMENTS,
              page: "executive-assistant-appointments" as Pages,
              icon: "my-appointments",
              iconActive: "my-appointments-active",
            },
            {
              name: "Scan Code",
              routeURL: routes.EXECUTIVE_ASSISTANT_SCAN,
              page: "executive-assistant-scan" as Pages,
              icon: "scan-qr",
              iconActive: "scan-qr-active",
            },
            {
              name: "Doctors Profile",
              routeURL: routes.EXECUTIVE_ASSISTANT_DOCTOR_LIST,
              icon: "doctor-account",
              iconActive: "doctor-account-active",
              page: "executive-assistant-doctor-list" as Pages,
            },
          ]
        : authUser.userType === USER_TYPES.hospitalAdmin.id
        ? [
            {
              name: "Analytics & Reports",
              routeURL: routes.HOSPITAL_ANALYTICS,
              page: "hospital-analytics" as Pages,
              icon: "analytics",
              iconActive: "analytics-active",
            },
          ]
        : []
      : []),

    ...(_.isEmpty(authUser)
      ? []
      : [
          // {
          //   name: "Logout",
          //   routeURL: routes.LOGIN_OPTIONS,
          //   page: "" as Pages,
          //   icon: "logout",
          //   onClick: async () => {
          //     console.log("LOG OUT");
          //     await services.signOut();
          //     setTimeout(() => {
          //       window.location.reload();
          //     }, 150);
          //     // props.history.push(routes.LOGIN_OPTIONS);
          //   },
          // },
        ]),
  ];

  useEffect(() => {
    if (
      !!authUser &&
      authUser.userType === USER_TYPES.patients.id &&
      _.isNull(todayAppointment)
    ) {
      consolidateAppointment();
    }

    if (!!authUser && authUser.userType === USER_TYPES.doctors.id) {
      checkIfDoctorIsOutOfOffice(authUser.userDetails as Doctor);
    }
  });

  const consolidateAppointment = async () => {
    const doctorAppointments = await getPatientDoctorAppointments();
    const serviceAppointments = await getPatientServiceAppointments();
    console.log("GOT CONSOLIDATED APPOINTMENT", {
      doctorAppointments,
      serviceAppointments,
    });
    getScheduleTodayAndHasBooking24hrs([
      ...doctorAppointments,
      ...serviceAppointments,
    ] as (HospitalDoctorAppointmentsView | HospitalServiceAppointmentsView)[]);
  };

  const checkIfDoctorIsOutOfOffice = (doctor: Doctor) => {
    const outOfOfficeStartDate = doctor.outOfOfficeStartDate;
    const outOfOfficeEndDate = doctor.outOfOfficeEndDate;
    const outOfOffice =
      outOfOfficeStartDate !== undefined &&
      !_.isNull(outOfOfficeStartDate) &&
      daysSinceDate(outOfOfficeStartDate!.toDate(), new Date()) >= 0 &&
      ((outOfOfficeEndDate !== undefined &&
        !_.isNull(outOfOfficeEndDate) &&
        daysSinceDate(outOfOfficeEndDate!.toDate(), new Date()) <= 0) ||
        _.isEmpty(outOfOfficeEndDate));

    setIsDoctorOutOfOffice(outOfOffice);
  };

  const getScheduleTodayAndHasBooking24hrs = (
    consolidatedAppointments: (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[]
  ) => {
    if (_.isNull(todayAppointment)) {
      console.log("WILL CHECK!! ---------------");
      const todaySchedule = _.find(consolidatedAppointments, (appointment) => {
        const timeDifference = moment(appointment.appointmentDate).diff(
          moment(new Date()),
          "hours"
        );
        console.log("CHECKING TIME DIFFERENCE", timeDifference);
        return (
          !appointment.isCancelled &&
          _.isEmpty(appointment.appointmentStatus) &&
          timeDifference >= 0 &&
          timeDifference < 24
        );
      });
      console.log("todaySchedule --- ", todaySchedule);
      if (todaySchedule !== undefined) {
        const msPerMinute = 60 * 1000;
        const msPerHour = msPerMinute * 60;
        const elapsed = moment(new Date()).diff(
          moment(todaySchedule.appointmentDate)
        );
        const hours = Math.round(elapsed / msPerHour);

        const hoursOfLastCovidFree = moment(new Date()).diff(
          moment(
            !!(authUser.userDetails as Patient).lastCovidFree
              ? toDateTimeFromSecs(
                  ((authUser.userDetails as Patient).lastCovidFree as any)
                    .seconds
                )
              : 25
          ),
          "hours"
        );
        const webReminder = moment(new Date()).diff(
          moment(
            !!(authUser.userDetails as Patient).webAppointmentReminderAt
              ? toDateTimeFromSecs(
                  ((authUser.userDetails as Patient)
                    .webAppointmentReminderAt as any).seconds
                )
              : 6
          ),
          "hours"
        );
        console.log("todaySchedule.bookFor ---- ", todaySchedule.bookingFor);
        setHasCompanion(!_.isEmpty(todaySchedule.bookingFor));
        setAppointmentReminderDialog(hours <= 24 && webReminder > 5);
        setCovidChecklistDialogOpen(hours <= 24 && hoursOfLastCovidFree > 24);
        setTodayAppointment(
          todaySchedule as
            | HospitalDoctorAppointmentsView
            | HospitalServiceAppointmentsView
        );
      } else {
        setTodayAppointment(
          {} as HospitalDoctorAppointmentsView | HospitalServiceAppointmentsView
        );
      }
    }
  };

  const getPatientDoctorAppointments = async (): Promise<
    HospitalDoctorAppointmentsView[]
  > => {
    try {
      console.log("getting called -- getPatientDoctorAppointments ");
      return new Promise(async (resolve) => {
        await services.getDoctorAppointmentsPatientOrHospital(
          "patient",
          authUser.uid,
          (patientDoctorAppointments, error) => {
            if (!error) {
              resolve(
                _.filter(
                  patientDoctorAppointments,
                  (appointment) => !appointment.isCancelled
                )
              );
            } else {
              resolve([]);
            }
          }
        );
      });
    } catch (error) {
      console.log("error -- patient -- ", error);
      return [];
    }
  };

  const getPatientServiceAppointments = async (): Promise<
    HospitalServiceAppointmentsView[]
  > => {
    try {
      console.log("getting called -- getPatientServiceAppointments");
      return new Promise(async (resolve) => {
        await services.getServiceAppointmentsPatientOrHospital(
          "patient",
          authUser.uid,
          (patientServiceAppointments, error) => {
            if (!error) {
              resolve(
                _.filter(
                  patientServiceAppointments,
                  (appointment) => !appointment.isCancelled
                )
              );
            } else {
              resolve([]);
            }
          }
        );
      });
    } catch (error) {
      console.log("error -- patient -- ", error);
      return [];
    }
  };

  const updatePatientCovidFreeStatus = async (isCovidPositive: boolean) => {
    console.log("isCovidPositive -------- ", isCovidPositive);
    try {
      await services.updatePatient(authUser.uid, {
        isCovidPositive,
        lastCovidFree: getServerTimestamp(),
      });
      setShowThankyouAnsweringCovid(true);
    } catch (error) {
      console.log("error - covidchecklist -- update -- ", error);
    }
  };

  const updatePatientWebAppointmentReminder = async () => {
    try {
      await services.updatePatient(authUser.uid, {
        webAppointmentReminderAt: getServerTimestamp(),
      });
      setShowThankyouAnsweringCovid(true);
    } catch (error) {
      console.log("error - covidchecklist -- update -- ", error);
    }
  };

  return (
    <IonPage>
      <MBHeader
        {...props}
        activePage={props.activePage as Pages}
        onHideSideMenu={() => {
          setShowSideMenu(!showSideMenu);
        }}
      />
      <IonContent className="mb-container-main-container">
        <IonGrid className="mb-container-grid ion-no-margin ion-no-padding">
          <IonRow className="mb-container-row ion-no-margin ion-no-padding">
            <IonCol
              size={
                !!props.authUser &&
                !!props.authUser.userDetails &&
                showSideMenu &&
                props.authUser.userType !== USER_TYPES.hospitalAdmin.id
                  ? !isMobile()
                    ? "2"
                    : !isMobile()
                    ? "11.5"
                    : "12"
                  : "0"
              }
              className="mb-container-col-side-menu ion-no-margin ion-no-padding"
            >
              <IonList className="mb-side-nav-list">
                <div className="mb-side-nav-item-container">
                  {navLinks.map((navLink, index) => {
                    return (
                      <IonItem
                        mode="md"
                        key={`container_nav_link_${index}`}
                        color={MBCOLORS.tertiary}
                        className="mb-side-nav-item ion-justify-content-center"
                        button={true}
                        lines="none"
                        routerLink={navLink.routeURL}
                        {...(!!navLink.onClick && {
                          onClick: navLink.onClick,
                        })}
                      >
                        <IonLabel
                          className={`mb-side-nav-label mb-h5 ${
                            props.activePage === navLink.page && "blue bold"
                          }`}
                        >
                          <IonIcon
                            className={`mb-side-nav-item-icon ${
                              props.activePage === navLink.page
                                ? navLink.iconActive
                                : navLink.icon
                            }`}
                            slot="start"
                          />
                          {navLink.name}
                        </IonLabel>
                      </IonItem>
                    );
                  })}
                </div>
              </IonList>
            </IonCol>
            <IonCol
              size={
                !!props.authUser && !!props.authUser.userDetails && showSideMenu
                  ? !isMobile()
                    ? "9.5"
                    : "0"
                  : "11.5"
              }
              className="mb-container-col-content ion-no-margin ion-no-padding"
            >
              <div className="mb-container-content-container">
                {props.children}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        {isDoctorOutOfOffice && !isDoctorBannerViewed && (
          <div className="mb-container-doctor-out-of-office-banner">
            <div className="mb-container-doctor-out-of-office-banner-details-container">
              <IonIcon
                icon={warning}
                color={MBCOLORS.tertiary}
                className="mb-container-doctor-out-of-office-banner-icon"
              />
              <div className="mb-container-doctor-out-of-office-banner-description">
                <IonLabel className="mb-h4 white bold">
                  Out-of-Office status
                </IonLabel>
                <IonLabel className="mb-body white">
                  You are still out-of-office and patients are still unable to
                  book appointments with you. You can change this status by
                  visiting My Account and clicking the Out-of-Office button.
                </IonLabel>
              </div>
            </div>
            <IonButton
              className="mb-container-doctor-out-of-office-banner-close-button"
              fill="clear"
              color={MBCOLORS.tertiary}
              onClick={() => {
                setIsDoctorBannerViewed(true);
              }}
            >
              <IonIcon icon={closeOutline} />{" "}
            </IonButton>
          </div>
        )}
      </IonContent>
      {covidChecklistDialogOpen && (
        <CovidChecklistDialog
          isOpen={covidChecklistDialogOpen}
          hasCompanion={hasCompanion}
          onDidDismiss={() => setCovidChecklistDialogOpen(false)}
          onNext={(isCovidPositive: boolean) => {
            if (isCovidPositive) {
              setCovidChecklistDialogOpen(false);
              updatePatientCovidFreeStatus(true);
            } else {
              setCovidChecklistDialogOpen(false);
              updatePatientCovidFreeStatus(false);
            }
          }}
          onBack={() => {
            setCovidChecklistDialogOpen(false);
          }}
        />
      )}
      {appointmentReminderDialog && (
        <IonModal
          isOpen={appointmentReminderDialog}
          cssClass="appointment-reminder-dialog"
          onDidDismiss={() => {
            setAppointmentReminderDialog(false);
          }}
        >
          <div className="appointment-reminder-container">
            <IonLabel className="appointment-reminder-label mb-h1 dark-blue ion-no-padding ion-no-margin">
              You have an upcoming appointment
            </IonLabel>
            <IonLabel className="appointment-reminder-label mb-body ion-no-padding ion-no-margin">
              Please make sure that you take the digital covid test the day
              before the appointment & take a screenshot of the booking QR code
              for your convenience.
            </IonLabel>
            <IonIcon className="appointment-reminder-icon ion-no-padding ion-no-margin" />

            <IonButton
              mode="ios"
              className="appointment-reminder-button"
              onClick={() => {
                props.history.push(routes.PATIENT_APPOINTMENTS);
                updatePatientWebAppointmentReminder();
                setAppointmentReminderDialog(false);
              }}
            >
              View Appointments
            </IonButton>
          </div>
        </IonModal>
      )}
      <MBDialog
        isOpen={showThankyouAnsweringCovid}
        title="Thank you for your response"
        message="You may now proceed with your appointment."
        icon="success"
        onDidDismiss={() => {
          setShowThankyouAnsweringCovid(false);
        }}
      />
      <MBFooter />
    </IonPage>
  );
};
