import * as _ from "lodash";
import React from "react";
import { IonIcon, IonButton, IonModal, IonLabel } from "@ionic/react";

import "./MBDialog.scss";
import { MBCOLORS } from "../../constants/config";

interface MBDialogProps {
  title: string;
  message?: string;
  isOpen: boolean;
  icon?:
    | "success"
    | "warning"
    | "time-keeping"
    | "services"
    | "doctor"
    | "assistant"
    | "family-member";
  onDidDismiss: () => void;
  onClick?: () => void;
  buttonLabel?: string;
  miscMessage?: any;
  onApprove?: () => void; //adding prop will show yes or no option rather than "ok" button
  onDecline?: () => void; //adding prop will show yes or no option rather than "ok" button
}

export const MBDialog = (props: MBDialogProps) => {
  const {
    isOpen,
    onDidDismiss,
    title,
    message,
    icon,
    onClick,
    buttonLabel,
    miscMessage,
    onApprove,
    onDecline,
  } = props;
  return (
    <IonModal cssClass="mb-dialog" isOpen={isOpen} onDidDismiss={onDidDismiss}>
      <div className="modal-content">
        <div className="mb-dialog-children-container">
          {icon !== undefined && (
            <IonIcon className={`mb-dialog-icon ${icon}`} />
          )}
          <IonLabel className="mb-dialog-title-label mb-h2 dark-blue">
            {title}
          </IonLabel>
          {!_.isEmpty(message) && (
            <IonLabel className="mb-dialog-message-label mb-body">
              {message}
            </IonLabel>
          )}
          {!_.isEmpty(miscMessage) && (
            <IonLabel className="mb-dialog-message-label mb-body">
              {miscMessage}
            </IonLabel>
          )}
          {onApprove !== undefined && onDecline !== undefined ? (
            <div className="mb-dialog-options-container">
              <IonButton
                onClick={onDecline}
                className="mb-dialog-no-button mb-body ion-text-capitalize"
                fill="clear"
              >
                No
              </IonButton>
              <IonButton
                onClick={onApprove}
                className="mb-dialog-submit-button mb-body bold white ion-text-capitalize"
                color={MBCOLORS.primary}
              >
                Yes
              </IonButton>
            </div>
          ) : (
            <IonButton
              className="mb-dialog-action-button mb-body white bold ion-text-capitalize"
              color={MBCOLORS.primary}
              onClick={!!onClick ? onClick : onDidDismiss}
            >
              {buttonLabel || "Okay"}
            </IonButton>
          )}
        </div>
      </div>
    </IonModal>
  );
};
