import _ from "lodash";
import { getEnv } from "../env";
var env = getEnv();

const medbookwebsite = env.firebase.websiteUrl;

export const successPatientRegistration = (fullName: string) => {
  return `You are one step away from completing your registration. Please click the verification link sent in your email, after which you may now book appointments for treatments or consultations for your condition, at a hospital or physician of your choice. Maraming Salamat! - MedBook Team –`;
};

export const successHospitalRegistration = (
  fullName: string,
  hospitalName: string
) => {
  return `You and your hospital ${hospitalName} have been successfully registered in MedBook.  You may now enter your available treatment and consultation services. Maraming Salamat! - MedBook Team – `;
};

export const rejectedHospitalRegistration = (
  fullName: string,
  hospitalName: string,
  reason: string
) => {
  return `We regret to inform you that you and your hospital ${hospitalName} has failed our verification process. Reason: ( ${reason} ) If you wish to apply again please register again Maraming Salamat! - MedBook Team –`;
};

export const updateTreatmentSchedule = (
  hospitalName: string,
  hospitalRepFullName: string,
  patientFullName: string,
  originalDateOfSchedule: string
) => {
  return `Patient ${patientFullName} have changed their scheduled Treatment on ${originalDateOfSchedule}. Please review the changes made in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};

export const patientSetDoctorAppointment = (
  secretaryName: string,
  patientFullName: string,
  originalDateOfSchedule: string
) => {
  return `
 Hi ${secretaryName}, Patient ${patientFullName} have scheduled an appointment on ${originalDateOfSchedule} for Consultation.  Please review the schedule in the MedBook platform. Maraming Salamat! - MedBook Team –`;
};

export const updateDoctorAvailabilitySecretary = (
  secretaryName: string,
  doctorFullName: string
) => {
  return `Hi ${secretaryName}, ${doctorFullName} have updated his/her availability schedule. Please review the changes made in the MedBook platform. Maraming Salamat! - MedBook Team –`;
};

export const updateConsultationSchedule = (
  hospitalName: string,
  hospitalRepFullName: string,
  doctorFullName: string,
  patientFullName: string,
  originalDateOfSchedule: string
) => {
  return `Patient ${patientFullName} have changed their scheduled Consultation on ${originalDateOfSchedule}. Please review the changes made in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};

export const cancelPatientTreatmentSchedule = (
  hospitalName: string,
  hospitalRepFullName: string,
  patientFullName: string,
  originalDateOfSchedule: string,
  reason: string
) => {
  return `Patient ${patientFullName} have changed their scheduled Consultation on ${originalDateOfSchedule}. Reason: ${reason}. Please review the changes made in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};

export const secretaryCancelPatientAppointment = (
  patientFullName: string,
  secretaryName: string,
  originalDateOfSchedule: string,
  reason: string,
  hospitalName: string,
  hospitalNumber: string
) => {
  return `Hi ${patientFullName}, We are sorry for the inconvenience. ${secretaryName} have cancelled your Consultation on ${originalDateOfSchedule} due to ${reason}.  Please contact ${hospitalName} at ${hospitalNumber} for more information. If you need to re-schedule, please visit MedBook ${medbookwebsite} and re-book your appointment. Maraming Salamat! - MedBook Team –`;
};

export const cancelPatientConsultationSchedule = (
  hospitalName: string,
  hospitalRepFullName: string,
  doctorFullName: string,
  patientFullName: string,
  originalDateOfSchedule: string,
  reason: string
) => {
  return `Patient  ${patientFullName} have cancelled their scheduled Consultation on ${originalDateOfSchedule}. Reason: ${reason}. Please review the changes made in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};

export const patientCancelConsultationAppointment = (
  secretaryName: string,
  patientFullName: string,
  originalDateOfSchedule: string
) => {
  return `Hi ${secretaryName}, Patient ${patientFullName} have cancelled their scheduled Consultation on ${originalDateOfSchedule}.  Please review the changes made in the MedBook platform. Maraming Salamat! - MedBook Team –`;
};

export const doctorHospitalSecretaryCancelPatientAppointment = (
  patientFullName: string,
  hospitalName: string,
  secretaryName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  reason: string,
  doctorContactNumber: string
) => {
  return `Hi ${secretaryName}, We are sorry for the inconvenience. ${hospitalName} have cancelled patient consultation on ${originalDateOfSchedule}for patient ${patientFullName} due to ${reason}.  Please contact ${doctorFullName} at ${doctorContactNumber} for more information. Maraming Salamat!  - MedBook Team –`;
};

export const hospitalCancelDoctorAppointment = (
  hospitalName: string,
  hospitalContact: string,
  secretaryName: string,
  originalDateOfSchedule: string,
  patientName: string,
  reason: string
) => {
  return `Hi ${secretaryName}, We are sorry for the inconvenience. ${hospitalName} have cancelled patient consultation on ${originalDateOfSchedule} for patient ${patientName} due to ${reason}.  Please contact ${hospitalName} at ${hospitalContact} for more information. Maraming Salamat!  - MedBook Team –`;
};

export const hospitalCancelServiceAppointment = (
  hospitalName: string,
  hospitalContact: string,
  originalDateOfSchedule: string,
  patientName: string,
  treatmentName: string,
  reason: string
) => {
  return `
  Hi ${patientName}, We are sorry for the inconvenience. ${hospitalName} have cancelled your ${treatmentName} on ${originalDateOfSchedule} due to ${reason}.  Please contact ${hospitalName} at ${hospitalContact} for more information. If you need to re-schedule, please visit MedBook ${medbookwebsite}and re-book your appointment. Maraming Salamat! - MedBook Team –`;
};

export const scheduleTreatmentAppointment = (
  hospitalName: string,
  hospitalRepFullName: string,
  treatmentType: string,
  patientFullName: string,
  originalDateOfSchedule: string
) => {
  return `Patient ${patientFullName} have scheduled an appointment on ${originalDateOfSchedule} for ${treatmentType}. Please review the changes made in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};

export const scheduleConsultationAppointment = (
  hospitalName: string,
  hospitalRepFullName: string,
  doctorFullName: string,
  patientFullName: string,
  originalDateOfSchedule: string
) => {
  return `Patient ${patientFullName} have scheduled an appointment on ${originalDateOfSchedule} for Consultation. Please review the changes made in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};

export const representativeCancelPatientSchedule = (
  patientFullName: string,
  hospitalName: string,
  originalDateOfSchedule: string,
  reason: string,
  hospitalContactNumber: string,
  treatmentType?: string
) => {
  return `${hospitalName} have cancelled your ${
    !!treatmentType ? treatmentType : "Consultation"
  } on ${originalDateOfSchedule} due to ${reason} Please contact ${hospitalName} at ${hospitalContactNumber} for more information. The same information can be found in ${medbookwebsite}. If you need to re-schedule, please visit MedBook and re-book your appointment. Maraming Salamat! - MedBook Team –`;
};

export const representativeCancelDoctorSchedule = (
  patientFullName: string,
  hospitalName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  reason: string,
  hospitalContactNumber: string
) => {
  return `We are sorry for the inconvenience. ${hospitalName} have cancelled your consultation on ${originalDateOfSchedule} due to ${reason}. Please contact ${hospitalName} at ${hospitalContactNumber} for more information. The same information can be found in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};

export const representativeUpdatePatientSchedule = (
  patientFullName: string,
  hospitalName: string,
  originalDateOfSchedule: string,
  newDateOfSchedule: string,
  hospitalContactNumber: string,
  treatmentType?: string
) => {
  return `${hospitalName} have updated your ${
    !!treatmentType ? treatmentType : "consultation"
  } on ${originalDateOfSchedule}. It is now moved to ${newDateOfSchedule}. Please contact ${hospitalName} at ${hospitalContactNumber} for more information. The same information can be found in ${medbookwebsite}. If you need to re-schedule, please visit MedBook and re-book your appointment. Maraming Salamat! - MedBook Team –`;
};

export const doctorUpdatePatientSchedule = (
  patientFullName: string,
  doctorName: string,
  originalDateOfSchedule: string,
  newDateOfSchedule: string,
  doctorNumber: string
) => {
  return `${doctorName} have updated your consultation on ${originalDateOfSchedule}. It is now moved to ${newDateOfSchedule}. Please contact ${doctorName} at ${doctorNumber} for more information. The same information can be found in ${medbookwebsite}. If you need to re-schedule, please visit MedBook ${medbookwebsite} and re-book your appointment. Maraming Salamat! - MedBook Team –`;
};

export const representativeUpdateDoctorSchedule = (
  patientFullName: string,
  hospitalName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  newDateOfSchedule: string,
  hospitalContactNumber: string
) => {
  return `${hospitalName} have udpated your consultation on ${originalDateOfSchedule} for patient ${patientFullName}. It is now moved to ${newDateOfSchedule}. Please contact ${hospitalName} at ${hospitalContactNumber} for more information. The same information can be found in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};

export const doctorCancelPatientAppointment = (
  patientFullName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  reason: string,
  doctorContactNumber: string
) => {
  return `
  Hi ${patientFullName}, We are sorry for the inconvenience. ${doctorFullName} have cancelled your Consultation on ${originalDateOfSchedule} due to ${reason}.  Please contact ${doctorFullName} at ${doctorContactNumber} for more information. If you need to re-schedule, please visit MedBook ${medbookwebsite} and re-book your appointment. Maraming Salamat! - MedBook Team –`;
};

export const doctorCancelHospitalConsultationSchedule = (
  patientFullName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  reason: string,
  doctorContactNumber: string
) => {
  return `Dr. ${doctorFullName} have cancelled the Consultation for ${patientFullName} on ${originalDateOfSchedule} due to ${reason}. Please contact ${doctorFullName} at ${doctorContactNumber} for more information. The same information can be found in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};

export const hospitalRegisterVerificationRequest = (
  hospitalRepFullName: string,
  hospitalName: string,
  hospitalRepEmailAddress: string,
  hospitalRepContactNumber: string
) => {
  return `Hi, ${hospitalRepFullName} with the following contacts details ${hospitalRepEmailAddress} / ${hospitalRepContactNumber} of ${hospitalName} have registered.  Please review and visit MedBook to reject or verify if the hospital is already part of the Bayanihan Cancer Network. Maraming Salamat! - MedBook Team –`;
};

export const requestTransportation = (
  transportFullName: string,
  patientFullName: string,
  patientAddress: string,
  patientPhoneNumber: string,
  patientEmailAddress: string
) => {
  return `${patientFullName} from ${patientAddress} is requesting transportation. Please contact the patient with contact details ${patientPhoneNumber} / ${patientEmailAddress}. Maraming Salamat! - MedBook Team –`;
};

export const existinDoctorRegisteredInNewHospital = (
  doctorFirstName: string,
  doctorLastName: string,
  hospitalRepName: string,
  hospitalName: string
) => {
  return `${hospitalRepName} of ${hospitalName} have registered you in their hospital.  You may see this by accessing your account in ${medbookwebsite}/ Maraming Salamat! - MedBook Team –`;
};

export const updateDoctorAvailabilityByRepresentative = (
  doctorFullName: string,
  secretaryName: string,
  hospitalRepName: string,
  hospitalName: string
) => {
  return `Hi ${secretaryName}, ${hospitalRepName} of ${hospitalName} have updated ${doctorFullName} availability schedule in their hospital.  You may see this by accessing your account in ${medbookwebsite} Maraming Salamat! - MedBook Team –`;
};

export const updateDoctorAvailabilityByDoctor = (
  doctorFullName: string,
  hospitalRepName: string
) => {
  return `${doctorFullName} have updated their availability schedule for your hospital.  You may see this by accessing your account in ${medbookwebsite} Maraming Salamat! - MedBook Team –`;
};

export const doctorOutOfOfficeSecretary = (
  secretaryName: string,
  doctorName: string,
  startVL: string,
  endVL: string
) => {
  return `
  Hi ${secretaryName},
  Dr. ${doctorName} is currently out from ${startVL}${
    !_.isEmpty(endVL) ? ` to ${endVL}` : ""
  } Some patients’ schedule may be affected. Please expect some calls from them. Maraming Salamat! Your MedBook Team`;
};

export const updateDoctorUserInfoByRepresentative = (
  doctorFullName: string,
  hospitalRepName: string,
  hospitalName: string
) => {
  return `${hospitalRepName} of ${hospitalName} have updated your profile details in their hospital.  You may see this by accessing your account in ${medbookwebsite} Maraming Salamat! - MedBook Team –`;
};

export const supportGroupRegistrationRequest = (
  supportGroupName: string,
  supportGroupEmail: string,
  supportGroupNumber: string
) => {
  return `${supportGroupName} with the following contacts details ${supportGroupEmail} / ${supportGroupNumber} have registered. Please review and visit MedBook to reject or verify this registration. Maraming Salamat! - MedBook Team –`;
};

export const successSupportGroupRegistration = (supportGroupName: string) => {
  return `Your support group have been successfully registered in MedBook. Your services will now be made public to our patients. Maraming Salamat! - MedBook Team –`;
};

export const rejectedSupportGroupRegistration = (
  supportGroupName: string,
  reason: string
) => {
  return `We regret to inform you that your support group has failed our screening process. Reason: (${reason}) If you wish, you may register again. Maraming Salamat! - MedBook Team –`;
};

export const notifyPreferredDoctorPatientScheduleServices = (
  hospitalName: string,
  doctorName: string,
  deparment: string,
  treatmentType: string,
  patientFullName: string,
  hospitalContactNumber: string,
  originalDateOfSchedule: string
) => {
  return `Patient ${patientFullName} have scheduled an appointment on ${originalDateOfSchedule} for ${treatmentType}. Please contact ${hospitalName} - ${deparment} at ${hospitalContactNumber} for details. Maraming Salamat! - MedBook Team –`;
};

export const notifyPatientHospitalDeletedDoctor = (
  patientName: string,
  hospitalName: string,
  hospitalNumber: string,
  doctorName: string,
  reason: string
) => {
  return `${hospitalName} has deleted the account of Dr. ${doctorName}. You will no longer be able to receive appointments in their hospital. Your appointments with him/her in this hospital have also been cancelled. Please call the hospital at ${hospitalNumber} for details. Maraming Salamat! - MedBook Team –`;
};

export const notifyDoctorHospitalDeletedDoctor = (
  hospitalName: string,
  hospitalNumber: string,
  doctorName: string,
  reason: string
) => {
  return `${hospitalName} has deleted your account. You will no longer be able to receive appointments in their hospital. Your appointments with patients in this hospital have also been cancelled. Please call the hospital at ${hospitalNumber} for details. Maraming Salamat! - MedBook Team –`;
};

// export const notifySecurityCredentials = (
//   hospitalName: string,
//   hospitalNumber: string,
//   doctorName: string,
//   reason: string
// ) => {
//   return `${hospitalName} has deleted your account. Reason: (${reason}). You will no longer be able to receive appointments in their hospital. Your appointments with patients in this hospital have also been cancelled. Please call the hospital at ${hospitalNumber} for details. Maraming Salamat! - MedBook Team –`;
// };

export const notifyPatientOfAvailableDoctorSlot = (
  patientName: string,
  doctorName: string,
  hospitalName: string,
  appointmentDate: string
) => {
  return `Hi ${patientName},  A slot has just became available for ${doctorName} in ${hospitalName} for  ${appointmentDate} Please check your book list in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};

export const notifyPatientOfAvailableServiceSlot = (
  patientName: string,
  serviceName: string,
  hospitalName: string,
  appointmentDate: string
) => {
  return `Hi ${patientName}, A slot has just became available for ${serviceName} in ${hospitalName} for  ${appointmentDate} Please check your book list in ${medbookwebsite}. Maraming Salamat! - MedBook Team –`;
};
