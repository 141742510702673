import * as _ from "lodash";
import React from "react";
import { IonSkeletonText, IonLabel } from "@ionic/react";

interface MBLabelProps {
  loading: boolean;
  text: string;
  loadingWidth?: number;
  className?: string;
  lines?: number;
  isSpanElement?: boolean;
  innerContent?: any;
}
export const MBLabel = (props: MBLabelProps) => {
  const {
    loading,
    text,
    loadingWidth,
    className,
    lines,
    isSpanElement,
    innerContent,
  } = props;

  return loading ? (
    <>
      {Array.apply(null, { length: lines || 1 } as any).map((value, index) => {
        return (
          <IonSkeletonText
            animated
            key={`skeleton_text${value}_${index}`}
            style={{ width: `${loadingWidth || 100}%` }}
            {...(!!className && { className })}
          />
        );
      })}
    </>
  ) : !!isSpanElement ? (
    <span
      {...(!!className && { className })}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  ) : (
    <IonLabel {...(!!className && { className })}>
      {text} {!_.isEmpty(innerContent) ? innerContent : ""}
    </IonLabel>
  );
};
