import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { close } from "ionicons/icons";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonButton,
  IonIcon,
  IonLabel,
  IonLoading,
  IonToast,
} from "@ionic/react";

import "./PatientViewResource.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { MBDateInput } from "../MBDateInput/MBDateInput";
import { MBInput } from "../MBInput/MBInput";
import { MBYesOrNoRadioGroup } from "../MBYesOrNoRadioGroup/MBYesOrNoRadioGroup";
import { MBProps, DaysOfWeek } from "../../interface";
import {
  MBDropdownSelect,
  MBDropdownSelectOption,
} from "../MBDropdownSelect/MBDropdownSelect";
import {
  MBCOLORS,
  AvailableDoctorSchedule,
  AvailableServiceSchedule,
} from "../../constants/config";
import {
  useEffectOnlyOnce,
  dateTomorrow,
  formatString,
  getServerTimestamp,
  isMobile,
} from "../../functions/common";
import {
  DoctorResource,
  ServiceResource,
  DoctorSchedule,
  ServiceSchedule,
  Patient,
  HospitalDoctorAppointments,
  HospitalServiceAppointments,
  BookListItem,
} from "../../models";
import { MSGS_COMMON } from "../../constants/messages";
import { MBMiniCalendar } from "../MBMiniCalendar/MBMiniCalendar";
import { FamilyMemberFormDialog } from "../FamilyMemberFormDialog/FamilyMemberFormDialog";

interface PatientViewResourceProps extends MBProps {
  resourceToView: DoctorResource | ServiceResource;
  bookListItem?: BookListItem;
  onClose: () => void;
}

const noSlotOption = {
  id: "noSlotsAvailable",
  name: "No Slots Available",
} as MBDropdownSelectOption;

const noSchedOption = {
  id: "noSchedAvailable",
  name: "No Schedule Available",
} as MBDropdownSelectOption;

const noSchedOptionDocIsOut = {
  id: "noSchedAvailableDocIsOut",
  name: "Doctor is out on this date",
} as MBDropdownSelectOption;

export const PatientViewResource = (props: PatientViewResourceProps) => {
  const { onClose, resourceToView, bookListItem, authUser } = props;
  const patient = authUser.userDetails as Patient;
  const [familyMembersOption, setFamilyMembersOption] = useState(
    [] as MBDropdownSelectOption[]
  );
  const [selectedFamilyMember, setSelectedFamilyMember] = useState(
    {} as MBDropdownSelectOption
  );

  const serviceResource = resourceToView as ServiceResource;
  const doctorResources = resourceToView as DoctorResource;

  const isDoctorResource = doctorResources.doctorHospitalId !== undefined;

  const isAvailableToday = !_.isEmpty(
    _.find(
      resourceToView.schedule as (DoctorSchedule | ServiceSchedule)[],
      (schedule) => schedule.dayOfWeek === new Date().getDay()
    )
  );

  const [bookingFee, setBookingFee] = useState(null as number | null);
  const [selectedDate, setSelectedDate] = useState(dateTomorrow());
  const [slotOptions, setSlotOptions] = useState(
    [] as MBDropdownSelectOption[]
  );
  const [selectedSlotOption, setSelectedSlotOption] = useState(
    {} as MBDropdownSelectOption
  );

  const [isForPatientBooking, setIsForPatientBooking] = useState(
    !_.isEmpty(bookListItem)
      ? !!_.isEmpty(bookListItem!.appointment.bookingFor)
      : true
  );
  const [condition, setCondition] = useState("");

  const [errorMsgCondition, setErrorMsgCondition] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [calendarAnchor, setCalendarAnchor] = useState(
    null as Event | undefined | null
  );
  const [isOpenFamilyMemberDialog, setIsOpenFamilyMemberDialog] = useState(
    false
  );
  useEffectOnlyOnce(() => {
    // on select date
    // check if day of the week is available
    // check if there's a slot
    // if no slot, add to waiting list
    //
    console.log("booking list item", bookListItem);
    getBookingFee();
    if (_.isEmpty(bookListItem)) {
      getAvailableScheduleForResource(selectedDate);
    } else {
      const appointmentDate = bookListItem!.appointment.appointmentDate.toDate();
      setSelectedDate(appointmentDate);
      getAvailableScheduleForResource(appointmentDate);
      if (bookListItem!.isDoctorResource) {
        setCondition(
          (bookListItem!.appointment as HospitalDoctorAppointments).condition
        );
      }
    }
    getFamilyMembers();
  });

  const getFamilyMembers = () => {
    services.getFamilyMember(props.authUser.uid, (familyMembers, error) => {
      const familyMembersOption = familyMembers.map((familyMember) => {
        return {
          id: familyMember.docId || "",
          name: `${familyMember.firstName} ${familyMember.lastName} - ${familyMember.relationship}`,
        };
      });
      if (!_.isEmpty(familyMembers)) {
        setFamilyMembersOption(familyMembersOption);
        if (
          _.isEmpty(bookListItem) ||
          (!_.isEmpty(bookListItem) &&
            _.isEmpty(bookListItem!.appointment.bookingFor))
        ) {
          setSelectedFamilyMember(familyMembersOption[0]);
        } else {
          setSelectedFamilyMember(
            _.find(
              familyMembersOption,
              (familyMembers) =>
                familyMembers.id === bookListItem!.appointment.bookingFor
            )!
          );
        }
      }
      setError(error || "");
      // this.setState({
      //   familyMembers,
      //   error,
      // });
    });
  };

  const getBookingFee = async () => {
    try {
      const bookingFee = await services.getBookingFee();
      setBookingFee(bookingFee);
    } catch (e) {
      console.log("ERROR ON BOOKING FEE", e);
      setError(e);
    }
  };

  const getAvailableScheduleForResource = async (date: Date) => {
    try {
      setLoading(true);
      console.log("WILL TRY TO GET AVAILABLE");
      const availableSlots = isDoctorResource
        ? await services.getAvailableDoctorSchedule(
            date.getDay() as DaysOfWeek,
            doctorResources.docId || "",
            doctorResources.hospital.docId || "",
            date
          )
        : await services.getAvailableServiceSchedule(
            date.getDay() as DaysOfWeek,
            serviceResource.docId || "",
            date
          );

      console.log("GOT AVAILABEL SLOTS", availableSlots);

      if (
        (isDoctorResource && _.isNull(availableSlots![0])) ||
        (!isDoctorResource && _.isNull(availableSlots))
      ) {
        if (isDoctorResource && availableSlots![1]) {
          setSlotOptions([noSchedOptionDocIsOut]);
          setSelectedSlotOption(noSchedOptionDocIsOut);
        } else {
          setSlotOptions([noSchedOption]);
          setSelectedSlotOption(noSchedOption);
        }
      } else if (
        (isDoctorResource && _.isEmpty(availableSlots![0])) ||
        (!isDoctorResource && _.isEmpty(availableSlots))
      ) {
        if (isDoctorResource && availableSlots![1]) {
          setSlotOptions([noSchedOptionDocIsOut]);
          setSelectedSlotOption(noSchedOptionDocIsOut);
        } else {
          setSlotOptions([noSlotOption]);
          setSelectedSlotOption(noSlotOption);
        }
      } else {
        console.log(" got it", availableSlots);
        const options = ((isDoctorResource
          ? availableSlots![0]
          : availableSlots) as (
          | AvailableDoctorSchedule
          | AvailableServiceSchedule
        )[])!.map((schedule) => {
          return {
            id: schedule.availableSchedule.docId || "",
            name: `Est. Time ${moment(schedule.estimatedAppointmentTime).format(
              "HH:mm A"
            )} - Slot ${schedule.slot}`,
            metadata: schedule,
          };
        });
        console.log("WILL SET OPTIONS", options);
        setSlotOptions(options);
        setSelectedSlotOption(options[0]);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  const onCheckout = async (
    addToBookList = false,
    addToWaitList = false,
    toCheckout = false
  ) => {
    const formattedCondition = formatString(condition);
    let errorMsg = "";
    if (isDoctorResource && _.isEmpty(formattedCondition)) {
      setErrorMsgCondition(MSGS_COMMON.requiredField);
    } else {
      setLoading(true);
      const availableDoctorSchedule = selectedSlotOption.metadata as AvailableDoctorSchedule;
      const availablServiceSchedule = selectedSlotOption.metadata as AvailableServiceSchedule;
      const patientHasDuplicateBooking = isDoctorResource
        ? await services.patientHasDuplicateDoctorAppointmentForTheDay(
            doctorResources.docId || "",
            selectedDate,
            patient.docId || "",
            !isForPatientBooking ? selectedFamilyMember.id : ""
          )
        : await services.patientHasDuplicateServiceAppointmentForTheDay(
            serviceResource.docId || "",
            selectedDate,
            patient.docId || "",
            !isForPatientBooking ? selectedFamilyMember.id : ""
          );

      if (!patientHasDuplicateBooking) {
        if (!_.isEmpty(bookListItem)) {
          await services.deleteBookList(bookListItem!.docId!);
        }
        if (!addToWaitList) {
          const appointmentDate = isDoctorResource
            ? availableDoctorSchedule.estimatedAppointmentTime
            : availablServiceSchedule.estimatedAppointmentTime;
          const resourceId = isDoctorResource
            ? doctorResources.docId
            : serviceResource.docId;

          const hasDuplicateItemInBookList = await services.checkPatientDuplicateBookListItem(
            patient.docId as string,
            resourceId as string,
            appointmentDate,
            isDoctorResource,
            !isForPatientBooking ? selectedFamilyMember.id : ""
          );
          if (hasDuplicateItemInBookList) {
            errorMsg = "Duplicate Item selected on your Book List.";
            setError(errorMsg);
          } else {
            await services.addBookList(
              {
                ...(isDoctorResource
                  ? {
                      doctorScheduleId:
                        availableDoctorSchedule.availableSchedule.docId || "",
                      hospitalId: doctorResources.hospital.docId || "",
                      doctorId: doctorResources.docId || "",
                      patientId: patient.docId || "",
                      ...(!isForPatientBooking && {
                        bookingFor: selectedFamilyMember.id,
                      }),
                      slotNumber: availableDoctorSchedule.slot,
                      bookingFee,
                      resourceFee: doctorResources.specialities[0].fee,
                      condition: formattedCondition,
                      isCancelled: false,
                      appointmentDate: getServerTimestamp(
                        availableDoctorSchedule.estimatedAppointmentTime
                      ),
                      createdAt: getServerTimestamp(),
                    }
                  : {
                      serviceScheduleId:
                        availablServiceSchedule.availableSchedule.docId || "",
                      hospitalId: serviceResource.hospital.docId || "",
                      service: serviceResource.docId || "",
                      patientId: patient.docId || "",
                      ...(!isForPatientBooking && {
                        bookingFor: selectedFamilyMember.id,
                      }),
                      slotNumber: availablServiceSchedule.slot,
                      bookingFee,
                      resourceFee: serviceResource.fee,
                      isCancelled: false,
                      appointmentDate: getServerTimestamp(
                        availablServiceSchedule.estimatedAppointmentTime
                      ),
                      createdAt: getServerTimestamp(),
                    }),
              } as HospitalDoctorAppointments | HospitalServiceAppointments,
              resourceToView,
              isDoctorResource,
              false,
              toCheckout
            );
          }
        } else {
          await services.addBookList(
            {
              ...(isDoctorResource
                ? {
                    patientId: patient.docId || "",
                    ...(!isForPatientBooking && {
                      bookingFor: selectedFamilyMember.id,
                    }),
                    bookingFee,
                    condition: formattedCondition,
                    appointmentDate: getServerTimestamp(selectedDate),
                  }
                : {
                    patientId: patient.docId || "",
                    ...(!isForPatientBooking && {
                      bookingFor: selectedFamilyMember.id,
                    }),
                    bookingFee,
                    appointmentDate: getServerTimestamp(selectedDate),
                  }),
            } as HospitalDoctorAppointments | HospitalServiceAppointments,
            resourceToView,
            isDoctorResource,
            true,
            false
          );
        }

        setLoading(false);

        if (addToBookList) {
          if (_.isEmpty(errorMsg)) {
            onClose();
          }
        } else {
          // redirect to payment page
          props.history.push(routes.PATIENT_CHECKOUT);
        }
      } else {
        setLoading(false);
        setError("Duplicate booking found.");
      }
    }
  };

  return (
    <div className="patient-view-resource-container">
      <IonGrid className="patient-view-resource-grid ion-no-padding ion-no-margin">
        <IonRow className="patient-view-resource-row">
          <IonCol
            className={isMobile() ? "" : "ion-padding ion-margin-right"}
            size={isMobile() ? "12" : "7"}
          >
            <IonCard className="patient-view-resource-details-card ion-no-margin">
              <IonCardHeader className="patient-view-resource-details-card-header">
                <IonButton fill="clear" onClick={onClose}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonCardHeader>
              <IonCardContent
                className={`patient-view-resource-details-card-content ${
                  isMobile() && "mobile"
                } ion-no-padding`}
              >
                <IonIcon
                  className={`${
                    isDoctorResource
                      ? "patient-view-resource-doctor-icon"
                      : "services-view-resource-doctor-icon"
                  } ion-no-margin`}
                />
                <IonLabel className="patient-view-resource-name mb-h2 dark-blue bold">
                  {isDoctorResource
                    ? doctorResources.fullName
                    : serviceResource.treatmentName}
                </IonLabel>

                <IonLabel className="patient-view-resource-hospital-name mb-h4 normal">
                  {resourceToView.hospital.hospitalName}
                </IonLabel>

                <IonLabel className="patient-view-resource-weekly-label mb-body">
                  Weekly Schedule
                </IonLabel>

                <div className="patient-view-resource-schedule-days-container">
                  {(_.sortBy(resourceToView.schedule, "dayOfWeek") as (
                    | DoctorSchedule
                    | ServiceSchedule
                  )[]).map((schedule: DoctorSchedule | ServiceSchedule) => {
                    return (
                      <IonLabel
                        className={`mb-data-table-schedule-label ${
                          isMobile() ? "mobile" : "big"
                        } mb-h2 blue bold`}
                      >
                        {moment.weekdaysMin(schedule.dayOfWeek)}
                      </IonLabel>
                    );
                  })}
                </div>

                <IonLabel
                  className={`patient-view-resource-card-status mb-body ${
                    isAvailableToday ? "white" : "light not-available"
                  } bold`}
                >
                  {isAvailableToday ? "Available Today" : "Not Available Today"}
                </IonLabel>

                <div className="patient-view-resource-fee-description-section">
                  <div className="patient-view-resource-fee-description-header">
                    <IonLabel className="patient-view-resource-fee-description-header-title mb-h4 opaque normal">
                      {isDoctorResource ? "Consultation " : "Service "} Fee
                    </IonLabel>
                    <IonLabel className="mb-h4 bold">
                      PHP{" "}
                      {isDoctorResource
                        ? doctorResources.specialities[0].fee
                        : serviceResource.fee}
                    </IonLabel>
                  </div>
                  <div className="patient-view-resource-fee-description-body">
                    <IonLabel className="mb-body opaque">
                      This is the amount you need to pay upon arrival at the
                      hospital. We charge booking fee for your online
                      reservation to ensure your safety and avoid too much
                      waiting time within the hospital or clinic.
                    </IonLabel>
                  </div>
                </div>
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol
            className={isMobile() ? "patient-view-resource-col" : "ion-padding"}
            size={isMobile() ? "12" : "5"}
          >
            <IonCard className="patient-view-resource-booking-options-card ion-no-margin">
              <IonCardHeader className="patient-view-resource-booking-options-card-header">
                <IonLabel className="mb-h4 bold">Booking Options</IonLabel>
              </IonCardHeader>
              <IonCardContent
                className={`patient-view-resource-booking-options-card-content ${
                  isMobile() && "mobile"
                } ion-no-padding`}
              >
                {/* <MBDateInput
                  className="patient-view-resource-date-input"
                  value={selectedDate}
                  onChange={(selectedDate) => {
                    setSelectedDate(selectedDate);
                    getAvailableScheduleForResource(selectedDate);
                  }}
                /> */}
                <MBInput
                  label="Selected Date"
                  readonly={true}
                  value={moment(selectedDate).format("ddd, MM/DD/YYYY")}
                  type="text"
                  onClick={(event) => {
                    setCalendarAnchor(event.nativeEvent);
                  }}
                />
                <div className="patient-view-resource-drop-down-container">
                  <MBDropdownSelect
                    label="Select Slot"
                    disabled={loading}
                    className="patient-view-resource-select-schedule"
                    options={slotOptions}
                    value={selectedSlotOption.name}
                    onSelectItem={(selectedSlotOption) => {
                      setSelectedSlotOption(selectedSlotOption);
                    }}
                    selectFromOptionsRequired={true}
                    error={""}
                  />
                </div>

                {isDoctorResource && (
                  <div className="patient-view-resource-condition-container">
                    <MBInput
                      disabled={
                        selectedSlotOption.id === noSchedOption.id ||
                        selectedSlotOption.id === noSchedOptionDocIsOut.id ||
                        _.isNull(bookingFee)
                      }
                      label="What's your condition?"
                      value={condition}
                      onChange={(condition) => {
                        setCondition(condition);
                        setErrorMsgCondition("");
                      }}
                      type="text"
                      error={errorMsgCondition}
                    />
                  </div>
                )}

                <IonLabel className="patient-view-resource-booking-for-label mb-body bold">
                  This booking is for
                </IonLabel>
                <div className="patient-view-resource-booking-for-container">
                  <MBYesOrNoRadioGroup
                    disabled={
                      selectedSlotOption.id === noSchedOption.id ||
                      selectedSlotOption.id === noSchedOptionDocIsOut.id ||
                      _.isNull(bookingFee)
                    }
                    optionYesLabel={`${patient.firstName} ${patient.lastName}`}
                    optionNoLabel="Someone else"
                    value={isForPatientBooking}
                    onChange={(isForPatientBooking) => {
                      setIsForPatientBooking(isForPatientBooking);
                    }}
                  />
                </div>
                {!isForPatientBooking && (
                  <div className="patient-view-resource-drop-down-container">
                    <IonButton
                      mode="ios"
                      fill="clear"
                      className="add-member-button mb-h6 bold dark-blue ion-no-padding ion-no-margin"
                      onClick={() => {
                        setIsOpenFamilyMemberDialog(true);
                      }}
                    >
                      <u>+ Add Family Member</u>
                    </IonButton>
                    <MBDropdownSelect
                      label="Select Family Member"
                      disabled={loading}
                      className="patient-view-resource-select-schedule"
                      options={familyMembersOption}
                      value={
                        !_.isEmpty(selectedFamilyMember)
                          ? selectedFamilyMember.name
                          : ""
                      }
                      onSelectItem={(selectedFamilyMember) => {
                        setSelectedFamilyMember(selectedFamilyMember);
                      }}
                      selectFromOptionsRequired={true}
                      error={""}
                    />
                  </div>
                )}
                <div className="patient-view-resource-booking-fee-container">
                  <IonLabel className="mb-h3">Booking fee</IonLabel>
                  <IonLabel className="mb-h3 bold">
                    PHP {!_.isNull(bookingFee) ? bookingFee : "--"}
                  </IonLabel>
                </div>
              </IonCardContent>

              <IonCardContent className="patient-view-resource-footer-container ion-no-padding">
                <IonButton
                  disabled={
                    selectedSlotOption.id === noSchedOption.id ||
                    selectedSlotOption.id === noSchedOptionDocIsOut.id ||
                    _.isNull(bookingFee) ||
                    (!isForPatientBooking && _.isEmpty(selectedFamilyMember))
                  }
                  className="patient-view-resource-submit-button mb-body bold ion-text-capitalize"
                  color={MBCOLORS.success}
                  size="large"
                  fill="outline"
                  onClick={() => {
                    if (selectedSlotOption.id !== noSlotOption.id) {
                      onCheckout(true);
                    } else {
                      // WAIT LIST
                      onCheckout(true, true);
                    }
                  }}
                >
                  {selectedSlotOption.id === noSlotOption.id
                    ? "Add to Waiting List"
                    : "Add to Book List"}
                </IonButton>

                <IonButton
                  disabled={
                    selectedSlotOption.id === noSchedOption.id ||
                    selectedSlotOption.id === noSchedOptionDocIsOut.id ||
                    _.isNull(bookingFee) ||
                    selectedSlotOption.id === noSlotOption.id ||
                    (!isForPatientBooking && _.isEmpty(selectedFamilyMember))
                  }
                  className="patient-view-resource-submit-button mb-body bold white ion-text-capitalize"
                  size="large"
                  onClick={() => {
                    onCheckout(false, false, true);
                  }}
                >
                  Check Out
                </IonButton>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>

      {!_.isNull(calendarAnchor) && !_.isEmpty(calendarAnchor) && (
        <MBMiniCalendar
          calendarAnchor={calendarAnchor}
          selectedDate={selectedDate}
          removeCalendarAnchor={() => {
            setCalendarAnchor(null);
          }}
          onDateChange={(selectedDate: Date) => {
            setSelectedDate(selectedDate);
            getAvailableScheduleForResource(selectedDate);
          }}
          minDate={dateTomorrow()}
        />
      )}
      {isOpenFamilyMemberDialog && (
        <FamilyMemberFormDialog
          {...props}
          isOpen={isOpenFamilyMemberDialog}
          onCancel={() => {
            setIsOpenFamilyMemberDialog(false);
          }}
        />
      )}

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </div>
  );
};
