import { LIMITS } from "./config";

export const MSGS_COMMON_FORM_ERROR = {
  fullName: "Full Name required",
  firstName: "First Name required",
  lastName: "Last Name required",
  mobileNumber: "Mobile Number required",
  emailAddress: "Email Address required",
  Deparment: "Deparment required",
  commonField: "This field is required",
  password: "Password is required",
  passwordConfirm: "Confirm password is required",
  passwordNotMatch: "Your password do not match",
  passwordExceedLimit: "Password exceed character limit",
  passwordBelowLimit: "Password must be atleast 8 characters",
  inquiry: "Inquiry field is required",
  invalidMobileNumner: "Mobile Number is invalid",
  invalidEmail: "Email Address is invalid",
};

export const MSGS_FIREBASE = {
  invalidActionCode: "The link is invalid. Please try again.",
  userNotFound: "There was no account found with this email address.",
  incorrectPassword: "The password you have entered is incorrect.",
  emailVerificationFailedNoOOB: "Email verification failed. No OOB Code found.",
  emailRecoveryFailedNoOOB: "Email recovery failed. No OOB Code found.",
};

export const MSGS_COMMON = {
  saving: "Saving...",
  loading: "Loading...",
  requiredField: "This is required.",
  invalidField: "Please input valid value.",
  notApplicable: "N/A",
  success: "Saving success!",
  successUpdate: "Update success!",
  successDelete: "Delete success!",
  noInternet:
    "No internet connection found. Make sure you have an active connection and try again.",
  clearingAllSchedule: "Clearing schedules, This may take a while..",
};

export const MSGS_SIGNUP = {
  firstName: {
    required: "Your first name is required.",
    max: `Your first name can not exceed ${LIMITS.name.single} characters in length.`,
  },
  gender: {
    required: "Your Gender is required.",
  },
  birthday: {
    required: "Your Birthday is required.",
  },
  lastName: {
    required: "Your surname is required.",
    max: `Your surname can not exceed ${LIMITS.name.single} characters in length.`,
  },
  email: {
    required: "An email address is required.",
    invalid: "You have entered an invalid email address.",
  },
  country: "Your country is required.",
  address: "Your address is required.",
  province: "Your province is required.",
  cityMunicipality: "Your City/Municipality is required.",
  password: {
    required: "You must enter a password.",
    invalid: "Your password must be a minimum of 8 characters long.",
  },
  carModelAndPlateNo: "This field is required",
  maxAccomodation: "This field is required",
  department: {
    required: "Your department is required.",
    invalid: "This department is not valid",
  },
  profileLink: {
    required: "Your profile link is required.",
    invalid: "Your profile link is an invalid link.",
  },
  link: {
    required: "Your link is required.",
    invalid: "Invalid link.",
  },
  governmentId: {
    required: "Your Government ID is required.",
  },
  hospitalId: {
    required: "Your hospital ID is required.",
  },
  hospitalName: "Hospital name is required.",
  hospitalAddress: "Hospital address is required.",
  mobileNumber: "Your mobile number is required",
  invalidMobileNumber: "Your mobile number is invalid",
  smsVerificationPin: "The verification code was incorrect. Please try again.",
  pendingSMSVerification: "Please input pin after receiving SMS",
  success: "Sign up success!",
  canProvideTransportation:
    "Please indicate if you can provide a transportation.",
  covid19Safe: {
    required: "Please indicate if your hospital is covid-19 safe.",
    safe: "Only covid-19 safe hospitals are allowed to register.",
  },
  designation: "Designation is required.",
  userName: "User Name is required.",
  timeKeepingPassword: "Password is Required.",
};

export const MSGS_APPOINTMENT = {
  appointment: "This field is required.",
  typeOfCancer: "This field is required",
  service: "This field is required.",
  hospital: "This field is required.",
  doctor: "This field is required.",
  schedule: "Please indicated your appointment schedule",
  recurringPeriod: "This field is required.",
  recurringScheduleRange: "This field is required.",
  multiAppointmentSuccess:
    "You will need to present QR code upon arrival on the hospital. Please take a screenshot of it in every booked appointments in the booking details to avoid hassle on the appointment day.",
};

export const MSGS_DOCTOR = {
  physicianId: "Physician Id number is required.",
  firstName: {
    required: "Your first name is required.",
    max: `Your first name can not exceed ${LIMITS.name.single} characters in length.`,
  },
  lastName: {
    required: "Your surname is required.",
    max: `Your surname can not exceed ${LIMITS.name.single} characters in length.`,
  },
  email: {
    required: "An email address is required.",
    invalid: "You have entered an invalid email address.",
  },
  password: {
    required: "You must enter a password.",
    invalid: "Your password must be a minimum of 8 characters long.",
  },
  speciality: "Speciality is Required.",
  hospitalId: {
    required: "Your hospital ID is required.",
  },
  allotedSlots:
    "Please complete the schedule details. Add slots for your schedule.",
  mobileNumber: "Your mobile number is required",
  invalidMobileNumber: "Your mobile number is invalid",
  smsVerificationPin: "The verification code was incorrect. Please try again.",
  pendingSMSVerification: "Please input pin after receiving SMS",
  success: "Doctor account setup complete",
  newDoctorSuccess: "New Doctor has been added",
  successDialogMsg:
    "Doctor’s name and details added to the system. Their name will now show up on list of doctors associated with the hospital.",
  updateSuccessDialogMsg:
    "Doctor’s name and details updated to the system. Their updated details will now show up on list of doctors associated with the hospital.",
  updateSuccess: "Doctor profile updated",
  doctorExist: "Doctor already exists in this hospital.",
  addExecAssistantSuccesTitle: "New Executive Assistant has been added",
  addExecAssistantSuccesMessage:
    "Clinic assistant details are now added. Their names will now appear on the list of clinic assistants associated with you and the clinic selected",
  executiveAssistantExist:
    "Executive assistant already exists in this hospital.",
};

export const MSGS_HOSPITAL_SERVICES = {
  treatmentType: {
    required: "Treatment Type is required.",
  },
  contactNumber: "Your contact number is required.",
  invalidContactNumber: "Your contact number is invalid.",
  invalidServiceFee: "Your service fee is invalid.",
  secondaryContactNumber: "Your secondary contact number is required.",
  invalidSecondaryContactNumber: "Your secondary contact number is invalid.",
  location: "Location is required.",
  allotedSlots: "Please complete the schedule details.",
  serviceExist: "Service already exists in this hospital.",
  maxNoOfPatient: "Number of Patient is Required.",
  success: "New Out-Patient Service has been added",
};

export const MSGS_COVID_DESCRIPTIONS = {
  patientNotice: `Pursuant to Republic Act 11332 or the "Mandatory Reporting of Notifiable Diseases and Health Events of Public Health Concern Act". Please answer the questions truthfully. Answering dishonestly can endanger the lives of co-patients, frontliners and the community.`,
  transportNotice: `Pursuant to Republic Act 11332 or the "Mandatory Reporting of Notifiable Diseases and Health Events of Public Health Concern Act", your truthful declaration will help protect the lives of patients, frontliners and the community.`,
  patientSymptoms: `Please answer the questions truthfully. Answering dishonestly can endanger the lives of co-patients and frontliners.`,
};
