import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonCheckbox,
} from "@ionic/react";

import "./AppointmentsFilterDialog.scss";
import { MBModal } from "../MBModal/MBModal";
import {
  MBDropdownSelect,
  MBDropdownSelectOption,
} from "../MBDropdownSelect/MBDropdownSelect";
import { analytics } from "../../firebase";
import { ANALYTICS_CONTENT_TYPES } from "../../constants/config";
import { isMobile } from "../../functions/common";

export interface AppointmentFilterParams {
  isConsultationSelected: boolean;
  isTreatmentSelected: boolean;
  selectedDoctor: string;
  selectedService: string;
  selectedHospital: string;
  selectedPatient: string;
  isPastSelected: boolean;
  isCancelledSelected: boolean;
  isActiveSelected: boolean;
}

interface AppointmentsFilterDialogProps {
  isOpen: boolean;
  mode: "patient" | "doctor" | "hospital";
  doctorOptions?: MBDropdownSelectOption[];
  serviceOptions?: MBDropdownSelectOption[];
  hospitalOptions?: MBDropdownSelectOption[];
  patientOptions?: MBDropdownSelectOption[];
  onSave: (filterParams: AppointmentFilterParams) => void;
  onBack: () => void;
}

export const AppointmentsFilterDialog = (
  props: AppointmentsFilterDialogProps
) => {
  const {
    mode,
    isOpen,
    doctorOptions,
    serviceOptions,
    hospitalOptions,
    patientOptions,
    onSave,
    onBack,
  } = props;

  const [isConsultationSelected, setIsConsultationSelected] = useState(false);
  const [isTreatmentSelected, setIsTreatmentSelected] = useState(false);

  const [selectDoctorSearch, setSelectDoctorSearch] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState(
    {} as MBDropdownSelectOption
  );
  const [selectServiceSearch, setSelectServiceSearch] = useState("");
  const [selectedService, setSelectedService] = useState(
    {} as MBDropdownSelectOption
  );
  const [selectHospitalSearch, setSelectHospitalSearch] = useState("");
  const [selectedHospital, setSelectedHospital] = useState(
    {} as MBDropdownSelectOption
  );
  const [selectPatientSearch, setSelectPatientSearch] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(
    {} as MBDropdownSelectOption
  );
  const [isPastSelected, setIsPastSelected] = useState(false);
  const [isCancelledSelected, setIsCancelledSelected] = useState(false);
  const [isActiveSelected, setIsActiveSelected] = useState(false);

  const hasSearch = () => {
    return (
      !!isConsultationSelected ||
      !!isTreatmentSelected ||
      !_.isEmpty(selectedDoctor) ||
      !_.isEmpty(selectedService) ||
      !_.isEmpty(selectedHospital) ||
      !_.isEmpty(selectedPatient) ||
      !!isPastSelected ||
      !!isCancelledSelected ||
      !!isActiveSelected
    );
  };

  return (
    <MBModal
      isOpen={isOpen}
      onDidDismiss={onBack}
      title="Filter Appointments"
      message=""
      submitLabel="Apply"
      onSave={() => {
        if (_.isEmpty(selectedDoctor)) {
          setSelectDoctorSearch("");
        }
        if (_.isEmpty(selectedService)) {
          setSelectServiceSearch("");
        }
        if (_.isEmpty(selectedHospital)) {
          setSelectHospitalSearch("");
        }
        if (_.isEmpty(selectedPatient)) {
          setSelectPatientSearch("");
        }
        analytics.logEvent("filter_patient_appointment", {
          content_type: ANALYTICS_CONTENT_TYPES.patientAppointments.type,
        });
        onSave({
          isConsultationSelected,
          isTreatmentSelected,
          selectedDoctor: selectedDoctor.id,
          selectedService: selectedService.id,
          selectedHospital: selectedHospital.id,
          selectedPatient: selectedPatient.id,
          isPastSelected,
          isCancelledSelected,
          isActiveSelected,
        });
      }}
      onBack={onBack}
      {...(hasSearch() && {
        onClearSearch: () => {
          setIsConsultationSelected(false);
          setIsTreatmentSelected(false);
          setSelectDoctorSearch("");
          setSelectedDoctor({} as MBDropdownSelectOption);
          setSelectServiceSearch("");
          setSelectedService({} as MBDropdownSelectOption);
          setSelectHospitalSearch("");
          setSelectedHospital({} as MBDropdownSelectOption);
          setSelectPatientSearch("");
          setSelectedPatient({} as MBDropdownSelectOption);
          setIsPastSelected(false);
          setIsCancelledSelected(false);
          setIsActiveSelected(false);

          onSave({
            isConsultationSelected: false,
            isTreatmentSelected: false,
            selectedDoctor: "",
            selectedService: "",
            selectedHospital: "",
            selectedPatient: "",
            isPastSelected: false,
            isCancelledSelected: false,
            isActiveSelected: false,
          });
        },
      })}
    >
      <div className="filter-appointments-container">
        {mode !== "doctor" && (
          <>
            <IonLabel className="mb-h4">Appointment Type</IonLabel>
            <IonGrid className="filter-appointments-container ion-no-padding">
              {!isMobile() ? (
                <IonRow>
                  <IonCol className="ion-no-padding ion-no-margin" size="6">
                    <IonItem
                      className="doctor-account-setup-schedule-item"
                      lines="none"
                    >
                      <IonCheckbox
                        checked={isConsultationSelected}
                        onIonChange={(event) => {
                          setIsConsultationSelected(event.detail.checked);
                          if (!event.detail.checked) {
                            setSelectDoctorSearch("");
                            setSelectedDoctor({} as MBDropdownSelectOption);
                          }
                        }}
                        className="ion-margin-end"
                      />
                      <IonLabel className="mb-body">Consultation</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol className="ion-no-padding ion-no-margin" size="6">
                    <IonItem
                      className="doctor-account-setup-schedule-item"
                      lines="none"
                    >
                      <IonCheckbox
                        checked={isTreatmentSelected}
                        className="ion-margin-end"
                        onIonChange={(event) => {
                          setIsTreatmentSelected(event.detail.checked);
                          if (!event.detail.checked) {
                            setSelectServiceSearch("");
                            setSelectedService({} as MBDropdownSelectOption);
                          }
                        }}
                      />
                      <IonLabel className="mb-body">
                        Out-Patient Service
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              ) : (
                <>
                  <IonRow>
                    <IonCol className="ion-no-padding ion-no-margin" size="12">
                      <IonItem
                        className="doctor-account-setup-schedule-item"
                        lines="none"
                      >
                        <IonCheckbox
                          mode="md"
                          checked={isConsultationSelected}
                          onIonChange={(event) => {
                            setIsConsultationSelected(event.detail.checked);
                            if (!event.detail.checked) {
                              setSelectDoctorSearch("");
                              setSelectedDoctor({} as MBDropdownSelectOption);
                            }
                          }}
                          className="ion-margin-end"
                        />
                        <IonLabel className="mb-body">Consultation</IonLabel>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-no-padding ion-no-margin" size="12">
                      <IonItem
                        className="doctor-account-setup-schedule-item"
                        lines="none"
                      >
                        <IonCheckbox
                          mode="md"
                          checked={isTreatmentSelected}
                          className="ion-margin-end"
                          onIonChange={(event) => {
                            setIsTreatmentSelected(event.detail.checked);
                            if (!event.detail.checked) {
                              setSelectServiceSearch("");
                              setSelectedService({} as MBDropdownSelectOption);
                            }
                          }}
                        />
                        <IonLabel className="mb-body">Treatment</IonLabel>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </>
              )}
            </IonGrid>
          </>
        )}
        <IonLabel className="mb-h4 ion-margin-top">
          Appointment Information
        </IonLabel>
        <IonGrid className="filter-appointments-container ion-no-padding">
          {isConsultationSelected && mode !== "doctor" && (
            <IonRow>
              <IonCol className="ion-no-margin ion-no-padding" size="12">
                <div className="filter-appointments-select-container">
                  <MBDropdownSelect
                    label="Doctor Name"
                    value={selectedDoctor.name || selectDoctorSearch}
                    onChange={(selectDoctorSearch) => {
                      setSelectDoctorSearch(selectDoctorSearch);
                      if (selectDoctorSearch.trim() !== selectedDoctor.name) {
                        setSelectedDoctor({} as MBDropdownSelectOption);
                      }
                    }}
                    onSelectItem={(selectedDoctor) => {
                      setSelectedDoctor(selectedDoctor);
                      console.log("SELECTED DOCTOR!", selectedDoctor);
                    }}
                    options={_.uniqBy(doctorOptions, "id") || []}
                    disableTab={true}
                    selectFromOptionsRequired={true}
                  />
                </div>
              </IonCol>
            </IonRow>
          )}
          {isTreatmentSelected && (
            <IonRow>
              <IonCol className="ion-no-margin ion-no-padding" size="12">
                <div className="filter-appointments-select-container">
                  <MBDropdownSelect
                    label="Service Name"
                    value={selectedService.name || selectServiceSearch}
                    onChange={(selectServiceSearch) => {
                      setSelectServiceSearch(selectServiceSearch);
                      if (selectServiceSearch.trim() !== selectedService.name) {
                        setSelectedService({} as MBDropdownSelectOption);
                      }
                    }}
                    onSelectItem={(selectedService) => {
                      setSelectedService(selectedService);
                    }}
                    options={_.uniqBy(serviceOptions, "id") || []}
                    disableTab={true}
                    selectFromOptionsRequired={true}
                  />
                </div>
              </IonCol>
            </IonRow>
          )}
          {mode !== "hospital" && (
            <IonRow>
              <IonCol className="ion-no-margin ion-no-padding" size="12">
                <div className="filter-appointments-select-container">
                  <MBDropdownSelect
                    label="Hospital Name"
                    value={selectedHospital.name || selectHospitalSearch}
                    onChange={(selectHospitalSearch) => {
                      setSelectHospitalSearch(selectHospitalSearch);
                      if (
                        selectHospitalSearch.trim() !== selectedHospital.name
                      ) {
                        setSelectedHospital({} as MBDropdownSelectOption);
                      }
                    }}
                    onSelectItem={(selectedHospital) => {
                      setSelectedHospital(selectedHospital);
                      console.log("SELECTED DOCTOR!", selectedDoctor);
                    }}
                    options={_.uniqBy(hospitalOptions, "id") || []}
                    disableTab={true}
                    selectFromOptionsRequired={true}
                  />
                </div>
              </IonCol>
            </IonRow>
          )}
          {mode !== "patient" && (
            <IonRow>
              <IonCol className="ion-no-margin ion-no-padding" size="12">
                <div className="filter-appointments-select-container">
                  <MBDropdownSelect
                    label="Patient Name"
                    value={selectedPatient.name || selectPatientSearch}
                    onChange={(selectPatientSearch) => {
                      setSelectPatientSearch(selectPatientSearch);
                      if (selectPatientSearch.trim() !== selectedPatient.name) {
                        setSelectedPatient({} as MBDropdownSelectOption);
                      }
                    }}
                    onSelectItem={(selectedPatient) => {
                      setSelectedPatient(selectedPatient);
                      console.log("SELECTED DOCTOR!", selectedDoctor);
                    }}
                    options={_.uniqBy(patientOptions, "id") || []}
                    disableTab={true}
                    selectFromOptionsRequired={true}
                  />
                </div>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        {/* <IonLabel className="mb-h4 ion-margin-top">Schedule</IonLabel>
        <IonGrid className="filter-appointments-container ion-no-padding">
          {!isMobile() ? (
            <IonRow>
              {mode === "patient" && (
                <IonCol
                  className="ion-no-padding ion-no-margin"
                  size={mode === "patient" ? "4" : "6.5"}
                >
                  <IonItem
                    className="doctor-account-setup-schedule-item"
                    lines="none"
                  >
                    <IonCheckbox
                      mode="md"
                      checked={isPastSelected}
                      onIonChange={(event) => {
                        setIsPastSelected(event.detail.checked);
                      }}
                      className="ion-margin-end"
                    />
                    <IonLabel className="mb-body">Past</IonLabel>
                  </IonItem>
                </IonCol>
              )}
              <IonCol
                className="ion-no-padding ion-no-margin"
                size={mode === "patient" ? "4" : "6.5"}
              >
                <IonItem
                  className="doctor-account-setup-schedule-item"
                  lines="none"
                >
                  <IonCheckbox
                    mode="md"
                    checked={isCancelledSelected}
                    className="ion-margin-end"
                    onIonChange={(event) => {
                      setIsCancelledSelected(event.detail.checked);
                    }}
                  />
                  <IonLabel className="mb-body">Cancelled</IonLabel>
                </IonItem>
              </IonCol>
              <IonCol
                className="ion-no-padding ion-no-margin"
                size={mode === "patient" ? "4" : "5.5"}
              >
                <IonItem
                  className="doctor-account-setup-schedule-item"
                  lines="none"
                >
                  <IonCheckbox
                    mode="md"
                    checked={isActiveSelected}
                    className="ion-margin-end"
                    onIonChange={(event) => {
                      setIsActiveSelected(event.detail.checked);
                    }}
                  />
                  <IonLabel className="mb-body">Active</IonLabel>
                </IonItem>
              </IonCol>
            </IonRow>
          ) : (
            <>
              {mode === "patient" && (
                <IonRow>
                  <IonCol className="ion-no-padding ion-no-margin" size="12">
                    <IonItem
                      className="doctor-account-setup-schedule-item"
                      lines="none"
                    >
                      <IonCheckbox
                        mode="md"
                        checked={isPastSelected}
                        onIonChange={(event) => {
                          setIsPastSelected(event.detail.checked);
                        }}
                        className="ion-margin-end"
                      />
                      <IonLabel className="mb-body">Past</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol className="ion-no-padding ion-no-margin" size="12">
                  <IonItem
                    className="doctor-account-setup-schedule-item"
                    lines="none"
                  >
                    <IonCheckbox
                      mode="md"
                      checked={isCancelledSelected}
                      className="ion-margin-end"
                      onIonChange={(event) => {
                        setIsCancelledSelected(event.detail.checked);
                      }}
                    />
                    <IonLabel className="mb-body">Cancelled</IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-no-padding ion-no-margin" size="12">
                  <IonItem
                    className="doctor-account-setup-schedule-item"
                    lines="none"
                  >
                    <IonCheckbox
                      mode="md"
                      checked={isActiveSelected}
                      className="ion-margin-end"
                      onIonChange={(event) => {
                        setIsActiveSelected(event.detail.checked);
                      }}
                    />
                    <IonLabel className="mb-body">Active</IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid> */}
      </div>
    </MBModal>
  );
};
