import * as _ from "lodash";
import rp from "request-promise";

import { getEnv } from "../env";
import { getRequestHeaders, encodeQueryData } from "../functions/common";

var env = getEnv();

let url = env.firebase.cloudFunctionsUrl;

export const addDoctor = async (
  hospitalId: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: string,
  physicianIdNumber: string,
  specialities: string[],
  hospitalRepName: string,
  hospitalName: string
) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `users/create-doctor/${hospitalId}`,
    headers,
    body: {
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      physicianIdNumber,
      specialities,
      hospitalRepName,
      hospitalName,
    },
    json: true,
  });
};

export const addExecutiveDoctor = async (
  hospitalUID: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: string,
  doctorName: string,
  doctorHospitalId: string,
  hospitalName: string
) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `users/createExecutiveAssistant`,
    headers,
    body: {
      hospitalUID,
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      doctorName,
      doctorHospitalId,
      hospitalName,
    },
    json: true,
  });
};

export const createSecurityTimeKeeping = async (
  hospitalId: string,
  hospitalRepName: string,
  hospitalName: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  designation: string,
  userEmail: string,
  encryptedPassword: string
) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `users/createSecurityTimeKeeping`,
    headers,
    body: {
      hospitalId,
      hospitalRepName,
      hospitalName,
      firstName,
      lastName,
      phoneNumber,
      designation,
      userEmail,
      encryptedPassword,
    },
    json: true,
  });
};

export const checkIfEmailIsUnique = async (emailAddress: string) => {
  return rp({
    method: "GET",
    url:
      url +
      `users/emailUnique?${encodeQueryData({
        emailAddress,
      })}`,
    headers: {
      "Content-Type": "application/json",
    },
    json: true,
  });
};

export const transferAccount = async (
  inviteId: string,
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: string,
  machineDetails: { [key: string]: string },
  newPasswordEncrypted: string
) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `users/transferAccount/${inviteId}`,
    headers,
    body: {
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      machineDetails,
      newPasswordEncrypted,
    },
    json: true,
  });
};

export const setEmailVerified = async (userId: string) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "GET",
    url: url + `users/setEmailVerified/${userId}`,
    headers,
    json: true,
  });
};
// setEmailVerified;

export const getUserMachineDetails = async () => {
  try {
    const result: string = await rp({
      method: "GET",
      url: "https://www.cloudflare.com/cdn-cgi/trace",
      headers: {
        "Content-Type": "text/plain",
      },
    });

    /**
     * Sample return:
      fl=35f291
      h=www.cloudflare.com
      ip=120.29.115.189
      ts=1589902650.71
      visit_scheme=https
      uag=Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.149 Safari/537.36
      colo=SIN
      http=http/2
      loc=PH
      tls=TLSv1.3
      sni=plaintext
      warp=off
     */

    let machineDetails: { [key: string]: string } = {};

    const machineDetailListRaw = result.split(/(\r\n|\n|\r)/gm);
    console.log("machineDetailListRaw", machineDetailListRaw);
    machineDetailListRaw.forEach((machineDetail, index) => {
      const machineDetailSplit = machineDetail.split("=");
      const key = machineDetailSplit[0];
      const value = machineDetailSplit[1];
      if (!_.isEmpty(key) && !_.isEmpty(value)) {
        machineDetails[key] = value;
      }
    });

    return machineDetails;
  } catch (e) {
    console.log("ERROR GETTING USER'S MACHINE DATA", e);
    return {};
  }
};

export const doctorOutOfOfficeNotifyPatientsAndHospitals = async (
  doctorId: string,
  startVL: string,
  endVL: string
) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "GET",
    url:
      url +
      `users/doctorOutOfOfficeNotifyPatientsAndHospitals?${encodeQueryData({
        doctorId,
        startVL,
        endVL,
      })}`,
    headers,
    json: true,
  });
};

export const doctorInOfOfficeNotifyPatientsAndHospitals = async (
  doctorId: string,
  startVL: number,
  endVL: number | ""
) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "GET",
    url:
      url +
      `users/doctorInOfOfficeNotifyPatientsAndHospitals?${encodeQueryData({
        doctorId,
        startVL,
        endVL,
      })}`,
    headers,
    json: true,
  });
};

export const deleteUser = async (emailAddress: string) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "GET",
    url:
      url +
      `users/clearUserDatabase?${encodeQueryData({
        emailAddress,
      })}`,
    headers,
    json: true,
  });
};

export const sendSMS = async (number: string, message: string) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `users/sendSms`,
    headers,
    body: {
      number,
      message,
    },
    json: true,
  });
};
