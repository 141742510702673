import * as EmailValidator from "email-validator";
import {
  IonRow,
  IonCard,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonTextarea,
  IonButton,
  IonLoading,
  IonToast,
} from "@ionic/react";
import _ from "lodash";
import React, { useState } from "react";
import { MSGS_COMMON_FORM_ERROR } from "../../constants/messages";
import { formatString } from "../../functions/common";
import { contactUsEmailTemplate } from "../../functions/email";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import { sendEmailToSupport } from "../../services";
import { analytics } from "../../firebase";
import { ANALYTICS_CONTENT_TYPES } from "../../constants/config";

import "./LandingContact.scss";
import { MBCOLORS } from "../../constants/config";

export const LandingContact = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [inquiry, setInquiry] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const formattedName = formatString(name || "");
    const formattedMobileNumber = formatString(number || "");
    const formattedEmailAddress = formatString(email || "");
    const formattedInquiry = inquiry.replace(/(\r\n|\n|\r)/gm, "<br/>");
    let errorMessage;

    if (_.isEmpty(formattedName)) {
      errorMessage = "Name is required.";
      setError(errorMessage);
    } else if (_.isEmpty(formattedMobileNumber)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.mobileNumber;
      setError(errorMessage);
    } else if (!validatePhoneNumber(formattedMobileNumber)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.invalidMobileNumner;
      setError(errorMessage);
    } else if (_.isEmpty(formattedEmailAddress)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.emailAddress;
      setError(errorMessage);
    } else if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.invalidEmail;
      setError(errorMessage);
    } else if (_.isEmpty(formattedInquiry)) {
      errorMessage = MSGS_COMMON_FORM_ERROR.inquiry;
      setError(errorMessage);
    }

    if (!errorMessage) {
      setLoading(true);
      try {
        const splittedName = formattedName.split(" ");
        const sendContactUsEmail = contactUsEmailTemplate(
          splittedName[0],
          splittedName[splittedName.length - 1],
          formattedMobileNumber,
          formattedInquiry,
          formattedEmailAddress
        );
        await sendEmailToSupport(
          sendContactUsEmail.subject,
          sendContactUsEmail.message
        );
        analytics.logEvent("submit_contact_us", {
          content_type: ANALYTICS_CONTENT_TYPES.landingPage.type,
        });
        setLoading(false);
        setSuccess("Email Sent!");
        clearState();
      } catch (error) {
        setLoading(false);
        setError("");
      }
    }
  };

  const clearState = () => {
    setName("");
    setNumber("");
    setEmail("");
    setInquiry("");
  };

  return (
    <div id="contact">
      <IonRow
        id="contact-doctor"
        className="main bkg-blue ion-justify-content-center landing-dark-blue pb-0 ion-padding-vertical ion-padding-horizontal ion-padding"
      >
        <IonCard
          color=""
          className="card-no-shadow bkg-trans"
          ion-align-items-end
        >
          <IonRow>
            <IonCol offset="1" size="">
              <IonCard className="contact-us-landing card-no-shadow white-text pb-0 bkg-trans ion-padding ion-text-start ion-justify-content-center ion-activatable ripple-parent bord-20 ">
                <h1>Contact Us</h1>
                <p className="ion-padding-vertical">
                  Got questions about MedBook? Send us your inquiry and we'll
                  get back to you shortly.
                </p>
                <form onSubmit={onSubmit}>
                  <IonItem color="transparent">
                    <IonLabel
                      color="tertiary"
                      position="floating"
                      className="white-text bord-white"
                    >
                      Full Name
                    </IonLabel>
                    <IonInput
                      value={name}
                      color="tertiary"
                      type="text"
                      onIonChange={(e) => {
                        setName(e.detail.value || "");
                      }}
                      clearInput
                    >
                      {" "}
                    </IonInput>
                  </IonItem>
                  <br></br>
                  <IonItem color="transparent">
                    <IonLabel color="tertiary" position="floating">
                      Mobile Number
                    </IonLabel>
                    <IonInput
                      color="tertiary"
                      type="number"
                      value={number}
                      onIonChange={(e) => {
                        setNumber(e.detail.value || "");
                      }}
                    >
                      {" "}
                    </IonInput>
                  </IonItem>
                  <br></br>

                  <IonItem color="transparent">
                    <IonLabel color="tertiary" position="floating">
                      Email
                    </IonLabel>
                    <IonInput
                      color="tertiary"
                      type="email"
                      value={email}
                      onIonChange={(e) => {
                        setEmail(e.detail.value || "");
                      }}
                    >
                      {" "}
                    </IonInput>
                  </IonItem>
                  <br></br>
                  <IonItem color="transparent" className="bord-white">
                    <IonLabel color="tertiary" position="floating">
                      Your Question
                    </IonLabel>
                    <IonTextarea
                      color="tertiary"
                      rows={3}
                      cols={20}
                      value={inquiry}
                      onIonChange={(e) => {
                        setInquiry(e.detail.value || "");
                      }}
                    ></IonTextarea>
                  </IonItem>
                  <br></br>

                  <IonButton
                    id="contact-submit"
                    fill="outline"
                    color="tertiary"
                    size="large"
                    type="submit"
                  >
                    Submit
                  </IonButton>
                  <br></br>
                  <br></br>
                  <br></br>
                </form>
              </IonCard>
            </IonCol>

            <IonCol>
              <IonCard className="card-no-shadow  bkg-trans  ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent  bord-20 ">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/ContactUs.svg`}
                ></img>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonCard>
      </IonRow>
      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={"Sending.."}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={MBCOLORS.danger}
      />
      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={2000}
        onDidDismiss={() => {
          setSuccess("");
        }}
        color={MBCOLORS.success}
      />
    </div>
  );
};
