import { Helmet } from "react-helmet";
import React from "react";

import "./CommonAuthPageContainer.scss";
import { MBProps } from "../../interface";
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";
import { MBHeader } from "../../components/MBHeader/MBHeader";
import { MBFooter } from "../../components/MBFooter/MBFooter";

interface CommonAuthPageContainerProps extends MBProps {
  helmetTitle: string;
  component: JSX.Element;
}
const CommonAuthPageContainer = (props: CommonAuthPageContainerProps) => {
  const { helmetTitle, component } = props;
  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <IonPage>
        <MBHeader {...props} />
        <IonContent className="mb-page-content-bg">
          <div className="mb-page-content-bg-card-container">
            <IonGrid className="mb-page-content-grid">
              <IonRow className="mb-page-content-row">
                <IonCol
                  size="8"
                  className="mb-page-content-bg-image login"
                ></IonCol>
                <IonCol size="4" className="mb-page-content-card-col">
                  {component}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
        <MBFooter />
      </IonPage>
    </>
  );
};

export default CommonAuthPageContainer;
