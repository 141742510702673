import * as _ from "lodash";
import React from "react";
import QRCode from "qrcode.react";
import { IonIcon, IonButton, IonModal, IonLabel } from "@ionic/react";

import "./MBCheckoutSuccessDialog.scss";
import * as routes from "../../constants/routes";
import { MBProps } from "../../interface";
import { MBCOLORS } from "../../constants/config";

interface MBCheckoutSuccessDialogProps extends MBProps {
  appointmentId?: string;
  isOpen: boolean;
  onDidDismiss: () => void;
}

export const MBCheckoutSuccessDialog = (
  props: MBCheckoutSuccessDialogProps
) => {
  const { appointmentId, isOpen, onDidDismiss, history } = props;
  console.log("GOT APPOINTMENTID", appointmentId);
  return (
    <IonModal
      cssClass="mb-checkout-success"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <div className="modal-content">
        <div className="mb-checkout-success-children-container">
          <IonLabel className="mb-checkout-success-title-label mb-h2 dark-blue ">
            Check out Success!
          </IonLabel>
          <IonLabel className="mb-checkout-success-message-label mb-body">
            {!_.isEmpty(appointmentId)
              ? "Please save a screenshot of this QR code. You will need to present this code upon arrival on the hospital on your scheduled appointment. You can still access this in booking details if you forgot to do so."
              : "You will need to present QR code upon arrival on the hospital. Please take a screenshot of it in every booked appointments in the booking details to avoid hassle on the appointment day."}
          </IonLabel>
          <div className="mb-checkout-success-image-container">
            {_.isEmpty(appointmentId) ? (
              <IonIcon className={`mb-checkout-success-icon `} />
            ) : (
              <QRCode value={appointmentId!} size={205} />
            )}
          </div>

          {!_.isEmpty(appointmentId) ? (
            <div className="mb-checkout-success-options-container">
              <IonButton
                onClick={() => {
                  history.push(routes.PATIENT_APPOINTMENTS);
                }} // change with appointment id
                className="mb-checkout-success-view-booking-button mb-body bold ion-text-capitalize"
                color={MBCOLORS.primary}
              >
                View Booking
              </IonButton>
              <IonButton
                onClick={() => {
                  history.push(routes.PATIENT_HOME);
                }}
                className="mb-checkout-success-go-to-home-button mb-body bold white ion-text-capitalize"
                color={MBCOLORS.primary}
              >
                Go to Home
              </IonButton>
            </div>
          ) : (
            <IonButton
              className="mb-checkout-success-action-button mb-body white bold ion-text-capitalize"
              color={MBCOLORS.primary}
              onClick={() => {
                history.push(routes.PATIENT_APPOINTMENTS);
              }}
            >
              Go to Booked Appointments
            </IonButton>
          )}
        </div>
      </div>
    </IonModal>
  );
};
