import moment from "moment";
import * as _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToast,
} from "@ionic/react";

import "./Analytics.scss";
import * as services from "../../services";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import { MBCOLORS, USER_TYPES } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";
import { MBLabel } from "../../components/MBLabel/MBLabel";
import { calendarOutline } from "ionicons/icons";
import { MBCalendarRangeDialog } from "../../components/MBCalendarRangeDialog/MBCalendarRangeDialog";
import {
  MBDropdownSelect,
  MBDropdownSelectOption,
} from "../../components/MBDropdownSelect/MBDropdownSelect";
import { HospitalAdminDirector } from "../../models";

var Chart = require("chart.js");

const BOOKED_COLOR = "#57b9c6";
const BOOKED_COLOR_RGBA = "rgba(87, 185, 198,1)";
const CAPACITY_COLOR = "#ffa403";
const CAPACITY_COLOR_RGBA = "rgba(255, 164, 3,1)";
let bookedServicesVsCapacityBarChart: any;
let bookedConsultationsVsCapacityBarChart: any;
let bookedFutureServicesVsCapacityBarChart: any;
let bookedFutureConsultationsVsCapacityBarChart: any;

const ALL_DEPARTMENTS = "allDepartments";
const ALL_HOSPITALS = "allHospitals";

class Analytics extends React.Component<MBProps> {
  outPatientBookedVsCapacityRef: React.RefObject<HTMLCanvasElement>;
  consultationsBookedVsCapacityRef: React.RefObject<HTMLCanvasElement>;
  outPatientBookedVsCapacityBarGraphRef: React.RefObject<HTMLCanvasElement>;
  consultationBookedVsCapacityBarGraphRef: React.RefObject<HTMLCanvasElement>;
  futureOutPatientBookedVsCapacityBarGraphRef: React.RefObject<HTMLCanvasElement>;
  futureConsultationBookedVsCapacityBarGraphRef: React.RefObject<HTMLCanvasElement>;

  state = {
    loading: false,
    error: "",

    viewType: "byDepartment" as "byHospital" | "byDepartment",

    patientCount: null as null | string,
    doctorCount: null as null | string,
    servicesBookingCount: null as null | string,
    consultationBookingCount: null as null | string,

    bookedServicesVsCapacityChartFilter: "daily" as
      | "daily"
      | "weekly"
      | "monthly"
      | "range",
    bookedServicesVsCapacityChartDateFilter: {
      dateTo: new Date(),
      dateFrom: new Date(),
    },

    bookedConsultationsVsCapacityChartFilter: "daily" as
      | "daily"
      | "weekly"
      | "monthly"
      | "range",
    bookedConsultationsVsCapacityChartDateFilter: {
      dateTo: new Date(),
      dateFrom: new Date(),
    },

    bookedFutureServicesVsCapacityChartFilter: "daily" as
      | "daily"
      | "weekly"
      | "monthly"
      | "range",
    bookedFutureServicesVsCapacityChartDateFilter: {
      dateTo: new Date(),
      dateFrom: new Date(),
    },

    bookedFutureConsultationsVsCapacityChartFilter: "daily" as
      | "daily"
      | "weekly"
      | "monthly"
      | "range",
    bookedFutureConsultationsVsCapacityChartDateFilter: {
      dateTo: new Date(),
      dateFrom: new Date(),
    },

    rangeFilterFor: null as
      | null
      | "getServicesBookedVsCapacity"
      | "getConsultationBookedVsCapacity"
      | "getFutureServicesBookedVsCapacity"
      | "getFutureConsultationBookedVsCapacity",

    outPatientBookingMadeDescription: "",
    consultationBookingMadeDescription: "",
    futureOutPatientBookingMadeDescription: "",
    futureConsultationBookingMadeDescription: "",

    unsubscribeTotalHospitalDepartmentDoctor: () => {},
    unsubscribeTotalHospitalDepartmentServicesAppointments: () => {},
    unsubscribeTotalHospitalDepartmentConsultationAppointments: () => {},
    unsubscribeServicesBookedAndCapacity: () => {},
    unsubscribeDoctorsBookedAndCapacity: () => {},

    unsubscribeGetServicesBookedVsCapacity: () => {},
    unsubscribeGetConsultationBookedVsCapacity: () => {},
    unsubscribeGetFutureServicesBookedVsCapacity: () => {},
    unsubscribeGetFutureConsultationBookedVsCapacity: () => {},

    departmentOptions: [] as MBDropdownSelectOption[],
    selectedDepartmentOption: {} as MBDropdownSelectOption,
    hospitalOptions: [] as MBDropdownSelectOption[],
    selectedHospitalOption: {} as MBDropdownSelectOption,
  };

  constructor(props: MBProps) {
    super(props);
    this.outPatientBookedVsCapacityRef = React.createRef();
    this.consultationsBookedVsCapacityRef = React.createRef();
    this.consultationBookedVsCapacityBarGraphRef = React.createRef();
    this.outPatientBookedVsCapacityBarGraphRef = React.createRef();
    this.futureOutPatientBookedVsCapacityBarGraphRef = React.createRef();
    this.futureConsultationBookedVsCapacityBarGraphRef = React.createRef();
  }

  componentDidMount = async () => {
    let hospitalList = [] as string[];
    const user = this.props.authUser;
    if (user.userType === USER_TYPES.hospitalAdmin.id) {
      const hospitalAdmin = user.userDetails as HospitalAdminDirector;
      const hospitalNetworks = await Promise.all(
        hospitalAdmin.hospitalNetworks.map((hospitalNetwork) => {
          return services.getHospitalNetworkWithId(hospitalNetwork);
        })
      );

      hospitalList = _.compact(hospitalNetworks).map(
        (hospitalNetwork) => hospitalNetwork.hospitalName
      );
    }

    // departlist with ids
    // hospital list with ids
    if (hospitalList.length > 0) {
      const hospitalDepartments = await Promise.all(
        hospitalList.map((hospitalName) => {
          return services.getHospitalDepartmentWithName(hospitalName);
        })
      );

      const departmentOptions = _.flatten(hospitalDepartments).map(
        (hospital) => {
          return {
            id: hospital.docId,
            name:
              hospitalList.length > 1
                ? `${hospital.hospitalName} - ${hospital.department}`
                : hospital.department,
            metadata: [hospital.docId],
          } as MBDropdownSelectOption;
        }
      );

      let hospitalOptions = [] as MBDropdownSelectOption[];
      if (hospitalList.length > 1) {
        const groupedByHospital = _.groupBy(
          _.flatten(hospitalDepartments),
          "hospitalName"
        );
        hospitalOptions.push({
          id: ALL_HOSPITALS,
          name: "All Hospitals",
          metadata: _.flatten(hospitalDepartments).map(
            (option) => option.docId
          ),
        });
        Object.keys(groupedByHospital).map((hospitalName) => {
          hospitalOptions.push({
            id: hospitalName,
            name: hospitalName,
            metadata: groupedByHospital[hospitalName].map(
              (hospital) => hospital.docId
            ),
          });
        });
      }
      const finalDepartmentOption = [
        {
          id: ALL_DEPARTMENTS,
          name: "All Departments",
          metadata: departmentOptions.map((option) => option.id),
        },
        ...departmentOptions,
      ];

      this.setState({
        departmentOptions: finalDepartmentOption,
        selectedDepartmentOption: finalDepartmentOption[0],
        hospitalOptions,
        selectedHospitalOption: hospitalOptions[0],
        viewType: hospitalOptions.length > 0 ? "byHospital" : "byDepartment",
      });

      console.log("USE THIS --- ", {
        departmentOptions: finalDepartmentOption,
        hospitalOptions: hospitalOptions,
      });
    }

    setTimeout(() => {
      this.loadAnalyticsAndReports();
      this.getServicesBookedVsCapacity(
        this.state.bookedServicesVsCapacityChartFilter
      );
      this.getConsultationBookedVsCapacity(
        this.state.bookedConsultationsVsCapacityChartFilter
      );

      this.getFutureServicesBookedVsCapacity(
        this.state.bookedFutureServicesVsCapacityChartFilter
      );

      this.getFutureConsultationBookedVsCapacity(
        this.state.bookedFutureConsultationsVsCapacityChartFilter
      );
    }, 50);
  };

  loadAnalyticsAndReports = async () => {
    this.state.unsubscribeTotalHospitalDepartmentDoctor();
    this.state.unsubscribeTotalHospitalDepartmentServicesAppointments();
    this.state.unsubscribeTotalHospitalDepartmentConsultationAppointments();
    this.state.unsubscribeServicesBookedAndCapacity();
    this.state.unsubscribeDoctorsBookedAndCapacity();

    let hospitalForFilter = [];
    const {
      departmentOptions,
      hospitalOptions,
      viewType,
      selectedDepartmentOption,
      selectedHospitalOption,
    } = this.state;
    if (departmentOptions.length > 0 && hospitalOptions.length > 0) {
      if (viewType === "byDepartment" && !_.isEmpty(selectedDepartmentOption)) {
        hospitalForFilter = selectedDepartmentOption.metadata;
      } else if (!_.isEmpty(selectedHospitalOption)) {
        hospitalForFilter = selectedHospitalOption.metadata;
      }
    } else {
      hospitalForFilter = [this.props.authUser.uid];
    }

    let bookedServicesVsCapacityChart: any;
    let bookedConsultationsVsCapacityChart: any;

    services.getTotalPatientsRegistered((patientCount, error) => {
      console.log({
        patientCount,
        error,
      });
      this.setState({
        patientCount: "" + patientCount,
        error,
      });
    });
    const unsubscribeTotalHospitalDepartmentDoctor = await services.getTotalHospitalDepartmentDoctors(
      hospitalForFilter,
      (doctorCount, error) => {
        console.log({
          doctorCount,
          error,
        });
        this.setState({
          doctorCount: "" + doctorCount,
          error,
        });
      }
    );
    const unsubscribeTotalHospitalDepartmentServicesAppointments = await services.getTotalHospitalDepartmentServicesAppointments(
      hospitalForFilter,
      (servicesBookingCount, error) => {
        console.log({
          servicesBookingCount,
          error,
        });
        this.setState({
          servicesBookingCount: "" + servicesBookingCount,
          error,
        });
      }
    );
    const unsubscribeTotalHospitalDepartmentConsultationAppointments = await services.getTotalHospitalDepartmentConsultationAppointments(
      hospitalForFilter,
      (consultationBookingCount, error) => {
        console.log({
          consultationBookingCount,
          error,
        });
        this.setState({
          consultationBookingCount: "" + consultationBookingCount,
          error,
        });
      }
    );

    const unsubscribeServicesBookedAndCapacity = await services.getServicesBookedAndCapacity(
      hospitalForFilter,
      (bookedServicesVsCapacity, error) => {
        console.log({
          bookedServicesVsCapacity,
          error,
        });

        if (!!bookedServicesVsCapacityChart) {
          console.log("NOT EMPTY WILL DESTROYYY");
          bookedServicesVsCapacityChart.destroy();
        }
        bookedServicesVsCapacityChart = new Chart(
          this.outPatientBookedVsCapacityRef.current,
          {
            type: "pie",
            data: {
              datasets: [
                {
                  data: bookedServicesVsCapacity,
                  backgroundColor: [BOOKED_COLOR, CAPACITY_COLOR],
                  borderWidth: 1,
                },
              ],
              labels: ["Booked", "Capacity"],
            },
            options: {
              cutoutPercentage: 50,
              legend: {
                position: "bottom",
              },
            },
          }
        );
      }
    );

    const unsubscribeDoctorsBookedAndCapacity = await services.getDoctorsBookedAndCapacity(
      hospitalForFilter,
      (bookedDoctorVsCapacity, error) => {
        console.log({
          bookedDoctorVsCapacity,
          error,
        });

        if (!!bookedConsultationsVsCapacityChart) {
          console.log("NOT EMPTY WILL DESTROYYY");
          bookedConsultationsVsCapacityChart.destroy();
        }
        bookedConsultationsVsCapacityChart = new Chart(
          this.consultationsBookedVsCapacityRef.current,
          {
            type: "pie",
            data: {
              datasets: [
                {
                  data: bookedDoctorVsCapacity,
                  backgroundColor: [BOOKED_COLOR, CAPACITY_COLOR],
                  borderWidth: 1,
                },
              ],
              labels: ["Booked", "Capacity"],
            },
            options: {
              cutoutPercentage: 50,
              legend: {
                position: "bottom",
              },
            },
          }
        );
      }
    );

    this.setState({
      unsubscribeTotalHospitalDepartmentDoctor,
      unsubscribeTotalHospitalDepartmentServicesAppointments,
      unsubscribeTotalHospitalDepartmentConsultationAppointments,
      unsubscribeServicesBookedAndCapacity,
      unsubscribeDoctorsBookedAndCapacity,
    });
  };

  getServicesBookedVsCapacity = async (
    mode: "daily" | "weekly" | "monthly" | "range",
    dateFrom = new Date(),
    dateTo = new Date(),
    useStateFilter = false
  ) => {
    let hospitalForFilter = [];
    const {
      departmentOptions,
      hospitalOptions,
      viewType,
      selectedDepartmentOption,
      selectedHospitalOption,
    } = this.state;
    if (departmentOptions.length > 0 && hospitalOptions.length > 0) {
      if (viewType === "byDepartment" && !_.isEmpty(selectedDepartmentOption)) {
        hospitalForFilter = selectedDepartmentOption.metadata;
      } else if (!_.isEmpty(selectedHospitalOption)) {
        hospitalForFilter = selectedHospitalOption.metadata;
      }
    } else {
      hospitalForFilter = [this.props.authUser.uid];
    }

    this.state.unsubscribeGetServicesBookedVsCapacity();
    if (!useStateFilter) {
      if (mode === "monthly") {
        dateFrom = moment(dateFrom).subtract(3, "months").toDate();
      } else if (mode === "weekly") {
        dateFrom = moment().startOf("month").toDate();
      } else {
        dateFrom = moment(dateFrom).subtract(6, "days").toDate();
      }
      this.setState({
        bookedServicesVsCapacityChartDateFilter: {
          dateFrom,
          dateTo,
        },
      });
    } else {
      dateTo = this.state.bookedServicesVsCapacityChartDateFilter.dateTo;
      dateFrom = this.state.bookedServicesVsCapacityChartDateFilter.dateFrom;
    }

    const unsubscribeGetServicesBookedVsCapacity = await services.getServicesBookedVsCapacity(
      hospitalForFilter,
      dateFrom,
      dateTo,
      mode,
      (booked, capacity, labels, error) => {
        console.log({
          booked,
          capacity,
          labels,
          error,
        });
        this.setState({
          outPatientBookingMadeDescription: labels[0] || "",
        });

        if (!_.isEmpty(bookedServicesVsCapacityBarChart)) {
          bookedServicesVsCapacityBarChart.destroy();
        }
        bookedServicesVsCapacityBarChart = new Chart(
          this.outPatientBookedVsCapacityBarGraphRef.current,
          {
            type: "bar",
            data: {
              labels,
              datasets: [
                {
                  label: "Booked",
                  backgroundColor: BOOKED_COLOR_RGBA,
                  data: booked,
                },
                {
                  label: "Capacity",
                  backgroundColor: CAPACITY_COLOR_RGBA,
                  data: capacity,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
            },
          }
        );
      }
    );
    this.setState({
      unsubscribeGetServicesBookedVsCapacity,
    });
  };

  getConsultationBookedVsCapacity = async (
    mode: "daily" | "weekly" | "monthly" | "range",
    dateFrom = new Date(),
    dateTo = new Date(),
    useStateFilter = false
  ) => {
    let hospitalForFilter = [];
    const {
      departmentOptions,
      hospitalOptions,
      viewType,
      selectedDepartmentOption,
      selectedHospitalOption,
    } = this.state;
    if (departmentOptions.length > 0 && hospitalOptions.length > 0) {
      if (viewType === "byDepartment" && !_.isEmpty(selectedDepartmentOption)) {
        hospitalForFilter = selectedDepartmentOption.metadata;
      } else if (!_.isEmpty(selectedHospitalOption)) {
        hospitalForFilter = selectedHospitalOption.metadata;
      }
    } else {
      hospitalForFilter = [this.props.authUser.uid];
    }

    this.state.unsubscribeGetConsultationBookedVsCapacity();
    if (!useStateFilter) {
      // let dateFrom = new Date();
      // let dateTo = new Date();
      if (mode === "monthly") {
        dateFrom = moment(dateFrom).subtract(3, "months").toDate();
      } else if (mode === "weekly") {
        dateFrom = moment().startOf("month").toDate();
      } else {
        dateFrom = moment(dateFrom).subtract(6, "days").toDate();
      }
      this.setState({
        bookedConsultationsVsCapacityChartDateFilter: {
          dateFrom,
          dateTo,
        },
      });
    } else {
      dateTo = this.state.bookedConsultationsVsCapacityChartDateFilter.dateTo;
      dateFrom = this.state.bookedConsultationsVsCapacityChartDateFilter
        .dateFrom;
    }

    const unsubscribeGetConsultationBookedVsCapacity = await services.getConsultationBookedVsCapacity(
      hospitalForFilter,
      dateFrom,
      dateTo,
      mode,
      (booked, capacity, labels, error) => {
        console.log({
          label: "HEREEE",
          booked,
          capacity,
          labels,
          error,
        });
        this.setState({
          consultationBookingMadeDescription: labels[0] || "",
        });
        if (!_.isEmpty(bookedConsultationsVsCapacityBarChart)) {
          bookedConsultationsVsCapacityBarChart.destroy();
        }
        bookedConsultationsVsCapacityBarChart = new Chart(
          this.consultationBookedVsCapacityBarGraphRef.current,
          {
            type: "bar",
            data: {
              labels,
              datasets: [
                {
                  label: "Booked",
                  backgroundColor: BOOKED_COLOR_RGBA,
                  data: booked,
                },
                {
                  label: "Capacity",
                  backgroundColor: CAPACITY_COLOR_RGBA,
                  data: capacity,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
            },
          }
        );
      }
    );
    this.setState({
      unsubscribeGetConsultationBookedVsCapacity,
    });
  };

  getFutureServicesBookedVsCapacity = async (
    mode: "daily" | "weekly" | "monthly" | "range",
    dateFrom = new Date(),
    dateTo = new Date(),
    useStateFilter = false
  ) => {
    let hospitalForFilter = [];
    const {
      departmentOptions,
      hospitalOptions,
      viewType,
      selectedDepartmentOption,
      selectedHospitalOption,
    } = this.state;
    if (departmentOptions.length > 0 && hospitalOptions.length > 0) {
      if (viewType === "byDepartment" && !_.isEmpty(selectedDepartmentOption)) {
        hospitalForFilter = selectedDepartmentOption.metadata;
      } else if (!_.isEmpty(selectedHospitalOption)) {
        hospitalForFilter = selectedHospitalOption.metadata;
      }
    } else {
      hospitalForFilter = [this.props.authUser.uid];
    }

    this.state.unsubscribeGetFutureServicesBookedVsCapacity();
    if (!useStateFilter) {
      // let dateFrom = new Date();
      // let dateTo = new Date();
      if (mode === "monthly") {
        dateTo = moment(dateFrom).add(3, "months").toDate();
      } else if (mode === "weekly") {
        dateTo = moment(dateFrom).add(1, "month").toDate();
      } else {
        dateTo = moment(dateFrom).add(6, "days").toDate();
      }
      this.setState({
        bookedFutureServicesVsCapacityChartDateFilter: {
          dateFrom,
          dateTo,
        },
      });
    } else {
      dateTo = this.state.bookedFutureServicesVsCapacityChartDateFilter.dateTo;
      dateFrom = this.state.bookedFutureServicesVsCapacityChartDateFilter
        .dateFrom;
    }

    const unsubscribeGetFutureServicesBookedVsCapacity = await services.getServicesFutureBookedAndCapacity(
      hospitalForFilter,
      dateFrom,
      dateTo,
      mode,
      (booked, capacity, labels, error) => {
        console.log({
          label: "HEREEE",
          booked,
          capacity,
          labels,
          error,
        });
        this.setState({
          futureOutPatientBookingMadeDescription:
            labels.length > 0
              ? `from ${labels[0]} ${
                  labels.length > 1 ? `to ${labels[labels.length - 1]}` : ""
                }`
              : "",
        });
        if (!_.isEmpty(bookedFutureServicesVsCapacityBarChart)) {
          bookedFutureServicesVsCapacityBarChart.destroy();
        }
        bookedFutureServicesVsCapacityBarChart = new Chart(
          this.futureOutPatientBookedVsCapacityBarGraphRef.current,
          {
            type: "bar",
            data: {
              labels,
              datasets: [
                {
                  label: "Booked",
                  backgroundColor: BOOKED_COLOR_RGBA,
                  data: booked,
                },
                {
                  label: "Capacity",
                  backgroundColor: CAPACITY_COLOR_RGBA,
                  data: capacity,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
            },
          }
        );
      }
    );
    this.setState({
      unsubscribeGetFutureServicesBookedVsCapacity,
    });
  };

  getFutureConsultationBookedVsCapacity = async (
    mode: "daily" | "weekly" | "monthly" | "range",
    dateFrom = new Date(),
    dateTo = new Date(),
    useStateFilter = false
  ) => {
    let hospitalForFilter = [];
    const {
      departmentOptions,
      hospitalOptions,
      viewType,
      selectedDepartmentOption,
      selectedHospitalOption,
    } = this.state;
    if (departmentOptions.length > 0 && hospitalOptions.length > 0) {
      if (viewType === "byDepartment" && !_.isEmpty(selectedDepartmentOption)) {
        hospitalForFilter = selectedDepartmentOption.metadata;
      } else if (!_.isEmpty(selectedHospitalOption)) {
        hospitalForFilter = selectedHospitalOption.metadata;
      }
    } else {
      hospitalForFilter = [this.props.authUser.uid];
    }

    this.state.unsubscribeGetFutureConsultationBookedVsCapacity();
    if (!useStateFilter) {
      // let dateFrom = new Date();
      // let dateTo = new Date();
      if (mode === "monthly") {
        dateTo = moment(dateFrom).add(3, "months").toDate();
      } else if (mode === "weekly") {
        dateTo = moment(dateFrom).add(1, "month").toDate();
      } else {
        dateTo = moment(dateFrom).add(6, "days").toDate();
      }
      this.setState({
        bookedFutureConsultationsVsCapacityChartDateFilter: {
          dateFrom,
          dateTo,
        },
      });
    } else {
      dateTo = this.state.bookedFutureConsultationsVsCapacityChartDateFilter
        .dateTo;
      dateFrom = this.state.bookedFutureConsultationsVsCapacityChartDateFilter
        .dateFrom;
    }

    const unsubscribeGetFutureConsultationBookedVsCapacity = await services.getDoctorsFutureBookedAndCapacity(
      hospitalForFilter,
      dateFrom,
      dateTo,
      mode,
      (booked, capacity, labels, error) => {
        this.setState({
          futureConsultationBookingMadeDescription:
            labels.length > 0
              ? `from ${labels[0]} ${
                  labels.length > 1 ? `to ${labels[labels.length - 1]}` : ""
                }`
              : "",
        });
        if (!_.isEmpty(bookedFutureConsultationsVsCapacityBarChart)) {
          bookedFutureConsultationsVsCapacityBarChart.destroy();
        }
        bookedFutureConsultationsVsCapacityBarChart = new Chart(
          this.futureConsultationBookedVsCapacityBarGraphRef.current,
          {
            type: "bar",
            data: {
              labels,
              datasets: [
                {
                  label: "Booked",
                  backgroundColor: BOOKED_COLOR_RGBA,
                  data: booked,
                },
                {
                  label: "Capacity",
                  backgroundColor: CAPACITY_COLOR_RGBA,
                  data: capacity,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
            },
          }
        );
      }
    );
    this.setState({
      unsubscribeGetFutureConsultationBookedVsCapacity,
    });
  };

  render = () => {
    const {
      loading,
      error,
      viewType,

      patientCount,
      doctorCount,
      servicesBookingCount,
      consultationBookingCount,

      outPatientBookingMadeDescription,
      consultationBookingMadeDescription,

      bookedServicesVsCapacityChartFilter,
      bookedConsultationsVsCapacityChartFilter,
      bookedFutureConsultationsVsCapacityChartFilter,
      futureConsultationBookingMadeDescription,

      bookedFutureServicesVsCapacityChartFilter,
      futureOutPatientBookingMadeDescription,

      rangeFilterFor,

      departmentOptions,
      selectedDepartmentOption,
      hospitalOptions,
      selectedHospitalOption,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>MedBook - Hospital - Analytics</title>
        </Helmet>
        <MBContainer {...this.props} activePage="hospital-analytics">
          <div className="hospital-analytics-container">
            <IonLabel className="mb-h1 dark-blue">
              {`Analytics & Reports`}
            </IonLabel>
            <IonGrid className="hospital-analytics-total-grid ion-no-padding ion-no-margin ion-margin-top">
              <IonRow className="ion-margin-bottom">
                <IonCol
                  className="ion-no-padding ion-no-margin ion-padding-end"
                  size="6"
                >
                  <IonCard className="hospital-analytics-total-card ion-no-padding ion-no-margin">
                    <IonCardContent className="hospital-analytics-total-card-content ion-no-padding ion-no-margin">
                      <IonIcon className="hospital-analytics-total-icon patient" />
                      <div className="hospital-analytics-total-container">
                        <MBLabel
                          className="mb-h1 dark-blue"
                          loading={_.isNull(patientCount)}
                          text={!_.isEmpty(patientCount) ? patientCount! : ""}
                        />
                        <IonLabel className="mb-body dark-blue">
                          Overall No. of Patents Registered
                        </IonLabel>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol
                  className="ion-no-padding ion-no-margin ion-padding-start"
                  size="6"
                >
                  <IonCard className="hospital-analytics-total-card ion-no-padding ion-no-margin">
                    <IonCardContent className="hospital-analytics-total-card-content ion-no-padding ion-no-margin">
                      <IonIcon className="hospital-analytics-total-icon doctor" />
                      <div className="hospital-analytics-total-container">
                        <MBLabel
                          className="mb-h1 dark-blue"
                          loading={_.isNull(doctorCount)}
                          text={!_.isEmpty(doctorCount) ? doctorCount! : ""}
                        />
                        <IonLabel className="mb-body dark-blue">
                          Overall No. of Doctors Registered
                        </IonLabel>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>

              {this.props.authUser.userType === USER_TYPES.hospitalAdmin.id && (
                <>
                  <IonRow className="ion-margin-top ion-padding-top">
                    <IonCol className="ion-no-padding ion-no-margin" size="12">
                      <div className="hospital-analytics-view-type-container ion-no-margin ion-no-padding">
                        {hospitalOptions.length > 0 && (
                          <IonButton
                            fill="clear"
                            mode="ios"
                            className={`hospital-analytics-view-type mb-h4 bold ${
                              viewType === "byHospital"
                                ? `active blue`
                                : `light`
                            } ion-no-margin ion-no-padding`}
                            onClick={() => {
                              // onClickStatus("today");
                              this.setState({
                                viewType: "byHospital",
                              });

                              setTimeout(() => {
                                this.loadAnalyticsAndReports();
                                this.getServicesBookedVsCapacity(
                                  this.state
                                    .bookedServicesVsCapacityChartFilter,
                                  new Date(),
                                  new Date(),
                                  true
                                );
                                this.getConsultationBookedVsCapacity(
                                  this.state
                                    .bookedConsultationsVsCapacityChartFilter,
                                  new Date(),
                                  new Date(),
                                  true
                                );

                                this.getFutureServicesBookedVsCapacity(
                                  this.state
                                    .bookedFutureServicesVsCapacityChartFilter,
                                  new Date(),
                                  new Date(),
                                  true
                                );

                                this.getFutureConsultationBookedVsCapacity(
                                  this.state
                                    .bookedFutureConsultationsVsCapacityChartFilter,
                                  new Date(),
                                  new Date(),
                                  true
                                );
                              }, 50);
                            }}
                          >
                            By Hospital
                          </IonButton>
                        )}
                        <IonButton
                          fill="clear"
                          mode="ios"
                          className={`hospital-analytics-view-type mb-h4 bold ${
                            viewType === "byDepartment"
                              ? `active blue`
                              : `light`
                          } ion-no-margin ion-no-padding`}
                          onClick={() => {
                            // onClickStatus("today");
                            this.setState({
                              viewType: "byDepartment",
                            });
                            setTimeout(() => {
                              this.loadAnalyticsAndReports();
                              this.getServicesBookedVsCapacity(
                                this.state.bookedServicesVsCapacityChartFilter,
                                new Date(),
                                new Date(),
                                true
                              );
                              this.getConsultationBookedVsCapacity(
                                this.state
                                  .bookedConsultationsVsCapacityChartFilter,
                                new Date(),
                                new Date(),
                                true
                              );

                              this.getFutureServicesBookedVsCapacity(
                                this.state
                                  .bookedFutureServicesVsCapacityChartFilter,
                                new Date(),
                                new Date(),
                                true
                              );

                              this.getFutureConsultationBookedVsCapacity(
                                this.state
                                  .bookedFutureConsultationsVsCapacityChartFilter,
                                new Date(),
                                new Date(),
                                true
                              );
                            }, 50);
                          }}
                        >
                          By Department
                        </IonButton>
                      </div>
                    </IonCol>
                  </IonRow>

                  <IonRow className="ion-margin-top ion-padding-top">
                    <IonCol className="ion-no-padding ion-no-margin" size="12">
                      <div
                        className="hospital-analytics-view-type-options-container"
                        // color={MBCOLORS.medium}
                      >
                        <IonLabel className="mb-h2 bold dark-blue">
                          {viewType === "byHospital"
                            ? "All Hospitals"
                            : "All Departments"}
                        </IonLabel>

                        <div
                          slot="end"
                          className="hospital-analytics-hospital-filter-container"
                        >
                          <MBDropdownSelect
                            className="hospital-analytics-hospital-filter-drop-down-options"
                            options={
                              viewType === "byHospital"
                                ? hospitalOptions
                                : departmentOptions
                            }
                            value={
                              viewType === "byHospital"
                                ? selectedHospitalOption.name
                                : selectedDepartmentOption.name
                            }
                            onSelectItem={(selectedOption) => {
                              if (viewType === "byHospital") {
                                this.setState({
                                  selectedHospitalOption: selectedOption,
                                });
                              } else {
                                this.setState({
                                  selectedDepartmentOption: selectedOption,
                                });
                              }

                              setTimeout(() => {
                                this.loadAnalyticsAndReports();
                                this.getServicesBookedVsCapacity(
                                  this.state
                                    .bookedServicesVsCapacityChartFilter,
                                  new Date(),
                                  new Date(),
                                  true
                                );
                                this.getConsultationBookedVsCapacity(
                                  this.state
                                    .bookedConsultationsVsCapacityChartFilter,
                                  new Date(),
                                  new Date(),
                                  true
                                );

                                this.getFutureServicesBookedVsCapacity(
                                  this.state
                                    .bookedFutureServicesVsCapacityChartFilter,
                                  new Date(),
                                  new Date(),
                                  true
                                );

                                this.getFutureConsultationBookedVsCapacity(
                                  this.state
                                    .bookedFutureConsultationsVsCapacityChartFilter,
                                  new Date(),
                                  new Date(),
                                  true
                                );
                              }, 50);
                            }}
                            selectFromOptionsRequired={true}
                            error={""}
                          />
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </>
              )}

              <IonRow className="ion-margin-top ion-padding-top">
                <IonCol className="ion-no-padding ion-no-margin" size="12">
                  <IonItem
                    className="hospital-analytics-todays-stat-label landing-light"
                    lines="none"
                    // color={MBCOLORS.medium}
                  >
                    <IonLabel className="mb-h3">
                      <b>Today's Stats</b> -{" "}
                      {moment(new Date()).format("MMMM DD, YYYY")}
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow className="ion-margin-top">
                <IonCol
                  className="ion-no-padding ion-no-margin ion-padding-end"
                  size="6"
                >
                  <IonCard className="hospital-analytics-total-card ion-no-padding ion-no-margin">
                    <IonCardContent className="hospital-analytics-total-card-content ion-no-padding ion-no-margin">
                      <IonIcon className="hospital-analytics-total-icon out-patient" />
                      <div className="hospital-analytics-total-container">
                        <MBLabel
                          className="mb-h1 dark-blue"
                          loading={_.isNull(servicesBookingCount)}
                          text={
                            !_.isEmpty(servicesBookingCount)
                              ? servicesBookingCount!
                              : ""
                          }
                        />
                        <IonLabel className="mb-body dark-blue">
                          Total No. of OutPatients Booking Made
                        </IonLabel>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol
                  className="ion-no-padding ion-no-margin ion-padding-start"
                  size="6"
                >
                  <IonCard className="hospital-analytics-total-card ion-no-padding ion-no-margin">
                    <IonCardContent className="hospital-analytics-total-card-content ion-no-padding ion-no-margin">
                      <IonIcon className="hospital-analytics-total-icon consultation" />
                      <div className="hospital-analytics-total-container">
                        <MBLabel
                          className="mb-h1 dark-blue"
                          loading={_.isNull(consultationBookingCount)}
                          text={
                            !_.isEmpty(consultationBookingCount)
                              ? consultationBookingCount!
                              : ""
                          }
                        />
                        <IonLabel className="mb-body dark-blue">
                          Total No. of Consulatation Made
                        </IonLabel>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>

              <IonRow className="ion-margin-top">
                <IonCol
                  className="ion-no-padding ion-no-margin ion-padding-end"
                  size="6"
                >
                  <IonCard className="hospital-analytics-total-card ion-no-padding ion-no-margin">
                    <IonCardContent className="hospital-analytics-doughnut-card-content ion-no-padding ion-no-margin ion-text-center">
                      <IonLabel className="mb-h4 dark-blue ion-margin-bottom ion-padding-bottom">
                        Out-patient Booked vs Capacity
                      </IonLabel>
                      <canvas
                        ref={this.outPatientBookedVsCapacityRef}
                        className="out-patient-booked-vs-capacity"
                        id="out-patient-booked-vs-capacity"
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
                <IonCol
                  className="ion-no-padding ion-no-margin ion-padding-start"
                  size="6"
                >
                  <IonCard className="hospital-analytics-total-card ion-no-padding ion-no-margin">
                    <IonCardContent className="hospital-analytics-doughnut-card-content ion-no-padding ion-no-margin ion-text-center">
                      <IonLabel className="mb-h4 dark-blue ion-margin-bottom ion-padding-bottom">
                        Consulations Booked vs Capacity
                      </IonLabel>
                      <canvas
                        ref={this.consultationsBookedVsCapacityRef}
                        className="consultations-booked-vs-capacity"
                        id="consultations-booked-vs-capacity"
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>

              <IonRow className="ion-margin-top">
                <IonCol className="ion-no-padding ion-no-margin" size="12">
                  <IonCard className="hospital-analytics-total-card ion-no-padding ion-no-margin">
                    <IonCardContent className="hospital-analytics-doughnut-card-content ion-no-padding ion-no-margin">
                      <div className="hospital-analytics-bar-charts-details-and-filters-content">
                        <div className="hospital-analytics-bar-charts-details-labels-content">
                          <IonLabel className="mb-h4 dark-blue">
                            {`Out-patient Booking Made - ${_.capitalize(
                              bookedServicesVsCapacityChartFilter
                            )}`}
                          </IonLabel>
                          <IonLabel className="mb-body light ion-margin-bottom ion-padding-bottom">
                            {outPatientBookingMadeDescription!!
                              ? outPatientBookingMadeDescription
                              : ""}
                          </IonLabel>
                        </div>
                        <IonSegment
                          className="hospital-analytics-bar-charts-filter-segment"
                          value={bookedServicesVsCapacityChartFilter}
                          onIonChange={(e) => {
                            const result = e.detail.value as
                              | "daily"
                              | "weekly"
                              | "monthly"
                              | "range";
                            this.setState({
                              bookedServicesVsCapacityChartFilter: result,
                            });
                            if (result !== "range") {
                              setTimeout(() => {
                                // rangeFilterFor
                                this.getServicesBookedVsCapacity(result);
                              }, 50);
                            }
                          }}
                        >
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="range"
                            onClick={() => {
                              this.setState({
                                rangeFilterFor: "getServicesBookedVsCapacity",
                              });
                            }}
                          >
                            <IonLabel className="mb-display-flex mb-margin-auto ion-align-items-center ion-text-center ion-text-capitalize">
                              Date Range
                              <IonIcon
                                className="ion-padding-start"
                                icon={calendarOutline}
                              />
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="daily"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Daily
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="weekly"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Weekly
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="monthly"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Monthly
                            </IonLabel>
                          </IonSegmentButton>
                        </IonSegment>
                      </div>

                      <canvas
                        ref={this.outPatientBookedVsCapacityBarGraphRef}
                        className="out-patient-booked-vs-capacity"
                        id="out-patient-booked-vs-capacity"
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol className="ion-no-padding ion-no-margin" size="12">
                  <IonCard className="hospital-analytics-total-card ion-no-padding ion-no-margin">
                    <IonCardContent className="hospital-analytics-doughnut-card-content  ion-no-padding ion-no-margin">
                      <div className="hospital-analytics-bar-charts-details-and-filters-content">
                        <div className="hospital-analytics-bar-charts-details-labels-content">
                          <IonLabel className="mb-h4 dark-blue">
                            {`Consulations Booking Made - ${_.capitalize(
                              bookedConsultationsVsCapacityChartFilter
                            )}`}
                          </IonLabel>
                          <IonLabel className="mb-body light ion-margin-bottom ion-padding-bottom">
                            {consultationBookingMadeDescription!!
                              ? consultationBookingMadeDescription
                              : ""}
                          </IonLabel>
                        </div>
                        <IonSegment
                          className="hospital-analytics-bar-charts-filter-segment"
                          value={bookedConsultationsVsCapacityChartFilter}
                          onIonChange={(e) => {
                            const result = e.detail.value as
                              | "daily"
                              | "weekly"
                              | "monthly"
                              | "range";
                            this.setState({
                              bookedConsultationsVsCapacityChartFilter: result,
                            });
                            if (result !== "range") {
                              setTimeout(() => {
                                // rangeFilterFor
                                this.getConsultationBookedVsCapacity(result);
                              }, 50);
                            }
                          }}
                        >
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="range"
                            onClick={() => {
                              this.setState({
                                rangeFilterFor:
                                  "getConsultationBookedVsCapacity",
                              });
                            }}
                          >
                            <IonLabel className="mb-display-flex mb-margin-auto ion-align-items-center ion-text-center ion-text-capitalize">
                              Date Range
                              <IonIcon
                                className="ion-padding-start"
                                icon={calendarOutline}
                              />
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="daily"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Daily
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="weekly"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Weekly
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="monthly"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Monthly
                            </IonLabel>
                          </IonSegmentButton>
                        </IonSegment>
                      </div>
                      <canvas
                        ref={this.consultationBookedVsCapacityBarGraphRef}
                        className="consultations-booked-vs-capacity"
                        id="consultations-booked-vs-capacity"
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>

              <IonRow className="ion-margin-top">
                <IonCol className="ion-no-padding ion-no-margin" size="12">
                  <IonCard className="hospital-analytics-total-card ion-no-padding ion-no-margin">
                    <IonCardContent className="hospital-analytics-doughnut-card-content  ion-no-padding ion-no-margin">
                      <div className="hospital-analytics-bar-charts-details-and-filters-content">
                        <div className="hospital-analytics-bar-charts-details-labels-content">
                          <IonLabel className="mb-h4 dark-blue">
                            Upcoming Outpatient Appointments
                          </IonLabel>
                          <IonLabel className="mb-body light ion-margin-bottom ion-padding-bottom">
                            {futureOutPatientBookingMadeDescription}
                          </IonLabel>
                        </div>
                        <IonSegment
                          className="hospital-analytics-bar-charts-filter-segment"
                          value={bookedFutureServicesVsCapacityChartFilter}
                          onIonChange={(e) => {
                            const result = e.detail.value as
                              | "daily"
                              | "weekly"
                              | "monthly"
                              | "range";
                            this.setState({
                              bookedFutureServicesVsCapacityChartFilter: result,
                            });
                            if (result !== "range") {
                              setTimeout(() => {
                                // rangeFilterFor
                                this.getFutureServicesBookedVsCapacity(result);
                              }, 50);
                            }
                          }}
                        >
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="range"
                            onClick={() => {
                              this.setState({
                                rangeFilterFor:
                                  "getFutureServicesBookedVsCapacity",
                              });
                            }}
                          >
                            <IonLabel className="mb-display-flex mb-margin-auto ion-align-items-center ion-text-center ion-text-capitalize">
                              Date Range
                              <IonIcon
                                className="ion-padding-start"
                                icon={calendarOutline}
                              />
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="daily"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Daily
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="weekly"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Weekly
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="monthly"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Monthly
                            </IonLabel>
                          </IonSegmentButton>
                        </IonSegment>
                      </div>
                      <canvas
                        ref={this.futureOutPatientBookedVsCapacityBarGraphRef}
                        className="consultations-booked-vs-capacity"
                        id="consultations-booked-vs-capacity"
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>

              <IonRow className="ion-margin-top">
                <IonCol className="ion-no-padding ion-no-margin" size="12">
                  <IonCard className="hospital-analytics-total-card ion-no-padding ion-no-margin">
                    <IonCardContent className="hospital-analytics-doughnut-card-content  ion-no-padding ion-no-margin">
                      <div className="hospital-analytics-bar-charts-details-and-filters-content">
                        <div className="hospital-analytics-bar-charts-details-labels-content">
                          <IonLabel className="mb-h4 dark-blue">
                            Upcoming Consultations
                          </IonLabel>
                          <IonLabel className="mb-body light ion-margin-bottom ion-padding-bottom">
                            {futureConsultationBookingMadeDescription}
                          </IonLabel>
                        </div>
                        <IonSegment
                          className="hospital-analytics-bar-charts-filter-segment"
                          value={bookedFutureConsultationsVsCapacityChartFilter}
                          onIonChange={(e) => {
                            const result = e.detail.value as
                              | "daily"
                              | "weekly"
                              | "monthly"
                              | "range";
                            this.setState({
                              bookedFutureConsultationsVsCapacityChartFilter: result,
                            });
                            if (result !== "range") {
                              setTimeout(() => {
                                // rangeFilterFor
                                this.getFutureConsultationBookedVsCapacity(
                                  result
                                );
                              }, 50);
                            }
                          }}
                        >
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="range"
                            onClick={() => {
                              this.setState({
                                rangeFilterFor:
                                  "getFutureConsultationBookedVsCapacity",
                              });
                            }}
                          >
                            <IonLabel className="mb-display-flex mb-margin-auto ion-align-items-center ion-text-center ion-text-capitalize">
                              Date Range
                              <IonIcon
                                className="ion-padding-start"
                                icon={calendarOutline}
                              />
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="daily"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Daily
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="weekly"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Weekly
                            </IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton
                            className="hospital-analytics-bar-charts-filter-segment-button"
                            value="monthly"
                          >
                            <IonLabel className="ion-text-center ion-text-capitalize">
                              Monthly
                            </IonLabel>
                          </IonSegmentButton>
                        </IonSegment>
                      </div>
                      <canvas
                        ref={this.futureConsultationBookedVsCapacityBarGraphRef}
                        className="consultations-booked-vs-capacity"
                        id="consultations-booked-vs-capacity"
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>

          {!_.isNull(rangeFilterFor) && (
            <MBCalendarRangeDialog
              isOpen={!_.isNull(rangeFilterFor)}
              isHistorical={
                [
                  "getFutureServicesBookedVsCapacity",
                  "getFutureConsultationBookedVsCapacity",
                ].indexOf(rangeFilterFor) !== -1
                  ? false
                  : true
              }
              maxRange={30}
              title="Please select date range"
              errorMessage="You must indicate a start and end date"
              onDidDismiss={() => {
                this.setState({
                  rangeFilterFor: null,
                });
              }}
              onSubmit={(startDate, endDate) => {
                //   bookedServicesVsCapacityChartFilter: "daily",
                // bookedServicesVsCapacityChartDateFilter: {
                //   dateTo: new Date(),
                //   dateFrom: new Date(),
                // },

                // bookedConsultationsVsCapacityChartFilter: "daily",
                // bookedConsultationsVsCapacityChartDateFilter: {
                //   dateTo: new Date(),
                //   dateFrom: new Date(),
                // },

                // bookedFutureServicesVsCapacityChartFilter: "daily",
                // bookedFutureServicesVsCapacityChartDateFilter: {
                //   dateTo: new Date(),
                //   dateFrom: new Date(),
                // },

                // bookedFutureConsultationsVsCapacityChartFilter: "daily",
                // bookedFutureConsultationsVsCapacityChartDateFilter: {
                //   dateTo: new Date(),
                //   dateFrom: new Date(),
                // },

                if (rangeFilterFor === "getConsultationBookedVsCapacity") {
                  this.setState({
                    bookedConsultationsVsCapacityChartFilter: "daily",
                    bookedConsultationsVsCapacityChartDateFilter: {
                      dateFrom: startDate,
                      dateTo: endDate,
                    },
                  });
                  setTimeout(() => {
                    this.getConsultationBookedVsCapacity(
                      "daily",
                      startDate,
                      endDate!
                    );
                  }, 50);
                } else if (rangeFilterFor === "getServicesBookedVsCapacity") {
                  this.setState({
                    bookedServicesVsCapacityChartFilter: "daily",
                    bookedServicesVsCapacityChartDateFilter: {
                      dateFrom: startDate,
                      dateTo: endDate,
                    },
                  });
                  setTimeout(() => {
                    this.getServicesBookedVsCapacity(
                      "daily",
                      startDate,
                      endDate!
                    );
                  }, 50);
                } else if (
                  rangeFilterFor === "getFutureServicesBookedVsCapacity"
                ) {
                  this.setState({
                    bookedFutureServicesVsCapacityChartFilter: "daily",
                    bookedFutureServicesVsCapacityChartDateFilter: {
                      dateFrom: startDate,
                      dateTo: endDate,
                    },
                  });
                  setTimeout(() => {
                    this.getFutureServicesBookedVsCapacity(
                      "daily",
                      startDate,
                      endDate!
                    );
                  }, 50);
                } else if (
                  rangeFilterFor === "getFutureConsultationBookedVsCapacity"
                ) {
                  this.setState({
                    bookedFutureConsultationsVsCapacityChartFilter: "daily",
                    bookedFutureConsultationsVsCapacityChartDateFilter: {
                      dateFrom: startDate,
                      dateTo: endDate,
                    },
                  });
                  setTimeout(() => {
                    this.getFutureConsultationBookedVsCapacity(
                      "daily",
                      startDate,
                      endDate!
                    );
                  }, 50);
                }
                this.setState({
                  rangeFilterFor: null,
                });
              }}
              onCancel={() => {
                this.setState({
                  rangeFilterFor: null,
                });
              }}
            />
          )}

          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />

          <IonToast
            isOpen={!_.isEmpty(error)}
            message={error}
            duration={2000}
            onDidDismiss={() => this.setState({ error: "" })}
            color={MBCOLORS.danger}
          />
        </MBContainer>
      </>
    );
  };
}

export default Analytics;
