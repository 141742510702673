import _ from "lodash";
import React, { useState } from "react";
import {
  IonIcon,
  IonButton,
  IonModal,
  IonLabel,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonToast,
} from "@ionic/react";

import "./CancelReasonDialog.scss";
import { isMobile } from "../../functions/common";
import { MBCOLORS } from "../../constants/config";
import { MBInput } from "../MBInput/MBInput";

interface CancelReasonDialogProps {
  title: string;
  message?: string;
  isOpen: boolean;
  icon: "success" | "warning";
  onDidDismiss: () => void;
  onApprove: (reason: string) => void;
  onDecline: () => void;
  mode:
    | "appointment"
    | "application"
    | "support-group"
    | "services"
    | "doctors"
    | "patient-appointment";
}

export const CancelReasonDialog = (props: CancelReasonDialogProps) => {
  const {
    isOpen,
    mode,
    onDidDismiss,
    title,
    message,
    icon,
    onApprove,
    onDecline,
  } = props;
  const [selectedValue, setSelectedValue] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [error, setError] = useState("");

  const onSubmit = () => {
    if (_.isEmpty(selectedValue) && mode !== "support-group") {
      setError("Please select reason");
    } else {
      if (selectedValue === "others" || mode === "support-group") {
        if (!_.isEmpty(otherReason)) {
          onApprove(otherReason);
        } else {
          setError("Please add reason");
        }
      } else {
        onApprove(selectedValue);
      }
    }
  };
  return (
    <IonModal
      cssClass="cancel-reason-dialog"
      isOpen={isOpen}
      onDidDismiss={() => {
        onDidDismiss();
        setSelectedValue("");
      }}
    >
      <div className="modal-content">
        <div
          className={`cancel-reason-dialog-children-container ${
            isMobile() && "mobile"
          }`}
        >
          <IonIcon className={`cancel-reason-dialog-icon ${icon}`} />
          <IonLabel className="cancel-reason-dialog-title-label mb-h2 dark-blue ion-text-center">
            {title}
          </IonLabel>
          <IonLabel className="cancel-reason-dialog-message-label mb-body warning">
            {message}
          </IonLabel>
          <IonRadioGroup
            color={MBCOLORS.tertiary}
            className="cancel-reason-dialog-radio-container"
            value={selectedValue}
            onIonChange={(e) => setSelectedValue(e.detail.value)}
          >
            {mode === "appointment" ? (
              <>
                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    Schedule is full
                  </IonLabel>
                  <IonRadio slot="start" mode="md" value="Schedule is full" />
                </IonItem>

                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    Risk is too high
                  </IonLabel>
                  <IonRadio slot="start" mode="md" value="Risk is too high" />
                </IonItem>

                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    On leave / unavailable
                  </IonLabel>
                  <IonRadio
                    slot="start"
                    mode="md"
                    value="On leave / unavailable"
                  />
                </IonItem>

                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    Emergency
                  </IonLabel>
                  <IonRadio slot="start" mode="md" value="Emergency" />
                </IonItem>
              </>
            ) : mode === "application" ? (
              <>
                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    Incorrect representative details
                  </IonLabel>
                  <IonRadio
                    slot="start"
                    mode="md"
                    value="Incorrect representative details"
                  />
                </IonItem>

                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    Incomplete hospital requirements
                  </IonLabel>
                  <IonRadio
                    slot="start"
                    mode="md"
                    value="Incomplete hospital requirements"
                  />
                </IonItem>
              </>
            ) : mode === "patient-appointment" ? (
              <>
                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    Emergency
                  </IonLabel>
                  <IonRadio slot="start" mode="md" value="Emergency" />
                </IonItem>

                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    Schedule conflict
                  </IonLabel>
                  <IonRadio slot="start" mode="md" value="Schedule conflict" />
                </IonItem>
              </>
            ) : mode === "support-group" ? (
              <></>
            ) : mode === "services" ? (
              <>
                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    No longer offered
                  </IonLabel>
                  <IonRadio slot="start" mode="md" value="No longer offered" />
                </IonItem>
                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    Wrong department
                  </IonLabel>
                  <IonRadio slot="start" mode="md" value="Wrong department" />
                </IonItem>
              </>
            ) : (
              <>
                <IonItem lines="none" color={MBCOLORS.tertiary}>
                  <IonLabel
                    className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                  >
                    Doctor no longer affiliated
                  </IonLabel>
                  <IonRadio
                    slot="start"
                    mode="md"
                    value="Doctor no longer affiliated"
                  />
                </IonItem>
              </>
            )}

            {mode !== "support-group" && (
              <IonItem lines="none" color={MBCOLORS.tertiary}>
                <IonLabel
                  className={`${isMobile() ? "mb-paragraph" : "mb-body"}`}
                >
                  Others
                </IonLabel>
                <IonRadio slot="start" mode="md" value="others" />
              </IonItem>
            )}
          </IonRadioGroup>
          {(selectedValue === "others" || mode === "support-group") && (
            <div className="cancel-reason-dialog-others-container">
              <MBInput
                value={otherReason}
                onChange={(otherReason) => {
                  setOtherReason(otherReason);
                }}
                type="text"
              ></MBInput>
            </div>
          )}
          {onApprove !== undefined && onDecline !== undefined && (
            <div className="cancel-reason-dialog-options-container">
              <IonButton
                onClick={onDecline}
                className="cancel-reason-dialog-no-button mb-body ion-text-capitalize"
                fill="clear"
              >
                Cancel
              </IonButton>
              <IonButton
                onClick={onSubmit}
                className="cancel-reason-dialog-submit-button mb-body bold white ion-text-capitalize"
                color={MBCOLORS.primary}
              >
                Submit
              </IonButton>
            </div>
          )}
        </div>
      </div>

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </IonModal>
  );
};
