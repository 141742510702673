import _ from "lodash";
import React from "react";
import { IonLoading, IonToast } from "@ionic/react";

import "./ExecutiveAssistantDoctorsList.scss";
import * as services from "../../services";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import { Hospital, HospitalDoctorListView } from "../../models";
import { MBCOLORS } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";
import { MBDoctorDetailCard } from "../../components/MBDoctorDetailCard/MBDoctorDetailCard";

interface DoctorProfile {
  details: HospitalDoctorListView;
  hospital: Hospital;
}
class ExecutiveAssistantDoctorsList extends React.Component<MBProps> {
  state = {
    doctorProfiles: [] as DoctorProfile[],
    unsubscribeDoctorProfiles: () => {},
    loading: true,
    error: "",
  };

  componentDidMount = () => {
    this.getDoctorProfiles();
  };

  getDoctorProfiles = async () => {
    try {
      this.setState({
        loading: true,
      });

      const unsubscribeDoctorProfiles = services.getExecutiveAssistantDoctorHospitals(
        this.props.authUser.uid,
        async (doctorHospitals, error) => {
          const doctorProfiles = await Promise.all(
            doctorHospitals.map((doctorHospital) => {
              return new Promise(async (resolve) => {
                const doctorDetail = await services.getDetailedDoctorWithHospital(
                  doctorHospital.hospitalId,
                  doctorHospital.doctorId
                );

                const hospital = await services.getHospital(
                  doctorHospital.hospitalId
                );

                if (!_.isEmpty(doctorDetail) && !_.isEmpty(hospital)) {
                  resolve({
                    details: doctorDetail,
                    hospital,
                  });
                } else {
                  resolve(null);
                }
              }) as Promise<DoctorProfile | null>;
            })
          );

          this.setState({
            doctorProfiles: _.compact(doctorProfiles),
            unsubscribeDoctorProfiles,
            loading: false,
          });
        }
      );
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  render = () => {
    const {
      unsubscribeDoctorProfiles,
      doctorProfiles,
      loading,
      error,
    } = this.state;

    return (
      <MBContainer
        {...this.props}
        activePage={"executive-assistant-doctor-list"}
      >
        {!loading &&
          doctorProfiles.map((doctorProfile) => {
            return (
              <div className="ion-margin-bottom ion-margin-top">
                <MBDoctorDetailCard
                  {...this.props}
                  executiveAssistantMode={true}
                  details={doctorProfile.details as HospitalDoctorListView}
                  hospital={doctorProfile.hospital}
                  onEditCallback={() => {
                    unsubscribeDoctorProfiles();
                    this.getDoctorProfiles();
                  }}
                />
              </div>
            );
          })}
        <IonLoading
          translucent={true}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />
        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => this.setState({ error: "" })}
          color={MBCOLORS.danger}
        />
      </MBContainer>
    );
  };
}

export default ExecutiveAssistantDoctorsList;
