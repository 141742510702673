import {
  ellipsisHorizontal,
  pencilOutline,
  archiveOutline,
} from "ionicons/icons";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import {
  IonCard,
  IonLabel,
  IonCardContent,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonPopover,
  IonList,
  IonItem,
} from "@ionic/react";

import "./MBDetailCard.scss";
import { Hospital } from "../../models";
import { HospitalServiceSchedule, ResourceType } from "../../interface";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBCOLORS, DAYS_OF_WEEK } from "../../constants/config";

interface MBDetailCardPropsInterface {
  details: HospitalServiceSchedule;
  hospital: Hospital;
  title?: string;
  titleDescription?: string;
  subtitle?: string;
  onReject?: () => void;
  hideActionButton?: boolean;
  noHeader?: boolean;
  readonly?: boolean;
  mode?: "ambulatory" | "service" | "doctor";
  onArchived: (docId: string, appointmentType: ResourceType) => void;
  onEditService: (data: any) => void;
}
interface SelectScheduleDays {
  dayOfWeek: number;
  name: string;
  slots: number | null;
  isDayChecked: boolean;
  docId: string;
  time: string; //firebase.firestore.Timestamp;
}
export const MBDetailCard = (props: MBDetailCardPropsInterface) => {
  const { details, hospital, mode, onArchived, onEditService } = props;
  const scheduleDays = Object.values(DAYS_OF_WEEK).map((day) => {
    return {
      dayOfWeek: day.day,
      name: day.name,
      time: !_.isEmpty(
        _.find(details.schedules, (schedule) => schedule.dayOfWeek === day.day)
      )
        ? moment(
            _.find(
              details.schedules,
              (schedule) => schedule.dayOfWeek === day.day
            )!.startTime.toDate()
          ).format("hh:mm A")
        : "",
      slots: !_.isEmpty(
        _.find(details.schedules, (schedule) => schedule.dayOfWeek === day.day)
      )
        ? _.find(
            details.schedules,
            (schedule) => schedule.dayOfWeek === day.day
          )!.slots
        : 0,
      isDayChecked: !_.isEmpty(
        _.find(details.schedules, (schedule) => schedule.dayOfWeek === day.day)
      )
        ? true
        : false,
      docId: !_.isEmpty(
        _.find(details.schedules, (schedule) => schedule.dayOfWeek === day.day)
      )
        ? _.find(
            details.schedules,
            (schedule) => schedule.dayOfWeek === day.day
          )!.docId
        : "",
    };
  });
  const isAvailableToday = !_.isEmpty(
    _.find(
      details.schedules,
      (schedule) => schedule.dayOfWeek === new Date().getDay()
    )
  );

  const isArchived = !_.isEmpty(details.archiveDate);

  const [selectedScheduleDays] = useState(scheduleDays as SelectScheduleDays[]);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [actionButtonsEvent, setActionButtonsEvent] = useState(
    undefined
  ) as any;

  return (
    <>
      <IonCard
        className={`mb-detail-card ion-no-margin ${mode}`}
        color={MBCOLORS.tertiary}
      >
        <IonCardContent className="mb-detail-card-content ion-no-padding">
          <div className="mb-detail-card-content-details-container">
            <IonGrid className="mb-detail-card-grid-detail ion-no-padding ion-no-margin">
              <IonRow className="mb-detail-card-row ion-no-padding ion-no-margin">
                <IonCol
                  size="5"
                  className="mb-detail-card-col-icons ion-no-padding ion-no-margin"
                >
                  <IonIcon
                    className={`mb-detail-card-details-icon ${
                      details.isAmbulatory ? "ambulatory" : "service"
                    }`}
                  ></IonIcon>
                  <div
                    className={`mb-detail-card-status-container ${
                      isArchived
                        ? "achived"
                        : isAvailableToday
                        ? ""
                        : "not-available"
                    }`}
                  >
                    <IonLabel
                      className={`mb-detail-card-status mb-body ${
                        isArchived
                          ? "white"
                          : isAvailableToday
                          ? "white"
                          : "light"
                      } bold`}
                    >
                      {isArchived
                        ? "Archived"
                        : isAvailableToday
                        ? "Available Today"
                        : "Not Available Today"}
                    </IonLabel>
                  </div>
                </IonCol>
                <IonCol
                  size="6"
                  className="mb-detail-card-col-details ion-no-padding ion-no-margin"
                >
                  <div className="mb-detail-card-details-container">
                    <IonLabel className="mb-h1 dark-blue ion-no-padding ion-no-margin">
                      {details.treatmentName}
                    </IonLabel>
                    <div className="mb-detail-card-sub-detail-container">
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon hospital" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          {hospital.hospitalName}
                        </IonLabel>
                      </div>
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon mobile" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          {details.contactNumber}
                        </IonLabel>
                      </div>
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon mobile" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          {details.secondaryContactNumber}
                        </IonLabel>
                      </div>
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon location" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          {details.location}
                        </IonLabel>
                      </div>
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon fee" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          <b>Php {details.fee}</b> - Service Fee
                        </IonLabel>
                      </div>
                    </div>
                  </div>
                </IonCol>
                <IonCol
                  size="1"
                  className="mb-detail-card-col-button ion-no-padding ion-no-margin"
                >
                  {details.archiveDate === undefined && (
                    <IonButton
                      className="mb-detail-card-action-button"
                      color={MBCOLORS.medium}
                      onClick={(e) => {
                        setActionButtonsEvent(e.nativeEvent);
                        setShowActionButtons(true);
                      }}
                    >
                      <IonIcon icon={ellipsisHorizontal} />
                    </IonButton>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
            <MBDivider />
            <IonGrid className="mb-detail-card-grid-schedule ion-no-padding ion-no-margin">
              <IonRow className="mb-detail-card-row-schedule-header ion-no-padding ion-no-margin">
                <IonCol size="4">
                  <IonLabel className="mb-h4 bold ion-no-padding ion-no-margin">
                    Schedule
                  </IonLabel>
                </IonCol>
                <IonCol size="4">
                  <IonLabel className="mb-h4 normal ion-no-padding ion-no-margin">
                    Start Time
                  </IonLabel>
                </IonCol>
                <IonCol size="1.5">
                  <IonLabel className="mb-h4 normal ion-no-padding ion-no-margin">
                    Slots
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="mb-detail-card-row-schedule-data ion-no-padding ion-no-margin">
                <IonCol className="mb-detail-card-col-schedule-day" size="4">
                  {selectedScheduleDays.map((scheduleDay) => {
                    return (
                      <div
                        className={`mb-detail-card-schedule-day-container ${
                          scheduleDay.slots !== 0 ? "" : "not-available"
                        } ion-no-padding ion-no-margin`}
                      >
                        <IonLabel
                          className={`mb-detail-card-schedule-label mb-h2 ${
                            scheduleDay.slots !== 0 ? "primary" : "light"
                          } normal ion-no-padding ion-no-margin`}
                        >
                          {scheduleDay.name}
                        </IonLabel>
                      </div>
                    );
                  })}
                </IonCol>
                <IonCol
                  size="4"
                  className="mb-detail-card-col-schedule-time-slot ion-no-padding ion-no-margin"
                >
                  {selectedScheduleDays.map((scheduleDay) => {
                    return (
                      <IonLabel
                        className={`mb-detail-card-schedule-time-slot-label mb-h2 ${
                          !_.isEmpty(scheduleDay.time) &&
                          scheduleDay.slots !== 0
                            ? ""
                            : "light"
                        } normal`}
                      >
                        {!_.isEmpty(scheduleDay.time)
                          ? scheduleDay.time
                          : "0:00 AM"}
                      </IonLabel>
                    );
                  })}
                </IonCol>
                <IonCol
                  size="1.5"
                  className="mb-detail-card-col-schedule-time-slot ion-no-padding ion-no-margin"
                >
                  {selectedScheduleDays.map((scheduleDay) => {
                    return (
                      <IonLabel
                        className={`mb-detail-card-schedule-time-slot-label mb-h2 ${
                          !_.isEmpty(scheduleDay.time) &&
                          scheduleDay.slots !== 0
                            ? ""
                            : "light"
                        } normal`}
                      >
                        {scheduleDay.slots}
                      </IonLabel>
                    );
                  })}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonCardContent>
      </IonCard>

      <IonPopover
        mode="md"
        isOpen={showActionButtons}
        event={actionButtonsEvent}
        cssClass="mb-detail-card-popover noselect ion-no-padding"
        showBackdrop={false}
        onDidDismiss={() => {
          setShowActionButtons(false);
        }}
      >
        <div className="mb-detail-card-list-container">
          <IonList className="mb-detail-card-list">
            <div className="mb-detail-card-item-container">
              <IonItem
                className="mb-detail-card-dropdown-modal-item ion-justify-content-center"
                button={true}
                lines="none"
                onClick={() => {
                  onEditService(details);
                }}
              >
                <IonLabel className="mb-detail-card-dropdown-label mb-h5">
                  <IonIcon
                    className="mb-detail-card-dropdown-modal-item-icon"
                    icon={pencilOutline}
                    slot="start"
                  />
                  Edit
                </IonLabel>
              </IonItem>

              <IonItem
                className="mb-detail-card-dropdown-modal-item ion-justify-content-center"
                button={true}
                lines="none"
                onClick={() => {
                  onArchived(details.docId as string, details.forResource);
                }}
              >
                <IonLabel className="mb-detail-card-dropdown-label mb-h5">
                  <IonIcon
                    className="mb-detail-card-dropdown-modal-item-icon"
                    icon={archiveOutline}
                    slot="start"
                  />
                  Archive
                </IonLabel>
              </IonItem>
            </div>
          </IonList>
        </div>
      </IonPopover>
    </>
  );
};
