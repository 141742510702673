import * as _ from "lodash";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonRadioGroup,
  IonGrid,
  IonRow,
  IonCol,
  IonRadio,
  IonItem,
  IonIcon,
  IonCheckbox,
  IonButton,
  IonToast,
  IonLoading,
} from "@ionic/react";

import "./Checkout.scss";
import * as email from "../../functions/email";
import * as sms from "../../functions/sms";
import * as api from "../../api";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import {
  getCurrentLocation,
  formatString,
  toDateTimeFromSecs,
  isMobile,
} from "../../functions/common";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBDateInput } from "../../components/MBDateInput/MBDateInput";
import { MBCheckoutSuccessDialog } from "../../components/MBCheckoutSuccessDialog/MBCheckoutSuccessDialog";
import { MBInput } from "../../components/MBInput/MBInput";
import { PaymongoIntent } from "../../api";
import { MBProps, PaymentMethod, DaysOfWeek } from "../../interface";
import {
  BookListItem,
  ServiceResource,
  DoctorResource,
  HospitalDoctorAppointments,
  HospitalServiceAppointments,
  Patient,
} from "../../models";
import { MBCOLORS, PAYMENT_TYPES } from "../../constants/config";
import { MSGS_COMMON, MSGS_SIGNUP } from "../../constants/messages";
import { giftSharp } from "ionicons/icons";

class Checkout extends React.Component<MBProps> {
  state = {
    selectedPaymentMethod: 1 as PaymentMethod,

    creditDebitCardNumber: "",
    creditDebitCardName: "",
    creditDebitExpiry: null as Date | null,
    creditDebitCVV: "",

    gcashName: "",
    gcashNumber: "",

    errorMsgCreditDebitCardNumber: "",
    errorMsgCreditDebitCardName: "",
    errorMsgCreditDebitExpiry: "",
    errorMsgCreditDebitCVV: "",
    errorMsgGcashName: "",
    errorMsgGcashNumber: "",

    bookList: [] as BookListItem[],
    agreedToTNC: false,

    singleAppointmentBooked: "",
    successDialogIsOpen: false,
    loading: true,
    error: "",
  };

  componentDidMount = () => {
    const { authUser } = this.props;
    this.getBooklist(authUser.uid);
  };

  getBooklist = async (patientId: string) => {
    services.getBookList(patientId, (bookList, error) => {
      if (_.isEmpty(error)) {
        console.log("GOT BOOKING LIST", bookList, this.props.location);
        this.setState({
          bookList: _.filter(bookList, (booking) => !!booking.toCheckout),
          loading: false,
        });
        setTimeout(() => {
          if (
            this.props.location.pathname === routes.PATIENT_CHECKOUT &&
            bookList.length === 0 &&
            !this.state.successDialogIsOpen
          ) {
            this.props.history.push(routes.PATIENT_HOME);
          }
        }, 3000);
      } else {
        this.setState({
          loading: false,
          error,
        });
      }
    });
  };

  confirmCheckout = async () => {
    const { authUser } = this.props;

    const yearsOld = moment(new Date()).diff(
      moment((authUser.userDetails as Patient).birthday.toDate()),
      "years"
    );
    console.log("YEARS OLD", yearsOld);
    if (yearsOld < 18) {
      this.setState({
        error: "Only 18 Years old above can book for an appointment.",
      });
    } else {
      try {
        const {
          agreedToTNC,
          bookList,
          selectedPaymentMethod,
          gcashName,
          gcashNumber,

          creditDebitCardNumber,
          creditDebitCardName,
          creditDebitExpiry,
          creditDebitCVV,
        } = this.state;
        let errorMsg = "";
        const formattedGcashName = formatString(gcashName);
        const formattedGcashNumber = formatString(gcashNumber);
        const formattedCreditDebitCardNumber = formatString(
          creditDebitCardNumber
        );
        const formattedCreditDebitCardName = formatString(creditDebitCardName);
        const formattedCreditDebitCVV = formatString(creditDebitCVV);

        if (selectedPaymentMethod === PAYMENT_TYPES.gCash.id) {
          if (_.isEmpty(formattedGcashName)) {
            errorMsg = MSGS_COMMON.requiredField;
            this.setState({
              errorMsgGcashName: errorMsg,
            });
          }
          if (_.isEmpty(formattedGcashNumber)) {
            errorMsg = MSGS_COMMON.requiredField;
            this.setState({
              errorMsgGcashNumber: errorMsg,
            });
          } else if (!validatePhoneNumber(formattedGcashNumber)) {
            errorMsg = MSGS_SIGNUP.invalidMobileNumber;
            this.setState({
              errorMsgGcashNumber: errorMsg,
            });
          }
        } else if (selectedPaymentMethod === PAYMENT_TYPES.card.id) {
          if (_.isEmpty(formattedCreditDebitCardName)) {
            errorMsg = MSGS_COMMON.requiredField;
            this.setState({
              errorMsgCreditDebitCardName: errorMsg,
            });
          }
          if (_.isEmpty(formattedCreditDebitCardNumber)) {
            errorMsg = MSGS_COMMON.requiredField;
            this.setState({
              errorMsgCreditDebitCardNumber: errorMsg,
            });
          }
          if (!creditDebitExpiry) {
            errorMsg = MSGS_COMMON.requiredField;
            this.setState({
              errorMsgCreditDebitExpiry: errorMsg,
            });
          }
          if (_.isEmpty(formattedCreditDebitCVV)) {
            errorMsg = MSGS_COMMON.requiredField;
            this.setState({
              errorMsgCreditDebitCVV: errorMsg,
            });
          }
        }

        if (!agreedToTNC) {
          errorMsg =
            "Approving of Terms and Condition is required to book an appointment.";
          this.setState({
            error: errorMsg,
          });
        }

        if (_.isEmpty(errorMsg)) {
          this.setState({
            loading: true,
          });

          //Recheck if booking is still available
          const toSetAsUnavailableBooking = _.compact(
            await Promise.all(
              bookList.map((booking) => {
                return new Promise(async (resolve) => {
                  const serviceResource = booking.resource as ServiceResource;
                  const doctorResources = booking.resource as DoctorResource;
                  const isDoctorResource = booking.isDoctorResource;

                  const availableSlots = isDoctorResource
                    ? await services.getAvailableDoctorSchedule(
                        booking.appointment.appointmentDate
                          .toDate()
                          .getDay() as DaysOfWeek,
                        doctorResources.docId || "",
                        doctorResources.hospital.docId || "",
                        booking.appointment.appointmentDate.toDate(),
                        booking.appointment.slotNumber
                      )
                    : await services.getAvailableServiceSchedule(
                        booking.appointment.appointmentDate
                          .toDate()
                          .getDay() as DaysOfWeek,
                        serviceResource.docId || "",
                        booking.appointment.appointmentDate.toDate(),
                        booking.appointment.slotNumber
                      );
                  if (
                    (isDoctorResource && _.isNull(availableSlots![0])) ||
                    (!isDoctorResource && _.isNull(availableSlots))
                  ) {
                    resolve(booking.docId);
                  } else {
                    resolve(null);
                  }
                }) as Promise<string | null>;
              })
            )
          );

          console.log("TO SET?? ", toSetAsUnavailableBooking);
          if (!_.isEmpty(toSetAsUnavailableBooking)) {
            // DO SOMETHING ABOUT THIS
            await Promise.all(
              toSetAsUnavailableBooking.map((bookListId) => {
                return services.setBookListAsUnavailable(bookListId);
              })
            );

            const bookListButton = document.getElementById(
              "mb-header-book-list-button"
            );

            if (!_.isNull(bookListButton)) {
              bookListButton.click();
            }

            this.setState({
              loading: false,
              error: "Booking/s became unavailable",
            });
          } else {
            const amount = _.sumBy(bookList, "appointment.bookingFee");
            if (selectedPaymentMethod === PAYMENT_TYPES.gCash.id) {
              console.log("SHOULD PAY THRU GCASH");
              const gcashPayment = await services.createGCashPayment();

              const gcashSource = await api.createGCashSource(
                amount,
                gcashNumber,
                gcashName,
                this.props.authUser.authEmail,
                gcashPayment.id
              );
              console.log("GOT GCASH SOURCE result", gcashSource);
              const sourceId = gcashSource.data.id;

              await services.updateGCashPaymentSourceId(
                gcashPayment.id,
                sourceId
              );

              console.log(
                "WILL NOW WATCH PAYMENT",
                gcashSource.data.attributes.redirect.checkout_url
              );
              window.open(
                gcashSource.data.attributes.redirect.checkout_url,
                "_blank"
              );
              let unsubscribeQuery = () => {};

              //watch for payment
              let paymentTimeout: any;
              unsubscribeQuery = services.watchForGCashPayment(
                sourceId,
                async (isChargeable, id) => {
                  if (!_.isNull(isChargeable) && isChargeable !== undefined) {
                    clearTimeout(paymentTimeout);
                    if (isChargeable) {
                      const paymentResult = await api.paymongoCreatePayment(
                        amount,
                        sourceId,
                        `Booking fee for appointment ${bookList
                          .map((booking) => booking.docId)
                          .join(", ")}`,
                        `Booking fee for appointment ${bookList
                          .map((booking) => booking.docId)
                          .join(", ")}`
                      );

                      console.log(
                        "paymentResult ---",
                        paymentResult,
                        paymentResult.data.attributes.status,
                        "should update??: ",
                        id
                      );
                      if (paymentResult.data.attributes.status === "paid") {
                        unsubscribeQuery();
                        await services.updateGCashPayment(
                          id,
                          paymentResult.data
                        );
                        await this.proceedBooking(gcashPayment.id);
                      } else {
                        this.setState({
                          error:
                            "Something went wrong with the payment. Please contact the admin.",
                          loading: false,
                        });
                      }
                    } else {
                      this.setState({
                        error: "Payment failed. Please try again.",
                        loading: false,
                      });
                      unsubscribeQuery();
                    }
                  }
                }
              );

              paymentTimeout = setTimeout(() => {
                this.setState({
                  error: "Payment timeout. Please try again.",
                  loading: false,
                });
                unsubscribeQuery();
              }, 1000 * 60 * 30); //30 mins
            } else if (selectedPaymentMethod === PAYMENT_TYPES.card.id) {
              const expMonth = creditDebitExpiry!.getMonth();
              const expYear = parseInt(
                creditDebitExpiry!.getFullYear().toString().slice(2)
              );
              try {
                const paymentIntent = await api.paymongoCreateIntent(amount);
                const paymentMethod = await api.paymongoCreatePaymentMethod(
                  formattedCreditDebitCardNumber,
                  expMonth,
                  expYear,
                  formattedCreditDebitCVV,
                  formattedCreditDebitCardName,
                  this.props.authUser.authEmail
                );
                const attachPaymentMethodToIntentResult = await api.paymongoAttachPaymentMethodToIntent(
                  paymentIntent.data.id,
                  paymentMethod.data.id
                );
                console.log("FIRST TEST WITH CREDIT CARD", {
                  paymentIntent,
                  paymentMethod,
                  attachPaymentMethodToIntentResult,
                });

                var paymentIntentStatus =
                  attachPaymentMethodToIntentResult.data.attributes.status;

                let hasRedirected = false;
                const processStatus = async (
                  status: string,
                  requery?: boolean
                ) => {
                  let paymentstatus = status;
                  let newPaymentIntent = {} as PaymongoIntent;
                  if (requery) {
                    newPaymentIntent = await api.paymongoRetrieveIntent(
                      paymentIntent.data.id
                    );
                    paymentstatus = newPaymentIntent.data.attributes.status;
                    console.log("STATUS REQUERY", newPaymentIntent);
                  }

                  if (paymentstatus === "succeeded") {
                    console.log("payment succeeded!");
                    const creditDebitPayment = await services.addCreditDebitPayment(
                      paymentIntentStatus,
                      attachPaymentMethodToIntentResult.data.attributes.payments
                    );
                    this.proceedBooking(creditDebitPayment.id);

                    // You already received your customer's payment. You can show a success message from this condition.
                  } else if (paymentstatus === "awaiting_payment_method") {
                    console.log("damn error!", paymentIntent);
                    if (!_.isEmpty(newPaymentIntent)) {
                      this.setState({
                        error:
                          newPaymentIntent.data.attributes.last_payment_error
                            .failed_message,
                        loading: false,
                      });
                    }
                    // The PaymentIntent encountered a processing error. You can refer to paymentIntent.attributes.last_payment_error to check the error and render the appropriate error message.
                  } else if (paymentstatus === "processing") {
                    console.log("processing???");
                    setTimeout(() => {
                      processStatus(status, true);
                    }, 3000);

                    // You need to true the PaymentIntent after a second or two. This is a transitory status and should resolve to `succeeded` or `awaiting_payment_method` quickly.
                  } else if (paymentstatus === "awaiting_next_action") {
                    setTimeout(() => {
                      processStatus(status, true);
                    }, 3000);
                    if (
                      (!hasRedirected || requery) &&
                      !_.isEmpty(
                        attachPaymentMethodToIntentResult.data.attributes
                          .next_action
                      )
                    ) {
                      window.open(
                        attachPaymentMethodToIntentResult.data.attributes
                          .next_action.redirect.url,
                        "_blank"
                      );
                    }
                  }
                };

                await processStatus(paymentIntentStatus);
              } catch (e) {
                this.setState({
                  error: e.message,
                  loading: false,
                });
              }
            } else {
              this.proceedBooking();
            }
          }
        }
      } catch (error) {
        this.setState({
          loading: false,
          error,
        });
      }
    }
  };

  proceedBooking = async (paymentReferenceId?: string) => {
    try {
      this.setState({
        loading: true,
      });
      const { bookList, selectedPaymentMethod } = this.state;
      const { authUser } = this.props;
      const userLocation = await getCurrentLocation();

      const bookingResults = await Promise.all(
        bookList.map((booking) => {
          return new Promise(async (resolve) => {
            const doctorBooking = booking.appointment as HospitalDoctorAppointments;
            const serviceBooking = booking.appointment as HospitalServiceAppointments;
            const isDoctorResource = booking.isDoctorResource;
            let result = "";
            console.log("WILL WORK WITH THIS BOOKING", booking.appointment);
            if (isDoctorResource) {
              result = await services.bookDoctorAppointment(
                doctorBooking.doctorScheduleId,
                doctorBooking.hospitalId,
                doctorBooking.doctorId,
                doctorBooking.patientId,
                !_.isEmpty(doctorBooking.bookingFor)
                  ? doctorBooking.bookingFor
                  : "",
                doctorBooking.slotNumber,
                doctorBooking.bookingFee,
                doctorBooking.resourceFee,
                this.state.selectedPaymentMethod,
                doctorBooking.condition,
                doctorBooking.appointmentDate.toDate(),
                userLocation
              );
            } else {
              result = await services.bookServiceAppointment(
                serviceBooking.serviceScheduleId,
                serviceBooking.hospitalId,
                serviceBooking.service,
                serviceBooking.patientId,
                !_.isEmpty(serviceBooking.bookingFor)
                  ? serviceBooking.bookingFor
                  : "",
                serviceBooking.slotNumber,
                serviceBooking.bookingFee,
                serviceBooking.resourceFee,
                this.state.selectedPaymentMethod,
                serviceBooking.appointmentDate.toDate(),
                userLocation
              );
            }

            resolve([isDoctorResource, result]);
          }) as Promise<[boolean, string]>;
        })
      );

      console.log("GOT BOOKING RESULTS", bookingResults);

      await Promise.all(
        bookList.map((booking) => {
          return new Promise(async (resolve) => {
            const doctorBooking = booking.appointment as HospitalDoctorAppointments;
            const serviceBooking = booking.appointment as HospitalServiceAppointments;
            const isDoctorResource = booking.isDoctorResource;
            try {
              const hospital = await services.getHospital(
                booking.appointment.hospitalId
              );
              if (isDoctorResource) {
                const doctorHospital = await services.getDetailedDoctorWithHospital(
                  doctorBooking.hospitalId,
                  doctorBooking.doctorId
                );
                const emailMessageDoctor = email.patientSetDoctorAppointment(
                  hospital.hospitalName,
                  `${doctorHospital.firstName} ${doctorHospital.lastName}`,
                  !!doctorHospital.executiveAssistant &&
                    !_.isEmpty(doctorHospital.executiveAssistant)
                    ? `${doctorHospital.executiveAssistant.firstName} ${doctorHospital.executiveAssistant.lastName}`
                    : "",
                  `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                  moment(
                    toDateTimeFromSecs(
                      (booking.appointment.appointmentDate as any).seconds
                    )
                  ).format("LL")
                );
                await services.sendEmail(
                  hospital.emailAddress,
                  emailMessageDoctor.subject,
                  emailMessageDoctor.message
                );
                await services.sendEmail(
                  doctorHospital.emailAddress,
                  emailMessageDoctor.subject,
                  emailMessageDoctor.message
                );
                if (
                  !!doctorHospital.executiveAssistant &&
                  !_.isEmpty(doctorHospital.executiveAssistant)
                ) {
                  const sendSMS = sms.patientSetDoctorAppointment(
                    !!doctorHospital.executiveAssistant &&
                      !_.isEmpty(doctorHospital.executiveAssistant)
                      ? `${doctorHospital.executiveAssistant.firstName} ${doctorHospital.executiveAssistant.lastName}`
                      : "",
                    `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                    moment(
                      toDateTimeFromSecs(
                        (booking.appointment.appointmentDate as any).seconds
                      )
                    ).format("LL")
                  );

                  await services.sendEmail(
                    doctorHospital.executiveAssistant.emailAddress,
                    emailMessageDoctor.subject,
                    emailMessageDoctor.message
                  );
                  try {
                    await api.sendSMS(
                      doctorHospital.executiveAssistant.phoneNumber,
                      sendSMS
                    );
                  } catch (e) {
                    console.log("ERROR IN SENDING SMS FOR CHECKOUT: ", e);
                  }
                }
              } else {
                const emailMessageHospital = email.patientSetServiceAppointment(
                  hospital.hospitalName,
                  `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                  moment(
                    toDateTimeFromSecs(
                      (booking.appointment.appointmentDate as any).seconds
                    )
                  ).format("LL")
                );
                await services.sendEmail(
                  hospital.emailAddress,
                  emailMessageHospital.subject,
                  emailMessageHospital.message
                );
                const patient = authUser.userDetails as Patient;
                if (!_.isNull(patient.preferredDoctor)) {
                  //check if surgeon
                  const doctorHospitals = await services.getDoctorHospitals(
                    patient.preferredDoctor!
                  );
                  const specialties = _.flatten(
                    doctorHospitals.map(
                      (doctorHospital) => doctorHospital.specialities
                    )
                  );
                  // if yes, notify
                  if (
                    _.isEmpty(
                      _.find(
                        specialties,
                        (specialty) =>
                          specialty.name.trim().toLowerCase() === "surgeon"
                      )
                    )
                  ) {
                    const doctor = await services.getDoctor(
                      patient.preferredDoctor!
                    );
                    const {
                      subject,
                      message,
                    } = email.notifyPreferredDoctorPatientScheduleServices(
                      `${hospital.firstName} ${hospital.lastName}`,
                      `${doctor.firstName} ${doctor.lastName}`,
                      hospital.department,
                      (booking.resource as ServiceResource).treatmentName,
                      `${patient.firstName} ${patient.lastName}`,
                      hospital.phoneNumber,
                      moment(serviceBooking.appointmentDate.toDate()).format(
                        "LL"
                      )
                    );

                    await services.sendEmail(
                      doctor.emailAddress,
                      subject,
                      message
                    );
                  }
                }
              }
              resolve();
            } catch (error) {
              console.log("error -- sendingNotif Appointment -- ", error);
            }
          });
        })
      );
      console.log("will attach payments now");

      // attach appointment to payments
      if (
        !!paymentReferenceId &&
        selectedPaymentMethod === PAYMENT_TYPES.gCash.id
      ) {
        await services.addAppointmentDetailsToGcashPayment(
          paymentReferenceId,
          bookingResults.map((bookingResult) => {
            return {
              appointmentId: bookingResult[1],
              isConsultation: bookingResult[0],
            };
          })
        );
      } else if (
        !!paymentReferenceId &&
        selectedPaymentMethod === PAYMENT_TYPES.card.id
      ) {
        await services.addAppointmentDetailsToCreditDebitPayment(
          paymentReferenceId,
          bookingResults.map((bookingResult) => {
            return {
              appointmentId: bookingResult[1],
              isConsultation: bookingResult[0],
            };
          })
        );
      } else {
        const bookingCredit =
          (this.props.authUser.userDetails as Patient).bookingCredit - 1;
        // charged to booking credit
        await services.updatePatient(this.props.authUser.uid, {
          bookingCredit,
        });
      }

      console.log(
        "DO SOMETHING ABOUT THIS",
        bookingResults,
        bookingResults.length === 1
        // bookingResults[1][1]
      );

      // delete booklist items that checkout
      await Promise.all(
        bookList.map((booking) => {
          return services.deleteBookList(booking.docId || "");
        })
      );

      this.setState({
        ...(bookingResults.length === 1
          ? {
              singleAppointmentBooked: bookingResults[0][1],
            }
          : {}),
        successDialogIsOpen: true,
        loading: false,
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  };

  render = () => {
    const {
      selectedPaymentMethod,

      creditDebitCardNumber,
      creditDebitCardName,
      creditDebitExpiry,
      creditDebitCVV,

      gcashName,
      gcashNumber,

      errorMsgCreditDebitCardNumber,
      errorMsgCreditDebitCardName,
      errorMsgCreditDebitExpiry,
      errorMsgCreditDebitCVV,
      errorMsgGcashName,
      errorMsgGcashNumber,

      bookList,
      agreedToTNC,
      successDialogIsOpen,
      singleAppointmentBooked,
      loading,
      error,
    } = this.state;
    const { authUser } = this.props;
    return (
      <>
        <Helmet>
          <title>MedBook - Patient - Checkout</title>
        </Helmet>

        <MBContainer {...this.props} activePage="patient-home">
          <div className="checkout-container">
            <IonCard className={`checkout-card ${isMobile() && "mobile"}`}>
              <IonCardHeader className="checkout-card-header">
                <IonLabel className="mb-h4 bold">
                  Select Payment Method
                </IonLabel>
              </IonCardHeader>
              <IonCardContent
                className={`checkout-card-content ${isMobile() && "mobile"}`}
              >
                <IonRadioGroup
                  className="checkout-card-payment-methods-radio-group"
                  onIonChange={(e) => {
                    this.setState({
                      selectedPaymentMethod: e.detail.value,
                    });
                  }}
                  value={selectedPaymentMethod}
                >
                  <IonGrid className="checkout-card-payment-methods ion-no-padding">
                    <IonRow>
                      <IonCol
                        className="checkout-card-payment-method-col ion-no-padding ion-no-margin"
                        size={isMobile() ? "12" : "6"}
                      >
                        <IonItem
                          lines="none"
                          color={MBCOLORS.tertiary}
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonRadio
                            mode="md"
                            // disabled={disabled}
                            className="checkout-card-payment-method-radio-button ion-no-margin"
                            slot="start"
                            value={PAYMENT_TYPES.gCash.id}
                          />
                          <div
                            className={`checkout-card-payment-method-image ${
                              isMobile() && "mobile"
                            }`}
                          >
                            <IonIcon className="checkout-card-payment-method-image-gcash" />
                            <IonLabel className="mb-body medium-dark">
                              {PAYMENT_TYPES.gCash.name}
                            </IonLabel>
                          </div>
                        </IonItem>
                      </IonCol>
                      <IonCol
                        className="checkout-card-payment-method-col ion-no-padding ion-no-margin"
                        size={isMobile() ? "12" : "6"}
                      >
                        <IonItem
                          lines="none"
                          color={MBCOLORS.tertiary}
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonRadio
                            mode="md"
                            // disabled={disabled}
                            className="checkout-card-payment-method-radio-button ion-no-margin"
                            slot="start"
                            value={PAYMENT_TYPES.card.id}
                          />
                          <div
                            className={`checkout-card-payment-method-image ${
                              isMobile() && "mobile"
                            }`}
                          >
                            <IonIcon className="checkout-card-payment-method-image-credit-debit-card" />
                            <IonLabel className="mb-body medium-dark">
                              {PAYMENT_TYPES.card.name}
                            </IonLabel>
                          </div>
                        </IonItem>
                      </IonCol>
                      {(authUser.userDetails as Patient).bookingCredit !==
                        0 && (
                        <IonCol
                          className="checkout-card-payment-method-col ion-no-padding ion-no-margin"
                          size={isMobile() ? "12" : "6"}
                        >
                          <IonItem
                            lines="none"
                            color={MBCOLORS.tertiary}
                            className="ion-no-padding ion-no-margin"
                          >
                            <IonRadio
                              mode="md"
                              // disabled={disabled}
                              className="checkout-card-payment-method-radio-button ion-no-margin"
                              slot="start"
                              value={PAYMENT_TYPES.bookingCredit.id}
                            />
                            <div
                              className={`checkout-card-payment-method-image ${
                                isMobile() && "mobile"
                              }`}
                            >
                              <IonIcon
                                className="checkout-card-payment-method-image-booking-credit"
                                icon={giftSharp}
                                color={MBCOLORS.primary}
                              />
                              <IonLabel className="mb-body medium-dark">
                                {PAYMENT_TYPES.bookingCredit.name}
                              </IonLabel>
                            </div>
                          </IonItem>
                        </IonCol>
                      )}
                    </IonRow>
                  </IonGrid>
                </IonRadioGroup>

                {selectedPaymentMethod === PAYMENT_TYPES.card.id ? (
                  <>
                    <div
                      className={`checkout-card-payment-method-credit-debit-label ${
                        isMobile() && "mobile"
                      }`}
                    >
                      <IonLabel className="mb-h4">
                        Payment Via Credit/ Debit Card
                      </IonLabel>
                      <IonIcon className="checkout-card-payment-method-cards-collection" />
                    </div>
                    <IonGrid className="checkout-card-payment-method-credit-debit-grid ion-no-padding ion-no-margin">
                      <IonRow>
                        <IonCol
                          size="12"
                          className="checkout-card-payment-method-credit-debit-col ion-no-padding ion-padding-bottom ion-no-margin"
                        >
                          <MBInput
                            label="Card Number"
                            placeholder="0000-0000-0000-0000"
                            value={creditDebitCardNumber}
                            onChange={(creditDebitCardNumber) => {
                              this.setState({
                                creditDebitCardNumber,
                                errorMsgCreditDebitCardNumber: "",
                              });
                            }}
                            error={errorMsgCreditDebitCardNumber}
                            type="number"
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          size={isMobile() ? "12" : "6"}
                          className="checkout-card-payment-method-credit-debit-col ion-no-padding ion-padding-end ion-no-margin"
                        >
                          <MBInput
                            label="Name Of Card"
                            placeholder="Juan Dela Cruz"
                            value={creditDebitCardName}
                            onChange={(creditDebitCardName) => {
                              this.setState({
                                creditDebitCardName,
                                errorMsgCreditDebitCardName: "",
                              });
                            }}
                            error={errorMsgCreditDebitCardName}
                            type="text"
                          />
                        </IonCol>
                        <IonCol
                          size={isMobile() ? "6" : "3"}
                          className="checkout-card-payment-method-credit-debit-col ion-no-padding ion-no-margin"
                        >
                          <MBDateInput
                            className="checkout-card-payment-method-credit-debit-detail-date-input"
                            title="Expiry Date"
                            value={creditDebitExpiry}
                            placeholder="MM/YY"
                            displayFormat="MM/YY"
                            onChange={(creditDebitExpiry) => {
                              this.setState({
                                creditDebitExpiry,
                              });
                            }}
                            hideIcon={true}
                            error={errorMsgCreditDebitExpiry}
                          />
                        </IonCol>
                        <IonCol
                          size={isMobile() ? "6" : "3"}
                          className="checkout-card-payment-method-credit-debit-col ion-no-padding ion-padding-start ion-no-margin"
                        >
                          <MBInput
                            label="CVV"
                            placeholder="123"
                            value={creditDebitCVV}
                            onChange={(creditDebitCVV) => {
                              this.setState({
                                creditDebitCVV,
                                errorMsgCreditDebitCVV: "",
                              });
                            }}
                            error={errorMsgCreditDebitCVV}
                            type="password"
                            removePasswordVisibility={true}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </>
                ) : selectedPaymentMethod === PAYMENT_TYPES.bookingCredit.id ? (
                  <div>
                    <IonLabel className="mb-h4">
                      Payment Via Booking Credit
                    </IonLabel>
                  </div>
                ) : (
                  <>
                    <IonLabel className="mb-h4">Payment Via GCash</IonLabel>

                    <IonGrid className="checkout-card-payment-method-gcash-grid ion-no-padding ion-no-margin">
                      <IonRow>
                        <IonCol
                          size="12"
                          className="checkout-card-payment-method-gcash-col ion-no-padding ion-no-margin"
                        >
                          <MBInput
                            label="Name"
                            placeholder="Juan Dela Cruz"
                            value={gcashName}
                            onChange={(gcashName) => {
                              this.setState({
                                gcashName,
                                errorMsgGcashName: "",
                              });
                            }}
                            error={errorMsgGcashName}
                            type="text"
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          size="12"
                          className="checkout-card-payment-method-gcash-col ion-no-padding ion-no-margin"
                        >
                          <MBInput
                            label="Mobile Number"
                            placeholder="091632278994"
                            value={gcashNumber}
                            onChange={(gcashNumber) => {
                              this.setState({
                                gcashNumber,
                                errorMsgGcashNumber: "",
                              });
                            }}
                            error={errorMsgGcashNumber}
                            type="number"
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </>
                )}
                <IonLabel className="checkout-card-payment-summary-label mb-h4 bold ion-text-start">
                  Summary
                </IonLabel>

                <div className="checkout-card-payment-summary-booking-fees-container">
                  <div className="checkout-card-payment-summary-booking-fees-item">
                    <IonLabel className="mb-body">
                      Subtotal ({bookList.length} booking):{" "}
                    </IonLabel>
                    <IonLabel className="mb-body bold">
                      Php {_.sumBy(bookList, "appointment.bookingFee")}
                    </IonLabel>
                  </div>
                  <div className="checkout-card-payment-summary-booking-fees-item">
                    <IonLabel className="mb-body">Others</IonLabel>
                    <IonLabel className="mb-body bold">Php 0</IonLabel>
                  </div>
                </div>

                <div className="checkout-card-payment-summary-booking-fees-total-container">
                  <IonLabel className="mb-h3 dark-blue">Total</IonLabel>
                  <IonLabel className="mb-h3 bold dark-blue">
                    Php {_.sumBy(bookList, "appointment.bookingFee")}
                  </IonLabel>
                </div>

                <IonItem
                  lines="none"
                  className="checkout-card-payment-tnc-item ion-no-padding"
                  color={MBCOLORS.tertiary}
                >
                  <IonCheckbox
                    className="checkout-card-payment-tnc-checkbox ion-no-margin"
                    checked={agreedToTNC}
                    onIonChange={(e) => {
                      this.setState({
                        agreedToTNC: e.detail.checked,
                      });
                    }}
                    disabled={_.isEmpty(bookList)}
                    color={MBCOLORS.primary}
                    mode="md"
                  />
                  <IonLabel className="checkout-card-payment-tnc-label mb-body ion-no-margin">
                    I have read and understood the{" "}
                    <Link to={routes.TERMS_CONDITION} target="_blank">
                      <u>Terms And Condition</u>
                    </Link>
                  </IonLabel>
                </IonItem>

                <IonButton
                  disabled={_.isEmpty(bookList)}
                  className="checkout-card-payment-submit-button mb-body bold white ion-text-capitalize"
                  size="large"
                  onClick={this.confirmCheckout}
                >
                  Confirm Checkout
                </IonButton>
              </IonCardContent>
            </IonCard>
          </div>

          {successDialogIsOpen && (
            <MBCheckoutSuccessDialog
              {...this.props}
              isOpen={successDialogIsOpen}
              onDidDismiss={() => {
                this.setState({
                  successDialogIsOpen: false,
                });
                // setTimeout(() => {
                //   if (
                //     this.props.location.pathname === routes.PATIENT_CHECKOUT &&
                //     bookList.length === 0
                //   ) {
                //     this.props.history.push(routes.PATIENT_HOME);
                //   }
                // }, 1000);
              }}
              {...(!_.isEmpty(singleAppointmentBooked)
                ? { appointmentId: singleAppointmentBooked }
                : {})}
            />
          )}

          <IonToast
            isOpen={!_.isEmpty(error)}
            message={error}
            duration={5000}
            onDidDismiss={() => this.setState({ error: "" })}
            color={MBCOLORS.danger}
          />

          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={"Processing appointment. This may take a while."}
          />
        </MBContainer>
      </>
    );
  };
}

export default Checkout;
