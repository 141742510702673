import _ from "lodash";
import moment from "moment";
import { ellipsisHorizontal, pencilOutline, trashBin } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonCard,
  IonLabel,
  IonCardContent,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonPopover,
  IonList,
  IonItem,
  IonToast,
} from "@ionic/react";

import "./MBDoctorDetailCard.scss";
import * as services from "../../services";
import * as routes from "../../constants/routes";
import { MBDivider } from "../MBDivider/MBDivider";
import { Hospital, HospitalDoctorListView, Doctor } from "../../models";
import { MBCOLORS, DAYS_OF_WEEK, RESOURCE_TYPES } from "../../constants/config";
import { DoctorHospitalAccountSetup } from "../DoctorHospitalAccountSetup/DoctorHospitalAccountSetup";
import { MBProps } from "../../interface";
import { useEffectOnlyOnce } from "../../functions/common";
import { MBDialog } from "../MBDialog/MBDialog";

interface MBDoctorDetailCardPropsInterface extends MBProps {
  details: HospitalDoctorListView;
  hospital: Hospital;
  executiveAssistantMode?: boolean;
  onEditCallback?: () => void;
}
interface SelectScheduleDays {
  dayOfWeek: number;
  name: string;
  slots: number | null;
  isDayChecked: boolean;
  docId: string;
  time: string;
}
export const MBDoctorDetailCard = (props: MBDoctorDetailCardPropsInterface) => {
  const { details, hospital, executiveAssistantMode, onEditCallback } = props;

  const scheduleDays = Object.values(DAYS_OF_WEEK).map((day) => {
    return {
      dayOfWeek: day.day,
      name: day.name,
      time: !_.isEmpty(
        _.find(details.schedule, (schedule) => schedule.dayOfWeek === day.day)
      )
        ? moment(
            _.find(
              details.schedule,
              (schedule) => schedule.dayOfWeek === day.day
            )!.startTime.toDate()
          ).format("hh:mm A")
        : "",
      slots: !_.isEmpty(
        _.find(details.schedule, (schedule) => schedule.dayOfWeek === day.day)
      )
        ? _.find(
            details.schedule,
            (schedule) => schedule.dayOfWeek === day.day
          )!.slots
        : 0,
      isDayChecked: !_.isEmpty(
        _.find(details.schedule, (schedule) => schedule.dayOfWeek === day.day)
      )
        ? true
        : false,
      docId: !_.isEmpty(
        _.find(details.schedule, (schedule) => schedule.dayOfWeek === day.day)
      )
        ? _.find(
            details.schedule,
            (schedule) => schedule.dayOfWeek === day.day
          )!.docId
        : "",
    };
  });

  console.log("WHAT IS SCHEDULE DAY? ", scheduleDays);
  const isAvailableToday = !_.isEmpty(
    _.find(
      details.schedule,
      (schedule) => schedule.dayOfWeek === new Date().getDay()
    )
  );

  const [isOpenEditDoctor, setIsOpenEditDoctor] = useState(false);
  const [
    isOpenResourceArchivedDialog,
    setIsOpenResourceArchivedDialog,
  ] = useState(false);
  const [selectedScheduleDays] = useState(scheduleDays as SelectScheduleDays[]);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [actionButtonsEvent, setActionButtonsEvent] = useState(
    undefined
  ) as any;
  const [error, setError] = useState("");
  const [isOpenSuccessArchiveDialog, setIsOpenSuccessArchiveDialog] = useState(
    false
  );

  const [hospitalDoctors, setHospitalDoctors] = useState(
    null as HospitalDoctorListView[] | null
  );

  useEffectOnlyOnce(() => {
    getHospitalDoctors();
  });

  const getHospitalDoctors = () => {
    try {
      services.getDetailedDoctorlistWithHospital(
        hospital.docId || "",
        (hospitalDoctors) => {
          setHospitalDoctors(hospitalDoctors);
        }
      );
    } catch (e) {
      console.log("SILENT ERROR", e);
      setHospitalDoctors([]);
    }
  };

  const removeDoctor = async () => {
    try {
      await services.removeDoctor(details.doctorHospitalId, hospital, "");
      setIsOpenSuccessArchiveDialog(true);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <>
      <IonCard
        className="mb-doctor-detail-card ion-no-margin"
        color={MBCOLORS.tertiary}
      >
        <IonCardContent className="mb-doctor-detail-card-content ion-no-padding">
          <div className="mb-doctor-detail-card-content-details-container">
            <IonGrid className="mb-doctor-detail-card-grid-detail ion-no-padding ion-no-margin">
              <IonRow className="mb-doctor-detail-card-row ion-no-padding ion-no-margin">
                <IonCol
                  size="5"
                  className="mb-doctor-detail-card-col-icons ion-no-padding ion-no-margin"
                >
                  <IonIcon className="mb-doctor-detail-card-details-icon ion-no-margin"></IonIcon>
                  <div
                    className={`mb-doctor-detail-card-status-container ${
                      isAvailableToday ? "" : "not-available"
                    }`}
                  >
                    <IonLabel
                      className={`mb-doctor-detail-card-status mb-body ${
                        isAvailableToday ? "white" : "light"
                      } bold`}
                    >
                      {isAvailableToday
                        ? "Available Today"
                        : "Not Available Today"}
                    </IonLabel>
                  </div>
                </IonCol>
                <IonCol
                  size="6"
                  className="mb-doctor-detail-card-col-details ion-no-padding ion-no-margin"
                >
                  <div className="mb-doctor-detail-card-details-container">
                    <IonLabel className="mb-h1 dark-blue ion-no-padding ion-no-margin">
                      {details.fullName}
                    </IonLabel>
                    <div className="mb-doctor-detail-card-sub-detail-container">
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon hospital" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          {hospital.hospitalName}
                        </IonLabel>
                      </div>
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon mail" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          {details.emailAddress}
                        </IonLabel>
                      </div>
                      <div className="detail-container">
                        <IonIcon className="md-detail-card-sub-detail-icon mobile" />
                        <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                          {details.phoneNumber}
                        </IonLabel>
                      </div>
                      {console.log("details -- ", details)}
                      {details.specialities.map((speciality) => {
                        return (
                          <>
                            <div className="detail-container">
                              <IonIcon className="md-detail-card-sub-detail-icon heart" />
                              <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                                {`${speciality.name} `}
                              </IonLabel>
                            </div>
                            <div className="detail-container fee-container">
                              <IonIcon className="md-detail-card-sub-detail-icon" />
                              <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                                <b>Php {speciality.fee}</b> - Consultation Fee
                              </IonLabel>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </IonCol>
                <IonCol
                  size="1"
                  className="mb-doctor-detail-card-col-button ion-no-padding ion-no-margin"
                >
                  <IonButton
                    className="mb-doctor-detail-card-action-button"
                    color={MBCOLORS.medium}
                    onClick={(e) => {
                      setActionButtonsEvent(e.nativeEvent);
                      setShowActionButtons(true);
                    }}
                  >
                    <IonIcon icon={ellipsisHorizontal} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <MBDivider />
            <IonGrid className="mb-doctor-detail-card-grid-schedule ion-no-padding ion-no-margin">
              <IonRow className="mb-doctor-detail-card-row-schedule-header ion-no-padding ion-no-margin">
                <IonCol size="4">
                  <IonLabel className="mb-h4 bold ion-no-padding ion-no-margin">
                    Schedule
                  </IonLabel>
                </IonCol>
                <IonCol size="4">
                  <IonLabel className="mb-h4 normal ion-no-padding ion-no-margin">
                    Start Time
                  </IonLabel>
                </IonCol>
                <IonCol size="1.5">
                  <IonLabel className="mb-h4 normal ion-no-padding ion-no-margin">
                    Slots
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="mb-doctor-detail-card-row-schedule-data ion-no-padding ion-no-margin">
                <IonCol
                  className="mb-doctor-detail-card-col-schedule-day"
                  size="4"
                >
                  {!!selectedScheduleDays &&
                    selectedScheduleDays.map((scheduleDay) => {
                      return (
                        <div
                          className={`mb-doctor-detail-card-schedule-day-container ${
                            scheduleDay.slots !== 0 ? "" : "not-available"
                          } ion-no-padding ion-no-margin`}
                        >
                          <IonLabel
                            className={`mb-doctor-detail-card-schedule-label mb-h2 ${
                              scheduleDay.slots !== 0 ? "primary" : "light"
                            } normal ion-no-padding ion-no-margin`}
                          >
                            {scheduleDay.name}
                          </IonLabel>
                        </div>
                      );
                    })}
                </IonCol>
                <IonCol
                  size="4"
                  className="mb-doctor-detail-card-col-schedule-time-slot ion-no-padding ion-no-margin"
                >
                  {selectedScheduleDays.map((scheduleDay) => {
                    return (
                      <IonLabel
                        className={`mb-doctor-detail-card-schedule-time-slot-label mb-h2 ${
                          !_.isEmpty(scheduleDay.time) &&
                          scheduleDay.slots !== 0
                            ? ""
                            : "light"
                        } normal`}
                      >
                        {!_.isEmpty(scheduleDay.time)
                          ? scheduleDay.time
                          : "0:00 AM"}
                      </IonLabel>
                    );
                  })}
                </IonCol>
                <IonCol
                  size="1.5"
                  className="mb-doctor-detail-card-col-schedule-time-slot ion-no-padding ion-no-margin"
                >
                  {selectedScheduleDays.map((scheduleDay) => {
                    return (
                      <IonLabel
                        className={`mb-doctor-detail-card-schedule-time-slot-label mb-h2 ${
                          !_.isEmpty(scheduleDay.time) &&
                          scheduleDay.slots !== 0
                            ? ""
                            : "light"
                        } normal`}
                      >
                        {scheduleDay.slots}
                      </IonLabel>
                    );
                  })}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonCardContent>
      </IonCard>

      <IonPopover
        mode="md"
        isOpen={showActionButtons}
        event={actionButtonsEvent}
        cssClass="mb-doctor-detail-card-popover noselect ion-no-padding"
        showBackdrop={false}
        onDidDismiss={() => {
          setShowActionButtons(false);
        }}
      >
        <div className="mb-doctor-detail-card-list-container">
          <IonList className="mb-doctor-detail-card-list">
            <div className="mb-doctor-detail-card-item-container">
              <IonItem
                className="mb-doctor-detail-card-dropdown-modal-item ion-justify-content-center"
                button={true}
                disabled={_.isNull(hospitalDoctors)}
                lines="none"
                onClick={() => setIsOpenEditDoctor(true)}
              >
                <IonLabel className="mb-doctor-detail-card-dropdown-label mb-h5">
                  <IonIcon
                    className="mb-doctor-detail-card-dropdown-modal-item-icon"
                    icon={pencilOutline}
                    slot="start"
                  />
                  {!executiveAssistantMode ? "Edit" : "Edit Schedule"}
                </IonLabel>
              </IonItem>
              {!executiveAssistantMode && (
                <IonItem
                  className="mb-doctor-detail-card-dropdown-modal-item ion-justify-content-center"
                  button={true}
                  lines="none"
                  onClick={() => {
                    setIsOpenResourceArchivedDialog(true);
                  }}
                >
                  <IonLabel className="mb-doctor-detail-card-dropdown-label mb-h5">
                    <IonIcon
                      className="mb-doctor-detail-card-dropdown-modal-item-icon"
                      icon={trashBin}
                      slot="start"
                    />
                    Remove
                  </IonLabel>
                </IonItem>
              )}
            </div>
          </IonList>
        </div>
      </IonPopover>

      <MBDialog
        isOpen={isOpenResourceArchivedDialog}
        icon="warning"
        title="You are about to remove this doctor"
        message="Patients will no longer be able to book this doctor and all appointments with the doctor will be cancelled . Are you sure you want to continue?"
        onDidDismiss={() => {
          setIsOpenResourceArchivedDialog(false);
        }}
        onApprove={() => {
          removeDoctor();
        }}
        onDecline={() => {
          setIsOpenResourceArchivedDialog(false);
        }}
      />

      <MBDialog
        isOpen={isOpenSuccessArchiveDialog}
        icon="success"
        title="Remove success!"
        message="You have successfully removed the doctor"
        onDidDismiss={() => {
          setIsOpenSuccessArchiveDialog(false);
          props.history.push(routes.HOSPITAL_DEPARTMENT);
        }}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
      {isOpenEditDoctor && !_.isNull(hospitalDoctors) && (
        <DoctorHospitalAccountSetup
          isEdit={true}
          existingPhysicianIdNumber={details.physicianIdNumber}
          isOpen={isOpenEditDoctor}
          doctors={hospitalDoctors as Doctor[]}
          hospitalId={hospital.docId || ""}
          hospitalName={hospital.hospitalName}
          hospitalDepartment={hospital.department}
          hospitalRepName={`${hospital.firstName} ${hospital.lastName}`}
          specialityMap={{} as any}
          onBack={() => {
            setIsOpenEditDoctor(false);
            if (onEditCallback !== undefined) {
              onEditCallback();
            }
          }}
          onCancel={() => {}}
          {...props}
          {...(!!executiveAssistantMode ? { executiveAssistantMode } : {})}
        />
      )}
    </>
  );
};
