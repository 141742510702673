/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonCheckbox,
} from "@ionic/react";

import "./HospitalAccountSetup.scss";
import * as api from "../../api";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import { storage } from "../../firebase";
import {
  formatString,
  validatePassword,
  useEffectOnlyOnce,
  isMobile,
  encryptPassword,
} from "../../functions/common";
import { HospitalNetwork, HospitalRepInvitation } from "../../models";
import { MBCard } from "../MBCard/MBCard";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBFileInput } from "../MBFileInput/MBFileInput";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import {
  MBDropdownSelect,
  MBDropdownSelectOption,
} from "../MBDropdownSelect/MBDropdownSelect";
import { LIMITS, MBCOLORS } from "../../constants/config";
import { MSGS_COMMON, MSGS_SIGNUP } from "../../constants/messages";

interface HospitalAccountSetupProps extends MBProps {
  invitation: HospitalRepInvitation;
  onCancel: () => void;
}
export const HospitalAccountSetup = (props: HospitalAccountSetupProps) => {
  const { onCancel, authUser, history, invitation } = props;

  const [formStep] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [haveReadPolicy, setHaveReadPolicy] = useState(false);
  const [consentNeededDialogOpen, setConsentNeededDialogOpen] = useState(false);
  const [department, setDepartment] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCityMunicipality, setSelectedCityMunicipality] = useState("");

  const [password, setPassword] = useState("");
  const [governmentIdFile, setGovernmentIdFile] = useState(null as any);
  const [hospitalIdFile, setHospitalIdFile] = useState(null as any);

  const [selectedHospital, setSelectedHospital] = useState(
    {} as MBDropdownSelectOption
  );
  const [hospitalSearch, setHospitalSearch] = useState("");

  // const [
  //   selectedExistingHospitalNetworkId,
  //   setSelectedExistingHospitalNetworkId,
  // ] = useState("");

  const [hospitalNetworkOptions, setHospitalNetworkOptions] = useState(
    [] as MBDropdownSelectOption[]
  );

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("");
  const [errorMsgEmaillAddress, setErrorMsgEmaillAddress] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [errorMsgGovtIdFile, setErrorMsgGovtIdFile] = useState("");
  const [errorMsgHospitalIdFile, setErrorMsgHospitalIdFile] = useState("");
  const [errorMsgHospitalName, setErrorMsgHospitalName] = useState("");
  const [errorMsgDepartment, setErrorMsgDepartment] = useState("");
  const [errorMsgProvince, setErrorMsgProvince] = useState("");
  const [errorMsgCityMunicipality, setErrorMsgCityMunicipality] = useState("");

  const [covidSafeDialogIsOpen, setCovidSafeDialogIsOpen] = useState(false);
  const [
    verificationSentDialogIsOpen,
    setVerificationSentDialogIsOpen,
  ] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffectOnlyOnce(() => {
    getHospitalOptions();
  });

  const setupInvitationData = async (
    hospitalNetwork: MBDropdownSelectOption[]
  ) => {
    setLoading(true);
    try {
      setEmailAddress(invitation.emailAddress);
      const invitationHospital = await services.getHospital(
        invitation.hospitalId
      );
      if (!_.isEmpty(invitationHospital)) {
        setDepartment(invitationHospital.department);
        const invitationHospitalSelectedOption =
          _.find(
            hospitalNetwork,
            (hospital) =>
              hospital.name.trim().toLowerCase() ===
              invitationHospital.hospitalName.trim().toLowerCase()
          ) || ({} as MBDropdownSelectOption);
        setSelectedHospital(invitationHospitalSelectedOption);
        setSelectedCityMunicipality(
          (invitationHospitalSelectedOption.metadata
            .hospital as HospitalNetwork).cityMunicipality
        );
        setSelectedProvince(
          (invitationHospitalSelectedOption.metadata
            .hospital as HospitalNetwork).province
        );
      } else {
        setError("Hospital not found");
        setTimeout(() => {
          props.history.push(routes.LOGIN_OPTIONS);
        }, 2000);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  const getHospitalOptions = () => {
    setLoading(true);
    services.getHospitalNetwork((hospitalList) => {
      const hospitalNetwork = hospitalList.map((hospital) => {
        return {
          id: hospital.docId || "",
          name: hospital.hospitalName,
          metadata: {
            hospital,
          },
        };
      });
      setHospitalNetworkOptions(hospitalNetwork);
      if (!_.isEmpty(authUser)) {
        setEmailAddress(authUser.authEmail);
      } else if (!_.isEmpty(invitation)) {
        setupInvitationData(hospitalNetwork);
      }
      setLoading(false);
    });
  };

  const onSubmit = async () => {
    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedPhoneNumber = formatString((phoneNumber || "") as string);
    const formattedPassword = password || "";
    const formattedDepartment = formatString(department);

    let errorMessage = null;
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_SIGNUP.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.firstName.max;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_SIGNUP.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedPhoneNumber.length === 0) {
      errorMessage = MSGS_SIGNUP.mobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (!validatePhoneNumber(formattedPhoneNumber)) {
      errorMessage = MSGS_SIGNUP.invalidMobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_SIGNUP.email.required;
      setErrorMsgEmaillAddress(errorMessage);
    }
    if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_SIGNUP.email.invalid;
      setErrorMsgEmaillAddress(errorMessage);
    }
    if (_.isEmpty(authUser) && formattedPassword.length === 0) {
      errorMessage = MSGS_SIGNUP.password.required;
      setErrorMsgPassword(errorMessage);
    }
    if (_.isEmpty(authUser) && !validatePassword(formattedPassword)) {
      errorMessage = MSGS_SIGNUP.password.invalid;
      setErrorMsgPassword(errorMessage);
    }
    if (_.isEmpty(formattedDepartment)) {
      errorMessage = MSGS_SIGNUP.department.required;
      setErrorMsgDepartment(errorMessage);
    }
    if (!governmentIdFile) {
      errorMessage = MSGS_SIGNUP.governmentId.required;
      setErrorMsgGovtIdFile(errorMessage);
    }
    if (!hospitalIdFile) {
      errorMessage = MSGS_SIGNUP.hospitalId.required;
      setErrorMsgHospitalIdFile(errorMessage);
    }
    if (_.isEmpty(selectedHospital)) {
      errorMessage = MSGS_SIGNUP.hospitalName;
      setErrorMsgHospitalName(errorMessage);
    }
    if (_.isEmpty(selectedProvince)) {
      errorMessage = MSGS_SIGNUP.province;
      setErrorMsgProvince(errorMessage);
    }
    if (_.isEmpty(selectedCityMunicipality)) {
      errorMessage = MSGS_SIGNUP.cityMunicipality;
      setErrorMsgCityMunicipality(errorMessage);
    }
    if (_.isEmpty(errorMessage)) {
      try {
        if (!haveReadPolicy) {
          setConsentNeededDialogOpen(true);
        } else {
          setLoading(true);
          console.log("WILL CHECK", {
            selectedHospital,
            department,
          });
          const isHospitalExisting = await services.hospitalDepartmentExisting(
            selectedHospital.name,
            department
          );
          if (isHospitalExisting && _.isEmpty(invitation)) {
            setError("This department is already registered.");
            setLoading(false);
          } else {
            let docId = !_.isEmpty(authUser)
              ? authUser!.uid
              : !_.isEmpty(invitation)
              ? invitation.hospitalId
              : null;
            if (_.isEmpty(docId)) {
              docId = await services.createUserUsingEmail(
                formattedEmailAddress,
                formattedPassword
              );
            }

            if (_.isEmpty(invitation)) {
              // SAVE hospital id photo
              const ref = storage.ref();
              const hospitalIdPath = `hospitalId/${formattedLastName
                .replace(" ", "")
                .toLowerCase()}_${formattedFirstName}_${docId}/hospital_id_${new Date().valueOf()}`;
              const uploadHospitalIdSnapshot = await ref
                .child(hospitalIdPath)
                .put(hospitalIdFile);
              const newHospitalIdURL = await uploadHospitalIdSnapshot.ref.getDownloadURL();
              const governmentIdPath = `governmentId/${formattedLastName
                .replace(" ", "")
                .toLowerCase()}_${formattedFirstName}_${docId}/government_id_${new Date().valueOf()}`;
              const uploadGovernmentIdSnapshot = await ref
                .child(governmentIdPath)
                .put(governmentIdFile);
              const newGovermentIdURL = await uploadGovernmentIdSnapshot.ref.getDownloadURL();
              await services.createNewHospital(
                docId!,
                formattedFirstName,
                formattedLastName,
                formattedEmailAddress,
                formattedPhoneNumber,
                formattedDepartment,
                newGovermentIdURL,
                newHospitalIdURL,
                selectedHospital.name,
                selectedProvince,
                selectedCityMunicipality,
                (selectedHospital.metadata.hospital as HospitalNetwork)
                  .location,
                !_.isEmpty(invitation)
              );
            } else {
              const machineDetails = await api.getUserMachineDetails();
              const encryptedNewPassword = encryptPassword(
                invitation.invitedBy,
                formattedPassword
              );
              await api.transferAccount(
                invitation.docId || "",
                formattedFirstName,
                formattedLastName,
                formattedEmailAddress,
                formattedPhoneNumber,
                machineDetails,
                encryptedNewPassword
              );

              await services.signIn(formattedEmailAddress, formattedPassword);

              // SAVE hospital id photo
              const ref = storage.ref();
              const hospitalIdPath = `hospitalId/${formattedLastName
                .replace(" ", "")
                .toLowerCase()}_${formattedFirstName}_${docId}/hospital_id_${new Date().valueOf()}`;
              const uploadHospitalIdSnapshot = await ref
                .child(hospitalIdPath)
                .put(hospitalIdFile);
              const newHospitalIdURL = await uploadHospitalIdSnapshot.ref.getDownloadURL();
              const governmentIdPath = `governmentId/${formattedLastName
                .replace(" ", "")
                .toLowerCase()}_${formattedFirstName}_${docId}/government_id_${new Date().valueOf()}`;
              const uploadGovernmentIdSnapshot = await ref
                .child(governmentIdPath)
                .put(governmentIdFile);
              const newGovermentIdURL = await uploadGovernmentIdSnapshot.ref.getDownloadURL();

              await services.updateHospitalRepresentative(
                invitation.invitedBy,
                {
                  governmentId: newGovermentIdURL,
                  identificationCard: newHospitalIdURL,
                }
              );
            }

            setVerificationSentDialogIsOpen(true);
            setLoading(false);
          }
        }
      } catch (error) {
        setError(error.message);
        console.log("error -- ", error);
        setLoading(false);
      }
    }
  };

  return (
    <MBCard
      title={
        _.isEmpty(invitation) ? "Register as a Hospital" : "Confirm Details"
      }
      titleDescription=""
      subtitle=""
      onBack={onCancel}
      onSubmit={onSubmit}
    >
      <div
        className={`hospital-account-registration-form-container ${
          formStep === 1 && "hidden"
        }`}
      >
        <IonGrid className="hospital-account-registration-form-container-grid ion-no-padding">
          <IonRow className="ion-no-padding">
            <IonCol
              className={`hospital-account-registration-form-container-col ${
                isMobile() ? "mobile" : "ion-padding-end"
              } ion-no-padding`}
              size={isMobile() ? "12" : "6"}
            >
              <MBInput
                label="First name"
                value={firstName}
                type="text"
                error={errorMsgFirstName}
                onChange={(firstName) => {
                  setFirstName(firstName);
                  setErrorMsgFirstName("");
                }}
              />
            </IonCol>
            <IonCol
              className={`hospital-account-registration-form-container-col ${
                isMobile() ? "mobile" : "ion-padding-start"
              } ion-no-padding`}
              size={isMobile() ? "12" : "6"}
            >
              <MBInput
                label="Last name"
                value={lastName}
                type="text"
                error={errorMsgLastName}
                onChange={(lastName) => {
                  setLastName(lastName);
                  setErrorMsgLastName("");
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-no-padding">
            <IonCol
              className={`hospital-account-registration-form-container-col ${
                isMobile() ? "mobile" : "ion-padding-end"
              } ion-no-padding`}
              size={isMobile() ? "12" : "6"}
            >
              <MBInput
                label="Mobile Number"
                value={phoneNumber}
                type="tel"
                error={errorMsgPhoneNumber}
                onChange={(phoneNumber) => {
                  setPhoneNumber(phoneNumber);
                  setErrorMsgPhoneNumber("");
                }}
              />
            </IonCol>
            <IonCol
              className={`hospital-account-registration-form-container-col ${
                isMobile() ? "mobile" : "ion-padding-start"
              } ion-no-padding`}
              size={isMobile() ? "12" : "6"}
            >
              <MBInput
                label="Email Address"
                value={emailAddress}
                type="email"
                error={errorMsgEmaillAddress}
                onChange={(emailAddress) => {
                  setEmailAddress(emailAddress);
                  setErrorMsgEmaillAddress("");
                }}
                readonly={!_.isEmpty(authUser) || !_.isEmpty(invitation)}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol
              className={`hospital-account-registration-form-container-col ${
                isMobile() && "mobile"
              }`}
              size="12"
            >
              <div className="hospital-account-setup-dropdown-container">
                <MBDropdownSelect
                  className="hospital-account-setup-hospital-drop-down"
                  label="Hospital"
                  readonly={!_.isEmpty(invitation)}
                  value={
                    !_.isEmpty(selectedHospital)
                      ? selectedHospital.name
                      : hospitalSearch
                  }
                  onChange={(hospitalSearch) => {
                    setHospitalSearch(hospitalSearch);
                    setErrorMsgHospitalName("");
                    if (
                      !_.isEmpty(selectedHospital) &&
                      hospitalSearch.trim().toLowerCase() !==
                        selectedHospital.name.trim().toLowerCase()
                    ) {
                      setSelectedHospital({} as MBDropdownSelectOption);
                    }
                  }}
                  onSelectItem={(selectedHospital) => {
                    setSelectedHospital(selectedHospital);
                    console.log("SHOULD USE THIS", selectedHospital);
                    setSelectedCityMunicipality(
                      (selectedHospital.metadata.hospital as HospitalNetwork)
                        .cityMunicipality
                    );
                    setSelectedProvince(
                      (selectedHospital.metadata.hospital as HospitalNetwork)
                        .province
                    );
                    setErrorMsgHospitalName("");
                  }}
                  selectFromOptionsRequired={true}
                  options={hospitalNetworkOptions}
                  error={errorMsgHospitalName}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="ion-no-padding">
            <IonCol
              className={`hospital-account-registration-form-container-col has-dropdown-input ${
                isMobile() ? "mobile" : "ion-padding-end"
              }`}
              size={`${isMobile() ? "12" : "6"}`}
            >
              <MBInput
                label="Province"
                readonly={true}
                value={selectedProvince}
                type="text"
                onChange={() => {
                  setErrorMsgProvince("");
                }}
                error={errorMsgProvince}
              />
            </IonCol>
            <IonCol
              className={`hospital-account-registration-form-container-col has-dropdown-input ${
                isMobile() ? "mobile" : "ion-padding-start"
              }`}
              size={`${isMobile() ? "12" : "6"}`}
            >
              <MBInput
                label="City/Municipality"
                readonly={true}
                value={selectedCityMunicipality}
                onChange={() => {
                  setErrorMsgCityMunicipality("");
                }}
                type="text"
                error={errorMsgCityMunicipality}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-no-padding">
            <IonCol
              className={`hospital-account-registration-form-container-col  ${
                isMobile() && "mobile"
              }`}
              size="12"
            >
              <MBInput
                label="Department"
                readonly={!_.isEmpty(invitation)}
                value={department}
                type="text"
                error={errorMsgDepartment}
                onChange={(department) => {
                  setDepartment(department);
                  setErrorMsgDepartment("");
                }}
              />
            </IonCol>
            {_.isEmpty(authUser) && (
              <IonCol
                className={`hospital-account-registration-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <MBInput
                  label="Password"
                  value={password}
                  type="password"
                  error={errorMsgPassword}
                  onChange={(password) => {
                    setPassword(password);
                    setErrorMsgPassword("");
                  }}
                />
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
        <div className="hospital-account-registration-form-footer-description-container">
          <IonLabel className="mb-h2 ion-text-start">
            Verification requirements
          </IonLabel>
          <IonLabel className="mb-body ion-text-start">
            For the protection of all parties we would require the following:
            Any Government ID and a picture of your Hospital ID.
          </IonLabel>
        </div>

        <IonGrid className="hospital-account-registration-form-container-grid footer ion-no-padding">
          <IonRow className="ion-no-padding">
            <IonCol
              className={`hospital-account-registration-form-container-col ${
                isMobile() ? "mobile" : "ion-padding-end"
              }`}
              size={isMobile() ? "12" : "6"}
            >
              <MBFileInput
                label="Government ID"
                onChange={(file) => {
                  setGovernmentIdFile(file);
                  setErrorMsgGovtIdFile("");
                }}
                error={errorMsgGovtIdFile}
                disableTab={true}
              />
            </IonCol>
            <IonCol
              className={`hospital-account-registration-form-container-col ${
                isMobile() ? "mobile" : "ion-padding-start"
              }`}
              size={isMobile() ? "12" : "6"}
            >
              <MBFileInput
                label="Hospital ID"
                onChange={(file) => {
                  setHospitalIdFile(file);
                  setErrorMsgHospitalIdFile("");
                }}
                error={errorMsgHospitalIdFile}
                disableTab={true}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="hospital-account-registration-form-data-privacy-consent-item ion-no-padding">
          <IonCheckbox
            className="hospital-account-registration-form-data-privacy-consent-checkbox ion-no-margin"
            checked={haveReadPolicy}
            onIonChange={(e) => setHaveReadPolicy(e.detail.checked)}
            color={MBCOLORS.tertiary}
            mode="md"
          />
          <IonLabel className="hospital-account-registration-form-data-privacy-consent-label mb-body ion-no-margin">
            I have read and understood the{" "}
            <Link to={routes.DATA_PRIVACY} target="_blank">
              <u>Data Privacy Policy</u>
            </Link>
          </IonLabel>
        </div>
      </div>
      <MBDialog
        isOpen={covidSafeDialogIsOpen}
        title="COVID-19 Safe"
        message="By clicking this option you are declaring that your facilities are COVID-19 safe and have completed the checklist provided."
        icon="warning"
        miscMessage={
          <IonLabel className="hospital-account-registration-covid-safe-list mb-body">
            Please refer to{" "}
            <a
              onClick={() => {
                window.open("http://www.covid19.gov.ph/covid-19-faqs/");
              }}
            >
              link here
            </a>{" "}
            for the complete checklist.
          </IonLabel>
          //PLACE HOLDER LINK FOR NOW
        }
        onDidDismiss={() => setCovidSafeDialogIsOpen(false)}
      />
      <MBDialog
        isOpen={verificationSentDialogIsOpen}
        title={
          _.isEmpty(invitation)
            ? "Verification request sent"
            : "Registration Success!"
        }
        message={
          _.isEmpty(invitation)
            ? "You are done setting up your account. Our verification team will now check the information you provided. You will receive an email once this process is done."
            : "You are done setting up your account. You can now start managing your hospital account."
        }
        icon="success"
        onDidDismiss={() => {
          setVerificationSentDialogIsOpen(false);
          history.push(routes.HOSPITAL_DEPARTMENT);
          window.location.reload(); //to solve the problem in white screen upon redirection
        }}
      />
      <MBDialog
        isOpen={consentNeededDialogOpen}
        title="Consent needed"
        message="We cannot proceed unless you have read and agreed to the Data Privacy Policy. Please take your time in reading the policy."
        icon="warning"
        onDidDismiss={() => setConsentNeededDialogOpen(false)}
      />
      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </MBCard>
  );
};
