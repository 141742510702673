import { Helmet } from "react-helmet";
import React from "react";

import "./HospitalApprovedApplications.scss";
import { HospitalApprovedApplicationsList } from "../../components/HospitalApprovedApplicationsList/HospitalApprovedApplicationsList";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";

class HospitalApprovedApplications extends React.Component<MBProps> {
  render = () => {
    return (
      <>
        <Helmet>
          <title>MedBook - Approved Hospitals</title>
        </Helmet>
        <MBContainer {...this.props} activePage="approved-applications">
          <HospitalApprovedApplicationsList {...this.props} />
        </MBContainer>
      </>
    );
  };
}

export default HospitalApprovedApplications;
