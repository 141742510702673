/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonCheckbox,
  IonItem,
  IonInput,
  IonModal,
  IonButton,
  IonDatetime,
  IonIcon,
} from "@ionic/react";

import "./DoctorHospitalAccountSetup.scss";
import * as api from "../../api";
import * as email from "../../functions/email";
import * as sms from "../../functions/sms";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { arrowForwardOutline } from "ionicons/icons";

import { analytics } from "../../firebase";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import {
  formatString,
  useEffectOnlyOnce,
  isMobile,
  getServerTimestamp,
} from "../../functions/common";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBInput } from "../MBInput/MBInput";
import {
  MBMultipleDropdownSelect,
  MBMultipleDropdownSelectOption,
} from "../MBMultipleDropdownSelect/MBMultipleDropdownSelect";
import {
  MBDropdownSelectOption,
  MBDropdownSelect,
} from "../MBDropdownSelect/MBDropdownSelect";
import { MBProps, DaysOfWeek } from "../../interface";
import {
  Doctor,
  DoctorHospitals,
  HospitalDoctorListView,
  Hospital,
} from "../../models";
import { MSGS_COMMON, MSGS_DOCTOR } from "../../constants/messages";
import {
  MBCOLORS,
  DAYS_OF_WEEK,
  ANALYTICS_CONTENT_TYPES,
  USER_TYPES,
} from "../../constants/config";

interface DoctorHospitalAccountSetupProps extends MBProps {
  onCancel: () => void;
  isEdit?: boolean;
  existingPhysicianIdNumber?: string;
  hospitalId: string;
  hospitalName: string;
  hospitalDepartment: string;
  onBack: () => void;
  doctors: Doctor[];
  hospitalRepName: string;
  specialityMap: {
    [doctorId: string]: string[];
  };
  isOpen: boolean;
  executiveAssistantMode?: boolean;
  scheduleEditOnly?: boolean;
}

interface SelectScheduleDays {
  docId?: string;
  dayOfWeek: number;
  name: string;
  isDayChecked: boolean;
  startTime: Date;
  endTime: Date;
  slots?: number | null;
}

export const DoctorHospitalAccountSetup = (
  props: DoctorHospitalAccountSetupProps
) => {
  const {
    isEdit,
    existingPhysicianIdNumber,
    hospitalId,
    onBack,
    hospitalName,
    hospitalRepName,
    hospitalDepartment,
    doctors,
    specialityMap,
    isOpen,
    executiveAssistantMode,
  } = props;

  const scheduleDays = Object.values(DAYS_OF_WEEK).map((day) => {
    const dateToday = new Date();
    const startTime = _.cloneDeep(dateToday);
    startTime.setHours(6);
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);

    const endTime = _.clone(dateToday);
    endTime.setHours(18);
    endTime.setMinutes(0);
    endTime.setSeconds(0);
    endTime.setMilliseconds(0);
    return {
      dayOfWeek: day.day,
      name: day.name,
      isDayChecked: false,
      slots: 0,
      startTime,
      endTime,
    };
  });

  const [selectedSpeciality, setSelectedSpeciality] = useState(
    {} as MBMultipleDropdownSelectOption
  );
  const [specialitySearch, setSpecialitySearch] = useState("");

  const [speciliatyOptions, setSpecialityOptions] = useState(
    [] as MBMultipleDropdownSelectOption[]
  );

  const [selectedSpecialities, setSelectedSpecialites] = useState(
    [] as string[]
  );
  const [toEditDoctor, setToEditDoctor] = useState(
    {} as HospitalDoctorListView
  );
  const [physicianIdNumber, setPhysicianIdNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [existingDoctorId, setExistingDoctorId] = useState("");
  const [isDoctorExistInHospital, setIsDoctorExistInHospital] = useState(false);
  const [withLunchBreak, setWithLunchBreak] = useState(false);
  const [startBreakTime, setStartBreakTime] = useState(
    new Date(2020, 1, 1, 12, 0, 0, 0)
  );
  const [endBreakTime, setEndBreakTime] = useState(
    new Date(2020, 1, 1, 13, 0, 0, 0)
  );
  const [scheduleDaysOfWeek, setScheduleDaysOfWeek] = useState(
    scheduleDays as SelectScheduleDays[] | null
  );

  const [estimatedAppointmentMinuteLengthOptions] = useState([
    { id: "15", name: "15 Minutes" },
    { id: "30", name: "30 Minutes" },
    { id: "45", name: "45 Minutes" },
    { id: "60", name: "60 Minutes" },
  ] as MBDropdownSelectOption[]);

  const [
    selectedEstimatedAppointmentMinuteLength,
    setSelectedEstimatedAppointmentMinuteLength,
  ] = useState({ id: "15", name: "15 Minutes" } as MBDropdownSelectOption);

  const [
    temporarySelectedSpecialities,
    setTemporarySelectedSpecialites,
  ] = useState([] as string[]);

  const [errorMsgPhysicianIdNumber, setErrorMsgPhysicianIdNumber] = useState(
    ""
  );
  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgMobileNumber, setErrorMsgMobileNumber] = useState("");
  const [errorMsgEmailAddress, setErrorMsgEmailAddress] = useState("");
  const [errorMsgSpeciality, setErrorMsgSpecility] = useState("");
  const [
    errorMsgEstimateAppointmentLength,
    setErrorMsgEstimateAppointmentLength,
  ] = useState("");

  const [
    isOpenDoctorSpecialitesDialog,
    setIsOpenDoctorSpecialitiesDialog,
  ] = useState(false);

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffectOnlyOnce(() => {
    getSpecialities();

    if (isEdit && !!existingPhysicianIdNumber) {
      checkPhysicianIdNumberExist(existingPhysicianIdNumber);
    }
  });

  const getSpecialities = async () => {
    await services.getSpecialtiesOptions((specialities) => {
      console.log("WHAT WE GOT", specialities);
      const specialitiesDropdownOptions = specialities.map((item, index) => {
        return {
          id: index.toString(),
          name: item as string,
        } as MBMultipleDropdownSelectOption;
      });
      setSpecialityOptions(
        specialitiesDropdownOptions as MBMultipleDropdownSelectOption[]
      );
    });
  };

  const checkPhysicianIdNumberExist = async (physicianIdNumber: string) => {
    console.log("WILL CHECK ID", physicianIdNumber, doctors);
    const doctorWithThisPhysicianId = _.find(doctors, (doctor) => {
      return doctor.physicianIdNumber.replace(/^0+/, "") === physicianIdNumber;
    });

    if (!!doctorWithThisPhysicianId && !_.isEmpty(doctorWithThisPhysicianId)) {
      try {
        setLoading(true);
        const isDoctorExist = await services.checkIfDoctorHospitalExist(
          doctorWithThisPhysicianId.docId || "",
          hospitalId
        );

        if (isDoctorExist && !isEdit) {
          setErrorMsgPhysicianIdNumber(MSGS_DOCTOR.doctorExist);
          setPhysicianIdNumber(physicianIdNumber);
          setIsDoctorExistInHospital(true);
        } else {
          setIsDoctorExistInHospital(false);

          if (!_.isEmpty(doctorWithThisPhysicianId)) {
            setPhysicianIdNumber(doctorWithThisPhysicianId.physicianIdNumber);
            setFirstName(doctorWithThisPhysicianId.firstName);
            setLastName(doctorWithThisPhysicianId.lastName);
            setEmailAddress(doctorWithThisPhysicianId.emailAddress);
            setMobileNumber(doctorWithThisPhysicianId.phoneNumber);
            setExistingDoctorId(doctorWithThisPhysicianId.docId || "");

            if (isEdit) {
              const doctorHospitalDetail = await services.getDetailedDoctorWithHospital(
                hospitalId,
                doctorWithThisPhysicianId.docId || ""
              );

              setToEditDoctor(doctorHospitalDetail);

              setSelectedSpecialites(
                doctorHospitalDetail.specialities.map(
                  (speciality) => speciality.name
                )
              );
              setTemporarySelectedSpecialites(
                doctorHospitalDetail.specialities.map(
                  (speciality) => speciality.name
                )
              );

              if (!_.isEmpty(doctorHospitalDetail.startBreakTime)) {
                setStartBreakTime(doctorHospitalDetail.startBreakTime.toDate());
                setEndBreakTime(doctorHospitalDetail.endBreakTime.toDate());
                setWithLunchBreak(true);
              }
              setSelectedEstimatedAppointmentMinuteLength(
                _.find(estimatedAppointmentMinuteLengthOptions, (option) => {
                  return (
                    option.id ===
                    doctorHospitalDetail.schedule[0].estimatedAppointmentMinuteLength.toString()
                  );
                })!
              );
              setScheduleDaysOfWeek(null);
              const doctorSchedule = Object.values(DAYS_OF_WEEK).map((day) => {
                const existingSchedule = _.find(
                  doctorHospitalDetail.schedule,
                  (schedule) => {
                    return schedule.dayOfWeek === day.day;
                  }
                );
                if (!_.isEmpty(existingSchedule)) {
                  return {
                    docId: existingSchedule!.docId!,
                    dayOfWeek: day.day,
                    name: day.name,
                    isDayChecked: true,
                    slots: existingSchedule!.slots,
                    startTime: existingSchedule!.startTime.toDate(),
                    endTime: existingSchedule!.endTime.toDate(),
                  };
                } else {
                  const dateToday = new Date();
                  const startTime = _.cloneDeep(dateToday);
                  startTime.setHours(6);
                  startTime.setMinutes(0);
                  startTime.setSeconds(0);
                  startTime.setMilliseconds(0);

                  const endTime = _.clone(dateToday);
                  endTime.setHours(18);
                  endTime.setMinutes(0);
                  endTime.setSeconds(0);
                  endTime.setMilliseconds(0);
                  return {
                    dayOfWeek: day.day,
                    name: day.name,
                    isDayChecked: false,
                    slots: 0,
                    startTime,
                    endTime,
                  };
                }
              });

              console.log("GOT EXISTING SCHEDULE", doctorSchedule);

              setScheduleDaysOfWeek(doctorSchedule);
            } else {
              setSelectedSpecialites(
                specialityMap[doctorWithThisPhysicianId.docId as string] || []
              );
              setTemporarySelectedSpecialites(
                specialityMap[doctorWithThisPhysicianId.docId as string]
              );
              setReadOnly(true);
            }
            setErrorMsgPhysicianIdNumber("");
          } else {
            setPhysicianIdNumber(physicianIdNumber);
            setFirstName("");
            setLastName("");
            setEmailAddress("");
            setMobileNumber("");
            setExistingDoctorId("");
            setReadOnly(false);
            setErrorMsgPhysicianIdNumber("");
          }
        }
        setLoading(false);
      } catch (error) {
        console.log("error -- checkIfDoctorHospitalExist -- ", error);
        setLoading(false);
      }
    } else {
      setIsDoctorExistInHospital(false);
      setPhysicianIdNumber(physicianIdNumber);
      setFirstName("");
      setLastName("");
      setEmailAddress("");
      setMobileNumber("");
      setExistingDoctorId("");
      setReadOnly(false);
      setErrorMsgPhysicianIdNumber("");
    }
  };

  const onSubmit = async () => {
    const { authUser } = props;
    const formattedPhysicianIdNumber = formatString(physicianIdNumber || "");
    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedMobileNumber = formatString(mobileNumber || "") as string;
    const formattedEmailAddress = formatString(emailAddress || "");

    const filteredScheduleDays = _.filter(
      scheduleDaysOfWeek,
      (item) => item.isDayChecked && item.slots !== 0
    );

    const noSlotChecked = !_.isEmpty(
      _.filter(
        scheduleDaysOfWeek,
        (item) => item.isDayChecked && item.slots === 0
      )
    );

    const oldSpecialities = speciliatyOptions.map((item) => item.name);
    const upatedSpecialites = _.union(oldSpecialities, selectedSpecialities);
    const newSpecialities = _.difference(upatedSpecialites, oldSpecialities);

    let errorMessage = null;

    if (formattedPhysicianIdNumber.length === 0) {
      errorMessage = MSGS_DOCTOR.physicianId;
      setErrorMsgPhysicianIdNumber(errorMessage);
    }
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_DOCTOR.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_DOCTOR.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedMobileNumber.length === 0) {
      errorMessage = MSGS_DOCTOR.mobileNumber;
      setErrorMsgMobileNumber(errorMessage);
    }
    if (!validatePhoneNumber(formattedMobileNumber)) {
      errorMessage = MSGS_DOCTOR.invalidMobileNumber;
      setErrorMsgMobileNumber(errorMessage);
    }
    if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_DOCTOR.email.required;
      setErrorMsgEmailAddress(errorMessage);
    }
    if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_DOCTOR.email.invalid;
      setErrorMsgEmailAddress(errorMessage);
    }
    if (_.isEmpty(selectedSpecialities)) {
      errorMessage = MSGS_DOCTOR.speciality;
      setErrorMsgSpecility(errorMessage);
    }
    if (_.isEmpty(filteredScheduleDays) || noSlotChecked) {
      errorMessage = MSGS_DOCTOR.allotedSlots;
      setError(errorMessage);
    }

    if (_.isEmpty(errorMessage) && !isDoctorExistInHospital) {
      setSaving(true);
      try {
        const result = !isEdit
          ? !!existingDoctorId && !_.isEmpty(existingDoctorId)
            ? await services.addDoctorHospital(
                existingDoctorId,
                hospitalId,
                selectedSpecialities
              )
            : await api.addDoctor(
                hospitalId, // hospital Id
                formattedFirstName, // firstname
                formattedLastName, // lastname
                formattedEmailAddress, // email addres
                formattedMobileNumber, // phone number
                formattedPhysicianIdNumber, // physician number
                selectedSpecialities, // selected specialities
                hospitalRepName, // rep name
                `${hospitalName} - ${hospitalDepartment}` // hospital name
              )
          : toEditDoctor;

        if (!_.isEmpty(newSpecialities)) {
          await services.addNewSpecialties(newSpecialities);
        }

        if (isEdit) {
          await services.updateDoctorDetail(toEditDoctor.docId || "", {
            firstName: formattedFirstName,
            lastName: formattedLastName,
            phoneNumber: formattedMobileNumber,
            physicianIdNumber: formattedPhysicianIdNumber,
          });

          const updatedDoctorHospital = {
            specialities: _.map(selectedSpecialities, (speciality) => {
              const existingSpeciality = _.find(
                toEditDoctor.specialities,
                (existingSpeciality) => existingSpeciality.name === speciality
              );

              if (!_.isEmpty(existingSpeciality)) {
                return existingSpeciality!;
              } else {
                return {
                  name: speciality,
                  fee: 0,
                };
              }
            }),
            ...(withLunchBreak
              ? {
                  startBreakTime,
                  endBreakTime,
                }
              : {
                  startBreakTime: null,
                  endBreakTime: null,
                }),
          } as Partial<DoctorHospitals>;

          await services.updateDoctorHospitalDetail(
            result.doctorHospitalId,
            updatedDoctorHospital
          );

          let scheduleUpdated = false;

          //UPDATE SCHEDULE
          if (!_.isNull(scheduleDaysOfWeek)) {
            await Promise.all(
              scheduleDaysOfWeek.map((schedule) => {
                //create
                if (schedule.docId === undefined && schedule.isDayChecked) {
                  scheduleUpdated = true;
                  return services.createDoctorSchedule(
                    toEditDoctor.doctorHospitalId,
                    schedule.dayOfWeek as DaysOfWeek,
                    schedule.slots!,
                    +selectedEstimatedAppointmentMinuteLength.id,
                    schedule.startTime,
                    schedule.endTime
                  );
                }
                //update
                else if (
                  schedule.docId !== undefined &&
                  schedule.isDayChecked
                ) {
                  scheduleUpdated = true;
                  return services.updateDoctorSchedule(
                    schedule.docId || "",
                    schedule.slots!,
                    +selectedEstimatedAppointmentMinuteLength.id,
                    schedule.startTime,
                    schedule.endTime
                  );
                }
                //delete
                else if (
                  schedule.docId !== undefined &&
                  !schedule.isDayChecked
                ) {
                  scheduleUpdated = true;
                  return services.removeDoctorSchedule(schedule.docId || "");
                }
              })
            );
          }

          const hospital = await services.getHospital(hospitalId);
          const hospitalName = `${hospital!.hospitalName} - ${
            hospital.department
          }`;

          //IF USER INFO IS CHANGED, SEND EMAIL
          if (
            !_.isEmpty(newSpecialities) ||
            toEditDoctor.firstName !== formattedFirstName ||
            toEditDoctor.lastName !== formattedLastName ||
            toEditDoctor.phoneNumber !== formattedMobileNumber
          ) {
            try {
              const {
                subject,
                message,
              } = email.updateDoctorUserInfoByRepresentative(
                `${toEditDoctor.firstName} ${toEditDoctor.lastName}`,
                `${hospital.firstName} ${hospital.lastName}`,
                hospitalName
              );

              await services.sendEmail(formattedEmailAddress, subject, message);

              const smsMessage = sms.updateDoctorUserInfoByRepresentative(
                `${toEditDoctor.firstName} ${toEditDoctor.lastName}`,
                `${hospital.firstName} ${hospital.lastName}`,
                hospitalName
              );

              await api.sendSMS(formattedMobileNumber, smsMessage);
            } catch (e) {
              console.log("ERROR SENDING NOTIFS!", e);
              // setError(e);
            }
          }

          //IF SCHEDULE IS CHANGED SEND EMAIL
          if (scheduleUpdated) {
            try {
              if (authUser.userType === USER_TYPES.executiveAssistant.id) {
                const emailUpdateMsg = email.secretaryUpdateDoctorSchedule(
                  `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                  `${toEditDoctor.firstName} ${toEditDoctor.lastName}`,
                  `${hospital.hospitalName}`
                );

                await services.sendEmail(
                  hospital.emailAddress,
                  emailUpdateMsg.subject,
                  emailUpdateMsg.message
                );
                await services.sendEmail(
                  toEditDoctor.emailAddress,
                  emailUpdateMsg.subject,
                  emailUpdateMsg.message
                );
              } else {
                const {
                  subject,
                  message,
                } = email.updateDoctorAvailabilityByRepresentative(
                  `${toEditDoctor.firstName} ${toEditDoctor.lastName}`,
                  `${hospital.firstName} ${hospital.lastName}`,
                  hospitalName
                );

                if (!!toEditDoctor.executiveAssistant) {
                  const sendEmailSecretary = email.updateDoctorAvailabilityByRepresentative(
                    `${toEditDoctor.firstName} ${toEditDoctor.lastName}`,
                    `${hospital.firstName} ${hospital.lastName}`,
                    hospitalName
                  );
                  const smsMessage = sms.updateDoctorAvailabilityByRepresentative(
                    `${toEditDoctor.firstName} ${toEditDoctor.lastName}`,
                    `${toEditDoctor.executiveAssistant.firstName} ${toEditDoctor.executiveAssistant.lastName}`,
                    `${hospital.firstName} ${hospital.lastName}`,
                    hospitalName
                  );
                  await services.sendEmail(
                    toEditDoctor.executiveAssistant.emailAddress,
                    sendEmailSecretary.subject,
                    sendEmailSecretary.message
                  );
                  await api.sendSMS(
                    toEditDoctor.executiveAssistant.phoneNumber,
                    smsMessage
                  );
                }

                await services.sendEmail(
                  formattedEmailAddress,
                  subject,
                  message
                );
              }
            } catch (e) {
              console.log("ERROR SENDING NOTIFS!", e);
              // setError(e);
            }
          }
          analytics.logEvent("update_doctor_speciality", {
            content_type: ANALYTICS_CONTENT_TYPES.hospitalDepartment.type,
            hospital: hospitalName,
          });
          analytics.logEvent("update_doctor_detail", {
            content_type: ANALYTICS_CONTENT_TYPES.hospitalDepartment.type,
            hospital: hospitalName,
          });
          analytics.logEvent("update_hospital_consultation_schedule", {
            content_type: ANALYTICS_CONTENT_TYPES.hospitalDepartment.type,
            hospital: hospitalName,
          });
        } else {
          if (withLunchBreak) {
            services.updateDoctorHospitalDetail(result.doctorHospital, {
              startBreakTime: getServerTimestamp(startBreakTime),
              endBreakTime: getServerTimestamp(endBreakTime),
            } as Partial<DoctorHospitals>);
          }

          if (!_.isEmpty(filteredScheduleDays)) {
            await Promise.all(
              filteredScheduleDays.map((schedule) => {
                return services.createDoctorSchedule(
                  result.doctorHospital,
                  schedule.dayOfWeek as DaysOfWeek,
                  schedule.slots as number,
                  +selectedEstimatedAppointmentMinuteLength.id,
                  schedule.startTime,
                  schedule.endTime
                );
              })
            );
          }

          if (!!existingDoctorId && !_.isEmpty(existingDoctorId)) {
            try {
              const {
                subject,
                message,
              } = email.existinDoctorRegisteredInNewHospital(
                formattedFirstName,
                formattedLastName,
                hospitalRepName,
                `${hospitalName} - ${hospitalDepartment}`
              );

              await services.sendEmail(formattedEmailAddress, subject, message);

              const smsMessage = sms.existinDoctorRegisteredInNewHospital(
                formattedFirstName,
                formattedLastName,
                hospitalRepName,
                `${hospitalName} - ${hospitalDepartment}`
              );

              await api.sendSMS(formattedMobileNumber, smsMessage);
            } catch (e) {
              console.log("ERROR SENDING NOTIFS", e);
              setError(e);
            }
          }
          analytics.logEvent("add_new_doctor", {
            content_type: ANALYTICS_CONTENT_TYPES.hospitalDepartment.type,
            hospital: hospitalName,
          });
        }

        setSaving(false);
        setSuccess(
          !isEdit ? MSGS_DOCTOR.newDoctorSuccess : MSGS_DOCTOR.updateSuccess
        );
      } catch (error) {
        setSaving(false);
        console.log("got error", error);
        setError(error);
      }
    } else {
      setSaving(false);
    }
  };

  return (
    <>
      <IonModal
        cssClass="doctor-account-modal"
        isOpen={isOpen}
        onDidDismiss={onBack}
      >
        <IonLabel className="doctor-account-header mb-h2 dark-blue ion-text-start">
          {!isEdit ? "Add Doctor" : "Update Doctor"}
        </IonLabel>
        <MBDivider />
        <div className="doctor-account-setup-form-container">
          <div
            className={`doctor-account-setup-form-description-container ${
              isMobile() && "mobile"
            }`}
          ></div>
          {!executiveAssistantMode && (
            <IonGrid className="doctor-account-setup-form-container-grid ion-no-padding">
              <IonRow>
                <IonCol
                  className={`doctor-account-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <div className="doctor-account-setup-form-container-col-dropdown-container">
                    <MBInput
                      label="Physician ID number"
                      value={physicianIdNumber.replace(/^0+/, "")}
                      type="text"
                      readonly={isEdit}
                      error={errorMsgPhysicianIdNumber}
                      onChange={(physicianIdNumber) => {
                        checkPhysicianIdNumberExist(
                          physicianIdNumber.replace(/^0+/, "")
                        );
                      }}
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`doctor-account-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    readonly={readOnly}
                    label="First name"
                    value={firstName}
                    type="text"
                    error={errorMsgFirstName}
                    onChange={(firstName) => {
                      setFirstName(firstName);
                      setErrorMsgFirstName("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`doctor-account-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    readonly={readOnly}
                    label="Last name"
                    value={lastName}
                    type="text"
                    error={errorMsgLastName}
                    onChange={(lastName) => {
                      setLastName(lastName);
                      setErrorMsgLastName("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`doctor-account-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    readonly={readOnly}
                    label="Mobile number"
                    value={mobileNumber}
                    type="tel"
                    error={errorMsgMobileNumber}
                    onChange={(mobileNumber) => {
                      setMobileNumber(mobileNumber);
                      setErrorMsgMobileNumber("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`doctor-account-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    readonly={readOnly || isEdit}
                    label="Email address"
                    value={emailAddress}
                    type="email"
                    error={errorMsgEmailAddress}
                    onChange={(emailAddress) => {
                      setEmailAddress(emailAddress);
                      setErrorMsgEmailAddress("");
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  className={`doctor-account-setup-form-container-col ${
                    isMobile() && "mobile"
                  } ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="Speciality"
                    value={selectedSpecialities.toString()}
                    type="text"
                    error={errorMsgSpeciality}
                    onClick={() => {
                      setErrorMsgSpecility("");
                      setIsOpenDoctorSpecialitiesDialog(true);
                    }}
                    readonly={true}
                    icon="dropdown"
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          <IonGrid
            className={`doctor-account-setup-break-time-schedule ion-no-padding ion-no-margin ${
              !!executiveAssistantMode ? "ion-padding-top ion-margin-top" : ""
            }`}
          >
            <IonRow>
              <IonCol size="4.5">
                <IonLabel className="mb-paragraph bold">
                  Regular Break Time
                </IonLabel>
              </IonCol>
              <IonCol className="doctor-account-setup-schedule-col" size="2.5">
                <IonLabel className="mb-paragraph bold">Start Time</IonLabel>
              </IonCol>
              <IonCol
                className="doctor-account-setup-schedule-col"
                size="1"
              ></IonCol>
              <IonCol className="doctor-account-setup-schedule-col" size="2.5">
                <IonLabel className="mb-paragraph bold">End Time</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4.5">
                <IonItem
                  className={`doctor-account-setup-schedule-item ${
                    isMobile() && "mobile"
                  }`}
                  lines="none"
                  color={MBCOLORS.tertiary}
                >
                  <IonCheckbox
                    mode="md"
                    checked={withLunchBreak}
                    onIonChange={(event) => {
                      setWithLunchBreak(event.detail.checked);
                    }}
                  />
                  <IonLabel className="doctor-account-setup-schedule-label mb-body">
                    with Lunch
                  </IonLabel>
                </IonItem>
              </IonCol>
              <IonCol className="doctor-account-setup-schedule-col" size="2.5">
                <IonDatetime
                  disabled={!withLunchBreak}
                  className="doctor-account-setup-time-slot-input mb-body ion-no-margin ion-no-padding"
                  display-format="h:mm A"
                  picker-format="h:mm A"
                  minuteValues="0,15,30,45"
                  value={startBreakTime.toISOString()}
                  onIonChange={(selectedTime) => {
                    setStartBreakTime(
                      new Date(selectedTime.detail.value as string)
                    );
                  }}
                ></IonDatetime>
              </IonCol>
              <IonCol className="doctor-account-setup-schedule-col" size="1">
                <IonIcon color={MBCOLORS.medium} icon={arrowForwardOutline} />
              </IonCol>
              <IonCol className="doctor-account-setup-schedule-col" size="2.5">
                <IonDatetime
                  disabled={!withLunchBreak}
                  className="doctor-account-setup-time-slot-input mb-body ion-no-margin ion-no-padding"
                  display-format="h:mm A"
                  picker-format="h:mm A"
                  minuteValues="0,15,30,45"
                  value={endBreakTime.toISOString()}
                  onIonChange={(selectedTime) => {
                    setEndBreakTime(
                      new Date(selectedTime.detail.value as string)
                    );
                  }}
                ></IonDatetime>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid className="doctor-account-setup-schedule-estimated-appointment-length ion-no-padding ion-no-margin">
            <IonRow>
              <IonCol className="ion-align-self-center" size="3">
                <IonLabel className="mb-paragraph bold">
                  Estimated Time per Appointment
                </IonLabel>
              </IonCol>
              <IonCol
                className="doctor-account-setup-schedule-col doctor-account-setup-schedule-appintment-length ion-align-items-center"
                size="4.5"
              >
                <div className="doctor-account-setup-form-container-col-dropdown-container ">
                  <MBDropdownSelect
                    label=""
                    className="appointment-length-options"
                    options={estimatedAppointmentMinuteLengthOptions}
                    value={selectedEstimatedAppointmentMinuteLength.name}
                    onSelectItem={(
                      selectedEstimatedAppointmentMinuteLength
                    ) => {
                      setSelectedEstimatedAppointmentMinuteLength(
                        selectedEstimatedAppointmentMinuteLength
                      );
                      setErrorMsgEstimateAppointmentLength("");
                    }}
                    selectFromOptionsRequired={true}
                    error={errorMsgEstimateAppointmentLength}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div
            className={`doctor-account-setup-schedule-container ${
              isMobile() && "mobile"
            }`}
          >
            <IonGrid className="ion-no-padding ion-no-margin">
              <IonRow>
                <IonCol size="4.5">
                  <IonLabel className="mb-paragraph bold">Schedule</IonLabel>
                </IonCol>
                <IonCol
                  className="doctor-account-setup-schedule-col"
                  size="2.5"
                >
                  <IonLabel className="mb-paragraph bold">Start Time</IonLabel>
                </IonCol>
                <IonCol
                  className="doctor-account-setup-schedule-col"
                  size="2.5"
                >
                  <IonLabel className="mb-paragraph bold">End Time</IonLabel>
                </IonCol>
                <IonCol
                  className="doctor-account-setup-schedule-col"
                  size="2.5"
                >
                  <IonLabel className="mb-paragraph bold">Slots</IonLabel>
                </IonCol>
              </IonRow>
              {!_.isNull(scheduleDaysOfWeek) &&
                scheduleDaysOfWeek.map((item, index) => {
                  return (
                    <IonRow
                      className={`doctor-account-setup-row-schedule ${
                        isMobile() && "mobile"
                      }`}
                      key={index}
                    >
                      <IonCol size="4.5">
                        <IonItem
                          className={`doctor-account-setup-schedule-item ${
                            isMobile() && "mobile"
                          }`}
                          lines="none"
                          color={MBCOLORS.tertiary}
                        >
                          <IonCheckbox
                            mode="md"
                            checked={item.isDayChecked}
                            onIonChange={() => {
                              const isCheckScheduleDaysOfWeek = _.clone(
                                scheduleDaysOfWeek
                              ) as SelectScheduleDays[];

                              const updateIsCheckScheduleDaysOfWeek =
                                isCheckScheduleDaysOfWeek[index];

                              const updateIsCheckValueIndex = {
                                ...isCheckScheduleDaysOfWeek[index],
                                isDayChecked: !updateIsCheckScheduleDaysOfWeek.isDayChecked,
                              } as SelectScheduleDays;

                              isCheckScheduleDaysOfWeek[
                                index
                              ] = updateIsCheckValueIndex;
                              setScheduleDaysOfWeek(isCheckScheduleDaysOfWeek);
                            }}
                          />
                          <IonLabel className="doctor-account-setup-schedule-label mb-body">
                            {isMobile()
                              ? item.name.slice(0, index === 4 ? 3 : 3)
                              : item.name}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol
                        className="doctor-account-setup-schedule-col"
                        size="2.5"
                      >
                        <IonDatetime
                          disabled={!item.isDayChecked}
                          className="doctor-account-setup-time-slot-input mb-body ion-no-margin ion-no-padding"
                          display-format="h:mm A"
                          picker-format="h:mm A"
                          minuteValues="0,15,30,45"
                          value={item.startTime.toISOString()}
                          onIonChange={(selectedTime) => {
                            const updatedScheduleDays = _.clone(
                              scheduleDaysOfWeek
                            );
                            const updateValueIndex = {
                              ...updatedScheduleDays[index],
                              startTime: new Date(
                                selectedTime.detail.value as string
                              ),
                            } as SelectScheduleDays;

                            updatedScheduleDays[index] = updateValueIndex;
                            setScheduleDaysOfWeek(updatedScheduleDays);
                          }}
                        ></IonDatetime>
                      </IonCol>
                      <IonCol
                        className="doctor-account-setup-schedule-col"
                        size="2.5"
                      >
                        <IonDatetime
                          disabled={!item.isDayChecked}
                          className="doctor-account-setup-time-slot-input mb-body ion-no-margin ion-no-padding"
                          display-format="h:mm A"
                          picker-format="h:mm A"
                          minuteValues="0,15,30,45"
                          value={item.endTime.toISOString()}
                          onIonChange={(selectedTime) => {
                            const updatedScheduleDays = _.clone(
                              scheduleDaysOfWeek
                            );
                            const updateValueIndex = {
                              ...updatedScheduleDays[index],
                              endTime: new Date(
                                selectedTime.detail.value as string
                              ),
                            } as SelectScheduleDays;

                            updatedScheduleDays[index] = updateValueIndex;
                            setScheduleDaysOfWeek(updatedScheduleDays);
                          }}
                        ></IonDatetime>
                      </IonCol>
                      <IonCol
                        className="doctor-account-setup-schedule-col"
                        size="2.5"
                      >
                        <IonInput
                          disabled={!item.isDayChecked}
                          className="doctor-account-setup-time-slot-input mb-body"
                          type="number"
                          min={"0"}
                          max={"99"}
                          value={scheduleDaysOfWeek[index].slots}
                          onIonChange={(slots) => {
                            const updatedScheduleDays = _.clone(
                              scheduleDaysOfWeek
                            );
                            const updateValueIndex = {
                              ...updatedScheduleDays[index],
                              slots:
                                parseInt(slots.detail.value as string) ||
                                (0 as number),
                            } as SelectScheduleDays;
                            updatedScheduleDays[index] = updateValueIndex;
                            setScheduleDaysOfWeek(updatedScheduleDays);
                          }}
                        ></IonInput>
                      </IonCol>
                    </IonRow>
                  );
                })}
            </IonGrid>
          </div>
        </div>
        <MBDivider />
        <div className="doctor-account-setup-button-container">
          <IonButton
            onClick={() => {
              onBack();
            }}
            className="doctor-account-setup-back-button mb-body ion-text-capitalize"
            fill="clear"
            color={MBCOLORS.primary}
          >
            Cancel
          </IonButton>
          <IonButton
            onClick={() => {
              onSubmit();
            }}
            className="doctor-account-setup-save-button mb-body bold white ion-text-capitalize"
            color={MBCOLORS.primary}
          >
            Save
          </IonButton>
        </div>
      </IonModal>
      <MBDialog
        isOpen={!_.isEmpty(success)}
        icon="doctor"
        buttonLabel="Okay"
        title={success}
        message={
          !isEdit
            ? MSGS_DOCTOR.successDialogMsg
            : MSGS_DOCTOR.updateSuccessDialogMsg
        }
        onDidDismiss={() => {
          console.log("SUICCESSSSS!!!");
          setSuccess("");
          onBack();
          // props.history.push(routes.HOSPITAL_DEPARTMENT);
        }}
      />
      {/* DOCTOR SPECIALITY MODAL */}
      <IonModal
        cssClass="doctor-specialities-dialog"
        isOpen={isOpenDoctorSpecialitesDialog}
        onDidDismiss={() => {
          setIsOpenDoctorSpecialitiesDialog(false);
        }}
      >
        <div className="modal-content">
          <div className="doctor-specialities-dialog-children-container">
            <IonLabel className="doctor-specialities-title-label mb-h2 dark-blue">
              Doctor Speciality
            </IonLabel>
            <IonLabel className="doctor-specialities-message-label mb-body">
              Type in to search for speciality. You can select more than one
              speciality per doctor. Click all applicable specialities.
            </IonLabel>

            <div className="doctor-specialities-dropdown-multiple-container">
              <div className="doctor-specialities-dialog-dropdown-container-container">
                <MBMultipleDropdownSelect
                  searchItem={selectedSpeciality.name || specialitySearch}
                  values={temporarySelectedSpecialities}
                  onChange={(specialitySearch) => {
                    setSpecialitySearch(specialitySearch);
                    setErrorMsgSpecility("");
                    if (specialitySearch !== selectedSpeciality.name) {
                      setSelectedSpeciality(
                        {} as MBMultipleDropdownSelectOption
                      );
                    }
                  }}
                  onSelectItems={(selectedItems: string[]) => {
                    setTemporarySelectedSpecialites(_.uniq(selectedItems));
                    setErrorMsgSpecility("");
                  }}
                  selectFromOptionsRequired={true}
                  onRemoveItem={(items: string[]) => {
                    setTemporarySelectedSpecialites(items);
                  }}
                  options={
                    speciliatyOptions as MBMultipleDropdownSelectOption[]
                  }
                  error={errorMsgSpeciality}
                />
              </div>
            </div>
          </div>
          <MBDivider />
          <div className="doctor-specialities-dialog-footer-container">
            <IonButton
              onClick={() => {
                if (_.isEmpty(temporarySelectedSpecialities)) {
                  setTemporarySelectedSpecialites([]);
                  setSelectedSpecialites([]);
                }
                setTemporarySelectedSpecialites([]);
                setErrorMsgSpecility("");
                setIsOpenDoctorSpecialitiesDialog(false);
              }}
              className="doctor-specialities-dialog-back-button mb-body blue ion-text-capitalize"
              fill="clear"
            >
              Cancel
            </IonButton>
            <IonButton
              onClick={() => {
                if (_.isEmpty(temporarySelectedSpecialities)) {
                  setErrorMsgSpecility("Speciality is required.");
                } else {
                  setSelectedSpecialites(temporarySelectedSpecialities);
                  setErrorMsgSpecility("");
                  setIsOpenDoctorSpecialitiesDialog(false);
                }
              }}
              className="doctor-specialities-dialog-submit-button mb-body bold white ion-text-capitalize"
              color={MBCOLORS.primary}
            >
              Submit
            </IonButton>
          </div>
        </div>
      </IonModal>
      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={saving || loading}
        message={saving ? MSGS_COMMON.saving : MSGS_COMMON.loading}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
