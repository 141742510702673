import * as _ from "lodash";
import * as CryptoJS from "crypto-js";
import moment from "moment";
import { isPlatform } from "@ionic/react";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useEffect } from "react";
import { Geolocation } from "@capacitor/core";

import * as capacitorStorage from "./localStorageCapacitor";
import { fb } from "../firebase";
import { storage } from "../firebase";
import { LOCAL_STORAGE } from "../config/index";
import {
  AppointmentStatus,
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
} from "../models";
import { APPOINTMENT_STATUS_TYPES } from "../constants/config";
import { StatusTab } from "../components/MBAppointments/MBAppointments";

let { json2excel } = require("js2excel");

export const getRequestHeaders = async () => {
  const id_token = await capacitorStorage.getItem(LOCAL_STORAGE.fbIdToken);
  const user_id = await capacitorStorage.getItem(LOCAL_STORAGE.userId);
  return {
    "Content-Type": "application/json",
    id_token,
    user_id,
  };
};

export const formatString = (str: string) => {
  return str.trim();
};

export const validatePassword = (password: string) => {
  return password.trim().length >= 8;
};

export const hasOnlyNumbers = (value: string) => {
  return /^\d+$/.test(value) || value.length === 0;
};

export const encodeQueryData = (data: any) => {
  return Object.keys(data)
    .map(function (key: any) {
      return [key, data[key]].map(encodeURIComponent).join("=");
    })
    .join("&");
};

export const toDateTimeFromSecs = (secs: number) => {
  return new Date(secs * 1000);
};

export const useEffectOnlyOnce = (func: () => void) => useEffect(func, []);

export const isMobile = () =>
  (isPlatform("ios") || isPlatform("android") || isPlatform("mobileweb")) &&
  !isPlatform("tablet");

export const isToday = (date: Date) => {
  const dateToday = new Date();
  const month = dateToday.getMonth();
  const monthDay = dateToday.getDate();
  const year = dateToday.getFullYear();

  const sameMonth = date.getMonth() === month;
  const sameMonthDay = date.getDate() === monthDay;
  const sameYear = date.getFullYear() === year;

  return sameMonth && sameMonthDay && sameYear;
};

export const isHistoricalDate = (date: Date) => {
  return moment(moment(date).format("YYYY-MM-DD")).isBefore(
    moment(new Date()).format("YYYY-MM-DD")
  );
};

export const isSameDate = (date: Date, date2: Date) => {
  return moment(date).isSame(moment(date2), "day");
};

export const daysSinceDate = (dateSince: Date, dateFrom = new Date()) => {
  const daySince = moment(dateSince);
  const targetDate = moment(dateFrom);
  const activeSinceDays = targetDate.diff(daySince, "days");
  return activeSinceDays;
};

export const getDayTimeDifference = (
  dateSince: Date,
  currentDate = new Date()
) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const elapsed = moment(currentDate).diff(moment(dateSince));

  const seconds = Math.round(elapsed / 1000);
  const minutes = Math.round(elapsed / msPerMinute);
  const hours = Math.round(elapsed / msPerHour);
  const days = Math.round(elapsed / msPerDay);
  const months = Math.round(elapsed / msPerMonth);
  const years = Math.round(elapsed / msPerYear);

  if (elapsed < msPerMinute) {
    return seconds.toString() + " seconds";
  } else if (elapsed < msPerHour) {
    return minutes.toString() + " minutes";
  } else if (elapsed < msPerDay) {
    return hours.toString() + " hours";
  } else if (elapsed < msPerMonth) {
    return days.toString() + " days";
  } else if (elapsed < msPerYear) {
    return months.toString() + " months";
  } else {
    return years.toString() + " years";
  }
};

export const dateTomorrow = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const dateYesterday = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const formatPhoneNumberToInternational = (
  phoneNumber: string | undefined
) => {
  if (!!phoneNumber) {
    const formattedNumber = parsePhoneNumberFromString(phoneNumber);
    return formattedNumber!.formatInternational();
  }
};

export const validURL = (str: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const uploadAttachments = async (
  file: string[],
  uploaderName: string,
  uploaderId: string,
  folderPathName: string
) => {
  const ref = storage.ref();

  const uploadedRefs: string[] = [];
  const uploadTasks: Promise<any>[] = [];

  file.forEach((attachmentRef: any) => {
    uploadTasks.push(
      new Promise(async (resolve) => {
        const attachmentPath = `${folderPathName}/${uploaderName
          .replace(" ", "")
          .toLowerCase()}_${uploaderId}/${uploaderName}_${new Date().valueOf()}_${
          attachmentRef.name
        }`;

        try {
          ref
            .child(attachmentPath)
            .put(attachmentRef)
            .then(async (result: any) => {
              const downloadUrl = await result.ref.getDownloadURL();
              uploadedRefs.push(downloadUrl);
              resolve();
            })
            .catch((error: any) => {
              resolve(error);
            });
        } catch (error) {
          resolve(error);
        }
      })
    );
  });
  await Promise.all(uploadTasks);
  return uploadedRefs;
};

export const removedUploadedAttachements = async (url: string[]) => {
  try {
    await Promise.resolve(
      url.forEach((url) => {
        new Promise(async (resolve) => {
          try {
            const refFromUrl = storage.refFromURL(url);
            const deletedUrl = await refFromUrl.delete();
            resolve(deletedUrl);
          } catch (error) {
            resolve(null);
          }
        });
      })
    );
  } catch (error) {
    console.log("ERROR ON REMOVING ATTACHMENTS");
  }
};

export const getNotebookHoles = () => {
  let holes = [];

  for (let x = 0; x < 24; x++) {
    holes.push(x);
  }
  return holes;
};

export const getSiteVisFormattedDate = (date: Date) => {
  return moment(date).format("ddd, DD MMM YYYY");
};

export const isNullAndLoadingORNotEmpty = (obj: any) => {
  return _.isNull(obj) || (!_.isEmpty(obj) && !_.isNull(obj));
};

export const getServerTimestamp = (
  date: null | Date = null
): firebase.firestore.Timestamp => {
  if (!_.isNull(date)) {
    return fb.firestore.Timestamp.fromDate(date);
  }
  return fb.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
};

export const getCurrentLocation = async (): Promise<Coordinates> => {
  return new Promise((resolve) => {
    Geolocation.getCurrentPosition()
      .then((result) => {
        resolve({
          lat: result.coords.latitude,
          lng: result.coords.longitude,
        });
      })
      .catch(() => {
        resolve({ lat: -28.1104171, lng: 153.4342987 });
      });
  });
};

export const encryptPassword = (
  userSecret: string,
  plainText: string
): string => {
  return CryptoJS.AES.encrypt(plainText, userSecret).toString();
};

export const decryptPassword = (
  userSecret: string,
  cipherText: any
): string => {
  if (!cipherText) {
    return "";
  }
  const bytes = CryptoJS.AES.decrypt(cipherText, userSecret);
  const plainText = bytes.toString(CryptoJS.enc.Utf8);

  return plainText;
};

interface Coordinates {
  lat: number;
  lng: number;
}
export const getDistanceFrom = (from: Coordinates, to: Coordinates) => {
  if (from.lat === to.lat && from.lng === to.lng) {
    return 0;
  } else {
    var radlat1 = (Math.PI * from.lat) / 180;
    var radlat2 = (Math.PI * to.lat) / 180;
    var theta = from.lng - to.lng;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344; // kilomteres unit
    return dist;
  }
};

export const checkIfCanVerifyMobile = async (
  callBack: (remainingTime: number) => void
) => {
  let recentMobileVerification = parseInt(
    (await capacitorStorage.getItem(LOCAL_STORAGE.recentMobileVerification)) ||
      "0"
  );

  const currentTime = new Date().valueOf();
  const timeLimit = 60000;
  const timePassed = currentTime - recentMobileVerification;
  const remainingTime =
    (timeLimit - timePassed) / 1000 > 1 ? (timeLimit - timePassed) / 1000 : 0;

  let coolDown = _.clone(remainingTime);
  let intervalId: any = null;

  if (!!remainingTime) {
    // this.setState({ mobileVerificationCooldown: remainingTime });
    callBack(remainingTime);

    intervalId = setInterval(() => {
      const nextRemainingTime = coolDown - 1;
      if (nextRemainingTime <= 0) {
        coolDown = 0;
        callBack(0);
        clearInterval(intervalId);
      } else {
        callBack(nextRemainingTime); // ~~ is roundoff
        coolDown = nextRemainingTime;
      }
    }, 1000);
  }
};

export const setRecentMobileVerification = () => {
  capacitorStorage.setItem(
    LOCAL_STORAGE.recentMobileVerification,
    new Date().valueOf().toString()
  );
};

export const download = (data: any[], fileName: string) => {
  console.log("DOWNLOAD CALLED!!");
  try {
    json2excel({
      data,
      name: fileName,
      formateDate: "yyyy/mm/dd",
    });
  } catch (e) {
    console.error("export error");
  }
};

export const getAppointmentStatus = (
  appointmentDate: Date,
  isCancelled: boolean,
  appointmentStatus: AppointmentStatus[] | undefined
) => {
  return !isCancelled &&
    _.isEmpty(appointmentStatus) &&
    isSameDate(new Date(), moment(appointmentDate).toDate())
    ? "today"
    : isCancelled
    ? "cancelled"
    : !!appointmentStatus &&
      _.includes(
        appointmentStatus.map((status) => status.id),
        APPOINTMENT_STATUS_TYPES.done.id
      )
    ? "done"
    : !!appointmentStatus &&
      !_.includes(
        appointmentStatus.map((status) => status.id),
        APPOINTMENT_STATUS_TYPES.done.id
      )
    ? "scheduled"
    : "booked";
};
