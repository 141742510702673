import _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { IonButton, IonLoading, IonSearchbar, IonToast } from "@ionic/react";

import "./DoctorPatientsList.scss";
import * as services from "../../services";
import { MBDialog } from "../../components/MBDialog/MBDialog";
import { MBAppointmentList } from "../../components/MBAppointmentList/MBAppointmentList";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps, UserType } from "../../interface";
import { HospitalDoctorAppointmentsView, Doctor } from "../../models";
import { MSGS_COMMON } from "../../constants/messages";
import { APPOINTMENT_STATUS_TYPES, MBCOLORS } from "../../constants/config";

class DoctorPatientsList extends React.Component<MBProps> {
  state = {
    loading: true,
    error: "",
    successConfirmArrival: false,

    searchQuery: "",
    doctorAppointments: [] as HospitalDoctorAppointmentsView[],
    filteredAppointments: [] as HospitalDoctorAppointmentsView[],
  };

  componentDidMount = () => {
    this.getHospitalDoctorAppointments();
  };

  getHospitalDoctorAppointments = async () => {
    const { authUser } = this.props;
    try {
      await services.getDoctorAppointmentsPatientOrHospital(
        "doctor",
        authUser.uid,
        (hospitalDoctorAppointments, error) => {
          if (!error) {
            this.setState({
              doctorAppointments: _.filter(
                hospitalDoctorAppointments,
                (appointment) => !appointment.isCancelled
              ),
              loading: false,
            });
            setTimeout(() => {
              this.applyFilters();
            }, 200);
          }
        },
        new Date()
      );
    } catch (error) {
      console.log("Error -- ", error);
    }
  };

  updateAppointmentStatus = async (
    appointmentId: string,
    isDoctorAppointment: boolean
  ) => {
    try {
      const { authUser } = this.props;
      await services.pushAppointmentProgress(
        appointmentId,
        isDoctorAppointment,
        APPOINTMENT_STATUS_TYPES.onGoing.id,
        authUser.uid,
        authUser.userType as UserType
      );
      this.setState({ successConfirmArrival: true });
    } catch (error) {
      this.setState({ error });
    }
  };

  applyFilters = () => {
    const { searchQuery, doctorAppointments } = this.state;

    const filterDoctorAppointments = _.filter(
      doctorAppointments,
      (appointment) => {
        let isValid = true;
        const formattedSearchQuery = searchQuery.trim().toLowerCase();

        if (
          !_.isEmpty(searchQuery) &&
          !appointment.patientDetails.firstName
            .trim()
            .toLocaleLowerCase()
            .includes(formattedSearchQuery)
        ) {
          isValid = false;
        }
        return isValid;
      }
    );

    this.setState({
      filteredAppointments: _.sortBy(filterDoctorAppointments, "distance"),
    });
  };

  render = () => {
    const {
      searchQuery,
      filteredAppointments,
      doctorAppointments,
      successConfirmArrival,
      loading,
      error,
    } = this.state;
    return (
      <>
        <Helmet>
          <title>MedBook - Doctor - Patient List</title>
        </Helmet>
        <MBContainer {...this.props} activePage="search-patients">
          <div className="doctor-patient-list-container">
            <div className="doctor-patient-list-search-card-container">
              <IonSearchbar
                mode="ios"
                placeholder="Search keyword here..."
                value={searchQuery}
                onIonChange={(e) =>
                  this.setState({ searchQuery: e.detail.value })
                }
                className="doctor-patient-list-search-input mb-body ion-no-margin ion-no-padding ion-text-start"
              />

              <IonButton
                className="doctor-patient-list-search-submit mb-body"
                color={MBCOLORS.primary}
                mode="ios"
                disabled={loading}
                onClick={this.applyFilters}
              >
                Search
              </IonButton>
            </div>
            <MBAppointmentList
              mode="patient-list"
              appointmentData={
                !_.isEmpty(filteredAppointments)
                  ? filteredAppointments
                  : doctorAppointments
              }
              onClickView={(appointmentId, isDoctorAppointment) => {
                this.updateAppointmentStatus(
                  appointmentId,
                  isDoctorAppointment
                );
              }}
              onClickCancel={() => {}}
              isDoctorView={true}
            />
          </div>
        </MBContainer>

        <IonLoading
          translucent={true}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />

        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => {
            this.setState({
              error: "",
            });
          }}
          color={MBCOLORS.danger}
        />

        <MBDialog
          isOpen={successConfirmArrival}
          title="You hav successfully admited the patient"
          message="It’s up to you now. We wish you a safe and a blessed medical service."
          icon="success"
          onDidDismiss={() => {
            this.setState({
              successConfirmArrival: false,
            });
          }}
        />
      </>
    );
  };
}

export default DoctorPatientsList;
