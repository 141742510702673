import * as _ from "lodash";
import React, { useState, useRef } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSlides,
  IonSlide,
  IonList,
  IonCardContent,
  IonCard,
  IonButton,
  IonIcon,
  IonLoading,
  IonToast,
  IonModal,
} from "@ionic/react";

import "./HospitalApplicationsList.scss";
import * as services from "../../services";
import { analytics } from "../../firebase";
import { useEffectOnlyOnce, isMobile } from "../../functions/common";
import { Hospital } from "../../models";
import { HospitalApplicationDetails } from "../HospitalApplicationDetails/HospitalApplicationDetails";
import { MBAppointmentCard } from "../MBAppointmentCard/MBAppointmentCard";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBProps } from "../../interface";
import { MSGS_COMMON } from "../../constants/messages";
import { MBCOLORS, ANALYTICS_CONTENT_TYPES } from "../../constants/config";

export const HospitalApplicationsList = (props: MBProps) => {
  const slidesRef: React.RefObject<HTMLIonSlidesElement> = useRef(null);

  const [formPage, setFormPage] = useState(0);
  const [lastSlideUserIsOn, setLastSlideUserIsOn] = useState(
    null as number | null
  );
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [applicationList, setApplicationList] = useState(
    null as Hospital[] | null
  );

  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);
  const [
    isViewApplicationDialogOpen,
    setIsViewApplicationDialogOpen,
  ] = useState(false);
  const [isOpenSaveSuccessDialog, setIsOpenSaveSuccessDialog] = useState(false);

  const [applicationData, setApplicationData] = useState(
    null as Hospital | null
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffectOnlyOnce(() => {
    getHospitalApplications();
  });

  const getHospitalApplications = async () => {
    try {
      setLoading(true);
      services.getHospitalApplications((appointments) => {
        setApplicationList([]);
        setApplicationList(appointments);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  const goToPage = (page: number) => {
    if (!_.isNull(slidesRef.current)) {
      slidesRef.current.lockSwipes(false);
      slidesRef.current.slideTo(page);
      slidesRef.current.lockSwipes(true);

      setLastSlideUserIsOn(page);
    }
  };

  return (
    <MBAppointmentCard
      mode="hospital-applications"
      title="For Approval"
      titleDescription="Hospital Representatives List"
      subtitle=""
      onSubmit={() => {}}
      showActionButton={true}
    >
      {!_.isNull(applicationList) && (
        <IonSlides
          onIonSlideDidChange={async (event) => {
            if (!_.isNull(slidesRef.current)) {
              const activeIndex = await slidesRef.current!.getActiveIndex();
              setFormPage(activeIndex);
            }
          }}
          onIonSlidesDidLoad={async () => {
            if (!_.isNull(slidesRef.current)) {
              slidesRef.current!.lockSwipes(true);
              const activeIndex = await slidesRef.current!.getActiveIndex();
              const lengthOfPage = _.chunk(applicationList, 5).map(
                (appointment) => appointment
              ).length;
              setNumberOfPages(lengthOfPage);
              setFormPage(activeIndex);

              if (!_.isNull(lastSlideUserIsOn)) {
                goToPage(lastSlideUserIsOn);
              }
            }
          }}
          ref={slidesRef}
          key={applicationList
            .map((application) => application.docId || "")
            .join("_")}
        >
          {!_.isEmpty(applicationList) ? (
            _.chunk(_.orderBy(applicationList, "isRejected", "desc"), 5).map(
              (row, index) => {
                return (
                  <IonSlide
                    id="hospital-application-list"
                    className="hospital-application-slide ion-no-padding ion-no-margin"
                    tabIndex={index}
                    key={`row_${index}`}
                  >
                    <IonList className="hospital-application-list">
                      {row.map((hospitalApplication, index) => {
                        return (
                          <IonCard
                            key={`col_${index}`}
                            className="hospital-application-list-card ion-no-margin"
                          >
                            <IonCardContent className="hospital-application-list-card-content ion-no-padding ion-no-margin">
                              <IonGrid className="ion-no-padding ion-no-margin">
                                <IonRow className="ion-no-padding ion-no-margin">
                                  <IonCol
                                    size={`${isMobile() ? "9" : "9.5"}`}
                                    className="hospital-application-list-details-col-container ion-no-padding ion-no-margin"
                                  >
                                    <IonLabel
                                      className={`hospital-application-list-appointment-label ${
                                        isMobile() ? "mb-h3 bold" : "mb-h1"
                                      }`}
                                    >
                                      <u>{hospitalApplication.hospitalName}</u>
                                    </IonLabel>
                                    <IonLabel
                                      className={`hospital-application-list-appointment-label ${
                                        isMobile() ? "mb-h3 bold" : "mb-h1"
                                      }`}
                                    >
                                      {hospitalApplication.department}
                                    </IonLabel>
                                    <IonLabel
                                      className={`hospital-application-list-doctor-hospital-label ${
                                        isMobile() ? "mb-h4 bold" : "mb-h3 bold"
                                      }`}
                                    >
                                      {`${hospitalApplication.firstName} ${hospitalApplication.lastName}`}
                                    </IonLabel>
                                    <IonLabel
                                      className={`hospital-application-list-date-label ${
                                        isMobile() ? "mb-h4" : "mb-h3"
                                      }`}
                                    >
                                      {hospitalApplication.phoneNumber}
                                    </IonLabel>
                                  </IonCol>
                                  <IonCol
                                    size={`${isMobile() ? "3" : "2.5"}`}
                                    className="hospital-application-list-button-col-container"
                                  >
                                    {!hospitalApplication.isRejected ? (
                                      <IonButton
                                        color={MBCOLORS.danger}
                                        className={`hospital-application-list-view-button ${
                                          isMobile()
                                            ? "mb-paragraph bold"
                                            : "mb-body bold"
                                        } ion-text-capitalize`}
                                        onClick={() => {
                                          setApplicationData(
                                            hospitalApplication
                                          );
                                          setIsViewApplicationDialogOpen(true);
                                          analytics.logEvent(
                                            "view_hospital_application",
                                            {
                                              content_type:
                                                ANALYTICS_CONTENT_TYPES
                                                  .supportAccount.type,
                                            }
                                          );
                                        }}
                                      >
                                        View
                                      </IonButton>
                                    ) : (
                                      <IonButton
                                        color={MBCOLORS.danger}
                                        className={`hospital-application-list-view-button ${
                                          isMobile()
                                            ? "mb-h6 bold"
                                            : "mb-body bold"
                                        } ion-text-capitalize`}
                                      >
                                        Rejected
                                      </IonButton>
                                    )}
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonCardContent>
                          </IonCard>
                        );
                      })}
                    </IonList>
                  </IonSlide>
                );
              }
            )
          ) : (
            <div className="hospital-application-list-no-schedule-container">
              <IonLabel className="hospital-application-list-label mb-h3 bold">
                No existing applications
              </IonLabel>
            </div>
          )}
        </IonSlides>
      )}

      {!_.isEmpty(applicationList) && (
        <div className="hospital-application-list-pagination-container">
          <IonIcon
            className="hospital-application-list-previous-icon"
            onClick={() => {
              goToPage(formPage - 1);
            }}
          />
          <IonLabel className="mb-body bold">{`Page ${
            formPage + 1
          } of ${numberOfPages}`}</IonLabel>
          <IonIcon
            className="hospital-application-list-next-icon"
            onClick={() => {
              goToPage(formPage + 1);
            }}
          />
        </div>
      )}
      {!!applicationData && (
        <IonModal
          cssClass="mb-application-details-dialog"
          isOpen={isViewApplicationDialogOpen}
          onDidDismiss={() => {
            setIsViewApplicationDialogOpen(false);
          }}
        >
          <HospitalApplicationDetails
            applicationData={applicationData}
            onCancel={() => {
              setIsViewApplicationDialogOpen(false);
            }}
          />
        </IonModal>
      )}

      <MBDialog
        isOpen={isOpenSuccessDialog}
        icon="success"
        title="Schedule cancelled"
        message="You have successfully cancelled a scheduled appointment. The doctor will be notified of this cancellation."
        onDidDismiss={() => {
          setIsOpenSuccessDialog(false);
        }}
      />
      <MBDialog
        isOpen={isOpenSaveSuccessDialog}
        icon="success"
        title="Schedule updated!"
        message="You have successfully edited your schedule. The doctor/specialist has been notified of your new schedule."
        onDidDismiss={() => {
          setIsOpenSaveSuccessDialog(false);
          setIsViewApplicationDialogOpen(false);
          getHospitalApplications();
        }}
      />

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </MBAppointmentCard>
  );
};
