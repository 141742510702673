import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonLabel,
  IonCardContent,
  IonButton,
  IonIcon,
} from "@ionic/react";

import "./MBCard.scss";
import { isMobile } from "../../functions/common";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBCOLORS } from "../../constants/config";

interface MBCardPropsInterface {
  title?: string;
  titleDescription?: string;
  subtitle?: string;
  children: any;
  onBack: () => void;
  onSubmit: () => void;
  onReject?: () => void;
  hideActionButton?: boolean;
  noHeader?: boolean;
  readonly?: boolean;
  mode?:
    | "transport"
    | "patient"
    | "hospital"
    | "doctor"
    | "patient-support"
    | "tech-admin";
}
export const MBCard = (props: MBCardPropsInterface) => {
  const {
    title,
    titleDescription,
    subtitle,
    children,
    onBack,
    onSubmit,
    onReject,
    hideActionButton,
    noHeader,
    readonly,
    mode,
  } = props;
  return (
    <IonCard
      className={`mb-card ion-no-margin ${mode}`}
      color={MBCOLORS.tertiary}
    >
      {!noHeader && (
        <IonCardHeader
          color={mode === "transport" ? MBCOLORS.secondary : MBCOLORS.tertiary}
          className={`mb-card-header ${mode}`}
        >
          <IonLabel
            className={`mb-body ${
              mode === "transport" ? "dark" : "dark-blue"
            } bold`}
          >
            {titleDescription || ""}
          </IonLabel>
          <IonLabel
            className={`mb-h2 ${mode === "transport" ? "dark" : "dark-blue"}`}
          >
            {title || ""}
          </IonLabel>
          <IonLabel
            className={`mb-body ${mode === "transport" ? "dark" : "dark-blue"}`}
          >
            {subtitle || ""}
          </IonLabel>
          {mode === "transport" && (
            <IonIcon
              className={`mb-card-header-logo magsikilos ${
                isMobile() && "mobile"
              }`}
            />
          )}
        </IonCardHeader>
      )}
      <IonCardContent className="mb-card-content ion-no-padding">
        <div className="mb-content-container">{children}</div>
        <MBDivider />
        {!hideActionButton && (
          <div className={`mb-card-footer ${isMobile() && "mobile"}`}>
            <IonButton
              onClick={onBack}
              className={`mb-back-button ${
                isMobile() && "mobile"
              } mb-body blue ion-text-capitalize`}
              fill="clear"
            >
              Back
            </IonButton>
            {readonly === undefined || !readonly ? (
              onReject === undefined ? (
                <IonButton
                  onClick={onSubmit}
                  className={`mb-submit-button ${
                    isMobile() && "mobile"
                  } mb-body bold white ion-text-capitalize`}
                  color={MBCOLORS.primary}
                >
                  Submit
                </IonButton>
              ) : (
                <div
                  className={`mb-card-approval-container ${
                    isMobile() && "mobile"
                  }`}
                >
                  <IonButton
                    onClick={onReject}
                    className={`mb-submit-button ${
                      isMobile() && "mobile"
                    } mb-body bold white ion-text-capitalize ${
                      isMobile() ? "ion-no-margin" : "ion-margin-end"
                    }`}
                    color={MBCOLORS.danger}
                  >
                    Reject
                  </IonButton>
                  <IonButton
                    onClick={onSubmit}
                    className={`mb-submit-button ${
                      isMobile() && "mobile"
                    } mb-body bold white ion-text-capitalize`}
                    color={MBCOLORS.success}
                  >
                    Approve
                  </IonButton>
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        )}
      </IonCardContent>
    </IonCard>
  );
};
