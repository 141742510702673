import moment from "moment";
import _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { IonLoading } from "@ionic/react";

import "./PatientAppointments.scss";
import * as api from "../../api";
import * as email from "../../functions/email";
import * as sms from "../../functions/sms";
import * as services from "../../services";
import { CancelReasonDialog } from "../../components/CancelReasonDialog/CancelReasonDialog";
import { MBDialog } from "../../components/MBDialog/MBDialog";
import { MBAppointmentList } from "../../components/MBAppointmentList/MBAppointmentList";
import { MBCalendar } from "../../components/MBCalendar/MBCalendar";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBViewAnAppointment } from "../../components/MBViewAnAppointment/MBViewAnAppointment";
import {
  isSameDate,
  isHistoricalDate,
  toDateTimeFromSecs,
} from "../../functions/common";
import { MBProps, AppointmentView, UserType } from "../../interface";
import {
  MBAppointments,
  StatusTab,
} from "../../components/MBAppointments/MBAppointments";
import {
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
  Patient,
} from "../../models";
import { MSGS_COMMON } from "../../constants/messages";
import {
  // ANALYTICS_CONTENT_TYPES,
  APPOINTMENT_STATUS_TYPES,
} from "../../constants/config";
import {
  AppointmentFilterParams,
  AppointmentsFilterDialog,
} from "../../components/AppointmentsFilterDialog/AppointmentsFilterDialog";
// import { analytics } from "firebase";

class PatientAppointments extends React.Component<MBProps> {
  state = {
    viewMode: 2 as AppointmentView,
    patient: {} as Patient,
    status: "today" as StatusTab,
    selectedAppointment: {} as
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView,
    viewAppointment: false,
    loading: true,
    doctorAppointments: [] as HospitalDoctorAppointmentsView[],
    serviceAppointments: [] as HospitalServiceAppointmentsView[],
    filteredAppointments: [] as (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[],
    appointmentViewMode: "list" as "list" | "calendar",
    isOpenCancelDialog: false,
    isOpenCancelSuccessDialog: false,
    isOpenCancelWarningDialog: false,
    selectedDate: new Date(),
    filterDialogOpen: false,
    filterParams: null as null | AppointmentFilterParams,
    appointmentToCancelData: {} as
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView,
  };

  componentDidMount = () => {
    this.getPatientDetails();
    this.getPatientDoctorAppointments();
    this.getPatientServiceAppointments();
  };

  getPatientDetails = () => {
    const { authUser } = this.props;
    services.getPatient(authUser.uid, (patient) => {
      this.setState({ patient });
    });
  };

  getPatientDoctorAppointments = async () => {
    const { authUser } = this.props;
    try {
      await services.getDoctorAppointmentsPatientOrHospital(
        "patient",
        authUser.uid,
        (patientDoctorAppointments, error) => {
          if (!error) {
            this.setState({
              doctorAppointments: patientDoctorAppointments,
              loading: false,
            });
          }
        }
      );
    } catch (error) {
      console.log("error -- patient -- ", error);
    }
  };

  getPatientServiceAppointments = async () => {
    const { authUser } = this.props;
    try {
      await services.getServiceAppointmentsPatientOrHospital(
        "patient",
        authUser.uid,
        (patientServiceAppointments, error) => {
          if (!error) {
            this.setState({
              serviceAppointments: patientServiceAppointments,
            });
          }
        }
      );
    } catch (error) {
      console.log("error -- patient -- ", error);
    }
  };

  getSelectedAppointment = async (
    appointmentId: string,
    isDoctorAppointment: boolean
  ) => {
    this.setState({ loading: true });
    try {
      const selectedAppointment = await services.getAppointment(
        appointmentId,
        isDoctorAppointment
      );
      console.log("selectedAppointment -- ", selectedAppointment);
      this.setState({ loading: false, selectedAppointment });
    } catch (error) {
      console.log("error - getSelectedAppointment - ", error);
    }
  };

  getAppointmentBySelectedStatus = (
    status: StatusTab,
    appointments: (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[]
  ) => {
    return _.filter(appointments, (appointment) => {
      if (status === "today") {
        return (
          !appointment.isCancelled &&
          _.isEmpty(appointment.appointmentStatus) &&
          isSameDate(new Date(), appointment.appointmentDate)
        );
      } else if (status === "cancelled") {
        return appointment.isCancelled === true;
      } else if (status === "done") {
        return _.find(
          appointment.appointmentStatus,
          (status) =>
            status.id === APPOINTMENT_STATUS_TYPES.done.id &&
            !appointment.isCancelled
        );
      } else if (status === "scheduled") {
        return (
          !!appointment.appointmentStatus &&
          !_.includes(
            appointment.appointmentStatus.map((status) => status.id),
            APPOINTMENT_STATUS_TYPES.done.id
          ) &&
          !appointment.isCancelled
        );
      } else {
        // returns all booked data
        return (
          !appointment.isCancelled &&
          _.isEmpty(appointment.appointmentStatus) &&
          !isSameDate(new Date(), appointment.appointmentDate)
        );
      }
    }) as HospitalDoctorAppointmentsView[] | HospitalServiceAppointmentsView[];
  };

  getConsolidatedAppointments = () => {
    const { doctorAppointments, serviceAppointments } = this.state;
    const consolidatedAppointments: (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[] = _.concat(
      doctorAppointments || [],
      (serviceAppointments as (
        | HospitalDoctorAppointmentsView
        | HospitalServiceAppointmentsView
      )[]) || []
    );
    return _.orderBy(consolidatedAppointments, "appointmentDate", "asc");
  };

  cancelAppointment = async (reason: string) => {
    const { appointmentToCancelData } = this.state;
    const { authUser } = this.props;
    const isDoctorAppointment =
      (appointmentToCancelData as HospitalDoctorAppointmentsView).doctorId !==
      undefined;
    //check if the bookeddate is lessthan 24hs

    try {
      this.setState({ loading: true });
      if (!_.isEmpty(appointmentToCancelData)) {
        const hospital = appointmentToCancelData.hospitalDetails;
        const hospitalName = `${hospital.hospitalName} - ${hospital.department}`;
        try {
          if (
            moment(new Date()).diff(
              toDateTimeFromSecs(
                (appointmentToCancelData.createdAt as any).seconds
              ),
              "hours"
            ) < 24
          ) {
            if (
              !!(authUser.userDetails as Patient) &&
              (authUser.userDetails as Patient).cancelCounter <= 2 &&
              (authUser.userDetails as Patient).bookingCredit > 0
            ) {
              const bookingCredit =
                (authUser.userDetails as Patient).bookingCredit - 1;
              // Reset cancel counter to 0, and charged booking
              await services.updatePatient(authUser.uid, {
                cancelCounter: 0,
                bookingCredit,
              });
            } else {
              //increment cancel counter to 1, give free booking 1
              const cancelCounter =
                (authUser.userDetails as Patient).cancelCounter + 1;
              const bookingCredit =
                (authUser.userDetails as Patient).bookingCredit + 1;
              await services.updatePatient(authUser.uid, {
                cancelCounter,
                bookingCredit,
              });
            }
          }
        } catch (error) {
          this.setState({ error });
        }

        if (isDoctorAppointment) {
          const appointment = appointmentToCancelData as HospitalDoctorAppointmentsView;
          await services.cancelAppointment(
            appointment.docId || "",
            "doctor",
            authUser.uid,
            authUser.userType as UserType,
            reason
          );

          try {
            const { subject, message } = email.patientCancelDoctorAppointment(
              `${hospital.firstName} ${hospital.lastName}`,
              hospitalName,
              `${appointment.doctorDetails.firstName} ${appointment.doctorDetails.lastName}`,
              !!appointment.doctorHospitalAssistantDetails
                ? `${appointment.doctorHospitalAssistantDetails.firstName} ${appointment.doctorHospitalAssistantDetails.lastName}`
                : "",
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              moment(appointment.appointmentDate).format("MMM D, YYYY")
            );
            await services.sendEmail(
              appointment.doctorDetails.emailAddress,
              subject,
              message
            );
            await services.sendEmail(hospital.emailAddress, subject, message);
            if (!!appointment.doctorHospitalAssistantDetails) {
              await services.sendEmail(
                appointment.doctorHospitalAssistantDetails.emailAddress,
                subject,
                message
              );
            }
            // const doctorSpecialities = await services.getDoctorSpecialitiesMap([
            //   appointment.doctorId,
            // ]);
            if (
              !!appointment.doctorHospitalAssistantDetails &&
              !_.isEmpty(!!appointment.doctorHospitalAssistantDetails)
            ) {
              const smsMessage = sms.patientCancelConsultationAppointment(
                `${appointment.doctorHospitalAssistantDetails.firstName} ${appointment.doctorHospitalAssistantDetails.lastName}`,
                `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                moment(appointment.appointmentDate).format("MMM D, YYYY")
              );
              await api.sendSMS(
                appointment.doctorHospitalAssistantDetails.phoneNumber,
                smsMessage
              );
            }

            // analytics.logEvent("cancel_patient_appointment", {
            //   content_type: ANALYTICS_CONTENT_TYPES.patientAppointments.type,
            //   reason: reason,
            //   appointment_type:
            //     APPOINTMENT_TYPES[
            //       APPOINTMENT_TYPE_KEYS[appointment.appointmentType]
            //     ].key,
            //   cancer_type:
            //     CANCER_TYPES[CANCER_TYPE_KEYS[appointment.cancerType]].key,
            //   doctor: `${appointment.doctorDetails.firstName} ${appointment.doctorDetails.lastName}`,
            //   hospital: appointment.hospitalDetails.hospitalName,
            //   doctor_specialization: doctorSpecialities[
            //     appointment.doctorId
            //   ].join(),
            // });
            this.setState({
              isOpenCancelSuccessDialog: true,
              loading: false,
              appointmentToCancel: {},
            });
          } catch (e) {
            console.log("error - cancelAppointment Doctor -- ", e);
          }
        } else {
          const appointment = appointmentToCancelData as HospitalServiceAppointmentsView;
          await services.cancelAppointment(
            appointment.docId || "",
            "service",
            authUser.uid,
            authUser.userType as UserType,
            reason
          );
          try {
            const { subject, message } = email.patientCancelServiceAppointment(
              `${hospital.firstName} ${hospital.lastName}`,
              hospitalName,
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              moment(appointment.appointmentDate).format("MMM D, YYYY")
            );
            await services.sendEmail(hospital.emailAddress, subject, message);

            // analytics.logEvent("cancel_patient_appointment", {
            //   content_type: ANALYTICS_CONTENT_TYPES.patientAppointments.type,
            //   reason: reason,
            //   appointment_type:
            //     APPOINTMENT_TYPES[
            //       APPOINTMENT_TYPE_KEYS[appointment.appointmentType]
            //     ].key,
            //   cancer_type:
            //     CANCER_TYPES[CANCER_TYPE_KEYS[appointment.cancerType]].key,
            //   hospital: appointment.hospitalDetails.hospitalName,
            // });
            this.setState({
              isOpenCancelSuccessDialog: true,
              loading: false,
              appointmentToCancel: {},
            });
          } catch (e) {
            console.log("error - cancelAppointment Service -- ", e);
          }
        }
      } else {
        this.setState({
          error: "Error cancelling appointment: No appointment to cancel",
        });
      }
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ error: e, loading: false });
    }
  };

  filterAppointmentList = (
    filterParams: AppointmentFilterParams,
    appointmentList: (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[]
  ) => {
    const {
      isConsultationSelected,
      isTreatmentSelected,
      selectedDoctor,
      selectedService,
      selectedPatient,
      isPastSelected,
      isCancelledSelected,
      isActiveSelected,
    } = filterParams;

    if (
      !!isConsultationSelected ||
      !!isTreatmentSelected ||
      !_.isEmpty(selectedDoctor) ||
      !_.isEmpty(selectedService) ||
      !_.isEmpty(selectedPatient) ||
      !!isPastSelected ||
      !!isCancelledSelected ||
      !!isActiveSelected
    ) {
      let clonedAppointmentList = _.cloneDeep(appointmentList);
      this.setState({ filteredAppointments: filterParams });

      //FILTER BY APPOINTMENT TYPES
      // this means if a single param is selected
      if (
        _.compact([isConsultationSelected, isTreatmentSelected]).length === 1
      ) {
        if (isConsultationSelected) {
          clonedAppointmentList = _.filter(
            clonedAppointmentList,
            (appointment: HospitalDoctorAppointmentsView) =>
              appointment.doctorId !== undefined
          ) as HospitalDoctorAppointmentsView[];
        } else {
          clonedAppointmentList = _.filter(
            clonedAppointmentList,
            (appointment: HospitalServiceAppointmentsView) =>
              appointment.service !== undefined
          ) as HospitalServiceAppointmentsView[];
        }
      }

      //FILTER BY RESOURCE TYPE
      if (!!isConsultationSelected && !_.isEmpty(selectedDoctor)) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) =>
            (appointment as HospitalDoctorAppointmentsView).doctorId !==
              undefined &&
            (appointment as HospitalDoctorAppointmentsView).doctorId ===
              selectedDoctor
        );
      }

      if (!!isTreatmentSelected && !_.isEmpty(selectedService)) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) =>
            (appointment as HospitalServiceAppointmentsView)
              .hospitalServiceDetails !== undefined &&
            (appointment as HospitalServiceAppointmentsView)
              .hospitalServiceDetails.docId === selectedService
        );
      }

      //FILTER BY PATIENT
      if (!_.isEmpty(selectedPatient)) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) => appointment.patientId === selectedPatient
        );
      }

      //FILTER BY DATE
      if (!!isPastSelected) {
        clonedAppointmentList = _.filter(clonedAppointmentList, (appointment) =>
          isHistoricalDate(appointment.appointmentDate)
        );
      }

      //FILTER BY STATUS
      // this means if a single params are selected
      if (_.compact([isCancelledSelected, isActiveSelected]).length === 1) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) => appointment.isCancelled === isCancelledSelected
        );
      }

      this.setState({
        filteredAppointments:
          this.state.appointmentViewMode === "calendar"
            ? _.filter(clonedAppointmentList, (appointment) =>
                isSameDate(appointment.appointmentDate, this.state.selectedDate)
              )
            : clonedAppointmentList,
      });
    } else {
      this.setState({ filterParams: null, filteredAppointments: null });
    }
  };

  render = () => {
    const {
      loading,
      filteredAppointments,
      selectedAppointment,
      appointmentViewMode,
      isOpenCancelDialog,
      isOpenCancelSuccessDialog,
      isOpenCancelWarningDialog,
      status,
      filterDialogOpen,
    } = this.state;

    const { authUser } = this.props;

    return (
      <>
        <Helmet>
          <title>MedBook - Patient Appointments</title>
        </Helmet>
        <MBContainer {...this.props} activePage="patient-appointments">
          <div className="patient-appointments-container">
            {_.isEmpty(selectedAppointment) ? (
              <>
                <MBAppointments
                  onFilterView={() => {
                    this.setState({ filterDialogOpen: true });
                  }}
                  status={status}
                  onClickStatus={(status) => {
                    this.setState({ status: status });
                  }}
                  setSelectedView={(selectedView) => {
                    this.setState({ appointmentViewMode: selectedView });
                    if (selectedView === "list") {
                      this.setState({ filteredAppointments: [] });
                    }
                  }}
                  view={appointmentViewMode}
                >
                  {appointmentViewMode !== "list" && (
                    <MBCalendar
                      appointmentDates={this.getConsolidatedAppointments().map(
                        (appointment) => appointment.appointmentDate
                      )}
                      onDateChange={(date) => {
                        this.setState({
                          selectedDate: date,
                          filteredAppointments: _.filter(
                            this.getConsolidatedAppointments(),
                            (appointment) =>
                              isSameDate(date, appointment.appointmentDate)
                          ),
                        });
                      }}
                    />
                  )}
                  <MBAppointmentList
                    mode="patient"
                    appointmentData={
                      appointmentViewMode === "list"
                        ? this.getAppointmentBySelectedStatus(
                            status,
                            !_.isEmpty(filteredAppointments)
                              ? (filteredAppointments as (
                                  | HospitalDoctorAppointmentsView
                                  | HospitalServiceAppointmentsView
                                )[])
                              : this.getConsolidatedAppointments()
                          )
                        : !_.isEmpty(filteredAppointments)
                        ? (filteredAppointments as (
                            | HospitalDoctorAppointmentsView
                            | HospitalServiceAppointmentsView
                          )[])
                        : _.filter(
                            this.getConsolidatedAppointments(),
                            (appointment) =>
                              isSameDate(
                                appointment.appointmentDate,
                                new Date()
                              )
                          )
                    }
                    onClickCancel={(appointmentId) => {
                      this.setState({
                        ...(!!(authUser.userDetails as Patient).cancelCounter &&
                        (authUser.userDetails as Patient).cancelCounter <= 2 &&
                        moment(new Date()).diff(
                          toDateTimeFromSecs(
                            ((_.find(
                              this.getConsolidatedAppointments(),
                              (appointment) =>
                                appointment.docId === appointmentId
                            ) as
                              | HospitalDoctorAppointmentsView
                              | HospitalServiceAppointmentsView)
                              .createdAt as any).seconds
                          ),
                          "hours"
                        ) < 24
                          ? { isOpenCancelWarningDialog: true }
                          : { isOpenCancelDialog: true }),
                        appointmentToCancelData: _.find(
                          this.getConsolidatedAppointments(),
                          (appointment) => appointment.docId === appointmentId
                        ),
                      });
                    }}
                    onClickView={(appointmentId, isDoctorAppointment) => {
                      this.getSelectedAppointment(
                        appointmentId,
                        isDoctorAppointment
                      );
                    }}
                  />
                </MBAppointments>
              </>
            ) : (
              <div className="patient-appointments-view-container">
                <MBViewAnAppointment
                  {...this.props}
                  mode="patient"
                  appointmentData={selectedAppointment}
                  onClickCancel={(appointmentId) => {
                    this.setState({
                      ...(!!(authUser.userDetails as Patient).cancelCounter &&
                      (authUser.userDetails as Patient).cancelCounter <= 2 &&
                      moment(new Date()).diff(
                        toDateTimeFromSecs(
                          ((_.find(
                            this.getConsolidatedAppointments(),
                            (appointment) => appointment.docId === appointmentId
                          ) as
                            | HospitalDoctorAppointmentsView
                            | HospitalServiceAppointmentsView).createdAt as any)
                            .seconds
                        ),
                        "hours"
                      ) < 24
                        ? { isOpenCancelWarningDialog: true }
                        : { isOpenCancelDialog: true }),
                      appointmentToCancelData: _.find(
                        this.getConsolidatedAppointments(),
                        (appointment) => appointment.docId === appointmentId
                      ),
                    });
                  }}
                  onClickBack={() => {
                    this.setState({
                      selectedAppointment: null,
                      filteredAppointments: null,
                      status: "today",
                    });
                  }}
                />
              </div>
            )}
          </div>
        </MBContainer>
        <MBDialog
          isOpen={isOpenCancelSuccessDialog}
          icon="success"
          title="Schedule cancelled"
          message="You have successfully cancelled a scheduled appointment."
          onDidDismiss={() => {
            this.setState({
              isOpenCancelSuccessDialog: false,
              selectedAppointment: null,
              filteredAppointments: [],
              status: "today",
            });
          }}
        />

        {!_.isEmpty(this.getConsolidatedAppointments()) && (
          <AppointmentsFilterDialog
            mode="patient"
            isOpen={filterDialogOpen}
            doctorOptions={_.filter(
              this.getConsolidatedAppointments(),
              (appointment: HospitalDoctorAppointmentsView) =>
                appointment.doctorId !== undefined
            ).map((appointment) => {
              const doctorAppointment = appointment as HospitalDoctorAppointmentsView;
              return {
                id: doctorAppointment.doctorId,
                name: `${doctorAppointment.doctorDetails.firstName} ${doctorAppointment.doctorDetails.lastName}`,
              };
            })}
            serviceOptions={_.uniqBy(
              _.filter(
                this.getConsolidatedAppointments(),
                (appointment: HospitalServiceAppointmentsView) =>
                  appointment.service !== undefined
              ).map((appointment) => {
                const hospitalAppointment = appointment as HospitalServiceAppointmentsView;
                return {
                  id: hospitalAppointment.hospitalServiceDetails.docId || "",
                  name:
                    hospitalAppointment.hospitalServiceDetails.treatmentName,
                };
              }),
              (service) => service.id
            )}
            hospitalOptions={this.getConsolidatedAppointments()!.map(
              (appointment) => {
                return {
                  id: appointment.hospitalId,
                  name: `${appointment.hospitalDetails.hospitalName} - ${appointment.hospitalDetails.department}`,
                };
              }
            )}
            onSave={(filterParams) => {
              this.filterAppointmentList(
                filterParams,
                this.getConsolidatedAppointments()
              );
              this.setState({ filterDialogOpen: false });
            }}
            onBack={() => {
              this.setState({ filterDialogOpen: false });
            }}
          />
        )}

        <MBDialog
          isOpen={isOpenCancelWarningDialog}
          title="You are about to Cancel your Appointment"
          message="Next Booking will be charged, For Cancelling the 2nd time. However, credit can be seen on My Account as Free Booking."
          onDidDismiss={() => {
            this.setState({ isOpenCancelWarningDialog: false });
          }}
          onApprove={() => {
            this.setState({
              isOpenCancelDialog: true,
              isOpenCancelWarningDialog: false,
            });
          }}
          onDecline={() => {
            this.setState({ isOpenCancelWarningDialog: false });
          }}
        />

        {isOpenCancelDialog && (
          <CancelReasonDialog
            mode="patient-appointment"
            isOpen={isOpenCancelDialog}
            icon="warning"
            title="Please select your reason for cancelling."
            onDidDismiss={() => {
              // setIsViewAppointmentDialogIsOpen(false);
            }}
            onApprove={(reason) => {
              // send reason to email here
              this.cancelAppointment(reason);

              this.setState({ isOpenCancelDialog: false });
            }}
            onDecline={() => {
              // setAppointmentToCancel({} as AppointmentToCancelInterface);
              this.setState({
                isOpenCancelDialog: false,
                appointmentToCancelData: {},
              });
            }}
          />
        )}
        <IonLoading
          translucent={true}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />
      </>
    );
  };
}

export default PatientAppointments;
