import React from "react";

import "./DoctorCreatePassword.scss";
import CommonAuthPageContainer from "../../components/CommonAuthPageContainer/CommonAuthPageContainer";
import { DoctorCreatePasswordForm } from "../../components/DoctorCreatePasswordForm/DoctorCreatePasswordForm";
import { MBProps } from "../../interface";

class DoctorCreatePassword extends React.Component<MBProps> {
  render = () => {
    return (
      <>
        <CommonAuthPageContainer
          {...this.props}
          helmetTitle="MedBook - Doctor Create Password"
          component={<DoctorCreatePasswordForm {...this.props} />}
        />
      </>
    );
  };
}

export default DoctorCreatePassword;
