import _ from "lodash";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonLoading,
  IonModal,
  IonToast,
} from "@ionic/react";

import "./DoctorAppointments.scss";
import * as api from "../../api";
import * as email from "../../functions/email";
import * as sms from "../../functions/sms";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import {
  download,
  getAppointmentStatus,
  isSameDate,
} from "../../functions/common";
import { CancelReasonDialog } from "../../components/CancelReasonDialog/CancelReasonDialog";
import { MBAppointmentList } from "../../components/MBAppointmentList/MBAppointmentList";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBCalendar } from "../../components/MBCalendar/MBCalendar";
import { MBDialog } from "../../components/MBDialog/MBDialog";
import { MBProps, UserType } from "../../interface";
import { MBViewAnAppointment } from "../../components/MBViewAnAppointment/MBViewAnAppointment";
import {
  MBAppointments,
  StatusTab,
} from "../../components/MBAppointments/MBAppointments";
import {
  DoctorAppointmentDetails,
  HospitalDoctorAppointmentsView,
} from "../../models";
import { MSGS_COMMON } from "../../constants/messages";
import {
  MBCOLORS,
  APPOINTMENT_STATUS_TYPES,
  USER_TYPES,
  // ANALYTICS_CONTENT_TYPES,
} from "../../constants/config";
import { MBInput } from "../../components/MBInput/MBInput";
// import { analytics } from "firebase";
import {
  AppointmentFilterParams,
  AppointmentsFilterDialog,
} from "../../components/AppointmentsFilterDialog/AppointmentsFilterDialog";
import { MBCalendarRangeDialog } from "../../components/MBCalendarRangeDialog/MBCalendarRangeDialog";
class DoctorAppointments extends React.Component<MBProps> {
  state = {
    selectedDate: new Date(),
    startDateDownload: null as Date | null,
    endDateDownload: null as Date | null,
    doctorAppointments: [] as HospitalDoctorAppointmentsView[],
    filteredAppointments: [] as HospitalDoctorAppointmentsView[],
    appointmentViewMode: "list" as "list" | "calendar",
    loading: true,
    error: "",
    status: "today" as StatusTab,
    selectedAppointment: {} as HospitalDoctorAppointmentsView,
    viewAppointment: false,
    appointmentToCancelData: {} as HospitalDoctorAppointmentsView,
    appointmentIdToCancel: "",
    filterParams: null as null | AppointmentFilterParams,
    appointmentToMarkDone: {} as HospitalDoctorAppointmentsView,
    showJobWellDone: false,
    initialDiagnosis: "",
    prescription: "",
    errorMsginitialDiagnosis: "",
    errorMsgprescription: "",
    isOpenCancelDialog: false,
    isOpenCancelSuccessDialog: false,
    isOpenClearAllReasonDialog: false,
    isOpenClearSchedule: false,
    isOpenSuccessClearDialog: false,
    isMarkDoneOpen: false,
    filterDialogOpen: false,
    isOpenDownload: false,
    isSuccessDownloadOpen: false,
  };

  checkDoctorHasAppointments = (
    appointments: HospitalDoctorAppointmentsView[]
  ) => {
    this.setState({ hasAppointments: !_.isEmpty(appointments) });
  };

  getAllFutureAppointments = async () => {
    services.getAllDoctorFutureAppointments(
      this.props.authUser.uid,
      (appointments) => {
        this.setState({
          futureAppointments: appointments,
        });
      }
    );
  };

  getSelectedAppointment = async (appointmentId: string) => {
    this.setState({ loading: true });
    const selectedAppointment = await services.getAppointment(
      appointmentId,
      true
    );
    console.log("selectedAppointment -- ", selectedAppointment);
    this.setState({ loading: false, selectedAppointment });
  };

  getDoctorHospitals = async () => {
    const { authUser } = this.props;

    try {
      const doctorHospitals = await services.getDoctorHospitals(authUser.uid);

      const doctorHospitalWithNoInitialSetup = _.find(
        doctorHospitals,
        (doctorHospital) => !doctorHospital.initialSetupDone
      );

      if (!_.isEmpty(doctorHospitalWithNoInitialSetup)) {
        this.props.history.push(routes.DOCTOR_ACCOUNT);
      }
    } catch (e) {
      console.log("GOT ERROR IN getFirstTimeLoginDoctorHostpital", e);
    }
  };

  componentDidMount = () => {
    const { userType } = this.props.authUser;
    if (userType === USER_TYPES.doctors.id) {
      this.getDoctorHospitals();
    }
    // this.getAllFutureAppointments();
    this.getDoctorsAppointments();

    const { appointmentId } = this.props.match.params as {
      appointmentId: string;
    };

    if (!_.isEmpty(appointmentId)) {
      this.getSelectedAppointment(appointmentId);
    }
  };

  getDoctorsAppointments = async () => {
    const { authUser } = this.props;
    if (!!authUser) {
      try {
        let doctorIds = [];
        if (authUser.userType === USER_TYPES.executiveAssistant.id) {
          doctorIds = await services.getExecutiveAssistantDoctorIds(
            authUser.uid
          );
        } else {
          doctorIds.push(authUser.uid);
        }

        await services.getAllDoctorAppointments(
          doctorIds,
          (doctorAppointments, error) => {
            console.log();
            if (!error) {
              this.setState({ doctorAppointments, loading: false });
            }
          }
        );
      } catch (error) {
        console.log("getDoctorAppointmens - error --", error);
      }
    }
  };

  getAppointmentBySelectedStatus = (
    status: StatusTab,
    appointments: HospitalDoctorAppointmentsView[]
  ) => {
    return _.filter(appointments, (appointment) => {
      if (status === "today") {
        return (
          !appointment.isCancelled &&
          _.isEmpty(appointment.appointmentStatus) &&
          isSameDate(new Date(), appointment.appointmentDate)
        );
      } else if (status === "cancelled") {
        return appointment.isCancelled === true;
      } else if (status === "done") {
        return _.find(
          appointment.appointmentStatus,
          (status) =>
            status.id === APPOINTMENT_STATUS_TYPES.done.id &&
            !appointment.isCancelled
        );
      } else if (status === "scheduled") {
        return (
          !!appointment.appointmentStatus &&
          !_.includes(
            appointment.appointmentStatus.map((status) => status.id),
            APPOINTMENT_STATUS_TYPES.done.id
          ) &&
          !appointment.isCancelled
        );
      } else {
        // returns all booked data
        return (
          !appointment.isCancelled &&
          _.isEmpty(appointment.appointmentStatus) &&
          !isSameDate(new Date(), appointment.appointmentDate)
        );
      }
    }) as HospitalDoctorAppointmentsView[];
  };

  filterAppointmentList = (
    filterParams: AppointmentFilterParams,
    appointmentList: HospitalDoctorAppointmentsView[]
  ) => {
    const {
      selectedPatient,
      selectedHospital,
      isCancelledSelected,
      isActiveSelected,
    } = filterParams;

    if (
      !_.isEmpty(selectedPatient) ||
      !_.isEmpty(selectedHospital) ||
      !!isCancelledSelected ||
      !!isActiveSelected
    ) {
      let clonedAppointmentList = _.cloneDeep(appointmentList);
      this.setState({ filterParams });

      //FILTER BY HOSPITAL
      if (!_.isEmpty(selectedHospital)) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) => appointment.hospitalId === selectedHospital
        );
      }

      //FILTER BY PATIENT
      if (!_.isEmpty(selectedPatient)) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) => appointment.patientId === selectedPatient
        );
      }

      //FILTER BY STATUS
      // this means if a single params are selected
      if (_.compact([isCancelledSelected, isActiveSelected]).length === 1) {
        clonedAppointmentList = _.filter(
          clonedAppointmentList,
          (appointment) => appointment.isCancelled === isCancelledSelected
        );
      }

      this.setState({
        filteredAppointments:
          this.state.appointmentViewMode === "calendar"
            ? _.filter(clonedAppointmentList, (appointment) =>
                isSameDate(appointment.appointmentDate, this.state.selectedDate)
              )
            : clonedAppointmentList,
      });
    } else {
      this.setState({ filteredAppointments: null });
    }
  };

  cancelAppointment = async (reason: string) => {
    console.log("this is the reason -- ", reason);
    const { appointmentIdToCancel, appointmentToCancelData } = this.state;
    const { authUser } = this.props;

    console.log(" appointmentToCancelData -- ", appointmentToCancelData);
    try {
      this.setState({ loading: true });

      if (
        !_.isEmpty(appointmentIdToCancel) &&
        !_.isEmpty(appointmentToCancelData)
      ) {
        await services.cancelAppointment(
          appointmentIdToCancel,
          "doctor",
          authUser.uid,
          authUser.userType as UserType,
          reason
        );

        //doctor cancel appointment - patient got free booking
        try {
          const bookingCredit =
            appointmentToCancelData.patientDetails.bookingCredit + 1;
          await services.updatePatient(appointmentToCancelData.patientId, {
            bookingCredit,
          });
        } catch (error) {
          console.log("error -- free booking for patient -- ", error);
          this.setState({ error });
        }

        try {
          if (authUser.userType === USER_TYPES.executiveAssistant.id) {
            //Secretaty Notifiy Patient
            const {
              subject,
              message,
            } = email.secretaryCancelPatientAppointment(
              `${appointmentToCancelData.patientDetails.firstName} ${appointmentToCancelData.patientDetails.lastName}`,
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              moment(appointmentToCancelData.appointmentDate).format(
                "MMM D, YYYY"
              ),
              reason,
              authUser.userDetails.phoneNumber
            );

            await services.sendEmail(
              appointmentToCancelData.patientDetails.emailAddress,
              subject,
              message
            );
            this.setState({
              isOpenCancelSuccessDialog: true,
              appointmentIdToCancel: "",
            });

            //Secretaty Notifiy Hospital and Doctor
            const {
              subject: subjectForHospital,
              message: messageForHospital,
            } = email.secretaryHospitalDoctorCancelPatientAppointment(
              `${appointmentToCancelData.patientDetails.firstName} ${appointmentToCancelData.patientDetails.lastName}`,
              `${appointmentToCancelData.hospitalDetails.hospitalName}`,
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              moment(appointmentToCancelData.appointmentDate).format(
                "MMM D, YYYY"
              ),
              reason,
              authUser.userDetails.phoneNumber
            );

            await services.sendEmail(
              appointmentToCancelData.hospitalDetails.emailAddress,
              subjectForHospital,
              messageForHospital
            );
            await services.sendEmail(
              appointmentToCancelData.doctorDetails.emailAddress,
              subjectForHospital,
              messageForHospital
            );
            const smsMessageSec = sms.secretaryCancelPatientAppointment(
              `${appointmentToCancelData.patientDetails.firstName} ${appointmentToCancelData.patientDetails.lastName}`,
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              moment(appointmentToCancelData.appointmentDate).format(
                "MMM D, YYYY"
              ),
              reason,
              appointmentToCancelData.hospitalDetails.hospitalName,
              appointmentToCancelData.hospitalDetails.phoneNumber
            );

            await api.sendSMS(
              appointmentToCancelData.patientDetails.phoneNumber,
              smsMessageSec
            );
          } else {
            const { subject, message } = email.doctorCancelPatientAppointment(
              `${appointmentToCancelData.patientDetails.firstName} ${appointmentToCancelData.patientDetails.lastName}`,
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              moment(appointmentToCancelData.appointmentDate).format(
                "MMM D, YYYY"
              ),
              reason,
              authUser.userDetails.phoneNumber
            );

            await services.sendEmail(
              appointmentToCancelData.patientDetails.emailAddress,
              subject,
              message
            );
            this.setState({
              isOpenCancelSuccessDialog: true,
              appointmentIdToCancel: "",
            });
            const {
              subject: subjectForHospital,
              message: messageForHospital,
            } = email.doctorHospitalSecretaryCancelPatientAppointment(
              `${appointmentToCancelData.patientDetails.firstName} ${appointmentToCancelData.patientDetails.lastName}`,
              `${appointmentToCancelData.hospitalDetails.hospitalName}`,
              !!appointmentToCancelData.doctorHospitalAssistantDetails
                ? `${appointmentToCancelData.doctorHospitalAssistantDetails.firstName} ${appointmentToCancelData.doctorHospitalAssistantDetails.lastName}`
                : "",
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              moment(appointmentToCancelData.appointmentDate).format(
                "MMM D, YYYY"
              ),
              reason,
              authUser.userDetails.phoneNumber
            );

            await services.sendEmail(
              appointmentToCancelData.hospitalDetails.emailAddress,
              subjectForHospital,
              messageForHospital
            );

            const smsMessageDoctor = sms.doctorCancelPatientAppointment(
              `${appointmentToCancelData.patientDetails.firstName} ${appointmentToCancelData.patientDetails.lastName}`,
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              moment(appointmentToCancelData.appointmentDate).format(
                "MMM D, YYYY"
              ),
              reason,
              authUser.userDetails.phoneNumber
            );
            const smsMessageHospital = sms.doctorHospitalSecretaryCancelPatientAppointment(
              `${appointmentToCancelData.patientDetails.firstName} ${appointmentToCancelData.patientDetails.lastName}`,
              `${appointmentToCancelData.hospitalDetails.hospitalName}`,
              !!appointmentToCancelData.doctorHospitalAssistantDetails
                ? `${appointmentToCancelData.doctorHospitalAssistantDetails.firstName} ${appointmentToCancelData.doctorHospitalAssistantDetails.lastName}`
                : "",
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              moment(appointmentToCancelData.appointmentDate).format(
                "MMM D, YYYY"
              ),
              reason,
              authUser.userDetails.phoneNumber
            );

            await api.sendSMS(
              appointmentToCancelData.patientDetails.phoneNumber,
              smsMessageDoctor
            );
            if (!!appointmentToCancelData.doctorHospitalAssistantDetails) {
              await services.sendEmail(
                appointmentToCancelData.doctorHospitalAssistantDetails
                  .emailAddress,
                subjectForHospital,
                messageForHospital
              );
              await api.sendSMS(
                appointmentToCancelData.doctorHospitalAssistantDetails
                  .phoneNumber,
                smsMessageHospital
              );
            }
          }
        } catch (e) {
          console.log("ERROR SENDING NOTIF: ", e);
        }
        // analytics.logEvent("cancel_doctor_appointment", {
        //   content_type: ANALYTICS_CONTENT_TYPES.doctorAppointments.type,
        // });
      } else {
        this.setState({
          loading: false,
          error: "Error cancelling appointment: No appointment to cancel",
        });
      }

      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  };

  markAppointmentDone = async () => {
    const { appointmentToMarkDone } = this.state;
    const { authUser } = this.props;
    this.setState({ loading: true });
    try {
      await services.pushAppointmentProgress(
        appointmentToMarkDone.docId || "",
        true,
        APPOINTMENT_STATUS_TYPES.done.id,
        authUser.uid,
        authUser.userType as UserType
      );
      this.setState({
        loading: false,
        showJobWellDone: true,
        isMarkDoneOpen: false,
      });
    } catch (error) {
      console.log("error -- mark done -- ", error);
      this.setState({ loading: false, error });
    }
  };
  jobWellDone = async () => {
    const {
      initialDiagnosis,
      prescription,
      appointmentToMarkDone,
    } = this.state;
    const { authUser } = this.props;
    if (authUser.userType === USER_TYPES.doctors.id) {
      if (_.isEmpty(initialDiagnosis) && _.isEmpty(prescription)) {
        this.setState({
          error: "Please indicate diagnosis and prescription, thank you.",
        });
      } else {
        try {
          const prescriptionData = {
            title: initialDiagnosis,
            description: prescription,
          };
          await services.updatePrescription(
            appointmentToMarkDone.docId || "",
            prescriptionData,
            authUser.uid
          );

          this.setState({ loading: false, showJobWellDone: false });
        } catch (error) {
          this.setState({ loading: false, error });
        }
      }
    } else {
      this.setState({ loading: false, showJobWellDone: false });
    }
  };

  downloadSchedules = async (
    startDateDownload: Date,
    endDateDownload: Date
  ) => {
    try {
      this.setState({
        loading: true,
      });
      const dateMin = _.clone(startDateDownload);
      dateMin.setHours(0);
      dateMin.setMinutes(0);
      dateMin.setSeconds(0);
      dateMin.setMilliseconds(0);

      const dateMax = _.clone(
        endDateDownload !== undefined && !_.isNull(endDateDownload)
          ? endDateDownload
          : startDateDownload
      );
      dateMax.setHours(23);
      dateMax.setMinutes(59);
      dateMax.setSeconds(59);
      dateMax.setMilliseconds(59);

      const { doctorAppointments } = this.state;

      const appointments = _.orderBy(
        _.filter(
          doctorAppointments,
          (appointment) =>
            moment(appointment.appointmentDate).isSameOrBefore(dateMax) &&
            moment(appointment.appointmentDate).isSameOrAfter(dateMin)
        ),
        "appointmentDate",
        "asc"
      ) as HospitalDoctorAppointmentsView[];

      if (appointments.length > 0) {
        const formattedAppointments = appointments.map((appointment) => {
          const row = {} as Partial<DoctorAppointmentDetails>;
          row.Patient = `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`;
          row.Contact = `${appointment.patientDetails.phoneNumber} / ${appointment.patientDetails.emailAddress}`;
          row.Date = moment(appointment.appointmentDate)
            .format("MMM D, YYYY")
            .toString();

          row.Status = getAppointmentStatus(
            appointment.appointmentDate,
            appointment.isCancelled,
            appointment.appointmentStatus
          );
          return row as DoctorAppointmentDetails;
        });

        download(
          formattedAppointments,
          `Appointments-${moment(new Date()).format("MMM D, YYYY").toString()}`
        );

        this.setState({
          loading: false,
          isSuccessDownloadOpen: true,
        });
      } else {
        this.setState({
          loading: false,
          error: "Empty appointments, nothing to download",
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  cancelAllAppointments = async (reason: string) => {
    const { selectedDate, doctorAppointments } = this.state;
    const { authUser } = this.props;
    try {
      this.setState({ loading: true });
      const appointments = _.filter(
        doctorAppointments,
        (appointment) =>
          moment(appointment.appointmentDate).isSame(
            moment(selectedDate),
            "days"
          ) && appointment.isCancelled === false
      );

      await Promise.all(
        appointments.map((appointment) => {
          return new Promise(async (resolve) => {
            try {
              if (authUser.userType === USER_TYPES.executiveAssistant.id) {
                //Secretaty Notifiy Patient
                const {
                  subject,
                  message,
                } = email.secretaryCancelPatientAppointment(
                  `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
                  `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                  moment(appointment.appointmentDate).format("MMM D, YYYY"),
                  reason,
                  authUser.userDetails.phoneNumber
                );

                await services.sendEmail(
                  appointment.patientDetails.emailAddress,
                  subject,
                  message
                );
                this.setState({
                  isOpenCancelSuccessDialog: true,
                  appointmentIdToCancel: "",
                });

                //Secretaty Notifiy Hospital and Doctor
                const {
                  subject: subjectForHospital,
                  message: messageForHospital,
                } = email.secretaryHospitalDoctorCancelPatientAppointment(
                  `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
                  `${appointment.hospitalDetails.hospitalName}`,
                  `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                  moment(appointment.appointmentDate).format("MMM D, YYYY"),
                  reason,
                  authUser.userDetails.phoneNumber
                );

                await services.sendEmail(
                  appointment.hospitalDetails.emailAddress,
                  subjectForHospital,
                  messageForHospital
                );
                await services.sendEmail(
                  appointment.doctorDetails.emailAddress,
                  subjectForHospital,
                  messageForHospital
                );
                const smsMessageSec = sms.secretaryCancelPatientAppointment(
                  `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
                  `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                  moment(appointment.appointmentDate).format("MMM D, YYYY"),
                  reason,
                  appointment.hospitalDetails.hospitalName,
                  appointment.hospitalDetails.phoneNumber
                );

                await api.sendSMS(
                  appointment.patientDetails.phoneNumber,
                  smsMessageSec
                );
              } else {
                const {
                  subject,
                  message,
                } = email.doctorCancelPatientAppointment(
                  `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
                  `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                  moment(appointment.appointmentDate).format("MMM D, YYYY"),
                  reason,
                  authUser.userDetails.phoneNumber
                );

                await services.sendEmail(
                  appointment.patientDetails.emailAddress,
                  subject,
                  message
                );

                const {
                  subject: subjectForHospital,
                  message: messageForHospital,
                } = email.doctorHospitalSecretaryCancelPatientAppointment(
                  `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
                  `${appointment.hospitalDetails.hospitalName}`,
                  !!appointment.doctorHospitalAssistantDetails
                    ? `${appointment.doctorHospitalAssistantDetails.firstName} ${appointment.doctorHospitalAssistantDetails.lastName}`
                    : "",
                  `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                  moment(appointment.appointmentDate).format("MMM D, YYYY"),
                  reason,
                  authUser.userDetails.phoneNumber
                );

                await services.sendEmail(
                  appointment.hospitalDetails.emailAddress,
                  subjectForHospital,
                  messageForHospital
                );

                const smsMessageDoctor = sms.doctorCancelPatientAppointment(
                  `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
                  `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                  moment(appointment.appointmentDate).format("MMM D, YYYY"),
                  reason,
                  authUser.userDetails.phoneNumber
                );
                const smsMessageHospital = sms.doctorHospitalSecretaryCancelPatientAppointment(
                  `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
                  `${appointment.hospitalDetails.hospitalName}`,
                  !!appointment.doctorHospitalAssistantDetails
                    ? `${appointment.doctorHospitalAssistantDetails.firstName} ${appointment.doctorHospitalAssistantDetails.lastName}`
                    : "",
                  `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                  moment(appointment.appointmentDate).format("MMM D, YYYY"),
                  reason,
                  authUser.userDetails.phoneNumber
                );

                await api.sendSMS(
                  appointment.patientDetails.phoneNumber,
                  smsMessageDoctor
                );
                if (!!appointment.doctorHospitalAssistantDetails) {
                  await services.sendEmail(
                    appointment.doctorHospitalAssistantDetails.emailAddress,
                    subjectForHospital,
                    messageForHospital
                  );
                  await api.sendSMS(
                    appointment.doctorHospitalAssistantDetails.phoneNumber,
                    smsMessageHospital
                  );
                }
              }

              // analytics.logEvent("cancel_all_doctor_appointments", {
              //   content_type: ANALYTICS_CONTENT_TYPES.doctorAppointments.type,
              // });
              resolve();
            } catch (e) {
              this.setState({ error: e });
              resolve();
            }
          });
        })
      );

      await Promise.all(
        appointments.map((appointment) => {
          return services.cancelAppointment(
            appointment.docId as string,
            "doctor",
            authUser.uid,
            authUser.userType as UserType,
            reason
          );
        })
      );

      await Promise.all(
        appointments.map((appointment, index) => {
          const bookingCredit =
            appointment.patientDetails.bookingCredit + index + 1;
          return services.updatePatient(appointment.patientId, {
            bookingCredit,
          });
        })
      );

      this.setState({ isOpenSuccessClearDialog: true, loading: false });
    } catch (e) {
      this.setState({ error: e, loading: false });
    }
  };

  render = () => {
    const {
      doctorAppointments,
      selectedDate,
      startDateDownload,
      endDateDownload,
      selectedAppointment,
      filteredAppointments,
      appointmentViewMode,
      loading,
      isOpenCancelDialog,
      isOpenCancelSuccessDialog,
      isMarkDoneOpen,
      filterDialogOpen,
      appointmentToMarkDone,
      showJobWellDone,
      error,
      status,
      initialDiagnosis,
      prescription,
      errorMsginitialDiagnosis,
      errorMsgprescription,
      isOpenClearAllReasonDialog,
      isOpenClearSchedule,
      isOpenSuccessClearDialog,
      isOpenDownload,
      isSuccessDownloadOpen,
    } = this.state;

    const { userType } = this.props.authUser;

    console.log("this should query the appointments based on -- ", status);
    return (
      <>
        <Helmet>
          <title>MedBook - Doctor Appointments</title>
        </Helmet>
        <MBContainer
          {...this.props}
          activePage={
            userType === USER_TYPES.doctors.id
              ? "doctor-appointments"
              : "executive-assistant-appointments"
          }
        >
          <div className="doctor-appointments-container">
            {_.isEmpty(selectedAppointment) ? (
              <>
                <MBAppointments
                  onFilterView={() => {
                    this.setState({ filterDialogOpen: true });
                  }}
                  onDownloadSchedule={() => {
                    this.setState({ isOpenDownload: true });
                  }}
                  onClearSchedule={() => {
                    this.setState({ isOpenClearSchedule: true });
                  }}
                  status={status}
                  onClickStatus={(status) => {
                    this.setState({ status: status });
                  }}
                  setSelectedView={(selectedView) => {
                    this.setState({ appointmentViewMode: selectedView });
                    if (selectedView === "list") {
                      this.setState({ filteredAppointments: [] });
                    } else {
                      this.setState({
                        filteredAppointments: _.filter(
                          doctorAppointments,
                          (appointment) =>
                            isSameDate(appointment.appointmentDate, new Date())
                        ),
                      });
                    }
                  }}
                  view={appointmentViewMode}
                >
                  {appointmentViewMode !== "list" && (
                    <MBCalendar
                      appointmentDates={doctorAppointments.map(
                        (appointment) => appointment.appointmentDate
                      )}
                      onDateChange={(date) => {
                        this.setState({
                          selectedDate: date,
                          filteredAppointments: _.filter(
                            doctorAppointments,
                            (appointment) =>
                              isSameDate(
                                date,

                                appointment.appointmentDate
                              )
                          ),
                        });
                      }}
                    />
                  )}
                  {console.log(
                    "filteredAppointments -------- ",
                    filteredAppointments
                  )}
                  <MBAppointmentList
                    mode={
                      userType === USER_TYPES.doctors.id ? "doctor" : "hospital"
                    }
                    appointmentData={
                      appointmentViewMode === "list"
                        ? _.orderBy(
                            this.getAppointmentBySelectedStatus(
                              status,
                              !_.isEmpty(filteredAppointments)
                                ? filteredAppointments
                                : doctorAppointments
                            ),
                            "appointmentDate",
                            "asc"
                          )
                        : _.orderBy(
                            _.filter(
                              !_.isEmpty(filteredAppointments)
                                ? filteredAppointments
                                : doctorAppointments,
                              (appointment) =>
                                isSameDate(
                                  appointment.appointmentDate,
                                  selectedDate
                                )
                            ),
                            "appointmentDate",
                            "asc"
                          )
                    }
                    onClickView={(appointmentId) => {
                      this.getSelectedAppointment(appointmentId);
                    }}
                    onClickCancel={(appointmentId) => {
                      this.setState({
                        isOpenCancelDialog: true,
                        appointmentIdToCancel: appointmentId,
                        appointmentToCancelData: _.find(
                          doctorAppointments,
                          (appointment) => appointment.docId === appointmentId
                        ),
                        selectedAppointment: null,
                        filteredAppointments: [],
                      });
                    }}
                  />
                </MBAppointments>
              </>
            ) : (
              <div className="doctor-appointments-view-container">
                <MBViewAnAppointment
                  {...this.props}
                  mode={
                    userType === USER_TYPES.doctors.id ? "doctor" : "hospital"
                  }
                  appointmentData={selectedAppointment}
                  onClickCancel={(appointmentId) => {
                    this.setState({
                      isOpenCancelDialog: true,
                      appointmentIdToCancel: appointmentId,
                      appointmentToCancelData: _.find(
                        doctorAppointments,
                        (appointment) => appointment.docId === appointmentId
                      ),
                    });
                  }}
                  onMarkAsDone={(appointmentId) => {
                    this.setState({
                      isMarkDoneOpen: true,
                      appointmentToMarkDone: _.find(
                        doctorAppointments,
                        (appointment) => appointment.docId === appointmentId
                      ),
                    });
                  }}
                  onClickBack={() => {
                    this.setState({
                      selectedAppointment: null,
                      filteredAppointments: null,
                      status: "today",
                    });
                  }}
                />
              </div>
            )}
          </div>
          {isOpenCancelDialog && (
            <CancelReasonDialog
              mode="appointment"
              isOpen={isOpenCancelDialog}
              icon="warning"
              title="Please select your reason for cancelling."
              message="Cancelling impacts patient experience"
              onDidDismiss={() => {
                this.setState({ isOpenCancelDialog: false });
              }}
              onApprove={(reason) => {
                // send reason to email here
                this.cancelAppointment(reason);

                this.setState({ isOpenCancelDialog: false });
              }}
              onDecline={() => {
                this.setState({
                  isOpenCancelDialog: false,
                  appointmentToCancelData: {},
                });
              }}
            />
          )}
          {isOpenDownload && (
            <MBCalendarRangeDialog
              icon="download"
              isOpen={isOpenDownload}
              title="Please set the duration of the schedule you want to download"
              errorMessage="You need to define date range to download schedules"
              hasEasyOptions={true}
              onDidDismiss={() => {
                this.setState({
                  isOpenDownload: false,
                });
              }}
              onSubmit={(startDate, endDate) => {
                this.setState({
                  startDateDownload: startDate,
                  endDateDownload: endDate,
                });
                this.downloadSchedules(startDate!, endDate!);
                this.setState({
                  isOpenDownload: false,
                });
              }}
              onCancel={() => {
                this.setState({
                  isOpenDownload: false,
                });
              }}
            />
          )}
          {!!isOpenClearAllReasonDialog && (
            <CancelReasonDialog
              mode="appointment"
              isOpen={isOpenClearAllReasonDialog}
              icon="warning"
              title="Please select your reason for cancelling."
              message="Cancelling impacts patient experience"
              onDidDismiss={() => {
                this.setState({ isOpenClearAllReasonDialog: false });
              }}
              onApprove={(reason) => {
                this.cancelAllAppointments(reason);
                this.setState({ isOpenClearAllReasonDialog: false });
              }}
              onDecline={() => {
                this.setState({ isOpenClearAllReasonDialog: false });
              }}
            />
          )}
          <MBDialog
            isOpen={isOpenClearSchedule}
            icon="warning"
            title={`You are about to clear all your schedules for ${moment(
              selectedDate
            ).format("MMM DD, YYYY")}`}
            message="Are you sure you want to clear all your schedules?"
            onDidDismiss={() => {
              this.setState({ isOpenClearSchedule: false });
            }}
            onApprove={() => {
              this.setState({ isOpenClearSchedule: false });
              this.setState({ isOpenClearAllReasonDialog: true });
            }}
            onDecline={() => {
              this.setState({ isOpenClearSchedule: false });
            }}
          />
          <MBDialog
            isOpen={isOpenSuccessClearDialog}
            icon="success"
            title="Schedule cleared!"
            message={`You have successfully cancelled all scheduled appointments for ${moment(
              selectedDate
            ).format(
              "MMM DD, YYYY"
            )}. All affected patients will be notified of this cancellation.`}
            onDidDismiss={() => {
              this.setState({
                isOpenSuccessClearDialog: false,
                status: "today",
                selectedAppointment: null,
                filteredAppointments: [],
              });
            }}
          />
          {!!appointmentToMarkDone && !_.isEmpty(appointmentToMarkDone) && (
            <IonModal
              isOpen={showJobWellDone}
              cssClass="doctor-job-well-done-dialog"
              onDidDismiss={() => {
                this.setState({
                  showJobWellDone: false,
                  appointmentToMarkDone: {},
                  initialDiagnosis: "",
                  prescription: "",
                  selectedAppointment: null,
                  filteredAppointments: null,
                  status: "today",
                });
              }}
            >
              <div className="doctor-job-well-done-container">
                <IonLabel className="doctor-job-well-done-label mb-h1 dark-blue ion-no-padding ion-no-margin">
                  Job Well Done
                </IonLabel>
                <IonLabel className="doctor-job-well-done-label mb-body ion-no-padding ion-no-margin">
                  You have successfully completed an appointment.{" "}
                  {userType === USER_TYPES.doctors.id &&
                    "Please leave a diagnosis and prescription to the patient for documentation purposes"}
                </IonLabel>
                <IonIcon className="doctor-job-well-done-icon ion-no-padding ion-no-margin" />
                <IonLabel className="doctor-job-well-done-label mb-h4 dark-blue bold ion-no-padding ion-no-margin">{`${appointmentToMarkDone.patientDetails.firstName} ${appointmentToMarkDone.patientDetails.lastName}`}</IonLabel>
                <IonLabel className="doctor-job-well-done-label mb-body ion-no-padding ion-no-margin">
                  {`Booking Id: ${appointmentToMarkDone.docId} scheduled on `}
                  <b>
                    {moment(appointmentToMarkDone.appointmentDate).format(
                      "MMMM DD, YYYY HH:mm A"
                    )}
                  </b>
                </IonLabel>
                {userType === USER_TYPES.doctors.id && (
                  <>
                    <MBInput
                      className="doctor-job-well-done-input"
                      type="text"
                      value={initialDiagnosis}
                      label="Initial Diagnosis"
                      onChange={(initialDiagnosis) => {
                        this.setState({ initialDiagnosis });
                      }}
                      error={errorMsginitialDiagnosis}
                    />
                    <MBInput
                      className="doctor-job-well-done-input"
                      type="text"
                      value={prescription}
                      label="Prescription"
                      onChange={(prescription) => {
                        this.setState({ prescription });
                      }}
                      error={errorMsgprescription}
                    />
                  </>
                )}
                <IonButton
                  mode="ios"
                  className="doctor-job-well-done-button"
                  onClick={() => {
                    this.jobWellDone();
                  }}
                >
                  Done
                </IonButton>
              </div>
            </IonModal>
          )}
          <MBDialog
            isOpen={isMarkDoneOpen}
            icon="success"
            title="Are you sure you want to mark this appointment as done?"
            message="This appointment will be tagged as complete once you click Proceed."
            onApprove={() => {
              //call to update mark as done
              // show job well done dialog
              this.markAppointmentDone();
            }}
            onDecline={() => {
              this.setState({
                isMarkDoneOpen: false,
              });
            }}
            onDidDismiss={() => {
              this.setState({
                isMarkDoneOpen: false,
              });
            }}
          />
          <MBDialog
            isOpen={isOpenCancelSuccessDialog}
            icon="success"
            title="Schedule cancelled"
            message="You have successfully cancelled a scheduled appointment. The patient will be notified of this cancellation."
            onDidDismiss={() => {
              this.setState({
                status: "today",
                isOpenCancelSuccessDialog: false,
                selectedAppointment: null,
                filteredAppointments: [],
              });
            }}
          />
          <MBDialog
            isOpen={isSuccessDownloadOpen}
            icon="success"
            title="Download complete"
            message={`Please check your downloads folder for the offline file of the schedules from ${moment(
              startDateDownload
            ).format("MMM DD, YYYY")} to ${moment(endDateDownload).format(
              "MMM DD, YYYY"
            )}`}
            onDidDismiss={() => {
              this.setState({
                isSuccessDownloadOpen: false,
              });
            }}
            onClick={() => {
              this.setState({
                isSuccessDownloadOpen: false,
              });
            }}
          />
          {!_.isEmpty(doctorAppointments) && (
            <AppointmentsFilterDialog
              mode="doctor"
              isOpen={filterDialogOpen}
              patientOptions={doctorAppointments!.map((appointment) => {
                return {
                  id: appointment.patientId,
                  name: `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`,
                };
              })}
              hospitalOptions={doctorAppointments!.map((appointment) => {
                return {
                  id: appointment.hospitalId,
                  name: appointment.hospitalDetails.hospitalName,
                };
              })}
              onSave={(filterParams) => {
                this.filterAppointmentList(filterParams, doctorAppointments!);
                // analytics.logEvent("filter_doctor_appointments", {
                //   content_type: ANALYTICS_CONTENT_TYPES.doctorAppointments.type,
                // });
                this.setState({ filterDialogOpen: false });
              }}
              onBack={() => {
                this.setState({ filterDialogOpen: false });
              }}
            />
          )}
          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />
          <IonToast
            isOpen={!_.isEmpty(error)}
            message={error}
            duration={2000}
            onDidDismiss={() => {
              this.setState({
                error: "",
              });
            }}
            color={MBCOLORS.danger}
          />
        </MBContainer>
      </>
    );
  };
}

export default DoctorAppointments;
