/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonCard,
  IonCardHeader,
  IonItem,
  IonInput,
  IonButton,
} from "@ionic/react";

import "./HospitalAccountForm.scss";
import * as services from "../../services";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import {
  formatString,
  useEffectOnlyOnce,
  isMobile,
} from "../../functions/common";
import { Hospital } from "../../models";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import {
  LIMITS,
  MBCOLORS,
  ANALYTICS_CONTENT_TYPES,
} from "../../constants/config";
import {
  MSGS_COMMON,
  MSGS_SIGNUP,
  MSGS_COMMON_FORM_ERROR,
} from "../../constants/messages";
import { analytics } from "../../firebase";

interface HospitalAccountFormProps extends MBProps {
  onBack: () => void;
}
export const HospitalAccountForm = (props: HospitalAccountFormProps) => {
  const { authUser, history, onBack } = props;

  const hospital = authUser.userDetails as Hospital;

  const [firstName, setFirstName] = useState(
    !!hospital && hospital.firstName ? hospital.firstName : ""
  );
  const [lastName, setLastName] = useState(
    !!hospital && hospital.lastName ? hospital.lastName : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    !!hospital && hospital.phoneNumber ? hospital.phoneNumber : ""
  );
  const [emailAddress, setEmailAddress] = useState(
    !!hospital && hospital.emailAddress ? hospital.emailAddress : ""
  );

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("");
  const [errorMsgEmaillAddress, setErrorMsgEmaillAddress] = useState("");

  const [isOpenSuccessProfileUpdate, setIsOpenSuccessProfileUpdate] = useState(
    false
  );
  const [isOpenSendChangePassword, setIsOpenSendChangePassword] = useState(
    false
  );

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  useEffectOnlyOnce(() => {
    if (!_.isEmpty(authUser)) {
      setEmailAddress(authUser.authEmail);
    }
  });

  const onSubmit = async () => {
    setLoading(true);
    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedPhoneNumber = formatString((phoneNumber || "") as string);

    let errorMessage = null;
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_SIGNUP.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.firstName.max;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_SIGNUP.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedPhoneNumber.length === 0) {
      errorMessage = MSGS_SIGNUP.mobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (!validatePhoneNumber(formattedPhoneNumber)) {
      errorMessage = MSGS_SIGNUP.invalidMobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_SIGNUP.email.required;
      setErrorMsgEmaillAddress(errorMessage);
    }
    if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_SIGNUP.email.invalid;
      setErrorMsgEmaillAddress(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      const hospitalRepDetail = _.cloneDeep(hospital);
      const updatedHospitalRepDetail = {
        ...hospitalRepDetail,
        firstName: formattedFirstName,
        lastName: formattedLastName,
        phoneNumber: formattedPhoneNumber,
      } as Partial<Hospital>;
      try {
        await services.updateHospitalRepresentative(
          hospital.docId || "",
          updatedHospitalRepDetail
        );
        analytics.logEvent("edit_hospital_rep_account", {
          content_type: ANALYTICS_CONTENT_TYPES.hospitalRepAccount.type,
        });
        setLoading(false);
        setIsOpenSuccessProfileUpdate(true);
      } catch (error) {
        console.log("error - updateHospitalRepresentative -- ", error);
        setError(error);
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  };

  const onChangePassword = async () => {
    let error = "";
    if (_.isEmpty(emailAddress)) {
      error = MSGS_COMMON_FORM_ERROR.emailAddress;
      setErrorMsgEmaillAddress(error);
    }

    if (!error) {
      setIsOpenSendChangePassword(true);
      try {
        await services.doSendResetPasswordEmail(emailAddress);
        analytics.logEvent("edit_hospital_rep_password", {
          content_type: ANALYTICS_CONTENT_TYPES.hospitalRepAccount.type,
        });
      } catch (error) {
        console.log("Error -- doSendResetPasswordEmail -- ", error);
      }
    }
  };

  return (
    <>
      <IonCard
        className="hospital-rep-card ion-no-margin"
        color={MBCOLORS.tertiary}
      >
        <IonCardHeader className="hospital-rep-card-header">
          <IonLabel className="mb-h2 ion-text-start">Edit Information</IonLabel>
          <IonLabel className="mb-body ion-text-start">
            Displayed below are the information you provided. Click edit to edit
            this information.
          </IonLabel>
        </IonCardHeader>
        <form autoComplete="nope...">
          <div className="hospital-rep-form-container">
            <div className="hospital-rep-form-description-container"></div>
            <IonGrid className="hospital-rep-form-container-grid ion-no-padding">
              {_.chunk(
                [
                  <IonCol
                    className="hospital-rep-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="First name"
                      value={firstName}
                      type="text"
                      error={errorMsgFirstName}
                      onChange={(firstName) => {
                        setFirstName(firstName);
                        setErrorMsgFirstName("");
                      }}
                    />
                  </IonCol>,
                  <IonCol
                    className="hospital-rep-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="Last name"
                      value={lastName}
                      type="text"
                      error={errorMsgLastName}
                      onChange={(lastName) => {
                        setLastName(lastName);
                        setErrorMsgLastName("");
                      }}
                    />
                  </IonCol>,
                  <IonCol
                    className="hospital-rep-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="Mobile Number"
                      value={phoneNumber}
                      type="tel"
                      error={errorMsgPhoneNumber}
                      onChange={(phoneNumber) => {
                        setPhoneNumber(phoneNumber);
                        setErrorMsgPhoneNumber("");
                      }}
                    />
                  </IonCol>,
                  <IonCol
                    className="hospital-rep-form-container-col ion-no-padding"
                    size="6"
                  >
                    <MBInput
                      label="Email Address"
                      value={emailAddress}
                      type="email"
                      error={errorMsgEmaillAddress}
                      onChange={(emailAddress) => {
                        setEmailAddress(emailAddress);
                        setErrorMsgEmaillAddress("");
                      }}
                      readonly={true} //last priority - implement the email change and verification
                    />
                  </IonCol>,
                ],
                !isMobile() ? 2 : 1
              ).map((row, index) => {
                return (
                  <IonRow>
                    {row.map((col) => {
                      return (
                        <IonCol
                          className="hospital-rep-form-container-col ion-no-padding"
                          size={(12 / row.length).toString()}
                        >
                          {col}
                        </IonCol>
                      );
                    })}
                  </IonRow>
                );
              })}

              <IonRow>
                <IonCol
                  className="hospital-rep-form-container-col last ion-no-padding"
                  size="12"
                >
                  <IonItem
                    lines="none"
                    className="hospital-rep-form-password-item ion-no-padding ion-no-margin"
                    color={MBCOLORS.tertiary}
                  >
                    <IonLabel
                      position="stacked"
                      className="mb-paragraph bold ion-no-padding ion-no-margin"
                    >
                      Password
                    </IonLabel>
                    <IonInput
                      type="password"
                      className="hospital-rep-form-password-input ion-no-padding ion-no-margin"
                      autocomplete="off"
                      value={hospital.emailAddress} //fake password changing password will send an Email
                      readonly={true}
                    >
                      <IonButton
                        color={MBCOLORS.primary}
                        className="hospital-rep-form-change-password-button sv-paragraph ion-no-margin ion-text-capitalize"
                        onClick={onChangePassword}
                      >
                        Edit Password
                      </IonButton>
                    </IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </form>
        <MBDivider />
        <div className="hospital-rep-card-footer">
          <IonButton
            onClick={() => {
              onBack();
            }}
            className="hospital-rep-cancel-button mb-body ion-text-capitalize"
            fill="clear"
          >
            Back
          </IonButton>

          <div className="hospital-rep-submit-container">
            <IonButton
              onClick={() => {
                onSubmit();
              }}
              className="hospital-rep-submit-button mb-body bold white ion-text-capitalize"
              color={MBCOLORS.primary}
            >
              Save
            </IonButton>
          </div>
        </div>
      </IonCard>
      <MBDialog
        isOpen={isOpenSendChangePassword}
        title="Email Sent"
        message={`We have sent you an email to ${emailAddress} Click the link to change you account password.`}
        icon="success"
        onDidDismiss={() => {
          setIsOpenSendChangePassword(false);
        }}
      />
      <MBDialog
        isOpen={isOpenSuccessProfileUpdate}
        title="Personal Profile Updated"
        message="You have successfully updated your personal profile. This will now reflect on your profile details."
        icon="success"
        onDidDismiss={() => {
          setIsOpenSuccessProfileUpdate(false);
          onBack();
        }}
      />

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={2000}
        onDidDismiss={() => setSuccess("")}
        color={MBCOLORS.success}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
