import * as _ from "lodash";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonLabel,
  IonButton,
  IonToast,
  IonLoading,
} from "@ionic/react";

import "./LoginForm.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { isMobile } from "../../functions/common";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import { MBCOLORS } from "../../constants/config";
import { MSGS_COMMON_FORM_ERROR, MSGS_COMMON } from "../../constants/messages";

export const LoginForm = (props: MBProps) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const login = async (event: React.FormEvent) => {
    event.preventDefault();
    let error;
    setLoading(true);
    if (_.isEmpty(emailAddress)) {
      error = MSGS_COMMON_FORM_ERROR.emailAddress;
      setErrorEmail(error);
    } else if (_.isEmpty(password)) {
      error = MSGS_COMMON_FORM_ERROR.password;
      setErrorPassword(error);
    }

    if (!error) {
      try {
        await services.signIn(emailAddress, password);
        setLoading(false);
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <IonCard
      className={`login-form-card ${isMobile() ? "mobile" : ""} ion-no-margin`}
      color={MBCOLORS.tertiary}
    >
      <IonCardContent className="login-form-card-content ion-no-padding">
        <IonIcon
          className={`login-form-card-logo ${isMobile() ? "mobile" : ""}`}
        />
        <div className="login-form-details">
          <IonLabel className="mb-h2 bold">Email Login</IonLabel>
          <form onSubmit={login}>
            <div className="login-form-input-container">
              <div className="login-form-email-input">
                <MBInput
                  type="email"
                  label="Email"
                  value={emailAddress}
                  onChange={(emailAddress: string) => {
                    setEmailAddress(emailAddress);
                    setErrorEmail("");
                  }}
                  error={errorEmail}
                />
              </div>
              <div className="login-form-password-input">
                <MBInput
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(password: string) => {
                    setPassword(password);
                    setErrorPassword("");
                  }}
                  error={errorPassword}
                />
              </div>

              <a
                className="login-form-forgot-password-link"
                href={routes.FORGOT_PASSWORD}
              >
                <IonLabel className="login-form-forgot-password mb-h4 blue">
                  Forgot your password?
                </IonLabel>
              </a>
            </div>

            <IonButton
              color={MBCOLORS.primary}
              className="login-form-login-button ion-text-capitalize"
              type="submit"
            >
              Login
            </IonButton>
            <IonButton
              fill="clear"
              color={MBCOLORS.primary}
              className="login-form-register-button ion-text-capitalize"
              type="submit"
              routerLink={routes.CREATE_WITH_EMAIL_ACCOUNT_SETUP}
            >
              Register
            </IonButton>
          </form>
          <IonLabel className="login-form-notice-label mb-paragraph light">
            {"By clicking Login I hereby agree and consent to the "}
            <Link
              className="link-user-agreement"
              to={routes.TERMS_CONDITION}
              target="_blank"
            >
              User Agreement
            </Link>
            .
          </IonLabel>
        </div>
      </IonCardContent>

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </IonCard>
  );
};
