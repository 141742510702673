import moment from "moment";
import * as _ from "lodash";
import { Helmet } from "react-helmet";
import React from "react";
import { IonLoading, IonToast } from "@ionic/react";

import "./HospitalScan.scss";
import * as services from "../../services";
import {
  Hospital,
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
} from "../../models";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps, UserType } from "../../interface";
import { MBTimeKeepingScan } from "../../components/MBTimeKeepingScan/MBTimeKeepingScan";
import { MBQRresultDialog } from "../../components/MBQRresultDialog/MBQRresultDialog";
import { MSGS_COMMON } from "../../constants/messages";
import { APPOINTMENT_STATUS_TYPES, MBCOLORS } from "../../constants/config";

class HospitalScan extends React.Component<MBProps> {
  state = {
    loading: false,
    isResultFound: false,
    qrResultOpenDialog: false,
    selected: 0,
    successConfirmArrival: false,
    qrAppointment: {} as
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView,
    isDoctorAppointment: false,
    error: "",
  };

  getAppointment = async (qrAppointmentId: string) => {
    try {
      const userDetail = this.props.authUser.userDetails as Hospital;
      const qrResult = await services.getAppointment(
        qrAppointmentId,
        null,
        userDetail.docId
      );

      const isResultFound = !_.isEmpty(qrResult);

      if (isResultFound) {
        this.setState({
          isDoctorAppointment: !_.isEmpty(
            (qrResult as HospitalDoctorAppointmentsView).doctorDetails
          ),
        });
      }

      this.setState({
        isResultFound,
        qrResultOpenDialog: true,
        qrAppointment: qrResult,
      });
    } catch (e) {
      this.setState({
        isResultFound: false,
        qrResultOpenDialog: true,
        qrAppointment: {},
      });
    }
  };

  updateAppointmentStatus = async (appointmentId: string) => {
    try {
      const { authUser } = this.props;
      await services.pushAppointmentProgress(
        appointmentId,
        this.state.isDoctorAppointment,
        APPOINTMENT_STATUS_TYPES.onGoing.id,
        authUser.uid,
        authUser.userType as UserType
      );
      this.setState({ successConfirmArrival: true });
    } catch (error) {
      this.setState({ error });
    }
  };

  render = () => {
    const {
      loading,
      isResultFound,
      qrResultOpenDialog,
      qrAppointment,
      successConfirmArrival,
      error,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>MedBook - Hospital - Scan Code</title>
        </Helmet>
        <MBContainer {...this.props} activePage="hospital-scan">
          <div className="hospital-scan-container">
            <MBTimeKeepingScan
              mode="hospital"
              isResultFound={isResultFound}
              onQRScan={(qrResult) => {
                this.getAppointment(qrResult);
              }}
            />
          </div>
          <MBQRresultDialog
            mode="hospital"
            isOpen={qrResultOpenDialog}
            onDidDismiss={() => {
              this.setState({
                qrResultOpenDialog: false,
                isResultFound: false,
              });
            }}
            qrData={qrAppointment}
            successConfirmArrival={successConfirmArrival}
            onDidDismissSuccessConfirm={() => {
              this.setState({
                successConfirmArrival: false,
                qrResultOpenDialog: false,
                isResultFound: false,
              });
            }}
            onConfirmArrival={(appointmentId: string) => {
              this.updateAppointmentStatus(appointmentId);
            }}
          />
          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />
          <IonToast
            isOpen={!_.isEmpty(error)}
            message={error}
            duration={2000}
            onDidDismiss={() => this.setState({ error: "" })}
            color={MBCOLORS.danger}
          />
        </MBContainer>
      </>
    );
  };
}

export default HospitalScan;
