import rp from "request-promise";

import { getEnv } from "../env";
import { getRequestHeaders } from "../functions/common";
import { Hospital } from "../models";

var env = getEnv();

let url = env.firebase.cloudFunctionsUrl;

export const getHospitalServices = async (hospitalId: string) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "GET",
    url: url + `hospital/getHospitalServices/${hospitalId}`,
    headers,
    json: true,
  });
};

export const rejectHospitalApplication = async (application: Hospital) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `hospital/rejectHospitalApplication`,
    headers,
    body: {
      application,
    },
    json: true,
  });
};

export const getHospitalDoctors = async (hospitalId: string) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "GET",
    url: url + `hospital/getHospitalDoctors/${hospitalId}`,
    headers,
    json: true,
  });
};
