export const termsAndConditions = "";
// export const termsAndConditions = `<body class="c15">
//   <p class="c3"><span class="c12 c5 c8">June 1, 2020</span></p>
//   <p class="c3 c7"><span class="c12 c5 c8"></span></p>
//   <p class="c3">
//     <span class="c12"
//       ><b
//         class="c14"

//         >Terms and Conditions</b
//       ></span
//     >
//   </p>
//   <p class="c3 c7"><span class="c6 c5 c8"></span></p>
//   <p class="c3">
//     <span class="c6 c5 c8"
//       >BY ACCESSING OR USING THE WeCAN WEBSITE (&ldquo;WeCAN&rdquo;,
//       &ldquo;WEBSITE&rdquo;), YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND,
//       AND AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS, (2) YOU ARE OF LEGAL
//       AGE TO FORM A BINDING CONTRACT, AND (3) YOU HAVE THE AUTHORITY TO ENTER
//       INTO THE TERMS AND CONDITIONS PERSONALLY OR ON BEHALF OF THE COMPANY YOU
//       HAVE NAMED AS THE USER, AND TO BIND THAT COMPANY TO THESE TERMS AND
//       CONDITIONS (&ldquo;TERMS AND CONDITIONS&rdquo; or
//       &ldquo;TERMS&rdquo;).&nbsp; THE TERM &ldquo;YOU&rdquo; REFERS TO YOU AS AN
//       INDIVIDUAL OR THE LEGAL ENTITY IDENTIFIED AS THE USER WHEN YOU REGISTERED
//       ON THE WEBSITE.&nbsp; IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS AND
//       CONDITIONS, YOU MAY NOT ACCESS OR USE THIS WEBSITE.</span
//     >
//   </p>
//   <p class="c3 c7"><span class="c6 c5 c8"></span></p>
//   <p class="c3">
//     <span class="c6 c5 c8"
//       >We know that your privacy is important. Please refer to our Data Privacy
//       Statement in the Website, which explains our policy on collection, use and
//       disclosure practices regarding any personal information that you provide
//       to us.&nbsp;</span
//     >
//   </p>
//   <p class="c3 c7"><span class="c6 c5 c8"></span></p>
//   <p class="c3">
//     <span class="c6 c5 c8"
//       >We may, at our sole discretion, change, add, or delete portions of these
//       Terms and Conditions at any time. It is your responsibility to check these
//       Terms and Conditions for changes prior to use of the Website, and in any
//       event your continued use of the Website following the posting of changes
//       to these Terms and Conditions constitutes your acceptance of any changes.
//       We will notify you of any such material changes by posting notice of the
//       changes on the Website.</span
//     >
//   </p>
//   <p class="c3 c7 c9"><span class="c6 c5 c8"></span></p>
//   <ol class="c1 lst-kix_list_20-0 start" start="1">
//     <li class="c3 c11">
//       <span class="c6 c5 c8"
//         >The WeCAN Website. The WeCAN Website, developed by </span
//       ><span class="c6 c5 c8"
//         ><a
//           class="c14"
//           href="https://www.cortex.ph"
//           target="blank"
//           >Cortex Technologies Corporation</a
//         ></span
//       ><span class="c6 c5 c8"
//         >, is a free-to-use, non-monetary online web platform, designed for
//         cancer patients, medical professionals, CARE hospitals and other cancer
//         facilities to easily schedule cancer treatments and consultations in
//         real-time, between the patients, the physicians and the hospitals.
//         &nbsp;WeCAN connects patients, who are in need of cancer treatments and
//         consultations, to hospitals and medical institutions that belong to the
//         Cancer Network Action Group Philippines (&ldquo;C-NAG PH&rdquo;).
//         Through WeCAN, patients can book appointments for specific treatments
//         and consultations while hospitals can enter treatments and consultation
//         services that they offer. Transportation can also be offered by
//         volunteers (individuals, neighbors, or barangays, etc.) who sign-up as
//         transport volunteers.</span
//       >
//     </li>
//   </ol>
//   <p class="c3 c7"><span class="c6 c5 c8"></span></p>
//   <ol class="c1 lst-kix_list_20-0" start="2">
//     <li class="c3 c11">
//       <span class="c6 c5 c8"
//         >The rights granted to you in these Terms and Conditions are subject to
//         the following restrictions: (a) you shall not license, sell, rent,
//         lease, transfer, assign, reproduce, distribute, host or otherwise
//         commercially exploit any portion of the Website, (b) you shall not frame
//         or utilize framing techniques to enclose any trademark, logo, or other
//         Cortex properties (including images, text, page layout or form); (c) you
//         shall not use any metatags or other &ldquo;hidden text&rdquo; using
//         Cortex&rsquo;s name or trademarks; (d) you shall not modify, translate,
//         adapt, merge, make derivative works of, disassemble, decompile, reverse
//         compile or reverse engineer any part of Cortex&rsquo;s properties except
//         to the extent the foregoing restrictions are expressly prohibited by
//         applicable law; (e) you shall not use any manual or automated software,
//         devices or other processes (including but not limited to spiders,
//         robots, scrapers, crawlers, avatars, data mining tools or the like) to
//         &ldquo;scrape&rdquo; or download data from any web pages contained in
//         the Website (except that we grant the operators of public search engines
//         revocable permission to use spiders to copy materials from the Website
//         for the sole purpose of and solely to the extent necessary for creating
//         publicly available searchable indices of the materials, but not caches
//         or archives of such materials); (f) access the Website in order to build
//         a similar or competitive website, application or service; (g) except as
//         expressly stated herein, no part of the Website may be copied,
//         reproduced, distributed, republished, downloaded, displayed, posted or
//         transmitted in any form or by any means; and (h) you shall not remove or
//         destroy any copyright notices or other proprietary markings contained on
//         or in the Website. Any future release, update or other addition to the
//         Website shall be subject to these Terms.&nbsp; Cortex, its suppliers and
//         service providers reserve all rights not granted in the
//         Terms.&nbsp;</span
//       >
//     </li>
//   </ol>
//   <p class="c3 c10"><span class="c6 c5 c8">&nbsp;</span></p>
//   <ol class="c1 lst-kix_list_20-0" start="3">
//     <li class="c3 c11">
//       <span class="c6 c5 c8"
//         >Third-Party Materials.&nbsp; You may have access to materials that are
//         hosted by another party.&nbsp; You agree that it is impossible for
//         Cortex to monitor such materials and that you access these materials at
//         your own risk.</span
//       >
//     </li>
//   </ol>
//   <p class="c3 c10 c7"><span class="c6 c5 c8"></span></p>
//   <ol class="c1 lst-kix_list_20-0 content-4" start="4">
//     <li class="c3 c11"><span class="c6 c5 c8">&nbsp;Registration.</span></li>
//   </ol>
//   <p class="c3 c10"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6"
//       >4.1 &nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Registering Your Account</span
//     ><span class="c6 c5 c8"
//       >.&nbsp; In order to access certain features of the Website you may be
//       required to become a Registered User, either as a patient, hospital, or as
//       a transportation volunteer.&nbsp; For purposes of these Terms and
//       Conditions, a &ldquo;Registered User&rdquo; or &ldquo;User&rdquo; is a
//       user who has registered an account on the Website
//       (&ldquo;Account&rdquo;).</span
//     >
//   </p>
//   <p class="c3 c4 c7"><span class="c6 c5 c8"></span></p>
//   <p class="c3 c4">
//     <span class="c6">4.2 &nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Registration Data.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; By registering, you agree to (1) provide true, accurate, current
//       and complete information about yourself as prompted by the
//       &nbsp;registration form (the &ldquo;Registration Data&rdquo;); and (2)
//       maintain and promptly update the Registration Data to keep it true,
//       accurate, current and complete.&nbsp; You represent that you are: (1) of
//       legal age to form a binding contract; and (2) not a person barred from
//       using the Website.&nbsp; You are responsible for all activities that occur
//       under your Account.&nbsp;&nbsp; You agree that you shall monitor your
//       Account to restrict use by minors, and you will accept full responsibility
//       for any unauthorized use of the Website by minors.&nbsp;&nbsp;You may not
//       share your Account or password with anyone, and you agree to (1) notify
//       Cortex immediately of any unauthorized use of your password or any other
//       breach of security; and (2) exit from your Account at the end of each
//       session.&nbsp; If you provide any information that is untrue, inaccurate,
//       not current or incomplete, or Cortex has reasonable grounds to suspect
//       that such information is untrue, inaccurate, not current or incomplete,
//       Cortex has the right to suspend or terminate your Account and refuse any
//       and all current or future use of the Website.&nbsp; You agree not to
//       create an Account using a false identity or information, or on behalf of
//       someone other than yourself.&nbsp; You agree that you shall not have more
//       than one Account at any given time.&nbsp; Cortex reserves the right to
//       remove or reclaim any usernames at any time and for any reason, including
//       but not limited to, claims by a third party that a username violates the
//       third party&rsquo;s rights.&nbsp; You agree not to create an Account or
//       use the Website if you have been previously removed by Website.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c10">
//     <span class="c6 c5 c8"
//       >5. &nbsp;&nbsp;Responsibility for
//       Content.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">5.1&nbsp;&nbsp;&nbsp;&nbsp; </span
//     ><span class="c2">Types of Content</span
//     ><span class="c6 c5 c8"
//       >.&nbsp; You acknowledge that all content in the Website is the sole
//       responsibility of the party from whom such content originated.&nbsp; This
//       means that you are entirely responsible for all content that you upload,
//       post, e-mail, transmit or otherwise make available (&ldquo;Make
//       Available&rdquo;) through the Website (&ldquo;Your Content&rdquo;), and
//       other Users of the Website are similarly responsible for all content they
//       Make Available through the Website (&ldquo;User Content&rdquo;).</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">No Obligation to Pre-Screen Content.</span
//     ><span class="c6 c5 c8"
//       >&nbsp;&nbsp;You acknowledge that Cortex has no obligation to pre-screen
//       content (including, but not limited to, Your Content and User Content),
//       although Cortex reserves the right in its sole discretion to pre-screen,
//       refuse or remove any Content.&nbsp; By entering into the Terms, you hereby
//       provide your irrevocable consent to such monitoring.&nbsp; You acknowledge
//       and agree that you have no expectation of privacy concerning the
//       transmission of Your Content.&nbsp; In the event that Cortex pre-screens,
//       refuses or removes any content, you acknowledge that Cortex will do so for
//       its benefit, not yours.&nbsp; Without limiting the foregoing, Cortex shall
//       have the right to remove any content that violates these Terms and
//       Conditions or is otherwise objectionable.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">5.3&nbsp;&nbsp;&nbsp;&nbsp; </span
//     ><span class="c2">Storage.</span
//     ><span class="c6 c5 c8"
//       >&nbsp;&nbsp;Unless expressly agreed to by Cortex in writing elsewhere,
//       Cortex has no obligation to store any of Your Content that you Make
//       Available on the Website.&nbsp; Cortex has no responsibility or liability
//       for the deletion or accuracy of any content, including Your Content; the
//       failure to store, transmit or receive transmission of content; or the
//       security, privacy, storage, or transmission of other communications
//       originating with or involving use of the Website. You agree that Cortex
//       retains the right to create reasonable limits on the use and storage of
//       content, including Your Content, such as limits on file size, storage
//       space, processing capacity, and similar limits as determined by Cortex in
//       its sole discretion.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <ol class="c1 lst-kix_list_29-0 start" start="6">
//     <li class="c3 c11"><span class="c6 c5 c8">&nbsp;&nbsp;Ownership.</span></li>
//   </ol>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Cortex Properties.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; Except with respect to Your Content and User Content, you agree
//       that Cortex owns all rights, title and interest in the Website.&nbsp; You
//       will not remove, alter or obscure any copyright, trademark, service mark
//       or other proprietary rights notices incorporated in or accompanying the
//       Website.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Trademarks.</span
//     ><span class="c6 c5 c8"
//       >&nbsp;WeCAN, Cortex, and other related graphics, logos, service marks and
//       trade names used on or in connection with the Website are the trademarks
//       of Cortex and may not be used without permission in connection with any
//       third-party products or services.&nbsp; Other trademarks, service marks
//       and trade names that may appear on or in the Website are the property of
//       their respective owners.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">6.3&nbsp;&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Other Content</span
//     ><span class="c6 c5 c8"
//       >.&nbsp; Except with respect to Your Content, you agree that you have no
//       right or title in or to any content that appears on or in the
//       Website.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">6.4&nbsp;&nbsp;&nbsp;&nbsp; </span
//     ><span class="c2">Your Content.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; Cortex does not claim ownership of Your Content.&nbsp; However,
//       when you as a User post or publish Your Content on or in the Website, you
//       represent and warrant that you own and/or have a royalty-free, perpetual,
//       irrevocable, worldwide, non-exclusive right (including any moral rights)
//       and license to use, license, reproduce, modify, adapt, publish, translate,
//       create derivative works from, distribute, derive revenue or other
//       remuneration from, and communicate to the public, perform and display Your
//       Content (in whole or in part) worldwide and/or to incorporate it in other
//       works in any form, media or technology now known or later developed, for
//       the full term of any worldwide intellectual property right that may exist
//       in Your Content.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">6.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Username.</span
//     ><span class="c6 c5 c8"
//       >&nbsp;Notwithstanding anything contained herein to the contrary, by
//       submitting Your Content to any forums, comments or any other area on the
//       Website, you hereby expressly permit Cortex to identify you by your
//       username (which may be a pseudonym) as the contributor of Your Content in
//       any publication in any form, media or technology now known or later
//       developed in connection with Your Content.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">6.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Your Account</span
//     ><span class="c6 c5 c8"
//       >. Notwithstanding anything to the contrary herein, you acknowledge and
//       agree that you shall have no ownership or other property interest in your
//       Account, and you further acknowledge and agree that all rights in and to
//       your Account are and shall forever be owned by and inure to the benefit of
//       Cortex.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">6.7 &nbsp;&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Your Profile.</span
//     ><span class="c6 c5 c8"
//       >&nbsp;&nbsp;Any Content posted by you in your profile may not contain
//       nudity, violence, sexually explicit, or offensive subject matter.&nbsp;
//       You may not post or submit for print services a photograph of another
//       person without that person&rsquo;s permission.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">6.8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Feedback.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; You agree that submission of any ideas, suggestions, documents,
//       and/or proposals to Cortex is at your own risk and that Cortex has no
//       obligations (including without limitation obligations of confidentiality)
//       with respect to such Feedback.&nbsp; You represent and warrant that you
//       have all rights necessary to submit the Feedback.&nbsp; You hereby grant
//       to Cortex a fully paid, royalty-free, perpetual, irrevocable, worldwide,
//       non-exclusive, and fully sublicensable right and license to use,
//       reproduce, perform, display, distribute, adapt, modify, re-format, create
//       derivative works of, and otherwise commercially or non-commercially
//       exploit in any manner, any and all Feedback, and to sublicense the
//       foregoing rights, in connection with the operation and maintenance of the
//       Website.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c10">
//     <span class="c6 c5 c8"
//       >7. &nbsp;&nbsp;User Conduct.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">7.1&nbsp;&nbsp;&nbsp; &nbsp;</span
//     ><span class="c2">Commercial Activities</span
//     ><span class="c6 c5 c8"
//       >.&nbsp; You agree that you will not, under any circumstances (except to
//       the extent expressly authorized by the Terms):</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reproduce, duplicate, copy, sell,
//       trade, resell or exploit for any commercial purpose any portion of the
//       Website;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upload, post, e-mail, transmit or
//       otherwise make available any unsolicited or unauthorized advertising,
//       promotional materials, &ldquo;junk mail,&rdquo; &ldquo;spam,&rdquo;
//       &ldquo;chain letters,&rdquo; &ldquo;pyramid schemes,&rdquo; or any other
//       form of solicitation;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Use the Website or any part
//       thereof for any commercial purpose, including, but not limited to
//       communicating or facilitating any commercial advertisement or
//       solicitation;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Engage in any chain letters,
//       contests, junk email, pyramid schemes, spamming, surveys or other
//       duplicative or unsolicited messages (commercial or otherwise); or</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Market any goods or services for
//       any business purposes.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Unauthorized Use or Access</span
//     ><span class="c6 c5 c8"
//       >. You agree that you will not, under any circumstances:</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Interfere or attempt to interfere
//       with the proper functioning of the Website in any way not expressly
//       permitted by the Terms;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >&nbsp;(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upload, post, e-mail, transmit or
//       otherwise make available any material that contains software viruses or
//       any other computer code, files or programs designed to interrupt, destroy
//       or limit the functionality of any computer software or hardware or
//       telecommunications equipment;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Solicit or attempt to solicit personal
//       information from other Users of the Website;</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c7"><span class="c6 c5 c8"></span></p>
//   <p class="c3 c7"><span class="c6 c5 c8"></span></p>
//   <p class="c3 c7"><span class="c6 c5 c8"></span></p>
//   <p class="c3 c4">
//     <span class="c6">7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">General.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; In connection with your use of the Website, you shall not:</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Make Available any Content that
//       (i) is unlawful, tortious, defamatory, vulgar, obscene, libelous, or
//       racially, ethnically or otherwise objectionable; (ii) violates, or
//       encourages any conduct that would violate, any applicable law or
//       regulation or would give rise to civil liability; (iii) promotes
//       discrimination, bigotry, racism, hatred, harassment or harm against any
//       individual or group; (iv) is violent or threatening, or promotes violence
//       or actions that are threatening to any other person; or (v) promotes
//       illegal or harmful activities;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Harm minors in any way;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Impersonate any person or entity,
//       including, but not limited to, Cortex personnel, or falsely state or
//       otherwise misrepresent your affiliation with a person or entity;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Make available any Content that
//       you do not have a right to Make Available under any law or under
//       contractual or fiduciary relationships (such as inside information,
//       proprietary and confidential information learned or disclosed as part of
//       employment relationships or under non-disclosure agreements);</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Make Available any Content that
//       infringes the rights of any person or entity, including without
//       limitation, any patent, trademark, trade secret, copyright, privacy,
//       publicity or other proprietary or contractual rights;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Intentionally or
//       unintentionally violate any applicable law or regulation, or any order of
//       a court;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Register for more than one Account
//       or register for an Account on behalf of an individual other than
//       yourself;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(h)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Stalk or otherwise harass any
//       other User of the Website; or</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Advocate, encourage or
//       assist any third party in doing any of the foregoing activities in this
//       section.</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c16 investigations">
//   <span class="c2 c5">7.4&nbsp;&nbsp;&nbsp;Investigations.</span><span class="c6 c5 c8">&nbsp; Cortex may, but is not obligated to, monitor or review the
//     Website’s content at any time.&nbsp; Without limiting the
//     foregoing, Cortex shall have the right, in its sole discretion, to
//     remove any of Your Content for any reason (or no reason), including if
//     such content violates the Terms and Conditions or any applicable law,
//     immediately and without prior notice to you.&nbsp; Although Cortex does
//     not generally monitor user activity occurring in connection with the
//     Website, if Cortex becomes aware of any possible violations by you of
//     any provision of the Terms and Conditions, Cortex reserves the right to
//     investigate such violations, and Cortex may, at its sole discretion,
//     immediately terminate your license to use the Website, or change, alter
//     or remove Your Content, in whole or in part, without prior notice to
//     you.</span>
// </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//     <p class="c3 c16 interaction-with-others">
//       <span class="c2 c5">7.5&nbsp;&nbsp;&nbsp;Interactions with Other Users.</span>
//     </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c1 lst-kix_list_24-0 start">
//     <p class="c3 c13 user-responsibility">
//       <span class="c2 c5">(a)&nbsp;&nbsp;&nbsp;User Responsibility.</span
//       ><span class="c6 c5 c8"
//         >&nbsp; You are solely responsible for your interactions with other
//         Users of the Website and any other parties with whom you interact
//         through the Website; provided, however, that Cortex reserves the right,
//         but has no obligation, to intercede in case of disputes.&nbsp; You agree
//         that Cortex will not be responsible for any liability incurred as the
//         result of such interactions.</span
//       >
//     </p>
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c1 lst-kix_list_24-0" start="2">
//     <p class="c3 c13 content-provided">
//       <span class="c2 c5">(b)&nbsp;&nbsp;&nbsp;Content Provided by Other Users.</span><span class="c6 c5 c8">&nbsp; The Website may contain User Content provided by other
//         Users.&nbsp; Cortex is not responsible for and does not control User
//         Content.&nbsp; Cortex has no obligation to review or monitor, and does
//         not approve, endorse or make any representations or warranties with
//         respect to User Content.&nbsp; You use all User Content and interact
//         with other Users at your own risk.</span>
//     </p>
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c10">
//     <span class="c6 c5 c8"
//       >&nbsp;8.
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indemnification.&nbsp; You
//       agree to indemnify and hold Cortex, its parents, subsidiaries, affiliates,
//       and their officers, employees, agents, partners and licensors
//       (collectively, the &ldquo;Cortex Parties&rdquo;) harmless from any losses,
//       costs, liabilities and expenses (including reasonable attorneys&rsquo;
//       fees) relating to or arising out of: (a) Your Content; (b) your use of, or
//       inability to use, the Website; (c) your violation of these Terms and
//       Conditions; (d) your violation of any rights of another party, including
//       any Users; or (e) your violation of any applicable laws, rules or
//       regulations.&nbsp; Cortex reserves the right, at its own cost, to assume
//       the exclusive defense and control of any matter otherwise subject to
//       indemnification by you, in which event you will fully cooperate with
//       Cortex in asserting any available defenses.&nbsp; You agree that the
//       provisions in this section will survive any termination of your Account,
//       the Terms and Conditions or your access to the Website.</span
//     >
//   </p>
//   <p class="c3 c10"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c10">
//     <span class="c6 c5 c8"
//       >9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disclaimer of
//       Warranties.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">9.1&nbsp;&nbsp;&nbsp;</span><span class="c2">As Is.</span
//     ><span class="c6 c5 c8"
//       >&nbsp;YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY
//       APPLICABLE LAW, YOUR USE OF THE WEBSITE IS AT YOUR SOLE RISK, AND THE
//       WEBSITE IS PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
//       AVAILABLE&rdquo; BASIS, WITH ALL FAULTS.&nbsp; CORTEX PARTIES EXPRESSLY
//       DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND,
//       WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
//       WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
//       PURPOSE AND NON-INFRINGEMENT.</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THE CORTEX PARTIES MAKE NO
//       WARRANTY, REPRESENTATION OR CONDITION THAT: (1) THE WEBSITE WILL MEET YOUR
//       REQUIREMENTS; (2) YOUR USE OF THE WEBSITE WILL BE UNINTERRUPTED, TIMELY,
//       SECURE OR ERROR-FREE; (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE
//       WEBSITE WILL BE ACCURATE OR RELIABLE; OR (4) ANY ERRORS IN THE WEBSITE
//       WILL BE CORRECTED.</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ANY CONTENT DOWNLOADED FROM OR
//       OTHERWISE ACCESSED THROUGH THE WEBSITE IS ACCESSED AT YOUR OWN RISK, AND
//       YOU SHALL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY OR PERSON,
//       INCLUDING, BUT NOT LIMITED TO, YOUR COMPUTER SYSTEM AND ANY DEVICE YOU USE
//       TO ACCESS THE WEBSITE, OR ANY OTHER LOSS THAT RESULTS FROM ACCESSING SUCH
//       CONTENT.</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THE SERVICES MAY BE SUBJECT TO DELAYS,
//       CANCELLATIONS AND OTHER DISRUPTIONS.&nbsp; CORTEX MAKES NO WARRANTY,
//       REPRESENTATION OR CONDITION WITH RESPECT TO SERVICES, INCLUDING BUT NOT
//       LIMITED TO, THE QUALITY, EFFECTIVENESS, REPUTATION AND OTHER
//       CHARACTERISTICS OF SERVICES.</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(d)&nbsp;&nbsp;&nbsp;NO STATEMENT, ADVICE, OR INFORMATION,
//       WHETHER ORAL OR WRITTEN, OBTAINED FROM CORTEX OR THROUGH THE WEBSITE WILL
//       CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(e)&nbsp;&nbsp;&nbsp;FROM TIME TO TIME, CORTEX MAY OFFER NEW
//       &ldquo;BETA&rdquo; FEATURES OR TOOLS WITH WHICH ITS USERS MAY
//       EXPERIMENT.&nbsp; SUCH FEATURES OR TOOLS ARE OFFERED SOLELY FOR
//       EXPERIMENTAL PURPOSES AND WITHOUT ANY WARRANTY OF ANY KIND, AND MAY BE
//       MODIFIED OR DISCONTINUED AT CORTEX&rsquo;S SOLE DISCRETION.&nbsp; THE
//       PROVISIONS OF THIS SECTION APPLY WITH FULL FORCE TO SUCH FEATURES OR
//       TOOLS.</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">9.2&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">No Liability for Conduct of Third Parties.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; YOU ACKNOWLEDGE AND AGREE THAT THE CORTEX PARTIES ARE NOT LIABLE,
//       AND YOU AGREE NOT TO SEEK TO HOLD THE CORTEX PARTIES LIABLE, FOR THE
//       CONDUCT OF THIRD PARTIES, INCLUDING OPERATORS OF EXTERNAL SITES, AND THAT
//       THE RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU.
//       &nbsp;CORTEX MAKES NO WARRANTY THAT THE SERVICES PROVIDED BY THIRD PARTIES
//       WILL MEET YOUR REQUIREMENTS.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">9.3&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">No Liability for Conduct of Other Users.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
//       INTERACTIONS WITH OTHER USERS OF THE WEBSITE. YOU UNDERSTAND THAT CORTEX
//       DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF THE
//       WEBSITE. CORTEX MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT
//       OF USERS OF THE WEBSITE OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE
//       USERS OF THE WEBSITE.&nbsp; YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN
//       ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE WEBSITE,
//       PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN PERSON.&nbsp; YOU
//       ACKNOWLEDGE AND AGREE THAT CORTEX DOES NOT CONDUCT BACKGROUND CHECKS ON
//       ANY USER.</span
//     >
//   </p>
//   <p class="c3 c7"><span class="c6 c5 c8"></span></p>
//   <ol class="c1 lst-kix_list_31-0 start ten" start="10">
//     <li class="c3 c11">
//       <span class="c6 c5 c8">&nbsp;&nbsp;Limitation of Liability.</span>
//     </li>
//   </ol>
//   <p class="c3 c10"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">10.1&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Disclaimer of Certain Damages.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; YOU UNDERSTAND AND AGREE THAT IN NO EVENT SHALL CORTEX PARTIES BE
//       LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
//       DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE WEBSITE, INCLUDING,
//       WITHOUT LIMITATION, ANY DAMAGES RESULTING FROM LOSS OF USE, DATA, OR
//       PROFITS, WHETHER OR NOT CORTEX HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
//       DAMAGES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL
//       DISTRESS ARISING OUT OF OR IN CONNECTION WITH THE TERMS, OR FROM ANY
//       COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF THE WEBSITE,
//       ON ANY THEORY OF LIABILITY, RESULTING FROM: (1) THE USE OR INABILITY TO
//       USE THE WEBSITE; (2) THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES
//       RESULTING FROM ANY DATA, INFORMATION OR SERVICES OBTAINED OR MESSAGES
//       RECEIVED FOR TRANSACTIONS ENTERED INTO THROUGH THE WEBSITE; (3)
//       UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (4)
//       STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE WEBSITE; OR (5) ANY OTHER
//       MATTER RELATED TO THE WEBSITE, WHETHER BASED ON WARRANTY, COPYRIGHT,
//       CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER
//       LEGAL THEORY.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">10.2&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">User Content.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; THE CORTEX PARTIES ASSUME NO RESPONSIBILITY FOR THE TIMELINESS,
//       DELETION, MIS-DELIVERY OR FAILURE TO STORE ANY CONTENT (INCLUDING, BUT NOT
//       LIMITED TO, YOUR CONTENT AND USER CONTENT), USER COMMUNICATIONS OR
//       PERSONALIZATION SETTINGS.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;&nbsp;&nbsp;</span></p>
//   <p class="c3 c10">
//     <span class="c6 c5 c8"
//       >11. &nbsp;&nbsp;Term and
//       Termination.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">11.1&nbsp;&nbsp;&nbsp;</span><span class="c2">Term.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; The Terms commence on the date when you accept them (as described
//       in the preamble above) and remain in full force and effect while you use
//       the Website, unless terminated earlier in accordance with the Terms.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">11.2&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Termination by Cortex.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; Cortex reserves the right to modify, suspend or terminate any
//       services provided to you at any time for any reason. You agree that all
//       terminations shall be made in Cortex&rsquo;s sole discretion and that
//       Cortex shall not be liable to you or any third party for any termination
//       of your Account.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">11.3&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Termination by You.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; If you want to terminate the services provided by Cortex, you may
//       do so by (a) notifying Cortex at any time and (b) closing your Account.
//       Your notice should be sent, in writing, to Cortex&rsquo;s address set
//       forth herein or in its website.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">11.4&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Effect of Termination.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; Termination includes removal of access to the Website and barring
//       of further use of the same.&nbsp; Termination&nbsp;also includes deletion
//       of your password and all related information, files and content associated
//       with or inside your Account (or any part thereof), including Your
//       Content.&nbsp; Upon termination, your right to use your Account will
//       automatically terminate immediately. You understand that any termination
//       may involve deletion of Your Content associated therewith from our live
//       databases.&nbsp; Cortex will not have any liability whatsoever to you for
//       any suspension or termination, including for deletion of Your
//       Content.&nbsp; All provisions of the Terms which by their nature should
//       survive, shall survive termination of Services, including without
//       limitation, ownership provisions, warranty disclaimers, and limitation of
//       liability.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c10">
//     <span class="c6 c5 c8"
//       >12. &nbsp;&nbsp;Remedies.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">12.1&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Violations.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; If Cortex becomes aware of any possible violations by you of the
//       Terms, Cortex reserves the right to investigate such violations.&nbsp; If,
//       as a result of the investigation, Cortex believes that criminal activity
//       has occurred, Cortex reserves the right to refer the matter to, and to
//       cooperate with, any and all applicable legal authorities.&nbsp; Cortex is
//       entitled, except to the extent prohibited by applicable law, to disclose
//       any information or materials on or in the Website, including Your Content,
//       in Cortex&rsquo;s possession in connection with your use of the Website,
//       to (1) comply with applicable laws, legal process or governmental request;
//       (2) enforce the Terms, (3) respond to any claims that Your Content
//       violates the rights of third parties, (4) respond to your requests for
//       customer service, or (5) protect the rights, property or personal safety
//       of Cortex, its Users or the public, and all enforcement or other
//       government officials, as Cortex in its sole discretion believes to be
//       necessary or appropriate.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">12.2&nbsp;&nbsp; </span><span class="c2">Breach.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; In the event that Cortex determines, in its sole discretion, that
//       you have breached any portion of the Terms, or have otherwise demonstrated
//       conduct inappropriate for the Website, Cortex reserves the right to:</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Warn you via e-mail (to any e-mail
//       address you have provided to Cortex) that you have violated the
//       Terms;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delete any of Your Content provided by
//       you;</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Discontinue your registration(s);</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notify and/or send Your Content to
//       and/or fully cooperate with the proper law enforcement authorities for
//       further action; and/or</span
//     >
//   </p>
//   <p class="c0"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c0">
//     <span class="c6 c5 c8"
//       >(e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pursue any other action which Cortex
//       deems to be appropriate.</span
//     >
//   </p>
//   <p class="c3"><span class="c6 c5 c8">&nbsp;</span></p>
//   <ol class="c1 lst-kix_list_32-0 thirtheen start" start="13">
//     <li class="c3 c11">
//       <span class="c6 c5 c8">&nbsp;General Provisions.</span>
//     </li>
//   </ol>
//   <p class="c3 c10"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">13.1&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Electronic Communications</span
//     ><span class="c6 c5 c8"
//       >.&nbsp; The communications between you and Cortex use electronic means,
//       whether you visit the Website or send e-mails, or whether Cortex posts
//       notices on the Website or communicates with you via e-mail.&nbsp; For
//       contractual purposes, you (1) consent to receive communications from
//       Cortex in an electronic form; and (2) agree that all terms and conditions,
//       agreements, notices, disclosures, and other communications that Cortex
//       provides to you electronically satisfy any legal requirement that such
//       communications would satisfy if it were to be in writing.&nbsp; The
//       foregoing does not affect your statutory rights.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">13.2&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Release.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; You hereby release the Cortex Parties and their successors from
//       claims, demands, any and all losses, damages, rights, and actions of any
//       kind, including personal injuries, death, and property damage, that is
//       either directly or indirectly related to or arises from your use of the
//       Website, including but not limited to, any interactions with or conduct of
//       other Users or third-party websites of any kind arising in connection with
//       or as a result of the Terms or your use of the Website.&nbsp;
//     </span>
//   </p>
//   <p class="c3 c4 c7"><span class="c6 c5 c8"></span></p>
//   <p class="c3 c4">
//     <span class="c6">13.3&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Assignment.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; The Terms, and your rights and obligations hereunder, may not be
//       assigned, subcontracted, delegated or otherwise transferred by you without
//       Cortex&rsquo;s prior written consent, and any attempted assignment,
//       subcontract, delegation, or transfer in violation of the foregoing will be
//       null and void.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">13.4&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Force Majeure</span
//     ><span class="c6 c5 c8"
//       >.&nbsp; Cortex shall not be liable for any delay or failure to perform
//       resulting from causes outside its reasonable control, including, but not
//       limited to, acts of God, war, terrorism, riots, embargos, acts of civil or
//       military authorities, fire, floods, accidents, strikes or shortages of
//       transportation facilities, fuel, energy, labor or materials.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">13.5 &nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Governing Law</span
//     ><span class="c6 c5 c8"
//       >.&nbsp; These Terms and Conditions shall be construed and enforced in
//       accordance with the laws of the Philippines.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">13.6&nbsp;&nbsp; </span
//     ><span class="c2">Waiver.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; Any waiver or failure to enforce any provision of the Terms and
//       Conditions on one occasion will not be deemed a waiver of any other
//       provision or of such provision on any other occasion.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">13.7&nbsp;&nbsp;&nbsp;</span
//     ><span class="c2">Severability.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; If any provision of the Terms and Conditions is, for any reason,
//       held to be invalid or unenforceable, the other provisions of the Terms and
//       Conditions will remain enforceable, and the invalid or unenforceable
//       provision will be deemed modified so that it is valid and enforceable to
//       the maximum extent permitted by law.</span
//     >
//   </p>
//   <p class="c3 c4"><span class="c6 c5 c8">&nbsp;</span></p>
//   <p class="c3 c4">
//     <span class="c6">13.8&nbsp;&nbsp; </span
//     ><span class="c2">Entire Agreement.</span
//     ><span class="c6 c5 c8"
//       >&nbsp; These Terms and Conditions are the final, complete and exclusive
//       agreement of the parties with respect to the subject matter hereof and
//       supersedes and merges all prior discussions between the parties with
//       respect to such subject matter.</span
//     >
//   </p>
//   <p class="c3 c7"><span class="c6 c5 c8"></span></p>
// </body>`;
