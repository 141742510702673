import _ from "lodash";
import moment from "moment";
import QRCode from "qrcode.react";
import { checkmarkCircle } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonLabel,
  IonCard,
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
  IonButton,
  IonModal,
} from "@ionic/react";

import "./MBViewAnAppointment.scss";
import * as services from "../../services";
import { MBProps, UserType } from "../../interface";
import {
  AppointmentStatus,
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
} from "../../models";
import { MBAppointmentDetail } from "../MBAppointmentDetail/MBAppointmentDetail";
import { MBDivider } from "../MBDivider/MBDivider";
import {
  getDayTimeDifference,
  isMobile,
  toDateTimeFromSecs,
  useEffectOnlyOnce,
} from "../../functions/common";
import {
  APPOINTMENT_STATUS_TYPES,
  MBCOLORS,
  PAYMENT_TYPES,
  USER_TYPES,
} from "../../constants/config";

interface MBViewAnAppointmentProps extends MBProps {
  appointmentData:
    | HospitalDoctorAppointmentsView
    | HospitalServiceAppointmentsView;
  onClickBack: () => void;
  mode: "doctor" | "patient" | "hospital";
  onClickCancel: (appointmentId: string) => void;
  onMarkAsDone?: (appointmentId: string) => void;
}

interface UserDetailsTimeLine {
  name: string;
  userId: string;
}
export const MBViewAnAppointment = (props: MBViewAnAppointmentProps) => {
  const {
    appointmentData,
    onClickBack,
    mode,
    onClickCancel,
    onMarkAsDone,
  } = props;
  const [showQR, setShowQR] = useState(false);
  console.log("appointmentData -- ", appointmentData);

  const serviceAppointment = appointmentData as HospitalServiceAppointmentsView;
  const doctorAppointment = appointmentData as HospitalDoctorAppointmentsView;
  const isDoctorAppointment = !_.isEmpty(doctorAppointment.doctorDetails);
  const [cancelledBy, setCancelledBy] = useState("");
  const [userDetailsTimeLine, setUserDetailsTimeLine] = useState(
    null as null | UserDetailsTimeLine[]
  );
  // const [userName, setUserName] = useState("");

  useEffectOnlyOnce(() => {
    getUserDetails();
  });

  const getUserDetails = async () => {
    // const userDetails = await services.getUserDetails(userId, userType);

    appointmentData.isCancelled
      ? await getCancelledBy()
      : await getListOfUserDetails();
  };

  const getCancelledBy = async () => {
    const userDetails = await services.getUserDetails(
      appointmentData.updatedBy as string,
      appointmentData.updatedByUserType as UserType
    );

    setCancelledBy(`${userDetails.firstName} ${userDetails.lastName}`);
  };

  const getListOfUserDetails = async () => {
    if (!!appointmentData.appointmentStatus) {
      const details = await Promise.all(
        appointmentData.appointmentStatus.map((statusDetail) => {
          return new Promise(async (resolve) => {
            const data = await services.getUserDetails(
              statusDetail.updatedBy as string,
              statusDetail.updatedByUserType as UserType
            );
            console.log("data -- ", data);
            resolve({
              name: `${data.firstName} ${data.lastName}`,
              userId: data.docId as string,
            });
          });
        })
      );
      setUserDetailsTimeLine(details as UserDetailsTimeLine[]);
    } else {
      setUserDetailsTimeLine(null);
    }
  };

  return (
    <>
      <div className="mb-view-appointment-container">
        <IonCard
          className="mb-view-appointment-status-card ion-no-padding ion-no-margin"
          color={MBCOLORS.tertiary}
        >
          <div className="mb-view-appointment-status-card-content-container">
            <IonGrid className="mb-view-appointment-status-grid ion-no-padding ion-no-margin">
              <IonRow className="mb-view-appointment-status-row ion-no-padding ion-no-margin">
                <IonCol
                  size="1.5"
                  className="mb-view-appointment-status-status-col ion-no-padding ion-no-margin"
                >
                  <div className="first-status-container">
                    {appointmentData.isCancelled ? (
                      <IonLabel className="first-status-icon cancelled label mb-h4 white">
                        1
                      </IonLabel>
                    ) : (
                      <IonIcon
                        className="first-status-icon"
                        color={MBCOLORS.success}
                        icon={checkmarkCircle}
                      />
                    )}
                    <IonLabel
                      className={`first-status-label mb-h4 ${
                        appointmentData.isCancelled ? "danger" : "success"
                      } ion-no-padding ion-no-margin`}
                    >
                      {`${
                        appointmentData.isCancelled ? "Cancelled" : "Booked"
                      }`}
                    </IonLabel>
                  </div>
                </IonCol>
                <IonCol
                  size="3.75"
                  className="mb-view-appointment-status-divider-col ion-no-padding ion-no-margin"
                >
                  <div className="status-divider-container">
                    <MBDivider />
                  </div>
                </IonCol>
                <IonCol
                  size="1.5"
                  className="mb-view-appointment-status-status-col ion-no-padding ion-no-margin"
                >
                  <div className="second-status-container">
                    {!_.isEmpty(
                      _.find(
                        appointmentData.appointmentStatus,
                        (status) =>
                          status.id === APPOINTMENT_STATUS_TYPES.onGoing.id
                      )
                    ) ? (
                      <>
                        <IonIcon
                          className="second-status-icon"
                          color={MBCOLORS.success}
                          icon={checkmarkCircle}
                        />
                        <IonLabel className="second-status-label mb-h4 success ion-no-padding ion-no-margin">
                          Appointment
                        </IonLabel>
                      </>
                    ) : (
                      <>
                        <IonLabel className="second-status-icon label mb-h4 white">
                          2
                        </IonLabel>
                        <IonLabel className="second-status-label mb-h4 blue ion-no-padding ion-no-margin">
                          Appointment
                        </IonLabel>
                      </>
                    )}
                  </div>
                </IonCol>
                <IonCol
                  size="3.75"
                  className="mb-view-appointment-status-divider-col ion-no-padding ion-no-margin"
                >
                  <div className="status-divider-container">
                    <MBDivider />
                  </div>
                </IonCol>
                <IonCol
                  size="1.5"
                  className="mb-view-appointment-status-status-col ion-no-padding ion-no-margin"
                >
                  <div className="third-status-container">
                    {!_.isEmpty(
                      _.find(
                        appointmentData.appointmentStatus,
                        (status) =>
                          status.id === APPOINTMENT_STATUS_TYPES.done.id
                      )
                    ) ? (
                      <>
                        <IonIcon
                          className="third-status-icon"
                          color={MBCOLORS.success}
                          icon={checkmarkCircle}
                        />
                        <IonLabel className="second-status-label mb-h4 success ion-no-padding ion-no-margin">
                          Done
                        </IonLabel>
                      </>
                    ) : (
                      <>
                        <IonLabel className="third-status-icon label mb-h4 white">
                          3
                        </IonLabel>
                        <IonLabel className="third-status-label mb-h4 dark-blue ion-no-padding ion-no-margin">
                          Done
                        </IonLabel>
                      </>
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonCard>
        <IonCard
          className="mb-view-appointment-detail-card ion-no-padding ion-no-margin"
          color={MBCOLORS.tertiary}
        >
          <div
            className={`mb-view-appointment-detail-card-container ${
              isMobile() && "mobile"
            }`}
          >
            <MBAppointmentDetail
              appointmentData={appointmentData}
              mode={mode}
            />
          </div>
          <div
            className={`mb-view-appointment-additional-detail-card-container ${
              isMobile() && "mobile"
            }`}
          >
            <IonGrid className="additional-detail-grid">
              <IonRow className="additional-detail-row">
                <IonLabel className="additional-header-label mb-h4 bold">
                  Additional Details
                </IonLabel>
                <IonCol className="additional-detail-col-label" size="6">
                  <IonLabel className="additional-input-label mb-h5">
                    Booking ID:
                  </IonLabel>
                  <IonLabel className="additional-input-label qr-code mb-h5">
                    QR Code:
                  </IonLabel>
                  <IonLabel className="additional-input-label mb-h5">
                    Phone Number:
                  </IonLabel>
                  <IonLabel className="additional-input-label mb-h5">
                    Patient Name:
                  </IonLabel>
                  <IonLabel className="additional-input-label mb-h5">
                    Gender:
                  </IonLabel>
                  <IonLabel className="additional-input-label mb-h5">
                    Birthday:
                  </IonLabel>
                  {!_.isEmpty(
                    (appointmentData as HospitalDoctorAppointmentsView)
                      .condition
                  ) && (
                    <IonLabel className="additional-input-label mb-h5">
                      Patient Condition:
                    </IonLabel>
                  )}
                  <IonLabel className="additional-input-label mb-h5">
                    Payment Mode:
                  </IonLabel>
                  <IonLabel className="additional-input-label mb-h5">
                    Remarks:
                  </IonLabel>
                </IonCol>
                <IonCol className="additional-detail-col-value" size="6">
                  <IonLabel
                    className={`additional-input-value ${
                      isMobile() && "mb-paragraph"
                    }`}
                  >{`${appointmentData.docId}`}</IonLabel>
                  <div className="additional-input-value qr-code">
                    <QRCode value={appointmentData.docId || ""} size={150} />
                    <IonButton
                      color={MBCOLORS.primary}
                      mode="ios"
                      fill="clear"
                      className="view-qr-button mb-body back ion-no-margin ion-no-padding"
                      onClick={() => {
                        setShowQR(true);
                      }}
                    >
                      View Full Screen
                    </IonButton>
                  </div>
                  <IonLabel className="additional-input-value">
                    {isDoctorAppointment
                      ? doctorAppointment.doctorDetails.phoneNumber
                      : serviceAppointment.hospitalDetails.phoneNumber}
                  </IonLabel>
                  <IonLabel className="additional-input-value">
                    {`${appointmentData.patientDetails.firstName} ${appointmentData.patientDetails.lastName}`}
                  </IonLabel>
                  <IonLabel className="additional-input-value">{`${appointmentData.patientDetails.gender}`}</IonLabel>
                  <IonLabel className="additional-input-value">{`${moment(
                    appointmentData.patientDetails.birthday.toDate()
                  ).format("MMMM DD, YYYY")}`}</IonLabel>
                  {isDoctorAppointment && (
                    <IonLabel className="additional-input-value">{`${doctorAppointment.condition}`}</IonLabel>
                  )}
                  <IonLabel className="additional-input-value">{`${
                    appointmentData.paymentMethod === PAYMENT_TYPES.gCash.id
                      ? "GCASH"
                      : appointmentData.paymentMethod === PAYMENT_TYPES.card.id
                      ? "Credit/Debit Card"
                      : "Booking Credit"
                  }`}</IonLabel>

                  <div className="additional-details-remarks-container">
                    <IonGrid className="additional-details-remarks-grid ion-no-padding ion-no-margin">
                      <IonRow className="additional-details-remarks-row ion-no-padding ion-no-margin">
                        {_.isEmpty(appointmentData.appointmentStatus) ? (
                          <>
                            <IonCol
                              className="additional-details-remarks-timeline-col ion-no-padding ion-no-margin"
                              size="2"
                            >
                              <div className="remarks-timeline-container">
                                <div className="remarks-icon" />
                                <div className="remarks-vertical-line" />
                              </div>

                              <div className="remarks-timeline-container">
                                <div className="remarks-icon disabled" />
                              </div>
                            </IonCol>
                            <IonCol
                              className="additional-details-remarks-status-col ion-no-padding ion-no-margin"
                              size="10"
                            >
                              <div className="status-detail-container">
                                <div className="remarks-status-container">
                                  <IonLabel className="status-label mb-paragraph ion-no-padding ion-no-margin">
                                    <i>{`${
                                      appointmentData.isCancelled
                                        ? "Cancelled"
                                        : "Booked"
                                    }`}</i>
                                  </IonLabel>
                                  <IonLabel className="mb-paragraph light ion-no-padding ion-no-margin">
                                    {`${getDayTimeDifference(
                                      toDateTimeFromSecs(
                                        !!appointmentData.updatedAt &&
                                          appointmentData.isCancelled
                                          ? appointmentData.updatedAt.seconds
                                          : appointmentData.createdAt.seconds
                                      )
                                    )} ago`}
                                  </IonLabel>
                                </div>
                                <IonLabel className="mb-paragraph bold ion-no-padding ion-no-margin">
                                  {appointmentData.isCancelled
                                    ? cancelledBy
                                    : `${appointmentData.patientDetails.firstName} ${appointmentData.patientDetails.lastName}`}
                                </IonLabel>
                                {appointmentData.isCancelled && (
                                  <IonLabel className="mb-paragraph ion-no-padding ion-no-margin">
                                    {appointmentData.cancelReason}
                                  </IonLabel>
                                )}
                              </div>
                            </IonCol>
                          </>
                        ) : (
                          !!userDetailsTimeLine && (
                            <>
                              <IonCol
                                className="additional-details-remarks-timeline-col ion-no-padding ion-no-margin"
                                size="2"
                              >
                                <div className="remarks-timeline-container">
                                  <div className="remarks-icon" />
                                  <div className="remarks-vertical-line" />
                                </div>
                                {/* loop this for appointment status timeline */}
                                {!!appointmentData.appointmentStatus &&
                                  appointmentData.appointmentStatus.map(
                                    (appointmentStatusDetail) => {
                                      return (
                                        <div className="remarks-timeline-container">
                                          <div className="remarks-icon" />
                                          <div
                                            className={`remarks-vertical-line ${
                                              appointmentStatusDetail.id ===
                                              APPOINTMENT_STATUS_TYPES.done.id
                                                ? "done"
                                                : ""
                                            }`}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                {_.isEmpty(
                                  _.find(
                                    appointmentData.appointmentStatus,
                                    (status) =>
                                      status.id ===
                                      APPOINTMENT_STATUS_TYPES.done.id
                                  )
                                ) && (
                                  <div className="remarks-timeline-container">
                                    <div className="remarks-icon disabled" />
                                  </div>
                                )}
                              </IonCol>
                              <IonCol
                                className="additional-details-remarks-status-col ion-no-padding ion-no-margin"
                                size="10"
                              >
                                <div className="status-detail-container">
                                  <div className="remarks-status-container">
                                    <IonLabel className="status-label mb-paragraph ion-no-padding ion-no-margin">
                                      <i>{`${
                                        appointmentData.isCancelled
                                          ? "Cancelled"
                                          : "Booked"
                                      }`}</i>
                                    </IonLabel>
                                    <IonLabel className="mb-paragraph light ion-no-padding ion-no-margin">
                                      {`${getDayTimeDifference(
                                        toDateTimeFromSecs(
                                          appointmentData.createdAt.seconds
                                        )
                                      )} ago`}
                                    </IonLabel>
                                  </div>
                                  <IonLabel className="mb-paragraph bold ion-no-padding ion-no-margin">
                                    {appointmentData.isCancelled
                                      ? cancelledBy
                                      : `${appointmentData.patientDetails.firstName} ${appointmentData.patientDetails.lastName}`}
                                  </IonLabel>
                                </div>
                                {!!appointmentData.appointmentStatus &&
                                  appointmentData.appointmentStatus.map(
                                    (appointmentStatusDetail) => {
                                      return (
                                        <div className="status-detail-container">
                                          <div className="remarks-status-container">
                                            <IonLabel className="status-label mb-paragraph ion-no-padding ion-no-margin">
                                              <i>{`${
                                                appointmentStatusDetail.id ===
                                                APPOINTMENT_STATUS_TYPES.arrived
                                                  .id
                                                  ? APPOINTMENT_STATUS_TYPES
                                                      .arrived.name
                                                  : appointmentStatusDetail.id ===
                                                    APPOINTMENT_STATUS_TYPES
                                                      .onGoing.id
                                                  ? "Appointment"
                                                  : "Done"
                                              }`}</i>
                                            </IonLabel>
                                            <IonLabel className="mb-paragraph light ion-no-padding ion-no-margin">
                                              {`${getDayTimeDifference(
                                                toDateTimeFromSecs(
                                                  appointmentStatusDetail
                                                    .dateTimestamp.seconds
                                                )
                                              )} ago`}
                                            </IonLabel>
                                          </div>
                                          <IonLabel className="mb-paragraph bold ion-no-padding ion-no-margin">
                                            {`${
                                              _.find(
                                                userDetailsTimeLine,
                                                (detailTimeLine) =>
                                                  detailTimeLine.userId ===
                                                  appointmentStatusDetail.updatedBy
                                              )!.name || ""
                                            }`}
                                          </IonLabel>
                                          {!!(appointmentData as HospitalDoctorAppointmentsView)
                                            .prescription &&
                                            appointmentStatusDetail.id ===
                                              APPOINTMENT_STATUS_TYPES.done
                                                .id && (
                                              <>
                                                <IonLabel className="mb-paragraph ion-no-padding ion-no-margin">
                                                  {(appointmentData as HospitalDoctorAppointmentsView)
                                                    .prescription!.title || ""}
                                                </IonLabel>
                                                <IonLabel className="mb-paragraph ion-no-padding ion-no-margin">
                                                  {(appointmentData as HospitalDoctorAppointmentsView)
                                                    .prescription!
                                                    .description || ""}
                                                </IonLabel>
                                              </>
                                            )}
                                        </div>
                                      );
                                    }
                                  )}
                              </IonCol>
                            </>
                          )
                        )}
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="mb-view-appointment-buttons-container">
            <IonButton
              color={MBCOLORS.primary}
              mode="ios"
              fill="clear"
              className="view-appointment-button back ion-no-margin ion-no-padding"
              onClick={onClickBack}
            >
              Back
            </IonButton>
            {!!props.authUser.userType &&
              (props.authUser.userType === USER_TYPES.hospitals.id ||
                props.authUser.userType === USER_TYPES.doctors.id) &&
              !!appointmentData.appointmentStatus &&
              !_.includes(
                appointmentData.appointmentStatus.map((status) => status.id),
                APPOINTMENT_STATUS_TYPES.done.id
              ) && (
                <IonButton
                  mode="ios"
                  color={MBCOLORS.success}
                  className="view-appointment-button confirm mb-body white bold"
                  onClick={() => {
                    if (!!onMarkAsDone)
                      onMarkAsDone(appointmentData.docId || "");
                  }}
                >
                  Mark as done
                </IonButton>
              )}
            <IonButton
              color={MBCOLORS.danger}
              mode="ios"
              className="view-appointment-button cancel ion-no-margin ion-no-padding"
              disabled={
                appointmentData.isCancelled ||
                ((appointmentData.appointmentStatus &&
                  appointmentData.appointmentStatus.map(
                    (status: AppointmentStatus) =>
                      status.id === APPOINTMENT_STATUS_TYPES.done.id
                  )) as boolean | undefined)
              }
              onClick={() => {
                onClickCancel(appointmentData.docId as string);
              }}
            >
              <IonIcon className="cancel-icon" />
              Cancel
            </IonButton>
          </div>
        </IonCard>
      </div>
      {showQR && (
        <IonModal
          isOpen={showQR}
          cssClass="mb-view-qr-dialog"
          onDidDismiss={() => {
            setShowQR(false);
          }}
        >
          <div className="mb-qr-code-header">
            <IonLabel className="mb-qr-header-label mb-h4 dark-blue ion-no-padding ion-no-margin">
              QR Code
            </IonLabel>
          </div>
          <div className="mb-qr-code-container">
            <IonLabel className="mq-qr-code-note mb-paragraph ion-no-padding ion-no-margin">
              Present this code upon arrival on the hospital the day of the
              appointment was scheduled. We also suggest you to screenhot this
              for your convenience
            </IonLabel>
            <QRCode value={appointmentData.docId || ""} size={250} />
            <IonButton
              mode="ios"
              fill="clear"
              color={MBCOLORS.primary}
              className="mb-qr-code-back-button mb-h4 bold"
              onClick={() => {
                setShowQR(false);
              }}
            >
              Go Back
            </IonButton>
          </div>
        </IonModal>
      )}
    </>
  );
};
