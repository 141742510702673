import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonLabel,
  IonItem,
  IonInput,
  IonIcon,
  IonButton,
  IonTextarea,
} from "@ionic/react";

import "./MBInput.scss";
import { eyeOutline, eyeOffOutline, caretDown } from "ionicons/icons";

interface MBInputProps {
  type: "number" | "text" | "email" | "password" | "tel";
  label?: string;
  value: string;
  onChange?: (value: any) => void;
  onClick?: (event: React.MouseEvent) => void;
  error?: string;
  disabled?: boolean;
  readonly?: boolean;
  hasInfoTooltip?: boolean;
  disableTab?: boolean;
  placeholder?: string;
  removePasswordVisibility?: boolean;
  icon?: "dropdown";
  mode?: "textArea";
  className?: string;
  debounce?: number;
}
export const MBInput = (props: MBInputProps) => {
  const {
    type,
    label,
    value,
    onChange,
    onClick,
    error,
    hasInfoTooltip,
    disabled,
    readonly,
    disableTab,
    placeholder,
    removePasswordVisibility,
    mode,
    icon,
    className,
    debounce,
  } = props;
  const [hasFocus, setHasFocus] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [passwordType, setPasswordType] = useState(
    "password" as "password" | "text"
  );

  return (
    <div className={`${className}`}>
      <IonItem
        aria-autocomplete="none"
        className="mb-input-item ion-no-padding ion-no-margin"
        {...(!_.isEmpty(value) ? { lines: "none" } : {})}
      >
        {label !== undefined && (
          <IonLabel
            className={`mb-body bold ${!!error && "has-error"}`}
            position="floating"
          >
            {label} {hasInfoTooltip && <IonIcon className="mb-input-tooltip" />}
          </IonLabel>
        )}
        {mode === "textArea" ? (
          <IonTextarea
            autoGrow={true}
            value={value}
            className={`mb-input text-area ${
              hasFocus && !readonly && "has-focus"
            } ${!!error && "has-error"} mb-body`}
            onIonFocus={() => {
              setHasFocus(true);
            }}
            onIonBlur={() => {
              setHasFocus(false);
            }}
            disabled={disabled !== undefined ? disabled : false}
            {...(placeholder !== undefined && { placeholder })}
            {...(onChange !== undefined && {
              onIonChange: (event) => {
                const data = event.detail.value;
                onChange(data);
              },
            })}
            {...(onClick !== undefined && { onClick })}
            {...(readonly !== undefined && { readonly })}
          ></IonTextarea>
        ) : (
          <IonInput
            className={`mb-input ${hasFocus && !readonly && "has-focus"} ${
              !!error && "has-error"
            } mb-body`}
            type={type === "password" ? passwordType : type}
            value={value}
            autocomplete="off"
            onIonFocus={() => {
              setHasFocus(true);
            }}
            onIonBlur={() => {
              setHasFocus(false);
            }}
            disabled={disabled !== undefined ? disabled : false}
            {...(placeholder !== undefined && { placeholder })}
            {...(onChange !== undefined && {
              onIonChange: (event) => {
                const data = event.detail.value;
                onChange(data);
              },
            })}
            {...(onClick !== undefined && { onClick })}
            {...(readonly !== undefined && { readonly })}
            {...(disableTab && {
              onKeyDown: (event: React.KeyboardEvent) => {
                if (event.keyCode === 9) {
                  event.preventDefault();
                }
              },
            })}
            {...(debounce !== undefined && {
              debounce,
            })}
          >
            {icon === "dropdown" && (
              <IonIcon icon={caretDown} className="dropdown-icon" />
            )}
            {type === "password" && !removePasswordVisibility && (
              <IonButton
                slot="end"
                className="password-visibility"
                fill="clear"
                expand="block"
                tabIndex={-1}
                onClick={() => {
                  setPasswordType(
                    passwordVisibility === true ? "password" : "text"
                  );
                  setPasswordVisibility(!passwordVisibility);
                }}
              >
                <IonIcon
                  icon={passwordVisibility ? eyeOutline : eyeOffOutline}
                  color="primary"
                />
              </IonButton>
            )}
          </IonInput>
        )}
      </IonItem>
      {!!error && (
        <div className="ion-text-start">
          <IonLabel className="mb-input-error-label mb-h6 small has-error ion ion-no-margin ion-no-padding">
            {error}
          </IonLabel>
        </div>
      )}
    </div>
  );
};
