import { Helmet } from "react-helmet";
import React from "react";

import "./TermsAndConditions.scss";
import { isMobile } from "../../functions/common";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import { IonCard } from "@ionic/react";
import { termsAndConditions } from "./WeCANTermsandConditions";

class TermsAndConditions extends React.Component<MBProps> {
  render = () => {
    return (
      <>
        <Helmet>
          <title>MedBook - Terms and Condition</title>
        </Helmet>
        <MBContainer {...this.props}>
          <IonCard
            className={`terms-and-condition-card ${isMobile() && "mobile"}`}
          >
            <div
              className="terms-and-condition-html mb-body"
              dangerouslySetInnerHTML={{
                __html: termsAndConditions,
              }}
            ></div>
          </IonCard>
        </MBContainer>
      </>
    );
  };
}

export default TermsAndConditions;
