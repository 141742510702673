import * as _ from "lodash";
import moment from "moment";
import Calendar from "react-calendar";
import React from "react";
import { chevronBack, chevronForward } from "ionicons/icons";
import { IonIcon, IonCard } from "@ionic/react";

import "./MBCalendar.scss";
import { isSameDate } from "../../functions/common";
import { MBCOLORS } from "../../constants/config";

interface MBCalendarProps {
  selectedDate?: Date | null;
  onDateChange?: (date: Date) => void;
  onDateRangeChange?: (dateRange: Date[]) => void;
  maxDate?: Date;
  minDate?: Date;
  selectRange?: boolean;
  appointmentDates?: Date[];
}
export const MBCalendar = (props: MBCalendarProps) => {
  const {
    selectedDate,
    onDateChange,
    onDateRangeChange,
    maxDate,
    minDate,
    selectRange,
    appointmentDates,
  } = props;

  return (
    <IonCard color={MBCOLORS.tertiary} className="mb-calendar-card">
      <Calendar
        className="mb-custom-calendar"
        {...(selectedDate !== undefined && !_.isNull(selectedDate)
          ? { defaultValue: selectedDate }
          : {})}
        onChange={(date: Date | Date[]) => {
          if (selectRange && onDateRangeChange !== undefined) {
            onDateRangeChange(
              (date as Date[]).map((unformattedDate) => {
                const toFormatDate = _.cloneDeep(unformattedDate);
                toFormatDate.setHours(0);
                toFormatDate.setMinutes(0);
                toFormatDate.setSeconds(0);
                toFormatDate.setMilliseconds(0);
                return toFormatDate;
              })
            );
          } else if (onDateChange !== undefined) {
            const toFormatDate = _.cloneDeep(date as Date);
            toFormatDate.setHours(0);
            toFormatDate.setMinutes(0);
            toFormatDate.setSeconds(0);
            toFormatDate.setMilliseconds(0);
            onDateChange(toFormatDate);
          }
        }}
        calendarType="US"
        tileContent={() => {
          return <div></div>;
        }}
        minDetail="year"
        maxDate={maxDate}
        minDate={minDate}
        prevLabel={<IonIcon size="large" mode="md" icon={chevronBack} />}
        nextLabel={<IonIcon size="large" mode="md" icon={chevronForward} />}
        next2Label={null}
        prev2Label={null}
        formatShortWeekday={(local: string, date: Date) => {
          const weekday = moment(date).format("ddd")[0];
          return weekday;
        }}
        tileClassName={({ date, view }) => {
          if (appointmentDates !== undefined) {
            if (
              _.filter(appointmentDates, (appointmentDate) =>
                isSameDate(appointmentDate, date)
              ).length > 0
            ) {
              return "mb-profile-calendar-tile has-appointment";
            } else {
              return "mb-profile-calendar-tile";
            }
          } else {
            return "mb-profile-calendar-tile";
          }
        }}
        {...(selectRange !== undefined && { selectRange })}
      />
    </IonCard>
  );
};
