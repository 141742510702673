import React from "react";
import { IonButton, IonModal, IonLabel } from "@ionic/react";

import "./MBModal.scss";
import { isMobile } from "../../functions/common";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBCOLORS } from "../../constants/config";

interface MBModalProps {
  title: string;
  message: string;
  isOpen: boolean;
  onDidDismiss: () => void;
  onClick?: () => void;
  buttonLabel?: string;
  children: any;
  isEdit?: boolean;
  viewOnly?: boolean;
  submitLabel?: string;
  onSave: () => void;
  onBack: () => void;
  onClearSearch?: () => void;
  onCancelSchedule?: () => void;
}

export const MBModal = (props: MBModalProps) => {
  const {
    isOpen,
    onDidDismiss,
    title,
    message,
    children,
    isEdit,
    viewOnly,
    submitLabel,
    onSave,
    onBack,
    onClearSearch,
    onCancelSchedule,
  } = props;
  return (
    <IonModal
      cssClass="mb-appointment-details-dialog"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <div className="modal-content">
        <div className="mb-appointment-details-dialog-children-container">
          <IonLabel className="mb-appointment-details-dialog-title-label mb-h2 dark-blue">
            {title}
          </IonLabel>
          <IonLabel className="mb-appointment-details-dialog-message-label mb-body">
            {message}
          </IonLabel>
        </div>
        <div className="mb-appointment-details-dialog-children-content">
          {children}
        </div>
        <MBDivider />
        <div
          className={`mb-appointment-details-dialog-buttons-container ${
            isMobile() && "mobile"
          }`}
        >
          {!!onCancelSchedule && isMobile() ? (
            <>
              {!viewOnly && (
                <>
                  {onClearSearch !== undefined && (
                    <IonButton
                      onClick={onClearSearch}
                      className="mb-appointment-details-dialog-mobile-submit-button mb-body bold white ion-text-capitalize ion-no-margin"
                      color={MBCOLORS.danger}
                      mode="ios"
                    >
                      Clear Search
                    </IonButton>
                  )}
                  <IonButton
                    onClick={onSave}
                    className="mb-appointment-details-dialog-mobile-submit-button mb-body bold white ion-text-capitalize ion-no-margin"
                    color={MBCOLORS.primary}
                    mode="ios"
                  >
                    {submitLabel !== undefined
                      ? submitLabel
                      : isEdit
                      ? "Save"
                      : "Edit"}
                  </IonButton>
                </>
              )}
              <IonButton
                onClick={onBack}
                className="mb-appointment-details-dialog-mobile-no-button mb-body ion-text-capitalize ion-no-margin"
                fill="clear"
                mode="ios"
              >
                Go Back
              </IonButton>

              <IonButton
                fill="clear"
                color={MBCOLORS.danger}
                className="mb-appointment-details-dialog-mobile-cancel-button mb-body"
                mode="ios"
                onClick={onCancelSchedule}
              >
                Cancel Appointment
              </IonButton>
            </>
          ) : (
            <div className="mb-appointment-details-dialog-back-edit-container">
              <IonButton
                onClick={onBack}
                className="mb-appointment-details-dialog-no-button mb-body blue ion-text-capitalize"
                fill="clear"
              >
                Back
              </IonButton>
              {!viewOnly && (
                <div className="mb-appointment-details-dialog-buttons-container">
                  {onClearSearch !== undefined && (
                    <IonButton
                      onClick={onClearSearch}
                      className="mb-appointment-details-dialog-submit-button clear-search mb-body bold white ion-text-capitalize"
                      color={MBCOLORS.danger}
                    >
                      Clear Search
                    </IonButton>
                  )}
                  <IonButton
                    onClick={onSave}
                    className="mb-appointment-details-dialog-submit-button mb-body bold white ion-text-capitalize"
                    color={MBCOLORS.primary}
                  >
                    {submitLabel !== undefined
                      ? submitLabel
                      : isEdit
                      ? "Save"
                      : "Edit"}
                  </IonButton>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </IonModal>
  );
};
