import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonLabel,
  IonCardContent,
  IonButton,
  IonIcon,
} from "@ionic/react";

import "./MBAppointmentCard.scss";
import { isMobile } from "../../functions/common";
import { MBCOLORS } from "../../constants/config";

interface MBAppointmentCardPropsInterface {
  title: string;
  titleDescription: string;
  subtitle?: string;
  children: any;
  onSubmit: () => void;
  showFilter?: () => void;
  downloadSchedules?: () => void;
  gotoCalendarView?: () => void;
  showCalendar?: boolean;
  mode:
    | "department"
    | "patient-appointments"
    | "doctor-appointments"
    | "hospital-appointments"
    | "hospital-applications"
    | "transport-list"
    | "support-group-registrations";
  showActionButton?: boolean;
  cssCardWidth?: "support-hospital-card";
}
export const MBAppointmentCard = (props: MBAppointmentCardPropsInterface) => {
  const {
    title,
    titleDescription,
    subtitle,
    children,
    onSubmit,
    showFilter,
    // downloadSchedules, //todo: remove comment once ask to implement this feature
    gotoCalendarView,
    showCalendar,
    mode,
    cssCardWidth,
    showActionButton,
  } = props;
  return (
    <IonCard
      className={`mb-appointment-card ${cssCardWidth} ion-no-margin`}
      color={MBCOLORS.secondary}
    >
      <IonCardHeader
        color={
          mode === "transport-list" ? MBCOLORS.secondary : MBCOLORS.primary
        }
        className="mb-appointment-card-header"
      >
        <IonLabel
          className={`mb-body ${
            mode === "transport-list" ? "dark" : "white"
          } bold`}
        >
          {titleDescription}
        </IonLabel>
        <IonLabel
          className={`mb-h2 ${mode === "transport-list" ? "dark" : "white"}`}
        >
          {title}
        </IonLabel>
        <IonLabel className={`${isMobile() ? "mb-h6 small" : "mb-body"} white`}>
          {subtitle}
        </IonLabel>
        {mode === "transport-list" && (
          <IonIcon
            className={`mb-card-header-logo magsikilos ${
              isMobile() ? "mobile" : ""
            }`}
          />
        )}
      </IonCardHeader>
      <IonCardContent className="mb-appointment-card-content ion-no-padding">
        <div className="mb-content-container">{children}</div>

        {showActionButton &&
          !showCalendar &&
          mode !== "hospital-applications" &&
          mode !== "transport-list" &&
          mode !== "support-group-registrations" && (
            <>
              <div className="mb-appointment-card-footer">
                <div
                  className={`mb-appointment-card-footer-main-buttons ${
                    isMobile() ? "mobile" : ""
                  }`}
                >
                  {gotoCalendarView !== undefined && (
                    <IonButton
                      onClick={gotoCalendarView}
                      className={`mb-appointment-card-set-appointment-button mb-body ion-text-capitalize ${
                        isMobile() ? "mobile" : ""
                      }`}
                      color={MBCOLORS.secondary}
                    >
                      Back to Calendar View
                    </IonButton>
                  )}
                  {showFilter !== undefined && (
                    <IonButton
                      onClick={showFilter}
                      className={`mb-appointment-card-set-appointment-button mb-body ion-text-capitalize ${
                        isMobile() ? "mobile" : ""
                      }`}
                      color={MBCOLORS.secondary}
                    >
                      Filter appointments
                    </IonButton>
                  )}
                  {["department", "patient-appointments"].indexOf(mode) !==
                    -1 && (
                    <IonButton
                      onClick={onSubmit}
                      className={`mb-appointment-card-set-appointment-button mb-body ion-text-capitalize ${
                        isMobile() ? "mobile" : ""
                      }`}
                      color={MBCOLORS.secondary}
                    >
                      {mode === "department"
                        ? "Add doctor/service"
                        : "Set Appointment"}
                    </IonButton>
                  )}
                  {/* //TODO: remove comment once download is ask to implement */}
                  {/* {downloadSchedules !== undefined && (
                    <IonButton
                      onClick={downloadSchedules}
                      className={`mb-appointment-card-set-appointment-button mb-body ion-text-capitalize ${isMobile() ? "mobile": ""}`}
                      color={WCCOLORS.tertiary}
                    >
                      Download Schedules
                    </IonButton>
                  )} */}
                </div>
                {["hospital-appointments", "doctor-appointments"].indexOf(
                  mode
                ) !== -1 && (
                  <div>
                    {
                      <IonButton
                        onClick={onSubmit}
                        className="mb-appointment-card-set-appointment-button clear-schedule mb-body red ion-text-capitalize"
                        fill="clear"
                      >
                        Clear Schedules
                      </IonButton>
                    }
                  </div>
                )}
              </div>
            </>
          )}
      </IonCardContent>
    </IonCard>
  );
};
