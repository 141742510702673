import * as _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { IonLoading, IonToast } from "@ionic/react";

import "./TimeKeepingScan.scss";
import * as services from "../../services";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps, UserType } from "../../interface";
import { MBTimeKeepingScan } from "../../components/MBTimeKeepingScan/MBTimeKeepingScan";
import { MBQRresultDialog } from "../../components/MBQRresultDialog/MBQRresultDialog";
import {
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
  TimeKeeping,
} from "../../models";
import { MSGS_COMMON } from "../../constants/messages";
import { APPOINTMENT_STATUS_TYPES, MBCOLORS } from "../../constants/config";
import { isToday } from "../../functions/common";

class TimeKeepingScan extends React.Component<MBProps> {
  state = {
    loading: false,
    isResultFound: false,
    qrResultOpenDialog: false,
    selected: 0,
    successConfirmArrival: false,
    qrAppointment: {} as
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView,
    isDoctorAppointment: false,
    error: "",
  };

  getAppointment = async (qrAppointmentId: string) => {
    try {
      this.setState({
        loading: true,
      });
      const userDetail = this.props.authUser.userDetails as TimeKeeping;
      const qrResult = await services.getAppointment(
        qrAppointmentId,
        null,
        userDetail.hospitalId
      );

      const isResultFound =
        !_.isEmpty(qrResult) &&
        _.isEmpty(qrResult.appointmentStatus) &&
        isToday(qrResult.appointmentDate) &&
        !qrResult.isCancelled;

      if (isResultFound) {
        this.setState({
          isDoctorAppointment: !_.isEmpty(
            (qrResult as HospitalDoctorAppointmentsView).doctorDetails
          ),
        });
      }

      this.setState({
        isResultFound,
        qrResultOpenDialog: true,
        qrAppointment: _.isEmpty(qrResult.appointmentStatus) ? qrResult : {},
        loading: false,
      });
    } catch (e) {
      this.setState({
        isResultFound: false,
        qrResultOpenDialog: true,
        qrAppointment: {},
        loading: false,
      });
    }
  };

  updateAppointmentStatus = async (appointmentId: string) => {
    try {
      this.setState({
        loading: true,
      });
      const { authUser } = this.props;
      await services.pushAppointmentProgress(
        appointmentId,
        this.state.isDoctorAppointment,
        APPOINTMENT_STATUS_TYPES.arrived.id,
        authUser.uid,
        authUser.userType as UserType
      );
      this.setState({ successConfirmArrival: true, loading: false });
    } catch (error) {
      console.log("ERROR???", error);
      this.setState({ error, loading: false });
    }
  };

  render = () => {
    const {
      loading,
      isResultFound,
      qrResultOpenDialog,
      qrAppointment,
      successConfirmArrival,
      error,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>MedBook - Time Keeping - Patient List</title>
        </Helmet>
        <MBContainer {...this.props} activePage="time-keeping-scan">
          <div className="time-keeping-scan-container">
            <MBTimeKeepingScan
              mode="security"
              isResultFound={isResultFound}
              onQRScan={(qrResult) => {
                this.getAppointment(qrResult);
              }}
            />
          </div>
          <MBQRresultDialog
            mode="security"
            isOpen={qrResultOpenDialog}
            onDidDismiss={() => {
              this.setState({
                qrResultOpenDialog: false,
                isResultFound: false,
              });
            }}
            qrData={qrAppointment}
            successConfirmArrival={successConfirmArrival}
            onDidDismissSuccessConfirm={() => {
              this.setState({
                successConfirmArrival: false,
                qrResultOpenDialog: false,
                isResultFound: false,
              });
            }}
            onConfirmArrival={(appointmentId: string) => {
              this.updateAppointmentStatus(appointmentId);
            }}
          />
          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />
          <IonToast
            isOpen={!_.isEmpty(error)}
            message={error}
            duration={2000}
            onDidDismiss={() => this.setState({ error: "" })}
            color={MBCOLORS.danger}
          />
        </MBContainer>
      </>
    );
  };
}

export default TimeKeepingScan;
