import moment from "moment";
import * as _ from "lodash";
import React, { useState } from "react";

import "./MBDateInput.scss";
import { IonItem, IonLabel, IonDatetime, IonIcon } from "@ionic/react";
import { MBCOLORS } from "../../constants/config";
import { calendarOutline } from "ionicons/icons";

interface MBDateInputProps {
  title?: string;
  value?: Date | null;
  minValue?: Date;
  maxValue?: Date;
  displayFormat?: string;
  placeholder?: string;
  hideIcon?: boolean;
  className?: string;
  error?: string;
  onChange: (date: Date) => void;
}
export const MBDateInput = (props: MBDateInputProps) => {
  const {
    title,
    value,
    minValue,
    maxValue,
    displayFormat,
    placeholder,
    hideIcon,
    className,
    error,
    onChange,
  } = props;

  const [hasFocus, setHasFocus] = useState(false);
  return (
    <>
      <IonItem
        lines="none"
        color={MBCOLORS.tertiary}
        className={`${!!className ? className : ""}  mb-input-container ${
          !!error ? "item-has-error" : ""
        } ion-no-padding ion-no-margin`}
      >
        <IonLabel
          className={`mb-date-input-label mb-body bold ${
            !!hasFocus ? "has-focus" : ""
          }`}
          position="floating"
        >
          {!_.isEmpty(title) ? title : "Selected Date"}
        </IonLabel>
        <IonDatetime
          className={`${!value ? "no-value" : ""} ${
            !!error && "has-error"
          } mb-date-input mb-body ion-no-margin ion-no-padding`}
          // picker-format="DDD/MM/DD/YYYY"
          displayFormat={!!displayFormat ? displayFormat : "DDD, MM/DD/YYYY"}
          {...(!!minValue && { min: minValue.toISOString() })}
          max={
            !!maxValue
              ? maxValue.toISOString()
              : moment(new Date()).add(10, "years").toISOString()
          }
          value={!!value ? value.toISOString() : value}
          onIonFocus={() => {
            setHasFocus(true);
          }}
          onIonBlur={() => {
            setHasFocus(false);
          }}
          onIonChange={(selectedTime) => {
            onChange(new Date(selectedTime.detail.value as string));
          }}
          {...(!!placeholder
            ? {
                placeholder,
              }
            : {})}
        ></IonDatetime>
        {!hideIcon && (
          <IonIcon className="mb-input-icon" icon={calendarOutline} />
        )}
      </IonItem>
      {!!error && (
        <div className="ion-text-start">
          <IonLabel className="mb-date-input-error-label mb-h6 small has-error ion ion-no-margin ion-no-padding">
            {error}
          </IonLabel>
        </div>
      )}
    </>
  );
};
