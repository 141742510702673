import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonLabel,
  IonButton,
  IonToast,
} from "@ionic/react";

import "./ResetPasswordForm.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import { MSGS_COMMON_FORM_ERROR } from "../../constants/messages";
import { MBCOLORS } from "../../constants/config";
import qs from "qs";

export const ResetPasswordForm = (props: MBProps) => {
  const { history } = props;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [error, setError] = useState("");

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    let error = "";
    if (_.isEmpty(password)) {
      error = MSGS_COMMON_FORM_ERROR.password;
      setPasswordError(error);
    } else if (_.isEmpty(confirmPassword)) {
      error = MSGS_COMMON_FORM_ERROR.passwordConfirm;
      setConfirmPasswordError(error);
    } else if (password !== confirmPassword) {
      error = MSGS_COMMON_FORM_ERROR.passwordNotMatch;
      setConfirmPasswordError(error);
    }

    if (!error) {
      try {
        const obj = qs.parse(props.location.search);

        await services.doResetPassword(obj.oobCode as string, password);
        setIsOpenSuccessDialog(true);
      } catch (error) {
        console.log("error -- doResetPassword -- ", error);
        setError(error);
      }
    }
  };
  return (
    <>
      <IonCard
        className="reset-password-form-card ion-no-margin"
        color={MBCOLORS.tertiary}
      >
        <IonCardContent className="reset-password-form-card-content ion-no-padding">
          <IonIcon className="reset-password-form-card-logo" />
          <div className="reset-password-form-details">
            <IonLabel className="mb-h2 bold">Reset Password</IonLabel>
            <div className="reset-password-info-container">
              <IonIcon className="reset-password-info" />
              <IonLabel className="reset-password-description mb-paragraph blue">
                Passwords must be at least 8-16 characters in length.
              </IonLabel>
            </div>

            <form onSubmit={submitForm}>
              <div className="reset-password-form-input-container">
                <div className="reset-password-form-email-input">
                  <MBInput
                    type="password"
                    label="Password"
                    value={password}
                    onChange={(password: string) => {
                      if (password.length > 16) {
                        setPassword(password);
                        setPasswordError(
                          MSGS_COMMON_FORM_ERROR.passwordExceedLimit
                        );
                        setPassword(password);
                      } else if (password.length < 8) {
                        setPassword(password);
                        setPasswordError(
                          MSGS_COMMON_FORM_ERROR.passwordBelowLimit
                        );
                      } else {
                        setPassword(password);
                        setPasswordError("");
                      }
                    }}
                    error={passwordError}
                  />
                </div>
                <div className="reset-password-form-email-input">
                  <MBInput
                    type="password"
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(confirmPassword: string) => {
                      setConfirmPassword(confirmPassword);
                      setConfirmPasswordError("");
                    }}
                    error={confirmPasswordError}
                  />
                </div>
              </div>

              <IonButton
                color={MBCOLORS.primary}
                className="reset-password-form-send-button ion-text-capitalize"
                type="submit"
              >
                Save
              </IonButton>
            </form>
          </div>
        </IonCardContent>
      </IonCard>
      <MBDialog
        isOpen={isOpenSuccessDialog}
        icon="success"
        title="Success Password Changed!"
        message="Please Login using your new password."
        onDidDismiss={() => {
          setIsOpenSuccessDialog(false);
          if (!_.isEmpty(props.authUser)) {
            services.signOut();
            history.push(routes.LOGIN_WITH_EMAIL);
            window.location.reload();
          } else {
            history.push(routes.LOGIN_WITH_EMAIL);
            window.location.reload();
          }
        }}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        color="danger"
        onDidDismiss={() => {
          setPasswordError("");
        }}
      />
    </>
  );
};
