import * as _ from "lodash";
import { caretDown } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonToolbar,
  IonHeader,
  IonLabel,
  IonPopover,
  IonToast,
  IonIcon,
  IonItem,
  IonList,
  IonButton,
} from "@ionic/react";

import "./MBHeader.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { isMobile, useEffectOnlyOnce } from "../../functions/common";
import { MBProps, Pages } from "../../interface";
import { MBCOLORS, USER_TYPES } from "../../constants/config";
import {
  BookListItem,
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
} from "../../models";
import { MBBookListDialog } from "../MBBookListDialog/MBBookListDialog";
import { MBAppointmentNotifDialog } from "../MBAppointmentNotifDialog/MBAppointmentNotifDialog";

interface MBHeaderProps extends MBProps {
  activePage?: Pages;
  onHideSideMenu?: () => void;
}

interface NavLink {
  name: string;
  routeURL: string;
  page: Pages;
  icon?: string;
  target?: string;
  onClick?: () => void;
}

export const MBHeader: React.FunctionComponent<MBHeaderProps> = (props) => {
  const { authUser, history } = props;
  const [showNavLinks, setShowNavLinks] = useState(false);
  const [authPasswordSent, setAuthPasswordSent] = useState("");
  const [dropdownEventWeb, setDropdownEventWeb] = useState(undefined) as any;
  const [booklist, setBooklist] = useState(null as BookListItem[] | null);
  const [waitList, setWaitList] = useState(null as BookListItem[] | null);
  const [doctorAppointmentList, setDoctorAppointmentList] = useState(
    null as HospitalDoctorAppointmentsView[] | null
  );
  const [serviceAppointmentList, setServiceAppointmentList] = useState(
    null as HospitalServiceAppointmentsView[] | null
  );

  const [booklistDialogOpen, setBooklistDialogOpen] = useState(false);
  const [waitlistDialogOpen, setWaitListDialogOpen] = useState(false);
  const [appointmentListDialogOpen, setAppointmentListDialogOpen] = useState(
    false
  );
  const [error, setError] = useState("");

  const navLinks: NavLink[] = [
    ...(!!props.authUser &&
    (authUser.emailVerified ||
      authUser.userType === USER_TYPES.doctors.id ||
      authUser.userType === USER_TYPES.support.id ||
      authUser.userType === USER_TYPES.techAdmin.id ||
      authUser.userType === USER_TYPES.timeKeeping.id ||
      authUser.userType === USER_TYPES.executiveAssistant.id)
      ? authUser.userType === USER_TYPES.doctors.id
        ? [
            {
              name: "My Account",
              routeURL: routes.DOCTOR_ACCOUNT,
              icon: "my-account",
              page: "account" as Pages,
            },
          ]
        : authUser.userType === USER_TYPES.hospitals.id
        ? [
            {
              name: "My Account",
              routeURL: routes.HOSPITAL_REP_ACCOUNT,
              page: "account" as Pages,
              icon: "my-account",
            },
          ]
        : authUser.userType === USER_TYPES.patients.id
        ? [
            {
              name: "My Account",
              routeURL: routes.PATIENT_ACCOUNT,
              page: "account" as Pages,
              icon: "my-account",
            },
          ]
        : authUser.userType === USER_TYPES.timeKeeping.id
        ? [
            {
              name: "My Account",
              routeURL: routes.TIME_KEEPING_ACCOUNT,
              icon: "my-account",

              page: "account" as Pages,
            },
          ]
        : authUser.userType === USER_TYPES.executiveAssistant.id
        ? [
            {
              name: "My Account",
              routeURL: routes.EXECUTIVE_ASSISTANT_ACCOUNT,
              icon: "my-account",
              page: "account" as Pages,
            },
          ]
        : []
      : []),
    {
      name: "Logout",
      routeURL: routes.LOGIN_OPTIONS,
      page: "" as Pages,
      icon: "logout",
      onClick: async () => {
        console.log("LOG OUT");
        await services.signOut();
        setTimeout(() => {
          window.location.reload();
        }, 150);
      },
    },
  ];

  useEffectOnlyOnce(() => {
    if (!_.isEmpty(authUser)) {
      if (authUser.userType === USER_TYPES.patients.id) {
        getBooklist(authUser.uid);
        getWaitList(authUser.uid);
      } else if (authUser.userType === USER_TYPES.hospitals.id) {
        getAppointmentList(false);
      } else if (authUser.userType === USER_TYPES.doctors.id) {
        getAppointmentList(true);
      }
    }
  });

  const getBooklist = async (patientId: string) => {
    services.getBookList(patientId, (booklist, error) => {
      if (_.isEmpty(error)) {
        console.log("GOT BOOKING LIST", booklist);
        setBooklist(booklist);
      } else {
        setError(error!);
      }
    });
  };

  const getWaitList = async (patientId: string) => {
    services.getBookList(
      patientId,
      (waitList, error) => {
        if (_.isEmpty(error)) {
          console.log("GOT WAITING LIST", waitList);
          setWaitList(waitList);
        } else {
          setError(error!);
        }
      },
      true
    );
  };

  const getAppointmentList = async (isDoctor: boolean) => {
    try {
      services.getDoctorAppointmentsPatientOrHospital(
        isDoctor ? "doctor" : "hospital",
        authUser.uid,
        (hospitalDoctorAppointments, error) => {
          if (!error) {
            const filteredAppointment = _.filter(
              hospitalDoctorAppointments,
              (appointment) => !appointment.isCancelled
            );
            setDoctorAppointmentList(
              _.filter(
                filteredAppointment,
                (appointment) =>
                  _.isEmpty(appointment.viewedByUsers) ||
                  !appointment.viewedByUsers!.includes(authUser.uid)
              )
            );
          }
        },
        new Date(),
        true
      );
      if (!isDoctor) {
        services.getServiceAppointmentsPatientOrHospital(
          "hospital",
          authUser.uid,
          (hospitalServiceAppointments, error) => {
            if (!error) {
              const filteredAppointment = _.filter(
                hospitalServiceAppointments,
                (appointment) => !appointment.isCancelled
              );
              setServiceAppointmentList(
                _.filter(
                  filteredAppointment,
                  (appointment) =>
                    _.isEmpty(appointment.viewedByUsers) ||
                    !appointment.viewedByUsers!.includes(authUser.uid)
                )
              );
            }
          },
          new Date(),
          true
        );
      }
    } catch (error) {
      console.log("Error -- ", error);
    }
  };

  return (
    <>
      <IonHeader mode="ios">
        <IonToolbar className="mb-header-toolbar" color={MBCOLORS.tertiary}>
          {!!props.authUser && !!props.authUser.userDetails && (
            <IonButton
              color={MBCOLORS.medium}
              className="mb-header-side-nav-button"
              onClick={() => {
                if (!!props.onHideSideMenu) props.onHideSideMenu();
              }}
            >
              <IonIcon className="mb-header-side-menu-icon" />
            </IonButton>
          )}
          <IonIcon
            className={`mb-header-logo ${
              isMobile() && "mobile"
            } mb-h2 ion-no-padding ion-no-margin`}
            slot="start"
            onClick={() => {
              props.history.push(routes.LANDING);
            }}
          />

          <div className="mb-header-links-container">
            {!_.isNull(waitList) && (
              <IonButton
                fill="clear"
                id="mb-header-wait-list-button"
                onClick={() => {
                  if (waitList.length > 0) {
                    setWaitListDialogOpen(true);
                  }
                }}
              >
                <IonIcon className="mb-header-wait-list-icon" />
                {waitList.length > 0 && (
                  <div className="mb-header-book-list-counter">
                    {waitList.length}
                  </div>
                )}
              </IonButton>
            )}
            {!_.isNull(booklist) && (
              <IonButton
                fill="clear"
                id="mb-header-book-list-button"
                onClick={() => {
                  if (booklist.length > 0) {
                    setBooklistDialogOpen(true);
                  }
                }}
              >
                <IonIcon className="mb-header-book-list-icon" />
                {booklist.length > 0 && (
                  <div className="mb-header-book-list-counter">
                    {booklist.length}
                  </div>
                )}
              </IonButton>
            )}
            {!_.isNull(doctorAppointmentList) &&
              ((authUser.userType === USER_TYPES.hospitals.id &&
                !_.isNull(serviceAppointmentList)) ||
                authUser.userType === USER_TYPES.doctors.id) && ( // only need to check doctor since this will cover hospital and doctor checking allready
                <IonButton
                  fill="clear"
                  id="mb-header-book-list-button"
                  onClick={() => {
                    const consolidatedAppointments = [
                      ...doctorAppointmentList,
                      ...(serviceAppointmentList || [])!,
                    ] as (
                      | HospitalDoctorAppointmentsView
                      | HospitalServiceAppointmentsView
                    )[];
                    if (consolidatedAppointments.length > 0) {
                      setAppointmentListDialogOpen(true);
                    }
                  }}
                >
                  <IonIcon className="mb-header-book-list-icon" />
                  {([
                    ...doctorAppointmentList,
                    ...(serviceAppointmentList || [])!,
                  ] as (
                    | HospitalDoctorAppointmentsView
                    | HospitalServiceAppointmentsView
                  )[]).length > 0 && (
                    <div className="mb-header-book-list-counter">
                      {
                        ([
                          ...doctorAppointmentList,
                          ...(serviceAppointmentList || [])!,
                        ] as (
                          | HospitalDoctorAppointmentsView
                          | HospitalServiceAppointmentsView
                        )[]).length
                      }
                    </div>
                  )}
                </IonButton>
              )}
            {!!props.authUser && (
              <IonItem
                mode="md"
                button={true}
                color={MBCOLORS.tertiary}
                className="mb-nav-button mb-h4 bold ion-no-margin ion-no-padding ion-align-items-start ion-text-capitalize"
                lines="none"
                onClick={(e) => {
                  setDropdownEventWeb(e.nativeEvent);
                  setShowNavLinks(true);
                }}
              >
                <div className="nav-button-container">
                  <IonLabel className="mb-nav-dropdown-label mb-h4 medium">
                    {!!authUser && !!authUser.userDetails
                      ? `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`
                      : "Need Help"}
                  </IonLabel>
                  <IonIcon
                    className="mb-nav-button-drop-down-icon"
                    icon={caretDown}
                  />
                </div>
              </IonItem>
            )}
          </div>
        </IonToolbar>
      </IonHeader>

      <IonPopover
        mode="md"
        isOpen={showNavLinks}
        event={dropdownEventWeb}
        cssClass="mb-nav-popover noselect ion-no-padding"
        showBackdrop={false}
        onDidDismiss={() => {
          setShowNavLinks(false);
        }}
      >
        <div className="mb-nav-list-container">
          <IonList className="mb-nav-list">
            <div className="mb-nav-item-container">
              {navLinks.map((navLink, index) => {
                return (
                  <IonItem
                    key={`nav_header_link_${index}`}
                    className="mb-nav-dropdown-modal-item ion-justify-content-center"
                    button={true}
                    lines="none"
                    routerLink={navLink.routeURL}
                    {...(!!navLink.onClick && { onClick: navLink.onClick })}
                  >
                    <IonLabel className="mb-nav-dropdown-label mb-h5">
                      <IonIcon
                        className={`mb-nav-dropdown-modal-item-icon ${navLink.icon}`}
                        slot="start"
                      />
                      {navLink.name}
                    </IonLabel>
                  </IonItem>
                );
              })}
            </div>
          </IonList>
        </div>
      </IonPopover>

      {!_.isNull(booklist) && !!booklistDialogOpen && (
        <MBBookListDialog
          {...props}
          isOpen={booklistDialogOpen}
          onDidDismiss={() => {
            setBooklistDialogOpen(false);
          }}
          bookList={booklist}
        />
      )}

      {!_.isNull(waitList) && (
        <MBBookListDialog
          {...props}
          isOpen={waitlistDialogOpen}
          onDidDismiss={() => {
            setWaitListDialogOpen(false);
          }}
          waitList={true}
          bookList={waitList}
        />
      )}

      {!_.isNull(doctorAppointmentList) &&
        ((authUser.userType === USER_TYPES.hospitals.id &&
          !_.isNull(serviceAppointmentList)) ||
          authUser.userType === USER_TYPES.doctors.id) && (
          <MBAppointmentNotifDialog
            {...props}
            isOpen={appointmentListDialogOpen}
            onDidDismiss={() => {
              setAppointmentListDialogOpen(false);
            }}
            appointmentList={[
              ...doctorAppointmentList,
              ...(serviceAppointmentList || []),
            ]}
          />
        )}

      <IonToast
        isOpen={!_.isEmpty(authPasswordSent)}
        message={authPasswordSent}
        duration={2000}
        onDidDismiss={() => setAuthPasswordSent("")}
        color={MBCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
