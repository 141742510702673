import * as _ from "lodash";
import firebase from "firebase";
import moment from "moment";

import * as sms from "../functions/sms";
import * as email from "../functions/email";
import { firestore } from "../firebase";
import {
  getServerTimestamp,
  daysSinceDate,
  isHistoricalDate,
  isToday,
} from "../functions/common";
import {
  AvailableServiceSchedule,
  AvailableDoctorSchedule,
  RESOURCE_TYPES,
} from "../constants/config";
import {
  DaysOfWeek,
  CancerType,
  Period,
  PaymentMethod,
  AppointmentStatusType,
  UserType,
} from "../interface";
import {
  ServiceSchedule,
  DoctorSchedule,
  HospitalDoctorAppointmentsOLD,
  Doctor,
  Hospital,
  DoctorAppointmentPatientView,
  ServiceAppointmentPatientView,
  HospitalServiceAppointmentsOLD,
  DoctorAppointmentView,
  Patient,
  Coordinates,
  HospitalDoctorAppointments,
  HospitalServiceAppointments,
  HospitalService,
  DoctorHospitals,
  BookingFee,
  BookListItem,
  DoctorResource,
  ServiceResource,
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
  Remarks,
  FamilyMember,
  ExecutiveAssistant,
} from "../models";
import {
  HOSPITAL_SERVICES,
  HOSPITAL_SERVICES_SCHEDULE,
  DOCTOR_HOSPITALS,
  HOSPITAL_SERVICE_APPOINTMENTS,
  DOCTOR_SCHEDULE,
  HOSPITAL_DOCTOR_APPOINTMENTS,
  DOCTORS,
  HOSPITALS,
  PATIENTS,
  BOOKING_FEE,
  BOOK_LIST,
  FAMILY_MEMBERS,
  EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL,
  EXECUTIVE_ASSISTANT,
} from "../constants/dbCollections";
import { sendEmail } from "./users";
import { sendSMS } from "../api";

export const getBookingFee = async () => {
  const bookingFeeQuery = await firestore.collection(BOOKING_FEE).get();
  if (!bookingFeeQuery.empty) {
    const bookingFeeData = bookingFeeQuery.docs[0].data() as BookingFee;

    return bookingFeeData.fee;
  } else {
    return 50;
  }
};

export const deletedSelectedBookingList = async (bookingId: string) => {
  try {
    await firestore.collection(BOOK_LIST).doc(bookingId).delete();
  } catch (error) {
    console.log(
      "error -- deletedSelectedBookingList -- ",
      deletedSelectedBookingList
    );
  }
};

export const addBookList = async (
  appointment: HospitalDoctorAppointments | HospitalServiceAppointments,
  resource: DoctorResource | ServiceResource,
  isDoctorResource: boolean,
  isWaitList?: boolean,
  toCheckout?: boolean
) => {
  await firestore.collection(BOOK_LIST).add({
    appointment,
    resource,
    isDoctorResource,
    ...(isWaitList && { isWaitList }),
    ...(toCheckout && { toCheckout }),
    createdAt: getServerTimestamp(),
  });
};

export const getBookList = (
  patientId: string,
  callback: (booklist: BookListItem[], error?: string) => void,
  waitListed?: boolean
) => {
  try {
    firestore
      .collection(BOOK_LIST)
      .where("appointment.patientId", "==", patientId)
      .onSnapshot(
        (bookList) => {
          if (!bookList.empty) {
            callback(
              _.filter(
                bookList.docs.map((bookListItem) => {
                  return {
                    ...bookListItem.data(),
                    docId: bookListItem.id,
                  } as BookListItem;
                }),
                (booking) => {
                  if (waitListed) {
                    return (
                      booking.isWaitList !== undefined && booking.isWaitList
                    );
                  } else {
                    return !booking.isWaitList;
                  }
                }
              )
            );
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([], error.message);
        }
      );
  } catch (e) {
    console.log("ERROR IN getBookList: ", e);
    callback([], e);
  }
};

export const getBookListItem = async (docId: string) => {
  const result = await firestore.collection(BOOK_LIST).doc(docId).get();
  if (result.exists) {
    return {
      docId,
      ...result.data(),
    } as BookListItem;
  } else {
    return null;
  }
};

export const deleteBookList = async (docId: string) => {
  await firestore.collection(BOOK_LIST).doc(docId).delete();
};

export const setBookListAsUnavailable = async (docId: string) => {
  await firestore.collection(BOOK_LIST).doc(docId).update({
    unavailable: true,
  });
};

export const setBookListToCheckout = async (
  docId: string,
  toCheckout: boolean
) => {
  await firestore.collection(BOOK_LIST).doc(docId).update({
    toCheckout,
  });
};

/**
 * This is to check availability of a date by matching hospitalService to hospitalServiceSchedule
 * to latest appointent in hospitalServiceAppointments to get available schedule
 *
 *
 * @param dayOfWeek for day of the week
 * @param service
 * @param hospitalId
 * @param appointmentDate
 */
export const getAvailableServiceSchedule = async (
  dayOfWeek: DaysOfWeek,
  service: string,
  appointmentDate: Date,
  slot?: number
) => {
  // get hospital service
  const hospitalService = await firestore
    .collection(HOSPITAL_SERVICES)
    .doc(service)
    .get();

  if (hospitalService.exists && !isHistoricalDate(appointmentDate)) {
    const hospitalServiceData = {
      ...hospitalService.data(),
      docId: hospitalService.id,
    } as HospitalService;

    // get schedule for that service for the appointmentDate
    const hospitalServiceSchedule = await firestore
      .collection(HOSPITAL_SERVICES_SCHEDULE)
      .where("hospitalServiceId", "==", hospitalServiceData.docId)
      .where("dayOfWeek", "==", dayOfWeek)
      .get();

    if (hospitalServiceSchedule.docs.length > 0) {
      const hospitalServiceScheduleData = {
        ...hospitalServiceSchedule.docs[0].data(),
        docId: hospitalServiceSchedule.docs[0].id,
      } as ServiceSchedule;
      const slotsPerDay = hospitalServiceScheduleData.slots;

      const dateMin = _.clone(appointmentDate);
      dateMin.setHours(0);
      dateMin.setMinutes(0);
      dateMin.setSeconds(0);
      dateMin.setMilliseconds(0);

      const dateMax = _.clone(appointmentDate);
      dateMax.setHours(23);
      dateMax.setMinutes(59);
      dateMax.setSeconds(59);
      dateMax.setMilliseconds(59);

      // get bookings for that schedule
      const appointmentsThatDay = await firestore
        .collection(HOSPITAL_SERVICE_APPOINTMENTS)
        .where("serviceScheduleId", "==", hospitalServiceScheduleData.docId)
        .where("appointmentDate", ">=", dateMin)
        .where("appointmentDate", "<=", dateMax)
        .where("isCancelled", "==", false)
        .get();

      let takenSlots = [] as number[];
      const availableSchedule = [] as AvailableServiceSchedule[];
      if (!appointmentsThatDay.empty) {
        takenSlots = appointmentsThatDay.docs.map((appointment) => {
          const appointmentData = appointment.data() as HospitalServiceAppointments;
          return appointmentData.slotNumber;
        });
      }

      const timeSlots: { [slot: number]: number } = {};
      _.chunk(_.range(slotsPerDay), hospitalServiceData.maxNoOfPatient).forEach(
        (slots, index) => {
          slots.forEach((slot) => {
            timeSlots[slot + 1] = index;
          });
        }
      );

      for (let slot = 1; slot <= slotsPerDay; slot++) {
        if (!takenSlots.includes(slot)) {
          const startTime = _.clone(
            hospitalServiceScheduleData.startTime.toDate()
          );
          startTime.setFullYear(appointmentDate.getFullYear());
          startTime.setMonth(appointmentDate.getMonth());
          startTime.setDate(appointmentDate.getDate());
          const minutePerAppointment = _.clone(
            hospitalServiceScheduleData.estimatedAppointmentMinuteLength
          );
          const estimatedAppointmentTime = moment(startTime)
            .add(minutePerAppointment * timeSlots[slot], "minutes")
            .toDate();

          availableSchedule.push({
            availableSchedule: hospitalServiceScheduleData,
            slot,
            estimatedAppointmentTime,
          });
        }
      }

      return slot === undefined
        ? availableSchedule
        : !_.isEmpty(
            _.find(
              availableSchedule,
              (availableScheduleItem) => availableScheduleItem.slot === slot
            )
          )
        ? availableSchedule
        : null;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

/**
 * This is to check availability of a date by matching hospitalService to hospitalServiceSchedule
 * to latest appointent in hospitalServiceAppointments to get available schedule
 *
 * Note: this function will only return one per day since there's only one service per hospital per day
 *
 * @param dayOfWeek for day of the week
 * @param service
 * @param hospitalId
 * @param appointmentDate
 */
export const getAvailableServiceScheduleOLD = async (
  dayOfWeek: DaysOfWeek,
  service: string,
  hospitalId: string,
  appointmentDate: Date
) => {
  // get hospital service
  const hospitalService = await firestore
    .collection(HOSPITAL_SERVICES)
    .where("service", "==", service)
    .where("hospitalId", "==", hospitalId)
    .get();

  if (hospitalService.docs.length > 0) {
    const hospitalServiceDoc = hospitalService.docs[0];

    // get schedule for that service for the appointmentDate
    const hospitalServiceSchedule = await firestore
      .collection(HOSPITAL_SERVICES_SCHEDULE)
      .where("hospitalServiceId", "==", hospitalServiceDoc.id)
      .where("dayOfWeek", "==", dayOfWeek)
      .get();

    if (hospitalServiceSchedule.docs.length > 0) {
      const hospitalServiceScheduleDoc = hospitalServiceSchedule.docs[0];
      const dateMin = _.clone(appointmentDate);
      dateMin.setHours(0);
      dateMin.setMinutes(0);
      dateMin.setSeconds(0);
      dateMin.setMilliseconds(0);

      const dateMax = _.clone(appointmentDate);
      dateMax.setHours(23);
      dateMax.setMinutes(59);
      dateMax.setSeconds(59);
      dateMax.setMilliseconds(59);

      // get bookings for that schedule
      const latestAppointmentThatDay = await firestore
        .collection(HOSPITAL_SERVICE_APPOINTMENTS)
        .where("serviceScheduleId", "==", hospitalServiceScheduleDoc.id)
        .where("appointmentDate", ">=", dateMin)
        .where("appointmentDate", "<=", dateMax)
        .where("isCancelled", "==", false)
        .get();

      if (latestAppointmentThatDay.docs.length > 0) {
        const hospitalServiceScheduleData = hospitalServiceScheduleDoc.data() as ServiceSchedule;
        const currentPatientSlotToBe = latestAppointmentThatDay.docs.length + 1;

        // check if has a slot
        if (currentPatientSlotToBe > hospitalServiceScheduleData.slots) {
          return null;
        } else {
          return {
            availableSchedule: {
              ...hospitalServiceScheduleDoc.data(),
              docId: hospitalServiceScheduleDoc.id,
            } as ServiceSchedule,
            slot: currentPatientSlotToBe,
          } as AvailableServiceSchedule;
        }
      } else {
        return {
          availableSchedule: {
            ...hospitalServiceScheduleDoc.data(),
            docId: hospitalServiceScheduleDoc.id,
          } as ServiceSchedule,
          slot: 1,
        } as AvailableServiceSchedule;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

/**
 * This is to check availability of a date by matching doctorHospitas to doctorSchedule
 * to latest appointent in hospitalDoctorAppointments to get available schedule
 *
 * @param dayOfWeek
 * @param doctorId
 * @param hospitalId
 * @param appointmentDate
 */
export const getAvailableDoctorSchedule = async (
  dayOfWeek: DaysOfWeek,
  doctorId: string,
  hospitalId: string,
  appointmentDate: Date,
  slot?: number
): Promise<[AvailableDoctorSchedule[] | null, boolean]> => {
  // get the doctor for the hospital
  const doctorHospital = await firestore
    .collection(DOCTOR_HOSPITALS)
    .where("doctorId", "==", doctorId)
    .where("hospitalId", "==", hospitalId)
    .get();

  const doctor = await firestore.collection(DOCTORS).doc(doctorId).get();
  const doctorData = doctor.data() as Doctor;

  const outOfOfficeStartDate = doctorData.outOfOfficeStartDate;
  const outOfOfficeEndDate = doctorData.outOfOfficeEndDate;
  const outOfOffice =
    outOfOfficeStartDate !== undefined &&
    !_.isNull(outOfOfficeStartDate) &&
    daysSinceDate(outOfOfficeStartDate!.toDate(), appointmentDate) >= 0 &&
    ((outOfOfficeEndDate !== undefined &&
      !_.isNull(outOfOfficeEndDate) &&
      daysSinceDate(outOfOfficeEndDate!.toDate(), appointmentDate) <= 0) ||
      _.isEmpty(outOfOfficeEndDate));

  if (
    doctorHospital.docs.length > 0 &&
    !outOfOffice &&
    !isHistoricalDate(appointmentDate)
  ) {
    const doctorHospitalData = {
      ...doctorHospital.docs[0].data(),
      docId: doctorHospital.docs[0].id,
    } as DoctorHospitals;

    // get schedules of that doctor for the appointmentDate
    const doctorSchedules = await firestore
      .collection(DOCTOR_SCHEDULE)
      .where("doctorHospitalId", "==", doctorHospitalData.docId)
      .where("dayOfWeek", "==", dayOfWeek)
      .get();

    if (doctorSchedules.docs.length > 0) {
      const doctorScheduleData = {
        ...doctorSchedules.docs[0].data(),
        docId: doctorSchedules.docs[0].id,
      } as DoctorSchedule;
      const slotsPerDay = doctorScheduleData.slots;

      const dateMin = _.clone(appointmentDate);
      dateMin.setHours(0);
      dateMin.setMinutes(0);
      dateMin.setSeconds(0);
      dateMin.setMilliseconds(0);

      const dateMax = _.clone(appointmentDate);
      dateMax.setHours(23);
      dateMax.setMinutes(59);
      dateMax.setSeconds(59);
      dateMax.setMilliseconds(59);

      // get bookings for that schedule
      const hospitalDoctorAppointmentsThatDay = await firestore
        .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
        .where("doctorScheduleId", "==", doctorScheduleData.docId)
        .where("appointmentDate", ">=", dateMin)
        .where("appointmentDate", "<=", dateMax)
        .where("isCancelled", "==", false)
        .get();
      let takenSlots = [] as number[];
      const availableSchedule = [] as AvailableDoctorSchedule[];
      if (!hospitalDoctorAppointmentsThatDay.empty) {
        takenSlots = hospitalDoctorAppointmentsThatDay.docs.map(
          (appointment) => {
            const appointmentData = appointment.data() as HospitalDoctorAppointments;
            return appointmentData.slotNumber;
          }
        );
      }

      for (let slot = 1; slot <= slotsPerDay; slot++) {
        if (!takenSlots.includes(slot)) {
          const startTime = _.clone(doctorScheduleData.startTime.toDate());
          startTime.setFullYear(appointmentDate.getFullYear());
          startTime.setMonth(appointmentDate.getMonth());
          startTime.setDate(appointmentDate.getDate());
          const minutePerAppointment = _.clone(
            doctorScheduleData.estimatedAppointmentMinuteLength
          );
          const { startBreakTime, endBreakTime } = doctorHospitalData;

          let estimatedAppointmentTime = moment(startTime)
            .add(minutePerAppointment * (slot - 1), "minutes")
            .toDate();

          if (!!startBreakTime && !!endBreakTime) {
            const startBreakTimeToday = startBreakTime.toDate();
            startBreakTimeToday.setFullYear(appointmentDate.getFullYear());
            startBreakTimeToday.setMonth(appointmentDate.getMonth());
            startBreakTimeToday.setDate(appointmentDate.getDate());

            const endBreakTimeToday = endBreakTime.toDate();
            endBreakTimeToday.setFullYear(appointmentDate.getFullYear());
            endBreakTimeToday.setMonth(appointmentDate.getMonth());
            endBreakTimeToday.setDate(appointmentDate.getDate());

            const breakTimeInMS =
              endBreakTime.toDate().valueOf() -
              startBreakTime.toDate().valueOf();
            const timeAddedWithAppointmentMinutes = moment(
              estimatedAppointmentTime
            ).add(minutePerAppointment, "minutes");

            if (
              timeAddedWithAppointmentMinutes.isAfter(
                moment(startBreakTimeToday)
              ) ||
              timeAddedWithAppointmentMinutes.isAfter(moment(endBreakTimeToday))
            ) {
              estimatedAppointmentTime = moment(estimatedAppointmentTime)
                .add(breakTimeInMS, "milliseconds")
                .toDate();
            }
          }

          availableSchedule.push({
            availableSchedule: doctorScheduleData,
            slot,
            estimatedAppointmentTime,
          });
        }
      }

      return [
        slot === undefined
          ? availableSchedule
          : !_.isEmpty(
              _.find(
                availableSchedule,
                (availableScheduleItem) => availableScheduleItem.slot === slot
              )
            )
          ? availableSchedule
          : null,
        outOfOffice,
      ];
    } else {
      return [null, outOfOffice];
    }
  } else {
    return [null, outOfOffice];
  }
};

/**
 * This is to check availability of a date by matching doctorHospitas to doctorSchedule
 * to latest appointent in hospitalDoctorAppointments to get available schedule
 *
 * @param dayOfWeek
 * @param doctorId
 * @param hospitalId
 * @param appointmentDate
 */
export const getAvailableDoctorScheduleOLD = async (
  dayOfWeek: DaysOfWeek,
  doctorId: string,
  hospitalId: string,
  appointmentDate: Date
) => {
  // get the doctor for the hospital
  const doctorHospital = await firestore
    .collection(DOCTOR_HOSPITALS)
    .where("doctorId", "==", doctorId)
    .where("hospitalId", "==", hospitalId)
    .get();

  const doctor = await firestore.collection(DOCTORS).doc(doctorId).get();
  const doctorData = doctor.data() as Doctor;

  const outOfOfficeStartDate = doctorData.outOfOfficeStartDate;
  const outOfOfficeEndDate = doctorData.outOfOfficeEndDate;
  const outOfOffice =
    outOfOfficeStartDate !== undefined &&
    !_.isNull(outOfOfficeStartDate) &&
    daysSinceDate(outOfOfficeStartDate!.toDate(), appointmentDate) >= 0 &&
    ((outOfOfficeEndDate !== undefined &&
      !_.isNull(outOfOfficeEndDate) &&
      daysSinceDate(outOfOfficeEndDate!.toDate(), appointmentDate) <= 0) ||
      _.isEmpty(outOfOfficeEndDate));

  if (doctorHospital.docs.length > 0 && !outOfOffice) {
    const doctorHospitalDoc = doctorHospital.docs[0];

    // get schedules of that doctor for the appointmentDate
    const doctorSchedules = await firestore
      .collection(DOCTOR_SCHEDULE)
      .where("doctorHospitalId", "==", doctorHospitalDoc.id)
      .where("dayOfWeek", "==", dayOfWeek)
      .get();

    if (doctorSchedules.docs.length > 0) {
      // loop thru doctor's schedules for that day, this will return 2 at max for am and pm
      const availableSchedulesUnformatted = await Promise.all(
        doctorSchedules.docs.map((doctorSchedule) => {
          return new Promise(async (resolve) => {
            const dateMin = _.clone(appointmentDate);
            dateMin.setHours(0);
            dateMin.setMinutes(0);
            dateMin.setSeconds(0);
            dateMin.setMilliseconds(0);

            const dateMax = _.clone(appointmentDate);
            dateMax.setHours(23);
            dateMax.setMinutes(59);
            dateMax.setSeconds(59);
            dateMax.setMilliseconds(59);

            // get bookings for that schedule
            const hospitalDoctorLatestAppointmentThatDay = await firestore
              .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
              .where("doctorScheduleId", "==", doctorSchedule.id)
              .where("appointmentDate", ">=", dateMin)
              .where("appointmentDate", "<=", dateMax)
              .where("isCancelled", "==", false)
              .get();

            if (hospitalDoctorLatestAppointmentThatDay.docs.length > 0) {
              const currentPatientSlotToBe =
                hospitalDoctorLatestAppointmentThatDay.docs.length + 1;
              const doctorScheduleData = doctorSchedule.data() as DoctorSchedule;

              // check if has a slot
              if (currentPatientSlotToBe > doctorScheduleData.slots) {
                resolve(null);
              } else {
                resolve({
                  availableSchedule: {
                    ...doctorSchedule.data(),
                    docId: doctorSchedule.id,
                  },
                  slot: currentPatientSlotToBe,
                } as AvailableDoctorSchedule);
              }
            } else {
              resolve({
                availableSchedule: {
                  ...doctorSchedule.data(),
                  docId: doctorSchedule.id,
                },
                slot: 1,
              } as AvailableDoctorSchedule);
            }
          }) as Promise<AvailableDoctorSchedule | null>;
        })
      );

      const availableSchedule = _.compact(availableSchedulesUnformatted);
      return !_.isEmpty(availableSchedule) ? availableSchedule : null;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const bookServiceAppointment = async (
  serviceScheduleId: string,
  hospitalId: string,
  service: string,
  patientId: string,
  bookingFor = "",
  slotNumber: number,
  bookingFee: number,
  resourceFee: number,
  paymentMethod: PaymentMethod,
  appointmentDate: Date,
  bookedFrom: Coordinates
) => {
  const result = await firestore.collection(HOSPITAL_SERVICE_APPOINTMENTS).add({
    serviceScheduleId,
    hospitalId,
    service,
    patientId,
    bookingFor,
    slotNumber,
    bookingFee,
    resourceFee,
    paymentMethod,
    isCancelled: false,
    bookedFrom,
    appointmentDate,
    createdAt: getServerTimestamp(),
  });
  return result.id;
};

export const bookServiceAppointmentOLD = async (
  serviceScheduleId: string,
  hospitalId: string,
  service: string,
  patientId: string,
  cancerType: CancerType,
  appointmentDate: Date,
  bookedFrom: Coordinates
) => {
  await firestore.collection(HOSPITAL_SERVICE_APPOINTMENTS).add({
    serviceScheduleId,
    hospitalId,
    service,
    patientId,
    isCancelled: false,
    cancerType,
    bookedFrom,
    appointmentDate,
    createdAt: getServerTimestamp(),
  });
};

export const bookDoctorAppointment = async (
  doctorScheduleId: string,
  hospitalId: string,
  doctorId: string,
  patientId: string,
  bookingFor = "",
  slotNumber: number,
  bookingFee: number,
  resourceFee: number,
  paymentMethod: PaymentMethod,
  condition: string,
  appointmentDate: Date,
  bookedFrom: Coordinates
) => {
  const result = await firestore.collection(HOSPITAL_DOCTOR_APPOINTMENTS).add({
    doctorScheduleId,
    hospitalId,
    doctorId,
    patientId,
    bookingFor,
    slotNumber,
    bookingFee,
    resourceFee,
    paymentMethod,
    condition,
    isCancelled: false,
    bookedFrom,
    appointmentDate,
    createdAt: getServerTimestamp(),
  });
  return result.id;
};

export const bookDoctorAppointmentOLD = async (
  doctorScheduleId: string,
  period: Period,
  hospitalId: string,
  doctorId: string,
  patientId: string,
  cancerType: CancerType,
  appointmentDate: Date,
  bookedFrom: Coordinates
) => {
  await firestore.collection(HOSPITAL_DOCTOR_APPOINTMENTS).add({
    doctorScheduleId,
    period,
    hospitalId,
    doctorId,
    patientId,
    isCancelled: false,
    cancerType,
    bookedFrom,
    appointmentDate,
    createdAt: getServerTimestamp(),
  });
};

export const getPatientsAppointments = async (
  patientId: string,
  callback: (
    appointments: (
      | DoctorAppointmentPatientView
      | ServiceAppointmentPatientView
    )[]
  ) => void
) => {
  const appointmentList: (
    | DoctorAppointmentPatientView
    | ServiceAppointmentPatientView
  )[] = [];

  firestore
    .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
    .where("patientId", "==", patientId)
    .onSnapshot(
      async (doctorsAppointments) => {
        if (!doctorsAppointments.empty) {
          const doctorsAppointmentsFormatted = await Promise.all(
            doctorsAppointments.docs.map((appointment) => {
              return new Promise(async (resolve) => {
                const appointmentData = appointment.data() as HospitalDoctorAppointmentsOLD;

                const dateMin = _.clone(
                  appointmentData.appointmentDate.toDate()
                );
                dateMin.setHours(0);
                dateMin.setMinutes(0);
                dateMin.setSeconds(0);
                dateMin.setMilliseconds(0);

                const dateMax = _.clone(
                  appointmentData.appointmentDate.toDate()
                );
                dateMax.setHours(23);
                dateMax.setMinutes(59);
                dateMax.setSeconds(59);
                dateMax.setMilliseconds(59);

                const allAppointmentsThatDay = await firestore
                  .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
                  .where(
                    "doctorScheduleId",
                    "==",
                    appointmentData.doctorScheduleId
                  )
                  .where("appointmentDate", ">=", dateMin)
                  .where("appointmentDate", "<=", dateMax)
                  .where("isCancelled", "==", false)
                  .get();

                const slotNo = _.orderBy(
                  allAppointmentsThatDay.docs.map((appointment) => {
                    return {
                      ...appointment.data(),
                      docId: appointment.id,
                    } as HospitalDoctorAppointmentsOLD;
                  }),
                  "createdAt"
                )
                  .map((appointment) => appointment.docId)
                  .indexOf(appointment.id);

                const doctor = await firestore
                  .collection(DOCTORS)
                  .doc(appointmentData.doctorId)
                  .get();
                const hospital = await firestore
                  .collection(HOSPITALS)
                  .doc(appointmentData.hospitalId)
                  .get();

                if (doctor.exists && hospital.exists) {
                  resolve({
                    appointmentType: RESOURCE_TYPES.consultation.id,
                    appointmentId: appointment.id,
                    scheduleId: appointmentData.doctorScheduleId,
                    doctorId: appointmentData.doctorId,
                    doctorDetails: doctor.data() as Doctor,
                    hospitalId: appointmentData.hospitalId,
                    hospitalDetails: hospital.data() as Hospital,
                    period: appointmentData.period,
                    cancerType: appointmentData.cancerType,
                    appointmentDate: appointmentData.appointmentDate.toDate(),
                    slotNo: slotNo + 1,
                    isCancelled: appointmentData.isCancelled,
                  } as DoctorAppointmentPatientView);
                } else {
                  resolve(null);
                }
              }) as Promise<DoctorAppointmentPatientView | null>;
            })
          );

          const compactDoctorAppointment = _.compact(
            doctorsAppointmentsFormatted
          );
          if (!_.isEmpty(compactDoctorAppointment)) {
            appointmentList.push(...compactDoctorAppointment);
            callback(
              _.orderBy(
                _.uniqBy(appointmentList, "appointmentId"),
                "appointmentDate",
                "asc"
              )
            );
          } else {
            callback(
              _.orderBy(
                _.uniqBy(appointmentList, "appointmentId"),
                "appointmentDate",
                "asc"
              )
            );
          }
        } else {
          callback(
            _.orderBy(
              _.uniqBy(appointmentList, "appointmentId"),
              "appointmentDate",
              "asc"
            )
          );
        }
      },
      (error) => {
        callback([]);
      }
    );

  firestore
    .collection(HOSPITAL_SERVICE_APPOINTMENTS)
    .where("patientId", "==", patientId)
    .onSnapshot(
      async (serviceAppointments) => {
        if (!serviceAppointments.empty) {
          const servicesAppointmentsFormatted = await Promise.all(
            serviceAppointments.docs.map((appointment) => {
              return new Promise(async (resolve) => {
                const appointmentData = appointment.data() as HospitalServiceAppointmentsOLD;

                const dateMin = _.clone(
                  appointmentData.appointmentDate.toDate()
                );
                dateMin.setHours(0);
                dateMin.setMinutes(0);
                dateMin.setSeconds(0);
                dateMin.setMilliseconds(0);

                const dateMax = _.clone(
                  appointmentData.appointmentDate.toDate()
                );
                dateMax.setHours(23);
                dateMax.setMinutes(59);
                dateMax.setSeconds(59);
                dateMax.setMilliseconds(59);

                const allAppointmentsThatDay = await firestore
                  .collection(HOSPITAL_SERVICE_APPOINTMENTS)
                  .where(
                    "serviceScheduleId",
                    "==",
                    appointmentData.serviceScheduleId
                  )
                  .where("appointmentDate", ">=", dateMin)
                  .where("appointmentDate", "<=", dateMax)
                  .where("isCancelled", "==", false)
                  .get();

                const slotNo = _.orderBy(
                  allAppointmentsThatDay.docs.map((appointment) => {
                    return {
                      ...appointment.data(),
                      docId: appointment.id,
                    } as HospitalServiceAppointmentsOLD;
                  }),
                  "createdAt"
                )
                  .map((appointment) => appointment.docId)
                  .indexOf(appointment.id);

                const hospital = await firestore
                  .collection(HOSPITALS)
                  .doc(appointmentData.hospitalId)
                  .get();

                if (hospital.exists) {
                  resolve({
                    appointmentType: RESOURCE_TYPES.services.id,
                    appointmentId: appointment.id,
                    scheduleId: appointmentData.serviceScheduleId,
                    service: appointmentData.service,
                    hospitalId: appointmentData.hospitalId,
                    hospitalDetails: hospital.data() as Hospital,
                    cancerType: appointmentData.cancerType,
                    appointmentDate: appointmentData.appointmentDate.toDate(),
                    slotNo: slotNo + 1,
                    isCancelled: appointmentData.isCancelled,
                  } as ServiceAppointmentPatientView);
                } else {
                  resolve(null);
                }
              }) as Promise<ServiceAppointmentPatientView | null>;
            })
          );

          const compactServiceAppointment = _.compact(
            servicesAppointmentsFormatted
          );
          if (!_.isEmpty(compactServiceAppointment)) {
            appointmentList.push(...compactServiceAppointment);
            callback(
              _.orderBy(
                _.uniqBy(appointmentList, "appointmentId"),
                "appointmentDate",
                "asc"
              )
            );
          } else {
            callback(
              _.orderBy(
                _.uniqBy(appointmentList, "appointmentId"),
                "appointmentDate",
                "asc"
              )
            );
          }
        } else {
          callback(
            _.orderBy(
              _.uniqBy(appointmentList, "appointmentId"),
              "appointmentDate",
              "asc"
            )
          );
        }
      },
      (error) => {
        callback([]);
      }
    );
};

//!OLD DONT USE
export const getDoctorAppointments = async (
  doctorId: string,
  appointmentDate: Date,
  callback: (appointments: DoctorAppointmentView[]) => void,
  endDate = null as Date | null
) => {
  const dateMin = _.clone(appointmentDate);
  dateMin.setHours(0);
  dateMin.setMinutes(0);
  dateMin.setSeconds(0);
  dateMin.setMilliseconds(0);

  const dateMax = _.clone(
    endDate !== undefined && !_.isNull(endDate) ? endDate : appointmentDate
  );
  dateMax.setHours(23);
  dateMax.setMinutes(59);
  dateMax.setSeconds(59);
  dateMax.setMilliseconds(59);

  firestore
    .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
    .where("doctorId", "==", doctorId)
    .where("appointmentDate", ">=", dateMin)
    .where("appointmentDate", "<=", dateMax)
    .onSnapshot(async (doctorAppointments) => {
      const hospitalDataMap: { [hospitalId: string]: Hospital } = {};

      if (!doctorAppointments.empty) {
        const doctorAppointmentsFormatted = await Promise.all(
          doctorAppointments.docs.map((appointment) => {
            return new Promise(async (resolve) => {
              const appointmentData = appointment.data() as HospitalDoctorAppointmentsOLD;

              const slotNo = _.orderBy(
                _.filter(
                  doctorAppointments.docs.map((appointment) => {
                    return {
                      ...appointment.data(),
                      docId: appointment.id,
                    } as HospitalDoctorAppointmentsOLD;
                  }),
                  (nonCancelledAppointment) =>
                    nonCancelledAppointment.isCancelled === false &&
                    appointmentData.doctorScheduleId ===
                      nonCancelledAppointment.doctorScheduleId
                ),
                "createdAt"
              )

                .map((appointment) => appointment.docId)
                .indexOf(appointment.id);

              const patient = await firestore
                .collection(PATIENTS)
                .doc(appointmentData.patientId)
                .get();

              if (!_.has(hospitalDataMap, appointmentData.hospitalId)) {
                const hospital = await firestore
                  .collection(HOSPITALS)
                  .doc(appointmentData.hospitalId)
                  .get();
                hospitalDataMap[
                  appointmentData.hospitalId
                ] = hospital.data() as Hospital;
              }

              if (
                patient.exists &&
                _.has(hospitalDataMap, appointmentData.hospitalId)
              ) {
                resolve({
                  appointmentId: appointment.id,
                  scheduleId: appointmentData.doctorScheduleId,
                  patientId: appointmentData.patientId,
                  patientDetails: patient.data() as Patient,
                  hospitalId: appointmentData.hospitalId,
                  hospitalDetails: hospitalDataMap[appointmentData.hospitalId],
                  period: appointmentData.period,
                  cancerType: appointmentData.cancerType,
                  appointmentDate: appointmentData.appointmentDate.toDate(),
                  slotNo: slotNo + 1,
                  isCancelled: appointmentData.isCancelled,
                } as DoctorAppointmentView);
              } else {
                resolve(null);
              }
            }) as Promise<DoctorAppointmentView | null>;
          })
        );

        callback(
          _.orderBy(
            _.compact(doctorAppointmentsFormatted),
            "appointmentDate",
            "asc"
          )
        );
      } else {
        callback([]);
      }
    });
};

export const getDoctorAppointmentsOnce = async (
  doctorId: string,
  appointmentDate: Date,
  endDate = null as Date | null
) => {
  const dateMin = _.clone(appointmentDate);
  dateMin.setHours(0);
  dateMin.setMinutes(0);
  dateMin.setSeconds(0);
  dateMin.setMilliseconds(0);

  const dateMax = _.clone(
    endDate !== undefined && !_.isNull(endDate) ? endDate : appointmentDate
  );
  dateMax.setHours(23);
  dateMax.setMinutes(59);
  dateMax.setSeconds(59);
  dateMax.setMilliseconds(59);

  const doctorAppointments = await firestore
    .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
    .where("doctorId", "==", doctorId)
    .where("appointmentDate", ">=", dateMin)
    .where("appointmentDate", "<=", dateMax)
    .get();

  const hospitalDataMap: { [hospitalId: string]: Hospital } = {};

  if (!doctorAppointments.empty) {
    const doctorAppointmentsFormatted = await Promise.all(
      doctorAppointments.docs.map((appointment) => {
        return new Promise(async (resolve) => {
          const appointmentData = appointment.data() as HospitalDoctorAppointmentsOLD;

          const slotNo = _.orderBy(
            _.filter(
              doctorAppointments.docs.map((appointment) => {
                return {
                  ...appointment.data(),
                  docId: appointment.id,
                } as HospitalDoctorAppointmentsOLD;
              }),
              (nonCancelledAppointment) =>
                nonCancelledAppointment.isCancelled === false &&
                appointmentData.doctorScheduleId ===
                  nonCancelledAppointment.doctorScheduleId
            ),
            "createdAt"
          )

            .map((appointment) => appointment.docId)
            .indexOf(appointment.id);

          const patient = await firestore
            .collection(PATIENTS)
            .doc(appointmentData.patientId)
            .get();

          if (!_.has(hospitalDataMap, appointmentData.hospitalId)) {
            const hospital = await firestore
              .collection(HOSPITALS)
              .doc(appointmentData.hospitalId)
              .get();
            hospitalDataMap[
              appointmentData.hospitalId
            ] = hospital.data() as Hospital;
          }

          if (
            patient.exists &&
            _.has(hospitalDataMap, appointmentData.hospitalId)
          ) {
            resolve({
              appointmentId: appointment.id,
              scheduleId: appointmentData.doctorScheduleId,
              patientId: appointmentData.patientId,
              patientDetails: patient.data() as Patient,
              hospitalId: appointmentData.hospitalId,
              hospitalDetails: hospitalDataMap[appointmentData.hospitalId],
              period: appointmentData.period,
              cancerType: appointmentData.cancerType,
              appointmentDate: appointmentData.appointmentDate.toDate(),
              slotNo: slotNo + 1,
              isCancelled: appointmentData.isCancelled,
            } as DoctorAppointmentView);
          } else {
            resolve(null);
          }
        }) as Promise<DoctorAppointmentView | null>;
      })
    );

    return _.orderBy(
      _.compact(doctorAppointmentsFormatted),
      "appointmentDate",
      "asc"
    );
  } else {
    return [];
  }
};

export const cancelAppointment = async (
  appointmentId: string,
  mode: "doctor" | "service",
  updatedBy: string,
  updatedByUserType: UserType,
  cancelReason?: string
) => {
  const appointment = await firestore
    .collection(
      mode === "doctor"
        ? HOSPITAL_DOCTOR_APPOINTMENTS
        : HOSPITAL_SERVICE_APPOINTMENTS
    )
    .doc(appointmentId)
    .get();

  const appointmentData = appointment.data() as
    | HospitalDoctorAppointments
    | HospitalServiceAppointments;

  await appointment.ref.update({
    isCancelled: true,
    updatedAt: getServerTimestamp(),
    cancelReason: cancelReason || "",
    updatedByUserType: updatedByUserType,
    updatedBy: updatedBy,
  });
  if (!isToday(appointmentData.appointmentDate.toDate())) {
    await transferToWaitList(appointmentData, mode === "doctor");
  }
};

const transferToWaitList = async (
  appointment: HospitalDoctorAppointments | HospitalServiceAppointments,
  isDoctorAppointment: boolean
) => {
  try {
    const dateMin = _.clone(appointment.appointmentDate.toDate());
    dateMin.setHours(0);
    dateMin.setMinutes(0);
    dateMin.setSeconds(0);
    dateMin.setMilliseconds(0);

    const dateMax = _.clone(appointment.appointmentDate.toDate());
    dateMax.setHours(23);
    dateMax.setMinutes(59);
    dateMax.setSeconds(59);
    dateMax.setMilliseconds(59);

    const prospectWaitList = await firestore
      .collection(BOOK_LIST)
      .where("appointment.appointmentDate", ">=", dateMin)
      .where("appointment.appointmentDate", "<=", dateMax)
      .where("isDoctorResource", "==", isDoctorAppointment)
      .where("isWaitList", "==", true)
      .where("resource.hospital.docId", "==", appointment.hospitalId)
      .get();

    const mappedWaitList = prospectWaitList.docs.map((waitItem) => [
      waitItem.data() as BookListItem,
      waitItem,
    ]);
    console.log("USE THIS", mappedWaitList);
    const matchedBooking = _.find(mappedWaitList, (waitList) => {
      if (isDoctorAppointment) {
        return (
          (waitList[0] as BookListItem).resource.docId ===
          (appointment as HospitalDoctorAppointments).doctorId
        );
      } else {
        return (
          (waitList[0] as BookListItem).resource.docId ===
          (appointment as HospitalServiceAppointments).service
        );
      }
    });
    console.log("got a match!", matchedBooking);

    if (!_.isEmpty(matchedBooking)) {
      //transfer details
      const matchedBookingItem = matchedBooking![0] as BookListItem;
      const patientId = matchedBookingItem.appointment.patientId;
      await (matchedBooking![1] as firebase.firestore.DocumentData).ref.update({
        appointment: {
          ...appointment,
          patientId,
          ...(!_.isEmpty(matchedBookingItem.appointment.bookingFor) && {
            bookingFor: matchedBookingItem.appointment.bookingFor,
          }),
          ...(isDoctorAppointment && {
            condition: (matchedBookingItem.appointment as HospitalDoctorAppointments)
              .condition,
          }),
        },
        isWaitList: false,
      });

      //notify
      const patient = await firestore.collection(PATIENTS).doc(patientId).get();
      const hospital = await firestore
        .collection(HOSPITALS)
        .doc(appointment.hospitalId)
        .get();

      console.log("WILL START TO NOTIFY", patient.exists, hospital.exists);

      if (patient.exists && hospital.exists) {
        const patientData = patient.data() as Patient;
        const patientName = `${patientData.firstName} ${patientData.lastName}`;
        const hospitalData = hospital.data() as Hospital;
        console.log("WILL COMMENCE NOTIFICATION", {
          patientData,
          patientName,
          hospitalData,
        });
        if (isDoctorAppointment) {
          const doctor = await firestore
            .collection(DOCTORS)
            .doc((appointment as HospitalDoctorAppointments).doctorId)
            .get();
          if (doctor.exists) {
            const doctorData = doctor.data() as Doctor;
            //notify
            console.log(
              "will notify nowww",
              patientName,
              `${doctorData.firstName} ${doctorData.lastName}`,
              hospitalData.hospitalName,
              moment(appointment.appointmentDate.toDate()).format("MM/DD/YYYY")
            );
            const {
              subject,
              message,
            } = email.notifyPatientOfAvailableDoctorSlot(
              patientName,
              `${doctorData.firstName} ${doctorData.lastName}`,
              hospitalData.hospitalName,
              moment(appointment.appointmentDate.toDate()).format("MM/DD/YYYY")
            );

            await sendEmail(patientData.emailAddress, subject, message);
            try {
              const smsMessage = sms.notifyPatientOfAvailableDoctorSlot(
                patientName,
                `${doctorData.firstName} ${doctorData.lastName}`,
                hospitalData.hospitalName,
                moment(appointment.appointmentDate.toDate()).format(
                  "MM/DD/YYYY"
                )
              );
              await sendSMS(patientData.phoneNumber, smsMessage);
            } catch (e) {
              console.log("ERROR SENDING SMS FOR: ", e);
            }
          }
        } else {
          const service = await firestore
            .collection(HOSPITAL_SERVICES)
            .doc((appointment as HospitalServiceAppointments).service)
            .get();
          if (service.exists) {
            const serviceData = service.data() as HospitalService;
            console.log(
              "will notify nowww",
              patientName,
              serviceData.treatmentName,
              hospitalData.hospitalName,
              moment(appointment.appointmentDate.toDate()).format("MM/DD/YYYY")
            );
            const {
              subject,
              message,
            } = email.notifyPatientOfAvailableServiceSlot(
              patientName,
              serviceData.treatmentName,
              hospitalData.hospitalName,
              moment(appointment.appointmentDate.toDate()).format("MM/DD/YYYY")
            );

            await sendEmail(patientData.emailAddress, subject, message);
            try {
              const smsMessage = sms.notifyPatientOfAvailableServiceSlot(
                patientName,
                serviceData.treatmentName,
                hospitalData.hospitalName,
                moment(appointment.appointmentDate.toDate()).format(
                  "MM/DD/YYYY"
                )
              );
              await sendSMS(patientData.phoneNumber, smsMessage);
            } catch (e) {
              console.log("ERROR SENDING SMS FOR: ", e);
            }
          }
        }
      }
    }
  } catch (e) {
    console.log("error in transferToWaitList", e);
  }
};

export const getHospitalAppointmentsOnce = async (
  hospitalId: string,
  appointmentDate: Date,
  endDate = null as Date | null
) => {
  const appointmentList: (
    | HospitalDoctorAppointmentsView
    | HospitalServiceAppointmentsView
  )[] = [];

  const dateMin = _.clone(appointmentDate);
  dateMin.setHours(0);
  dateMin.setMinutes(0);
  dateMin.setSeconds(0);
  dateMin.setMilliseconds(0);

  const dateMax = _.clone(
    endDate !== undefined && !_.isNull(endDate) ? endDate : appointmentDate
  );
  dateMax.setHours(23);
  dateMax.setMinutes(59);
  dateMax.setSeconds(59);

  const doctorsAppointments = await firestore
    .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
    .where("hospitalId", "==", hospitalId)
    .where("appointmentDate", ">=", dateMin)
    .where("appointmentDate", "<=", dateMax)
    .get();

  if (!doctorsAppointments.empty) {
    const doctorAppointmentsRawData = doctorsAppointments.docs.map(
      (appointment) => {
        return {
          ...appointment.data(),
          docId: appointment.id,
        } as HospitalDoctorAppointmentsView;
      }
    );
    let doctorsDataMap: {
      [doctorId: string]: Doctor;
    } = {};
    let patientsDataMap: {
      [patientId: string]: Patient;
    } = {};

    // populate doctors data map
    await Promise.all(
      _.uniqBy(doctorAppointmentsRawData, "doctorId").map(
        (doctorAppointment) => {
          return new Promise(async (resolve) => {
            const doctor = await firestore
              .collection(DOCTORS)
              .doc(doctorAppointment.doctorId)
              .get();
            if (doctor.exists) {
              doctorsDataMap[doctor.id] = doctor.data() as Doctor;
            }
            resolve();
          });
        }
      )
    );

    // populate patients data map
    await Promise.all(
      _.uniqBy(doctorAppointmentsRawData, "patientId").map(
        (doctorAppointment) => {
          return new Promise(async (resolve) => {
            const patient = await firestore
              .collection(PATIENTS)
              .doc(doctorAppointment.patientId)
              .get();
            if (patient.exists) {
              patientsDataMap[patient.id] = patient.data() as Patient;
            }
            resolve();
          });
        }
      )
    );

    const doctorsAppointmentsFormatted = doctorAppointmentsRawData.map(
      (appointment) => {
        const slotNo = _.orderBy(
          _.filter(
            doctorAppointmentsRawData,
            (nonCancelledAppointments) =>
              nonCancelledAppointments.isCancelled === false &&
              appointment.doctorScheduleId ===
                nonCancelledAppointments.doctorScheduleId
          ),
          "createdAt"
        )
          .map((appointment) => appointment.docId)
          .indexOf(appointment.docId);

        if (
          _.has(doctorsDataMap, appointment.doctorId) &&
          _.has(patientsDataMap, appointment.patientId)
        ) {
          return {
            docId: appointment.docId,
            doctorId: appointment.doctorId,
            doctorDetails: doctorsDataMap[appointment.doctorId],
            patientId: appointment.patientId,
            patientDetails: patientsDataMap[appointment.patientId],
            appointmentDate: appointment.appointmentDate,
            slotNumber: slotNo + 1,
            isCancelled: appointment.isCancelled,
          } as HospitalDoctorAppointmentsView;
        } else {
          return null;
        }
      }
    );

    const compactDoctorAppointment = _.compact(doctorsAppointmentsFormatted);
    if (!_.isEmpty(compactDoctorAppointment)) {
      appointmentList.push(...compactDoctorAppointment);
    }
  }

  const serviceAppointments = await firestore
    .collection(HOSPITAL_SERVICE_APPOINTMENTS)
    .where("hospitalId", "==", hospitalId)
    .where("appointmentDate", ">=", dateMin)
    .where("appointmentDate", "<=", dateMax)
    .get();

  if (!serviceAppointments.empty) {
    const serviceAppointmentsRawData = serviceAppointments.docs.map(
      (appointment) => {
        return {
          ...appointment.data(),
          docId: appointment.id,
        } as HospitalServiceAppointmentsView;
      }
    );

    let patientsDataMap: {
      [patientId: string]: Patient;
    } = {};

    // populate patients data map
    await Promise.all(
      _.uniqBy(serviceAppointmentsRawData, "patientId").map(
        (serviceAppointment) => {
          return new Promise(async (resolve) => {
            const patient = await firestore
              .collection(PATIENTS)
              .doc(serviceAppointment.patientId)
              .get();
            if (patient.exists) {
              patientsDataMap[patient.id] = patient.data() as Patient;
            }
            resolve();
          });
        }
      )
    );

    const servicesAppointmentsFormatted = serviceAppointmentsRawData.map(
      (appointment) => {
        const slotNo = _.orderBy(
          _.filter(
            serviceAppointmentsRawData,
            (nonCancelledAppointments) =>
              nonCancelledAppointments.isCancelled === false
          ),
          "createdAt"
        )
          .map((appointment) => appointment.docId)
          .indexOf(appointment.docId);

        if (_.has(patientsDataMap, appointment.patientId)) {
          return {
            docId: appointment.docId,
            service: appointment.service,
            patientId: appointment.patientId,
            patientDetails: patientsDataMap[appointment.patientId],
            appointmentDate: appointment.appointmentDate,
            slotNumber: slotNo + 1,
            isCancelled: appointment.isCancelled,
          } as HospitalServiceAppointmentsView;
        } else {
          return null;
        }
      }
    );

    const compactServiceAppointment = _.compact(servicesAppointmentsFormatted);
    if (!_.isEmpty(compactServiceAppointment)) {
      appointmentList.push(...compactServiceAppointment);
    }
  }

  return _.orderBy(appointmentList, "appointmentDate", "asc");
};

export const getAllDoctorFutureAppointments = async (
  doctorId: string,
  callback: (appointmentDates: Date[]) => void
) => {
  try {
    firestore
      .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
      .where("doctorId", "==", doctorId)
      .where("isCancelled", "==", false)
      .onSnapshot(
        (appointments) => {
          if (!appointments.empty) {
            callback(
              _.uniqBy(
                appointments.docs.map((appointment) => {
                  const appointmentData = appointment.data() as HospitalDoctorAppointmentsOLD;
                  return appointmentData.appointmentDate.toDate();
                }),
                (appointment) => {
                  return moment(appointment).format("MMM DD YYYY");
                }
              )
            );
          } else {
            callback([]);
          }
        },
        (error) => {
          callback([]);
        }
      );
  } catch (e) {
    callback([]);
  }
};

export const getAllHospitalFutureAppointments = async (
  hospitalId: string,
  callback: (appointmentDates: Date[]) => void
) => {
  let appointmentDates = [] as Date[];
  let doctorAppointments = [] as Date[];
  let serviceAppointments = [] as Date[];
  try {
    firestore
      .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
      .where("hospitalId", "==", hospitalId)
      .where("isCancelled", "==", false)
      .onSnapshot(
        (appointments) => {
          if (!appointments.empty) {
            doctorAppointments = _.uniqBy(
              [
                ...appointments.docs.map((appointment) => {
                  const appointmentData = appointment.data() as HospitalDoctorAppointmentsOLD;
                  return appointmentData.appointmentDate.toDate();
                }),
              ],
              (appointment) => {
                return moment(appointment).format("MMM DD YYYY");
              }
            );
            appointmentDates = [...serviceAppointments, ...doctorAppointments];
            callback(appointmentDates);
          } else {
            callback(appointmentDates);
          }
        },
        (error) => {
          callback([]);
        }
      );
  } catch (e) {
    callback(appointmentDates);
  }

  try {
    firestore
      .collection(HOSPITAL_SERVICE_APPOINTMENTS)
      .where("hospitalId", "==", hospitalId)
      .where("isCancelled", "==", false)
      .onSnapshot(
        (appointments) => {
          if (!appointments.empty) {
            serviceAppointments = _.uniqBy(
              [
                ...appointments.docs.map((appointment) => {
                  const appointmentData = appointment.data() as HospitalDoctorAppointmentsOLD;
                  return appointmentData.appointmentDate.toDate();
                }),
              ],
              (appointment) => {
                return moment(appointment).format("MMM DD YYYY");
              }
            );

            appointmentDates = [...serviceAppointments, ...doctorAppointments];
            callback(appointmentDates);
          } else {
            callback(appointmentDates);
          }
        },
        (error) => {
          callback([]);
        }
      );
  } catch (e) {
    callback(appointmentDates);
  }
};

export const getDoctorAppointmentsPatientOrHospital = async (
  mode: "patient" | "hospital" | "doctor",
  userId: string,
  callback: (
    patientDoctorAppointments: HospitalDoctorAppointmentsView[],
    error?: string
  ) => void,
  dateFilter?: Date,
  fromDateOnly?: boolean
) => {
  try {
    let doctorAppointmentsQuery = firestore
      .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
      .where(
        `${
          mode === "patient"
            ? "patientId"
            : mode === "doctor"
            ? "doctorId"
            : "hospitalId"
        }`,
        "==",
        userId
      );

    if (!!dateFilter) {
      const dateMin = _.clone(dateFilter);
      dateMin.setHours(0);
      dateMin.setMinutes(0);
      dateMin.setSeconds(0);
      dateMin.setMilliseconds(0);

      const dateMax = _.clone(dateFilter);
      dateMax.setHours(23);
      dateMax.setMinutes(59);
      dateMax.setSeconds(59);
      dateMax.setMilliseconds(59);

      doctorAppointmentsQuery = doctorAppointmentsQuery.where(
        "appointmentDate",
        ">=",
        fromDateOnly ? dateMax : dateMin
      );
      if (!fromDateOnly) {
        doctorAppointmentsQuery = doctorAppointmentsQuery.where(
          "appointmentDate",
          "<=",
          dateMax
        );
      }
    }

    doctorAppointmentsQuery.onSnapshot(
      async (doctorAppointments) => {
        if (!doctorAppointments.empty) {
          const doctorAppointmentsView = await Promise.all(
            doctorAppointments.docs.map((doctorAppointment) => {
              return new Promise(async (resolve) => {
                const doctorAppointmentData = {
                  ...doctorAppointment.data(),
                  docId: doctorAppointment.id,
                } as HospitalDoctorAppointments;

                const doctor = await firestore
                  .collection(DOCTORS)
                  .doc(doctorAppointmentData.doctorId)
                  .get();
                const doctorHospital = await firestore
                  .collection(DOCTOR_HOSPITALS)
                  .where("doctorId", "==", doctorAppointmentData.doctorId)
                  .where("hospitalId", "==", doctorAppointmentData.hospitalId)
                  .get();

                let doctorHospitalAssistantDetails = {} as ExecutiveAssistant;
                if (!_.isNull(doctorHospital) && !doctorHospital.empty) {
                  const eaDoctorHospital = await firestore
                    .collection(EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL)
                    .where("doctorHospitalId", "==", doctorHospital?.docs[0].id)
                    .get();
                  if (!eaDoctorHospital.empty) {
                    const doctorEAquery = await firestore
                      .collection(EXECUTIVE_ASSISTANT)
                      .doc(eaDoctorHospital.docs[0].data().executiveAssistantId)
                      .get();
                    if (doctorEAquery.exists) {
                      doctorHospitalAssistantDetails = {
                        ...doctorEAquery.data(),
                        docId: doctorEAquery.id,
                      } as ExecutiveAssistant;
                    }
                  }
                }
                const patient = await firestore
                  .collection(PATIENTS)
                  .doc(doctorAppointmentData.patientId)
                  .get();
                const hospital = await firestore
                  .collection(HOSPITALS)
                  .doc(doctorAppointmentData.hospitalId)
                  .get();

                let familyMember = {} as FamilyMember;
                if (!_.isEmpty(doctorAppointmentData.bookingFor)) {
                  const familyMemberQuery = await firestore
                    .collection(FAMILY_MEMBERS)
                    .doc(doctorAppointmentData.bookingFor)
                    .get();
                  if (familyMemberQuery.exists) {
                    const familyMemberData = familyMemberQuery.data() as FamilyMember;
                    familyMember = {
                      firstName: familyMemberData.firstName,
                      lastName: familyMemberData.lastName,
                      birthday: familyMemberData.birthday,
                      gender: familyMemberData.gender,
                    } as FamilyMember;
                  }
                }
                const doctorHospitalData = _.find(
                  doctorHospital.docs.map(
                    (doctorHospitalData) => doctorHospitalData.data(),
                    (doctorHospital: DoctorHospitals) =>
                      doctorHospital.doctorId === doctorAppointmentData.doctorId
                  )
                ) as DoctorHospitals;
                if (
                  doctor.exists &&
                  !doctorHospital.empty &&
                  patient.exists &&
                  hospital.exists &&
                  !!doctorHospitalData.initialSetupDone
                ) {
                  resolve({
                    ...doctorAppointmentData,
                    appointmentDate: doctorAppointmentData.appointmentDate.toDate(),
                    patientDetails: { ...patient.data(), ...familyMember },
                    hospitalDetails: hospital.data(),
                    doctorDetails: doctor.data(),
                    doctorHospitalAssistantDetails,
                    doctorHospitalDetails: doctorHospitalData,
                  });
                } else {
                  resolve(null);
                }
              });
            })
          );
          callback(
            _.compact(
              doctorAppointmentsView
            ) as HospitalDoctorAppointmentsView[]
          );
        } else {
          callback([]);
        }
      },
      (error) => {
        callback([], error.message);
      }
    );
  } catch (error) {
    callback([], error);
  }
};

export const getServiceAppointmentsPatientOrHospital = async (
  mode: "patient" | "hospital",
  userId: string,
  callback: (
    patientServiceAppointments: HospitalServiceAppointmentsView[],
    error?: string
  ) => void,
  dateFilter?: Date,
  fromDateOnly?: boolean
) => {
  try {
    let serviceAppointmentsQuery = firestore
      .collection(HOSPITAL_SERVICE_APPOINTMENTS)
      .where(
        `${mode === "patient" ? "patientId" : "hospitalId"}`,
        "==",
        userId
      );

    if (!!dateFilter) {
      const dateMin = _.clone(dateFilter);
      dateMin.setHours(0);
      dateMin.setMinutes(0);
      dateMin.setSeconds(0);
      dateMin.setMilliseconds(0);

      const dateMax = _.clone(dateFilter);
      dateMax.setHours(23);
      dateMax.setMinutes(59);
      dateMax.setSeconds(59);
      dateMax.setMilliseconds(59);

      serviceAppointmentsQuery = serviceAppointmentsQuery.where(
        "appointmentDate",
        ">=",
        fromDateOnly ? dateMax : dateMin
      );

      if (!fromDateOnly) {
        serviceAppointmentsQuery = serviceAppointmentsQuery.where(
          "appointmentDate",
          "<=",
          dateMax
        );
      }
    }

    serviceAppointmentsQuery.onSnapshot(
      async (serviceAppointments) => {
        if (!serviceAppointments.empty) {
          const serviceAppointmentsView = await Promise.all(
            serviceAppointments.docs.map((serviceAppointment) => {
              return new Promise(async (resolve) => {
                const serviceAppointmentData = {
                  ...serviceAppointment.data(),
                  docId: serviceAppointment.id,
                } as HospitalServiceAppointments;

                const hospitalService = await firestore
                  .collection(HOSPITAL_SERVICES)
                  .doc(serviceAppointmentData.service)
                  .get();

                const patient = await firestore
                  .collection(PATIENTS)
                  .doc(serviceAppointmentData.patientId)
                  .get();
                const hospital = await firestore
                  .collection(HOSPITALS)
                  .doc(serviceAppointmentData.hospitalId)
                  .get();

                let familyMember = {} as FamilyMember;
                if (!_.isEmpty(serviceAppointmentData.bookingFor)) {
                  const familyMemberQuery = await firestore
                    .collection(FAMILY_MEMBERS)
                    .doc(serviceAppointmentData.bookingFor)
                    .get();
                  if (familyMemberQuery.exists) {
                    const familyMemberData = familyMemberQuery.data() as FamilyMember;
                    familyMember = {
                      firstName: familyMemberData.firstName,
                      lastName: familyMemberData.lastName,
                      birthday: familyMemberData.birthday,
                      gender: familyMemberData.gender,
                    } as FamilyMember;
                  }
                }

                if (
                  hospitalService.exists &&
                  patient.exists &&
                  hospital.exists
                ) {
                  resolve({
                    ...serviceAppointmentData,
                    appointmentDate: serviceAppointmentData.appointmentDate.toDate(),
                    patientDetails: { ...patient.data(), ...familyMember },
                    hospitalDetails: hospital.data(),
                    hospitalServiceDetails: {
                      ...hospitalService.data(),
                      docId: hospitalService.id,
                    },
                  });
                } else {
                  resolve(null);
                }
              });
            })
          );
          callback(
            _.compact(
              serviceAppointmentsView
            ) as HospitalServiceAppointmentsView[]
          );
        } else {
          callback([]);
        }
      },
      (error) => {
        callback([], error.message);
      }
    );
  } catch (error) {
    callback([], error);
  }
};

export const getAllDoctorAppointments = async (
  doctorId: string | string[],
  callback: (
    doctorAppointments: HospitalDoctorAppointmentsView[],
    error?: string
  ) => void
) => {
  try {
    let doctorAppointmentsQuery;

    if (typeof doctorId === "string") {
      doctorAppointmentsQuery = firestore
        .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
        .where("doctorId", "==", doctorId) as firebase.firestore.DocumentData;
    } else {
      doctorAppointmentsQuery = firestore
        .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
        .where("doctorId", "in", doctorId) as firebase.firestore.DocumentData;
    }

    doctorAppointmentsQuery.onSnapshot(
      async (doctorAppointments: firebase.firestore.QuerySnapshot) => {
        if (!doctorAppointments.empty) {
          const doctorAppointmentsView = await Promise.all(
            doctorAppointments.docs.map((doctorAppointment) => {
              return new Promise(async (resolve) => {
                const doctorAppointmentData = {
                  ...doctorAppointment.data(),
                  docId: doctorAppointment.id,
                } as HospitalDoctorAppointments;
                const patient = await firestore
                  .collection(PATIENTS)
                  .doc(doctorAppointmentData.patientId)
                  .get();

                const doctor =
                  typeof doctorId !== "string"
                    ? await firestore
                        .collection(DOCTORS)
                        .doc(doctorAppointmentData.doctorId)
                        .get()
                    : null;

                const doctorHospital =
                  typeof doctorId !== "string"
                    ? await firestore
                        .collection(DOCTOR_HOSPITALS)
                        .where("doctorId", "==", doctorAppointmentData.doctorId)
                        .get()
                    : null;

                let doctorHospitalAssistantDetails = {} as ExecutiveAssistant;
                if (!_.isNull(doctorHospital) && !doctorHospital.empty) {
                  const eaDoctorHospital = await firestore
                    .collection(EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL)
                    .where("doctorHospitalId", "==", doctorHospital?.docs[0].id)
                    .get();
                  if (!eaDoctorHospital.empty) {
                    const doctorEAquery = await firestore
                      .collection(EXECUTIVE_ASSISTANT)
                      .doc(eaDoctorHospital.docs[0].data().executiveAssistantId)
                      .get();
                    if (doctorEAquery.exists) {
                      doctorHospitalAssistantDetails = {
                        ...doctorEAquery.data(),
                        docId: doctorEAquery.id,
                      } as ExecutiveAssistant;
                    }
                  }
                }

                const hospital = await firestore
                  .collection(HOSPITALS)
                  .doc(doctorAppointmentData.hospitalId)
                  .get();

                let familyMember = {} as FamilyMember;
                if (!_.isEmpty(doctorAppointmentData.bookingFor)) {
                  const familyMemberQuery = await firestore
                    .collection(FAMILY_MEMBERS)
                    .doc(doctorAppointmentData.bookingFor)
                    .get();
                  if (familyMemberQuery.exists) {
                    const familyMemberData = familyMemberQuery.data() as FamilyMember;
                    familyMember = {
                      firstName: familyMemberData.firstName,
                      lastName: familyMemberData.lastName,
                      birthday: familyMemberData.birthday,
                      gender: familyMemberData.gender,
                    } as FamilyMember;
                  }
                }

                resolve({
                  ...doctorAppointmentData,
                  appointmentDate: doctorAppointmentData.appointmentDate.toDate(),
                  patientDetails: { ...patient.data(), ...familyMember },
                  hospitalDetails: hospital.data(),
                  doctorHospitalAssistantDetails,
                  doctorDetails: !_.isNull(doctor)
                    ? (doctor.data() as Doctor)
                    : {},
                  doctorHospitalDetails:
                    !_.isNull(doctorHospital) && !doctorHospital.empty
                      ? (doctorHospital.docs[0].data() as DoctorHospitals)
                      : {},
                });
              });
            })
          );
          callback(doctorAppointmentsView as HospitalDoctorAppointmentsView[]);
        } else {
          callback([]);
        }
      }
    );
  } catch (error) {
    callback([], error);
  }
};

export const getAppointment = async (
  appointmenId: string,
  isDoctorResource = null as null | boolean,
  hospitalId?: string
) => {
  let isDoctor = _.clone(isDoctorResource);

  try {
    let result = null;

    if (!_.isNull(isDoctor)) {
      if (isDoctor) {
        result = await firestore
          .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
          .doc(appointmenId)
          .get();
      } else {
        result = await firestore
          .collection(HOSPITAL_SERVICE_APPOINTMENTS)
          .doc(appointmenId)
          .get();
      }
    } else {
      result = await firestore
        .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
        .doc(appointmenId)
        .get();
      if (!result.exists) {
        result = await firestore
          .collection(HOSPITAL_SERVICE_APPOINTMENTS)
          .doc(appointmenId)
          .get();
        isDoctor = false;
      } else {
        isDoctor = true;
      }
    }

    if (result.exists) {
      if (isDoctor) {
        const doctorAppointment = {
          ...result.data(),
          docId: result.id,
        } as HospitalDoctorAppointments;

        const doctor = await firestore
          .collection(DOCTORS)
          .doc(doctorAppointment.doctorId)
          .get();

        const doctorHospital = await firestore
          .collection(DOCTOR_HOSPITALS)
          .where("doctorId", "==", doctorAppointment.doctorId)
          .where("hospitalId", "==", doctorAppointment.hospitalId)
          .get();

        const patient = await firestore
          .collection(PATIENTS)
          .doc(doctorAppointment.patientId)
          .get();

        const hospital = await firestore
          .collection(HOSPITALS)
          .doc(doctorAppointment.hospitalId)
          .get();

        const eaDoctorHospital = await firestore
          .collection(EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL)
          .where("doctorHospitalId", "==", doctorHospital.docs[0].id)
          .get();

        let doctorHospitalAssistantDetails = {} as ExecutiveAssistant;
        if (!eaDoctorHospital.empty) {
          const doctorEAquery = await firestore
            .collection(EXECUTIVE_ASSISTANT)
            .doc(eaDoctorHospital.docs[0].data().executiveAssistantId)
            .get();
          if (doctorEAquery.exists) {
            doctorHospitalAssistantDetails = {
              ...doctorEAquery.data(),
              docId: doctorEAquery.id,
            } as ExecutiveAssistant;
          }
        }

        let familyMember = {} as FamilyMember;
        if (!_.isEmpty(doctorAppointment.bookingFor)) {
          const familyMemberQuery = await firestore
            .collection(FAMILY_MEMBERS)
            .doc(doctorAppointment.bookingFor)
            .get();
          if (familyMemberQuery.exists) {
            const familyMemberData = familyMemberQuery.data() as FamilyMember;
            familyMember = {
              firstName: familyMemberData.firstName,
              lastName: familyMemberData.lastName,
              birthday: familyMemberData.birthday,
              gender: familyMemberData.gender,
            } as FamilyMember;
          }
        }
        if (
          doctor.exists &&
          !doctorHospital.empty &&
          patient.exists &&
          hospital.exists
        ) {
          const doctorApppointmentView = {
            ...doctorAppointment,
            appointmentDate: doctorAppointment.appointmentDate.toDate(),
            patientDetails: { ...patient.data(), ...familyMember },
            doctorDetails: doctor.data() as Doctor,
            hospitalDetails: hospital.data() as Hospital,
            doctorHospitalAssistantDetails,
            doctorHospitalDetails: _.find(
              doctorHospital.docs.map(
                (doctorHospitalData) => {
                  return {
                    ...doctorHospitalData.data(),
                    docId: doctorHospitalData.id,
                  };
                },
                (doctorHospital: DoctorHospitals) =>
                  doctorHospital.doctorId === doctorAppointment.doctorId
              )
            ) as DoctorHospitals,
          };

          return (!!hospitalId &&
          doctorApppointmentView.hospitalId !== hospitalId
            ? {}
            : doctorApppointmentView) as HospitalDoctorAppointmentsView;
        } else {
          return {} as HospitalDoctorAppointmentsView;
        }
      } else {
        const serviceAppointment = {
          ...result.data(),
          docId: result.id,
        } as HospitalServiceAppointments;

        const hospitalService = await firestore
          .collection(HOSPITAL_SERVICES)
          .doc(serviceAppointment.service)
          .get();

        const patient = await firestore
          .collection(PATIENTS)
          .doc(serviceAppointment.patientId)
          .get();

        const hospital = await firestore
          .collection(HOSPITALS)
          .doc(serviceAppointment.hospitalId)
          .get();

        let familyMember = {} as FamilyMember;
        if (!_.isEmpty(serviceAppointment.bookingFor)) {
          const familyMemberQuery = await firestore
            .collection(FAMILY_MEMBERS)
            .doc(serviceAppointment.bookingFor)
            .get();
          if (familyMemberQuery.exists) {
            const familyMemberData = familyMemberQuery.data() as FamilyMember;
            familyMember = {
              firstName: familyMemberData.firstName,
              lastName: familyMemberData.lastName,
              birthday: familyMemberData.birthday,
              gender: familyMemberData.gender,
            } as FamilyMember;
          }
        }

        if (hospitalService.exists && patient.exists && hospital.exists) {
          const serviceApppointmentView = {
            ...serviceAppointment,
            appointmentDate: serviceAppointment.appointmentDate.toDate(),
            patientDetails: { ...patient.data(), ...familyMember },
            hospitalDetails: hospital.data() as Hospital,
            hospitalServiceDetails: hospitalService.data() as HospitalService,
          };

          console.log(
            "serviceApppointmentView --- view --- ",
            serviceApppointmentView
          );
          return (!!hospitalId &&
          serviceApppointmentView.hospitalId !== hospitalId
            ? {}
            : serviceApppointmentView) as HospitalServiceAppointmentsView;
        } else {
          return {} as HospitalServiceAppointmentsView;
        }
      }
    } else {
      return {} as
        | HospitalServiceAppointmentsView
        | HospitalDoctorAppointmentsView;
    }
  } catch (error) {
    console.log("error -- ", error);
    return {} as
      | HospitalServiceAppointmentsView
      | HospitalDoctorAppointmentsView;
  }
};

export const pushAppointmentProgress = async (
  appointmentId: string,
  isDoctorAppointment: boolean,
  status: AppointmentStatusType,
  updatedBy: string,
  updatedByUserType: UserType
) => {
  console.log("WILL UPDATE", {
    appointmentId,
    isDoctorAppointment,
    status,
  });
  await firestore
    .collection(
      isDoctorAppointment
        ? HOSPITAL_DOCTOR_APPOINTMENTS
        : HOSPITAL_SERVICE_APPOINTMENTS
    )
    .doc(appointmentId)
    .update({
      appointmentStatus: firebase.firestore.FieldValue.arrayUnion({
        id: status,
        dateTimestamp: new Date(),
        updatedBy,
        updatedByUserType,
      }),
    });
};

export const updatePrescription = async (
  appointmentId: string,
  prescription: Remarks,
  updatedBy: string
) => {
  await firestore
    .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
    .doc(appointmentId)
    .update({
      prescription,
      updatedBy,
      updatedAt: new Date(),
    });
};

export const checkPatientDuplicateBookListItem = async (
  patientId: string,
  resourceId: string,
  appointmentDate: Date,
  isDoctorResource: boolean,
  bookingFor = "" as string
): Promise<boolean> => {
  try {
    const dateMin = _.clone(appointmentDate);
    dateMin.setHours(0);
    dateMin.setMinutes(0);
    dateMin.setSeconds(0);
    dateMin.setMilliseconds(0);

    const dateMax = _.clone(appointmentDate);
    dateMax.setHours(23);
    dateMax.setMinutes(59);
    dateMax.setSeconds(59);
    dateMax.setMilliseconds(59);

    console.log("isDoctorResource -- ", isDoctorResource);
    console.log("min -- ", dateMin);
    console.log("max -- ", dateMax);
    console.log("resourceId -- ", resourceId);
    console.log("bookingFor --- ", bookingFor);
    const result = isDoctorResource
      ? await firestore
          .collection(BOOK_LIST)
          .where("appointment.doctorId", "==", resourceId)
          .where("appointment.patientId", "==", patientId)
          .where("appointment.appointmentDate", ">=", dateMin)
          .where("appointment.appointmentDate", "<=", dateMax)
          .get()
      : await firestore
          .collection(BOOK_LIST)
          .where("appointment.service", "==", resourceId)
          .where("appointment.patientId", "==", patientId)
          .where("appointment.appointmentDate", ">=", dateMin)
          .where("appointment.appointmentDate", "<=", dateMax)
          .get();
    if (!result.empty) {
      if (!_.isEmpty(bookingFor)) {
        const existingBooking = _.find(
          result.docs.map((appointment) => appointment.data()),
          (appointment) => {
            return (
              appointment.appointment.bookingFor === bookingFor &&
              !appointment.appointment.isCancelled
            );
          }
        );
        console.log("existingBooking --- ", existingBooking);
        //existing booking for family member
        if (!_.isEmpty(existingBooking)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error - checkPatientDuplicateBookListItem -- ", error);
    return false;
  }
};

export const getPatientLatestAppointments = async () => {
  // const dateMin = _.clone(appointmentDate);
  // dateMin.setHours(0);
  // dateMin.setMinutes(0);
  // dateMin.setSeconds(0);
  // dateMin.setMilliseconds(0);
  // const dateMax = _.clone(appointmentDate);
  // dateMax.setHours(23);
  // dateMax.setMinutes(59);
  // dateMax.setSeconds(59);
  // dateMax.setMilliseconds(59);
  // // get bookings for that schedule
  // const latestAppointmentThatDay = await firestore
  //   .collection(HOSPITAL_SERVICE_APPOINTMENTS)
  //   .where("serviceScheduleId", "==", hospitalServiceScheduleDoc.id)
  //   .where("appointmentDate", ">=", dateMin)
  //   .where("appointmentDate", "<=", dateMax)
  //   .where("isCancelled", "==", false)
  //   .get();
};

export const patientHasDuplicateDoctorAppointmentForTheDay = async (
  doctorId: string,
  appointmentDate: Date,
  patientId: string,
  bookingFor = ""
): Promise<boolean> => {
  const dateMin = _.clone(appointmentDate);
  dateMin.setHours(0);
  dateMin.setMinutes(0);
  dateMin.setSeconds(0);
  dateMin.setMilliseconds(0);

  const dateMax = _.clone(appointmentDate);
  dateMax.setHours(23);
  dateMax.setMinutes(59);
  dateMax.setSeconds(59);
  dateMax.setMilliseconds(59);

  const doctorAppointments = await firestore
    .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
    .where("doctorId", "==", doctorId)
    .where("patientId", "==", patientId)
    .where("appointmentDate", ">=", dateMin)
    .where("appointmentDate", "<=", dateMax)
    .get();

  if (!doctorAppointments.empty) {
    const appointments = doctorAppointments.docs.map(
      (appointment) => appointment.data() as HospitalDoctorAppointments
    );

    if (!_.isEmpty(bookingFor)) {
      const existingBooking = _.find(
        appointments,
        (appointment) =>
          appointment.bookingFor === bookingFor && !appointment.isCancelled
      );
      if (!_.isEmpty(existingBooking)) {
        return true;
      } else {
        return false;
      }
    } else {
      const existingBooking = _.find(
        appointments,
        (appointment) => !appointment.isCancelled && !_.isEmpty(bookingFor)
      );
      return !_.isEmpty(existingBooking);
    }
  } else {
    return false;
  }
};

export const patientHasDuplicateServiceAppointmentForTheDay = async (
  service: string,
  appointmentDate: Date,
  patientId: string,
  bookingFor = ""
): Promise<boolean> => {
  const dateMin = _.clone(appointmentDate);
  dateMin.setHours(0);
  dateMin.setMinutes(0);
  dateMin.setSeconds(0);
  dateMin.setMilliseconds(0);

  const dateMax = _.clone(appointmentDate);
  dateMax.setHours(23);
  dateMax.setMinutes(59);
  dateMax.setSeconds(59);
  dateMax.setMilliseconds(59);

  const serviceAppointments = await firestore
    .collection(HOSPITAL_SERVICE_APPOINTMENTS)
    .where("service", "==", service)
    .where("patientId", "==", patientId)
    .where("appointmentDate", ">=", dateMin)
    .where("appointmentDate", "<=", dateMax)
    .get();

  if (!serviceAppointments.empty) {
    const appointments = serviceAppointments.docs.map(
      (appointment) => appointment.data() as HospitalServiceAppointments
    );
    if (!_.isEmpty(bookingFor)) {
      const existingBooking = _.find(
        appointments,
        (appointment) =>
          appointment.bookingFor === bookingFor && !appointment.isCancelled
      );

      if (!_.isEmpty(existingBooking)) {
        return true;
      } else {
        return false;
      }
    } else {
      const existingBooking = _.find(
        appointments,
        (appointment) => !appointment.isCancelled && !_.isEmpty(bookingFor)
      );

      return !_.isEmpty(existingBooking);
    }
  } else {
    return false;
  }
};

export const updateAppointmentViewers = async (
  viewedByUser: string,
  appointmentId: string,
  isConsultation: boolean
) => {
  const appointmentCollection = isConsultation
    ? HOSPITAL_DOCTOR_APPOINTMENTS
    : HOSPITAL_SERVICE_APPOINTMENTS;

  const appointment = await firestore
    .collection(appointmentCollection)
    .doc(appointmentId)
    .get();

  if (appointment.exists) {
    const appointmentData = appointment.data() as
      | HospitalDoctorAppointments
      | HospitalServiceAppointments;

    if (_.isEmpty(appointmentData.viewedByUsers)) {
      await appointment.ref.update({
        viewedByUsers: [viewedByUser],
      });
    } else {
      await appointment.ref.update({
        viewedByUsers: firebase.firestore.FieldValue.arrayUnion(viewedByUser),
      });
    }
  }
};
