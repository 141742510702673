export const ENVIRONMENTS = {
  development: "development",
  training: "training",
  demo: "demo",
  production: "production",
  local: "local",
};

//for firebase authentication and tokens once, ready configure this.
export const LOCAL_STORAGE = {
  accessToken: "access_token",
  fbIdToken: "id_token",
  userId: "user_id",
  recentMobileVerification: "recent_mobile_verification",
};

export const APP_ID = {
  production: "medbook-prod",
  demo: "medbook-demo-ae05c",
  development: "medbook-dev",
  training: "medbook-training",
  local: "medbook-dev",
};
